import * as React from "react";
import { useLocation } from "react-router-dom";
import SideNavButton from "common/components/sidenavbutton/SideNavButton";
import { routes } from "../routes";
import { useTranslation } from "react-i18next";

export function MaintenaceSideNav() {
  const { pathname } = useLocation();
  const { t } = useTranslation("errorBoard");

  return (
    <SideNavButton
      to={routes.majorTrouble.machineUnsellable()}
      translationKey={t("maintenance")}
      icon="Service"
      active={pathname.startsWith(routes.index())}
    />
  );
}

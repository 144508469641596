import * as React from "react";

interface IAndroidProps {
  color?: string;
  className?: string;
}

function Android({ color = "white", className }: IAndroidProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.4675 11.0943H4.55843V16.8816H15.4675V11.0943ZM15.4675 9.4408C15.4675 8.6808 15.3264 7.92825 15.0523 7.2261C14.7781 6.52396 14.3764 5.88597 13.8699 5.34857C13.3634 4.81118 12.7621 4.38489 12.1003 4.09405C11.4385 3.80321 10.7292 3.65352 10.0129 3.65352C9.29665 3.65352 8.58736 3.80321 7.92559 4.09405C7.26382 4.38489 6.66252 4.81118 6.15602 5.34857C5.64952 5.88597 5.24775 6.52396 4.97363 7.2261C4.69952 7.92825 4.55843 8.6808 4.55843 9.4408H15.4675ZM5.63531 3.62706C6.87712 2.57123 8.42161 1.99719 10.0129 2.00001C11.6688 2.00001 13.1906 2.6085 14.3906 3.62706L15.5228 2.42579L16.6246 3.59482L15.4924 4.79609C16.4875 6.11366 17.0286 7.75238 17.0259 9.4408V17.7083C17.0259 17.9276 16.9438 18.1379 16.7977 18.2929C16.6515 18.448 16.4533 18.5351 16.2467 18.5351H3.77922C3.57256 18.5351 3.37436 18.448 3.22823 18.2929C3.0821 18.1379 3 17.9276 3 17.7083V9.4408C3 7.68395 3.5735 6.0693 4.5335 4.79609L3.4013 3.59565L4.50311 2.42662L5.63531 3.62706ZM7.6753 7.78729C7.46864 7.78729 7.27044 7.70019 7.12431 7.54514C6.97818 7.39009 6.89608 7.17981 6.89608 6.96054C6.89608 6.74127 6.97818 6.53098 7.12431 6.37593C7.27044 6.22089 7.46864 6.13378 7.6753 6.13378C7.88196 6.13378 8.08015 6.22089 8.22629 6.37593C8.37242 6.53098 8.45451 6.74127 8.45451 6.96054C8.45451 7.17981 8.37242 7.39009 8.22629 7.54514C8.08015 7.70019 7.88196 7.78729 7.6753 7.78729ZM12.3506 7.78729C12.1439 7.78729 11.9457 7.70019 11.7996 7.54514C11.6535 7.39009 11.5714 7.17981 11.5714 6.96054C11.5714 6.74127 11.6535 6.53098 11.7996 6.37593C11.9457 6.22089 12.1439 6.13378 12.3506 6.13378C12.5573 6.13378 12.7555 6.22089 12.9016 6.37593C13.0477 6.53098 13.1298 6.74127 13.1298 6.96054C13.1298 7.17981 13.0477 7.39009 12.9016 7.54514C12.7555 7.70019 12.5573 7.78729 12.3506 7.78729Z"
        fill={color}
      />
    </svg>
  );
}

export default Android;

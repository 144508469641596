import * as moment from 'moment';

export function getUNIXTimeStampInMS (moment: moment.Moment): string {
  return String(moment.valueOf());
}

interface IUNIXTimeStampStringOptions {
  timeOfDay: 'dayStart' | 'dayEnd';
}

export function UNIXTimeStampXDaysAgoInMS (
  x: number,
  { timeOfDay }: IUNIXTimeStampStringOptions = { timeOfDay: 'dayEnd' }
): string {
  const today = moment();
  const XDaysAgo = today.subtract(x, 'days');

  if (timeOfDay === 'dayStart') {
    XDaysAgo.set({ hour: 0, minute: 0, second: 0 });
  } else if (timeOfDay === 'dayEnd') {
    XDaysAgo.set({ hour: 23, minute: 59, second: 59 });
  }

  return getUNIXTimeStampInMS(XDaysAgo);
}

export function UNIXTimeStampTodayInMS (
  options?: IUNIXTimeStampStringOptions
): string {
  return UNIXTimeStampXDaysAgoInMS(0, options);
}

export function formatDateForReport(timeStamp: string): string {
  return moment(Number(timeStamp)).format("DD/MM/YYYY");
}

import * as React from "react";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import * as moment from "moment";

import Typography from "common/components/typography/Typography";
import TextButton from "common/components/textbutton/TextButton";

import EditQuantityModal from "machines/components/editquantitymodal/EditQuantityModal";
import NewSlotModal from "machines/components/newslotmodal/NewSlotModal";
import EditMinMaxModal from "machines/components/editminmaxmodal/EditMinMaxModal";
import DeleteSlotConfirmationModal from "machines/components/deleteslotmodal/DeleteSlotConfirmationModal";
import AdjustOrderModal from "warehousing/components/adjustordermodal/AdjustOrderModal";

import { useDispatch } from "react-redux";

import { compareSlotNames } from "common/utils/slotUtils";

import { getTriggerRefill } from "refillorders/service";

import * as styles from "./Inventory.module.scss";
import { SwapOrderModal } from "machines/components/swapordermodal/SwapOrderModal";
import { useGetMachineInventory } from "../../../../machines/hooks/useGetMachineInventory";
import { downloadCSVFromJSON } from "utils/csv";
import InformationModal from "./InformationModal/InformationModal";

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import i18n from "common/i18n";

import {
  useInactiveMaterialSlotMutation,
  useActiveMaterialSlotMutation,
  useGetMachineDetailByIdQuery,
  MachineKind,
} from "gql/generated";
import {
  useIsAdmin,
  useIsOperator,
  useIsTechnician,
  useIsForthCKUser,
  useIsAdminContract,
} from "utils/user-role";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";

import { CancelSwapOrderModal } from "./CancelSwapOrderModal";
import { InventoryTable } from "./table";
import {
  IMachineInventoryWithPercentageData,
  mapDataToTable,
} from "./table/mapDataToTable";
import { toasti18n } from "utils/toast";

function Inventory(): JSX.Element {
  const cannotViewContent = useIsTechnician();
  const canEditContent =
    useIsAdmin() || useIsOperator() || useIsAdminContract();
  const isForthCKUser = useIsForthCKUser();

  if (cannotViewContent === true) {
    return <NoPermissionComponent />;
  }

  const { machineId }: { machineId: string } = useParams();

  const dispatch = useDispatch();
  const { data: rawMachine } = useGetMachineDetailByIdQuery({
    machineId,
  });
  const machineData = rawMachine?.machine;
  const { data = [], isLoading } = useGetMachineInventory(machineId);

  const [deleteSlotConfirmationData, setDeleteSlotConfirmationData] =
    useState<null | IMachineInventoryWithPercentageData>(null);
  const [editQuantityModalData, setEditQuantityModalData] =
    useState<IMachineInventoryWithPercentageData>(null);
  const [editMinMaxModalData, setEditMinMaxModalData] =
    useState<IMachineInventoryWithPercentageData>(null);
  const [swapInventoryData, setSwapInventoryData] =
    useState<null | IMachineInventoryWithPercentageData>(null);
  const [newSlotModalOpen, setNewSlotModalOpen] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>(null);
  const [informationModalOpen, setInformationModalOpen] =
    useState<boolean>(false);
  const [swapInventoryToCancel, setSwapInventoryToCancel] = useState<
    string | null
  >(null);

  const { mutate: inactiveMutation } = useInactiveMaterialSlotMutation();
  const { mutate: activeMutation } = useActiveMaterialSlotMutation();


  async function forceTriggerRefill() {
    try {
      const newRefillOrder = await getTriggerRefill(machineId);
      dispatch({
        type: "refillOrder/modify",
        payload: { [newRefillOrder.orderNumber]: newRefillOrder },
      }); // Update store
      toasti18n.success("toast_refill_order_create_success");
    } catch {}
  }

  function handleQuantityEdit(data: IMachineInventoryWithPercentageData): void {
    setEditQuantityModalData(data);
  }

  function handleIngredientEdit(
    data: IMachineInventoryWithPercentageData
  ): void {
    setSwapInventoryData(data);
  }

  function handleMinMaxEdit(data: IMachineInventoryWithPercentageData): void {
    setEditMinMaxModalData(data);
  }

  // function handleDeleteSlot(data: IMachineInventoryData): void {
  //   setDeleteSlotConfirmationData(data);
  // }

  function handleSwapInventoryCancel(machineInventoryId: string): void {
    setSwapInventoryToCancel(machineInventoryId);
  }

  function handleActive(data: IMachineInventoryWithPercentageData): void {
    const loadingToast = toast.loading(i18n.t("label_loading"));

    if (data.status === "normal") {
      toast.update(loadingToast, {
        type: "error",
        isLoading: false,
        render: i18n.t("toast_cannot_active_actived_slot"),
        autoClose: 5000,
      });
      return;
    }

    activeMutation(
      { machineId: data.machineId, slot: data.slot, materialId: data.SKU },
      {
        onSuccess: () => {
          toast.update(loadingToast, {
            type: "success",
            isLoading: false,
            render: i18n.t("toast_request_successful"),
            autoClose: 5000,
          });
          setInformationModalOpen(true);
        },
        onError: () => {
          toast.update(loadingToast, {
            type: "error",
            isLoading: false,
            render: i18n.t("toast_request_failed"),
            autoClose: 5000,
          });
        },
      }
    );
  }

  function handleDisable(data: IMachineInventoryWithPercentageData): void {
    const loadingToast = toast.loading(i18n.t("label_loading"));

    if (data.status === "off") {
      toast.update(loadingToast, {
        type: "error",
        isLoading: false,
        render: i18n.t("toast_cannot_inactive_inactived_slot"),
        autoClose: 5000,
      });
      return;
    }
    inactiveMutation(
      { machineId: data.machineId, slot: data.slot, materialId: data.SKU },
      {
        onSuccess: () => {
          toast.update(loadingToast, {
            type: "success",
            isLoading: false,
            render: i18n.t("toast_request_successful"),
            autoClose: 5000,
          });
          setInformationModalOpen(true);
        },
        onError: () => {
          toast.update(loadingToast, {
            type: "error",
            isLoading: false,
            render: i18n.t("toast_request_failed"),
            autoClose: 5000,
          });
        },
      }
    );
  }

  const isMachineEnableWorkOrderCreation =
    !machineData || isEmpty(machineData)
      ? true
      : !!machineData.enableWorkOrderCreation;

  const inventoryDataWithPercentages = useMemo(() => mapDataToTable(data), [data]);

  return (
    <>
      <InformationModal
        isOpen={informationModalOpen}
        onClose={() => setInformationModalOpen(false)}
      />
      <AdjustOrderModal
        orderNumber={orderNumber}
        onClose={() => setOrderNumber(null)}
      />
      <EditQuantityModal
        data={editQuantityModalData}
        onClose={() => setEditQuantityModalData(null)}
      />
      <EditMinMaxModal
        data={editMinMaxModalData}
        onClose={() => setEditMinMaxModalData(null)}
      />
      {deleteSlotConfirmationData !== null && (
        <DeleteSlotConfirmationModal
          inventoryData={deleteSlotConfirmationData}
          onClose={() => setDeleteSlotConfirmationData(null)}
        />
      )}
      <NewSlotModal
        isOpen={newSlotModalOpen}
        onClose={() => setNewSlotModalOpen(false)}
        machineId={machineId}
        occupiedSlots={new Set(data.map((x) => x.slot))}
        dataLoading={isLoading}
      />

      {swapInventoryData !== null && (
        <SwapOrderModal
          machineInventory={swapInventoryData}
          onClose={() => setSwapInventoryData(null)}
          machineType={machineData?.kind === MachineKind.BeverageVending ? "Beverage": "Spiral"}
        />
      )}

      {swapInventoryToCancel ? (
        <CancelSwapOrderModal
          machineId={machineId}
          machineInventoryId={swapInventoryToCancel}
          onClose={() => setSwapInventoryToCancel(null)}
        />
      ) : null}
      <div className={styles.Inventory}>
        {!isMachineEnableWorkOrderCreation && (
          <>
            <div className={styles.flash}>
              <Typography
                type="subtitle-1"
                translationKey="label_work_order_creation_disabled_flash"
              />
            </div>
            <div className={styles.spacer} />
          </>
        )}

        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.title}>
              <Typography type="headline-5" translationKey="menu_inventory" />
            </div>
            {canEditContent && (
              <>
                <TextButton
                  translationKey="action_create_new_slot"
                  onClick={() => setNewSlotModalOpen(true)}
                  icon="Plus"
                  className={styles.action}
                />

                <TextButton
                  translationKey="label_download_inventory_snapshot"
                  onClick={() =>
                    downloadCSVFromJSON(
                      inventoryDataWithPercentages
                        .map((inventory: any) => ({
                          slot: inventory.slot,
                          inventoryCode: inventory.inventoryCode,
                          name: inventory.inventory,
                          lastRefilled: moment(
                            Number(inventory.lastRefilled)
                          ).format("DD/MM/YY"),
                          slotStatus: inventory.slotStatus,
                          status: inventory.status,
                          priority:
                            inventory.additional.inventoryInfo.importanceLevel,
                          refillInProgress: inventory.refillInProgress,
                          value: inventory.value.toFixed(2),
                          parLevel: inventory.parLevel,
                          refillLevel: inventory.refillLevel,
                          capacity: inventory.capacity,
                          uom: inventory.uom,
                          recommendRefillAbovePar:
                            inventory.recommendRefillAbovePar,
                          noPartialRefill: inventory.noPartialRefill,
                          autoRefill: inventory.autoRefill,
                          notified: inventory.notified,
                        }))
                        .sort((a, b) => compareSlotNames(a.slot, b.slot)),
                      `Machine ${machineId} inventory ${moment().format(
                        "DD/MM/YYYY HH:mm"
                      )}`
                    )
                  }
                  icon="DownloadTray"
                  className={styles.action}
                />
                <TextButton
                  translationKey="action_force_trigger_refill"
                  onClick={forceTriggerRefill}
                  icon="Lightning"
                  className={styles.action}
                />
              </>
            )}
          </div>
        </div>
        <InventoryTable
          isLoading={isLoading}
          data={inventoryDataWithPercentages}
          permission={{ isAdmin: canEditContent, isForthCKUser, isMachineEnableWorkOrderCreation }}
          action={{
            handleQuantityEdit,
            handleIngredientEdit,
            handleMinMaxEdit,
            handleSwapInventoryCancel,
            handleActive,
            handleDisable,
          }}
        />
      </div>
    </>
  );
}

export default Inventory;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CardAggregate--1zFfwr2Mgv .Header--1daySUyTUA{font-size:10px !important}.CardAggregate--1zFfwr2Mgv .Value--1CS-HQnebG{margin-top:-11px}", ""]);
// Exports
exports.locals = {
	"CardAggregate": "CardAggregate--1zFfwr2Mgv",
	"Header": "Header--1daySUyTUA",
	"Value": "Value--1CS-HQnebG"
};
module.exports = exports;

import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';

function useIsVendiiUser (): boolean {
  const { data: userInfo, isLoading } = useGetCurrentUser();
  if (isLoading === true) {
    return false;
  }
  return userInfo.email.includes('@vendii.io');
}

export default useIsVendiiUser;

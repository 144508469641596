import * as React from 'react';
import * as moment from 'moment';
import { uniq } from 'lodash';

import { IBucket, IStateMap } from 'common/components/bucketstatus/BucketStatus';
import Typography from 'common/components/typography/Typography';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';

import * as styles from './TooltipContent.module.scss';

interface IStateCount {
  stateConfig: IStateMap,
  count: number
}

interface ITooltipContentProps {
  bucket: IBucket,
  stateMap: IStateMap[]
}

function TooltipContent ({
  bucket,
  stateMap
}: ITooltipContentProps): JSX.Element {
  const stateCount = {};
  const bucketStates = uniq(bucket.data.map(i => i.state));
  bucketStates.forEach(state => {
    stateCount[state] = {
      stateConfig: stateMap.find(i => i.state === state),
      count: bucket.data.filter(i => i.state === state).length
    };
  });

  const stateCounts: IStateCount[] = Object.values(stateCount);

  return (
    <div className={styles.TooltipContent}>
      {!!stateCounts.length && (
        <div className={styles.topRow}>
          {stateCounts.map((i, index) => {
            return (
              <div
                key={index}
                className={styles.row}
              >
                <IndicatorLabel
                  label={i.stateConfig.label}
                  color={i.stateConfig.color}
                />
                <Typography
                  text={i.count}
                  type='body-2'
                />
              </div>
            );
          })}
        </div>
      )}

      <div
        className={[
          styles.bottomRow
        ].join(' ')}
      >
        <Typography
          text={moment(bucket.min).format('MMM D, HH:mm')}
          type='body-2'
        />
        <Typography
          text='-'
          type='body-2'
        />
        <Typography
          text={moment(bucket.max).format('MMM D, HH:mm')}
          type='body-2'
        />
      </div>
    </div>
  );
}

export default TooltipContent;

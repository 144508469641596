import * as React from "react";

interface ITopBoardProps {
  color?: string;
  className?: string;
}

function TopBoard({ color = "white", className }: ITopBoardProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.7802 6.14134L7.71215 6.14134L7.71215 8.49736L14.7802 8.49736L14.7802 6.14134ZM15.5656 2.99998C15.9975 2.99998 16.3673 3.15378 16.6748 3.46137C16.9824 3.76896 17.1362 4.13873 17.1362 4.57066L17.1362 15.5654C17.1362 15.9974 16.9824 16.3671 16.6748 16.6747C16.3673 16.9823 15.9975 17.1361 15.5655 17.1361L4.57079 17.1361C4.13885 17.1361 3.76908 16.9823 3.46149 16.6747C3.1539 16.3671 3.00011 15.9974 3.00011 15.5654L3.00011 4.57066C3.00011 4.13873 3.1539 3.76896 3.46149 3.46137C3.76908 3.15378 4.13885 2.99998 4.57079 2.99998L15.5656 2.99998ZM15.5656 4.57066L4.57079 4.57066L4.57079 15.5654L15.5655 15.5654L15.5656 4.57066Z"
        fill={color}
      />
      <rect
        x="9.28467"
        y="8.49744"
        width="3.9267"
        height="2.35602"
        transform="rotate(-180 9.28467 8.49744)"
        fill={color}
      />
    </svg>
  );
}

export default TopBoard;

import * as React from 'react';

import Icon from 'common/components/icon/Icon';
import * as styles from './AdminIconCell.module.scss';

interface IAdminIconCellProps {
  onClick: (rowData: object) => void;
  icon: string;
  currentUserId: string;
}

export function AdminIconCell({
  onClick,
  icon,
  currentUserId,
}: IAdminIconCellProps) {
  return function Component(rowData: IUserData): JSX.Element {
    if (rowData.id === currentUserId) {
      return <div className={styles.AdminIconCell}></div>;
    }
    return (
      <>
        <div onClick={() => onClick(rowData)} className={styles.AdminIconCell}>
          <Icon color='primary' name={icon} />
        </div>
      </>
    );
  };
}

interface IUserData {
  id: string;
  role: string;
  name: string;
}

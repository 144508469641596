import {
  AutocompleteRenderInputParams,
  Autocomplete as MuiAutoComplete,
  createFilterOptions
} from "@mui/material";
import TextField from "components/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as styles from "./Autocomplete.module.scss";
export interface IAutocompleteProps {
  value: string;
  options: Array<AutocompleteOption>;
  label: string;
  id: string;
  required?: boolean;
  onChange: (newValue: string) => void;
  disableClearable?: boolean;
  translateLabels?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  className?: string;
  customRenderInput?: (
    params: AutocompleteRenderInputParams
  ) => React.ReactNode;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export interface AutocompleteOption {
  label: string;
  value: string;
}

export default function Autocomplete({
  value,
  options,
  id,
  onChange,
  label,
  required = false,
  disableClearable = false,
  translateLabels = false,
  loading = false,
  style,
  size = "medium",
  customRenderInput,
  className,
  open,
  onOpen,
  onClose,
}: IAutocompleteProps): JSX.Element {
  const { t } = useTranslation();
  options = options.map((option) => ({
    value: option.value,
    label: translateLabels ? t(option.label) : option.label,
  }));

  const filterOptions = createFilterOptions<AutocompleteOption>({
    limit: 1000,
  });

  const renderInput = (params) => (
    <TextField {...params} label={label} required={required} />
  );

  return (
    <MuiAutoComplete
      style={style}
      className={className}
      loading={loading}
      filterOptions={filterOptions}
      size={size}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={
        value === ""
          ? null
          : options.find((option) => option.value === value) ?? {
              value: value,
              label: value,
            }
      }
      disableClearable={disableClearable}
      autoHighlight
      ListboxProps={{
        style: {
          maxHeight: "20vh",
        },
      }}
      componentsProps={{
        popper: {
          className: styles.Popper,
        },
        paper: {
          className: styles.Dropdown,
        },
      }}
      onChange={(_, newValue: { label: string; value: string } | null) =>
        onChange(newValue === null ? "" : newValue.value)
      }
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      options={options}
      id={id}
      noOptionsText={t("label_no_options")}
      loadingText={t("label_loading")}
      renderInput={customRenderInput || renderInput}
    />
  );
}

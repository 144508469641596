import * as React from "react";

interface IAlertOutlineProps {
  color?: string;
  className?: string;
}

function AlertOutline({
  color = "white",
  className,
}: IAlertOutlineProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2085 46.3752H40.7918V51.9585H35.2085V46.3752ZM35.2085 24.0418H40.7918V40.7918H35.2085V24.0418ZM37.9722 10.0835C22.5622 10.0835 10.0835 22.5902 10.0835 38.0002C10.0835 53.4102 22.5622 65.9168 37.9722 65.9168C53.4102 65.9168 65.9168 53.4102 65.9168 38.0002C65.9168 22.5902 53.4102 10.0835 37.9722 10.0835ZM38.0002 60.3335C25.661 60.3335 15.6668 50.3393 15.6668 38.0002C15.6668 25.661 25.661 15.6668 38.0002 15.6668C50.3393 15.6668 60.3335 25.661 60.3335 38.0002C60.3335 50.3393 50.3393 60.3335 38.0002 60.3335Z"
        fill={color}
      />
    </svg>
  );
}

export default AlertOutline;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header--1Ig38vxyNm{background-color:#fff;padding-left:39px;padding-right:39px;margin:-25px -35px 0 -35px;box-shadow:0px 1px 4px rgba(0,0,0,.13)}.HeadingContainer--10AnCSb6LZ{display:flex;padding-top:21px;align-items:center;border-bottom:1px solid #cbcce6}.HeadingContainer--10AnCSb6LZ>:first-child{margin-right:33px}.HeadingContainer--10AnCSb6LZ>:nth-child(2){margin-right:6px}.HeadingContainer--10AnCSb6LZ .WarehouseDropdown--1eAIgCITyg{margin-left:auto}.ActiveTab--2aEh_q8O80{height:56px;display:flex;align-items:center;padding-left:8px;padding-right:8px;color:#4b4d9b;position:relative}.ActiveTab--2aEh_q8O80::after{content:\"\";position:absolute;height:2px;border-radius:1px;background-color:#4b4d9b;left:0;right:0;bottom:-2px}.InactiveTab--129OSwEulI{height:56px;display:flex;align-items:center;padding-left:8px;padding-right:8px;color:#8688c5}.TabButtonsContainer--2FkDJOB80q{display:flex;gap:12px;padding-top:16px;padding-bottom:16px;min-width:0}@media screen and (max-width: 1024px){.TabButtonsContainer--2FkDJOB80q{flex-wrap:wrap}.TabButtonsContainer--2FkDJOB80q>*{flex:1 1 40%}}.Gap--yMVKiO7F5U{height:20px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Header": "Header--1Ig38vxyNm",
	"HeadingContainer": "HeadingContainer--10AnCSb6LZ",
	"WarehouseDropdown": "WarehouseDropdown--1eAIgCITyg",
	"ActiveTab": "ActiveTab--2aEh_q8O80",
	"InactiveTab": "InactiveTab--129OSwEulI",
	"TabButtonsContainer": "TabButtonsContainer--2FkDJOB80q",
	"Gap": "Gap--yMVKiO7F5U"
};
module.exports = exports;

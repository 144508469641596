import * as React from "react";
import { Document, Page, View } from "@react-pdf/renderer";
import { OrganizationHeader } from "../atoms/OrganizationHeader";
import { InfoTable } from "../molecules/InfoTable";
import { TransferInventoryTable } from "../molecules/TransferInventoryTable";
import { TransferInventoryHeader } from "../atoms/TransferInventoryHeader";

export interface TransferInventorySheetProps {
  transferSheetData: {
    transferId: string;
    organizationInfo: {
      name: string; // บริษัท ฟอร์ท เวนด้ิง จํากัด สํานักงานใหญ่
      address: string | undefined; // 1053/1 ถนนพหลโยธิน แขวงพญาไท เขตพญาไท กรุงเทพฯ 10400
      tel: string | undefined; // 0-2279-1188
      taxId: string | undefined; // 0105558175256
      img: string | undefined;
    };
    fromWarehouse: {
      name: string; // Forth CK Main
      address: string | undefined; // //99/299 หมู่ที่ 1  ต.ลําไทร อ.วังน้อย จ.พระนครศรีอยุธยา 13170
      contact: string | undefined; // เนตรนิล สุขเสมอ
      tel: string | undefined; // 02 234 232
      email: string | undefined; // n.soksamor@boonrort.com
    };
    toWarehouse: {
      name: string;
      address: string | undefined;
      contact: string | undefined;
      tel: string | undefined;
      email: string | undefined;
    };
    transferInventoryData: Array<{
      id: string; // 07-01-01-0046
      name: string; // สิงห เลมอนโซดา กระปอง 330 มล.
      unit: string | undefined; // ลัง (24)
      pricePerUnit: string | undefined; // 257.01
      quantity: string; // 6,168.24
    }>;
  };
}

export function TransferInventorySheet({
  transferSheetData
}: TransferInventorySheetProps): JSX.Element {
  return (
    <Document>
      <Page size="A4" style={{ padding: 28 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 13
          }}
        >
          <View
            style={{
              flexBasis: 333,
              minWidth: 0
            }}
          >
            <View style={{ paddingTop: 6 }}>
              <OrganizationHeader
                orgInfo={transferSheetData.organizationInfo}
              />
            </View>
          </View>
          <View
            style={{
              flexBasis: 200,
              minWidth: 0
            }}
          >
            <TransferInventoryHeader
              transferId={transferSheetData.transferId}
            />
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 26
          }}
        >
          <View
            style={{
              flexBasis: 333,
              minWidth: 0
            }}
          >
            <InfoTable
              label={"label_sender"}
              tableData={[
                {
                  label: "label_warehouse",
                  value: transferSheetData.fromWarehouse.name
                },
                {
                  label: "label_address",
                  value: transferSheetData.fromWarehouse.address
                },
                {
                  label: "label_contact",
                  value: transferSheetData.fromWarehouse.contact
                },
                {
                  label: ["label_tel", "label_email"],
                  value: `${transferSheetData.fromWarehouse.tel}, ${transferSheetData.fromWarehouse.email}`
                }
              ]}
            />
          </View>
          <View
            style={{
              flexBasis: 200,
              minWidth: 0
            }}
          >
            <InfoTable
              label={"label_recepient"}
              tableData={[
                {
                  label: "label_warehouse",
                  value: transferSheetData.toWarehouse.name
                },
                {
                  label: "label_address",
                  value: transferSheetData.toWarehouse.address
                },
                {
                  label: "label_contact",
                  value: transferSheetData.toWarehouse.contact
                },
                {
                  label: ["label_tel", "label_email"],
                  value: `${transferSheetData.toWarehouse.tel}, ${transferSheetData.toWarehouse.email}`
                }
              ]}
            />
          </View>
        </View>

        <TransferInventoryTable
          transferInventoryData={transferSheetData.transferInventoryData}
        />
      </Page>
    </Document>
  );
}

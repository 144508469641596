// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HoverHighlight--25oDDcgVQq{transform:scale(1);transition:background-color .1s ease-in 0s,transform .1s ease-in 0s}.HoverHighlight--25oDDcgVQq .CloseButton--46e0jeFKnL{display:none}.HoverHighlight--25oDDcgVQq:hover{transform:scale(1.02)}.HoverHighlight--25oDDcgVQq:hover .CloseButton--46e0jeFKnL{display:block}.HoverHighlight--25oDDcgVQq:hover .HoverCard--1lAfUAItxu{background-color:#eeeef7}.HoverHighlight--25oDDcgVQq .HoverCard--1lAfUAItxu{transition:background-color .1s ease-in 0s,transform .1s ease-in 0s}", ""]);
// Exports
exports.locals = {
	"HoverHighlight": "HoverHighlight--25oDDcgVQq",
	"CloseButton": "CloseButton--46e0jeFKnL",
	"HoverCard": "HoverCard--1lAfUAItxu"
};
module.exports = exports;

import * as React from 'react';
import * as moment from 'moment';

import { StringCell, CheckboxCell } from 'common/components/table/cells/Cells';
import Table from 'common/components/table/Table';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';

import { formatMomentDate } from 'common/utils/format';

import { IRefillOrder } from 'refillorders/interfaces';
import { prepareItems } from '../refillOrderUtils';

import * as styles from './OrderForm.module.scss';

interface IOrderPdfProps {
  order: IRefillOrder;
  operator?: string;
}

function OrderForm({ order, operator }: IOrderPdfProps): JSX.Element {
  if (!order) {
    return null;
  }

  const items = prepareItems(order);
  const dummyCheckMap: { [id: string]: boolean } = {};

  const columns = [
    {
      dataKey: 'slot',
      headerLabel: 'label_slot',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.2,
    },
    {
      dataKey: 'name',
      headerLabel: 'label_ingredient',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.8,
    },
    {
      dataKey: 'lots',
      headerLabel: 'label_lot_nos',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.2,
    },
    {
      dataKey: 'location',
      headerLabel: 'label_location',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.5,
    },
    {
      dataKey: 'quantity',
      headerLabel: 'label_quantity',
      cellRenderer: StringCell,
      align: 'center',
      columnFlex: 0.4,
    },
    {
      dataKey: 'packageQuantity',
      headerLabel: 'label_unit',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 0.5,
    },
    {
      dataKey: 'total',
      headerLabel: 'label_total',
      cellRenderer: StringCell,
      align: 'center',
      columnFlex: 0.4,
    },
    {
      headerLabel: 'label_packed',
      cellRenderer: CheckboxCell({
        checkedMap: dummyCheckMap,
        onClick: () => {},
        idKey: 'id',
      }),
      align: 'center',
      columnFlex: 0.4,
    },
    {
      headerLabel: 'label_filled',
      cellRenderer: CheckboxCell({
        checkedMap: dummyCheckMap,
        onClick: () => {},
        idKey: 'id',
      }),
      align: 'center',
      columnFlex: 0.4,
    },
  ];

  let filterColumns = [];
  if (order?.status === 'recommended') {
    filterColumns = columns.filter(
      (c) => !(c.dataKey === 'lots' || c.dataKey === 'location')
    );
  } else {
    filterColumns = columns;
  }

  return (
    <div id={`ordernumber-${order.orderNumber}`} className={styles.OrderForm}>
      <div className={styles.header}>
        <div className={styles.left}>
          <Typography type='headline-6' translationKey='label_order_number' />
          &nbsp;
          <Typography type='headline-6' text={order.orderNumber} />
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.row}>
          <Input
            value={order.machineId}
            label='Machine ID'
            renderStyle='none'
            className={styles.input}
          />
          <Input
            value={order.type}
            label='Machine Type'
            renderStyle='none'
            className={styles.input}
          />
          <Input
            value={order.name}
            label='Machine Name'
            renderStyle='none'
            className={styles.input}
          />
        </div>
        <div className={styles.row}>
          <Input
            value={formatMomentDate(moment())}
            label='Date'
            renderStyle='none'
            className={styles.input}
          />
          <Input
            value={operator || order.assignee}
            label='Operator'
            renderStyle='none'
            className={styles.input}
          />
        </div>

        <Table
          className={styles.table}
          data={items || []}
          columns={filterColumns}
          disableScrollToTop={true}
        />

        <Typography type='body-1' text='reason' />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />

        <div className={styles.signatures}>
          <div className={styles.row}>
            <Input
              value='_________________'
              label='Packed By'
              renderStyle='none'
            />
            <Input
              value='_________________'
              label='Refilled By'
              renderStyle='none'
            />
          </div>

          <div className={styles.row}>
            <Input
              value='_____/_____/_____'
              label='Date Packed'
              renderStyle='none'
            />
            <Input
              value='_____/_____/_____'
              label='Date Refilled'
              renderStyle='none'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderForm;

import * as React from "react";
import Icon from "common/components/icon/Icon";

function PaginatorNavigationContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}

function PaginatorLeftChevron({ onClick }: { onClick: () => void }) {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <Icon onClick={onClick} name="PaginationChevron" color="onSurfaceMedium" />
    </div>
  );
}

function PaginatorDoubleLeftChevron({ onClick }: { onClick: () => void }) {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <Icon onClick={onClick} name="PaginationDoubleChevron" color="onSurfaceMedium" />
    </div>
  );
}

function PaginatorPage({
  onClick,
  pageNumber,
  active,
}: {
  onClick: () => void;
  pageNumber: number;
  active: boolean | undefined;
}) {
  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: "2px",
        minWidth: "16px",
        minHeight: "15px",
        color: active ? "white" : "rgba(0,0,0,0.6)",
        backgroundColor: active ? "#4B4D9B" : undefined,
        display: "grid",
        placeContent: "center",
        cursor: "pointer",
        fontFamily: "Kanit",
        fontSize: "10px",
      }}
    >
      {pageNumber}
    </div>
  );
}

function PaginatorRightChevron({ onClick }: { onClick: () => void }) {
  return (
    <div
      onClick={onClick}
      style={{
        transform: "rotate(180deg)",
        cursor: "pointer",
      }}
    >
      <Icon name="PaginationChevron" color="onSurfaceMedium" />
    </div>
  );
}

function PaginatorDoubleRightChevoron({ onClick }: { onClick: () => void }) {
  return (
    <div
      onClick={onClick}
      style={{
        transform: "rotate(180deg)",
        cursor: "pointer",
      }}
    >
      <Icon name="PaginationDoubleChevron" color="onSurfaceMedium" />
    </div>
  );
}

export {
  PaginatorNavigationContainer,
  PaginatorDoubleLeftChevron,
  PaginatorDoubleRightChevoron,
  PaginatorLeftChevron,
  PaginatorPage,
  PaginatorRightChevron,
};

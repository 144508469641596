// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Base64FileUploadModal--1vF_1Zud6H{box-sizing:border-box;padding:24px 32px 20px 32px;width:416px;max-width:100%}.Base64FileUploadModal--1vF_1Zud6H .Header--3sLEsw3vEC{margin-bottom:32px}.Base64FileUploadModal--1vF_1Zud6H .ButtonsGroup--1VxKH78VOj{display:flex;justify-content:space-between;margin-top:40px}", ""]);
// Exports
exports.locals = {
	"Base64FileUploadModal": "Base64FileUploadModal--1vF_1Zud6H",
	"Header": "Header--3sLEsw3vEC",
	"ButtonsGroup": "ButtonsGroup--1VxKH78VOj"
};
module.exports = exports;

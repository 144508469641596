import { useRouteMatch } from "react-router-dom";
import { warehousingRoutes } from "containers/warehousing/routes";


export function useInventoryIdFromURL() {
  const match = useRouteMatch<{ inventoryId: string; }>(
    warehousingRoutes.masterInventoryListWithInventoryId()
  );
  return match ? match.params.inventoryId : null;
}

import * as React from "react";
import * as JSZip from "jszip";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const PDFMerger = require("pdf-merger-js/browser");

export async function downloadPdf(
  PDFs:
    | React.ReactElement
    | Array<{
        document: React.ReactElement;
        fileName: string;
      }>,
  fileName: string,
  options: {
    merge: boolean;
  } = { merge: false }
) {
  if (Array.isArray(PDFs) && PDFs.length === 0) {
    return;
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));

  let downloadPromise: Promise<void>;
  if (Array.isArray(PDFs) && PDFs.length === 1) {
    downloadPromise = downloadSinglePdf(PDFs[0].document, PDFs[0].fileName);
  } else if (Array.isArray(PDFs) && options.merge) {
    downloadPromise = downloadMultiplePdfsAsMerged(PDFs, fileName);
  } else if (Array.isArray(PDFs)) {
    downloadPromise = downloadMultiplePdfsAsZip(PDFs, fileName);
  } else {
    downloadPromise = downloadSinglePdf(PDFs, fileName);
  }



  return downloadPromise;
}

async function downloadMultiplePdfsAsZip(
  PDFs: Array<{
    document: React.ReactElement;
    fileName: string;
  }>,
    zipFileName: string
) {
  const zip = new JSZip();

  const PDFBlobs = await Promise.all(PDFs.map((PDF) => pdf(PDF.document).toBlob()));

  PDFBlobs.forEach((blob, index) => zip.file(`${PDFs[index].fileName}.pdf`, blob));

  return zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${zipFileName}.zip`);
  });
}

async function downloadMultiplePdfsAsMerged(
  PDFs: Array<{
    document: React.ReactElement;
  }>,
  mergedPdfFileName: string
) {
  const merger = new PDFMerger();

  const PDFBlobs = await Promise.all(PDFs.map((PDF) => pdf(PDF.document).toBlob()));

  for (const blob of PDFBlobs) {
    await merger.add(blob);
  }

  const mergedPdf = await merger.saveAsBlob();
  saveAs(mergedPdf, `${mergedPdfFileName}.pdf`);
}

async function downloadSinglePdf(PDF: React.ReactElement, fileName: string) {
  const pdfBlob = await pdf(PDF).toBlob();
  const link = document.createElement("a");
  link.download = `${fileName}.pdf`;
  link.href = URL.createObjectURL(pdfBlob);
  link.click();
  URL.revokeObjectURL(link.href);
}

import { fetcher } from "gql/fetcher";
import { GetArchivedPlansDocument, GetArchivedPlansQuery, GetArchivedPlansQueryVariables, SortingOrder } from "gql/generated";
import * as React from "react";
import { useImmer } from "use-immer";

export const LIMIT = 50;
export const ROWS_PER_PAGE = 10;
export function computeNumberOfPages(numberOfRows: number) {
  return Math.max(Math.ceil(numberOfRows / ROWS_PER_PAGE), 1);
}
export interface UsePaginatedPlanArchivesFilter {
  range?: {
    startTime: string;
    endTime: string;
  };
  warehouseName?: string;
  planName?: string;
  routeName?: string;
}

interface DataPages {
  [pageNumber: number]: DataPage;
}
export type DataPage = GetArchivedPlansQuery['planArchives']["edges"];
type Direction = "FORWARDS" | "BACKWARDS";

export function usePaginatedPlanArchives({ filter }: { filter?: UsePaginatedPlanArchivesFilter } = {}) {
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(1); 

  const [searchDirection, setSearchDirection] = React.useState<Direction>("FORWARDS");
  const [nextTimestamp, setNextTimestamp] = React.useState<string | null>(null);

  const [data, setData] = useImmer<DataPages>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error>(null);
  if(error) throw error;

  const [totalNumberOfRecords, setTotalNumberOfRecords] = React.useState(undefined);
  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(undefined);

  const queryFilter: GetArchivedPlansQueryVariables['filter'] = {
    warehouseName: filter.warehouseName,
    name: filter.planName,
    refillZoneName: filter.routeName
  };

  function getRefillOrderArchive(cursor: string | undefined, sortOrder: SortingOrder){
    return fetcher<GetArchivedPlansQuery, GetArchivedPlansQueryVariables>(GetArchivedPlansDocument, {
      pagination: {
        limit: LIMIT,
        cursor: cursor
      },
      filter: queryFilter,
      sort: {
        order: sortOrder
      }
    })();
  }

  function getDataByPageNumber(pageNumber: number) {
    return data[pageNumber];
  }

  React.useEffect(() => {
    async function fetchDataOnFilterChange() {
      setData({});
      setIsLoading(true);
      setCurrentPageNumber(1);
      try {
        const refillOrderArchiveQuery = await getRefillOrderArchive(undefined, SortingOrder.Desc);

        setData((draft ) => {
          updateState({
            currentState: draft,
            incomingData: refillOrderArchiveQuery,
            insertDirection: "FORWARDS",
            startingPageNumber: 1,
          });
        });
  
        const nextTimestamp = refillOrderArchiveQuery.planArchives.pageInfo.endCursor;
        const totalNumberOfRecords = refillOrderArchiveQuery.planArchives.totalCount;
        const numberOfPages = computeNumberOfPages(totalNumberOfRecords);
  
        setSearchDirection('FORWARDS');
        setNextTimestamp(nextTimestamp);
        setTotalNumberOfRecords(totalNumberOfRecords);
        setTotalNumberOfPages(numberOfPages);
        setIsLoading(false);
      } catch(err) {
        setError(err);
      }
    }
    fetchDataOnFilterChange();
  }, [queryFilter.refillZoneName, queryFilter.warehouseName, queryFilter.name]);



  async function goToNextPage() {
    if (isLoading) {
      return;
    }
    const nextPageNumber = currentPageNumber + 1;
    if (totalNumberOfPages < nextPageNumber) {
      console.error("Attemping to fetch non-existing page");
      return;
    }
    const currentDataAtNextPageNumber = getDataByPageNumber(nextPageNumber);
    if (currentDataAtNextPageNumber) {
      setCurrentPageNumber(nextPageNumber);
      return;
    }
    if (searchDirection === "FORWARDS") {
      setIsLoading(true);
      setCurrentPageNumber(nextPageNumber);
      const data = await getRefillOrderArchive(nextTimestamp, SortingOrder.Desc);
      setData((draft) => {
        updateState({
          currentState: draft,
          incomingData: data,
          insertDirection: "FORWARDS",
          startingPageNumber: nextPageNumber,
        });
      });
      setNextTimestamp(data.planArchives.pageInfo.endCursor);
      setIsLoading(false);
    }
  }

  async function goToPage(pageNumber: number) {
    if (isLoading) {
      return;
    }
    if (pageNumber === currentPageNumber || pageNumber > totalNumberOfPages) {
      return;
    }
    const pageDifference = Math.abs(pageNumber - currentPageNumber);
    if (pageDifference > 5) {
      throw new Error("No cursor to fetch");
    }
    const dataAtNextPageNumber = getDataByPageNumber(pageNumber);
    if (dataAtNextPageNumber) {
      setCurrentPageNumber(pageNumber);
      return;
    }
    setIsLoading(true);
    setCurrentPageNumber(pageNumber);
    const refillOrderArchive = await getRefillOrderArchive(nextTimestamp, searchDirection === 'FORWARDS' ? SortingOrder.Desc : SortingOrder.Asc);
    setData((draft) => {
      updateState({
        currentState: draft,
        incomingData: refillOrderArchive,
        insertDirection: searchDirection,
        startingPageNumber: findClosestEmptyPage({ data, pageNumber, searchDirection }),
      });
    });
    setNextTimestamp(refillOrderArchive.planArchives.pageInfo.endCursor);
    setIsLoading(false);
  }

  async function goToPreviousPage() {
    if (isLoading) {
      return;
    }
    const previousPageNumber = currentPageNumber - 1;
    if (previousPageNumber < 1) {
      console.error("Attemping to fetch non-existing page");
      return;
    }
    const dataAtPreviousPage = getDataByPageNumber(previousPageNumber);
    if (dataAtPreviousPage) {
      setCurrentPageNumber(previousPageNumber);
      return;
    }
    if (searchDirection === "BACKWARDS") {
      setIsLoading(true);
      setCurrentPageNumber(previousPageNumber);
      const refillOrderArchive = await getRefillOrderArchive(nextTimestamp, SortingOrder.Asc);
      setData((draft) => {
        updateState({
          currentState: draft,
          incomingData: refillOrderArchive,
          insertDirection: "BACKWARDS",
          startingPageNumber: previousPageNumber,
        });
      });
      setNextTimestamp(refillOrderArchive.planArchives.pageInfo.endCursor);
      setIsLoading(false);
    }
  }

  async function goToLastPage() {
    if (isLoading) {
      return;
    }
    const dataAtLastPage = getDataByPageNumber(totalNumberOfPages);
    if (dataAtLastPage) {
      setCurrentPageNumber(totalNumberOfPages);
      return;
    }
    if (searchDirection === "FORWARDS") {
      setIsLoading(true);
      setCurrentPageNumber(totalNumberOfPages);
      const data = await getRefillOrderArchive(undefined, SortingOrder.Asc);
      const newTotalNumberOfPages = computeNumberOfPages(data.planArchives.totalCount);
      setTotalNumberOfRecords(data.planArchives.totalCount);
      setTotalNumberOfPages(newTotalNumberOfPages);
      setCurrentPageNumber(newTotalNumberOfPages);
      setData(() => {
        const draft: DataPages = {};
        updateState({
          currentState: draft,
          incomingData: data,
          insertDirection: "BACKWARDS",
          startingPageNumber: newTotalNumberOfPages,
          totalNumberOfPages: newTotalNumberOfPages,
        });
        return draft;
      });
      const orphanLength = data.planArchives.totalCount % ROWS_PER_PAGE;
      if (orphanLength === 0) {
        setNextTimestamp(data.planArchives.pageInfo.endCursor);
      } else {
        const reversedEdges = [...data.planArchives.edges].reverse();
        setNextTimestamp(reversedEdges[ROWS_PER_PAGE - orphanLength].cursor);
      }
      setSearchDirection("BACKWARDS");
      setIsLoading(false);
    }
  }

  async function goToFirstPage() {
    if (isLoading) {
      return;
    }
    const dataAtFirstPage = getDataByPageNumber(1);
    if (dataAtFirstPage) {
      setCurrentPageNumber(1);
      return;
    }
    if (searchDirection === "BACKWARDS") {
      setIsLoading(true);
      setCurrentPageNumber(1);
      const refillOrderArchiveQuery = await getRefillOrderArchive(undefined, SortingOrder.Desc);
      setData(() => {
        const draft: DataPages = {};
        updateState({
          currentState: draft,
          incomingData: refillOrderArchiveQuery,
          insertDirection: "FORWARDS",
          startingPageNumber: 1,
        });
        return draft;
      });

      const nextTimestamp = refillOrderArchiveQuery.planArchives.pageInfo.endCursor;
      const totalNumberOfRecords = refillOrderArchiveQuery.planArchives.totalCount;
      const numberOfPages = computeNumberOfPages(totalNumberOfRecords);

      setTotalNumberOfRecords(totalNumberOfRecords);
      setTotalNumberOfPages(numberOfPages);
      setNextTimestamp(nextTimestamp);
      setSearchDirection("FORWARDS");
      setIsLoading(false);
    }
  }

  return {
    currentPageNumber,
    totalNumberOfRecords,
    totalNumberOfPages,
    error,
    data,
    currentPageData: data[currentPageNumber],
    isLoading,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPreviousPage,
    goToPage,
  };
}

function updateState({
  startingPageNumber,
  incomingData,
  currentState,
  insertDirection,
  totalNumberOfPages,
}: {
  startingPageNumber: number;
  totalNumberOfPages?: number;
  incomingData: GetArchivedPlansQuery;
  currentState: DataPages;
  insertDirection: Direction;
}) {
  const { planArchives } = incomingData;

  // If there are no records, one page with empty array
  if(insertDirection === 'FORWARDS' && planArchives.edges.length === 0){
    currentState[1] = [];
    return;
  }  

  if (insertDirection === "FORWARDS") {
    const numberOfIncomingRecords = planArchives.edges.length;
    const numberOfPages = computeNumberOfPages(numberOfIncomingRecords);
    for (let i = 0; i < numberOfPages; i++) {
      currentState[startingPageNumber + i] = planArchives.edges.slice(
        i * ROWS_PER_PAGE,
        (i + 1) * ROWS_PER_PAGE
      );
    }
    return ;
  } 
  
  if (insertDirection === "BACKWARDS") {
    let reversedEdges = [...planArchives.edges].reverse();
    if (totalNumberOfPages === startingPageNumber) {
      const orphanLength = planArchives.totalCount % ROWS_PER_PAGE;
      if (orphanLength !== 0) {
        reversedEdges = reversedEdges.slice(ROWS_PER_PAGE - orphanLength);
      }
    }
    const numberOfIncomingRecords = reversedEdges.length;
    const numberOfPages = computeNumberOfPages(numberOfIncomingRecords);

    const currentPageToInsert = startingPageNumber - numberOfPages + 1;
    for (let i = 0; i < numberOfPages; i++) {
      currentState[currentPageToInsert + i] = reversedEdges.slice(
        i * ROWS_PER_PAGE,
        (i + 1) * ROWS_PER_PAGE
      );
    }
  }
}
function findClosestEmptyPage({
  data,
  pageNumber,
  searchDirection,
}: {
  data: DataPages;
  pageNumber: number;
  searchDirection: Direction;
}): number {
  let currentPageNumber = pageNumber;
  while (data[currentPageNumber + (searchDirection === "FORWARDS" ? -1 : 1)] === undefined) {
    currentPageNumber =
      searchDirection === "FORWARDS" ? currentPageNumber - 1 : currentPageNumber + 1;
  }
  return currentPageNumber;
}

import * as React from 'react';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

import * as styles from './TextButton.module.scss';

interface ITextButtonProps {
  translationKey?: string;
  onClick?: () => void;
  icon: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  text?: string;
  iconColor?: string;
  textType?: ITypographyType;
  badge?: number;
  showBorder?: boolean;
}
type ITypographyType =
  | 'headline-1'
  | 'headline-2'
  | 'headline-3'
  | 'headline-4'
  | 'headline-5'
  | 'headline-6'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'body-1'
  | 'body-2'
  | 'button'
  | 'caption'
  | 'overline';

function TextButton({
  translationKey,
  onClick,
  icon,
  className,
  loading,
  disabled,
  text,
  iconColor,
  textType,
  badge,
  showBorder,
}: ITextButtonProps): JSX.Element {
  return (
    <div
      onClick={loading || disabled ? () => {} : onClick}
      className={[
        styles.TextButton,
        loading || disabled ? styles.loading : '',
        showBorder ? styles.border : '',
        className,
      ].join(' ')}
    >
      <Icon name={icon} color={iconColor || 'primary'} />
      {textType ? (
        <Typography
          translationKey={loading ? 'label_loading' : translationKey}
          type={textType}
          className={styles.text}
          text={text}
        />
      ) : (
        <Typography
          translationKey={loading ? 'label_loading' : translationKey}
          type="button"
          className={styles.text}
          text={text}
        />
      )}
      {badge !== 0 && badge !== null && badge !== undefined && (
        <Typography
          className={styles.badge}
          text={`${badge}`}
          type="subtitle-2"
        />
      )}
    </div>
  );
}

export default TextButton;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoData--1lWmXn81ya{display:flex;flex:1 1 0;align-items:center;justify-content:center;position:sticky;left:0}.NoData--1lWmXn81ya .TextContainer--muLnUGaUnh{display:flex;flex-direction:column;align-items:center;gap:8px}.FadeInMoveUp--29gl4Fiuwk{animation:FadeInMoveUp--29gl4Fiuwk 300ms ease-out}@keyframes FadeInMoveUp--29gl4Fiuwk{0%{opacity:0;transform:translateY(8px)}100%{opacity:1;transform:translateY(0)}}", ""]);
// Exports
exports.locals = {
	"NoData": "NoData--1lWmXn81ya",
	"TextContainer": "TextContainer--muLnUGaUnh",
	"FadeInMoveUp": "FadeInMoveUp--29gl4Fiuwk"
};
module.exports = exports;

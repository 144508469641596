import * as React from "react";

import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import { Link } from "react-router-dom";

import { Skeleton } from "@mui/material";
import MuiLabel from "components/MuiLabel";
import { IIconProps } from "common/components/icon/Icon";

interface TextFieldViewOnlyProps {
  required?: boolean;
  value?: string | null;
  translateValue?: boolean;
  label: string | string[];
  link?: string;
  isLoading?: boolean;
  iconProps?: IIconProps;
}

/**
 * ## Usage:
 * ### Basic
 * ```
 * <TextFieldViewOnly
    value={machineId}
    label="label_machine_id"
  >
  ```
 * ### Required
 * ```
 * <TextFieldViewOnly
    value={machineId}
    label="label_machine_id"
    required={true}
  />
  ```
 * ### With link
 * ```        
 * <TextFieldViewOnly
    value={machineId}
    label="label_machine_id"
    link={`machines/${machineId}`}
    />
  ```
 * ### With loading state
 * ```        
 * <TextFieldViewOnly
    value={data?.machineId}
    label="label_machine_id"
    isLoading={isLoading}
    />
  ```
 * ### With array label
 * ```        
 * <TextFieldViewOnly
    value={account.accountNumber}
    label={["label_account_number", " ", "" + (index + 1)]}
    />
  ```
 * ### With translate value
 * ```        
 * <TextFieldViewOnly
    value="label_office"
    translateValue={true}
    label={["label_account_number", " ", "" + (index + 1)]}
    />
  ```
 */

function TextFieldViewOnly({
  required = false,
  translateValue = false,
  value,
  label,
  link,
  isLoading = false,
  iconProps,
}: TextFieldViewOnlyProps) {
  const { t } = useTranslation();
  value = translateValue ? t(value) : value;
  return (
    <MuiLabel label={label} required={required} iconProps={iconProps}>
      {isLoading ? (
        <Skeleton width="60%" />
      ) : link ? (
        <Link to={link} target="_blank" rel="noopener noreferrer">
          <Typography type="subtitle-1" color="primary500">
            {value || <span>&nbsp;</span>}
          </Typography>
        </Link>
      ) : (
        <Typography type="subtitle-1">
          {value || <span>&nbsp;</span>}
        </Typography>
      )}
    </MuiLabel>
  );
}

export default TextFieldViewOnly;

import * as React from "react";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import { WarehousingNavigation } from "containers/warehousing/components/WarehousingNavigation";
import {
  useGetWarehouseId,
  warehousingRoutes,
} from "containers/warehousing/routes";
import { Fallback } from "components/Fallback";
import { ErrorBoundary } from "@sentry/react";
import { useGetWarehouseById } from "../WarehouseOverview/use-get-warehouse";
import { useGetWarehouseProductsById } from "./use-get-products";
import { IAppliedFilter } from "common/components/filterbar/PureFilterBar";
import { warehouseBorderStyle } from "containers/warehousing/components/borderStyle";
import { WarehousingWorkspacePaddingContainer } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import Checkbox from "components/Checkbox/Checkbox";
import Typography from "components/Typography/Typography";
import { TableHeader } from "components/TableHeader";
import { EllipsisDropdown } from "common/components/EllipsisDropdown";
import { Link } from "react-router-dom";
import * as colors from "common/styles/colors.scss";
import { getCategoryTranslation } from "../../constants";
import * as moment from "moment";
import { Table } from "../../../../components/Table";
import { useSortState } from "hooks/useSortState";
import { useIdSelection } from "hooks/useIdSelection";
import { FilteBar } from "../../../../components/FilteBar";
import { DownloadHistoryModal } from "../WarehouseOverview/DownloadHistoryModal";
import { DownloadSummaryModal } from "./DownloadSummaryModal";
import { AcceptInventoryModalButton } from "./AcceptInventory";
import { useGetWarehouseLotTransfers } from "./use-get-lot-transfer";
import { MoveInventoryModalButton } from "./MoveInventory";
import { SyncLotButton, useQuerySyncLot } from "./SyncLotButton";
import { useTranslation } from "react-i18next";

export function WarehouseId() {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <WarehousingNavigation />
      <ErrorBoundary fallback={Fallback}>
        <WarehouseIdBody />
      </ErrorBoundary>
    </div>
  );
}

function WarehouseIdBody() {
  const warehouseId = useGetWarehouseId();
  const { data: warehouse, isLoading } = useGetWarehouseById(warehouseId);
  const { isLoading: isProductsLoading, isError: hasFailedToLoadProducts } =
    useGetWarehouseProductsById(warehouseId, {
      useErrorBoundary: () => false,
    });
  const { isLoading: isLotTransfersLoading } = useGetWarehouseLotTransfers(
    warehouseId,
    {
      useErrorBoundary: () => false,
    }
  );
  const { isLoading: isSyncLotStatusLoading } = useQuerySyncLot();
  const hideHeaderButtons =
    isProductsLoading ||
    hasFailedToLoadProducts ||
    isLotTransfersLoading ||
    isSyncLotStatusLoading;

  return (
    <>
      <WarehousingHeader
        title={warehouse?.name}
        isLoading={isLoading}
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
            link: warehousingRoutes.warehouses(),
          },
          {
            label: warehouse?.name,
          },
        ]}
        buttons={
          hideHeaderButtons ? null : (
            <div style={{ display: "flex", gap: "44px" }}>
              <MoveInventoryModalButton warehouseId={warehouseId} />
              <AcceptInventoryModalButton warehouseId={warehouseId} />
              <WarehouseIdHeaderDropDown />
            </div>
          )
        }
        afterElement={
          hideHeaderButtons ? null : (
            <SyncLotButton
              style={{
                position: "relative",
                top: "8px",
                height: "14px",
              }}
            />
          )
        }
      />
      <div
        style={{
          flex: "1 1 0px",
          minHeight: "0px",
          background: "white",
        }}
      >
        <ErrorBoundary fallback={Fallback}>
          <WarehouseIdTable />
        </ErrorBoundary>
      </div>
    </>
  );
}

function WarehouseIdTable() {
  const { t } = useTranslation();
  const warehouseId = useGetWarehouseId();
  const { data, isLoading } = useGetWarehouseProductsById(warehouseId);
  const [filterState, setFilterState] = React.useState<IAppliedFilter>(null);
  const filteredData = React.useMemo(() => {
    if (isLoading || !filterState) return data;
    return data.filter((product) =>
      product.SKU.toLowerCase().includes(filterState.value.toLowerCase())
    );
  }, [data, filterState]);

  const { getSortDirectionByColumnId, toggleSortColumnByColumnId, sortState } =
    useSortState();

  const sortedData = React.useMemo(() => {
    if (!sortState)
      return filteredData?.sort((productA, productB) =>
        productA.SKU.trim().localeCompare(productB.SKU.trim())
      );

    if (sortState.columnId === COLUMNS.STOCK) {
      return filteredData?.sort((productA, productB) => {
        const comparisonResult =
          productA.totalQuantity - productB.totalQuantity;
        return sortState.direction === "ASC"
          ? comparisonResult
          : -comparisonResult;
      });
    }
  }, [filteredData, sortState]);

  const allIds = React.useMemo(() => {
    return data?.map((product) => product.id) ?? [];
  }, [data]);
  const filteredIds = React.useMemo(
    () => filteredData?.map((product) => product.id),
    [filteredData]
  );
  const {
    clearSelectionInView,
    isIdSelected,
    selectAllInView,
    selectionStatus,
    toggleId,
  } = useIdSelection(allIds, filteredIds);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ borderBottom: warehouseBorderStyle }}>
        <WarehousingWorkspacePaddingContainer>
          <FilteBar
            value={filterState}
            onChange={setFilterState}
            filterOptions={[
              {
                field: COLUMNS.SKU,
                queryType: "contains",
                translationKey: "label_sku",
              },
            ]}
            placeholder="label_search"
          />
        </WarehousingWorkspacePaddingContainer>
      </div>

      <div style={{ flex: "1 1 0", minHeight: "0px" }}>
        <Table
          RowWrapper={WarehousingWorkspacePaddingContainer}
          data={sortedData}
          isLoading={isLoading}
          getKey={(product) => product.id}
          isSelected={(product) => isIdSelected(product.id)}
          columns={[
            {
              id: COLUMNS.CHECKBOX,
              style: {
                flex: "1 0 29px",
                minWidth: "29px",
                display: "flex",
                alignItems: "center",
              },
              cell: (product) => (
                <Checkbox
                  checked={isIdSelected(product.id)}
                  onClick={() => {
                    toggleId(product.id);
                  }}
                />
              ),
              headerCell: () => (
                <Checkbox
                  onClick={() => {
                    if (selectionStatus === "ALL") {
                      clearSelectionInView();
                    } else {
                      selectAllInView();
                    }
                  }}
                  checked={selectionStatus === "ALL"}
                  indeterminate={selectionStatus === "PARTIAL"}
                />
              ),
            },
            {
              id: COLUMNS.SKU,
              style: {
                flex: "1 0 107px",
                minWidth: "107px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_sku")}</TableHeader>,
              cell: (product) => (
                <Link
                  to={warehousingRoutes.warehouseWithWarehouseIdProductWithProductId(
                    warehouseId,
                    product.id
                  )}
                >
                  <span
                    style={{
                      fontFamily: "Kanit",
                      color: colors.primary500,
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {product.SKU}
                  </span>
                </Link>
              ),
            },
            {
              id: COLUMNS.IMAGE,
              style: {
                flex: "1 0 43px",
                minWidth: "43px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              },
              headerCell: () => <TableHeader>{t("label_image")}</TableHeader>,
              cell: (product) => (
                <img src={product.image} style={{ height: "38px" }} />
              ),
            },
            {
              id: COLUMNS.NAME,
              style: {
                flex: "2 0 250px",
                minWidth: "250px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_name")}</TableHeader>,
              cell: (product) => (
                <div style={{ paddingRight: "20px" }}>
                  <Typography type="body-2">{product.name}</Typography>
                </div>
              ),
            },
            {
              id: COLUMNS.BRAND,
              style: {
                flex: "1 0 125px",
                minWidth: "125px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_brand")}</TableHeader>,
              cell: (product) => (
                <div style={{ paddingRight: "10px" }}>
                  <Typography type="body-2">{product.manufacturer}</Typography>
                </div>
              ),
            },
            {
              id: COLUMNS.PACKAGE_VALUE,
              style: {
                flex: "1 0 79px",
                minWidth: "79px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_package_value")}</TableHeader>,
              cell: (product) => (
                <Typography type="body-2">{product.package}</Typography>
              ),
            },
            {
              id: COLUMNS.UNIT,
              style: {
                flex: "1 0 48px",
                minWidth: "48px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_unit")}</TableHeader>,
              cell: (product) => (
                <Typography type="body-2">{product.uom}</Typography>
              ),
            },
            {
              id: COLUMNS.PACK,
              style: {
                flex: "1 0 64px",
                minWidth: "64px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_pack")}</TableHeader>,
              cell: (product) => (
                <Typography type="body-2">{product.packageType}</Typography>
              ),
            },
            {
              id: COLUMNS.STOCK,
              style: {
                flex: "1 0 102px",
                minWidth: "102px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: (columnId) => (
                <TableHeader
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_stock")}
                </TableHeader>
              ),
              cell: (product) => (
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "8px",
                      height: "8px",
                      backgroundColor: (() => {
                        const totalQuantity = product.totalQuantity;
                        if (totalQuantity <= 50) {
                          return colors.error;
                        }
                        if (totalQuantity >= 100) {
                          return colors.success;
                        }
                        return colors.warning;
                      })(),
                    }}
                  ></div>
                  <Typography type="body-2">
                    {product.totalQuantity}
                  </Typography>
                </div>
              ),
            },
            {
              id: COLUMNS.CATEGORY,
              style: {
                flex: "1 0 82px",
                minWidth: "82px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_category")}</TableHeader>,
              cell: (product) => (
                <Typography type="body-2" translate>
                  {getCategoryTranslation(product.machineCategory)}
                </Typography>
              ),
            },
            {
              id: COLUMNS.EXPIRATION,
              style: {
                flex: "1 0 85px",
                minWidth: "85px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t("label_expired")}</TableHeader>,
              cell: (product) =>
                !product.oldestExpiry ? (
                  <div>-</div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography type="body-2">
                      {moment(product.oldestExpiry).format("DD/MM/YYYY")}
                    </Typography>
                    {(() => {
                      const isExpired = moment(product.oldestExpiry).isBefore(
                        moment()
                      );
                      return isExpired ? (
                        <Typography color="error" translate type="caption">
                          label_expired
                        </Typography>
                      ) : null;
                    })()}
                  </div>
                ),
            },
          ]}
        />
      </div>
    </div>
  );
}

function WarehouseIdHeaderDropDown() {
  const [isOpen, setIsOpen] = React.useState(false);
  const warehouseId = useGetWarehouseId();
  const { data: warehouse } = useGetWarehouseById(warehouseId);

  const [isDownloadHistoryModalOpen, setIsDownloadHistoryModalOpen] =
    React.useState(false);

  const [isDownloadSummaryModalOpen, setIsDownloadSummaryModalOpen] =
    React.useState(false);

  return (
    <>
      {isDownloadSummaryModalOpen ? (
        <DownloadSummaryModal
          onClose={() => setIsDownloadSummaryModalOpen(false)}
          fileName={`${warehouse.name}-summary-${moment().format(
            "DD-MM-YYYY"
          )}`}
          warehouseId={warehouseId}
        />
      ) : null}

      {isDownloadHistoryModalOpen ? (
        <DownloadHistoryModal
          onClose={() => setIsDownloadHistoryModalOpen(false)}
        />
      ) : null}
      <EllipsisDropdown>
        <EllipsisDropdown.EllipsisIcon onClick={() => setIsOpen(!isOpen)} />
        <EllipsisDropdown.OptionGroup isOpen={isOpen}>
          <EllipsisDropdown.Option
            icon="DownloadDocument"
            translationKey="label_download_summary"
            onClick={() => {
              setIsDownloadSummaryModalOpen(true);
              setIsOpen(false);
            }}
          />
          <EllipsisDropdown.Option
            icon="DownloadDocument"
            translationKey="label_download_history"
            onClick={() => {
              setIsDownloadHistoryModalOpen(true);
              setIsOpen(false);
            }}
          />
          <Link
            to={warehousingRoutes.warehouseWithWarehouseIdSupportedMachines(
              warehouseId
            )}
          >
            <EllipsisDropdown.Option
              onClick={() => {}}
              translationKey="label_support_machine"
              icon="Machine"
            />
          </Link>
          <Link
            to={warehousingRoutes.warehouseWithWarehouseIdWarehouseTeam(
              warehouseId
            )}
          >
            <EllipsisDropdown.Option
              translationKey="label_warehouse_team"
              icon="Team"
              onClick={() => {}}
            />
          </Link>
          <Link
            to={warehousingRoutes.warehouseWithWarehouseIdSettings(warehouseId)}
          >
            <EllipsisDropdown.Option
              translationKey="label_setting"
              icon="Settings"
              onClick={() => {}}
            />
          </Link>
        </EllipsisDropdown.OptionGroup>
      </EllipsisDropdown>
    </>
  );
}

const COLUMNS = {
  CHECKBOX: "checkbox",
  SKU: "sku",
  IMAGE: "image",
  NAME: "name",
  BRAND: "brand",
  PACKAGE_VALUE: "packageValue",
  UNIT: "unit",
  PACK: "pack",
  STOCK: "stock",
  CATEGORY: "category",
  EXPIRATION: "expiration",
};

import * as React from 'react';
import { Scatter } from 'react-chartjs-2';
import { formatCurrency, hasCurrencySymbol } from 'utils/currency';
import * as styles from './LineGraph.module.scss';
import * as moment from 'moment';
import { GridLineOptions, LinearScaleOptions } from 'chart.js';

interface LineGraphProps {
  className?: string;
  ticks?: Partial<LinearScaleOptions['ticks']>;
  YGrid?: Partial<GridLineOptions>;
  data: [number, number][];
  labels?: Array<string | Array<string>>;
  color?: string;
  scales?: {
    xAxis?: {
      min?: number;
      max?: number;
    };
    yAxis?: {
      min?: number;
      max?: number;
    };
  };
}

function LineGraph({
  data,
  scales,
  labels,
  className,
  color = '#2C2E78',
  ticks = {},
  YGrid = {},
}: LineGraphProps): JSX.Element {
  return (
    <div className={[styles.LineGraph, className].join(' ')}>
      <Scatter
        data={{
          datasets: [
            {
              showLine: true,
              data: data.map(([x, y]) => ({ x, y })),
              pointBackgroundColor: (ctx) => {
                const dateString = labels[ctx.dataIndex][0];
                const parsedDate = moment(dateString, 'YYYY-MM-DD');
                const dayOfWeek = parsedDate.day();
                const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
                return isWeekend ? color : 'rgba(255,255,255)';
              },
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: labels
                  ? (ctx) => {
                      const label = labels[ctx.dataIndex];
                      return typeof label === 'string'
                        ? tryCurrencyFormatter(label)
                        : label.map((line) => tryCurrencyFormatter(line));
                    }
                  : undefined,
              },
              displayColors: false,
            },
            legend: {
              display: false,
            },
          },
          clip: {
            top: 20,
            left: 0,
            right: 0,
            bottom: 0,
          },
          scales: {
            xAxis: {
              min: scales?.xAxis?.min,
              max: scales?.xAxis?.max,
              grid: {
                display: false,
                borderColor: '#9E9E9E',
                borderWidth: 1,
              },
              ticks: {
                display: false,
              },
            },
            yAxis: {
              min: scales?.yAxis?.min,
              max: scales?.yAxis?.max,
              grid: {
                display: false,
                borderColor: '#9E9E9E',
                borderWidth: 1,
                drawTicks: false,
                ...YGrid,
              },
              ticks: {
                display: false,
                ...ticks,
              },
            },
          },
          elements: {
            point: {
              backgroundColor: 'rgb(255, 255, 255)',
              borderColor: color,
              borderWidth: 1,
            },
            line: {
              backgroundColor: 'rgb(255, 255, 255)',
              borderColor: color,
              borderWidth: 1,
            },
          },
        }}
      />
    </div>
  );
}

export { LineGraph };

function tryCurrencyFormatter(str: string) {
  return hasCurrencySymbol(str)
    ? formatCurrency({ input: str, minimumFractionDigits: 0 })
    : str;
}

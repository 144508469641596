// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { deleteMachineInventories } from 'machines/service';

// Types
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';
import { toasti18n } from 'utils/toast';
interface IUseDeleteMachineInventory {
  machineId: string;
  machineInventoryId: string;
}

function useDeleteMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, IUseDeleteMachineInventory>(
    ({ machineId, machineInventoryId }) =>
      deleteMachineInventories(machineId, machineInventoryId),
    {
      onSuccess: (_, { machineId }) => {
        toasti18n.success("toast_machine_delete_success");
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { useDeleteMachineInventory };
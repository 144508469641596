// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Disabled--1xUWxgpe4u{cursor:not-allowed;background-color:#f7f7f7}", ""]);
// Exports
exports.locals = {
	"Disabled": "Disabled--1xUWxgpe4u"
};
module.exports = exports;

/* eslint-disable react/display-name */
import * as React from "react";

import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";

import {
  Container,
  Header,
  OperationTable,
  TextButtonsContainer,
} from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";

import {
  useDownloadInventorySummaryByPlanId,
  useDownloadRefillOrdersForPlansByStateCSV,
} from "../../hooks";
import { useIdSelection } from "hooks/useIdSelection";
import { useSortState } from "hooks/useSortState";

import { useQueryClient } from "react-query";
import TextButton from "common/components/textbutton/TextButton";
import {
  GetEnroutePlansQuery,
  MachineKind,
  PlanStatus,
  useGetEnroutePlansQuery,
} from "gql/generated";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { EnrouteModal } from "./EnrouteModal";
import { useTranslation } from "react-i18next";
import { DownloadOperationPDFButton } from "../operation-pdf/DownloadOperationPDFButton";
import { MarkEnroutePlansCompleteButton } from "./CompleteConfirmationModal";

export default function Enroute() {
  const { t } = useTranslation();
  const { isLoading, data: enroutePlansQuery } = useGetEnroutePlansQuery({
    status: [PlanStatus.EnRoute],
  });
  const plans = React.useMemo(
    () => (isLoading ? undefined : mapDataToTable(enroutePlansQuery)),
    [enroutePlansQuery]
  );

  const allPlanIds = React.useMemo(
    () => plans?.map((plan) => plan.planId) ?? [],
    [plans]
  );
  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredPlans = React.useMemo(
    () =>
      appliedFilter
        ? plans?.filter((plan) => {
            let value;
            switch (appliedFilter.field) {
              case columns.planName:
                value = plan.planName;
                break;
              case columns.warehouseName:
                value = plan.warehouseName;
                break;
              default:
                value = plan.planName;
                break;
            }
            return value
              .toLowerCase()
              .includes(appliedFilter.value.toLowerCase());
          })
        : plans,
    [plans, appliedFilter]
  );
  const filteredPlanIds = React.useMemo(
    () => filteredPlans?.map((plan) => plan.planId),
    [filteredPlans]
  );

  const {
    selectionStatus,
    isIdSelected,
    toggleId,
    clearSelectionInView,
    selectAllInView,
    clearSelection,
    selectedIds,
  } = useIdSelection(allPlanIds, filteredPlanIds);

  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({
      columnId: columns.planName,
      direction: "ASC",
    });

  const sortedPlans = React.useMemo(
    () =>
      sortState
        ? filteredPlans
            ?.map((elem) => elem)
            ?.sort((planA, planB) => {
              return (
                sortFnMap[sortState.columnId](planA, planB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredPlans,
    [filteredPlans, sortState]
  );

  // const [planIdForDownloadingReport, setPlanIdForDownloadingReport] = React.useState<null | string>(
  //   null
  // );
  // console.log(planIdForDownloadingReport);
  const [planIdToView, setPlanIdToView] = React.useState<string>(null);

  const queryClient = useQueryClient();

  const { isLoading: isDownloadingReport, downloadInventorySummaryByPlanId } =
    useDownloadInventorySummaryByPlanId();

  const {
    downloadRefillOrdersForPlansByStateCSV,
    isLoading: isDownloadingRefillOrdersByPlanState,
  } = useDownloadRefillOrdersForPlansByStateCSV();

  return (
    <>
      <Container>
        {planIdToView ? (
          <EnrouteModal
            planId={planIdToView}
            onClose={() => setPlanIdToView(null)}
            onCompleteSuccess={() => {
              queryClient.invalidateQueries(["GetOperationStatusCounts"]);
              queryClient.invalidateQueries(["GetEnroutePlans"]);
              queryClient.invalidateQueries(["GetCompletedPlans"]);
              setPlanIdToView(null);
            }}
          />
        ) : null}
        <Header>
          <TextButtonsContainer>
            <MarkEnroutePlansCompleteButton
              planIds={selectedIds}
              onSuccess={clearSelection}
            />
            <TextButton
              icon="DownloadTray"
              translationKey="label_download_refill_orders_in_all_plans"
              disabled={isDownloadingRefillOrdersByPlanState}
              onClick={() => downloadRefillOrdersForPlansByStateCSV("en_route")}
            />
          </TextButtonsContainer>
          <Typography type="body-2" translate>
            {selectedIds.length}
            {` `}
            {selectedIds.length === 1 ? "label_plan" : "label_plans"}
            {` `}
            label_selected
          </Typography>
        </Header>
        <PureFilterBar
          placeholder="label_search"
          filterOptions={[
            {
              field: columns.planName,
              queryType: "contains",
              translationKey: "label_plan",
            },
            {
              field: columns.warehouseName,
              queryType: "contains",
              translationKey: "label_warehouse",
            },
          ]}
          onChange={setAppliedFilter}
          value={appliedFilter}
        />
        <OperationTable
          data={sortedPlans}
          columns={[
            {
              cell: (plan) => (
                <Checkbox
                  checked={isIdSelected(plan.planId)}
                  onClick={() => {
                    toggleId(plan.planId);
                  }}
                />
              ),
              headerCell: () => (
                <Checkbox
                  onClick={() => {
                    if (selectionStatus === "ALL") {
                      clearSelectionInView();
                    } else {
                      selectAllInView();
                    }
                  }}
                  checked={selectionStatus === "ALL"}
                  indeterminate={selectionStatus === "PARTIAL"}
                />
              ),
              id: columns.checkbox,
              style: {
                flex: "0 0 42px",
                minWidth: "42px",
                paddingRight: "var(--table-h-padding)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            },
            {
              id: columns.planName,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <div
                  style={{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: 700,
                    textDecoration: "underline",
                    color: "#4B4D9B",
                    cursor: "pointer",
                  }}
                  onClick={() => setPlanIdToView(plan.planId)}
                >
                  {plan.planName}
                </div>
              ),
              style: {
                flex: "1 0 202px",
                minWidth: "202px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.warehouseName,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_warehouse")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.warehouseName}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.taobinMachinesInRoute,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_taobin_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.taobinMachinesInRoute}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.taobinRefilled,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_taobin_refilled")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.taobinRefilled}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.taobinAdjusted,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_taobin_adjusted")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.taobinAdjusted}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.spiralMachinesInRoute,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_spiral_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.spiralMachinesInRoute}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.spiralRefilled,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_spiral_refilled")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.spiralRefilled}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.spiralAdjusted,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_spiral_adjusted")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.spiralAdjusted}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            // {
            //   id: columns.flag,
            //   headerCell: (columnId) => (
            //     <TableHeader
            //       sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
            //       onClick={() => toggleSortColumnByColumnId(columnId)}
            //     >
            //       label_flag
            //     </TableHeader>
            //   ),
            //   cell: (plan) => (
            //     <Typography type="body-2" color={plan.flag > 0 ? "error" : "onSurfaceMedium"}>
            //       {plan.flag}
            //     </Typography>
            //   ),
            //   style: {
            //     flex: "1 0 176px",
            //     minWidth: "176px",
            //     display: "flex",
            //     alignItems: "center",
            //   },
            // },
            {
              id: columns.download,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  action_download
                </Typography>
              ),
              cell: (plan) => (
                <DownloadOperationPDFButton planId={plan.planId} />
              ),
              style: {
                flex: "0 0 78px",
                minWidth: "78px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.downloadReport,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_inventory_summary
                </Typography>
              ),
              cell: (plan) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={
                    isDownloadingReport
                      ? () => {}
                      : () =>
                          downloadInventorySummaryByPlanId(
                            plan.planId,
                            plan.planName
                          )
                  }
                >
                  <Icon name="DownloadTray" color="primary500" />
                </div>
              ),
              style: {
                flex: "0 0 68px",
                minWidth: "68px",
                display: "flex",
                alignItems: "center",
              },
            },
          ]}
          isLoading={isLoading}
          getKey={(plan) => plan.planId}
          isSelected={(plan) => isIdSelected(plan.planId)}
        />
      </Container>
    </>
  );
}

interface TableRow {
  planId: string;
  planName: string;
  warehouseName: string;
  taobinMachinesInRoute: number;
  spiralMachinesInRoute: number;
  taobinRefilled: number;
  taobinAdjusted: number;
  spiralRefilled: number;
  spiralAdjusted: number;
  flag: number;
}

function countMachinesInPlan(plan: GetEnroutePlansQuery["plans"][0], type: MachineKind) {
  return plan.refillOrders.reduce((agg, curr) => {
    if(curr.machine.kind === type) {
      return agg + 1;
    }
    return agg;
  }, 0);
}

function mapDataToTable(enroutePlansQuery: GetEnroutePlansQuery): TableRow[] {
  const enroutePlans = enroutePlansQuery.plans;
  return enroutePlans.map((ep) => ({
    // TODO: Flag needed
    flag: 0,
    taobinMachinesInRoute: countMachinesInPlan(ep, MachineKind.BeverageVending),
    spiralMachinesInRoute: countMachinesInPlan(ep, MachineKind.SpiralVending),
    planId: ep.id,
    planName: ep.name,
    warehouseName: ep.warehouse.name,
    taobinAdjusted: ep.refillOrders.reduce(
      (agg, ro) =>
        ro.state === "adjusted" &&
        ro.machine.kind === MachineKind.BeverageVending
          ? agg + 1
          : agg,
      0
    ),
    taobinRefilled: ep.refillOrders.reduce(
      (agg, ro) =>
        ro.state === "completed" &&
        ro.machine.kind === MachineKind.BeverageVending
          ? agg + 1
          : agg,
      0
    ),
    spiralAdjusted: ep.refillOrders.reduce(
      (agg, ro) =>
        ro.state === "adjusted" && ro.machine.kind === MachineKind.SpiralVending
          ? agg + 1
          : agg,
      0
    ),
    spiralRefilled: ep.refillOrders.reduce(
      (agg, ro) =>
        ro.state === "completed" &&
        ro.machine.kind === MachineKind.SpiralVending
          ? agg + 1
          : agg,
      0
    ),
  }));
}

const columns = {
  planName: "planName",
  warehouseName: "warehouseName",
  taobinRefilled: "taobinRefilled",
  taobinAdjusted: "taobinAdjusted",
  spiralRefilled: "spiralRefilled",
  spiralAdjusted: "spiralAdjusted",
  taobinMachinesInRoute: "taobinMachinesInRoute",
  spiralMachinesInRoute: "spiralMachinesInRoute",
  flag: "flag",
  download: "download",
  downloadReport: "downloadReport",
  checkbox: "checkbox",
} as const;
type Columns = keyof typeof columns;

const sortFnMap: Record<Columns, (planA: TableRow, planB: TableRow) => number> =
  {
    planName: (planA, planB) => {
      return alphanumeric(planA.planName, planB.planName);
    },
    warehouseName: (planA, planB) => {
      return planA.warehouseName.localeCompare(planB.warehouseName);
    },
    taobinRefilled: (planA, planB) => {
      let comparisonValue = planA.taobinRefilled - planB.taobinRefilled;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    taobinAdjusted: (planA, planB) => {
      let comparisonValue = planA.taobinAdjusted - planB.taobinAdjusted;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralRefilled: (planA, planB) => {
      let comparisonValue = planA.spiralRefilled - planB.spiralRefilled;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralAdjusted: (planA, planB) => {
      let comparisonValue = planA.spiralAdjusted - planB.spiralAdjusted;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    flag: (planA, planB) => {
      let comparisonValue = planA.flag - planB.flag;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    taobinMachinesInRoute: (planA, planB) => {
      let comparisonValue =
        planA.taobinMachinesInRoute - planB.taobinMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralMachinesInRoute: (planA, planB) => {
      let comparisonValue =
        planA.spiralMachinesInRoute - planB.spiralMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    checkbox: () => {
      throw new Error("Check box column not sortable");
    },
    download: () => {
      throw new Error("Download column not sortable");
    },
    downloadReport: () => {
      throw new Error("Download report column not sortable");
    },
  };

import * as React from "react";
import { View, Image } from "@react-pdf/renderer";
import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

interface OrganizationHeaderProps {
  orgInfo: {
    name: string;
    address: string | undefined;
    tel: string | undefined;
    taxId: string | undefined;
    img: string | undefined;
  };
}

export function OrganizationHeader({
  orgInfo
}: OrganizationHeaderProps): JSX.Element {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <View style={{ flexBasis: 60, minWidth: 0, marginRight: 8 }}>
        <View
          style={{
            height: 60,
            borderColor: COLORS.outline,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 4,
            overflow: 'hidden'
          }}
        >
          {orgInfo.img && (
            <Image
              source={{uri: orgInfo.img,method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 3
              }}
            />
          )}
        </View>
      </View>
      <View style={{ flexGrow: 1 }}>
        <Text
          style={{
            fontFamily: "Kanit-light",
            fontSize: 12,
            lineHeight: 20 / 12,
            letterSpacing: 0
          }}
        >
          {orgInfo.name}
        </Text>
        {orgInfo.address && (
          <Text
            style={{
              fontFamily: "Kanit-light",
              fontSize: 8,
              color: COLORS.onSurfaceMedium
            }}
          >
            {orgInfo.address}
          </Text>
        )}
        {orgInfo.tel && (
          <Text
            translate
            style={{
              fontFamily: "Kanit-light",
              fontSize: 8,
              color: COLORS.onSurfaceMedium
            }}
          >
            {"label_tel"} {orgInfo.tel}
          </Text>
        )}

        {orgInfo.taxId && (
          <Text
            translate
            style={{
              fontFamily: "Kanit-light",
              fontSize: 8,
              color: COLORS.onSurfaceMedium
            }}
          >
            {"label_tax_id"} {orgInfo.taxId}
          </Text>
        )}
      </View>
    </View>
  );
}

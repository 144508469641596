import * as React from "react";

import { Combobox, Popover } from "@headlessui/react";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import { CircularProgress } from "@mui/material";
import Icon from "common/components/icon/Icon";

export interface AutoCompleteSelectionCellProps<T> {
  options: Array<T>;
  value: Array<T>;
  areOptionsLoading?: boolean;
  isOptionLoading?: (option: T) => boolean;
  filterFn: (options: Array<T>, query: string) => Array<T>;
  onChange: ({ action, value }: { action: AutoCompleteSelectionCellAction; value: T }) => void;
  getOptionDisplay: (option: T) => string;
  getOptionId: (option: T) => string;
  compareFn: (a: T, b: T) => boolean;
}
export type AutoCompleteSelectionCellAction = "ADD" | "REMOVE";

export function AutoCompleteSelectionCell<T>(props: AutoCompleteSelectionCellProps<T>) {
  const { value, getOptionDisplay } = props;
  return (
    <Popover style={{ position: "relative", width: "100%", maxWidth: "117px" }}>
      <Popover.Button
        style={{ background: "transparent", border: "none", width: "100%", cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography type="body-2">
              {props.value.length === 0
                ? "-"
                : value.map((elem) => getOptionDisplay(elem)).join(", ")}
            </Typography>
          </div>
          <div
            style={{
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                border: "5px solid transparent",
                borderTopColor: "black",
                marginBottom: "-5px",
              }}
            ></div>
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel
        style={{
          position: "absolute",
          zIndex: 10,
          backgroundColor: "white",
          boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.2)",
          borderRadius: "4px",
          overflow: "hidden",
          maxHeight: "281px",
          width: "187px",
          left: "-29px",
          marginTop: "10px",
        }}
      >
        <AutoCompleteDropDown {...props} />
      </Popover.Panel>
    </Popover>
  );
}

function AutoCompleteDropDown<T>({
  options,
  value,
  onChange,
  isOptionLoading,
  filterFn,
  getOptionId,
  getOptionDisplay,
  compareFn,
  areOptionsLoading,
}: AutoCompleteSelectionCellProps<T>) {
  const [query, setQuery] = React.useState("");

  const filteredOptions = query === "" || areOptionsLoading ? options : filterFn(options, query);

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <Combobox
      value={value}
      onChange={(newValue) => {
        if (newValue.length > value.length) {
          const optionAdded = newValue.find((elem) => !value.includes(elem));
          onChange({ action: "ADD", value: optionAdded });
        } else {
          const optionRemoved = value.find((elem) => !newValue.includes(elem));
          onChange({ action: "REMOVE", value: optionRemoved });
        }
      }}
      multiple
      by={compareFn}
    >
      <div
        style={{
          padding: "9px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
        }}
      >
        <Combobox.Input
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          style={{
            padding: "5px 15px",
            fontFamily: "Kanit",
            fontWeight: 400,
            fontSize: "16px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
            boxSizing: "border-box",
            width: "100%",
          }}
          ref={inputRef}
          placeholder={"Search"}
        />

        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "0px",
            bottom: "0px",
            paddingRight: "19px",
            display: "grid",
            placeContent: "center",
            marginLeft: "14px",
          }}
        >
          {areOptionsLoading ? (
            <CircularProgress
              size={20}
              style={{
                color: "#4b4d9b",
              }}
            />
          ) : (
            <Icon name="Search" color="primary500" />
          )}
        </div>
      </div>

      {areOptionsLoading ? null : (
        <Combobox.Options
          static
          style={{
            listStyle: "none",
            padding: "0px",
            margin: "0px",
            maxHeight: "229px",
            overflow: "auto",
          }}
        >
          {filteredOptions.length === 0 && query !== "" ? (
            <div
              style={{
                padding: "8px 12px",
              }}
            >
              <Typography type="body-2" translate color="onSurfaceHigh">
                label_no_options
              </Typography>
            </div>
          ) : (
            filteredOptions.map((option) => (
              <Combobox.Option
                disabled={isOptionLoading(option)}
                value={option}
                key={getOptionId(option)}
              >
                {({ selected, active }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        padding: "8px 12px",
                        gap: "8px",
                        cursor: "pointer",
                        backgroundColor: isOptionLoading(option)
                          ? "rgba(52, 71, 173, 0.04)"
                          : active
                          ? "rgba(52, 71, 173, 0.22)"
                          : selected
                          ? "rgba(52, 71, 173, 0.12)"
                          : undefined,
                      }}
                    >
                      {isOptionLoading(option) ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: "#4b4d9b",
                          }}
                        />
                      ) : (
                        <Checkbox
                          checked={selected}
                          style={{ userSelect: "none" }}
                          onFocus={() => {
                            inputRef.current.focus();
                          }}
                        />
                      )}
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography type="body-2" color="onSurfaceHigh">
                          {getOptionDisplay(option)}
                        </Typography>
                      </div>
                    </div>
                  );
                }}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      )}
    </Combobox>
  );
}

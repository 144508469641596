import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { IUsers } from 'userandauth/interfacesUser';
import { getMachineUsers } from 'machines/service';
import { machineKeys } from './machineKeys';


function useGetAllMachineUsers (machineId:string, { enabled = true } = {}) {
  return useQuery<IUsers[], AxiosError>(
    machineKeys.machineUsers(machineId),
    () => getMachineUsers(machineId),
    {
      refetchInterval: 50000,
      retry: 0,
      enabled
    }
  );
}

export { useGetAllMachineUsers };
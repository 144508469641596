import { ElectricFee } from "containers/contract/hooks/useGetLocationFee";
import { ElectricFeeInput, ElectricityRateType } from "gql/generated";

export function mapRentFeeToElectricFeeInput (electricFee: ElectricFee): ElectricFeeInput {
  if(!electricFee) {
    throw new Error("electricFee can't be null");
  }
  if(electricFee.type === "FIXED") {
    return {
      type: ElectricityRateType.FixedRate,
      value: Number(electricFee.value)
    };
  }

  if(electricFee.type === "PAY_PER_USAGE") {
    return {
      type: ElectricityRateType.ActualUsage,
      value: Number(electricFee.value)
    };
  }
}

import {
  GetMaintenanceBoardPaperCupErrorDocument,
  GetMaintenanceBoardPaperCupErrorQuery,
  GetMaintenanceBoardPaperCupErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getPaperCups({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardPaperCupErrorQuery,
    GetMaintenanceBoardPaperCupErrorQueryVariables
  >(GetMaintenanceBoardPaperCupErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

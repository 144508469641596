// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LineGraphCard--FqnSAnNasW{display:flex;flex-direction:column}.LineGraphCard--FqnSAnNasW .CardAggregatesContainer--1PS07_MrGP{display:flex;margin-top:9px}.LineGraphCard--FqnSAnNasW .CardAggregatesContainer--1PS07_MrGP .CardAggregate--HZrfDlZViX{flex:1 0 auto}.LineGraphCard--FqnSAnNasW .LineGraph--3OvjRswGpF{margin-top:auto}", ""]);
// Exports
exports.locals = {
	"LineGraphCard": "LineGraphCard--FqnSAnNasW",
	"CardAggregatesContainer": "CardAggregatesContainer--1PS07_MrGP",
	"CardAggregate": "CardAggregate--HZrfDlZViX",
	"LineGraph": "LineGraph--3OvjRswGpF"
};
module.exports = exports;

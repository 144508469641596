/* eslint-disable no-unreachable */
import * as React from "react";
import { Package } from "../types";
import {
  IsReturnQuantityAbnormalReturnType,
} from "../components/ReturnLessThanExpected";

import { useTranslation } from "react-i18next";
import { AccordionWithStickyHeader } from "../components/AccordionWithStickyHeader";
import { TaobinInventoryListHeader, TaobinInventoryListView } from "./TaobinInventoryList";
import { SpiralInventoryListHeader, SpiralInventoryListView } from "./SpiralInventoryList";

export type FormProps = {
  packages: Array<Package>;
  onPackageActualReturnUnitChange: (p: Package, value: string) => void;
  onPackageActualReturnBagChange: (p: Package, value: string) => void;
  getPackageActualReturnBag: (p: Package) => string;
  getPackageActualReturnUnit: (p: Package) => string;
  isPackageActualReturnAbnormal: (
    p: Package
  ) => IsReturnQuantityAbnormalReturnType;
};

export function FormView({
  getPackageActualReturnBag,
  getPackageActualReturnUnit,
  onPackageActualReturnUnitChange,
  onPackageActualReturnBagChange,
  isPackageActualReturnAbnormal,
  packages,
}: FormProps) {
  const { t } = useTranslation();
  const { coffeePackages, spiralPackages } = React.useMemo(() => {
    return {
      spiralPackages: packages?.filter(p => p.type === "SPIRAL") ?? [],
      coffeePackages: packages?.filter(p => p.type === "COFFEE") ?? [],
    };
  }, [packages]);
  return (
    <div className="relative">
      <AccordionWithStickyHeader
        className="mb-px"
        title={t("label_complete_plan_taobin_package_header")}
        subTitle={
          <TaobinInventoryListHeader />
        }
        body={
          <TaobinInventoryListView
            packages={coffeePackages}
            getPackageActualReturnBag={getPackageActualReturnBag}
            getPackageActualReturnUnit={getPackageActualReturnUnit}
            onPackageActualReturnUnitChange={onPackageActualReturnUnitChange}
            onPackageActualReturnBagChange={onPackageActualReturnBagChange}
            isPackageActualReturnAbnormal={isPackageActualReturnAbnormal}
          />
        }
      />

      <AccordionWithStickyHeader
        title={t("label_complete_plan_spiral_package_header")}
        subTitle={
          <SpiralInventoryListHeader />
        }
        body={
          <SpiralInventoryListView
            packages={spiralPackages}
            getPackageActualReturnBag={getPackageActualReturnBag}
            onPackageActualReturnBagChange={onPackageActualReturnBagChange}
            isPackageActualReturnAbnormal={isPackageActualReturnAbnormal}
          />
        }
      />
    </div>
  );
}
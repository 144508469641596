import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

import * as styles from './SideNavButton.module.scss';

interface ISubNavigation {
  to: string;
  translationKey: string;
  icon: string;
  active: boolean;
}
export interface ISideNavButtonProps {
  to: string;
  translationKey?: string;
  icon: string;
  active: boolean;
  className?: (active: boolean) => string;
  subNavigation?: ISubNavigation[];
}

function SideNavButton({
  to,
  translationKey,
  icon,
  active,
  className,
  subNavigation,
}: ISideNavButtonProps): JSX.Element {
  const history = useHistory();
  const subNavNode = useRef(null);

  const [subNavOpen, setSubNavOpen] = useState<boolean>(false);

  useEffect(() => {
    function handleClick(e): void {
      if (!subNavNode.current?.contains(e.target)) {
        setSubNavOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  function handleSubNavClick(to: string): void {
    history.push(to);
    setTimeout(() => {
      setSubNavOpen(false);
    }, 100);
  }

  const commonBox = (
    <>
      <div
        className={[styles.selectedBar, active ? styles.selected : ''].join(
          ' '
        )}
      />
      <Icon name={icon} color={active ? 'surface' : 'onPrimaryMedium'} />
      {translationKey && (
        <Typography
          translationKey={translationKey}
          type="caption"
          className={styles.text}
        />
      )}
    </>
  );

  if (subNavigation) {
    return (
      <div
        className={[
          styles.SideNavButton,
          active || subNavOpen ? styles.active : '',
        ].join(' ')}
        onClick={() => setSubNavOpen((open) => !open)}
        ref={subNavNode}
      >
        <div
          className={[styles.SubNav, subNavOpen ? styles.open : ''].join(' ')}
        >
          {subNavigation.map((i, index) => {
            return (
              <Link 
                to={i.to}
                key={index}
                className={[
                  styles.option,
                  i.active ? styles.selected : '',
                ].join(' ')}
                onClick={() => handleSubNavClick(i.to)}>
                <Icon name={i.icon} color="onSurfaceHigh" />
                <Typography
                  translationKey={i.translationKey}
                  type="body-1"
                  className={styles.text}
                />
              </Link>
            );
          })}
        </div>
        {commonBox}
      </div>
    );
  }

  return (
    <Link
      className={[
        styles.SideNavButton,
        active ? styles.active : '',
        className?.(active) ?? '',
      ].join(' ')}
      to={to}
    >
      {commonBox}
    </Link>
  );
}

export default SideNavButton;

import * as React from "react";
import {
  contractRoutes,
  useContractIdUrlParams,
  useLocationIdUrlParams,
} from "containers/contract/routes";
import { Link } from "react-router-dom";
import { LocationIdAutoComplete } from "../../components/location/LocationIdAutoComplete";
import { ContractWorkSpace } from "../../components/ContractWorkSpace";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import { ContractLocationFee } from "containers/contract/hooks/useGetLocationFee";
import { useGetLocationFee } from "../../hooks/useGetLocationFee";

import {
  RentFeeForm,
  mapRentFeeToRentFeeFormValue,
} from "../../components/location/RentFee";
import {
  PaymentInformationForm,
  mapPaymentInfoToPaymentInfoFormValue,
} from "../../components/location/PaymentInformation";
import {
  ElectricFeeForm,
  mapElectricFeeToElectricFeeFormValue,
} from "../../components/location/ElectricFee";
import {
  InsuranceFeeForm,
  mapInsuranceFeeToInsuranceFeeFormValue,
} from "../../components/location/InsuranceFee";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";
import { AlertFeeChangeModal } from "./AlertFeeChangeModal";
import { useModifiedState } from "./useModifiedState";
import {
  EditLocationFeeFormValue,
  useEditLocationFee,
} from "./useEditLocationFee";

function EditLocationFee() {
  const { t } = useTranslation();
  const contractId = useContractIdUrlParams();
  const locationId = useLocationIdUrlParams();
  const getLocationFeeResult = useGetLocationFee({ contractId, locationId });
  const permissionResult = useCanAccessContractFeature();
  const isLoading =
    getLocationFeeResult.isLoading || permissionResult.isLoading;

  return (
    <ContractWorkSpace.ScreenWrapper>
      <ContractWorkSpace.PageTitle>
        {t("label_edit_location_fee_details")}
      </ContractWorkSpace.PageTitle>

      {isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <EditLocationFeeForm initialValue={getLocationFeeResult.data} />
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function EditLocationFeeForm({
  initialValue,
}: {
  initialValue: ContractLocationFee;
}) {
  const [locationId, setLocationId] = React.useState<string>(
    initialValue.location.id
  );

  const [rentFee, setRentFee, isRentFeeModified] = useModifiedState(
    mapRentFeeToRentFeeFormValue(initialValue.fees.rentFee.fee)
  );

  const [
    rentFeePaymentInfo,
    setRentFeePaymentInfo,
    isRentFeePaymentInfoModified,
  ] = useModifiedState(
    mapPaymentInfoToPaymentInfoFormValue(
      initialValue.fees.rentFee.paymentInformation
    )
  );

  const [electricFee, setElectricFee, isElectricFeeModified] = useModifiedState(
    mapElectricFeeToElectricFeeFormValue(initialValue.fees.electricFee.fee)
  );

  const [
    electricFeePaymentInfo,
    setElectricFeePaymentInfo,
    isElectricFeePaymentInfoModified,
  ] = useModifiedState(
    mapPaymentInfoToPaymentInfoFormValue(
      initialValue.fees.electricFee.paymentInformation
    )
  );

  const [insuranceFee, setInsuranceFee, isInsuranceFeeModified] =
    useModifiedState(
      mapInsuranceFeeToInsuranceFeeFormValue(initialValue.fees.insuranceFee.fee)
    );

  const [
    insuranceFeePaymentInfo,
    setInsuranceFeePaymentInfo,
    isInsuranceFeePaymentInfoModified,
  ] = useModifiedState(
    mapPaymentInfoToPaymentInfoFormValue(
      initialValue.fees.insuranceFee.paymentInformation
    )
  );

  const form: EditLocationFeeFormValue = {
    contractId: initialValue.contract.id,
    newLocationId: locationId,
    oldLocationId: initialValue.location.id,
    rentFee,
    rentFeePaymentInfo,
    electricFee,
    electricFeePaymentInfo,
    insuranceFee,
    insuranceFeePaymentInfo,
  };

  const editLocationFeeResult = useEditLocationFee({ form });

  function onSave() {
    if (
      isRentFeeModified ||
      isRentFeePaymentInfoModified ||
      isElectricFeeModified ||
      isElectricFeePaymentInfoModified ||
      isInsuranceFeeModified ||
      isInsuranceFeePaymentInfoModified
    ) {
      setIsAlertFeeChangeModalOpen(true);
    } else {
      editLocationFeeResult.submit();
    }
  }

  const [isAlertFeeChangeModalOpen, setIsAlertFeeChangeModalOpen] =
    React.useState(false);

  return (
    <>
      {isAlertFeeChangeModalOpen ? (
        <AlertFeeChangeModal
          form={form}
          fieldsModified={{
            rentFee: isRentFeeModified,
            rentPaymentInfo: isRentFeePaymentInfoModified,
            electricFee: isElectricFeeModified,
            electricPaymentInfo: isElectricFeePaymentInfoModified,
            insuranceFee: isInsuranceFeeModified,
            insurancePaymentInfo: isInsuranceFeePaymentInfoModified,
          }}
          onClose={() => {
            setIsAlertFeeChangeModalOpen(false);
          }}
        />
      ) : null}

      <ContractWorkSpace.MaxWidthContainer>
        <ContractWorkSpace.Spacer />
        <TextFieldViewOnly
          label={"label_contract"}
          value={initialValue.contract.friendlyId}
        />
        <LocationIdAutoComplete
          value={locationId}
          onChange={setLocationId}
          fallBackOptions={[
            {
              label:
                initialValue.location.friendlyId +
                " - " +
                initialValue.location.name,
              value: initialValue.location.id,
            },
          ]}
        />
        <ContractWorkSpace.Spacer />
        <RentFeeForm value={rentFee} onChange={setRentFee} />
        <ContractWorkSpace.Spacer />
        <PaymentInformationForm
          title="label_rent_payment_information"
          value={rentFeePaymentInfo}
          onChange={setRentFeePaymentInfo}
        />
        <ContractWorkSpace.Spacer />
        <ElectricFeeForm value={electricFee} onChange={setElectricFee} />
        <ContractWorkSpace.Spacer />
        <PaymentInformationForm
          title="label_electric_payment_information"
          value={electricFeePaymentInfo}
          onChange={setElectricFeePaymentInfo}
        />
        <ContractWorkSpace.Spacer />
        <InsuranceFeeForm value={insuranceFee} onChange={setInsuranceFee} />
        <ContractWorkSpace.Spacer />
        <PaymentInformationForm
          title="label_insurance_fee_payment_information"
          value={insuranceFeePaymentInfo}
          onChange={setInsuranceFeePaymentInfo}
        />

        <ContractWorkSpace.Spacer />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            to={contractRoutes.locationId(
              initialValue.contract.id,
              initialValue.location.id
            )}
            style={{
              pointerEvents: editLocationFeeResult.isLoading ? "none" : "auto",
            }}
          >
            <Button type="secondary" disabled={editLocationFeeResult.isLoading}>
              action_cancel
            </Button>
          </Link>
          <Button
            type="primary"
            onClick={onSave}
            loading={editLocationFeeResult.isLoading}
          >
            action_save
          </Button>
        </div>
      </ContractWorkSpace.MaxWidthContainer>
    </>
  );
}

export default EditLocationFee;

import * as React from "react";

import Typography from "components/Typography/Typography";
import { Modal, Title } from "containers/warehousing/components/ReusableModal";
import Button from "components/Button/Button";
import { toasti18n } from "utils/toast";
import { downloadXLSXFile } from "utils/xlsx";

import { httpClient } from "common/services/transportService";

export function DownloadSummaryModal(props: {
  fileName: string;
  onClose: () => void;
  warehouseId: string;
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  async function downloadSummary() {
    setIsLoading(true);
    try {
      await downloadXLSXFile(
        () => downloadSummaryXLSXBufferByWarehouseId(props.warehouseId),
        props.fileName
      );
      props.onClose();
      toasti18n.success();
    } catch {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "500px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_download_summary</Title>

      <div
        style={{
          marginTop: "25px",
        }}
      >
        <Typography type="subtitle-1" translate>
          label_download_summary_description
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "29px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button type="primary" onClick={downloadSummary} loading={isLoading}>
          action_download
        </Button>
      </div>
    </Modal>
  );
}

export async function downloadSummaryXLSXBufferByWarehouseId(
  warehouseId: string
): Promise<ArrayBuffer> {
  const response = await httpClient.get<ArrayBuffer>(
    `/warehouse/warehouseReport/${warehouseId}`,
    {
      responseType: "arraybuffer",
    }
  );
  return response.data;
}

import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import { QueryFunctionContext, UseQueryOptions, useQuery } from "react-query";

const userAuditLogKeys = {
  userAuditLog: (userId: string) =>
    [
      {
        scope: "user",
        innerScope: "userAuditLogs",
        userId,
        url: `/users/:userid/audit-logs`,
        resolvedUrl: `/users/${userId}/audit-logs`,
      },
    ] as const,
};

type UserAuditLogQueryKey = ReturnType<typeof userAuditLogKeys["userAuditLog"]>;

type UserAuditLogs = Array<{
  id: string;
  createdAt: string;
  userId: string;
  event: string;
  description: string;
  resourceType: string;
  resourceId: string;
}>;

async function getUserAuditLogsByUserId(
  context: QueryFunctionContext<UserAuditLogQueryKey>
) {
  const [{ userId }] = context.queryKey;
  const response = await httpClient.get<UserAuditLogs>(
    `/users/${userId}/audit-logs`
  );
  return response.data;
}

type GetUserAuditLogsByUserIdQueryOptions = Omit<
  UseQueryOptions<
    UserAuditLogs,
    AxiosError,
    UserAuditLogs,
    UserAuditLogQueryKey
  >,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetUserAuditLogsByUserIdBase({
  userId,
  options = {},
}: {
  userId: string;
  options?: GetUserAuditLogsByUserIdQueryOptions;
}) {
  return useQuery({
    queryKey: userAuditLogKeys.userAuditLog(userId),
    queryFn: getUserAuditLogsByUserId,
    staleTime: 0,
    ...options,
  });
}

export function useGetUserAuditLogsByUserId(
  userId: string,
  options?: GetUserAuditLogsByUserIdQueryOptions
) {
  return useGetUserAuditLogsByUserIdBase({
    userId,
    options,
  });
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InviteMemberModal--3ZTQfrt2yX{display:flex;flex-direction:column;min-height:180px}.InviteMemberModal--3ZTQfrt2yX .body--y4ULyF_QYZ>*{margin-top:20px}.InviteMemberModal--3ZTQfrt2yX .checkboxContainer--3NFBe-BSKT{margin-top:15px;margin-bottom:10px}.InviteMemberModal--3ZTQfrt2yX .action--2FDBd_k1yY{display:flex;justify-content:space-between;min-width:400px;margin-top:auto}.modal--1G3WCsBmJl{overflow:unset !important}", ""]);
// Exports
exports.locals = {
	"InviteMemberModal": "InviteMemberModal--3ZTQfrt2yX",
	"body": "body--y4ULyF_QYZ",
	"checkboxContainer": "checkboxContainer--3NFBe-BSKT",
	"action": "action--2FDBd_k1yY",
	"modal": "modal--1G3WCsBmJl"
};
module.exports = exports;

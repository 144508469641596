
// Hooks
import { useQuery } from 'react-query';

// Service function
import { getMachineModuleStatus } from 'machines/service';

// Types
import { IMachineModuleStatus } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';

function useGetMachineModuleStatus (machineId: string, {enabled = true} = {}) {
  return useQuery<IMachineModuleStatus, AxiosError>(
    machineKeys.machineStatus(machineId),
    () => getMachineModuleStatus(machineId),
    {
      enabled: enabled,
    }
  );
}

export { useGetMachineModuleStatus };

import { getMachineTicketsXLSX } from "machines/service";
import { toasti18n } from "utils/toast";
import { downloadXLSXFile } from "utils/xlsx";

function downloadTicketsXLSX() {
  const downloadPromise = downloadXLSXFile(
    getMachineTicketsXLSX,
    "Machine Tickets " + new Date().toLocaleDateString()
  );
  toasti18n.promise(downloadPromise);
}

export default downloadTicketsXLSX;

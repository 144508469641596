import {
  GetMaintenanceBoardMilkErrorDocument,
  GetMaintenanceBoardMilkErrorQuery,
  GetMaintenanceBoardMilkErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getMilkError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardMilkErrorQuery,
    GetMaintenanceBoardMilkErrorQueryVariables
  >(GetMaintenanceBoardMilkErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

import { clsx } from 'clsx';
import { Column } from "@tanstack/react-table";

type GetCommonPinningStylesProps<T> = {
  column: Column<T>;
  totalPinnedLeft: number;
}

export function getCommonPinningStyles<T>({ column, totalPinnedLeft }: GetCommonPinningStylesProps<T>) {
  const isPinned = column.getIsPinned();
  const pinnedIndex = column.getPinnedIndex();
  const isLastPinnedLeft = isPinned === 'left' && pinnedIndex === totalPinnedLeft - 1;


  const borderWidth = column.getPinnedIndex() === 0 ? 0: 2;
  return {
    className: clsx(
      isPinned && 'sticky z-[1] opacity-95',
      !isPinned && 'relative opacity-100 ',
      isPinned === 'left' && 'left-0',
      isPinned === 'right' && 'right-0',
      isLastPinnedLeft && "!border-r"
    ),
    style: {
      left: isPinned === 'left' ? `${column.getStart('left') + borderWidth}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right') + borderWidth}px` : undefined,
      // width: column.getSize()+20,
    },
  };
}

import { httpClient } from 'common/services/transportService';

export async function getSlotUsageCSV({
  selectedMachine,
  from,
  to,
}: ISlotUsage): Promise<string> {
  const response = await httpClient.post<string>(
    '/material/usage/',
    {
      selectedMachine,
      from,
      to,
    },
    { responseType: 'text' }
  );
  return response.data;
}

interface ISlotUsage {
  selectedMachine: string[];
  from: string;
  to: string;
}

import { orderBy } from 'lodash';

import { IBinaryState, IPosition } from '../BinaryStatus';

function getPositionData (
  data: IBinaryState[],
  from: number,
  to: number
): IPosition[] {
  const now = Date.now();
  const firstTimestamp = from;
  const lastTimestamp = to;
  const range = lastTimestamp - firstTimestamp;

  const orderedData = orderBy(data, ['timestamp'], ['asc']);

  const withSynthetics: IBinaryState[] = [
    { timestamp: from, state: 2 },
    ...orderedData
  ];
  if (now < lastTimestamp) {
    withSynthetics.push({ timestamp: now, state: 2 });
  }
  withSynthetics.push({ timestamp: to, state: 2 });

  const filteredData = withSynthetics.filter((i, index) => {
    if (index === 0 || index === withSynthetics.length - 1) {
      return true;
    }
    const previousDataPoint = withSynthetics[index - 1];
    if (previousDataPoint.state === i.state) {
      return false;
    }
    return true;
  });

  const positions: IPosition[] = filteredData
    .map((i, index) => {
      const nextDataPoint = filteredData[index + 1];

      const startPosition = (i.timestamp - firstTimestamp) / range;
      const endPosition = nextDataPoint
        ? (nextDataPoint.timestamp - firstTimestamp) / range
        : 1;
      const renderWidth = Math.max(1, Math.round(endPosition * 100) - Math.round(startPosition * 100));

      const timeToNextDataPoint = nextDataPoint
        ? nextDataPoint.timestamp - i.timestamp
        : now - i.timestamp;

      return {
        ...i,
        renderWidth,
        timeToNextDataPoint,
        nextTimestamp: nextDataPoint ? nextDataPoint.timestamp : now
      };
    })
    .slice(0, -1);

  return positions;
}

export default getPositionData;

import * as React from 'react';

interface TableContextType {
  activeInPlaceFilterColumnId: null | string;
  setActiveInPlaceFilterColumnId: (state: string | null) => void;
}


export const TableContext = React.createContext<TableContextType | undefined>(undefined);

interface TableContextProviderProps {
  children: React.ReactNode;
}

export const useTableContext = (): TableContextType => {
  const context = React.useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableContextProvider');
  }
  return context;
};

export const TableContextProvider = ({ children }: TableContextProviderProps) => {
  const [activeInPlaceFilterColumnId, setActiveInPlaceFilterColumnId] = React.useState<string | null>(null);


  return (
    <TableContext.Provider value={{ activeInPlaceFilterColumnId, setActiveInPlaceFilterColumnId }}>
      {children}
    </TableContext.Provider>
  );
};

import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import IndexTransaction from "./IndexTransaction/IndexTransaction";
import Transactions from "./Transactions";
import { TransactionGroupByError } from "./groupByError";

export function TransactionRoute() {
  return (
    <Switch>
      <Route path="/transactions/group-by-none" component={IndexTransaction} />
      <Route path="/transactions/group-by-error" component={TransactionGroupByError} />

      <Route path="/transactions/" component={Transactions} />

      <Redirect to="/transactions/group-by-none" />
    </Switch>
  );
}

import * as React from "react";

import Typography from "components/Typography";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import TabButton from "components/TabButton";

import RefillRoute from "./refillroute/RefillRoute";
import Plan from "./plan/Plan";
import Kitting from "./kitting/Kitting";
import PreKits from "./pre-kits/PreKits";
import Enroute from "./enroute/Enroute";
import Complete from "./complete/Complete";
import { ErrorBoundary } from "react-error-boundary";
import { useGetOperationStatusCountsQuery } from "gql/generated";

import * as styles from "./RefillOrder.module.scss";
import { FallBackWrapper } from "../Components";
import { DownloadInventorySummaryReportButton } from "./DownloadInventorySummaryReport";

export function RefillOrder(): JSX.Element {
  const { data } = useGetOperationStatusCountsQuery();
  const tabNumbers = {
    route: data?.operationStatusCounts.routes,
    plan: data?.operationStatusCounts.candidates,
    kitting: data?.operationStatusCounts.plans,
    prekits: data?.operationStatusCounts.kitted,
    enroute: data?.operationStatusCounts.en_route,
    adjustment: data?.operationStatusCounts.adjusted,
  };

  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeadingContainer}>
          <Typography type="headline-5" translate>
            {"workspace_operations"}
          </Typography>
          <Link to="/new-operations/refillorder" className={styles.ActiveTab}>
            <Typography type="body-2" translate>
              label_refill_order
            </Typography>
          </Link>
          <Link to="/new-operations/archive" className={styles.InactiveTab}>
            <Typography type="body-2" translate>
              label_archive
            </Typography>
          </Link>
          <div
            style={{
              marginLeft: "auto",
              display: "grid",
              placeContent: "center",
            }}
          >
            <DownloadInventorySummaryReportButton />
          </div>
        </div>

        <div className={styles.TabButtonsContainer}>
          <TabButton
            to="/new-operations/refillorder/route"
            type="primary"
            number={tabNumbers.route}
          >
            label_route
          </TabButton>
          <TabButton
            to="/new-operations/refillorder/plan"
            type="primary"
            number={tabNumbers.plan}
          >
            label_plan
          </TabButton>
          <TabButton
            to="/new-operations/refillorder/kitting"
            type="primary"
            number={tabNumbers.kitting}
          >
            label_kitting
          </TabButton>
          <TabButton
            to="/new-operations/refillorder/pre-kits"
            type="primary"
            number={tabNumbers.prekits}
          >
            label_pre_kits
          </TabButton>
          <TabButton
            to="/new-operations/refillorder/enroute"
            type="primary"
            number={tabNumbers.enroute}
          >
            label_enroute
          </TabButton>
          <TabButton
            to="/new-operations/refillorder/complete"
            type="primary"
            alertNumber={tabNumbers.adjustment}
          >
            label_complete
          </TabButton>
        </div>
      </div>

      <Switch>
        <Route path="/new-operations/refillorder/route">
          <ErrorBoundary FallbackComponent={FallBackWrapper} key="route">
            <RefillRoute />
          </ErrorBoundary>
        </Route>
        <Route path="/new-operations/refillorder/plan">
          <ErrorBoundary FallbackComponent={FallBackWrapper} key="plan">
            <Plan />
          </ErrorBoundary>
        </Route>
        <Route path="/new-operations/refillorder/kitting">
          <ErrorBoundary FallbackComponent={FallBackWrapper} key="kitting">
            <Kitting />
          </ErrorBoundary>
        </Route>
        <Route path="/new-operations/refillorder/pre-kits">
          <ErrorBoundary FallbackComponent={FallBackWrapper} key="pre-kits">
            <PreKits />
          </ErrorBoundary>
        </Route>
        <Route path="/new-operations/refillorder/enroute" key="enroute">
          <ErrorBoundary FallbackComponent={FallBackWrapper}>
            <Enroute />
          </ErrorBoundary>
        </Route>
        <Route path="/new-operations/refillorder/complete" key="complete">
          <ErrorBoundary FallbackComponent={FallBackWrapper}>
            <Complete />
          </ErrorBoundary>
        </Route>
        <Redirect to="/new-operations/refillorder/route" />
      </Switch>
    </>
  );
}

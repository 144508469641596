import { AxiosError } from "axios";

import { getMachineMaintenanceComponent, getMachineMaintenanceSlot } from "machines/service";

import { useQuery } from "react-query";

import { machineKeys } from "./machineKeys";

function useGetMachineMaintenance(
  machineId: string,
  { enabled = true } = {}
) {
  return useQuery<UseGetMachineMaintenanceTransformedData, AxiosError>(
    machineKeys.machineMaintenance(machineId),
    async () => {
      const [slots, components] = await Promise.all([
        getMachineMaintenanceSlot(machineId),
        getMachineMaintenanceComponent(machineId)
      ]);
      
      const transformedSlots : UseGetMachineMaintenanceTransformedData = slots.map(slot => ({
        name: slot.slot,
        nextMaintainDateTimeStamp: slot.nextMaintainDate,
        lastMaintainDateTimeStamp: slot.lastMaintainDate,
        maintenancePeriodInDays: slot.maintenancePeriod,
        type: 'slot'
      }));
      const transformedComponents :  UseGetMachineMaintenanceTransformedData = components.map(component => ({
        name: component.name,
        nextMaintainDateTimeStamp: component.nextMaintainDate,
        lastMaintainDateTimeStamp: component.lastMaintainDate,
        maintenancePeriodInDays: component.maintenancePeriod,
        type: 'component'
      }));
      return [...transformedSlots, ...transformedComponents];
    },
    { enabled: enabled, retry: 0, staleTime: 10 * 60 & 1000 }
  );
}

export type UseGetMachineMaintenanceTransformedData = Array<{
  name: string,
  maintenancePeriodInDays: number | null,
  lastMaintainDateTimeStamp: string | null,
  nextMaintainDateTimeStamp: string | null
  type: 'slot' | 'component'
}>

export { useGetMachineMaintenance };

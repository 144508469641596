import * as React from "react";
import { Skeleton } from "@mui/material";
import { warehouseBorderStyle } from "./borderStyle";

export function InventoryImage({
  imgUrl,
  isLoading = false,
  width = "76px",
  height = "76px",
}: {
  imgUrl?: string | null;
  isLoading?: boolean;
  width?: string | number;
  height?: string | number;
}) {
  return (
    <div
      style={{
        width,
        height,
        overflow: "hidden",
        border: warehouseBorderStyle,
        borderRadius: "4px",
        flexShrink: 0,
      }}
    >
      {isLoading ? (
        <Skeleton
          height={height}
          width={width}
          style={{ transform: "scale(1,1)" }}
        />
      ) : imgUrl ? (
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={imgUrl}
        />
      ) : (
        <div
          style={{
            backgroundColor: "#F3F3FD",
            width: "100%",
            height: "100%",
            placeContent: "center",
            display: "grid",
          }}
        >
          <PlaceHolderInventoryImageIcon />
        </div>
      )}
    </div>
  );
}

function PlaceHolderInventoryImageIcon({
  width = "44",
  height = "44",
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7623 18.2815L34.4014 3.15651C34.2334 2.37921 33.5401 1.83301 32.7628 1.83301H12.5961C12.1548 1.83301 11.7139 2.02195 11.3988 2.31613L6.3571 7.35779C5.32778 8.40804 6.08384 10.2359 7.55443 10.2359H9.38197L7.59659 18.2815C7.57536 18.3864 7.55443 18.5126 7.55443 18.6385V40.4859C7.55443 41.41 8.31081 42.1663 9.23489 42.1663H29.4016C29.6539 42.1663 29.9268 42.1033 30.1579 41.9983L32.7628 40.6958L35.3676 41.9983C36.46 42.5236 37.7835 41.7251 37.8044 40.4859V18.6385C37.8044 18.5126 37.7835 18.3864 37.7623 18.2815ZM22.5745 5.19422H28.7086L27.0278 6.87467H22.2594C22.3853 6.32847 22.4905 5.76135 22.5745 5.19422ZM19.1711 5.19422C19.0662 5.76135 18.9403 6.32847 18.7933 6.87467H13.5623L13.9405 5.19422H19.1711ZM17.6378 10.2359V15.2776H20.999V10.6138C21.0408 10.5088 21.1039 10.383 21.167 10.2359H27.7211C28.6664 10.2359 29.2757 9.39551 29.8637 8.78622L28.0571 16.958H11.3357L12.8272 10.2359H17.6378ZM10.9156 20.3192H27.7211V38.8051H10.9156V20.3192ZM34.4432 37.7758L33.5191 37.3136C33.0357 37.0825 32.4898 37.0825 32.0064 37.3136L31.0823 37.7758V18.8277L32.7628 11.2652L34.4432 18.8277V37.7758Z"
        fill="#CBCCE6"
      />
      <path
        d="M19.3182 23.6797C16.5244 23.6797 14.2765 25.9276 14.2765 28.7214C14.2765 31.5154 16.5244 33.763 19.3182 33.763C22.1122 33.763 24.3598 31.5154 24.3598 28.7214C24.3598 25.9485 22.0913 23.6797 19.3182 23.6797ZM19.3182 30.4021C18.3941 30.4021 17.6377 29.6457 17.6377 28.7214C17.6377 27.7973 18.3941 27.0409 19.3182 27.0409C20.2425 27.0409 20.9989 27.7973 20.9989 28.7214C20.9989 29.6457 20.2425 30.4021 19.3182 30.4021Z"
        fill="#CBCCE6"
      />
    </svg>
  );
}

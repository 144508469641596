import * as React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
  Redirect,
} from "react-router-dom";
import { OverviewPage } from "./pages/overview/Overview";
import SnapshotPage from "./pages/snapshot/Snapshot";
import SpecificErrorPage from "./pages/specificerror/SpecificError";
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";
import { HeaderCTA } from "containers/machinesV2/components/HeaderCTA";
import analytics from "utils/analytics";

const tabs = [
  {
    label: "label_overview",
    path: "/machines/monitor/overview",
  },
  {
    label: "label_snapshot",
    path: "/machines/monitor/snapshot",
  }
];

export function OverviewLayout() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <div>
      <HeaderCTA />
      <div className="flex px-2">
        {tabs.map((tab) => {
          const isActive = location.pathname.includes(tab.path);
          return (
            <Link
              key={tab.path}
              to={tab.path}
              onClick={() => {
                analytics.track({
                  name: "machine monitor page tab click",
                  properties: { label: tab.label, path: tab.path }
                });
              }}
              className={clsx(
                "px-2 h-6 min-w-[80px] justify-center flex items-center font-kanit text-body2",
                isActive
                  ? "text-primary-500 font-medium border-b-2 border-t-0 border-x-0 border-solid border-primary-500"
                  : "text-primary-300"
              )}
            >
              {t(tab.label)}
            </Link>
          );
        })}
      </div>
      <Switch>
        <Route exact path={`${path}/overview`}>
          <OverviewPage />
        </Route>
        <Route exact path={`${path}/snapshot`}>
          <SnapshotPage />
        </Route>
        <Route exact path={`${path}/error/:errortype`}>
          <div>
            <SpecificErrorPage />
          </div>
        </Route>
        <Redirect to={`${path}/overview`} />
      </Switch>
    </div>
  );
}

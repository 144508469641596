import * as React from "react";
import { Column } from "@tanstack/react-table";
import { clsx } from "clsx";

type SelectBadgeProps<T> = {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (selectedValues: string[]) => void;
};

type handleSelectBadgeChangeType = {
  isCheck: boolean;
  value: string;
};

export function SelectBadge<T>({
  column,
  className,
  onChange,
}: SelectBadgeProps<T>) {
  const filterValue = (column.getFilterValue() as string[]) || [];

  const handleCheckboxChange = ({
    value,
    isCheck,
  }: handleSelectBadgeChangeType) => {
    let newFilterValue: string[];

    if (!isCheck) {
      newFilterValue = filterValue.filter((item) => item !== value);
    } else {
      newFilterValue = [...filterValue, value];
    }

    // remove duplicate
    newFilterValue = [...new Set(newFilterValue)];

    if (newFilterValue.length === 0) {
      newFilterValue = undefined;
    }

    column.setFilterValue(newFilterValue);
    onChange?.(newFilterValue);
  };

  const renderOptions = () => {
    const columnFilter = column.columnDef.meta?.columnFilter;
    if (columnFilter?.type !== "selectBadge") return null;
    const options = columnFilter?.options;
    return options.map((option) => {
      const isChecked = filterValue.includes(option.value);

      return (
        <button type="button" onClick={() => handleCheckboxChange({ isCheck: !isChecked, value: option.value })} key={option.value} className={clsx("border border-solid px-2 py-1.5 rounded-[4px] cursor-pointer", isChecked ? "border-navigation bg-background": "border-outline bg-white")}>
          {option.label}
        </button>
      );
    });
  };

  return (
    <div className={clsx("w-full grid grid-cols-3 gap-2", className)}>
      {renderOptions()}
    </div>
  );
}

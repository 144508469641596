import * as React from "react";
import { useGetProductsByWarehouseIdQuery } from "gql/generated";

/**
 * Returns lookup table with productId as keys as totalQuantity as values
 */
export function useGetWarehouseProducts(warehouseId: string) {
  const { data: productsQuery, ...rest } = useGetProductsByWarehouseIdQuery(
    { warehouseId: warehouseId },
    { cacheTime: 0, enabled: Boolean(warehouseId) }
  );
  const warehouseProducts = React.useMemo(() => {
    return productsQuery
      ? Object.fromEntries(
          productsQuery.masterProducts.map((product) => [
            product.id,
            product.totalQuantity,
          ])
        )
      : undefined;
  }, [productsQuery]);
  return { data: warehouseProducts, ...rest };
}

export type WarehouseProducts = ReturnType<
  typeof useGetWarehouseProducts
>["data"];

import * as React from "react";
import * as locationCardDisabledStyles from "./LocationCardDisabled.module.scss";
import { LocationCard } from "../../components/contract/LocationCard";


export function LocationCardDisabled({
  locationFriendlyId, machineId,
}: {
  locationFriendlyId: string;
  machineId: string | null;
}) {
  return (
    <LocationCard
      locationFriendlyId={locationFriendlyId}
      machineId={machineId}
      className={locationCardDisabledStyles.Disabled} />
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EllipsisIcon--1wIfQruLuq{box-sizing:border-box;display:flex;align-items:center;justify-content:center;padding:10px 3px;cursor:pointer;outline:none;user-select:none}", ""]);
// Exports
exports.locals = {
	"EllipsisIcon": "EllipsisIcon--1wIfQruLuq"
};
module.exports = exports;

import * as React from "react";
import { Column } from "@tanstack/react-table";
import { clsx } from "clsx";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput } from "@mui/material";

export function DebouncedInputFilter<T>({
  column,
  className,
  onChange,
}: {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (e: string) => void;
}) {
  const filterValue = column.getFilterValue() as string;
  const label = column.columnDef.meta?.columnFilter?.label;
  return (
    <FormControl className="w-full" variant="outlined">
      {label && <InputLabel className="!font-kanit !font-light !text-on-surface-high" htmlFor={column.id} size="small">{label}</InputLabel>}
      <OutlinedInput
        size="small"
        id={column.id}
        className={clsx("!font-kanit", className)}
        label={label}
        value={filterValue || ""}
        onChange={(e) => {
          column.setFilterValue(e.target.value);
          onChange?.(e.target.value);
        }}
      />
    </FormControl>
  );
}

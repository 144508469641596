import { AxiosError } from 'axios';

import { useMutation, useQueryClient } from 'react-query';
import { deleteUser } from 'userandauth/servicesUser';
import { userKeys } from './userKeys';
import { toasti18n } from 'utils/toast';

function useDeleteUser () {
    const queryClient = useQueryClient();
    // use mutation , return value , error , var
    return useMutation<string, AxiosError, string>(
        (userId:string) => deleteUser(userId),{
            onSuccess:() => {
                toasti18n.success("toast_user_delete_success");
                queryClient.invalidateQueries(userKeys.all());
            }
        }
    );
}

export { useDeleteUser };
import * as React from 'react';

import {ResponsiveModal, Title} from 'components/ResponsiveModal';
import Icon from "common/components/icon/Icon";
import IconButton from '@mui/material/IconButton/IconButton';

import * as styles from "./Details.module.scss";
import Button from 'common/components/button/Button';
import Typography from 'components/Typography';


export function EnableWorkOrderCreationInfoModalButton({ isEditView, locationId, machineId, value }: { locationId: string, machineId: string, isEditView: boolean, value: boolean }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <IconButton
        aria-label="More info"
        onClick={() => setIsOpen(true)}
        className={styles.IconButton}
        data-tracking-event="work order creation tooltip clicked"
        data-tracking-property-click-at-work-order-toggle={isEditView ? "edit machine details": "machine details"}
        data-tracking-property-location-id={locationId}
        data-tracking-property-machine-id={machineId}
        data-tracking-property-value={value}
      >
        <Icon
          name="QuestionCircle"
          color="primary500"
        />
      </IconButton>
      {isOpen ? (
        <EnableWorkOrderCreationInfoModal onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}
function EnableWorkOrderCreationInfoModal({
  onClose
}: {
  onClose: () => void
}) {
  return (
    <ResponsiveModal
      onClose={onClose}
      open={true}
      modalStyles={{
        maxWidth: '500px',
        alignment: 'center',
        heightStyles: {}
      }}
    >
      <Title>label_enable_work_order_creation_info_topic</Title>
      <div>
        <ul>
          <li>
            <Typography type='body-1' translate>
              label_enable_work_order_creation_info_item_1
            </Typography>
          </li>
          <li>
            <Typography type='body-1' translate>
              label_enable_work_order_creation_info_item_2
            </Typography>
          </li>
        </ul>
      </div>
      <Button
        translationKey='label_close'
        onClick={onClose}
        type='primary'
        className='dialogCloseButton'
      />
    </ResponsiveModal>
  );
}
import * as React from "react";
import { Column } from "@tanstack/react-table";
import { SelectFilter } from "./SelectFilter";
import { AutocompleteFilter } from "./AutocompleteFilter";
import { DebouncedInputFilter } from "./DebouncedInputFilter";
import { FilterLocationType } from "components/tableV2/types/columnMetaTypes";
import { tableV2Tracking } from "components/tableV2/tracking";
import { MinMaxInputFilter } from "./MinMaxFilter";
import { CheckboxFilter } from "./CheckboxFilter";
import { SelectBadge } from "./SelectBadge";

export function ColumnFilterInput<T>({
  column,
  className,
  location,
  tableName,
}: {
  column: Column<T, unknown>;
  className?: string;
  location: FilterLocationType;
  tableName: string;
}) {
  const filterType = column.columnDef.meta?.columnFilter?.type;

  switch (filterType) {
    case "select":
      return (
        <SelectFilter
          column={column}
          className={className}
          onChange={(e) =>
            tableV2Tracking({
              name: "select filter on change",
              properties: {
                value: e.target.value,
                column_id: column.id,
                location,
              },
              tableName,
            })
          }
        />
      );
    case "autocomplete":
      return (
        <AutocompleteFilter
          column={column}
          className={className}
          onChange={(val) =>
            tableV2Tracking({
              name: "autocomplete filter on change",
              properties: {
                value: val,
                column_id: column.id,
                location,
              },
              tableName,
            })
          }
        />
      );
    case "minmax":
      return (
        <MinMaxInputFilter
          column={column}
          className={className}
          onChange={(val) =>
            tableV2Tracking({
              name: "minmax filter on change",
              properties: {
                value: val,
                column_id: column.id,
                location,
              },
              tableName,
            })
          }
        />
      );

    case "checkbox":
      return (
        <CheckboxFilter
          column={column}
          className={className}
          onChange={(val) =>
            tableV2Tracking({
              name: "checkbox filter on change",
              properties: {
                value: val,
                column_id: column.id,
                location,
              },
              tableName,
            })
          }
        />
      );
      case "selectBadge":
        return (
          <SelectBadge
            column={column}
            className={className}
            onChange={(val) =>
              tableV2Tracking({
                name: "selectBadge filter on change",
                properties: {
                  value: val,
                  column_id: column.id,
                  location,
                },
                tableName,
              })
            }
          />
        );
    default:
      return (
        <DebouncedInputFilter
          column={column}
          className={className}
          onChange={(val) =>
            tableV2Tracking({
              name: "text filter on change",
              properties: {
                value: val,
                column_id: column.id,
                location,
              },
              tableName,
            })
          }
        />
      );
  }
}

import * as React from "react";

import { Dialog, IconButton } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";
import { Link } from "react-router-dom";
import CheckboxWithLabel from "components/CheckboxWithLabel";
import { Note } from "./components/Note";
import LoadingScreen from "components/LoadingScreen";

import { useGetTicketByIdQuery } from "gql/generated";
import { PRIORITY_ICON_LOOKUP } from "./constants";

import * as styles from "./ClosedTicketModal.module.scss";

import * as moment from "moment";

interface IClosedTicketProps {
  ticketId: string | null;
  onClose: () => void;
}

export default function ClosedTicketModal({
  ticketId,
  onClose,
}: IClosedTicketProps): JSX.Element {
  const { data, isLoading } = useGetTicketByIdQuery(
    { ticketId: ticketId },
    { enabled: ticketId !== null, cacheTime: 0 }
  );
  const ticket = data?.ticket;

  return (
    <Dialog
      open={ticketId !== null}
      onClose={onClose}
      PaperProps={{
        className: styles.Paper,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={styles.IconButton}
      >
        <Icon name="Close" color="onSurfaceHigh" />
      </IconButton>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div>
            <Typography translate type="headline-6">
              label_ticket
              {` ${ticket.ticketId}`}
            </Typography>
          </div>

          <div className={styles.InfoOuterContainer}>
            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_machine_id
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Link
                  to={`/machines/detail/${ticket.machine.id}/inventory`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.Link}
                >
                  <Typography type="subtitle-1" color="primary500">
                    {ticket.machine.id}
                  </Typography>
                </Link>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_machine_name
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Typography type="subtitle-1">{ticket.machine.name}</Typography>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_service_zone
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Typography type="subtitle-1">
                  {ticket.machine.serviceZone}
                </Typography>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_priority
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <div className={styles.PriorityFieldValue}>
                  <div className={styles.IconContainer}>
                    <Icon name={PRIORITY_ICON_LOOKUP[ticket.priority].icon} />
                  </div>
                  <Typography type="subtitle-1" translate>
                    {PRIORITY_ICON_LOOKUP[ticket.priority].label}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_technician
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Typography type="subtitle-1">{ticket.assignedTo}</Typography>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  status_error
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                {ticket.issues.map((issue) => (
                  <div key={issue.id} className={styles.Error}>
                    <CheckboxWithLabel
                      checked={issue.done}
                      disabled={true}
                      label={
                        <div key={issue.id} className={styles.ErrorText}>
                          <Typography type="subtitle-1">
                            {issue.description.split("(").join(" (")}
                          </Typography>
                          <Typography
                            type="body-3"
                            color="onSurfaceDisabled"
                            className={styles.Date}
                          >
                            {" "}
                            {moment(Number(issue.createdAt)).format(
                              "MM/DD/YY HH:mm"
                            )}
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  label_note
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                {ticket.notes
                  .sort((a, b) => Number(b.createdAt) - Number(a.createdAt))
                  .map((note) => (
                    <div key={note.id} className={styles.Note}>
                      <Note
                        name={note.createdBy.name}
                        imgUrl={note.createdBy.userAvatar}
                        type={
                          note.type as
                            | "created"
                            | "in_progress"
                            | "blocked"
                            | "awaiting_approval"
                            | "follow_up"
                            | "closed"
                        }
                        description={note.description}
                        timestamp={note.createdAt}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InviteMemberModal--2_qPamITbR{display:flex;flex-direction:column;min-height:280px}.InviteMemberModal--2_qPamITbR .body--sHTW_2OxfU>*{margin-top:20px;margin-bottom:20px}.InviteMemberModal--2_qPamITbR .action--1pfhw_KOQ7{display:flex;justify-content:space-between;min-width:400px;margin-top:auto}.modal--1OHoSi_hls{overflow:unset !important}", ""]);
// Exports
exports.locals = {
	"InviteMemberModal": "InviteMemberModal--2_qPamITbR",
	"body": "body--sHTW_2OxfU",
	"action": "action--1pfhw_KOQ7",
	"modal": "modal--1OHoSi_hls"
};
module.exports = exports;

import * as React from "react";

import ReactPDF, { Text as ReactPDFText } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

/*
Text is block element by default in react pdf and does not adhere strictly to CSS standards

Usage
<Text>
  {swapSheetData.orderNumber}
</Text>

<Text translate style={{fontFamily: 'Kanit-medium'}}>
  {true && 'label_machines'}
  {false && 'label_operation'}
  {true && 'action_cancel'}
</Text>

<Text translate style={{fontFamily: 'Kanit-medium'}}>
  action_cancel
</Text>
*/
interface TextProps extends ReactPDF.TextProps {
  translate?: boolean;
  breakWordAnywhere?: boolean;
  children?: React.ReactNode;
}

export function Text({
  translate = false,
  breakWordAnywhere = false,
  children,
  ...rest
}: TextProps) {
  const { t } = useTranslation();

  if (translate === false) {
    return (
      <ReactPDFText {...rest}>
        {breakWordAnywhere ? insertSoftHyphen(children) : children}
      </ReactPDFText>
    );
  }

  function recursiveTranslate(children: React.ReactNode) {
    if (typeof children === "string" && children.includes("_")) {
      return t(children);
    }
    if (Array.isArray(children)) {
      return children.map((child) => {
        return recursiveTranslate(child);
      });
    }
    return children;
  }

  return (
    <ReactPDFText {...rest}>
      {breakWordAnywhere
        ? insertSoftHyphen(recursiveTranslate(children))
        : recursiveTranslate(children)}
    </ReactPDFText>
  );
}

/**
 * Insert soft hyphen character between every letter to allow thai words to be wrapped at any point
 */
function insertSoftHyphen(children: React.ReactNode): React.ReactNode {
  if (typeof children === "string") {
    return children.split("").join("\u00AD");
  } else if (Array.isArray(children)) {
    return children.map((child) =>
      typeof child === "string" ? child.split("").join("\u00AD") : child
    );
  }
}

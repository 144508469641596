import * as React from 'react';

interface IWagonProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function Wagon({ color = 'white', className, ...rest }: IWagonProps): JSX.Element {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g clipPath="url(#clip0_7628_85447)">
        <path
          d="M8.16667 8.8335H2.5C2.224 8.8335 2 8.6095 2 8.3335V2.3335C2 2.0575 2.224 1.8335 2.5 1.8335H8.16667C8.44267 1.8335 8.66667 2.0575 8.66667 2.3335V8.3335C8.66667 8.6095 8.44267 8.8335 8.16667 8.8335ZM3 7.8335H7.66667V2.8335H3V7.8335Z"
          fill={color}
        />
        <path
          d="M6.16666 5.16683H4.5C4.224 5.16683 4 4.94283 4 4.66683V2.3335C4 2.0575 4.224 1.8335 4.5 1.8335H6.16666C6.44266 1.8335 6.66666 2.0575 6.66666 2.3335V4.66683C6.66666 4.94283 6.44266 5.16683 6.16666 5.16683ZM5 4.16683H5.66666V2.8335H5V4.16683Z"
          fill={color}
        />
        <path
          d="M12.3333 14.5H0.5C0.224 14.5 0 14.276 0 14C0 13.724 0.224 13.5 0.5 13.5H12.3333C12.6093 13.5 12.8333 13.724 12.8333 14C12.8333 14.276 12.6093 14.5 12.3333 14.5Z"
          fill={color}
        />
        <path
          d="M14 16.5C12.8973 16.5 12 15.6027 12 14.5C12 13.3973 12.8973 12.5 14 12.5C15.1027 12.5 16 13.3973 16 14.5C16 15.6027 15.1027 16.5 14 16.5ZM14 13.5C13.4487 13.5 13 13.9487 13 14.5C13 15.0513 13.4487 15.5 14 15.5C14.5513 15.5 15 15.0513 15 14.5C15 13.9487 14.5513 13.5 14 13.5Z"
          fill={color}
        />
        <path
          d="M2.66677 16.5002C1.93143 16.5002 1.33344 15.9022 1.33344 15.1668C1.33344 14.4315 1.93143 13.8335 2.66677 13.8335C3.4021 13.8335 4.0001 14.4315 4.0001 15.1668C4.0001 15.9022 3.4021 16.5002 2.66677 16.5002ZM2.66677 14.8335C2.48277 14.8335 2.33343 14.9835 2.33343 15.1668C2.33343 15.3502 2.48277 15.5002 2.66677 15.5002C2.85077 15.5002 3.0001 15.3502 3.0001 15.1668C3.0001 14.9835 2.85077 14.8335 2.66677 14.8335Z"
          fill={color}
        />
        <path
          d="M9.16661 14.5002C9.09928 14.5002 9.03061 14.4862 8.96528 14.4582C8.71261 14.3462 8.59794 14.0515 8.70994 13.7988L11.0346 8.53016C11.2206 8.10616 11.6393 7.8335 12.1013 7.8335H13.1666C13.8099 7.8335 14.3333 8.35683 14.3333 9.00016V12.8335C14.3333 13.1095 14.1093 13.3335 13.8333 13.3335C13.5573 13.3335 13.3333 13.1095 13.3333 12.8335V9.00016C13.3333 8.90816 13.2586 8.8335 13.1666 8.8335H12.1013C12.0353 8.8335 11.9753 8.87283 11.9486 8.9335L9.62394 14.2015C9.54127 14.3888 9.35861 14.5002 9.16661 14.5002V14.5002Z"
          fill={color}
        />
        <path
          d="M12.5 8.83332C12.4367 8.83332 12.3727 8.82132 12.31 8.79532C12.0547 8.69066 11.9333 8.39799 12.038 8.14266L13.2047 5.30933C13.31 5.05333 13.6 4.93 13.8574 5.03733C14.1127 5.142 14.234 5.43466 14.1294 5.68999L12.9627 8.52332C12.8827 8.71666 12.696 8.83332 12.5 8.83332Z"
          fill={color}
        />
        <path
          d="M13.9999 5.83316C13.2646 5.83316 12.6666 5.23517 12.6666 4.49983C12.6666 3.7645 13.2646 3.1665 13.9999 3.1665C14.7352 3.1665 15.3332 3.7645 15.3332 4.49983C15.3332 5.23517 14.7352 5.83316 13.9999 5.83316ZM13.9999 4.1665C13.8159 4.1665 13.6666 4.3165 13.6666 4.49983C13.6666 4.68317 13.8159 4.83317 13.9999 4.83317C14.1839 4.83317 14.3332 4.68317 14.3332 4.49983C14.3332 4.3165 14.1839 4.1665 13.9999 4.1665Z"
          fill={color}
        />
        <path
          d="M7.5001 14.5002H1.83344C1.55744 14.5002 1.33344 14.2762 1.33344 14.0002V8.3335C1.33344 8.0575 1.55744 7.8335 1.83344 7.8335H7.5001C7.7761 7.8335 8.0001 8.0575 8.0001 8.3335V14.0002C8.0001 14.2762 7.7761 14.5002 7.5001 14.5002ZM2.33344 13.5002H7.0001V8.8335H2.33344V13.5002Z"
          fill={color}
        />
        <path
          d="M5.5001 11.1668H3.83343C3.55743 11.1668 3.33344 10.9428 3.33344 10.6668V8.33349C3.33344 8.0575 3.55743 7.8335 3.83343 7.8335H5.5001C5.7761 7.8335 6.0001 8.0575 6.0001 8.33349V10.6668C6.0001 10.9428 5.7761 11.1668 5.5001 11.1668ZM4.33343 10.1668H5.0001V8.83349H4.33343V10.1668Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7628_85447">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Wagon;

/* eslint-disable no-unused-vars */

export enum LastSoldEnum {
  LessThan30Mins = "less_than_30_mins",
  ThirtyMinsOneHr = "30mins_1hrs",
  OneHrTwoHrs = "1hrs_2hrs",
  MoreThan2Hrs = "more_than_2hrs",
  MoreThan1Day = "more_than_1_day",
  MoreThan7Days = "more_than_7_days",
}

import * as React from "react";
import { useImmer } from "use-immer";

/**
 * @param allIds - Array of all the Ids
 * @param filteredIds - An array of filtered Ids or undefined. Can also accept unfiltered allIds
 */

export function useIdSelection(
  allIds: Array<string>,
  filteredIds?: Array<string>
) {
  const [selectedIds, setSelectedIds] = useImmer<Array<string>>([]);

  const hasFiltered = Boolean(filteredIds);
  const idsInView = hasFiltered ? filteredIds : allIds;

  React.useEffect(() => {
    setSelectedIds((draft) => draft.filter((id) => allIds.includes(id)));
  }, [allIds]);

  const selectionStatus: "NONE" | "PARTIAL" | "ALL" =
    selectedIds.length === 0 || idsInView.length === 0
      ? "NONE"
      : idsInView.every((id) => selectedIds.includes(id))
      ? "ALL"
      : idsInView.some((id) => selectedIds.includes(id))
      ? "PARTIAL"
      : "NONE";

  function toggleId(id: string) {
    toggleIds([id]);
  }

  function toggleIds(ids: string[]) {
    setSelectedIds((draft) => {
      ids.forEach((id) => {
        if (!selectedIds.includes(id)) {
          draft.push(id);
        } else {
          removeElementInPlace(draft, id);
        }
      });
    });
  }

  function isIdSelected(id: string) {
    return selectedIds.includes(id);
  }

  function selectAll() {
    setSelectedIds([...allIds]);
  }

  function clearSelection() {
    setSelectedIds([]);
  }

  function clearSelectionInView() {
    setSelectedIds((draft) => {
      idsInView.forEach((id) => {
        removeElementInPlace(draft, id);
      });
    });
  }

  function selectAllInView() {
    setSelectedIds((draft) => {
      idsInView.forEach((id) => {
        if (!draft.includes(id)) draft.push(id);
      });
    });
  }

  function selectIds(ids: string[]) {
    setSelectedIds((draft) => {
      ids.forEach((id) => {
        if (!allIds.includes(id)) {
          console.warn(`ID:${id} not found in ${allIds}`);
          return;
        }
        if (!draft.includes(id)) draft.push(id);
      });
    });
  }

  function deselectIds(ids: string[]) {
    setSelectedIds((draft) => {
      ids.forEach((id) => {
        removeElementInPlace(draft, id);
      });
    });
  }

  return {
    selectIds,
    deselectIds,
    toggleIds,
    toggleId,
    isIdSelected,
    selectionStatus,
    selectedIds,
    selectAll,
    clearSelection,
    selectAllInView,
    clearSelectionInView,
  };
}
function removeElementInPlace<T>(array: T[], element: T): void {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

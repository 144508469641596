import * as React from "react";

interface IPriorityLowestProps {
  className?: string;
}

function PriorityLowest({ className }: IPriorityLowestProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5212 8.10001C13.0212 7.80001 13.6212 8.00001 13.9212 8.50001C14.2212 9.00001 14.0212 9.50001 13.5212 9.80001L8.52124 12.8C8.22124 13 7.82124 13 7.52124 12.8L2.52124 9.80001C2.02124 9.50001 1.92124 8.90001 2.22124 8.40001C2.42124 8.00001 3.02124 7.80001 3.52124 8.10001L8.02124 10.8L12.5212 8.10001Z"
        fill="#3DA49F"
      />
      <path
        d="M12.5212 3.80002C13.0212 3.50002 13.6212 3.60002 13.9212 4.10002C14.2212 4.60002 14.0212 5.20002 13.5212 5.50002L8.52124 8.50002C8.22124 8.70002 7.82124 8.70002 7.52124 8.50002L2.52124 5.50002C2.02124 5.20002 1.92124 4.60002 2.22124 4.10002C2.52124 3.60002 3.12124 3.50002 3.62124 3.80002L8.02124 6.50002L12.5212 3.80002Z"
        fill="#4CBDB7"
      />
    </svg>
  );
}

export default PriorityLowest;

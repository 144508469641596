import { Menu } from "@mui/material";
import * as React from "react";
import { MenuWithIcon } from "../MenuWithIcon";
import Icon from "common/components/icon/Icon";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";
import { useTranslation } from "react-i18next";
import analytics from "utils/analytics";

export function MoreDropdownMenu() {
  const { t } = useTranslation("machine", {
    keyPrefix: "overview_page.header"
  });
  const { setModalOpen } = useMachinesStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const handleClickDownloadDropdown = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    analytics.track({
      name: "click more dropdown",
      properties: { location: "header" },
    });
  };

  const handleCloseDownloadDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        id="location-dropdown"
        onClick={handleClickDownloadDropdown}
        className="cursor-pointer h-10 flex items-center p-2 border-0 bg-transparent"
        aria-controls={open ? "icon-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        role="button"
      >
        <Icon name="Ellipsis" color="primary400" />
      </button>
      <Menu
        id="machine-monitor-page-more-dropdown"
        aria-labelledby="machine monitor page more dropdown"
        open={open}
        onClose={handleCloseDownloadDropdown}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuWithIcon
          onClick={() => {
            setModalOpen("ChangeServiceZone");
            analytics.track({
              name: "click change service zone",
              properties: { location: "header" },
            });
          }}
          iconName="Delivery"
        >
          {t("more_dropdown.options.change_refill_zone_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          onClick={() => {
            setModalOpen("ChangeServiceZone");
            analytics.track({
              name: "click change service zone",
              properties: { location: "header" },
            });
          }}
          iconName="Service"
        >
          {t("more_dropdown.options.change_service_zone_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          onClick={() => {
            setModalOpen("UploadLimit");
            analytics.track({
              name: "click upload limit",
              properties: { location: "header" },
            });
          }}
          iconName="Upload"
        >
          {t("more_dropdown.options.upload_limit_dropdown_label")}
        </MenuWithIcon>
      </Menu>
    </>
  );
}

import * as React from "react";

import { Modal } from "@mui/material";
import SelectWarehouseStep from "./SelectWarehouseStep";
import RoutingStep from "./RoutingStep";

import { PlanInfo, RefillCandidate } from "./types";

import * as _ from "lodash";

import * as styles from "./CreatePlanModal.module.scss";

type InitialRoutingStatus =
  | {
      status: "success";
    }
  | { status: "error"; error: Error }
  | null;

export interface Props {
  onCreatePlanSuccess: () => void;
  onClose: () => void;
  selectedRefillCandidates: Array<RefillCandidate>;
}
function CreatePlanModal({
  selectedRefillCandidates,
  onCreatePlanSuccess,
  onClose,
}: Props) {
  const [clonedSelectedRefillCandidates] = React.useState(() =>
    _.clone(selectedRefillCandidates)
  );
  const [step, setStep] = React.useState<"SELECT_WAREHOUSE" | "ROUTING">(
    "SELECT_WAREHOUSE"
  );
  const [planInfo, setPlanInfo] = React.useState<PlanInfo | null>(null);
  const [routedRefillCandidates, setRoutedRefillCandidates] =
    React.useState<Array<RefillCandidate> | null>(null);
  const [initialRoutingStatus, setInitialRoutingStatus] =
    React.useState<InitialRoutingStatus>(null);

  function findCandidateById(id: string) {
    return clonedSelectedRefillCandidates.find(
      (candidate) => candidate.candidateId === id
    );
  }

  return (
    <Modal open={true} onClose={onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          {step === "SELECT_WAREHOUSE" ? (
            <SelectWarehouseStep
              onCancel={onClose}
              initialPlanInfo={planInfo}
              onRouteError={({ planInfo, err }) => {
                setPlanInfo(planInfo);
                setInitialRoutingStatus({
                  error: err,
                  status: "error",
                });
                setStep("ROUTING");
              }}
              onRouteSuccess={({ planInfo, routedRefillCandidates }) => {
                setPlanInfo(planInfo);
                setInitialRoutingStatus({ status: "success" });
                setRoutedRefillCandidates(
                  routedRefillCandidates.map((refillCandidate) =>
                    findCandidateById(refillCandidate.candidateId)
                  )
                );
                setStep("ROUTING");
              }}
              selectedRefillCandidates={clonedSelectedRefillCandidates}
            />
          ) : null}
          {step === "ROUTING" ? (
            <RoutingStep
              initialRoutingStatus={initialRoutingStatus}
              onBack={() => {
                setStep("SELECT_WAREHOUSE");
                setInitialRoutingStatus(null);
                setRoutedRefillCandidates(null);
              }}
              planInfo={planInfo}
              onCreatePlanSuccess={onCreatePlanSuccess}
              initialRoutedRefillCandidates={
                initialRoutingStatus.status === "success"
                  ? routedRefillCandidates
                  : clonedSelectedRefillCandidates
              }
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
}

export default CreatePlanModal;

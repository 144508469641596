import { warehousingRoutes } from "containers/warehousing/routes";
import { WarehousesOverview } from "./views/WarehouseOverview";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { WarehouseId } from "./views/WarehouseId/WarehouseId";
import { WarehouseIdSupportedMachines } from "./views/WarehouseIdSupportedMachines";
import { WarehouseIdSettings } from "./views/WarehouseIdSettings";
import { WarehouseIdTeam } from "./views/WarehouseIdTeam";
import { WarehouseIdWithProductId } from "./views/WarehouseIdWithProductId";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import { useIsAdmin, useIsAdminContract, useIsClerk, useIsOperator } from "utils/user-role";
import i18n from "common/i18n";
import { WarehousingNavigation } from "containers/warehousing/components/WarehousingNavigation";

function useHasAccessToWarehouses() {
  const canView = useIsAdmin() || useIsOperator() || useIsClerk() || useIsAdminContract();
  if (!canView) {
    throw new Error(i18n.t("label_no_permission"));
  }
}

export function Warehouses() {
  return (
    <ErrorBoundary
      fallback={function FallBackWithNavigation(props: { error: Error }) {
        return (
          <>
            <WarehousingNavigation />
            <Fallback error={props.error} />
          </>
        );
      }}
      key="no-permissions"
    >
      <WarehousesRouter />
    </ErrorBoundary>
  );
}

export function WarehousesRouter() {
  useHasAccessToWarehouses();
  return (
    <>
      <Switch>
        <Route path={warehousingRoutes.warehouses()} exact>
          <ErrorBoundary fallback={Fallback} key="overview">
            <WarehousesOverview />
          </ErrorBoundary>
        </Route>
        <Route path={warehousingRoutes.warehousesWithWarehouseId()} exact>
          <ErrorBoundary fallback={Fallback} key="warehouse-id">
            <WarehouseId />
          </ErrorBoundary>
        </Route>
        <Route
          path={warehousingRoutes.warehouseWithWarehouseIdWarehouseTeam()}
          exact
        >
          <ErrorBoundary fallback={Fallback} key="warehouse-id-team">
            <WarehouseIdTeam />
          </ErrorBoundary>
        </Route>
        <Route
          path={warehousingRoutes.warehouseWithWarehouseIdSupportedMachines()}
          exact
        >
          <ErrorBoundary fallback={Fallback} key="supported-machines">
            <WarehouseIdSupportedMachines />
          </ErrorBoundary>
        </Route>
        <Route
          path={warehousingRoutes.warehouseWithWarehouseIdSettings()}
          exact
        >
          <ErrorBoundary fallback={Fallback} key="warehouse-id-settings">
            <WarehouseIdSettings />
          </ErrorBoundary>
        </Route>
        <Route
          path={warehousingRoutes.warehouseWithWarehouseIdProductWithProductId()}
          exact
        >
          <ErrorBoundary fallback={Fallback} key="warehouse-id-with-product-id">
            <WarehouseIdWithProductId />
          </ErrorBoundary>
        </Route>
        <Redirect to={warehousingRoutes.warehouses()} />
      </Switch>
    </>
  );
}

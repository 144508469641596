import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';
import { Histogram } from '../atoms/charts/Histogram';

import * as styles from './HistogramCard.module.scss';

export interface HistogramCardProps {
  title: string;
  icon: string;
  className?: string;
  data: Array<[string, number]>;
  barStyles: {
    backgroundColor: string,
    borderColor: string,
  }
  scales: {
    xAxis: {
      label: string
    }
  }
}

function HistogramCard ({
  title,
  icon,
  data,
  scales,
  barStyles,
  className = ''
}: HistogramCardProps): JSX.Element {
  return (
    <Card className={[ styles.HistogramCard, className ].join(' ')}>
      <CardTitle icon={icon} title={title} />
      <Histogram data={data} scales={scales} barStyles={barStyles} className={styles.Histogram} />
    </Card>
  );
}

export { HistogramCard };

import * as React from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";

import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import Hamburger from "common/components/hamburger/Hamburger";

import * as styles from "./ViewMenu.module.scss";
import TextButton from "../textbutton/TextButton";

import {
  useDeactivateMachineMutation,
  useActivateMachineMutation,
  useSelfTestMachineMutation,
  useRebootMachineMutation,
  MachineStatusEnum,
} from "gql/generated";
import InformationModal from "containers/machinedetail/views/inventory/InformationModal/InformationModal";
import { toasti18n } from "utils/toast";
import { MachineStatus } from "components/MachineStatus";
import { useTranslation } from "react-i18next";
import { useIsTechnician } from "utils/user-role";

interface MachineInfoType {
  title: string;
  subTitle: string;
  status: MachineStatusEnum;
  to?: string;
}

interface IViewMenuOption {
  translationKey: string;
  icon: string;
  to: string;
}

interface IViewMenuProps {
  options: IViewMenuOption[];
  backPath: string;
  header: MachineInfoType;
  subHeader: MachineInfoType[];
  type: "TaobinMachine" | "SpiralMachine";
}

function ViewMenu({
  options,
  backPath,
  header,
  subHeader,
  type
}: IViewMenuProps): JSX.Element {
  const isTechnician = useIsTechnician();
  const { t } = useTranslation("machine", {
    keyPrefix: "machine_detail"
  });
  const { machineId }: { machineId: string } = useParams();
  const node = useRef(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [dropDownOpen, setDropdownOpen] = useState<boolean>(false);

  const [informationModalOpen, setInformationModalOpen] =
    useState<boolean>(false);

  const { mutate: deactiveMutation } = useDeactivateMachineMutation();
  const deactiveMachineWithToast = toastDecorator(deactiveMutation, {
    onSuccess: () => {
      setInformationModalOpen(true);
      setDropdownOpen(false);
    },
    onError: () => {},
  });

  const { mutate: activeMutation } = useActivateMachineMutation();
  const activeMachineWithToast = toastDecorator(activeMutation, {
    onSuccess: () => {
      setInformationModalOpen(true);
      setDropdownOpen(false);
    },
    onError: () => {},
  });

  const { mutate: selfTestMutation } = useSelfTestMachineMutation();
  const selfTestMachineWithToast = toastDecorator(selfTestMutation, {
    onSuccess: () => {
      setInformationModalOpen(true);
      setDropdownOpen(false);
    },
    onError: () => {},
  });

  const { mutate: rebootMutation } = useRebootMachineMutation();
  const rebootMutationWithToast = toastDecorator(rebootMutation, {
    onSuccess: () => {
      setInformationModalOpen(true);
      setDropdownOpen(false);
    },
    onError: () => {},
  });
  useEffect(() => {
    function handleClick(e): void {
      if (!node.current.contains(e.target)) {
        setMobileOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, []);

  const onBackClick = useCallback(() => {
    history.push(backPath);
  }, [backPath]);

  function onOptionClick(to: string): void {
    setMobileOpen(false);
    history.push(to);
  }

  return (
    <div ref={node} className={styles.ViewMenuContainer}>
      <div className={styles.Hamburger}>
        <Hamburger
          hamburgerClick={() => setMobileOpen(!mobileOpen)}
          open={mobileOpen}
        />
      </div>

      <InformationModal
        isOpen={informationModalOpen}
        onClose={() => setInformationModalOpen(false)}
      />

      <div
        className={[styles.ViewMenu, mobileOpen ? styles.open : ""].join(" ")}
      >
        <div className={styles.header}>
          <div className={styles.topBar}>
            <div className={styles.back} onClick={onBackClick}>
              <Icon name="Chevron" color="onSurfaceHigh" />
            </div>
            <div className={styles.badgeContainer}>
              <div className={styles.dropDownContainer}>
                <div
                  className={styles.cogIcon}
                  onClick={() => setDropdownOpen((prev) => !prev)}
                >
                  <Icon name="Settings" color="primary400" />
                </div>
                {dropDownOpen && (
                  <div className={styles.dropdown}>
                    <div className={styles.options}>
                      <TextButton
                        translationKey="label_self_test"
                        onClick={() => selfTestMachineWithToast({ machineId })}
                        icon="Swap"
                        className={styles.action}
                      />
                    </div>
                    <div className={styles.options}>
                      <TextButton
                        translationKey="label_active_machine"
                        disabled={isTechnician}
                        onClick={() => activeMachineWithToast({ machineId })}
                        icon="Play"
                        className={styles.action}
                        // disabled={}
                      />
                    </div>
                    <div className={styles.options}>
                      <TextButton
                        translationKey="label_deactive_machine"
                        disabled={isTechnician}
                        onClick={() => {
                          deactiveMachineWithToast({ machineId });
                        }}
                        icon="Pause"
                        className={styles.action}
                      />
                    </div>
                    <div className={styles.options}>
                      <TextButton
                        translationKey="label_restart_machine"
                        onClick={() => {
                          rebootMutationWithToast({ machineId });
                        }}
                        icon="RotateCW"
                        className={styles.action}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 pt-1">
            <div className="rounded-[4px] border-outline border overflow-hidden">
              <div className="p-2 bg-black-overlay-4">
                <div className="flex gap-1 items-center">
                  {type === "SpiralMachine" && <Icon name="Snack" color="primary" className="w-3.5 h-3.5" />}
                  <p className="text-overline text-on-surface-medium mb-0.5 leading-4">
                    {t("label_machine_view_menu")}
                  </p>
                </div>
                <div className="flex gap-1 items-center mb-2">
                  <p className="flex-1 text-h6 font-medium text-on-surface-high">
                    {header.title}
                  </p>
                  <MachineStatus
                    className="!flex-initial"
                    status={header.status}
                  />
                </div>
                <p className="text-body3 font-medium font-kanit text-on-surface-high">
                  {header.subTitle}
                </p>
              </div>
              {type === "TaobinMachine" && subHeader && subHeader.length > 0 && (
                <div className="border-t border-outline px-2 py-1">
                  <p className="mb-0.5 text-overline text-on-surface-medium">{subHeader.length} {t("label_spiral_machine_view_menu")}</p>
                  <div className="space-y-1">
                    {subHeader.map((item) => (
                      <div key={item.title} className="flex gap-1 items-center">
                        <Link to={item.to} className="flex-1 text-body3 text-primary font-medium hover:underline">
                          {item.title}
                        </Link>
                        <MachineStatus
                          className="!flex-initial"
                          status={item.status}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {type === "SpiralMachine" && (
                <div className="border-t border-outline px-2 py-1">
                  <p className="mb-0.5 text-overline text-on-surface-medium">{subHeader.length} {t("label_parent_machine_view_menu")}</p>
                  <div className="flex gap-1 items-center">
                    <Link to={subHeader[0]?.to} className="flex-1 text-body3 text-primary font-medium hover:underline">
                      {subHeader[0]?.title}
                    </Link>
                    <MachineStatus
                      className="!flex-initial"
                      status={subHeader[0]?.status}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.menu}>
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => onOptionClick(option.to)}
              className={[
                styles.option,
                option.to.includes(pathname) ? styles.selected : "",
              ].join(" ")}
            >
              <div className={styles.icon}>
                <Icon
                  name={option.icon}
                  color={
                    option.to.includes(pathname)
                      ? "onSurfaceHigh"
                      : "onSurfaceMedium"
                  }
                />
              </div>
              <Typography
                translationKey={option.translationKey}
                type="subtitle-1"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewMenu;

function toastDecorator<T>(
  cb: (arg: T, opts: { onSuccess: () => void; onError: () => void }) => void,
  opts: {
    onSuccess?: () => void;
    onError?: () => void;
  }
): (arg: T) => void {
  return (arg: T) => {
    const toastRef = toasti18n.loading();
    cb(arg, {
      onSuccess: async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toasti18n.updateLoadingToastStatus(toastRef, "success");
        opts.onSuccess?.();
      },
      onError: async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toasti18n.updateLoadingToastStatus(toastRef, "error");
        opts.onError?.();
      },
    });
  };
}

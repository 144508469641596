import * as React from "react";
import { useGetWarehousesByOrgIdQuery } from "gql/generated";
import Autocomplete, {
  AutocompleteOption,
  IAutocompleteProps,
} from "components/Autocomplete/Autocomplete";

type WarehouseIdFormProps = {
  organizationId: string;
  onChange: (value: string) => void;
  value: string;
  fallBackOptions?: Array<AutocompleteOption>;
} & Omit<IAutocompleteProps, "options" | "label" | "id">;

export function WarehouseIdAutoComplete({
  value,
  onChange,
  organizationId,
  fallBackOptions = [],
  ...props
}: WarehouseIdFormProps) {
  const getWarehouseByOrgIdQueryResult = useGetWarehousesByOrgIdQuery(
    {
      organisationId: organizationId,
    },
    {
      enabled: organizationId !== "",
    }
  );

  const warehouses = getWarehouseByOrgIdQueryResult.data?.warehouses;
  const optionsFromQuery =
    warehouses?.map((warehouse) => ({
      label: warehouse.name,
      value: warehouse.warehouseId,
    })) ?? [];
  const options = warehouses ? optionsFromQuery : fallBackOptions;
  const filteredOptions = options.filter(
    (option) => !WAREHOUSE_BLACK_LIST.includes(option.value)
  );

  return (
    <Autocomplete
      label="label_warehouse"
      id="warehouse"
      disableClearable
      value={value}
      onChange={onChange}
      options={filteredOptions}
      {...props}
    />
  );
}

const WAREHOUSE_BLACK_LIST = [
  "5a7a17cf-dd04-4469-b1d9-5decfac6c015",
  "377ac901-099c-480e-b046-b818aabff55c",
  "8679f3d3-a787-4d74-98cf-3818a7783705",
  "1d67a451-9a38-44cd-af3e-aee6dcb03104",
  "74e385dc-38f6-4b55-b469-847f6b25a79f",
];

import * as React from "react";

import { useImmer } from "use-immer";

import {
  OptimizeRouteMutation,
  OptimizeRouteMutationVariables,
  OptimizeRouteDocument
} from "gql/generated";

import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
import TextField from "components/TextField";
import Typography from "components/Typography";
import DateTimePicker from "components/DateTimePicker/DateTimePicker";

import * as moment from "moment";

import { ReplaceFieldValueTypes } from "types/utils";
import { PlanInfo, RefillCandidate } from "./types";

import * as styles from "./SelectWarehouseStep.module.scss";
import { useMutation, UseMutationOptions } from "react-query";
import { fetcher } from "gql/fetcher";
import { toasti18n } from "utils/toast";
import { useGetWarehousesForCurrentUserOrg } from "../../../hooks";

interface Props {
  onCancel: () => void;
  selectedRefillCandidates: Array<RefillCandidate>;
  initialPlanInfo: PlanInfo | null;
  onRouteError: (arg: {planInfo: PlanInfo, err: Error}) => void;
  onRouteSuccess: (arg: {
    routedRefillCandidates: Array<RefillCandidate>;
    planInfo: PlanInfo;
  }) => void;
}
type FormValue = ReplaceFieldValueTypes<
  PlanInfo,
  "startTime",
  moment.Moment | null
>;

function SelectWarehouseStep({
  onCancel,
  onRouteSuccess,
  onRouteError,
  initialPlanInfo,
  selectedRefillCandidates,
}: Props) {
  const [planInfo, setPlanInfo] = useImmer<FormValue>(() => ({
    planName: initialPlanInfo?.planName ?? "",
    warehouseId: initialPlanInfo?.warehouseId
      ? initialPlanInfo?.warehouseId
      : selectedRefillCandidates[0].warehouseId ?? "",
    startTime: initialPlanInfo?.startTime
      ? moment(Number(initialPlanInfo.startTime))
      : null,
  }));

  const isFormDisabled =
    planInfo.planName === "" ||
    planInfo.warehouseId === "" ||
    planInfo.startTime === null ||
    (moment.isMoment(planInfo.startTime) && !planInfo.startTime.isValid()) ||
    planInfo.startTime.isBefore(moment());

  const { isWarehousesLoading, warehouses } =
    useGetWarehousesForCurrentUserOrg();

  const { mutate: getRecommendedRoute, isLoading: isGettingRecommendedRoute } =
    useRoute({
      onError: (err: Error) => {
       onRouteError({
          planInfo: {
            ...planInfo,
            startTime: String(planInfo.startTime.valueOf()),
          },
          err
       });
      },
      onSuccess: (response) => {
        onRouteSuccess({
          planInfo: {
            ...planInfo,
            startTime: String(planInfo.startTime.valueOf()),
          },
          routedRefillCandidates: response.optimizeRoute.route
            .map(({ name }) => findCandidateById(name))
            // Filter out the warehouse
            .filter(Boolean),
        });
      },
    });

  function findCandidateById(id: string) {
    return selectedRefillCandidates.find(
      (candidate) => candidate.candidateId === id
    );
  }
  function getCandidateLatLng(id: string) {
    const candidate = findCandidateById(id);
    return { lat: String(candidate.lat), lng: String(candidate.lng) };
  }

  function getWarehouseById(id: string) {
    return warehouses.find((warehouse) => warehouse.warehouseId === id);
  }
  function getWarehouseAddressById(id: string) {
    const warehouse = getWarehouseById(id);
    return {
      lat: String(warehouse.lat),
      lng: String(warehouse.long),
      address: warehouse.warehouseId,
    };
  }

  function handleRoute() {
    const candidatesWithDifferentWarehouse = selectedRefillCandidates.filter(
      (refillCandidate) => {
        return refillCandidate.warehouseId !== planInfo.warehouseId;
      }
    );

    if (candidatesWithDifferentWarehouse.length > 0) {
      const machineIds = candidatesWithDifferentWarehouse
        .map((candidate) => candidate.machineId)
        .join(", ");
      const warehouseName = getWarehouseById(planInfo.warehouseId).name;

      toasti18n.error({
        message: `${machineIds} not assignable to ${warehouseName}`,
      });
      return;
    }

    getRecommendedRoute({
      waypoints: [
        {
          servicetime: 0,
          ...getWarehouseAddressById(planInfo.warehouseId),
        },
        ...selectedRefillCandidates.map((candidate) => ({
          servicetime: 0,
          address: candidate.candidateId,
          ...getCandidateLatLng(candidate.candidateId),
        })),
        {
          servicetime: 0,
          ...getWarehouseAddressById(planInfo.warehouseId),
        },
      ],
    });
  }

  return (
    <div className={styles.SelectWarehouseModal}>
      <Typography type="headline-6" color="onSurfaceHigh" translate>
        label_select_warehouse
      </Typography>
      <div className={styles.ActionFields}>
        <div>
          <TextField
            value={planInfo.planName}
            required={true}
            label="label_plan_name"
            onChange={(e) =>
              setPlanInfo((draft) => {
                draft.planName = e.target.value;
              })
            }
          />
        </div>
        <div>
          <Autocomplete
            label="label_warehouse"
            id="warehouse"
            value={isWarehousesLoading ? "" : planInfo.warehouseId}
            disableClearable
            required
            onChange={(value) =>
              setPlanInfo((draft) => {
                draft.warehouseId = value;
              })
            }
            options={
              !isWarehousesLoading
                ? warehouses.map((warehouse) => ({
                    label: warehouse.name,
                    value: warehouse.warehouseId,
                  }))
                : []
            }
          />
        </div>
        <div>
          <DateTimePicker
            required
            label="label_time_from"
            disablePast={true}
            value={planInfo.startTime}
            onChange={(time) =>
              setPlanInfo((draft) => {
                draft.startTime = time;
              })
            }
          />
        </div>
        <div className={styles.ButtonsContainer}>
          <Button
            type="secondary"
            onClick={onCancel}
            disabled={isGettingRecommendedRoute}
          >
            action_cancel
          </Button>
          <Button
            type="primary"
            disabled={isFormDisabled}
            onClick={handleRoute}
            loading={isGettingRecommendedRoute}
          >
            label_route
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SelectWarehouseStep;

export function useGetWarehouseCoordinatesById(warehouseId: string) {
  const { warehouses, isWarehousesLoading } =
    useGetWarehousesForCurrentUserOrg();
  const warehouse = warehouses?.find(
    (warehouse) => warehouse.warehouseId === warehouseId
  );
  return isWarehousesLoading
    ? undefined
    : { lat: warehouse.lat, lng: warehouse.long };
}

function useRoute(
  options?: Omit<
    UseMutationOptions<
      OptimizeRouteMutation,
      unknown,
      OptimizeRouteMutationVariables
    >,
    "mutationFn"
  >
) {
  function mutationFunction(variables: OptimizeRouteMutationVariables) {
    return fetcher<OptimizeRouteMutation, OptimizeRouteMutationVariables>(
      OptimizeRouteDocument,
      variables
    )();
  }
  return useMutation(mutationFunction, options);
}

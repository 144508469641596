// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Paper--3f66ueCpUX{overflow-y:scroll;overflow-x:hidden;width:min(100%,712px);height:min(100% - 82px,763px);padding:24px 33px;position:relative;box-sizing:border-box;max-height:unset !important;max-width:unset !important;margin:0 !important}.Paper--3f66ueCpUX .IconButton--1MDCp2bnD9{z-index:2;padding:8px;margin:-8px;position:absolute;top:7px;right:7px}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3{margin-top:32px;display:flex;min-height:90px}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3 .MachineAutocomplete--Bf12j7LtkS{width:158px;flex-shrink:0}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3 .MachineNameContainer--3WyNS8n-jo{flex-grow:1;margin-top:-10px;margin-left:25px;margin-right:25px}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3 .MachineNameContainer--3WyNS8n-jo .MachineName--Xu-e6AKbTg{margin-top:10px;line-height:1.1}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3 .ServiceZoneContainer--QvExFmM-f-{width:91px;flex-basis:91px;flex-shrink:0;margin-top:-10px}.Paper--3f66ueCpUX .MachineContainer--2Aecjs8mR3 .ServiceZoneContainer--QvExFmM-f- .ServiceZone--3AY47RQBcy{margin-top:10px;line-height:1.1}.Paper--3f66ueCpUX .CustomerContact--3XsC8Rmxet{margin-top:25px}.Paper--3f66ueCpUX .ButtonContainer--18X3hWdVSn{display:flex;margin-top:auto;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"Paper": "Paper--3f66ueCpUX",
	"IconButton": "IconButton--1MDCp2bnD9",
	"MachineContainer": "MachineContainer--2Aecjs8mR3",
	"MachineAutocomplete": "MachineAutocomplete--Bf12j7LtkS",
	"MachineNameContainer": "MachineNameContainer--3WyNS8n-jo",
	"MachineName": "MachineName--Xu-e6AKbTg",
	"ServiceZoneContainer": "ServiceZoneContainer--QvExFmM-f-",
	"ServiceZone": "ServiceZone--3AY47RQBcy",
	"CustomerContact": "CustomerContact--3XsC8Rmxet",
	"ButtonContainer": "ButtonContainer--18X3hWdVSn"
};
module.exports = exports;

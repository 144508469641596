import * as React from "react";
import Tooltip from "common/components/tooltip/Tooltip";
import Button from "common/components/button/Button";
import * as styles from './ButtonCell.module.scss';
import Typography from 'common/components/typography/Typography';

interface ButtonCellProps {
  translationKey: string | ((rowData: object) => string);
  onClick: (rowData: object) => void;
  disabled?: (rowData: object) => boolean;
  type: "primary" | "secondary" | "status";
  tooltip?: (rowData: object) => string;
}

// Do not use with datakey in table props
function ButtonCell({
  translationKey,
  onClick,
  disabled,
  type,
  tooltip
}: ButtonCellProps) {
  function rowDataAcceptor(rowData) {
    const button = <Button
                      className={styles.ButtonCell}
                      disabled={disabled ? disabled(rowData) : false}
                      translationKey={
                        typeof translationKey === "function"
                          ? translationKey(rowData)
                          : translationKey
                      }
                      onClick={(e) => {e.stopPropagation(); onClick(rowData);}}
                      type={type}
                    />;

    if (tooltip && tooltip(rowData)) {
      return <Tooltip className={styles.Tooltip} content={
                <div className={styles.TooltipContent}>
                  <Typography translationKey={tooltip(rowData)} type="caption" />
                </div>
              }>
                {button}
              </Tooltip>;
    } else {
      return button;
    }
  }
  return rowDataAcceptor;
}

export { ButtonCell };

import * as React from "react";

import {

  useDirectionsService,
  useGoogleMap,
} from "@ubilabs/google-maps-react-hooks";
import {
  GoogleMapsProvider
} from './GoogleMapsProvider';

import config from "common/config";

import * as styles from "./GoogleMaps.module.scss";

// Usage
// Render <VendiiGoogleMapsProvider> surrounding component you want to use google map
// Render <VendiiGoogleMap> anywhere as a descendant in the component tree
// Can then access the google map instance using `useGoogleMap`
// Can also use hooks from `google-maps-react-hooks`
/*
<VendiiGoogleMapsProvider>
  <Marker markers={...} />
  <VendiiGoogleMap />
</VendiiGoogleMapsProvider>
*/

const mapOptions: google.maps.MapOptions = {
  center: { lat: 37.4239163, lng: -122.0947209 },
  zoom: 6,
  mapId: config.GOOGLE_MAPS_MAP_ID,
  clickableIcons: false,
  disableDefaultUI: true,
  zoomControl: false,
};

const vendiiGoogleMapsContext =
  React.createContext<(node: HTMLDivElement | null) => void>(null);

export function VendiiGoogleMapsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mapContainer, setMapContainer] = React.useState<HTMLDivElement | null>(
    null
  );
  const mapRef = React.useCallback((node: HTMLDivElement | null) => {
    setMapContainer(node);
  }, []);

  return (
    <vendiiGoogleMapsContext.Provider value={mapRef}>
      <GoogleMapsProvider
        googleMapsAPIKey={config.GOOGLE_MAPS_API_KEY}
        mapContainer={mapContainer}
        mapOptions={mapOptions}
        libraries={["marker"]}
        version="beta"
      >
        {children}
      </GoogleMapsProvider>
    </vendiiGoogleMapsContext.Provider>
  );
}

export function VendiiGoogleMap() {
  const context = React.useContext(vendiiGoogleMapsContext);
  return <div ref={context} style={{ width: "100%", height: "100%" }}></div>;
}

interface DirectionsRendererProps {
  directions: google.maps.DirectionsResult | null | undefined;
}

export function DirectionsRenderer({ directions }: DirectionsRendererProps) {
  const map = useGoogleMap();
  const { directionsRenderer } = useDirectionsService({
    renderOnMap: true,
    renderOptions: {
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "#8688c5",
        strokeOpacity: 1,
        strokeWeight: 5,
      },
    },
  });

  React.useEffect(() => {
    if (!map) return;

    if (!directions) {
      directionsRenderer.setMap(null);
    } else {
      directionsRenderer.setMap(map);
      directionsRenderer.setDirections(directions);
    }
  }, [directions, map]);

  return null;
}

interface MarkerProps {
  markers: Array<{
    lat: number;
    lng: number;
    icon?: number | "Warehouse";
  }> | null;
}

export function Marker({ markers }: MarkerProps) {
  const map = useGoogleMap();
  const currentMarkers =
    React.useRef<google.maps.marker.AdvancedMarkerElement[]>();
  const hasSetInitialBounds = React.useRef(false);

  React.useEffect(() => {
    if (!map) {
      return () => {};
    }
    if (!markers || markers.length === 0) {
      return () => {};
    }
    const iniitalBounds = new google.maps.LatLngBounds();
    const markersToDisplay = markers.map((marker) => {
      const markerLatLng = { lat: marker.lat, lng: marker.lng };
      iniitalBounds.extend(markerLatLng);

      return new google.maps.marker.AdvancedMarkerElement({
        map,
        content: buildMarkerHTML(marker.icon),
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
      });
    });

    if (hasSetInitialBounds.current === false) {
      map.fitBounds(iniitalBounds);
      hasSetInitialBounds.current = true;
    }

    currentMarkers.current = markersToDisplay;
    return () => {
      currentMarkers.current.forEach((marker) => {
        marker.map = null;
      });
    };
  }, [markers, map]);

  return null;
}

function buildMarkerHTML(icon?: number | "Warehouse" | undefined | null) {
  const markerHTML = document.createElement("div");
  markerHTML.className = styles.Marker;

  const numberOrUndefinedMarkerHTML =
    typeof icon === "number" ? String(icon) : "";

  const warehouseMarkerHTML = `
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7594 22.9993H21.3958C22.2804 22.9993 23 22.2797 23 21.3951V8.79095C23 8.56453 22.8882 8.35278 22.703 8.22445L12.3905 1.12028C12.1558 0.958034 11.8451 0.958034 11.6104 1.12028L1.29792 8.22445C1.11183 8.35278 1 8.56453 1 8.79095V21.3951C1 22.2797 1.71958 22.9993 2.60417 22.9993H6.24045C6.2505 22.9997 6.26061 22.9999 6.27078 22.9999H17.7291C17.7393 22.9999 17.7494 22.9997 17.7594 22.9993ZM18.4166 21.6243H21.3958C21.5223 21.6243 21.625 21.5216 21.625 21.3951V9.15212L12 2.52187L2.375 9.15212V21.3951C2.375 21.5216 2.47767 21.6243 2.60417 21.6243H5.58328V18.6458V14.9791V11.7708C5.58328 11.3913 5.89128 11.0833 6.27078 11.0833H17.7291C18.1086 11.0833 18.4166 11.3913 18.4166 11.7708V14.9791V18.6458V21.6243ZM17.0416 17.9583V15.6666H6.95828V17.9583H17.0416ZM6.95828 19.3333H17.0416V21.6243H6.95828V19.3333ZM6.95828 14.2916H17.0416V12.4583H6.95828V14.2916Z"
        fill="white"
      />
    </svg>
  `;

  markerHTML.innerHTML =
    icon === "Warehouse" ? warehouseMarkerHTML : numberOrUndefinedMarkerHTML;

  return markerHTML;
}

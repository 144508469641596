import Typography from 'common/components/typography/Typography';
import * as React from 'react';
import { Doughnut } from '../atoms/Doughnut';

import * as styles from './PieChartKey.module.scss';

interface PieChartKeyProps {
  className?: string;
  color: string;
  label: string;
  value: number | string;
}

function PieChartKey ({
  className = '',
  color,
  label,
  value
}: PieChartKeyProps): JSX.Element {
  return (
    <div className={[ styles.PieChartKey, className ].join(' ')}>
      <Doughnut
        color={color}
        className={styles.Doughnut}
      />
      <Typography
        translationKey={label}
        type="body-2"
        className={styles.Label}
      />
      <Typography text={`${value}%`} type="body-2" className={styles.Value} />
    </div>
  );
}

export { PieChartKey };

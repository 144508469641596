import * as React from 'react';

import { Pie } from 'react-chartjs-2';

import * as styles from './PieChart.module.scss';

interface PieChartProps {
  className?: string;
  data: Array<PieChartSegment>;
}

export interface PieChartSegment {
  label: string;
  color: string;
  amount: number;
  borderColor: string;
}

function PieChart({ data, className = '' }: PieChartProps): JSX.Element {
  return (
    <div className={[styles.PieChart, className].join(' ')}>
      <Pie
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          datasets: [
            {
              data: data.map((segment) => segment.amount),
              backgroundColor: data.map((segment) => segment.color),
              borderColor: data.map((segment) => segment.borderColor),
              borderAlign: 'inner',
            },
          ],
          labels: data.map((segment) => segment.label),
        }}
      />
    </div>
  );
}

export { PieChart };

import * as React from "react";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";

export function MachineInventoryStatusCell({ status }: { status: string }): JSX.Element {
  const { t } = useTranslation();
  const context = generateStatusContext(status?.toLocaleLowerCase());
  return (
    <div
      className={clsx(status ? context.className: "text-on-surface-disabled")}
    >
      <p className="text-body2">{status ? t(context.translationKey): t("status_none")}</p>
    </div>
  );
}

function generateStatusContext (status: string) {
  switch (status) {
    case "normal":
      return {
        translationKey: 'status_available',
        className: "text-on-background"
      };
    case "low":
      return {
        translationKey: 'status_available',
        className: "text-on-background"
      };
    case "abnormal":
      return {
        translationKey: 'status_error',
        className: "text-error"
      };
    case "disable":
      return {
        translationKey: 'status_disable',
        className: "text-error"
      };
    case "disable low":
      return {
        translationKey: 'status_disable',
        className: "text-error"
      };
    case "off":
      return {
        translationKey: "status_inactive",
        className: "text-error"
      };
    default:
      break;
  }
}

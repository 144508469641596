import { DashboardCardHistogramProps } from '../organisms/DashboardCard';
import { APIHistogram } from 'dashboard/interface';

export function mapDataToHistogramCard (
  dataPoint: APIHistogram
): Omit<DashboardCardHistogramProps, 'className'> {
  return {
    type: 'histogram',
    cardProps: {
      title: dataPoint.title,
      icon: dataPoint.icon,
      barStyles: computeBarStyles(dataPoint.title),
      data: dataPoint.data.bins,
      scales: {
        xAxis: {
          label: dataPoint.data.xAxis.label
        }
      }
    }
  };
}

const AVAILABLE_COLOR_SETS: Array<DashboardCardHistogramProps['cardProps']['barStyles']> = [
  {
    backgroundColor: 'rgba(121, 205, 201, 0.5)',
    borderColor: '#3DA49F'
  },
  {
    backgroundColor: '#EEEEF7',
    borderColor: '#8688C5'
  }
];

const titleToBarStylesCache = {};
let nextAvailableColorIndex = 0;

function computeBarStyles (
  title: string
): DashboardCardHistogramProps['cardProps']['barStyles'] {
  if (titleToBarStylesCache[title] === undefined) {
    titleToBarStylesCache[title] =
      AVAILABLE_COLOR_SETS[nextAvailableColorIndex];
    nextAvailableColorIndex =
      (nextAvailableColorIndex + 1) % AVAILABLE_COLOR_SETS.length;
  }
  return titleToBarStylesCache[title];
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ToggleSwitch--25D6lPeVlC{box-sizing:border-box;width:34px;height:20px;display:flex;align-items:center;cursor:pointer;user-select:none}.ToggleSwitch--25D6lPeVlC *{box-sizing:border-box}.ToggleSwitchDisabled--v7mfRuF8zk{cursor:revert}.Bar--1LKJeZaTow{width:100%;height:14px;display:flex;align-items:center;border-width:1px;border-style:solid;border-radius:100px}.BarOnDisabled--1bVJ9EvQZf{justify-content:flex-end;border-color:rgba(0,0,0,0);background-color:#cfd5f0}.BarOffDisabled--3FjjFjAxfN{justify-content:flex-start;border-color:rgba(0,0,0,0);background-color:#dadada}.BarOn--3GZVVj4Tw8{justify-content:flex-end;border-color:rgba(0,0,0,0);background-color:#8191d9}.BarOff--2y1-LPmqmE{justify-content:flex-start;border-color:#8191d9;background-color:rgba(0,0,0,0)}.Circle--SxVgTnvuuE{width:20px;height:20px;border-radius:50%;box-shadow:0px 1px 3px 0px rgba(0,0,0,.2),0px 2px 1px 0px rgba(0,0,0,.12),0px 1px 1px 0px rgba(0,0,0,.14)}.CircleOnDisabled--Z9hZsbVjgx{background-color:#babbd9}.CircleOffDisabled--1LIW12_6S-{background-color:#acacac}.CircleOn--2gz-Nvysjf{background-color:#4b4d9b}.CircleOff--1q2hQcFHYG{background-color:#4b4d9b}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"ToggleSwitch": "ToggleSwitch--25D6lPeVlC",
	"ToggleSwitchDisabled": "ToggleSwitchDisabled--v7mfRuF8zk",
	"Bar": "Bar--1LKJeZaTow",
	"BarOnDisabled": "BarOnDisabled--1bVJ9EvQZf",
	"BarOffDisabled": "BarOffDisabled--3FjjFjAxfN",
	"BarOn": "BarOn--3GZVVj4Tw8",
	"BarOff": "BarOff--2y1-LPmqmE",
	"Circle": "Circle--SxVgTnvuuE",
	"CircleOnDisabled": "CircleOnDisabled--Z9hZsbVjgx",
	"CircleOffDisabled": "CircleOffDisabled--1LIW12_6S-",
	"CircleOn": "CircleOn--2gz-Nvysjf",
	"CircleOff": "CircleOff--1q2hQcFHYG"
};
module.exports = exports;

import * as React from "react";
import { useImmer } from "use-immer";

import { StringCell } from "common/components/table/cells/Cells";
import { ButtonCell } from "common/components/table/cells/ButtonCell/ButtonCell";
import {
  CheckBoxCell,
  HeaderCheckBoxCell,
} from "common/components/table/cells/CheckboxCell/CheckboxCell";
import { DateCell } from "common/components/table/cells/DateCell/DateCell";
import { IconAndTextCell } from "common/components/table/cells/IconAndTextCell/IconAndTextCell";
import { LinkCell } from "common/components/table/cells/LinkCell/LinkCell";
import { ToolTipCell } from "common/components/table/cells/ToolTipCell/ToolTipCell";

import Table from "common/components/table/Table";
import CheckboxWithLabel from "components/CheckboxWithLabel";
import TextButton from "common/components/textbutton/TextButton";
import CreateTicketModal from "./modals/CreateTicketModal";
import InProgressTicketModal from "./modals/InProgressTicketModal";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";

import { sameElementsInBothArrays } from "common/utils/arrayUtils";

import { useGetTicketsByStatusQuery } from "gql/generated";

import { mapDataToTable, ITableRow } from "./utils/mapDataToTable";
import sortingFn from "./utils/sortingFn";
import { filterOptions } from "./utils/filterOptions";
import { get } from "lodash";
import { useLocalStorage } from "react-use";
import { staleTime } from "./utils/constants";

import * as styles from "./InProgress.module.scss";
import downloadTicketsXLSX from "./utils/downloadTicketsXLSX";

export function InProgress(): JSX.Element {
  const { data, isLoading } = useGetTicketsByStatusQuery(
    {
      status: "in_progress",
    },
    {
      staleTime: staleTime,
    }
  );

  const [selectedTicketNumbers, setSelectedTicketNumbers] = useImmer<
    Array<string>
  >([]);
  const allTicketNumbers = data?.tickets.map((dataPoint) => dataPoint.id);

  const [filter, setFilter] = React.useState<IAppliedFilter | null>(null);

  const mappedData = mapDataToTable(data);
  const tableData =
    filter === null
      ? mappedData
      : mappedData.filter((row) =>
          String(get(row, filter.field).toLowerCase()).includes(
            filter.value.toLowerCase()
          )
        );

  const [isCreateNewTicketModalOpen, setIsCreateNewTicketModalOpen] =
    React.useState<boolean>(false);
  const [
    inProgressTicketModalTicketNumber,
    setInProgressTicketModalTicketNumber,
  ] = React.useState<string | null>(null);

  const [paginated, setPaginated] = useLocalStorage<boolean>(
    "paginate-machine-ticket",
    true
  );

  return (
    <>
      {isCreateNewTicketModalOpen && (
        <CreateTicketModal
          open={isCreateNewTicketModalOpen}
          onClose={() => setIsCreateNewTicketModalOpen(false)}
        />
      )}
      {inProgressTicketModalTicketNumber !== null && (
        <InProgressTicketModal
          ticketId={inProgressTicketModalTicketNumber}
          onClose={() => setInProgressTicketModalTicketNumber(null)}
        />
      )}
      <div>
        <div className={styles.TableHeader}>
          <div className={styles.ButtonsContainer}>
            <TextButton
              translationKey="action_create_new_ticket"
              onClick={() => setIsCreateNewTicketModalOpen(true)}
              icon="Plus"
            />
            <TextButton
              translationKey="action_download"
              onClick={downloadTicketsXLSX}
              icon="Download"
            />
            <div>
              <CheckboxWithLabel
                label="label_toggle_pagination"
                checked={paginated}
                onChange={(event) => setPaginated(event.target.checked)}
              />
            </div>
          </div>
        </div>
        <PureFilterBar
          value={filter}
          onChange={(selectedFilter) => {
            setFilter(selectedFilter);
          }}
          filterOptions={filterOptions}
        />
        <div className={styles.TableContainer}>
          <Table
            key={String(paginated)}
            infiniteScroll={paginated}
            loading={isLoading}
            data={tableData}
            loadingRows={10}
            defaultSortColumn="table.machineId"
            defaultSortDirection="asc"
            columns={[
              {
                headerCellRenderer: HeaderCheckBoxCell({
                  checked:
                    isLoading || data?.tickets.length === 0
                      ? false
                      : sameElementsInBothArrays(
                          allTicketNumbers,
                          selectedTicketNumbers
                        ),
                  onClick: () => {
                    if (
                      sameElementsInBothArrays(
                        allTicketNumbers,
                        selectedTicketNumbers
                      )
                    ) {
                      setSelectedTicketNumbers([]);
                    } else {
                      setSelectedTicketNumbers([...allTicketNumbers]);
                    }
                  },
                }),
                cellRenderer: CheckBoxCell({
                  key: "misc.ticketIdBackend",
                  onClick: (selectedOrderNumber, checked) => {
                    setSelectedTicketNumbers((draft) => {
                      if (checked) {
                        draft.push(selectedOrderNumber);
                      } else {
                        const orderIndex = draft.findIndex(
                          (orderNumber) => orderNumber === selectedOrderNumber
                        );
                        draft.splice(orderIndex, 1);
                      }
                    });
                  },
                  checkedFn: (orderNumber) => {
                    return selectedTicketNumbers.includes(orderNumber);
                  },
                }),
                columnWidth: 21,
                minWidth: 21,
                showMobile: true,
              },
              {
                cellRenderer: ToolTipCell({
                  onClick: (rowData: ITableRow) => {
                    setInProgressTicketModalTicketNumber(
                      rowData.misc.ticketIdBackend
                    );
                  },
                  cellTextKey: "table.ticketIdFrontend",
                  toolTipTextKey: "table.ticketIdFrontend",
                }),
                headerLabel: "label_ticket_number",
                dataKey: "table.ticketIdFrontend",
                showMobile: true,
                columnWidth: 77,
                minWidth: 77,
                sortable: true,
                sortingFn: (a: ITableRow, b: ITableRow) =>
                  sortingFn.alphaNumericComparison(
                    a.table.ticketIdFrontend,
                    b.table.ticketIdFrontend
                  ),
              },
              {
                dataKey: "table.machineId",
                cellRenderer: LinkCell,
                headerLabel: "label_machine_id",
                sortable: true,
                showMobile: true,
                columnWidth: 95,
                minWidth: 95,
                sortingFn: (a: ITableRow, b: ITableRow) =>
                  sortingFn.stringComparison(
                    a.misc.machineId,
                    b.misc.machineId
                  ),
              },
              {
                dataKey: "table.machineName",
                cellRenderer: StringCell,
                headerLabel: "label_machine_name",
                columnFlex: 1,
                sortable: true,
                showMobile: true,
                minWidth: 200,
              },
              {
                dataKey: "table.technician",
                cellRenderer: StringCell,
                headerLabel: "label_technician",
                sortable: true,
                showMobile: true,
                columnWidth: 95,
                minWidth: 95,
              },
              {
                dataKey: "table.organisation",
                cellRenderer: StringCell,
                headerLabel: "label_organisation",
                sortable: true,
                showMobile: true,
                columnWidth: 105,
                minWidth: 105,
              },
              {
                dataKey: "table.priority",
                cellRenderer: IconAndTextCell,
                headerLabel: "label_priority",
                showMobile: true,
                columnWidth: 95,
                minWidth: 95,
                sortable: true,
                sortingFn: (a: ITableRow, b: ITableRow) =>
                  sortingFn.priorityComparison(
                    a.misc.priority,
                    b.misc.priority
                  ),
              },
              {
                dataKey: "table.created",
                cellRenderer: DateCell({ format: "MMM D HH:mm" }),
                headerLabel: "label_created",
                sortable: true,
                showMobile: true,
                columnWidth: 97,
                minWidth: 97,
              },
              {
                dataKey: "table.updated",
                cellRenderer: DateCell({ format: "MMM D HH:mm" }),
                headerLabel: "label_updated",
                sortable: true,
                showMobile: true,
                columnWidth: 97,
                minWidth: 97,
              },
              {
                dataKey: "table.issues",
                cellRenderer: StringCell,
                headerLabel: "label_issues",
                sortable: true,
                showMobile: true,
                columnWidth: 78,
                minWidth: 78,
              },
              {
                dataKey: "table.serviceZone",
                cellRenderer: StringCell,
                headerLabel: "label_service_zone",
                sortable: true,
                showMobile: true,
                columnWidth: 90,
                minWidth: 90,
              },
              {
                cellRenderer: ButtonCell({
                  onClick: (rowData: ITableRow) => {
                    setInProgressTicketModalTicketNumber(
                      rowData.misc.ticketIdBackend
                    );
                  },
                  translationKey: "label_view",
                  type: "primary",
                }),
                headerLabel: "label_action",
                showMobile: true,
                columnWidth: 85,
                minWidth: 85,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}

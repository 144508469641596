import * as React from "react";
import Button from "components/Button";
import Drawer from "@mui/material/Drawer";
import Icon from "common/components/icon/Icon";
import { flexRender, Table } from "@tanstack/react-table";
import { ColumnFilterInput } from "../inputs";
import { tableV2Tracking } from "../../tracking";
import { useTranslation } from "react-i18next";
import { useResponsive } from "hooks/useResponsive";

type MoreFilterProps<T> = {
  table: Table<T>;
};

const location = "modal";

export function MoreFilter<T>({ table }: MoreFilterProps<T>) {
  const { isXLDesktop } = useResponsive();
  const tableName = table.getState().tableName;
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2.filter",
  });
  const allLeafColumns = table.getAllLeafColumns();

  const moreFilter = allLeafColumns.filter((column) => {
    const { enableColumnFilter, meta } = column?.columnDef || {};
    
    if (!enableColumnFilter) return false;
    
    return isXLDesktop ? meta?.columnFilter?.location === location : meta?.columnFilter?.location !== "primary" && meta?.columnFilter?.location !== "in-place";
  });

  const [open, setOpen] = React.useState(false);
  const filters = table.getState().columnFilters;

  if (moreFilter.length === 0) return null;

  return (
    <>
      <Button
        className="whitespace-nowrap"
        variant="outlined"
        size="small"
        onClick={() => {
          setOpen((prev) => !prev);
          tableV2Tracking({
            name: "more filter button clicked",
            properties: {
              location,
            },
            tableName,
          });
        }}
        type="secondary"
      >
        <div className="flex gap-2 items-center">
          <Icon className="w-6 h-6" name="Filter" color="primary" />
          <p className="text-body2 text-on-surface-high hidden md:block">
            {t("label_more_filter_button")}
          </p>
        </div>
      </Button>
      <Drawer
        anchor="right"
        open={open}
        sx={{
          flexShrink: 0,
        }}
        onClose={() => {
          setOpen(false);
          tableV2Tracking({
            name: "modal close by click on backdrop",
            properties: {
              location,
            },
            tableName,
          });
        }}
      >
        <div
          id="more-filter"
          className="w-full lg:w-[440px] max-w-full min-h-full box-border flex flex-col rounded-md shadow-sm max-h-[calc(100vh-6rem)] bg-white"
        >
          <div className="p-4 flex items-center gap-1 text-on-surface-high !border-b border-solid border-0 border-outline pb-2">
            <div className="flex items-baseline flex-1 gap-1">
              <h3 className="text-h6 font-medium">{t("more_filter.title")}</h3>
              {filters?.length > 0 && (
                <p className="text-body3">
                  {t("more_filter.label_sub_title", {
                    count: filters.length,
                    postProcess: "interval",
                  })}
                </p>
              )}
            </div>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <Icon name="Close" color="onSurfaceHigh" />
            </div>
          </div>
          <div className="flex flex-col gap-3 flex-1 overflow-auto p-4 divide-y divide-outline">
            {moreFilter.map((column) => {
              return (
                <div
                  key={column.id}
                  className="py-4"
                  style={{ order: column.columnDef.meta?.columnFilter?.order }}
                >
                  <p className="mb-4 flex gap-1">
                    {column.columnDef.meta?.columnFilter?.label ||
                      flexRender(column.columnDef.header, {
                        table,
                        column,
                      })}

                    {column.columnDef.meta?.columnFilter?.helperText && (
                      <span className="!font-light">
                        {column.columnDef.meta?.columnFilter?.helperText}
                      </span>
                    )}
                  </p>
                  <ColumnFilterInput
                    className="w-full"
                    column={column}
                    location={location}
                    tableName={tableName}
                  />
                </div>
              );
            })}
          </div>
          <div className="p-4 flex gap-4 justify-end !border-t border-solid border-outline">
            <Button
              size="small"
              variant="outlined"
              color="error"
              disabled={filters?.length === 0}
              onClick={() => {
                table.resetColumnFilters();
                tableV2Tracking({
                  name: "clear all button clicked",
                  properties: {
                    location,
                  },
                  tableName,
                });
              }}
              type="secondary"
            >
              {t("label_clear_all_button")}
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setOpen(false);
                tableV2Tracking({
                  name: "apply button clicked",
                  properties: {
                    location,
                  },
                  tableName,
                });
              }}
            >
              {t("label_apply_button")}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

import * as React from "react";
import * as styles from "./FallBack.module.scss";
import Typography from "components/Typography";

export function Fallback({ error }: { error: Error; }) {
  return (
    <div className={`${styles.Fallback} ${styles.FadeIn}`}>
      <div style={{ textAlign: "center", maxWidth: "80%" }}>
        <div className={styles.FadeInMoveUp}>
          <Typography color="error" translate type="headline-6">
            label_an_error_has_occured
          </Typography>
        </div>

        <div className={styles.FadeInMoveUp}>
          <Typography color="error" translate type="caption">
            {error.message}
          </Typography>
        </div>
      </div>
    </div>
  );
}

import {
  GetMaintenanceBoardPlasticCupErrorDocument,
  GetMaintenanceBoardPlasticCupErrorQuery,
  GetMaintenanceBoardPlasticCupErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getPlasticCups({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardPlasticCupErrorQuery,
    GetMaintenanceBoardPlasticCupErrorQueryVariables
  >(GetMaintenanceBoardPlasticCupErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

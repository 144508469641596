import * as React from 'react';
import * as styles from './Card.module.scss';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

function Card ({ children, className = '' }: CardProps): JSX.Element {
  return <div className={[ styles.Card, className ].join(' ')}>{children}</div>;
}

export { Card };

import * as React from 'react';
import * as moment from 'moment';
import {
  get,
  capitalize,
  template as lodashTemplate,
  templateSettings,
} from 'lodash';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { toFixed, formatMoment, formatMomentDate } from 'common/utils/format';

import Icon from 'common/components/icon/Icon';
import Input from 'common/components/input/Input';
import Tooltip from 'common/components/tooltip/Tooltip';
import Button from 'common/components/button/Button';
import SelectButton from 'common/components/selectbutton/SelectButton';
import Typography from 'common/components/typography/Typography';
import Checkbox from 'common/components/checkbox/Checkbox';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';
import ProgressBar from 'common/components/progressbar/ProgressBar';
import Badge, { IBadgeProps } from 'common/components/badge/Badge';

import { IRefillOrder } from 'refillorders/interfaces';

import * as styles from './Cells.module.scss';

import * as colors from 'common/styles/colors.scss';
import { formatCurrency, formatCurrencyWithoutSymbol } from 'utils/currency';
import Avatar from '@mui/material/Avatar';
import { clsx } from 'clsx';

export function CurrencyCellWithoutSymbol(value: string): JSX.Element {
  return (
    <Typography
      text={formatCurrencyWithoutSymbol({
        input: value,
        minimumFractionDigits: 0,
      })}
      type="body-2"
      className={styles.CurrencyCell}
    />
  );
}

export function CurrencyCell(value: string): JSX.Element {
  return (
    <Typography
      text={formatCurrency({ input: value, minimumFractionDigits: 0 })}
      type="body-2"
      className={styles.CurrencyCell}
    />
  );
}

export function StringDateCell(stringDate: string): JSX.Element {
  const timestamp = new Date(stringDate).getTime();
  return (
    <Typography
      text={formatMoment(moment.unix(Number(timestamp) / 1000))}
      type="body-2"
    />
  );
}

export function LastRefillCell(lastRefill: number): JSX.Element {
  if (lastRefill) {
    const date = moment.unix(lastRefill / 1000);
    const dateIsPast = moment().isAfter(date);
    return (
      <div className={styles.LastRefillCell}>
        {dateIsPast && (
          <Typography
            translationKey="label_expired"
            type="caption"
            className={styles.expired}
          />
        )}
        {!dateIsPast && (
          <Typography translationKey="label_available" type="caption" />
        )}
        <div className={styles.bottomRow}>
          <Typography translationKey="label_last_refill" type="caption" />
          &nbsp;
          <Typography text={formatMomentDate(date)} type="caption" />
        </div>
      </div>
    );
  }
}

export function FromNowCell({ postDateMessage }) {
  return function Component(stringDate: string): JSX.Element {
    const time = new Date(stringDate).getTime();
    const date = moment.unix(Number(time) / 1000);
    const dateIsPast = moment().isAfter(date);

    return (
      <div className={styles.FromNowCell}>
        <Typography text={formatMomentDate(date)} type="body-2" />
        {dateIsPast && (
          <Typography
            translationKey={postDateMessage}
            type="caption"
            className={styles.postDateMessage}
          />
        )}
        {!dateIsPast && (
          <Typography
            text={date.fromNow()}
            type="caption"
            className={styles.fromNow}
          />
        )}
      </div>
    );
  };
}

export function DateCell(value: string): JSX.Element {
  if (value) {
    return (
      <Typography
        text={formatMoment(moment.unix(Number(value) / 1000))}
        type="body-2"
      />
    );
  }
}

export function NumberCell(fallback?: string) {
  return function Component(value: number | string): JSX.Element {
    return (
      <Typography
        text={toFixed(String(value), 2).toLocaleString() || fallback || '-'}
        type="body-2"
      />
    );
  };
}

interface IUuidCellProps {
  onCopy: (uuid: string) => void;
  onUuidClick: (uuid: string, rowData: object) => void;
  dataKey: string;
}

export function UuidCell({ onCopy, onUuidClick, dataKey }: IUuidCellProps) {
  return function Component(rowData: object): JSX.Element {
    const uuid = rowData[dataKey];
    const substring = uuid.substr(uuid.length - 6);
    return (
      <div className={styles.UuidCell}>
        <Tooltip
          key={uuid}
          align="left"
          content={
            <div className={styles.tooltip}>
              <Typography text={uuid} type="caption" />
            </div>
          }
          onClick={() => onUuidClick(uuid, rowData)}
        >
          <Typography text={substring} type="body-2" />
        </Tooltip>

        <div
          onClick={() => {
            if (navigator?.clipboard?.writeText) {
              navigator.clipboard.writeText(uuid);
              onCopy(uuid);
            } else {
              console.log('This browser does not support clipboard copy.');
            }
          }}
        >
          <Icon name="Copy" color="primary" className={styles.copyIcon} />
        </div>
      </div>
    );
  };
}

export function SnakedStringCell(value: string): JSX.Element {
  const unsnaked = value.replace(/_/g, ' ');
  return <Typography text={capitalize(unsnaked)} type="body-2" />;
}

interface IClickableStringCellProps {
  onClick: (value: string) => void;
}

export function ClickableStringCell({ onClick }: IClickableStringCellProps) {
  return function Component(value: string): JSX.Element {
    return (
      <div
        className={styles.ClickableStringCell}
        onClick={() => onClick(value)}
      >
        <Typography text={value} type="body-2" />
      </div>
    );
  };
}

export function AvatarCellWithText(
  textToDisplay: string,
  avatar: string
): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Avatar src={avatar} sx={{ width: 24, height: 24 }} />
      <Typography translationKey={textToDisplay} type="body-2" />
    </div>
  );
}

export function StringCell(value: string | string[]): JSX.Element {
  return <Typography translationKey={value} type="body-2" />;
}

export function ErrorCell(value: string): JSX.Element {
  return <div className={clsx(value ? "text-error": "text-on-surface-high")}>{value || "-"}</div>;
}

export function TextStringCell(value: string): JSX.Element {
  return <Typography text={value} type="body-2" />;
}

export function ArrayCell(values: string[]): JSX.Element {
  return (
    <>
      {values.map((value, index) => (
        <div key={`${value}-${index}`}>
          <Typography text={value} type="body-2" />
          {values.length > 1 && <>&nbsp;</>}
        </div>
      ))}
    </>
  );
}

export function CapitalCell(value: string): JSX.Element {
  return <Typography text={capitalize(value)} type="body-2" />;
}

export function StatusCell(value: string): JSX.Element {
  const badgeProps: IBadgeProps = {
    translationKey: 'status_none',
    status: 'none',
  };

  if (value === 'online') {
    badgeProps.translationKey = 'status_online';
    badgeProps.status = 'success';
  }

  if (value === 'offline') {
    badgeProps.translationKey = 'status_offline';
    badgeProps.status = 'error';
  }

  if (value === 'out_of_service') {
    badgeProps.translationKey = 'status_out_of_service';
    badgeProps.status = 'error';
  }

  if (value === 'maintenance') {
    badgeProps.translationKey = 'status_maintenance';
    badgeProps.status = 'warning';
  }

  if (value === 'not_deployed') {
    badgeProps.translationKey = 'status_not_deployed';
    badgeProps.status = 'not_deployed';
  }

  return <Badge {...badgeProps} />;
}

type IInlineStatus =
  | 'online'
  | 'success'
  | 'normal'
  | 'warning'
  | 'error'
  | 'fail'
  | 'failed';
interface IInlineStatusCellProps {
  idKey: string;
  labelKey?: string;
}

export function InlineStatusCell({ idKey, labelKey }: IInlineStatusCellProps) {
  return function Component(rowData: object): JSX.Element {
    const value: IInlineStatus = get(rowData, idKey);

    let color: string = value;
    if (value === 'online' || value === 'success' || value === 'normal') {
      color = 'success';
    }
    if (value === 'fail' || value === 'failed') {
      color = 'error';
    }

    return (
      <IndicatorLabel
        label={labelKey ? get(rowData, labelKey) : capitalize(value)}
        color={isMobile ? undefined : color}
      />
    );
  };
}

export function CountCell(items: any[], label: string): JSX.Element {
  return (
    <>
      <Typography text={items?.length || 0} type="body-2" />
      &nbsp;
      <Typography text={label} type="body-2" />
    </>
  );
}

export function InlineLowInventoryCell(value: boolean): JSX.Element {
  let color: string = 'success';
  if (value) {
    color = 'error';
  }

  return (
    <IndicatorLabel
      label={value ? 'status_low_inventory' : 'status_normal'}
      color={isMobile ? undefined : color}
    />
  );
}

interface IProgressBarCellProps {
  labelTextCallBack: (rowData: object) => string | string[];
  labelTextClassNameCallBack: (rowData: object) => string;
  labelKey: string;
  percentageKey: string;
  warning?: string;
  critical?: string;
}

export function ProgressBarCell({
  labelTextCallBack,
  labelTextClassNameCallBack,
  labelKey,
  percentageKey,
  warning,
  critical,
}: IProgressBarCellProps) {
  return function Component(rowData: object): JSX.Element {
    const percentage = rowData[percentageKey] * 100;
    const roundedPercentage = Math.round(percentage);

    let color = 'success';
    const warningValue = warning ? rowData[warning] * 100 : 30;
    if (percentage < warningValue) {
      color = 'warning';
    }
    const criticalValue = Math.min(
      critical ? rowData[critical] * 100 : 10,
      warningValue
    );
    if (percentage < criticalValue) {
      color = 'critical';
    }

    return (
      <ProgressBar
        key={`${labelKey}-${roundedPercentage}`}
        color={color}
        label={labelTextCallBack(rowData)}
        labelClassName={labelTextClassNameCallBack(rowData)}
        percentage={roundedPercentage}
      />
    );
  };
}

interface ICheckboxCellProps {
  labelKey?: string;
  checkedMap: { [id: string]: boolean };
  onClick: (id: number | string) => void;
  idKey: string;
}

/**
 * Creates a checkbox cell renderer with state depending on the row data
 *
 * @param ICheckboxCellProps -
 * @param ICheckboxCellProps.labelKey -  Label to be displayed next to check box, taken from  _`rowData[labelKey]`_ __[OPTIONAL]__
 * @param ICheckboxCellProps.checkedMap - Object in the form _`{rowData[idKey]: boolean}`_ for specifying checked state
 * @param ICheckboxCellProps.onClick - Function to be called when checkbox clicked, _`() => onClick(rowData[idKey])`_
 * @param ICheckboxCellProps.idKey - rowData property to look up in checkedMap and to be passed to the click handler
 *
 * @returns Returns a function with type _`(rowData: object) => JSX.Element`_. Component returned renders a checkbox and conditionally a label key nested inside of a div
 *
 * @remarks
 * The checkbox cell renderer returns a function that can be called with rowData and returns a JSX element. The rowData represents data for a single row in the table.
 *
 *  The row-specific data is then used to ...
 * 1) Check the specific column (`rowData[idKey]`) against the checkmap to determine the checked property of the checkbox
 * 2) Call the click handler with (`rowData[idKey]`) as the argument when the checkbox is clicked.
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 02-Jul-21
 *
 * __Code last updated__: 02-Jul-21
 */
export function CheckboxCell({
  labelKey,
  checkedMap,
  onClick,
  idKey,
}: ICheckboxCellProps) {
  return function Component(rowData: object): JSX.Element {
    return (
      <div className={styles.CheckboxCell}>
        <Checkbox
          checked={checkedMap[rowData[idKey]]}
          onClick={() => onClick(rowData[idKey])}
        />
        {labelKey && (
          <Typography
            text={rowData[labelKey]}
            type="body-2"
            className={styles.label}
          />
        )}
      </div>
    );
  };
}

interface IInputCellProps {
  inputMap: { [id: string]: number | string };
  onChange: (id: string, newValue: number | string) => void;
  idKey: string;
  type?: 'number' | 'text';
}

export function InputCell({
  inputMap,
  onChange,
  idKey,
  type,
}: IInputCellProps) {
  return function Component(rowData: object): JSX.Element {
    let value = inputMap[rowData[idKey]];
    if (value === null || value === undefined) {
      value = '';
    }
    return (
      <Input
        className={styles.InputCell}
        value={value}
        onChange={(newValue) => onChange(rowData[idKey], newValue)}
        renderStyle="none"
        type={type || 'text'}
      />
    );
  };
}

interface ISelectButtonOption {
  label: string;
  onClick: (data: object) => void;
  optionComponent?: (data: object) => JSX.Element;
}

interface ISelectButtonCellProps {
  label: string;
  options: ISelectButtonOption[];
  idKey: string;
  buttonProps?: object;
  className?: string;
}

export function SelectButtonCell({
  label,
  options,
  buttonProps,
  className = '',
}: ISelectButtonCellProps) {
  return function Component(rowData: object): JSX.Element {
    const enhancedOptions = options.map((option) => {
      return {
        ...option,
        onClick: () => option.onClick(rowData),
        optionComponent: option.optionComponent?.(rowData),
      };
    });

    return (
      <SelectButton
        label={label}
        options={enhancedOptions}
        dropdownClassName={styles.SelectButtonCellDropdown}
        className={[className, styles.selectButtonCell].join(' ')}
        buttonProps={buttonProps}
      />
    );
  };
}

export function LinkCell(template: string) {
  return function Component(value: string): JSX.Element {
    templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const compiled = lodashTemplate(template);
    const templated = compiled({ data: value });
    return (
      <Link to={templated} className={styles.LinkCell}>
        <Typography text={value} type="body-2" />
      </Link>
    );
  };
}

interface IIconCellProps {
  icon: string;
  iconColor?: string;
  onClick: (data: object) => void;
}

export function IconCell({ icon, iconColor, onClick }: IIconCellProps) {
  return function Component(rowData: object): JSX.Element {
    return (
      <div className={styles.IconCell} onClick={() => onClick(rowData)}>
        <Icon name={icon} color={iconColor || 'primary'} />
      </div>
    );
  };
}

export function ConditionalCheckCell(
  value: string,
  conditions: string[]
): JSX.Element {
  const isChecked = conditions.includes(value);
  return (
    <Icon
      name={isChecked ? 'Check' : 'Close'}
      color={isChecked ? 'success' : 'onSurfaceDisabled'}
    />
  );
}

interface IConditionalRefillOrderCellProps {
  onGenerate: (data: IRefillOrder) => void;
  onAdjust: (data: IRefillOrder) => void;
  onCollectPrekit: (data: IRefillOrder) => void;
  onRefill: (data: IRefillOrder) => void;
  onReadyForCollection: (data: IRefillOrder) => void;
  onCancelOrder: (data: IRefillOrder) => void;
  onUndoOrder: (data: IRefillOrder) => void;
  onDismiss: (data: IRefillOrder) => void;
}

export function ConditionalRefillOrderCell({
  onGenerate,
  onAdjust,
  onCollectPrekit,
  onRefill,
  onReadyForCollection,
  onCancelOrder,
  onUndoOrder,
  onDismiss,
}: Partial<IConditionalRefillOrderCellProps>) {
  return function Component(rowData: IRefillOrder): JSX.Element {
    if (rowData.status === 'completed') {
      return null;
    }

    if (rowData.status === 'adjustment') {
      return (
        <Button
          translationKey={isMobile ? undefined : 'action_adjust'}
          postIcon={isMobile ? { name: 'Plus' } : undefined}
          type="primary"
          onClick={() => onAdjust(rowData)}
        />
      );
    }

    const actionOptions = {
      recommended: [
        { label: 'action_generate', onClick: () => onGenerate(rowData) },
        { label: 'refill_dismiss', onClick: () => onDismiss(rowData) },
      ],
      ready_for_collection: [
        {
          label: 'refill_collect_prekit',
          onClick: () => onCollectPrekit(rowData),
        },
        { label: 'refill_cancel_order', onClick: () => onCancelOrder(rowData) },
        { label: 'refill_undo_order', onClick: () => onUndoOrder(rowData) },
      ],
      en_route: [
        { label: 'refill_refill', onClick: () => onRefill(rowData) },
        { label: 'refill_cancel_order', onClick: () => onCancelOrder(rowData) },
        { label: 'refill_undo_order', onClick: () => onUndoOrder(rowData) },
      ],
      created: [
        {
          label: 'refill_ready_for_collection',
          onClick: () => onReadyForCollection(rowData),
        },
        { label: 'refill_cancel_order', onClick: () => onCancelOrder(rowData) },
      ],
      refilled: [
        { label: 'refill_cancel_order', onClick: () => onCancelOrder(rowData) },
      ],
    };

    return (
      <SelectButton
        label="label_actions"
        options={actionOptions[rowData.status] || []}
        dropdownClassName={styles.SelectButtonCellDropdown}
        className={styles.SelectButtonCell}
      />
    );
  };
}

export function ImageCell(value: string): JSX.Element {
  const stylesSizes = { width: '50px', height: '44px' };

  if (value === undefined || value === null) {
    return <div style={stylesSizes}></div>;
  }

  return (
    <img
      src={value}
      style={{ objectFit: 'contain', ...stylesSizes }}
      alt="Avatar"
    />
  );
}

export function IsExpiredDateCell({ postDateMessage }) {
  // this cell show date in DD/MM/YYYY and add expired label if the date already past
  return function Component(stringDate: string): JSX.Element {
    const time = new Date(stringDate).getTime();
    const date = moment.unix(Number(time) / 1000);
    const dateIsPast = moment().isAfter(date);

    return (
      <div className={styles.FromNowCell}>
        <Typography text={formatMomentDate(date)} type="body-2" />
        {dateIsPast && (
          <Typography
            translationKey={postDateMessage}
            type="caption"
            className={styles.postDateMessage}
          />
        )}
      </div>
    );
  };
}

interface userData {
  name: string;
  image?: string;
}

export function UserCell(userData: userData): JSX.Element {
  const stylesSizes = { width: '50px', height: '44px', borderRadius: '50%' };

  if (userData.image === undefined || userData.image === null) {
    return (
      <React.Fragment>
        <div style={stylesSizes}></div>
        <Typography text={userData.name} type="body-2" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <img
        src={userData.image}
        style={{ objectFit: 'contain', ...stylesSizes }}
      />
      <Typography text={userData.name} type="body-2" />
    </React.Fragment>
  );
}

export function StockCell(value: string): JSX.Element {
  const getColor = (value) => {
    if (Number(value) >= 100) {
      return '#27B6BA';
    } else if (Number(value) < 100 && Number(value) > 50) {
      return '#FFB025';
    } else if (Number(value) <= 50) {
      return '#E95D50';
    }
  };
  const color = getColor(value);
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          margin: '5px 20px 0 0',
          backgroundColor: color,
          width: '10px',
          height: '10px',
          borderRadius: '10px',
        }}
      ></div>
      <Typography text={value} type="body-2" />
    </div>
  );
}

interface ISlotAndImageProp {
  image: string;
  slot: string;
}

export function SlotAndImage({ slot, image }: ISlotAndImageProp): JSX.Element {
  const stylesSizes = { width: '25px', height: '40px' };

  if (image === undefined || image === null) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <div style={{ marginRight: '20px' }}>
          <Typography text={slot} type="body-2" />
        </div>
        <div style={stylesSizes}></div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
      <div style={{ marginRight: '20px' }}>
        <Typography text={slot} type="body-2" />
      </div>
      <img src={image} style={{ objectFit: 'contain', ...stylesSizes }} />
    </div>
  );
}

interface IIngredienceProp {
  ingredience: string;
  UPC: string;
}

export function IngredienceCell({
  ingredience,
  UPC,
}: IIngredienceProp): JSX.Element {
  return (
    <div>
      <div>
        <Typography text={ingredience} type="body-2" />
      </div>
      <div style={{ color: '#aaa' }}>
        <Typography text={UPC} type="body-2" />
      </div>
    </div>
  );
}

export function AcceptTransferDateCell(stringDate: string): JSX.Element {
  const timestamp = new Date(stringDate).getTime();
  const date = formatMoment(moment.unix(Number(timestamp) / 1000)).slice(0, 8);
  return <Typography text={date} type="body-2" />;
}

interface IReviewAndAcceptCellProps {
  onClick: (data: object) => void;
}

export function ReviewAndAcceptCell({ onClick }: IReviewAndAcceptCellProps) {
  return function Component(rowData: object): JSX.Element {
    return (
      <div>
        <Button
          translationKey="action_review_and_accept"
          onClick={() => onClick(rowData)}
          type="primary"
        />
      </div>
    );
  };
}

export function AcceptTableUserCell(value: string): JSX.Element {
  return (
    <div>
      <Typography text={value} type="body-2" />
    </div>
  );
}

export function ExpireDateStatus(value: string): JSX.Element {
  const daysFromNow = moment().diff(value, 'days');

  const getColor = (value) => {
    if (Number(value) >= 60) {
      // healthy days left color
      return '#27B6BA';
    } else if (Number(value) < 14 && Number(value) > 60) {
      // pretty low
      return '#FFB025';
    } else if (Number(value) <= 14) {
      // low
      return '#E95D50';
    } else {
      // if no date show this color
      return '#ff0000';
    }
  };
  const color = getColor(-daysFromNow);
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          margin: '5px 20px 0 0',
          backgroundColor: color,
          width: '10px',
          height: '10px',
          borderRadius: '10px',
        }}
      ></div>
    </div>
  );
}

export function MachineInventoryStatusCell(status: string): JSX.Element {
  const statuses = {
    normal: {
      translationKey: 'status_available',
      color: `${colors.onBackground}`,
    },
    low: {
      translationKey: 'status_available',
      color: `${colors.onBackground}`,
    },
    abnormal: { translationKey: 'status_error', color: `${colors.error}` },
    disable: { translationKey: 'status_disable', color: `${colors.error}` },
    'disable low': {
      translationKey: 'status_disable',
      color: `${colors.error}`,
    },
    off: {
      translationKey: 'status_inactive',
      color: `${colors.error}`,
    },
  };
  return (
    <div
      style={{
        color: status
          ? statuses[status.toLowerCase()]?.color
          : `${colors.onSurfaceDisabled}`,
      }}
    >
      <Typography
        translationKey={
          status
            ? statuses[status.toLowerCase()]?.translationKey
            : 'status_none'
        }
        type="body-2"
      />
    </div>
  );
}

export function RoundImageCell(src: string | undefined | null): JSX.Element {
  const stylesSizes = {
    width: '30px',
    height: '30px',
    borderRadius: '30px',
  };

  if (src === undefined || src === null) {
    return <div style={stylesSizes}></div>;
  }

  return <img src={src} style={{ objectFit: 'contain', ...stylesSizes }} />;
}

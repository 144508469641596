import * as React from 'react';
import { useState, useEffect } from 'react';
import * as queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { pickBy } from 'lodash';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';
import Button from 'common/components/button/Button';
import Input from 'common/components/input/Input';
import Checkbox from 'common/components/checkbox/Checkbox';
import Select from 'common/components/select/Select';

import * as styles from './FilterBar.module.scss';

type IOperator = 'lt' | 'gt' | 'or' | 'incl';

interface IExistingQuery {
  title: string;
  rawKey: string;
  queryKey: string;
  operator: IOperator;
  value: any;
}

interface ICheckboxOption {
  title: string;
  queryValue: string;
}

interface ICompareOption {
  title: string;
  operator: 'lt' | 'gt';
  unit: string;
}

export interface IFilterMenuItem {
  title: string;
  queryKey: string;
  type: 'contains' | 'checkbox' | 'compare';
  unit?: string;
  checkboxOptions?: ICheckboxOption[];
  compareOptions?: ICompareOption[];
}

interface IFilterBarProps {
  menu: IFilterMenuItem[];
  icon?: string;
  className?: string;
  text?: string;
}

function FilterBar({
  icon = 'Filter',
  menu,
  className,
  text,
}: IFilterBarProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<'menu' | 'picker'>('menu');
  const [selection, setSelection] = useState<IFilterMenuItem>(null);
  const [value, setValue] = useState<string>(null);
  const [checkboxMap, setCheckboxMap] = useState<object>({});
  const [operator, setOperator] = useState<'lt' | 'gt'>('lt');
  const [existingQueries, setExistingQueries] =
    useState<IExistingQuery[]>(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>(null);

  useEffect(() => {
    if (text === null || text === undefined) {
      return;
    }
    setSearchPlaceholder(text);
  }, [checkboxMap]);

  useEffect(() => {
    if (step === 'picker') {
      const searchMap = queryString.parse(location.search);
      const existingRawKey = Object.keys(searchMap).find((key) => {
        return key.includes(selection.queryKey);
      });

      if (existingRawKey) {
        const value = searchMap[existingRawKey];
        let operator = null;
        if (existingRawKey.includes('[')) {
          operator = existingRawKey.split('[').pop().split(']')[0];
        }

        if (selection.type === 'contains') {
          setValue(value as string);
        }
        if (selection.type === 'compare') {
          setValue(value as string);
          setOperator(operator);
        }
        if (selection.type === 'checkbox') {
          const newMap = {};
          const _value = Array.isArray(value) ? value : [value];
          _value.forEach((i) => {
            newMap[i] = true;
          });
          setCheckboxMap(newMap);
        }
      }
    }
  }, [step]);

  function getExistingQueries(): IExistingQuery[] {
    const _searchMap = queryString.parse(location.search);
    return Object.keys(_searchMap)
      .map((rawKey) => {
        let queryKey = rawKey;
        let operator = null;
        if (rawKey.includes('[')) {
          queryKey = rawKey.split('[')[0];
          operator = rawKey.split('[').pop().split(']')[0];
        }
        const menuItem = menu.find((i) => i.queryKey === queryKey);
        if (menuItem) {
          return {
            title: menuItem.title,
            rawKey,
            queryKey,
            operator,
            value: _searchMap[rawKey],
          };
        }
        return null;
      })
      .filter((i) => !!i);
  }

  useEffect(() => {
    const existingQueries = getExistingQueries();
    setExistingQueries(existingQueries);
  }, [location.search]);

  function handleMenuSelection(menuItem: IFilterMenuItem): void {
    setSelection(menuItem);
    setStep('picker');
  }

  function handleClose(): void {
    setStep('menu');
    setSelection(null);
    setValue(null);
    setCheckboxMap({});
    setOperator('lt');
    setIsOpen(false);
  }

  function handleBack(): void {
    setStep('menu');
    setSelection(null);
    setValue(null);
    setCheckboxMap({});
    setOperator('lt');
  }

  function handleFilterIconClick(): void {
    if (!isOpen) {
      return setIsOpen(true);
    }
    return handleClose();
  }

  function handleApplyFilter(): void {
    let search;

    if (selection) {
      const existingSearchMap = queryString.parse(location.search);
      if (selection.type === 'contains') {
        search = queryString.stringify(
          {
            ...existingSearchMap,
            [`${selection.queryKey}[incl]`]: value || null,
          },
          { skipNull: true }
        );
      }
      if (selection.type === 'compare') {
        search = queryString.stringify(
          {
            ...existingSearchMap,
            ...(operator === 'gt'
              ? { [`${selection.queryKey}[lt]`]: null }
              : {}),
            ...(operator === 'lt'
              ? { [`${selection.queryKey}[gt]`]: null }
              : {}),
            [`${selection.queryKey}[${operator}]`]: value || null,
          },
          { skipNull: true }
        );
      }
      if (selection.type === 'checkbox') {
        const checked = pickBy(checkboxMap, (i) => !!i);
        const queryValues = Object.keys(checked);
        search = queryString.stringify(
          {
            ...existingSearchMap,
            [`${selection.queryKey}[or]`]: queryValues,
          },
          { skipNull: true }
        );
      }

      history.push({ search });
    }
    return handleClose();
  }

  function handleClearQuery(query: IExistingQuery): void {
    const existingSearchMap = queryString.parse(location.search);
    const _search = { ...existingSearchMap };
    delete _search[query.rawKey];
    const search = queryString.stringify(_search, { skipNull: true });
    history.push({ search });
  }

  return (
    <div className={[styles.FilterBar, className].join(' ')}>
      <div onClick={handleFilterIconClick} className={styles.iconWrapper}>
        <Icon
          name={icon}
          color={isOpen ? 'primary' : 'onSurfaceHigh'}
          className={styles.icon}
        />
        {location.search !== '' ? null : (
          <Typography
            className={styles.searchPlaceholder}
            text={searchPlaceholder}
            type='body-1'
          />
        )}
      </div>

      {existingQueries?.map((query, index) => {
        function getLabel() {
          if (query.operator === 'gt') {
            return 'label_greater_than';
          }
          if (query.operator === 'lt') {
            return 'label_less_than';
          }
          if (query.operator === 'incl') {
            return 'label_contains';
          }
          return 'label_semi';
        }

        return (
          <div key={index} className={styles.ExistingQuery}>
            <Typography translationKey={query.title} type='body-1' />
            &nbsp;
            <span className={styles.operator}>
              <Typography translationKey={getLabel()} type='body-1' />
            </span>
            &nbsp;
            <span className={styles.value}>
              <Typography
                text={
                  Array.isArray(query.value)
                    ? query.value.join(', ')
                    : query.value
                }
                type='body-1'
              />
            </span>
            &nbsp;
            <span
              className={styles.iconWrapper}
              onClick={() => handleClearQuery(query)}
            >
              <Icon name='CloseFilled' color='onSurfaceMedium' />
            </span>
          </div>
        );
      })}

      {isOpen && (
        <div className={styles.Picker}>
          {step === 'menu' && (
            <div className={styles.menuStep}>
              {menu.map((i, index) => {
                return (
                  <div
                    key={index}
                    className={styles.menuOption}
                    onClick={() => handleMenuSelection(i)}
                  >
                    <Typography translationKey={i.title} type='body-1' />
                  </div>
                );
              })}
            </div>
          )}

          {step === 'picker' && (
            <div className={styles.pickerStep}>
              <div className={styles.header}>
                <div className={styles.titleWrapper}>
                  <div onClick={handleBack} className={styles.backIcon}>
                    <Icon name='Chevron' color='surface' />
                  </div>
                  <Typography
                    translationKey={selection.title}
                    type='body-1'
                    className={styles.title}
                  />
                </div>
                <div onClick={handleClose} className={styles.iconWrapper}>
                  <Icon name='Close' color='surface' />
                </div>
              </div>

              <div className={styles.content}>
                <div className={styles.valuePicker}>
                  {selection.type === 'contains' && (
                    <Input
                      label='label_contains'
                      renderStyle='line'
                      value={value}
                      onChange={setValue}
                      unit={selection.unit}
                    />
                  )}

                  {selection.type === 'compare' && (
                    <div className={styles.compareItem}>
                      <Select
                        options={[
                          {
                            label: 'label_less_than',
                            value: 'lt',
                          },
                          {
                            label: 'label_greater_than',
                            value: 'gt',
                          },
                        ]}
                        value={operator}
                        onSelect={(value: 'lt' | 'gt') => setOperator(value)}
                        className={styles.select}
                        renderStyle='nobox'
                      />
                      <Input
                        renderStyle='line'
                        value={value}
                        onChange={setValue}
                        label='label_value'
                        className={styles.value}
                        unit={selection.unit}
                      />
                    </div>
                  )}

                  {selection.type === 'checkbox' &&
                    selection.checkboxOptions.map((i, index) => {
                      return (
                        <div key={index} className={styles.checkboxItem}>
                          <Checkbox
                            checked={checkboxMap[i.queryValue]}
                            onClick={() =>
                              setCheckboxMap((oldMap) => {
                                return {
                                  ...oldMap,
                                  [i.queryValue]: !oldMap[i.queryValue],
                                };
                              })
                            }
                          />
                          <Typography
                            translationKey={i.title}
                            type='body-1'
                            className={styles.label}
                          />
                        </div>
                      );
                    })}
                </div>

                <div className={styles.footer}>
                  <Button
                    translationKey='label_apply'
                    onClick={handleApplyFilter}
                    type='secondary'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FilterBar;

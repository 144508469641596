import * as React from 'react';
import { useState } from 'react';
import Autocomplete from 'components/Autocomplete';
import MuiModal from 'components/Modal/MuiModal';

import Typography from 'components/Typography';
import Button from 'components/Button';
import { IEditServiceZone } from 'machines/interfaces';

import {
  useGetServiceZonesByOrgIdQuery,
  useBatchUpdateMachinesServiceZoneMutation,
} from 'gql/generated';
import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';

import * as styles from './ChangeServiceZoneModal.module.scss';
import { toasti18n } from 'utils/toast';

interface IChangeZoneModalProps {
  selectedMachine: Array<string>;
  isOpen: boolean;
  onClose: () => void;
}

export function ChangeServiceZoneModal({
  selectedMachine,
  isOpen,
  onClose,
}: IChangeZoneModalProps): JSX.Element {
  const { data: userInfo } = useGetCurrentUser();

  const {
    mutate: usePostBatchUpdateMachineServiceZone,
    isLoading: isBatchUpdateLoading,
  } = useBatchUpdateMachinesServiceZoneMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: () => {
      toasti18n.success();
      onClose();
    },
  });

  const [zoneId, setZoneId] = useState('');
  const { data: serviceZonesByOrgIdQuery, isLoading: isServiceZoneLoading } =
    useGetServiceZonesByOrgIdQuery({ organisationId: userInfo.orgId });
  const serviceZones = serviceZonesByOrgIdQuery?.serviceZones;

  function editZone({ machineIds, serviceZoneId }: IEditServiceZone) {
    usePostBatchUpdateMachineServiceZone({ machineIds, serviceZoneId });
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      minWidth="416px"
      minHeight="204px"
      padding="24px 32px"
    >
      <div className={styles.ChangeZone}>
        <div>
          <Typography translate type="headline-6">
            label_edit_zone
          </Typography>
        </div>
        <div className={styles.EditZoneContent}>
          <Autocomplete
            value={zoneId}
            onChange={(zone) => setZoneId(zone)}
            id="serviceZone"
            label="label_service_zone"
            options={
              isServiceZoneLoading
                ? []
                : serviceZones.map((zone) => {
                    return {
                      label: zone.friendlyId,
                      value: zone.id,
                    };
                  })
            }
            required
          />
        </div>

        <div className={styles.ButtonContainer}>
          <Button
            disabled={isBatchUpdateLoading}
            type="secondary"
            onClick={onClose}
          >
            {'action_cancel'}
          </Button>
          <Button
            loading={isBatchUpdateLoading}
            onClick={() =>
              editZone({ machineIds: selectedMachine, serviceZoneId: zoneId })
            }
            className={styles.save}
            type="primary"
            disabled={zoneId === ''}
          >
            {'action_save'}
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

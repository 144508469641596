export const swapOrderKeys = {
  all: () => ["swaporders"],
  validateBatch: (machineId: Array<string>, slot: string, productId: string) =>
    [
      "swaporder",
      "validate",
      machineId.reduce((acc, curr) => {
        acc[curr] = curr;
        return acc;
      }, {}),
      slot,
      productId
    ] as const
};
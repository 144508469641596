export const machineKeys = {
  all: (params?: object) =>
    params ? (["machines", params] as const) : (["machines"] as const),
  slots: (machineIds: Array<string>) =>
    [
      "machines",
      "slots",
      machineIds.reduce((acc, curr) => {
        acc[curr] = curr;
        return acc;
      }, {})
    ] as const,
  monitor: () => [...machineKeys.all(), "monitor"] as const,
  machine: (id: string | number) => ["machine", String(id)] as const,
  machineInventory: (id: string | number) =>
    [...machineKeys.machine(id), "inventory"] as const,
  machineMaintenance: (id: string | number) =>
    [...machineKeys.machineInventory(id), "maintenance"] as const,
  machineUsers: (id: string | number) =>
    [...machineKeys.machine(id), "users"] as const,
  machineStatus: (id: string) => [...machineKeys.machine(id), "status"] as const
};

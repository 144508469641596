import * as React from "react";

export function useModifiedState<T>(initialValue: T) {
  const [state, _setState] = React.useState<T>(initialValue);
  const [modified, setModified] = React.useState(false);

  function setState(value: T) {
    _setState(value);
    setModified(true);
  }

  return [state, setState, modified] as const;
}

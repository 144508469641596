import Icon from "common/components/icon/Icon";
import Button from "components/Button";
import { JobStatus, useJobStatusQuery } from "gql/generated";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import analytics from "utils/analytics";

function isValidUrl(url: string) {
  // Regular expression for URL validation
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
}

export function DownloadReportPage() {
  const { t } = useTranslation("downloadReport");
  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(
    5000
  );
  const { jobId } = useParams<{ jobId: string }>();

  const { data, error } = useJobStatusQuery(
    {
      jobStateId: jobId,
    },
    {
      onSuccess: (data) => {
        if (data?.jobState?.status === JobStatus.Finished) {
          setRefetchInterval(false);
        }
      },
      onError: () => setRefetchInterval(false),
      refetchInterval,
    }
  );

  React.useEffect(() => {
    if (!refetchInterval && !error && isValidUrl(data?.jobState?.data)) {
      analytics.track({
        name: "transaction report generation successful",
        properties: {
          jobId,
        },
      });
    }
  }, [refetchInterval, error, data]);

  if (data?.jobState.status === JobStatus.Fail || error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="bg-white p-6 rounded-md shadow-md space-y-3 mb-20">
          <h3 className="text-h4 text-on-surface-high font-kanit text-center">
            {t("fail_state.title")}
          </h3>
          <p className="text-body1 text-on-surface-medium text-center">
            {t("fail_state.body")}
          </p>
          <div className="flex gap-3 justify-center">
            <Button
              type="secondary"
              onClick={() => window.close()}
              data-tracking-event="cancel button clicked on transaction report ready"
              data-tracking-property-jobID={jobId}
            >
              {t("fail_state.cta.close")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (
    data?.jobState?.status === JobStatus.Finished &&
    isValidUrl(data?.jobState?.data)
  ) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="bg-white p-6 rounded-md shadow-md space-y-3 mb-20">
          <h3 className="text-h4 text-on-surface-high font-kanit text-center">
            {t("success_state.title")}
          </h3>
          <p className="text-body1 text-on-surface-medium text-center">
            {t("success_state.body")}
          </p>
          <div className="flex gap-3 justify-center">
            <a href={data?.jobState?.data}>
              <Button
                type="primary"
                iconName="Download"
                data-tracking-event="download button clicked on transaction report ready"
                data-tracking-property-jobID={jobId}
              >
                {t("success_state.cta.download")}
              </Button>
            </a>
            <Button
              type="secondary"
              onClick={() => window.close()}
              data-tracking-event="cancel button clicked on transaction report ready"
              data-tracking-property-jobID={jobId}
            >
              {t("success_state.cta.close")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-full">
      <div className="bg-white p-6 rounded-md shadow-md space-y-3 mb-20">
        <div className="flex justify-center">
          <Icon name="loader" className="animate-spin" color="primary" />
        </div>
        <h3 className="text-h4 text-on-surface-high font-kanit text-center">
          {t("loading_state.title")}
        </h3>
        <p className="text-body1 text-on-surface-medium text-center">
          {t("loading_state.body")}
        </p>
        <div className="flex justify-center">
          <Button
            type="secondary"
            onClick={() => window.close()}
            data-tracking-event="cancel button clicked on generating transaction report"
            data-tracking-property-jobID={jobId}
          >
            {t("loading_state.cta.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}

import { httpClient } from 'common/services/transportService';
import { ISwapOrder } from './getSwapOrders';

export interface IPostSwapOrder {
   machineId : string,
   swapMethod :  'await_low_level' | 'immediate' | 'append' ,
   assignee :   string,
   itemsRequested : Array<{
      productId :  string,
      machineInventoryId :  string,
      slotConfig : {
          slot :  string,
          parLevel : number,
          refillLevel : number,
          capacity : number,
          additional : {
              inventoryInfo : {
                  importanceLevel : number
            }
        },
          noPartialRefill : boolean
    }
   }>
}

export async function postSwapOrder (postSwapOrderData: IPostSwapOrder): Promise<ISwapOrder> {
  const response = await httpClient.post<ISwapOrder>('/swaporder', postSwapOrderData);
  return response.data;
}
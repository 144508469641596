import * as z from "zod";

const ColumnFilterSchema = z.object({
  id: z.string(),
  value: z.union([z.string(), z.number(), z.array(z.string()), z.array(z.number())]),
});

const ColumnFilterStateSchema = z.array(ColumnFilterSchema);

export type ColumnFilterStateSchemaType = z.infer<typeof ColumnFilterStateSchema>;

export function parseColumnFilterState (state: unknown) {
  return ColumnFilterStateSchema.parse(state);
}

import * as React from 'react';

import * as styles from './RadioButton.module.scss';

interface IRadioButtonProps {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

function RadioButton ({
  checked,
  onClick,
  disabled = false,
  className
}: IRadioButtonProps): JSX.Element {
  return (
    <button
      className={[
        styles.RadioButtonContainer,
        checked ? styles.checked : '',
        disabled ? styles.disabled : '',
        className
      ].join(' ')}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        className={[
          styles.innerCircle,
          checked ? styles.checked : ''
        ].join(' ')}
      >
      </div>
    </button>
  );
}

export { RadioButton };
import * as React from "react";

import {
  Select as MuiSelect,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";

import Icon, { IconNames } from "common/components/icon/Icon";

import { useTranslation } from "react-i18next";

import * as styles from "./Select.module.scss";

interface ISelect<T extends number | string> {
  id: string;
  value: T;
  // Type manually because SelectInputProps<'' | number> gets evaluated
  // as SelectInputProps<string | number>
  onChange: (event: { target: { value: T; name: string } }) => void;
  label: string;
  options: Array<{
    value: T;
    label: string;
    icon?: {
      name: IconNames;
      color?: string;
    };
  }>;
  required?: boolean;
  className?: string;
  size?: "small";
}

export default function Select<T extends number | string>({
  id,
  value,
  onChange,
  label,
  options,
  required = false,
  className = "",
  size,
}: ISelect<T>) {
  const { t } = useTranslation();
  return (
    <FormControl
      fullWidth
      className={[styles.FormControl, className].join(" ")}
    >
      <InputLabel
        size={size}
        id={`${id}-label`}
        className={`${
          required ? styles.InputLabelRequired : styles.InputLabel
        }`}
      >
        {t(label)}
      </InputLabel>
      <MuiSelect
        size={size}
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={t(label)}
        onChange={onChange as SelectInputProps<T>["onChange"]}
        className={styles.Select}
        MenuProps={{ PaperProps: { className: styles.MenuDropdown } }}
      >
        {options.map((option) => (
          <MenuItem
            value={option.value}
            key={option.value}
            className={styles.MenuItem}
          >
            <div className={styles.ItemContainer}>
              {option.icon && (
                <div className={styles.Icon}>
                  <Icon name={option.icon.name} color={option.icon.color} />
                </div>
              )}
              <div className={styles.Label}>{t(option.label)}</div>
            </div>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Overlay--28dsQIAPBC{background-color:rgba(243,243,243,.8);z-index:1;top:0;left:0;position:absolute;width:100%;height:100%;display:flex;align-items:center;justify-content:center}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg{display:flex;flex-direction:column;align-items:center;justify-content:center}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg .IconWrapper--XRvnFxCg6d{width:150px;height:150px;margin-bottom:20px}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg .IconWrapper--XRvnFxCg6d svg{width:100%;height:100%}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg .IconWrapper--XRvnFxCg6d svg path{fill:rgba(68,69,140,.87) !important}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg .Info--2VXcpmMHEY{text-align:center;margin-bottom:90px;max-width:320px}.Overlay--28dsQIAPBC .Content--2rN78p0Xvg .Info--2VXcpmMHEY span{color:rgba(68,69,140,.87) !important;font-size:24px !important;line-height:1 !important;font-weight:400 !important}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Overlay": "Overlay--28dsQIAPBC",
	"Content": "Content--2rN78p0Xvg",
	"IconWrapper": "IconWrapper--XRvnFxCg6d",
	"Info": "Info--2VXcpmMHEY"
};
module.exports = exports;

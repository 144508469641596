import * as React from "react";
import { PaginationButton } from "./button";
import Icon from "common/components/icon/Icon";
import { PaginationType, RowPerPageValue } from "../types/tableTypes";
import { tableV2Tracking } from "../tracking";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../filter/inputs/customSelect";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { kebabCase } from "lodash";
import { useLocalStorage } from "react-use";
import { generateTableRowPerPageSettingKey } from "../setting";
import { isNullish } from "utils/isNullish";

type PaginationProps = { pagination?: PaginationType; tableName: string };

const pageSizeOptions = [
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "200", value: 200 },
];

export function Pagination({ pagination, tableName }: PaginationProps) {
  const formatTableName = kebabCase(tableName);
  const [rowPerPage, setRowPerPage] = useLocalStorage(
    generateTableRowPerPageSettingKey(formatTableName)
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2.pagination",
  });
  const {
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
    resetPagination,
  } = pagination;

  function onRowPerPageChange (e: SelectChangeEvent<unknown>) {
    setRowPerPage(e.target.value as RowPerPageValue);
    pagination?.onRowPerPageChange(
      e.target.value as RowPerPageValue
    );
  }

  React.useLayoutEffect(() => {
    if (!isNullish(rowPerPage) && !isNaN(Number(rowPerPage))) {
      const state = Number(rowPerPage);
      pagination?.onRowPerPageChange(state as RowPerPageValue);
    }
  }, []);

  if (!pagination) return null;

  return (
    <div className="flex justify-end gap-3 mt-4">
      <div className="flex gap-1">
        {pagination?.rowPerPage && pagination?.onRowPerPageChange && (
          <div className="flex items-baseline gap-1">
            <p className="text-body-2 text-on-surface-high">
              {t("label_item_per_page")}
            </p>
            <CustomSelect
              variant="standard"
              value={pagination?.rowPerPage || 20}
              onChange={onRowPerPageChange}
              className="bg-white border border-outline rounded-[4px] overflow-hidden"
              sx={{
                "&.MuiInputBase-root .MuiSelect-select": {
                  paddingLeft: "16px",
                  background: "white"
                },
                "&:before": {
                  content: "none"
                },
                "&:after": {
                  content: "none"
                },
              }}
            >
              {pageSizeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        )}
        {pagination?.resetPagination ? (
          <PaginationButton
            disabled={!hasPreviousPage}
            onClick={() => {
              resetPagination();
              tableV2Tracking({
                name: "reset pagination clicked",
                properties: {
                  has_next_page: hasNextPage,
                  has_previous_page: hasPreviousPage,
                },
                tableName,
              });
            }}
          >
            <Icon
              className="w-4 h-4"
              name="DoubleChevronLeft"
              color={!hasPreviousPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
            />
          </PaginationButton>
        ) : null}
        <PaginationButton
          disabled={!hasPreviousPage}
          onClick={() => {
            onPreviousPage();
            tableV2Tracking({
              name: "previous button clicked",
              properties: {
                has_next_page: hasNextPage,
                has_previous_page: hasPreviousPage,
              },
              tableName,
            });
          }}
        >
          <Icon
            className="w-4 h-4 rotate-180"
            name="ChevronRight"
            color={!hasPreviousPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
          />
        </PaginationButton>
        <PaginationButton
          disabled={!hasNextPage}
          onClick={() => {
            onNextPage();
            tableV2Tracking({
              name: "next button clicked",
              properties: {
                has_next_page: hasNextPage,
                has_previous_page: hasPreviousPage,
              },
              tableName,
            });
          }}
        >
          <Icon
            className="w-4 h-4"
            name="ChevronRight"
            color={!hasNextPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
          />
        </PaginationButton>
      </div>
    </div>
  );
}

import { useImmer } from "use-immer";

type CompletedModalState = {
  status: "VIEW" | "EDIT";
  productForReadjustment: null | {
    id: string;
    actualReturnBag: string;
    actualReturnRemaining: string;
  };
};

export type useCompletedModalStateReturnValue = {
  isViewing: boolean;
  isEditing: boolean;
  product: null | {
    id: string;
    actualReturnBag: string;
    actualReturnRemaining: string;
  };
  reset: () => void;
  selectProductForAdjusment: (args: {
    productId: string;
    actualReturnBag: string;
    actualReturnRemaining: string;
  }) => void;
  editActualReturnBag: (args: {
    actualReturnBag: string;
  }) => void;
  editActualReturnRemaining: (args: {
    actualReturnRemaining: string;
  }) => void;
  checkIfInvalid: () => boolean | undefined;
  checkIfEditingProduct: (productId: string) => boolean;
};


export function useCompletedModalState(): useCompletedModalStateReturnValue {
  const [state, setState] = useImmer<CompletedModalState>({
    status: "VIEW",
    productForReadjustment: null,
  });

  function checkIfEditingProduct(productId: string) {
    return state.productForReadjustment?.id === productId;
  }

  function selectProductForAdjusment({
    productId, actualReturnBag, actualReturnRemaining,
  }: {
    productId: string;
    actualReturnBag: string;
    actualReturnRemaining: string;
  }) {
    setState((draft) => {
      if (draft.status === "VIEW") draft.status = "EDIT";
      draft.productForReadjustment = {
        id: productId,
        actualReturnBag,
        actualReturnRemaining,
      };
    });
  }

  function editActualReturnBag({
    actualReturnBag,
  }: {
    actualReturnBag: string;
  }) {
    setState((draft) => {
      if (draft.status !== "EDIT") return;
      if (!draft.productForReadjustment) return;
      draft.productForReadjustment.actualReturnBag = actualReturnBag;
    });
  }
  function editActualReturnRemaining({
    actualReturnRemaining,
  }: {
    actualReturnRemaining: string;
  }) {
    setState((draft) => {
      if (draft.status !== "EDIT") return;
      if (!draft.productForReadjustment) return;
      draft.productForReadjustment.actualReturnRemaining =
        actualReturnRemaining;
    });
  }

  function reset() {
    setState((draft) => {
      draft.status = "VIEW";
      draft.productForReadjustment = null;
    });
  }

  function checkIfInvalid() {
    if (state.status === "VIEW") return undefined;
    if (!state.productForReadjustment)
      throw new Error("Product must exist in edit mode");
    return (
      state.productForReadjustment.actualReturnBag === "" ||
      state.productForReadjustment.actualReturnRemaining === ""
    );
  }

  return {
    isViewing: state.status === "VIEW",
    isEditing: state.status === "EDIT",
    product: state.productForReadjustment,
    reset,
    selectProductForAdjusment,
    editActualReturnBag,
    editActualReturnRemaining,
    checkIfInvalid,
    checkIfEditingProduct,
  };
}

import {
  useGetWarehouseId,
  warehousingRoutes,
} from "containers/warehousing/routes";
import * as React from "react";
import { useGetWarehouseById } from "../WarehouseOverview/use-get-warehouse";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import { useGetWarehouseSupportedMachinesById } from "./use-get-warehouse-supported-machines-by-id";
import { Fallback } from "components/Fallback";
import { ErrorBoundary } from "@sentry/react";
import { WarehousingWorkspacePaddingContainer } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import { FilteBar } from "containers/warehousing/components/FilteBar";
import { IAppliedFilter } from "common/components/filterbar/PureFilterBar";
import { warehouseBorderStyle } from "containers/warehousing/components/borderStyle";
import { Table } from "../../../../components/Table";
import { TableHeader } from "components/TableHeader";
import Typography from "components/Typography/Typography";
import { StatusCell } from "common/components/table/cells/Cells";
import { useTranslation } from "react-i18next";

export function WarehouseIdSupportedMachines() {
  const warehouseId = useGetWarehouseId();
  const { data: warehouse, isLoading } = useGetWarehouseById(warehouseId);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WarehousingHeader
        title={"label_support_machine"}
        isLoading={isLoading}
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
            link: warehousingRoutes.warehouses(),
          },
          {
            label: warehouse?.name,
            link: warehousingRoutes.warehousesWithWarehouseId(warehouseId),
          },
          {
            label: "label_support_machine",
          },
        ]}
      />
      <div style={{ flex: "1 1 0px", minHeight: "0px", background: "white" }}>
        <ErrorBoundary fallback={Fallback}>
          <SupportedMachinesTable />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function SupportedMachinesTable() {
  const { t } = useTranslation();
  const warehouseId = useGetWarehouseId();
  const { data, isLoading } = useGetWarehouseSupportedMachinesById(warehouseId);
  const [filterState, setFilterState] = React.useState<IAppliedFilter>(null);

  const filteredData = React.useMemo(() => {
    if (!data || !filterState) return data;

    return data.filter((machine) => {
      if (filterState.field === COLUMNS.MACHINE_ID) {
        return machine.id.includes(filterState.value);
      }
      return false;
    });
  }, [data, filterState]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ borderBottom: warehouseBorderStyle }}>
        <WarehousingWorkspacePaddingContainer>
          <FilteBar
            value={filterState}
            onChange={setFilterState}
            filterOptions={[
              {
                field: COLUMNS.MACHINE_ID,
                queryType: "contains",
                translationKey: "label_machine_id",
              },
            ]}
            placeholder="label_search"
          />
        </WarehousingWorkspacePaddingContainer>
      </div>
      <div style={{ flex: "1 1 0", minHeight: "0px" }}>
        <Table
          data={filteredData}
          isLoading={isLoading}
          getKey={(machine) => machine.id}
          RowWrapper={WarehousingWorkspacePaddingContainer}
          columns={[
            {
              id: COLUMNS.MACHINE_ID,
              headerCell: () => <TableHeader>{t("label_machine_id")}</TableHeader>,

              cell: (machine) => (
                <Typography type="body-2">{machine.id}</Typography>
              ),
              style: {
                flex: "127 0 0px",
                minWidth: "105px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: COLUMNS.STATUS,
              headerCell: () => <TableHeader>{t("label_status")}</TableHeader>,
              style: {
                flex: "155 0 0px",
                minWidth: "105px",
                display: "flex",
                alignItems: "center",
              },
              cell: (machine) => StatusCell(machine.status),
            },
            {
              id: COLUMNS.NAME,
              headerCell: () => <TableHeader>{t("label_name")}</TableHeader>,
              style: {
                flex: "907 0 0px",
                minWidth: "0px",
                display: "flex",
                alignItems: "center",
              },
              cell: (machine) => (
                <Typography type="body-2">{machine.name}</Typography>
              ),
            },
            {
              id: COLUMNS.TYPE,
              headerCell: () => <TableHeader>{t("label_type")}</TableHeader>,
              style: {
                flex: "123 0 0px",
                minWidth: "60px",
                display: "flex",
                alignItems: "center",
              },
              cell: (machine) => (
                <Typography type="body-2">{machine.type}</Typography>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

const COLUMNS = {
  MACHINE_ID: "machineId",
  STATUS: "status",
  NAME: "name",
  TYPE: "type",
} as const;

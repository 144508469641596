import * as React from 'react';

interface IDashboardDialProps {
  color?: string;
  className?: string;
}

function DashboardDial ({
  color = 'white',
  className
}: IDashboardDialProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.99992 3.33334C4.97123 3.33334 0.833252 7.63244 0.833252 12.9924V15.2652C0.833252 15.579 1.07369 15.8333 1.37036 15.8333H18.6295C18.9261 15.8333 19.1666 15.579 19.1666 15.2652V12.9924C19.1666 7.66425 15.0501 3.33334 9.99992 3.33334ZM16.481 13.5606H18.0924V14.697H1.90747V13.5606H3.5188C3.81547 13.5606 4.05591 13.3063 4.05591 12.9924C4.05591 12.6786 3.81547 12.4243 3.5188 12.4243H1.92593C2.0465 10.5035 2.7737 8.75475 3.90764 7.38904L5.02676 8.57305C5.23657 8.79499 5.5766 8.79499 5.78641 8.57305C5.99608 8.3511 5.99608 7.9914 5.78641 7.76945L4.66827 6.58678C5.96712 5.38605 7.63286 4.6159 9.46281 4.48894V6.17425C9.46281 6.48808 9.70325 6.74243 9.99992 6.74243C10.2966 6.74243 10.537 6.48808 10.537 6.17425V4.48894C12.367 4.6159 14.0327 5.38605 15.3316 6.58663L14.2134 7.76945C14.0038 7.9914 14.0038 8.3511 14.2134 8.57305C14.4232 8.79484 14.7633 8.79499 14.9731 8.57305L16.0922 7.38904C17.2261 8.75475 17.9533 10.5035 18.0739 12.4243H16.481C16.1844 12.4243 15.9439 12.6786 15.9439 12.9924C15.9439 13.3061 16.1844 13.5606 16.481 13.5606Z"
        fill={color}
      />
      <path
        d="M11.8101 12.4167C11.8101 12.1481 11.7495 11.8937 11.6416 11.6661L13.9939 9.33218C14.2218 9.10599 14.2245 8.7367 13.9998 8.50716C13.7751 8.27778 13.4083 8.27504 13.1804 8.50124L10.8241 10.8393C10.5962 10.7287 10.341 10.6667 10.0717 10.6667C9.11312 10.6667 8.33325 11.4518 8.33325 12.4167C8.33325 13.3816 9.11312 14.1667 10.0717 14.1667C11.0302 14.1667 11.8101 13.3816 11.8101 12.4167ZM10.0717 13C9.75219 13 9.49219 12.7383 9.49219 12.4167C9.49219 12.0951 9.75219 11.8334 10.0717 11.8334C10.3911 11.8334 10.6511 12.0951 10.6511 12.4167C10.6511 12.7383 10.3911 13 10.0717 13Z"
        fill={color}
      />
    </svg>
  );
}

export default DashboardDial;

import {
  InventoryStatusString,
  isValidRefillPriority,
  RefillPriorityString,
} from "../../../Components";
import { computeInventoryStatus } from "../../utils";
import { formatCurrency } from "utils/currency";
import { GetCreatedPlanByIdQuery, MachineKind } from "gql/generated";

type PlanResponse = GetCreatedPlanByIdQuery["plan"];
type RefillOrderResponse = PlanResponse["refillOrders"][number];
type MachineInventoriesResponse =
  RefillOrderResponse["machine"]["machineInventories"];
type SwapOrdersResponse = RefillOrderResponse["swapOrders"];

export interface MachineMap {
  [machineId: string]: Machine;
}
export interface Machine {
  machineId: string;
  kind: MachineKind;
  parentId?: string;
  positionInPlan: number;
  refillPriority: RefillPriorityString | null;
  cashStatus: {
    isBankNotesFull: boolean;
    isCoinFull: boolean;
  };
  error: {
    mostRecentErrorCode: number;
    recentErrorDetails: Array<any>;
  };
  location: {
    id: string;
    type: string;
    revenue: string;
    rawRevenue: number;
    name: string;
    grade: string;
  };
  lastRefilled: string;
  cashValue: number;
  inventory: InventoryMap;
  refillOrder: {
    refillOrderId: string;
    refillTime: string;
    locationNote: string;
    criticalNote: string;
    critical: boolean;
    swapOrders: Array<{
      swapOrderId: string;
      swapItems: Array<{ swapItemId: string; inventoryReference: Inventory }>;
    }>;
  };
}

export function computeMachineMapFromPlanAndUsage(
  plan: PlanResponse
): MachineMap {
  const entries: [machineId: string, machine: Machine][] = plan?.refillOrders?.map(
    (refillOrder) => {
      const { machine, machineId } = refillOrder;
      const { machineInventories } = machine;

      const inventoryMap =
        computeInventoryMapFromMachineInventoriesAndMachineUsage(
          machineInventories
        );
      modifyInventoryProductForSwapOrders({
        inventory: inventoryMap,
        swapOrders: refillOrder.swapOrders,
      });
      const swapOrders = generateSwapOrdersWithAttachedInventoryRefs({
        inventory: inventoryMap,
        swapOrders: refillOrder.swapOrders,
      });

      return [
        machineId,
        {
          machineId: refillOrder.machineId,
          parentId: refillOrder?.machine?.parentId,
          kind: refillOrder.machine.kind,
          positionInPlan: refillOrder.positionInPlan,
          refillPriority:
            machine.location.__typename !== "Factory" &&
            isValidRefillPriority(machine.location.refillPriority)
              ? machine.location.refillPriority
              : null,
          cashStatus: {
            isBankNotesFull: machine.cashStatus.isBankNoteFull,
            isCoinFull: machine.cashStatus.isCoinFull,
          },
          error: {
            mostRecentErrorCode: machine.latestStatus?.code,
            recentErrorDetails: [],
          },
          location: {
            id: machine.location.id,
            type: machine.location.locationType,
            grade:
              machine.location.__typename !== "Factory"
                ? machine.location.locationGrade
                : "-",
            revenue: formatCurrency({
              input:
                machine.location.__typename !== "Factory"
                  ? machine.location.revenueSinceLastRefilled
                  : null,
              minimumFractionDigits: 2,
            }),
            rawRevenue: machine.location.__typename !== "Factory"
              ? machine.location.revenueSinceLastRefilled
              : 0,
            name: machine.location.locationName,
          },
          lastRefilled: machine.lastRefilled,
          cashValue: machine.cashValue,
          inventory: inventoryMap,
          refillOrder: {
            refillOrderId: refillOrder.id,
            refillTime: refillOrder.estimatedArrival,
            locationNote: refillOrder.note,
            criticalNote: refillOrder.criticalNote,
            critical: refillOrder.isCritical,
            swapOrders,
          },
        },
      ];
    }
  );
  return Object.fromEntries(entries);
}

interface InventoryMap {
  [inventoryId: string]: Inventory;
}

export interface Inventory {
  inventoryId: string;
  slot: string;
  isAutoRefillOn: boolean;
  pendingSwapMaterial: boolean;
  noPartialRefill: boolean;
  inventoryStatus: InventoryStatusString | null;
  inventoryLevel: {
    low: number;
    high: number;
    current: number;
    baseline: number;
    capacity: number;
  };
  recommendedAction: null | "REFILL" | "SWAP_REFILL_ON_TOP" | "SWAP_REPLACE";
  currentProduct: {
    sku: string;
    name: string;
  };
  productToUse: {
    productId: string;
    uom: string;
    img: string;
    sku: string;
    packageSize: number;
    name: string;
    amountRequired: {
      recommendAmount: number;
      roundedToPackageSize: number;
    };
  };
}

function computeInventoryMapFromMachineInventoriesAndMachineUsage(
  machineInventories: MachineInventoriesResponse
): InventoryMap {
  const inventories = machineInventories.map((machineInventory) => {
    const inventoryId = machineInventory.id;
    const product = machineInventory.product;

    const low = machineInventory.parLevel;
    const current = machineInventory.value;
    const high = machineInventory.refillLevel;
    const capacity = machineInventory.capacity;

    const baseline = Math.max(high - current, 0);


    return {
      inventoryId,
      slot: machineInventory.slot,
      isAutoRefillOn: machineInventory.autoRefill,
      noPartialRefill: Boolean(machineInventory.noPartialRefill),
      inventoryStatus: computeInventoryStatus(machineInventory.status),
      pendingSwapMaterial: machineInventory.hasPendingSwap,
      currentProduct: {
        sku: product.SKU,
        name: product.name,
      },
      inventoryLevel: {
        low,
        current,
        high,
        capacity,
        baseline,
      },
      recommendedAction: machineInventory.refillOverview.recommendedPackageCount > 0 ? "REFILL" : null,
      productToUse: {
        productId: product.id,
        uom: product.uom,
        img: product.image,
        sku: product.SKU,
        packageSize: product.package,
        name: product.name,
        amountRequired: {
          recommendAmount: machineInventory.refillOverview.recommendedAmount,
          roundedToPackageSize: machineInventory.refillOverview.recommendedPackageCount,
        },
      },
    };
  });

  const inventoriesEntries = inventories.map((inventory) => [
    inventory.inventoryId,
    inventory,
  ]);

  return Object.fromEntries(inventoriesEntries);
}

// have to rewrite this function 
function modifyInventoryProductForSwapOrders({ 
  inventory,
  swapOrders,
}: {
  swapOrders: SwapOrdersResponse;
  inventory: InventoryMap;
}) {
  swapOrders.forEach((swapOrder) =>
    swapOrder.swapItems.forEach((swapItem) => {
      const machineInventory = inventory[swapItem.machineInventoryId];
      const swapProduct = swapItem.product;
      machineInventory.recommendedAction =
        swapOrder.swapMethod === "append"
          ? ("SWAP_REFILL_ON_TOP" as const)
          : ("SWAP_REPLACE" as const);

      const low = swapItem.parLevel;
      const high = swapItem.refillLevel;
      const capacity = swapItem.capacity;
      const current = machineInventory.inventoryLevel.current;

      const currentLevelUsedForCalculation =
        machineInventory.recommendedAction === "SWAP_REPLACE" ? 0 : current;

      const baseline = Math.max(high - currentLevelUsedForCalculation, 0);

      // Use new slot configuration for calculating recommended packages
      machineInventory.inventoryLevel = {
        low,
        high,
        capacity,
        current,
        baseline,
      };

      machineInventory.productToUse = {
        productId: swapProduct.id,
        uom: swapProduct.uom,
        img: swapProduct.image,
        sku: swapProduct.SKU,
        packageSize: swapProduct.package,
        name: swapProduct.name,
        amountRequired: machineInventory.productToUse.amountRequired,
      };
    })
  );
}
function generateSwapOrdersWithAttachedInventoryRefs({
  inventory,
  swapOrders,
}: {
  swapOrders: SwapOrdersResponse;
  inventory: InventoryMap;
}): Machine["refillOrder"]["swapOrders"] {
  return swapOrders.map((swapOrder) => ({
    swapOrderId: swapOrder.orderNumber,
    swapItems: swapOrder.swapItems.map((swapItem) => ({
      swapItemId: swapItem.id,
      inventoryReference: inventory[swapItem.machineInventoryId],
    })),
  }));
}

import * as React from 'react';
import { TextCard } from './TextCard';
import { useGetDailyDrinkCategorySalesQuery } from 'gql/generated';
import { capitalize } from 'lodash';

export function BestSellingDrinkCategory(): JSX.Element {
  const { isError, data } = useGetDailyDrinkCategorySalesQuery(undefined, {
    enabled: false,
    useErrorBoundary: false,
  });

  return (
    <TextCard
      isError={isError}
      data={data?.dailyDrinkCategorySales.slice(0, 6).map((drink) => ({
        label: capitalize(drink.drinkCategory),
        value: drink.totalCount.toLocaleString(),
      }))}
      icon="CoffeeCup"
      title="Best-Selling Drink Category"
      labelType="Drink Category"
      valueType="Total Cup Sales"
    />
  );
}

import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import {
  QueryFunctionContext,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { queryClient } from "index";

const productLotsQueryKey = {
  productLots: (productId: string) =>
    [
      {
        ...getProductLotsScope()[0],
        productId: productId,
        url: "/lots?productId=:productId",
        resolvedUrl: `/lots?productId=${productId}`,
      },
    ] as const,
};
type ProductLotsQueryKey = ReturnType<
  typeof productLotsQueryKey["productLots"]
>;

function getProductLotsScope() {
  return [
    {
      scope: "lots",
    },
  ] as const;
}

export function invalidateAllProductLots() {
  queryClient.invalidateQueries(getProductLotsScope());
}

export function getCachedProductLots() {
  const query = queryClient.getQueriesData<ProductLots>(getProductLotsScope());
  return query;
}

export function findProductLotQueryKeyByLotId(lotId: string) {
  const productLotQuery = getCachedProductLots().find(([_, productLot]) => {
    if (!productLot) return false;

    const lotsSearchResult = productLot.lots.findIndex((lot) => {
      return lot.id === lotId;
    });
    return lotsSearchResult !== -1;
  });

  if (productLotQuery) {
    const [queryKey] = productLotQuery;
    return queryKey;
  }

  return undefined;
}

export interface ProductLots {
  lots: Lot[];
  productDetail: ProductDetail;
}

export interface Lot {
  id: string;
  productId: string;
  lotNo: string;
  expiryDate: string;
  warehouseId: string;
  quantity: number;
  totalUsed: number;
  organisationId: string;
  receivedDate: string;
  updatedAt: string;
  createdAt: string;
  isDeleted: boolean;
  expiryDateUTC: unknown;
  receivedDateUTC: unknown;
  updatedAtUTC: string;
  createdAtUTC: unknown;
  version: number;
  lotId: string;
  totalQuantity: number;
}

export interface ProductDetail {
  id: string;
  image: string;
  inventoryCode: string;
  SKU: string;
  name: string;
  description: string;
  manufacturer: string;
  package: number;
  uom: string;
  machineCategory: string;
  additional: unknown;
  packageType: string;
  organisationId: string;
  disabled: boolean;
  updatedAt: string;
  createdAt: string;
  updatedAtUTC: unknown;
  createdAtUTC: unknown;
  catalogId: string;
  productId: string;
  UPC: string;
  totalQuantity: number;
}

async function getLotsByProductId(
  context: QueryFunctionContext<ProductLotsQueryKey>
) {
  const [{ productId }] = context.queryKey;
  const response = await httpClient.get<ProductLots>(
    `/lots?productId=${productId}`
  );
  return response.data;
}

type GetLotsByProductIdQueryOptions = Omit<
  UseQueryOptions<ProductLots, AxiosError, ProductLots, ProductLotsQueryKey>,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetProductByIdBase({
  productId,
  options = {},
}: {
  productId: string;
  options?: GetLotsByProductIdQueryOptions;
}) {
  return useQuery({
    queryKey: productLotsQueryKey.productLots(productId),
    queryFn: getLotsByProductId,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetProductLotsByWarehouseId(
  { warehouseId, productId }: { warehouseId: string; productId: string },
  options?: GetLotsByProductIdQueryOptions
) {
  const { data, ...rest } = useGetProductByIdBase({
    productId: productId,
    options,
  });

  return {
    data: data?.lots.filter((lot) => lot.warehouseId === warehouseId),
    ...rest,
  };
}

export type AddNewLotRequest = {
  productId: string;
  warehouseId: string;
  receivedDate: string;
  expiryDate: string;
  // location: "NOT_USED";
  // supplierId: "NOT_USED";
  quantity: number;
  additional: {
    reason: AddNewLotReason;
  };
};

export const ADD_NEW_LOT_REASONS = {
  PURCHASE: "purchase",
  FROM_REPACKAGE: "from_repackage",
  BACK_FROM_REFILL_MACHINE: "back_from_refill_machine",
  STOCK_ADJUSTMENT: "stock_adjustment",
} as const;

export type AddNewLotReason =
  typeof ADD_NEW_LOT_REASONS[keyof typeof ADD_NEW_LOT_REASONS];

const ADD_NEW_LOT_REASON_TRANSLATIONS = {
  [ADD_NEW_LOT_REASONS.PURCHASE]: "label_purchase",
  [ADD_NEW_LOT_REASONS.FROM_REPACKAGE]: "label_from_repackage",
  [ADD_NEW_LOT_REASONS.BACK_FROM_REFILL_MACHINE]:
    "label_back_from_refill_machine",
  [ADD_NEW_LOT_REASONS.STOCK_ADJUSTMENT]: "label_stock_adjustment",
} as const;

export function getAddNewLotReasonTranslation(
  key: AddNewLotReason | (string & {})
): undefined | string {
  return ADD_NEW_LOT_REASON_TRANSLATIONS[key];
}

export async function addNewLotInWarehouse(data: AddNewLotRequest) {
  return await httpClient.post("/lots", {
    ...data,
    location: "UNUSED",
    supplierId: "UNUSED",
  });
}

export function useAddNewLotInWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, AddNewLotRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewLotInWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, variables] = args;
      queryClient.invalidateQueries(
        productLotsQueryKey.productLots(variables.productId)
      );
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

export type WithdrawLotRequest = {
  warehouseId: string;
  justification: WithdrawLotReason;
  lotId: string;
  quantity: number;
};

export const WITHDRAW_LOT_REASONS = {
  REFILL_MACHINE: "refill_machine",
  GENERAL_WITHDRAW: "general_withdraw",
  WITHDRAW_FOR_REPACKAGE: "withdraw_for_repackage",
  ADJUST_STOCK: "adjust_stock",
  SELL_TO_OPERATOR: "sell_to_operator",
  DAMAGE_GOODS: "damage_goods",
  EXPIRED: "expired",
} as const;

export type WithdrawLotReason =
  typeof WITHDRAW_LOT_REASONS[keyof typeof WITHDRAW_LOT_REASONS];

const WITHDRAW_LOT_REASONS_TRANSLATIONS = {
  [WITHDRAW_LOT_REASONS.REFILL_MACHINE]: "label_refill_machine",
  [WITHDRAW_LOT_REASONS.GENERAL_WITHDRAW]: "label_general_withdraw",
  [WITHDRAW_LOT_REASONS.WITHDRAW_FOR_REPACKAGE]: "label_withdraw_package",
  [WITHDRAW_LOT_REASONS.ADJUST_STOCK]: "label_adjust_stock",
  [WITHDRAW_LOT_REASONS.SELL_TO_OPERATOR]: "label_sell_to_operator",
  [WITHDRAW_LOT_REASONS.DAMAGE_GOODS]: "label_damage_goods",
  [WITHDRAW_LOT_REASONS.EXPIRED]: "label_expired",
} as const;

export function getWithdrawLotReasonTranslation(
  key: WithdrawLotReason | (string & {})
): undefined | string {
  return WITHDRAW_LOT_REASONS_TRANSLATIONS[key];
}

async function withDrawLotFromWarehouse(data: WithdrawLotRequest) {
  return await httpClient.put("/lots/withdraw", {
    warehouseId: data.warehouseId,
    justification: data.justification,
    payload: [
      {
        lotId: data.lotId,
        quantity: data.quantity,
      },
    ],
  });
}

export function useWithdrawLotFromWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, WithdrawLotRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: withDrawLotFromWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, variables] = args;
      const queryKeyToInvalidate = findProductLotQueryKeyByLotId(
        variables.lotId
      );

      if (queryKeyToInvalidate) {
        queryClient.invalidateQueries(queryKeyToInvalidate);
      }

      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

export type EditLotRequest = {
  lotId: string;
  expiryDate: string; // "Thu Dec 07 2023 12:00:00 GMT+0700"
  receivedDate: string;
  quantity: number;
};

async function editProductLot(data: EditLotRequest) {
  return await httpClient.patch(`/lots/${data.lotId}`, {
    location: "NOT_USED",
    expiryDate: data.expiryDate,
    receivedDate: data.receivedDate,
    quantity: data.quantity,
  });
}

export function useEditProductLot({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, EditLotRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editProductLot,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, variables] = args;
      const queryKeyToInvalidate = findProductLotQueryKeyByLotId(
        variables.lotId
      );

      if (queryKeyToInvalidate) {
        queryClient.invalidateQueries(queryKeyToInvalidate);
      }

      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

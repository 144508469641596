import * as React from 'react';

interface IDiamondProps {
  color?: string;
  className?: string;
}

function Diamond({ color = 'white', className }: IDiamondProps): JSX.Element {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 1.12402L12.8013 8.32587L8.00002 15.5277L3.19879 8.32587L8.00002 1.12402ZM5.94723 10.0448L8.00003 13.124L10.0528 10.0448L8.00003 11.0712L5.94723 10.0448ZM4.97363 8.06732L8.00003 3.52772L11.0264 8.06732L8.00003 9.58052L4.97363 8.06732Z"
        fill={color}
      />
    </svg>
  );
}

export default Diamond;

import * as React from 'react';

interface IPlayProps {
  color?: string;
  className?: string;
}

function Play({ color = 'white', className }: IPlayProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Play</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Play">
          <rect
            id="Rectangle"
            fillRule="nonzero"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <path
            d="M17.3371,12.4218 L7.76844,18.511 C7.43558,18.7228 7,18.4837 7,18.0892 L7,5.91084 C7,5.51629 7.43558,5.27718 7.76844,5.48901 L17.3371,11.5782 C17.6459,11.7746 17.6459,12.2254 17.3371,12.4218 Z"
            id="Path"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Play;

import {
  GetMaintenanceOverviewDocument,
  GetMaintenanceOverviewQuery,
  GetMaintenanceOverviewQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getErrorOverviews({
  serviceZoneIds,
}: {
  serviceZoneIds?: string[];
}) {
  return fetcher<
    GetMaintenanceOverviewQuery,
    GetMaintenanceOverviewQueryVariables
  >(GetMaintenanceOverviewDocument, {
    filter: {
      serviceZoneIds
    },
  })();
}

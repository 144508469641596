import { IOption } from "common/components/select/Select";

export const groupByOptions: IOption[] = [
  { label: "label_none", value: "allTransactionNone" },
  { label: "label_group_by_error", value: "groupByError" },
  { label: "label_drink_name", value: "productName" },
  { label: "label_machine", value: "machineId" },
  {
    label: "label_daily_machine_sales",
    value: "dailyMachineSales",
  },
  {
    label: "label_weekly_machine_sales",
    value: "weeklyMachineSales",
  },
  { label: "label_user", value: "telephoneNumber" },
  { label: "label_status", value: "status" },
  { label: "label_campaign_code", value: "codeId" },
  { label: "label_campaign_code_type", value: "codeType" },
  { label: "label_location", value: "locationId" },
];

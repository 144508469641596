import * as React from 'react';

// Colors
import * as SCSScolors from 'common/styles/colors.scss';

// Custom components
import Typography from 'common/components/typography/Typography';
import { Indicator, IIndicatorProps } from 'common/components/Indicator';

// Styles
import * as styles from './textAndIndicatorCell.module.scss';
import Tooltip from '@mui/material/Tooltip';

interface ItextAndIndicatorCell {
  indicatorType: IIndicatorProps['type'];
}

export interface ItextAndIndicatorCellRenderProp {
  indicatorColor?: IIndicatorProps['color'];
  translationKey?: string | string[];
  tooltipTitle?: string | string[];
  textColor?: string;
}

function textAndIndicatorCell({
  indicatorType = 'circle',
}: ItextAndIndicatorCell) {
  return function textAndIndicatorCellRenderProp({
    indicatorColor,
    translationKey,
    textColor,
    tooltipTitle,
  }: ItextAndIndicatorCellRenderProp): JSX.Element {
    return (
      <Tooltip disableFocusListener title={tooltipTitle ?? ''} placement='top'>
        <div
          className={styles.textAndIndicatorCell}
          style={{ color: SCSScolors[textColor] }}
        >
          {indicatorColor && (
            <Indicator
              color={indicatorColor}
              type={indicatorType}
              className={styles.indicator}
            />
          )}
          <Typography translationKey={translationKey} type='body-2' />
        </div>
      </Tooltip>
    );
  };
}

export { textAndIndicatorCell };

import { IFilterOption } from 'common/components/filterbar/PureFilterBar';

const filterOptionsGroupByNone: Array<IFilterOption> = [
  {
    field: 'drinkName',
    queryType: 'contains',
    translationKey: 'label_drink_name'
  },
  {
    field: 'drinkCode',
    queryType: 'contains',
    translationKey: 'label_drink_code'
  },
  {
    field: 'userId',
    queryType: 'contains',
    translationKey: 'label_user'
  },
  {
    field: 'machineId',
    queryType: 'contains',
    translationKey: 'label_machine_id'
  },
  {
    field: 'machineName',
    queryType: 'contains',
    translationKey: 'label_machine_name'
  }
];

const filterOptionsGroupByProductName: Array<IFilterOption> = [
  {
    field: 'drinkName',
    queryType: 'contains',
    translationKey: 'label_drink_name'
  },
  {
    field: 'drinkCode',
    queryType: 'contains',
    translationKey: 'label_drink_code'
  }
];

const filterOptionsGroupByTelephoneNumber: Array<IFilterOption> = [
  {
    field: 'userId',
    queryType: 'contains',
    translationKey: 'label_user'
  }
];

const filterOptionsGroupByMachineId: Array<IFilterOption> = [
  {
    field: 'machineId',
    queryType: 'contains',
    translationKey: 'label_machine_id'
  },
  {
    field: 'machineName',
    queryType: 'contains',
    translationKey: 'label_machine_name'
  }
];

const filterOptionsGroupByDaily: Array<IFilterOption> = [
  {
    field: 'orgName',
    queryType: 'contains',
    translationKey: 'label_organisation'
  }
];

const filterOptionsGroupByCodeId: Array<IFilterOption> = [
  {
    field: 'codeId',
    queryType: 'contains',
    translationKey: 'label_code_id'
  },
  {
    field: 'codeDescription',
    queryType: 'contains',
    translationKey: 'label_code_description'
  },
];

const filterOptionsGroupByCodeType: Array<IFilterOption> = [
  {
    field: 'codeType',
    queryType: 'contains',
    translationKey: 'label_code_type'
  }
];

const filterOptionsGroupByLocationId: Array<IFilterOption> = [
  // {
  //   field: 'locationId',
  //   queryType: 'contains',
  //   translationKey: 'label_location_id'
  // },
  // {
  //   field: 'locationName',
  //   queryType: 'contains',
  //   translationKey: 'label_location_name'
  // },
  // {
  //   field: 'locationType',
  //   queryType: 'contains',
  //   translationKey: 'label_location_type'
  // }
];

const filterOptionsGroupByLocationType: Array<IFilterOption> = [
  {
    field: 'locationType',
    queryType: 'contains',
    translationKey: 'label_location_type'
  }
];

const filterOptionsForGroupBy = {
  none: filterOptionsGroupByNone,
  productName: filterOptionsGroupByProductName,
  telephoneNumber: filterOptionsGroupByTelephoneNumber,
  machineId: filterOptionsGroupByMachineId,
  dailyMachineSales: filterOptionsGroupByDaily,
  weeklyMachineSales: [] as IFilterOption[],
  status: [] as IFilterOption[],
  codeId: filterOptionsGroupByCodeId,
  codeType: filterOptionsGroupByCodeType,
  locationId: filterOptionsGroupByLocationId,
  locationType: filterOptionsGroupByLocationType,
};

export { filterOptionsForGroupBy };

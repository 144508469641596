import * as React from 'react';

import Icon from 'common/components/icon/Icon';

import * as styles from './Splash.module.scss';

function Splash (): JSX.Element {
  return (
    <div className={styles.Splash}>
      <div className={styles.logoWrapper}>
        <Icon
          name='Vendii'
          className={styles.logo}
        />
      </div>
    </div>
  );
}

export default Splash;

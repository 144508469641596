import * as React from "react";

interface IArrowBottomProps {
  color?: string;
  className?: string;
}

function ArrowBottom({
  color = "white",
  className,
}: IArrowBottomProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00008 10.5858V2H9.00008V10.5858L12.293 7.29289L13.7072 8.70711L8.00008 14.4142L2.29297 8.70711L3.70718 7.29289L7.00008 10.5858Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowBottom;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header--3nBll8IHIV{background-color:#fff;padding-left:39px;padding-right:39px;margin:-25px -35px 0 -35px;box-shadow:0px 1px 4px rgba(0,0,0,.13)}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB{padding-top:21px;align-items:center;border-bottom:1px solid #cbcce6}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB>:first-child{margin-right:33px}@media(min-width: 768px){.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB{display:flex}}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP{display:flex}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .Tab--1CPQELk6zU{height:56px;flex-basis:0;min-width:0;flex-grow:1;padding-left:8px;padding-right:8px;display:flex;align-items:center;justify-content:center}@media(min-width: 768px){.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .Tab--1CPQELk6zU{flex-grow:unset;min-width:unset;flex-basis:unset}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .Tab--1CPQELk6zU>:first-child{margin-right:6px}}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .ActiveTab--2kgbeGY7rO{color:#4b4d9b;position:relative}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .ActiveTab--2kgbeGY7rO::after{content:\"\";position:absolute;height:2px;border-radius:1px;background-color:#4b4d9b;left:0;right:0;bottom:-2px}.Header--3nBll8IHIV .HeadingContainer--fNyipkbxOB .HeadingTabs--vQpB1J6ytP .InactiveTab--3Nv0fs_FHV{color:#8688c5}.TabButtonsContainer---xaFdK-UWe{display:flex;gap:12px;min-width:0;overflow-x:auto;padding-top:23px;padding-bottom:23px;margin-right:-39px;padding-right:39px}@media(min-width: 768px){.TabButtonsContainer---xaFdK-UWe{padding-top:16px;padding-bottom:16px}}.TabButtonsContainer---xaFdK-UWe>*{min-width:145px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Header": "Header--3nBll8IHIV",
	"HeadingContainer": "HeadingContainer--fNyipkbxOB",
	"HeadingTabs": "HeadingTabs--vQpB1J6ytP",
	"Tab": "Tab--1CPQELk6zU",
	"ActiveTab": "ActiveTab--2kgbeGY7rO",
	"InactiveTab": "InactiveTab--3Nv0fs_FHV",
	"TabButtonsContainer": "TabButtonsContainer---xaFdK-UWe"
};
module.exports = exports;

import * as React from "react";

import { useTranslation } from "react-i18next";

import { COLORS } from "pdf/constants";
import { Checkbox, FormField } from "pdf/components/atoms";
import { Text } from "pdf/components/primitives/Text";
import ReactPDF, {
  Document,
  Page,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import { Package, PlanPackageSummary } from "./type";


export function OperationTaobinPackageChecklist({
  data,
}: {
  data: PlanPackageSummary;
}) {
  return (
    <Document>
      <Page size="A4" style={{ padding: 32 }}>
        <Header data={data} />
        <View style={{ height: 15 }} />
        <SummaryBox data={data} />
        <View style={{ height: 18 }} />
        <Table packages={data.packages} />
        <View
          wrap={false}
          style={{
            marginTop: 13,
          }}
        >
          <FooterForm />
        </View>
      </Page>
    </Document>
  );
}

function Header({ data }: { data: PlanPackageSummary }) {
  const { t } = useTranslation();
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: -3,
        }}
      >
        <Text
          style={{
            fontFamily: "Kanit-regular",
            fontSize: 20,
            letterSpacing: 0.15,
            color: COLORS.onSurfaceHigh,
          }}
        >
          {`${t("label_plan")} (${t('label_package_summary')})`}
        </Text>
        <Text
          style={{
            fontFamily: "Kanit-light",
            fontSize: 12,
            letterSpacing: 0.25,
            color: COLORS.onSurfaceHigh,
            marginTop: 5,
          }}
        >
          {`${t("label_date")}: ${data.createdAt.format("DD/MM/YYYY")}`}
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontFamily: "Kanit-medium",
            fontSize: 20,
          }}
        >
          {data.planName}
        </Text>
      </View>
    </>
  );
}

function SummaryBox({ data }: { data: PlanPackageSummary }) {
  const { t } = useTranslation();
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        paddingVertical: 6,
        paddingHorizontal: 16,
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 4,
        borderColor: "#000000",
      }}
    >
      <PlanHeaderItem title={t("label_plan_id")} value={data.planFriendlyId} />

      <PlanHeaderItem
        title={t("label_no_of_machine")}
        value={data.numberOfMachines.toString()}
        style={{
          marginLeft: 16,
          maxWidth: 127,
        }}
      />
      <PlanHeaderItem
        title={t("label_route")}
        value={data.routeNames.join(", ")}
        style={{
          marginLeft: 16,
          maxWidth: 127,
        }}
      />
      <PlanHeaderItem
        title={t("label_warehouse")}
        value={data.warehouseName}
        style={{
          marginLeft: 16,
          maxWidth: 127,
        }}
      />
    </View>
  );
}

function PlanHeaderItem({
  title,
  value,
  ...rest
}: { title: string; value: string } & ReactPDF.ViewProps) {
  return (
    <View {...rest}>
      <Text
        style={{
          fontFamily: "Kanit-regular",
          fontSize: 10,
          letterSpacing: 0.4,
          color: COLORS.onSurfaceMedium,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          fontFamily: "Kanit-medium",
          fontSize: 12,
          color: COLORS.onSurfaceHigh,
        }}
      >
        {value}
      </Text>
    </View>
  );
}

function Table({ packages }: { packages: Package[] }) {
  return (
    <>
      <TableHeader />
      {packages.map((packageUnit, index) => (
        <TableRow packageUnit={packageUnit} key={index} />
      ))}
    </>
  );
}

function TableRow({ packageUnit }: { packageUnit: Package }) {
  const tableRowText: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceHigh,
    fontFamily: "Kanit-light",
    fontSize: 11,
  };
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
      }}
      wrap={false}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -2,
          marginBottom: -2,
        }}
      >
        <View style={styles.skuCell}>
          <Text
            style={{
              color: COLORS.onSurfaceMedium,
              fontSize: 7,
              fontFamily: "Kanit-regular",
            }}
          >
            {packageUnit.packageSKU}
          </Text>
        </View>
        <View style={styles.ingredientCell}>
          <Text
            style={{ ...styles.tableRowText, paddingRight: 5 }}
            breakWordAnywhere
          >
            {packageUnit.productName}{" "}
          </Text>
        </View>

        <View style={styles.unitCell}>
          <Text
            style={{
              ...tableRowText,
              paddingRight: 5,
            }}
            breakWordAnywhere
          >
            {packageUnit.productQuantityPerPackage +
              packageUnit.productUOMInPackage +
              "/" +
              packageUnit.packageUOM}
          </Text>
        </View>
        <View style={{ ...styles.totalCell, paddingRight: 5 }}>
          <Text
            style={{
              ...tableRowText,
            }}
          >
            {packageUnit.numberOfPackages *
              packageUnit.productQuantityPerPackage +
              packageUnit.productUOMInPackage}
          </Text>
        </View>
        <View style={styles.quantityCell}>
          <Text
            style={{
              fontFamily: "Kanit-medium",
              fontSize: 14,
              fontWeight: 800,
            }}
          >
            {packageUnit.numberOfPackages}
          </Text>
        </View>
        <View style={styles.packedCell}>
          <Checkbox />
        </View>
      </View>
    </View>
  );
}

function TableHeader() {
  const tableHeaderStyles: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceMedium,
    fontFamily: "Kanit-regular",
    fontSize: 10,
    lineHeight: 16 / 10,
    letterSpacing: 0.4,
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
        borderBottomWidth: 1,
      }}
    >
      <View style={styles.skuCell}>
        <Text translate style={tableHeaderStyles}>
          label_sku
        </Text>
      </View>
      <View style={styles.ingredientCell}>
        <Text translate style={tableHeaderStyles}>
          label_ingredient
        </Text>
      </View>
      <View style={styles.unitCell}>
        <Text translate style={tableHeaderStyles}>
          label_unit
        </Text>
      </View>
      <View style={styles.totalCell}>
        <Text translate style={tableHeaderStyles}>
          label_total
        </Text>
      </View>
      <View style={styles.quantityCell}>
        <Text translate style={tableHeaderStyles}>
          {"label_quantity_short"}{" "}
        </Text>
      </View>
      <View style={styles.packedCell}>
        <Text translate style={tableHeaderStyles}>
          label_packed
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tableRowText: {
    color: COLORS.onSurfaceHigh,
    fontFamily: "Kanit-light",
    fontSize: 11,
  },
  skuCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 65,
    flexBasis: 65,
  },
  ingredientCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    flexBasis: 0,
    flexGrow: 1,
  },
  unitCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 100,
    flexBasis: 100,
  },
  totalCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 58,
    flexBasis: 58,
  },
  quantityCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 30,
    flexBasis: 30,
  },
  packedCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 54,
    flexBasis: 54,
  },
});

function FooterForm() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flexBasis: 265,
          minWidth: 265,
        }}
      >
        <FormField
          label="label_notes"
          numberOfLines={3}
          numberOfDots={85}
          type="text"
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          flexBasis: 0,
          minWidth: 0,
        }}
      >
        <FormField
          label="label_pack_by"
          numberOfDots={27 * 3 + 4}
          type="text"
        />
        <FormField label="label_pack_date" numberOfDots={27} type="date" />
      </View>
    </View>
  );
}

import Button from "components/Button/Button";
import { Modal, Title } from "containers/warehousing/components/ReusableModal";
import * as React from "react";
import { useEditProductLot } from "./use-get-product-lots";
import { toasti18n } from "utils/toast";
import { useImmer } from "use-immer";
import * as moment from "moment";
import DatePicker from "components/DatePicker/DatePicker";
import TextField from "components/TextField/TextField";
import Icon from "common/components/icon/Icon";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";

function useCanEditLot() {
  const canEditLot = useIsAdmin() || useIsAdminContract();
  return canEditLot;
}

export function EditLotModalButton(props: {
  lotId: string;
  initialData: { expiryDate: string; receivedDate: string; quantity: number };
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canEditLot = useCanEditLot();
  if (!canEditLot) {
    return null;
  }

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(true)}
      >
        <Icon name="PencilCreate" color="primary500" />
      </div>
      {isOpen ? (
        <EditLotModal onClose={() => setIsOpen(false)} {...props} />
      ) : null}
    </>
  );
}

function EditLotModal(props: {
  lotId: string;
  onClose: () => void;
  initialData: {
    expiryDate: string;
    receivedDate: string;
    quantity: number;
  };
}) {
  const [form, setForm] = useImmer<{
    receivedDate: moment.Moment | null;
    expiryDate: moment.Moment | null;
    quantity: string;
  }>({
    expiryDate: moment(props.initialData.expiryDate),
    receivedDate: moment(props.initialData.receivedDate),
    quantity: String(props.initialData.quantity),
  });

  const isFormDisabled =
    !form.expiryDate ||
    !form.expiryDate.isValid() ||
    !form.receivedDate ||
    !form.receivedDate.isValid() ||
    !form.quantity ||
    isNaN(Number(form.quantity));

  const { mutate, isLoading } = useEditProductLot({
    onSuccess: () => {
      toasti18n.success();
      props.onClose();
    },
  });

  function handleEdit() {
    mutate({
      lotId: props.lotId,
      expiryDate: form.expiryDate.toString(),
      receivedDate: form.receivedDate.toString(),
      quantity: Number(form.quantity),
    });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "720px",
        alignment: "start",
        heightStyles: {
          flex: "1 1 auto",
          maxHeight: "679px",
        },
      }}
    >
      <Title>action_edit_lot</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          marginTop: "25px",
        }}
      >
        <TextField
          required
          label="label_quantity"
          value={form.quantity}
          onChange={(e) =>
            setForm((draft) => {
              draft.quantity = e.target.value;
            })
          }
        />

        <DatePicker
          label="label_received"
          value={form.receivedDate}
          onChange={(value) => {
            setForm((draft) => {
              draft.receivedDate = value;
            });
          }}
          required
        />
        <DatePicker
          label="label_expiration"
          value={form.expiryDate}
          onChange={(value) => {
            setForm((draft) => {
              draft.expiryDate = value;
            });
          }}
          required
        />
      </div>
      <div style={{ flex: "1 1 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={handleEdit}
          loading={isLoading}
          disabled={isFormDisabled}
        >
          action_edit
        </Button>
      </div>
    </Modal>
  );
}

import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { inviteUser } from "userandauth/servicesUser";
import { userKeys } from "./userKeys";
import { IInviteUser } from "userandauth/interfacesUser";
import { toasti18n } from "utils/toast";

function useInviteUser({ onSuccess }: { onSuccess: () => void }) {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, IInviteUser>(
    (newUserData: IInviteUser) => inviteUser(newUserData),
    {
      onSuccess: () => {
        toasti18n.success();
        onSuccess();
        queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
}

export { useInviteUser };

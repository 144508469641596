import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { routes } from "./routes";
import { ErrorBoard } from "./pages/ErrorBoard";

import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import * as styles from "./Maintenance.module.scss";

export function Maintenance() {

  return (
    <div className={styles.Maintenance}>
      <ErrorBoundary fallback={Fallback}>
        <Switch>
          <Route path={routes.errorBoard()}>
            <ErrorBoard />
          </Route>
          <Redirect to={routes.errorBoard()} />
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

import Typography from 'common/components/typography/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as styles from './LinkCell.module.scss';

export interface LinkCellProps {
  to: string,
  translationKey: string
}

function LinkCell({to, translationKey} : LinkCellProps){
  return (
    <Link
      to={to}
      className={styles.LinkCell}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Typography
        translationKey={translationKey}
        type='body-2'
        className={styles.Link}
      />
    </Link>
  );
}

export { LinkCell };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmationModal--2rCnESu88s{flex-direction:column;width:416px}.ConfirmationModal--2rCnESu88s .modalTitle--1hD9Z_YOFh{margin-bottom:24px}.ConfirmationModal--2rCnESu88s .modalText--2sB4R9damN{margin-bottom:24px}.ConfirmationModal--2rCnESu88s .buttonContainer--lZ-0XiRCeU{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"ConfirmationModal": "ConfirmationModal--2rCnESu88s",
	"modalTitle": "modalTitle--1hD9Z_YOFh",
	"modalText": "modalText--2sB4R9damN",
	"buttonContainer": "buttonContainer--lZ-0XiRCeU"
};
module.exports = exports;

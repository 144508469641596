import * as React from "react";

interface IPriorityVeryHighProps {
  className?: string;
}

function PriorityVeryHigh({ className }: IPriorityVeryHighProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.47876 7.89999C2.97876 8.19999 2.37876 7.99999 2.07876 7.49999C1.77876 6.99999 1.97876 6.49999 2.47876 6.19999L7.47876 3.19999C7.77876 2.99999 8.17876 2.99999 8.47876 3.19999L13.4788 6.19999C13.9788 6.49999 14.0788 7.09999 13.7788 7.59999C13.5788 7.99999 12.9788 8.19999 12.4788 7.89999L7.97876 5.19999L3.47876 7.89999Z"
        fill="#E95D50"
      />
      <path
        d="M3.47876 12.2C2.97876 12.5 2.37876 12.4 2.07876 11.9C1.77876 11.4 1.97876 10.8 2.47876 10.5L7.47876 7.49998C7.77876 7.29998 8.17876 7.29998 8.47876 7.49998L13.4788 10.5C13.9788 10.8 14.0788 11.4 13.7788 11.9C13.4788 12.4 12.8788 12.5 12.3788 12.2L7.97876 9.49998L3.47876 12.2Z"
        fill="#F89389"
      />
    </svg>
  );
}

export default PriorityVeryHigh;

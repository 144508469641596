import * as React from 'react';

// Custom components
import Icon from '../../../icon/Icon';

// Styles
import * as styles from './EllipsisIcon.module.scss';

interface IEllipsisIconProps {
  onClick: () => void;
  className?: string;
}

function EllipsisIcon ({
  onClick,
  className
}: IEllipsisIconProps): JSX.Element {
  return (
    <div
      onClick={onClick}
      className={[ className, styles.EllipsisIcon ].join(' ')}
    >
      <Icon name="Ellipsis" color="primary400" />
    </div>
  );
}

export { EllipsisIcon };

import * as React from "react";
import * as moment from "moment";

import { useState } from "react";

import Typography from "common/components/typography/Typography";

import Table, { IColumn } from "common/components/table/Table";
import TextButton from "common/components/textbutton/TextButton";
import InviteMemberModal from "./Modal/InviteMemberModal";
import RemoveMemberModal from "./Modal/RemoveMemberModal";

import {
  AvatarCellWithText,
  StringCell,
} from "common/components/table/cells/Cells";
import { AdminIconCell } from "./TeamTableCell/IconCell/AdminIconCell";
import { DropdownCell } from "containers/settings/component/Team/TeamTableCell/DropdownCell/DropdownCell";
import { useGetUsers } from "userandauth/hooks/useGetUsers";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { usePatchUserRole } from "userandauth/hooks/usePatchUserRole";

import * as styles from "./Team.module.scss";
import { useIsAdmin, isAdminRole, getRoleTranslation, ROLE, isAdminContractRole, isRoutemanRole, isTechnicianRole, useIsAdminContract } from "utils/user-role";
import { useQueryClient } from "react-query";
import { useImmer } from "use-immer";

import {
  AutoCompleteSelectionCell,
  AutoCompleteSelectionCellAction,
} from "./TeamTableCell/AutoCompleteSelectionCell/AutoCompleteSelectionCell";
import {
  GetRefillZonesByOrgIdQuery,
  GetServiceZonesByOrgIdQuery,
  useGetRefillZonesByOrgIdQuery,
  useGetServiceZonesByOrgIdQuery,
} from "gql/generated";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import { IUsers } from "userandauth/interfacesUser";
import {
  useGetRefillZonesPermissions,
  useGetServiceZonePermissions,
  REFILL_ZONE_PERMISSION_KEY,
  SERVICE_ZONE_PERMISSION_KEY,
  GetServiceZonePermissionsResponse,
  GetRefillZonePermissionsResponse,
} from "../../../../reactquery/user-permissions";
import {
  ResourceType,
  setUserPermissions,
} from "../../../../services/user-permission";
import { ArrayElement } from "types/utils";
import { toasti18n } from "utils/toast";
import { useGetOwnPermission } from "hooks/permission";
import { AuditLogModalButton } from "./Modal/AuditLogModal/AuditLogModal";

function Team(): JSX.Element {
  const queryClient = useQueryClient();
  const {
    allowed: isAllowedToAssignToAdminRole,
    isLoading: isLoadingCurrentUserPermission,
  } = useGetOwnPermission({
    action: "WRITE",
    resourceType: "ADMIN_USER_ROLE",
  });
  const { data: users, isLoading: isUsersLoading } = useGetUsers();
  const { data: refillZonePermissions } = useGetRefillZonesPermissions(
    isUsersLoading
      ? undefined
      : users.filter((user) => isRoutemanRole(user.role)).map((user) => user.id)
  );
  const { data: serviceZonePermissions } = useGetServiceZonePermissions(
    isUsersLoading
      ? undefined
      : users
          .filter((user) => isTechnicianRole(user.role))
          .map((user) => user.id)
  );

  const { data: user } = useGetCurrentUser();
  const { data: serviceZonesQuery, isLoading: isServiceZoneLoading } =
    useGetServiceZonesByOrgIdQuery(
      { organisationId: user.orgId },
      { staleTime: 5 * 60 * 1000 }
    );
  const serviceZones = React.useMemo(
    () =>
      serviceZonesQuery?.serviceZones.sort((a, b) =>
        alphanumeric(a.friendlyId, b.friendlyId)
      ),
    [serviceZonesQuery]
  );
  const { data: refillZonesQuery, isLoading: isRefillZoneLoading } =
    useGetRefillZonesByOrgIdQuery(
      {
        organisationId: user.orgId,
      },
      {
        staleTime: 5 * 60 * 1000,
      }
    );
  const refillZones = React.useMemo(
    () =>
      refillZonesQuery?.refillZones.sort((a, b) =>
        alphanumeric(a.friendlyId, b.friendlyId)
      ),
    [refillZonesQuery]
  );

  const isLoading =
    isServiceZoneLoading ||
    isRefillZoneLoading ||
    isUsersLoading ||
    isLoadingCurrentUserPermission;

  const tableData: TableRow[] = React.useMemo(
    () =>
      isLoading
        ? []
        : mapDataToTable(
            users,
            serviceZonePermissions,
            serviceZones,
            refillZonePermissions,
            refillZones
          ),
    [
      users,
      serviceZonePermissions,
      serviceZones,
      refillZonePermissions,
      refillZones,
    ]
  );

  const [requestStates, setRequestStates] = useImmer<{
    [userId: string]: { [zoneId: string]: boolean };
  }>({});
  function setUserRequestStatusForZoneId({
    userId,
    zoneId,
    isLoading,
  }: {
    userId: string;
    zoneId: string;
    isLoading: boolean;
  }) {
    setRequestStates((draft) => {
      if (draft[userId]) {
        draft[userId][zoneId] = isLoading;
      } else {
        draft[userId] = { [zoneId]: isLoading };
      }
    });
  }
  function isOptionLoading({
    userId,
    zoneId,
  }: {
    userId: string;
    zoneId: string;
  }): boolean {
    return requestStates[userId]?.[zoneId];
  }
  async function handleZoneClick({
    userId,
    action,
    zone,
    resourceType,
  }: {
    userId: string;
    action: AutoCompleteSelectionCellAction;
    zone: ArrayElement<TableRow["refillZones"] | TableRow["serviceZones"]>;
    resourceType: ResourceType;
  }) {
    const zoneId = zone.id;
    if (isOptionLoading({ userId, zoneId })) return;

    setUserRequestStatusForZoneId({ userId, zoneId, isLoading: true });

    try {
      await setUserPermissions([
        {
          userId,
          operation: action === "ADD" ? "add" : "delete",
          resourceId: zoneId,
          action: "read",
          resourceType,
        },
      ]);
      await queryClient.invalidateQueries([
        resourceType === "refillZone"
          ? REFILL_ZONE_PERMISSION_KEY
          : SERVICE_ZONE_PERMISSION_KEY,
        { userId },
      ]);
      toasti18n.success();
    } catch (err) {
      toasti18n.error(err as Error);
    }
    setUserRequestStatusForZoneId({ userId, zoneId, isLoading: false });
  }

  const { mutate: patchUser } = usePatchUserRole();
  const [modalOpen, setModalOpen] = useState<ITeamModal>("none");
  const [userToUpdate, setUserToUpdate] = useState<TableRow>(null);

  const onClickRemoveUser = (user: TableRow) => {
    setUserToUpdate(user);
    setModalOpen("removeMember");
  };

  const onClickInvite = () => {
    setModalOpen("inviteMember");
  };

  const onCancelModal = () => {
    setModalOpen("none");
  };

  const onChangeRole = (role: string, rowData: TableRow) => {
    patchUser({
      userId: rowData.id,
      newUserData: {
        role: role,
      },
    });
  };

  const adminColumns: IColumn[] = [
    {
      dataKey: "name",
      headerLabel: "label_name",
      cellRenderer: (name: string, rowData: TableRow) =>
        AvatarCellWithText(name, rowData.avatar),
      align: "flex-start",
      columnFlex: 2,
      sortable: true,
      showMobile: true,
    },
    {
      headerLabel: "",
      cellRenderer: (rowData: TableRow) => {
        return <AuditLogModalButton userId={rowData.id} />;
      },
      columnFlex: 0.2,
    },
    {
      dataKey: "email",
      headerLabel: "label_email",
      cellRenderer: StringCell,
      align: "flex-start",
      columnFlex: 2,
      sortable: true,
      showMobile: true,
    },
    // {
    //   dataKey: "phonenumber",
    //   headerLabel: "label_phone",
    //   cellRenderer: StringCell,
    //   align: "flex-start",
    //   columnFlex: 2,
    //   showMobile: true,
    // },
    {
      dataKey: "lastActive",
      headerLabel: "label_last_active",
      cellRenderer: StringCell,
      align: "flex-start",
      columnFlex: 1,
      showMobile: true,
    },
    {
      headerLabel: "label_role",
      columnFlex: 1,
      cellRenderer: DropdownCell({
        onChangeRole: onChangeRole,
        options: Object.values(ROLE).map((role) => ({
          label: getRoleTranslation(role),
          value: role,
          hidden: (isAdminRole(role) && !isAllowedToAssignToAdminRole) || isAdminContractRole(role),
        })),
        currentUserId: user.userId,
      }),
    },
    {
      headerLabel: "label_route",
      cellRenderer: function AutoCompleteDropDown(data: TableRow) {
        if (isRoutemanRole(data.role)) {
          return (
            <AutoCompleteSelectionCell
              value={data.refillZones}
              compareFn={(a, b) => a.id === b.id}
              getOptionDisplay={(rz) => rz.friendlyId}
              areOptionsLoading={isRefillZoneLoading}
              onChange={({ action, value }) => {
                handleZoneClick({
                  action,
                  zone: value,
                  userId: data.id,
                  resourceType: "refillZone",
                });
              }}
              getOptionId={(rz) => rz.id}
              isOptionLoading={(option) =>
                isOptionLoading({
                  userId: data.id,
                  zoneId: option.id,
                })
              }
              options={refillZones}
              filterFn={(rzs, query) =>
                rzs.filter((rz) =>
                  rz.friendlyId.toLowerCase().includes(query.toLowerCase())
                )
              }
            />
          );
        }

        if (isTechnicianRole(data.role)) {
          return (
            <AutoCompleteSelectionCell
              value={data.serviceZones}
              compareFn={(a, b) => a.id === b.id}
              getOptionDisplay={(sz) => sz.friendlyId}
              areOptionsLoading={isServiceZoneLoading}
              onChange={({ action, value }) => {
                handleZoneClick({
                  action,
                  zone: value,
                  userId: data.id,
                  resourceType: "serviceZone",
                });
              }}
              getOptionId={(sz) => sz.id}
              isOptionLoading={(option) =>
                isOptionLoading({
                  userId: data.id,
                  zoneId: option.id,
                })
              }
              options={serviceZones}
              filterFn={(szs, query) =>
                szs.filter((sz) =>
                  sz.friendlyId.toLowerCase().includes(query.toLowerCase())
                )
              }
            />
          );
        }

        return <div>-</div>;
      },
      align: "flex-start",
      columnFlex: 1,
      showMobile: true,
    },
    {
      headerLabel: "",
      cellRenderer: AdminIconCell({
        onClick: onClickRemoveUser,
        icon: "TrashInventoryV2",
        currentUserId: user.userId,
      }),
      columnFlex: 0.2,
    },
  ];

  const columns: IColumn[] = [
    {
      dataKey: "name",
      headerLabel: "label_name",
      cellRenderer: (name: string, rowData: TableRow) =>
        AvatarCellWithText(name, rowData.avatar),
      align: "flex-start",
      columnFlex: 2,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: "email",
      headerLabel: "label_email",
      cellRenderer: StringCell,
      align: "flex-start",
      columnFlex: 2,
      sortable: true,
      showMobile: true,
    },
    // {
    //   dataKey: "phonenumber",
    //   headerLabel: "label_phone",
    //   cellRenderer: StringCell,
    //   align: "flex-start",
    //   columnFlex: 2,
    //   showMobile: true,
    // },
    {
      dataKey: "lastActive",
      headerLabel: "label_last_active",
      cellRenderer: StringCell,
      align: "flex-start",
      columnFlex: 1,
      showMobile: true,
    },
    {
      dataKey: "role",
      headerLabel: "label_role",
      cellRenderer: function MapRoleToLabel(value: string) {
        return StringCell(getRoleTranslation(value));
      },
      align: "flex-start",
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
  ];

  return (
    <div className={styles.team}>
      {modalOpen === "inviteMember" && (
        <InviteMemberModal onClose={onCancelModal} inviterOrgId={user.orgId} />
      )}
      {modalOpen === "removeMember" && (
        <RemoveMemberModal onClose={onCancelModal} userData={userToUpdate} />
      )}
      <div className={styles.header}>
        <div className={styles.left}>
          <Typography type="headline-5" translationKey="label_team" />
        </div>
        <div className={styles.right}>
          {(useIsAdmin() || useIsAdminContract()) && (
            <TextButton
              className={styles.customiseTextButton}
              icon="Plus"
              onClick={onClickInvite}
              translationKey="label_invite_people"
              showBorder={true}
            />
          )}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table
          defaultSortColumn="name"
          defaultSortDirection="desc"
          data={tableData}
          columns={isAdminRole(user.role) || isAdminContractRole(user.role) ? adminColumns : columns}
        />
      </div>
    </div>
  );
}

export default Team;

type ITeamModal = "removeMember" | "inviteMember" | "none";

export interface TableRow {
  name: string;
  email: string;
  role: string;
  id: string;
  lastActive: string | number;
  avatar: string;
  refillZones?: Array<{
    id: string;
    friendlyId: string;
  }>;
  serviceZones?: Array<{
    id: string;
    friendlyId: string;
  }>;
}

function mapDataToTable(
  users: IUsers[],
  serviceZonePermissions: GetServiceZonePermissionsResponse,
  serviceZones: GetServiceZonesByOrgIdQuery["serviceZones"],
  refillZonePermissions: GetRefillZonePermissionsResponse,
  refillZones: GetRefillZonesByOrgIdQuery["refillZones"]
): TableRow[] {
  return users.map((user) => {
    const lastLogin =
      user.lastLogin === 0
        ? "label_yet_to_login"
        : moment.unix(user.lastLogin / 1000).format("DD MMM YYYY hh:mm a");

    return Object.assign(
      {
        name: user.firstName,
        email: user.email,
        role: user.role,
        id: user.id,
        lastActive: lastLogin,
        avatar: user.userAvatar,
      },
      isTechnicianRole(user.role)
        ? {
            serviceZones:
              serviceZonePermissions[user.id]
                ?.map((serviceZoneId) =>
                  serviceZones.find((sz) => sz.id === serviceZoneId)
                )
                .sort((a, b) => alphanumeric(a.friendlyId, b.friendlyId)) ?? [],
          }
        : {},
      isRoutemanRole(user.role)
        ? {
            refillZones:
              refillZonePermissions[user.id]
                ?.map((refillZoneId) =>
                  refillZones.find((rz) => rz.id === refillZoneId)
                )
                .sort((a, b) => alphanumeric(a.friendlyId, b.friendlyId)) ?? [],
          }
        : {}
    );
  });
}

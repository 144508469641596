import * as React from "react";

type PaginationButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
};

export function PaginationButton({
  children,
  ...rest
}: PaginationButtonProps) {
  return (
    <button
      className="cursor-pointer w-8 h-8 flex items-center justify-center rounded-[4px] border border-outline border-solid bg-white"
      {...rest}
    >
      {children}
    </button>
  );
}

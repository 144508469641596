import { httpClient } from "common/services/transportService";
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

const queryKeyFactory = {
  productsCatalog: () => [{ scope: "productCatalog" }],
};

export type ProductInfo = {
  productId: string;
  inventoryCode: string;
  productSKU: string;
  productName: string;
  productDescription: null | string;
  packageType: string;
  packageValue: number;
  packageUnit: string;
  productImage: null | string;
  totalStock: number;
  vendor: string;
  organisationId: string;
  enoughFor: string;
  disabled: boolean;
  machineCategory: string;
};

export type ProductsCatalog = {
  id: string;
  name: string;
  deleted: boolean;
  products: Array<ProductInfo>;
  updatedAt: string;
  createdAt: string;
};

async function getProductsCatalog() {
  const response = await httpClient.get<ProductsCatalog>("/catalog");
  return response.data;
}

export function useGetProductCatalogBase<T>({
  select,
}: {
  select?: (response: ProductsCatalog) => T;
}) {
  return useQuery({
    queryKey: queryKeyFactory.productsCatalog(),
    queryFn: getProductsCatalog,
    staleTime: 1000 * 60 * 3,
    select,
  });
}

export function useGetProductCatalog() {
  return useGetProductCatalogBase<ProductInfo[]>({
    select: (response) => (response.products ? response.products : []),
  });
}

export function useGetProductByIdFromCatalog(id: string) {
  const getProductCatalogQuery = useGetProductCatalogBase({
    select: (response) =>
      response.products.find((product) => product.productId === id),
  });

  if (
    !getProductCatalogQuery.isLoading &&
    getProductCatalogQuery.data === undefined
  ) {
    throw new Error("Product not found in catalog");
  }
  return getProductCatalogQuery;
}

export type Product = {
  inventoryCode: string;
  UPC: string;
  name: string;
  image: string;
  description: string;
  package: number;
  packageType: string;
  uom: string;
  machineCategory: string;
  manufacturer: string;
};

type AddNewInventoryRequest = Product;
async function addNewInventory(product: AddNewInventoryRequest) {
  return await httpClient.post("/products", [product]);
}

export function useAddNewInventory({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, AddNewInventoryRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addNewInventory,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(queryKeyFactory.productsCatalog());
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

type EditInventoryRequest = Partial<Product> & { id: string };

async function editInventory(product: EditInventoryRequest) {
  const { id, ...restOfProduct } = product;
  return await httpClient.patch("/products/" + product.id, restOfProduct);
}

export function useEditInventory({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, EditInventoryRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editInventory,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(queryKeyFactory.productsCatalog());

      onSuccess?.(...args);
    },
    ...restOptions,
  });
}


import * as React from 'react';

// Custom components
import { FormField } from './components';

// Styles
import * as styles from './FilePicker.module.scss';

interface IFilePickerProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string,
  children: JSX.Element | JSX.Element[],
  id: string,
}

/**
 ### Usage
 ```
<FilePicker id="..." className={...} onChange={...} accept={...}>
  <FilePicker.FormField
    icon="..."
    iconColor="..."
    fileName={...}
    placeHolderText="..."
    className={...}
  />
</FilePicker>
```

*/

function FilePicker ({ children, className = '', id, ...fileInputProps }: IFilePickerProps) : JSX.Element {
  return (
    <label
      htmlFor={id}
      className={[ styles.FilePicker, className ].join(' ')}>
      {children}
      <input type="file" id={id} className={styles.input} {...fileInputProps}></input>
    </label>
  );
}

FilePicker.FormField = FormField;

export { FilePicker };

import * as React from 'react';

interface IDownloadDocumentProps {
  color?: string;
  className?: string;
}

function DownloadDocument ({
  color = 'white',
  className
}: IDownloadDocumentProps): JSX.Element {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V14.5858L9.70711 13.2929L8.29289 14.7071L12 18.4142L15.7071 14.7071L14.2929 13.2929L13 14.5858V10H11Z'
        fill={color}
      />
    </svg>
  );
}

export default DownloadDocument;

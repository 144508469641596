import { AutocompleteOption } from "components/Autocomplete";
import TextField from "components/TextField";
import * as React from "react";
import { AdditionalContractInformation } from "../types";
import { ProjectIdAutoComplete } from "./form/ProjectIdAutoComplete";
import { TextFieldWithLabel } from "./ui/TextField";

export type AdditionalContractInfoFormValue = {
  projectTypeId: string;
  sales: string;
};

type ContractInfoFormProps = {
  value: AdditionalContractInfoFormValue;
  onChange: (value: AdditionalContractInfoFormValue) => void;
  initialProjectTypeOptions: Array<AutocompleteOption>;
};

export function mapAdditionalContractInfoToAdditionalContractInfoFormValue(
  additionalContractInfo: AdditionalContractInformation
): AdditionalContractInfoFormValue {
  return {
    projectTypeId: additionalContractInfo.projectType.id,
    sales: additionalContractInfo.sales,
  };
}

export function AdditionalContractInfoForm({
  value,
  onChange,
  initialProjectTypeOptions,
}: ContractInfoFormProps) {
  return (
    <div className="py-2 grid gap-2.5">
      <TextField
        label="label_sale_person"
        value={value.sales}
        onChange={(e) => {
          onChange({
            ...value,
            sales: e.target.value,
          });
        }}
      />
      <ProjectIdAutoComplete
        value={value.projectTypeId}
        onChange={(projectTypeId) => {
          onChange({
            ...value,
            projectTypeId: projectTypeId,
          });
        }}
        fallBackOptions={initialProjectTypeOptions}
      />
    </div>
  );
}

export function AdditionalContractInfo({
  additionalContractInfo,
}: {
  additionalContractInfo: AdditionalContractInformation;
}) {
  return (
    <div className="space-y-4">
      <TextFieldWithLabel
        label="label_sale_person"
        value={additionalContractInfo.sales}
      />
      <TextFieldWithLabel
        label="label_project"
        value={
          additionalContractInfo.projectType.id +
          " - " +
          additionalContractInfo.projectType.name
        }
      />
    </div>
  );
}

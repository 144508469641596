import * as React from 'react';

interface IGoogleProps {
  className?: string
}

function Google ({
  className
}: IGoogleProps): JSX.Element {
  return (
    <svg className={className} width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M25.5865 13.351C25.5865 12.4254 25.5034 11.5353 25.3491 10.6808H13.0543V15.7305H20.0799C19.7773 17.3623 18.8576 18.7448 17.475 19.6705V22.946H21.6939C24.1624 20.6733 25.5865 17.3267 25.5865 13.351Z' fill='#4285F4'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M13.0543 26.1086C16.579 26.1086 19.534 24.9396 21.6939 22.9459L17.475 19.6704C16.306 20.4537 14.8107 20.9165 13.0543 20.9165C9.65425 20.9165 6.77636 18.6202 5.74982 15.5346H1.38849V18.9168C3.53652 23.1832 7.95125 26.1086 13.0543 26.1086Z' fill='#34A853'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.74984 15.5346C5.48875 14.7513 5.3404 13.9146 5.3404 13.0542C5.3404 12.1938 5.48875 11.3572 5.74984 10.5739V7.19167H1.3885C0.504371 8.954 0 10.9478 0 13.0542C0 15.1607 0.504371 17.1545 1.3885 18.9168L5.74984 15.5346Z' fill='#FBBC05'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M13.0543 5.19206C14.9709 5.19206 16.6917 5.85071 18.0446 7.14427L21.7888 3.40006C19.5281 1.29356 16.573 0 13.0543 0C7.95125 0 3.53652 2.92535 1.38849 7.19174L5.74982 10.574C6.77636 7.48843 9.65425 5.19206 13.0543 5.19206Z' fill='#EA4335'/>
    </svg>
  );
}

export default Google;

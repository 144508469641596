import { compareSlotNames } from "common/utils/slotUtils";

type Priority = "urgent" | "highest" | "high" | "medium" | "low" | "lowest";

const PRIORITY_VALUE_LOOKUP: Record<Priority, number> = {
  urgent: 0,
  highest: 1,
  high: 2,
  medium: 3,
  low: 4,
  lowest: 5,
};

function priorityComparison(priorityA: string, priorityB: string): number {
  return (
    (priorityA ? PRIORITY_VALUE_LOOKUP[priorityA] : 6) -
    (priorityB ? PRIORITY_VALUE_LOOKUP[priorityB] : 6)
  );
}

function alphaNumericComparison(stringA: string, stringB: string): number {
  // TODO: Using old code at the moment
  return compareSlotNames(stringA, stringB);
}

function stringComparison(stringA: string, stringB: string): number {
  return stringA.localeCompare(stringB);
}

const sortingFn = {
  priorityComparison,
  alphaNumericComparison,
  stringComparison,
};

export default sortingFn;

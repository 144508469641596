import {
  GetMaintenanceBoardOutOfServiceErrorDocument,
  GetMaintenanceBoardOutOfServiceErrorQuery,
  GetMaintenanceBoardOutOfServiceErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getOutOfServiceError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardOutOfServiceErrorQuery,
    GetMaintenanceBoardOutOfServiceErrorQueryVariables
  >(GetMaintenanceBoardOutOfServiceErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

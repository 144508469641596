import { useEffect } from 'react';

type IKeyAction = 'enter' | 'escape';

const keyMap = {
  'enter': 13,
  'escape': 27
};

/**
 *  Attaches a keydown listener for `'enter'` or `'escape'` keys to the document with the call back `eventListener`. Attaches on mount and removes it on unmount.
 * @param action - _`'enter' | 'escape'`_ the key you want to listen to
 * @param eventListener - Callback to fire when the key is pressed
 * @remarks
 * The code is using the keyCode property. The property gives back the decimal ASCII of the key pressed
 *
 * @privateRemarks
 *
 * keyCode property is __[deprecated*](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode)__
 *
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 08-Jul-21
 *
 * __Code last updated__: 08-Jul-21
 */
function useEventListener (action: IKeyAction, eventListener: () => void): void {
  useEffect(() => {
    function enterListener (event) {
      if (event.keyCode === keyMap[action]) {
        eventListener();
      }
    }
    document.addEventListener('keydown', enterListener, false);
    return () => document.removeEventListener('keydown', enterListener, false);
  }, []);
}

export default useEventListener;

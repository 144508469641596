import * as React from 'react';
import { DrinkFailureType, useGetDailyDrinkFailureQuery } from 'gql/generated';
import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';
import { ErrorFallBack } from '../atoms/ErrorFallback';
import Select from 'components/Select';
import { Skeleton } from '@mui/material';

type FilterValue = 'ALL' | 'INVENTORY' | 'HARDWARE';

const options: Array<{ value: FilterValue; label: string }> = [
  { value: 'ALL', label: 'All' },
  { value: 'INVENTORY', label: 'Inventory Fail' },
  { value: 'HARDWARE', label: 'Hardware Fail' },
];

const FILTER_VALUE_TO_DRINK_FAILURE_TYPE: Record<
  FilterValue,
  DrinkFailureType
> = {
  ALL: null,
  INVENTORY: DrinkFailureType.Inventory,
  HARDWARE: DrinkFailureType.Hardware,
};

export function MostDrinkFails(): JSX.Element {
  const [filter, setFilter] = React.useState<FilterValue>('ALL');

  const { isError, data, isLoading } = useGetDailyDrinkFailureQuery(
    {
      filter: {
        errorType: FILTER_VALUE_TO_DRINK_FAILURE_TYPE[filter],
      },
    },
    {
      useErrorBoundary: false,
    }
  );
  return (
    <Card>
      <div className="flex justify-between items-center">
        <CardTitle
          icon="AlertOutline"
          title="Most Drink Fails"
          className={isError ? '!text-on-surface-disabled grow' : 'grow'}
        />
        <div className="w-36 flex items-center justify-center h-0">
          <Select
            label=""
            id="type"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            options={options}
            size="small"
          />
        </div>
      </div>

      {isError ? (
        <ErrorFallBack />
      ) : isLoading ? (
        <Skeleton
          height={160}
          className="mt-auto"
          style={{ transform: 'scale(1,1)' }}
        />
      ) : (
        <>
          <div className="grid [grid-template-columns:auto_1fr] gap-x-14 max-w-full mt-[15px]">
            <div className="font-kanit text-overline tracking-[0.25px] font-light text-[#3E3E3C] whitespace-nowrap overflow-hidden text-ellipsis max-w-32 mb-1">
              Error Code
            </div>
            <div className="font-kanit text-overline tracking-[0.25px] font-light text-[#3E3E3C] whitespace-nowrap">
              Frequency
            </div>
            {data.dailyDrinkFailure.slice(0, 6).map((dataPoint) => (
              <>
                <div className="text-body2 whitespace-nowrap font-bold overflow-hidden text-ellipsis max-w-32 mb-1">
                  {dataPoint.errorCode}
                </div>
                <div className="text-body2 whitespace-nowrap">
                  {dataPoint.totalCount.toLocaleString()}
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </Card>
  );
}

import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardAggregate } from '../molecules/CardAggregate';
import { CardTitle } from '../molecules/CardTitle';
import { LineGraph } from '../atoms/charts/LineGraph';
import * as moment from 'moment';
import Typography from 'components/Typography';
import Icon from 'common/components/icon/Icon';
import { useGetDashboardMaScoreGradeQuery } from 'gql/generated';
import { ErrorFallBack } from '../atoms/ErrorFallback';

export function DailyGradeCard(): JSX.Element {
  const getDailyGradeResult = useGetDashboardMaScoreGradeQuery(undefined, {
    enabled: false,
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  });
  const isEmpty = getDailyGradeResult.data?.dashboardMAScoreGrade.length === 0;

  return (
    <Card className="flex flex-col relative overflow-hidden">
      {isEmpty ? (
        <div className="inset-0 bg-primary-100 opacity-15 absolute" />
      ) : null}
      <CardTitle
        icon={'Star'}
        title={'Daily Grade'}
        className={
          getDailyGradeResult.isError || isEmpty
            ? 'text-on-surface-disabled'
            : undefined
        }
      />
      {getDailyGradeResult.isError ? (
        <ErrorFallBack />
      ) : isEmpty ? (
        <div className="flex-grow flex-col items-center justify-center flex ">
          <Icon name="EyeNo" color="error2" className="w-[67x] h-[67px]" />
          <Typography type="body-3" color="error">
            No data available
          </Typography>
        </div>
      ) : (
        <>
          <div className="grid mt-[9px] grid-cols-2">
            <CardAggregate
              header={'Grade'}
              type={'numeric'}
              value={
                getDailyGradeResult.data.dashboardMAScoreGrade[
                  getDailyGradeResult.data.dashboardMAScoreGrade.length - 1
                ].grade
              }
            />
            <CardAggregate
              header={'Raw Score'}
              type={'numeric'}
              value={String(
                getDailyGradeResult.data.dashboardMAScoreGrade[
                  getDailyGradeResult.data.dashboardMAScoreGrade.length - 1
                ].score.toFixed(2)
              )}
            />
          </div>

          <LineGraph
            data={getDailyGradeResult.data.dashboardMAScoreGrade.map(
              (grade, index) => [index, grade.score]
            )}
            labels={getDailyGradeResult.data.dashboardMAScoreGrade.map(
              (grade) => [
                moment(grade.date).format('YYYY-MM-DD'),
                `Grade ${grade.grade}`,
                String(grade.score.toFixed(2)),
              ]
            )}
            ticks={{
              count: 5,
              autoSkip: false,
              stepSize: 1,
              display: true,
              font: {
                family: 'Kanit',
                size: 10,
              },
            }}
            YGrid={{
              display: true,
            }}
            scales={{
              yAxis: {
                min: 1,
                max: 5,
              },
              xAxis: {
                min: 0,
                max: 30,
              },
            }}
            className="mt-auto"
          />
        </>
      )}
    </Card>
  );
}

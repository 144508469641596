import * as React from "react";
import { useTranslation } from "react-i18next";
import { MachineStatus } from "../types";
import * as styles from "./MachineStatusBadge.module.scss";

export function MachineStatusBadge({
  status,
}: {
  status?: MachineStatus | undefined;
}) {
  const { t } = useTranslation('errorBoard');

  if (!status || !COLOR_AND_TRANSLATION_MAP[status]) {
    return null;
  }

  return (
    <span
      className={styles.MachineStatusBadge}
      style={COLOR_AND_TRANSLATION_MAP[status].style}
    >
      {t(COLOR_AND_TRANSLATION_MAP[status].translation)}
    </span>
  );
}

const COLOR_AND_TRANSLATION_MAP: Record<
  MachineStatus,
  { style: React.CSSProperties; translation: string }
> = {
  maintenance: {
    translation: "machineStatuses.maintenance",
    style: {
      color: "rgba(0,0,0,0.87)",
      backgroundColor: "#FFEDCC",
    },
  },
  offline: {
    translation: "machineStatuses.offline",
    style: {
      color: "white",
      backgroundColor: "rgba(0,0,0,0.38)",
    },
  },
  online: {
    translation: "machineStatuses.online",
    style: {
      color: "rgba(0,0,0,0.87)",
      backgroundColor: "#B2D3D3",
    },
  },

  out_of_service: {
    translation: "machineStatuses.outOfService",
    style: {
      color: "rgba(0,0,0,0.87)",
      backgroundColor: "#F2CDCA",
    },
  },
};

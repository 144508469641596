// Copied from React-table sortType.js file
const alphanumeric = (a: string, b: string) => {
  const arrayA = a.split(/([0-9]+)/gm).filter(Boolean);
  const arrayB = b.split(/([0-9]+)/gm).filter(Boolean);

  while (arrayA.length && arrayB.length) {
    const aa = arrayA.shift();
    const bb = arrayB.shift();

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1])) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }
  return arrayA.length - arrayB.length;
};

export default alphanumeric;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderForm--2mGby0UWyy{position:fixed;width:800px;top:3000px;background-color:#fff;display:flex;flex-direction:column}.OrderForm--2mGby0UWyy .header--9TaB8Dvias{position:relative;margin-bottom:40px}.OrderForm--2mGby0UWyy .header--9TaB8Dvias .left--3JpqUhkq5x{display:flex;flex-direction:row;align-items:center}.OrderForm--2mGby0UWyy .header--9TaB8Dvias .qrCode--BVOeh9ndtg{position:absolute;top:0;right:0}.OrderForm--2mGby0UWyy .details--15h1h3EXZc{display:flex;flex-direction:column}.OrderForm--2mGby0UWyy .details--15h1h3EXZc .row--5K_3Bw4FuW{display:flex;flex-direction:row;align-items:center;margin-bottom:20px;justify-content:flex-start}.OrderForm--2mGby0UWyy .details--15h1h3EXZc .row--5K_3Bw4FuW .input--3kipXi9g-I{margin-right:20px}.OrderForm--2mGby0UWyy .table--2QuGNOrnYM{margin-bottom:40px}.OrderForm--2mGby0UWyy .line--2PuhKuCi_y{border-bottom:1px dashed #000;height:40px;width:100%}.OrderForm--2mGby0UWyy .signatures--s4HUvF0Yug{display:flex;flex-direction:column;margin-top:40px}.OrderForm--2mGby0UWyy .signatures--s4HUvF0Yug .row--5K_3Bw4FuW{display:flex;flex-direction:row;align-items:center;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"OrderForm": "OrderForm--2mGby0UWyy",
	"header": "header--9TaB8Dvias",
	"left": "left--3JpqUhkq5x",
	"qrCode": "qrCode--BVOeh9ndtg",
	"details": "details--15h1h3EXZc",
	"row": "row--5K_3Bw4FuW",
	"input": "input--3kipXi9g-I",
	"table": "table--2QuGNOrnYM",
	"line": "line--2PuhKuCi_y",
	"signatures": "signatures--s4HUvF0Yug"
};
module.exports = exports;

import * as html2canvas from 'html2canvas';
import { jsPDF as JsPdf } from 'jspdf';

const PDF_MARGIN = 30;
const PDF_WIDTH = 595.276;
const PDF_HEIGHT = 841.8898;

function addMargin (pdf): void {
  pdf.setFillColor(255, 255, 255);
  pdf.rect(0, 0, PDF_WIDTH, PDF_MARGIN, 'F');
  pdf.rect(0, PDF_HEIGHT - PDF_MARGIN, PDF_WIDTH, PDF_MARGIN, 'F');
}

/**
 * Function that take a snapshot of the element with the id `elementId` and generates a pdf. Upon calling, will create and automatically save the pdf.
 * @param elementId - Take a snapshot of an element with this id
 * @param pdfName - The name of the generated pdf file
 * @return A `Promise` that will be pending when the pdf is being generated and fulfilled after the pdf is saved
 * @remarks
 *
 * Steps to produce the pdf
 * 1. Convert HTML element to a canvas element.
 * 2. The canvas element is then converted to a PNG image as a DataURL.
 * 3. The DataURL is then placed onto a PDF with the help of the _JsPdf_ library.
 * 4. The pdf is then saved and the promise is fulfilled
 *
 * __(*)__ _The first step is done asynchronously using the html2canvas library. This step is the primary reason the function is an async function._
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 02-Jul-21
 *
 * __Code last updated__: 02-Jul-21
 */
async function elementToPdf (elementId: string, pdfName: string): Promise<void> {
  const element = document.getElementById(elementId);
  const canvas: HTMLCanvasElement = await (html2canvas as any)(element);
  const imgData = canvas.toDataURL('image/png', 0.3);

  // Uncomment to see if the image is being produced correctly, make sure to have <a id="link"></a> somewhere
  /*
  let link = document.getElementById('link');
  link.setAttribute('download', `${pdfName}.png`);
  link.setAttribute('href', imgData.replace("image/png", "image/octet-stream"));
  link.click();
  */

  const pdf: JsPdf = new JsPdf('portrait', 'pt', [ PDF_WIDTH, PDF_HEIGHT ], true);

  const imgProps = pdf.getImageProperties(imgData);
  const imgWidth = PDF_WIDTH - (2 * PDF_MARGIN);
  const imgHeight = imgProps.height * ((PDF_WIDTH - (2 * PDF_MARGIN)) / imgProps.width);

  const totalPdfPages = Math.ceil(imgHeight / (PDF_HEIGHT - (2 * PDF_MARGIN))) - 1;

  canvas.getContext('2d');
  pdf.addImage(
    imgData,
    'PNG',
    PDF_MARGIN,
    PDF_MARGIN,
    imgWidth,
    imgHeight,
    'FAST'
  );
  addMargin(pdf);

  for (let i = 1; i <= totalPdfPages; i++) {
    pdf.addPage([ PDF_WIDTH, PDF_HEIGHT ], 'portrait');
    pdf.addImage(
      imgData,
      'PNG',
      PDF_MARGIN,
      -(PDF_HEIGHT * i) + (PDF_MARGIN * (2 * i + 1)),
      imgWidth,
      imgHeight,
      'FAST'
    );
    addMargin(pdf);
  }

  pdf.save(`${pdfName}.pdf`);
}

export default elementToPdf;

import * as React from "react";

import TextButton from "common/components/textbutton/TextButton";
import Typography from "components/Typography";
import TextFieldViewOnly from "components/TextFieldViewOnly";

import * as styles from "./Details.module.scss";
import * as moment from "moment";
import Button from "components/Button";
import { useImmer } from "use-immer";
import { clone, isEqual } from "lodash";
import TextField from "components/TextField";
import MuiLabel from "components/MuiLabel";
import Checkbox from "common/components/checkbox/Checkbox";
import {
  GetMachineByIdQuery,
  UpdateMachineInput,
  useGetMachineByIdQuery,
  useGetPlanByIdQuery,
  useRegisterMachineMutation,
  useUnregisterMachineMutation,
  useUpdateMachineMutation,
  LocationKind,
  useGetMachineFirmwareVersionQuery,
  MachineKind,
} from "gql/generated";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { toasti18n } from "utils/toast";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import MuiModal from "components/Modal";
import isForthFactory from "containers/location/utils/isForthFactory";
import {
  useIsViewer,
  useIsAdmin,
  useIsClerk,
  useIsOperator,
  useIsTechnician,
  useIsRouteman,
  useIsSuperViewer,
  useIsAdminContract,
} from "utils/user-role";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import { getProvince } from "containers/location/utils/getProvince";
import { useGetOwnPermission } from "hooks/permission";
import Tooltip from "common/components/tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { EnableWorkOrderCreationInfoModalButton } from "./EnableWorkOrderCreationInfoModal";
import {
  LocationIdAutoComplete,
  locationIdCache,
} from "containers/contract/components/location/LocationIdAutoComplete";
import analytics from "utils/analytics";
import { MachineModel } from "./MachineModel";

function Details(): JSX.Element {
  const cannotViewContent = useIsViewer();
  const cannotEdit =
    useIsTechnician() || useIsRouteman() || useIsViewer() || useIsSuperViewer();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const { machineId }: { machineId: string } = useParams();
  const machineByIdQueryResult = useGetMachineByIdQuery(
    { machineId },
    { refetchOnWindowFocus: false }
  );
  const machineFirmwareVersionResult = useGetMachineFirmwareVersionQuery(
    { machineId },
    {
      enabled:
        Boolean(machineByIdQueryResult.data) &&
        machineByIdQueryResult.data.machine.kind !== MachineKind.SpiralVending,
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    }
  );
  const permissionResult = useGetOwnPermission({
    action: "MOVE",
    resourceType: "MACHINE",
  });

  const [mode, setMode] = React.useState<"edit" | "view">("view");

  function refresh() {
    machineByIdQueryResult.remove();
    machineByIdQueryResult.refetch();
    if (machineFirmwareVersionResult.status !== "idle") {
      machineFirmwareVersionResult.remove();
      machineFirmwareVersionResult.refetch();
    }
  }

  const isLoading =
    machineByIdQueryResult.isLoading ||
    permissionResult.isLoading ||
    machineFirmwareVersionResult.isLoading;

  return (
    <div className={styles.Details}>
      <div className={styles.HeaderPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.HeaderFlex}>
            <Typography type="headline-5" translate>
              menu_details
            </Typography>
            {mode === "view" && !cannotEdit && (
              <TextButton
                translationKey="label_edit_machine_detail"
                icon="Edit"
                onClick={() => setMode("edit")}
                disabled={
                  isLoading ||
                  (!permissionResult.allowed &&
                    machineByIdQueryResult.data?.machine.deploymentStatus ===
                      "STANDBY") ||
                  (!permissionResult.allowed &&
                    !machineByIdQueryResult.data?.machine.registered)
                }
              />
            )}
            {mode === "view" && (
              <TextButton
                icon="Refresh"
                className="ml-auto"
                onClick={refresh}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>

      {isLoading || machineByIdQueryResult.data?.machine.location === null ? (
        <MachineDetailsSkeleton />
      ) : mode === "view" ? (
        <MachineDetailsView />
      ) : permissionResult.allowed ? (
        machineByIdQueryResult.data?.machine.registered ? (
          <MachineDetailsFormAdmin onBack={() => setMode("view")} />
        ) : (
          <UnregisteredMachineDetailsFormAdmin onBack={() => setMode("view")} />
        )
      ) : machineByIdQueryResult.data?.machine.registered ? (
        <MachineDetailsFormNonAdmin onBack={() => setMode("view")} />
      ) : null}
    </div>
  );
}

export default Details;

function UnregisteredMachineDetailsFormAdmin({
  onBack,
}: {
  onBack: () => void;
}) {
  const { machineId }: { machineId: string } = useParams();
  const { data: getMachineQuery } = useGetMachineByIdQuery({
    machineId,
  });
  const machine = getMachineQuery.machine;

  const queryClient = useQueryClient();
  const { mutate, isLoading: isRegisteringMachine } =
    useRegisterMachineMutation({
      onError: (err) => {
        toasti18n.error(err as Error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(["GetMachineById", { machineId }]);
        toasti18n.success("label_changes_saved");
        analytics.track({
          name: "machine detail saved",
          properties: {
            type: "registerMachine",
            location_id: machine.location.friendlyId,
            location_name: machine.location.locationName,
            location_type: machine.location.locationType,
            machine_id: machineId,
          },
        });
      },
    });

  const [registered, setRegistered] = React.useState<boolean>(
    machine?.registered ?? false
  );

  return (
    <>
      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_registered_status
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <MuiLabel label="label_status" required={true}>
                <div className={styles.StatusContainer}>
                  <div className={styles.CheckBoxAndLabel}>
                    <Checkbox
                      roundCheckbox
                      checked={registered}
                      onClick={() => {
                        setRegistered(true);
                      }}
                    ></Checkbox>
                    <Typography type="subtitle-1" translate>
                      label_registered
                    </Typography>
                  </div>
                  <div className={styles.CheckBoxAndLabel}>
                    <Checkbox
                      roundCheckbox
                      checked={!registered}
                      onClick={() => {
                        setRegistered(false);
                      }}
                    />
                    <Typography type="subtitle-1" translate>
                      label_unregistered
                    </Typography>
                  </div>
                </div>
              </MuiLabel>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}></div>
            <div className={styles.RightColumn}>
              <div className={styles.ButtonsContainer}>
                <Button
                  type="secondary"
                  onClick={onBack}
                  disabled={isRegisteringMachine}
                >
                  action_cancel
                </Button>
                <Button
                  type="primary"
                  disabled={!registered}
                  loading={isRegisteringMachine}
                  onClick={() =>
                    mutate({
                      machineId: machineId,
                    })
                  }
                >
                  action_save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MachineDetailsFormNonAdmin({
  onBack,
}: {
  onBack: () => void;
}): JSX.Element {
  const canEditSettings =
    useIsAdmin() || useIsAdminContract() || useIsClerk() || useIsOperator();
  const { t } = useTranslation();

  const { machineId }: { machineId: string } = useParams();
  const { data: getMachineQuery } = useGetMachineByIdQuery({
    machineId,
  });
  const machine = getMachineQuery.machine;

  const planId = machine.activeRefillOrders[0]?.planId;
  const { data: getPlanByIdQuery, isLoading: isPlanLoading } =
    useGetPlanByIdQuery(
      { planId: planId },
      { enabled: machine.activeRefillOrders.length > 0 }
    );

  const queryClient = useQueryClient();
  const { mutate, isLoading: isUpdatingMachine } = useUpdateMachineMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: () => {
      toasti18n.success("label_changes_saved");
      queryClient.invalidateQueries(["GetMachineById", { machineId }]);
      initialFormValues.current = clone(formValues);
      analytics.track({
        name: "machine detail saved",
        properties: {
          type: "updateMachine",
          location_id: machine.location.friendlyId,
          location_name: machine.location.locationName,
          location_type: machine.location.locationType,
          machine_id: machineId,
        },
      });
    },
  });

  const [formValues, setFormValues] = useImmer<UpdateMachineInput>(() => ({
    mute: machine.mute,
    enableWorkOrderCreation: machine.enableWorkOrderCreation,
    recommendTickets: machine.recommendTickets,
  }));
  const initialFormValues = React.useRef<UpdateMachineInput>(clone(formValues));

  return (
    <>
      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_setting
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <div className={styles.Setting}>
                <div>
                  <ToggleSwitch
                    disabled={!canEditSettings}
                    isOn={formValues.mute}
                    onClick={() =>
                      setFormValues((draft) => {
                        draft.mute = !draft.mute;
                      })
                    }
                  />
                  <Typography type="subtitle-1" translate>
                    label_mute
                  </Typography>
                </div>
                <div>
                  <ToggleSwitch
                    isOn={formValues.enableWorkOrderCreation}
                    disabled={!canEditSettings}
                    onClick={() =>
                      setFormValues((draft) => {
                        draft.enableWorkOrderCreation =
                          !draft.enableWorkOrderCreation;
                        analytics.track({
                          name: "work order creation toggled",
                          properties: {
                            enable_work_order_creation:
                              draft.enableWorkOrderCreation,
                            location_id: machine.location.friendlyId,
                            location_grade:
                              machine.location.__typename === "Location" ||
                              machine.location.__typename === "Store"
                                ? machine.location.locationGrade
                                : "-",
                            location_name: machine.location.locationName,
                            location_type: machine.location.locationType,
                            machine_id: machineId,
                          },
                        });
                      })
                    }
                  />
                  <div className={styles.WithCaption}>
                    <div className={styles.WithInfoButton}>
                      <Typography type="subtitle-1" translate>
                        label_enable_work_order_creation
                      </Typography>
                      <EnableWorkOrderCreationInfoModalButton
                        value={formValues.enableWorkOrderCreation}
                        isEditView={true}
                        machineId={machineId}
                        locationId={machine.location.friendlyId}
                      />
                    </div>
                    {machine.activeRefillOrders.length > 0 &&
                      !isPlanLoading && (
                        <Tooltip
                          disabled={!isPlanLoading && !getPlanByIdQuery.plan}
                          content={
                            <div className={styles.WorkOrderToolTip}>
                              <Typography type="caption" color="surface">
                                {t("label_plan")}: {getPlanByIdQuery.plan.name}
                              </Typography>
                              <Typography type="caption" color="surface">
                                {t("label_create_date")}:{" "}
                                {moment(
                                  Number(getPlanByIdQuery.plan.startTime)
                                ).format("DD/MM/YYYY, h:mma")}
                              </Typography>
                              <Typography type="caption" color="surface">
                                {t("label_usually_completed_in_few_days")}
                              </Typography>
                            </div>
                          }
                        >
                          <Typography type="caption" color="critical" translate>
                            label_work_order_in_progress
                          </Typography>
                        </Tooltip>
                      )}
                  </div>
                </div>
                <div>
                  <ToggleSwitch
                    isOn={formValues.recommendTickets}
                    disabled={!canEditSettings}
                    onClick={() =>
                      setFormValues((draft) => {
                        draft.recommendTickets = !draft.recommendTickets;
                      })
                    }
                  />
                  <Typography type="subtitle-1" translate>
                    label_recommend_tickets
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}></div>
            <div className={styles.RightColumn}>
              <div className={styles.ButtonsContainer}>
                <Button
                  type="secondary"
                  onClick={onBack}
                  disabled={isUpdatingMachine}
                >
                  action_cancel
                </Button>
                <Button
                  type="primary"
                  disabled={isEqual(formValues, initialFormValues.current)}
                  loading={isUpdatingMachine}
                  onClick={() =>
                    mutate({ updateMachineId: machineId, machine: formValues })
                  }
                >
                  action_save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MachineDetailsFormAdmin({
  onBack,
}: {
  onBack: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { machineId }: { machineId: string } = useParams();
  const { data: getMachineQuery } = useGetMachineByIdQuery({
    machineId,
  });
  const machine = getMachineQuery.machine;

  const planId = machine.activeRefillOrders[0]?.planId;
  const { data: getPlanByIdQuery, isLoading: isPlanLoading } =
    useGetPlanByIdQuery(
      { planId: planId },
      { enabled: machine.activeRefillOrders.length > 0 }
    );

  const queryClient = useQueryClient();
  const { mutate, isLoading: isUpdatingMachine } = useUpdateMachineMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["GetMachineById", { machineId }]);
      toasti18n.success("label_changes_saved");
      const getMachineQueryCache: GetMachineByIdQuery =
        queryClient.getQueryData(["GetMachineById", { machineId }]);
      const machine = getMachineQueryCache.machine;
      const newFormValues = {
        deployed: machine.deploymentStatus === "OPERATING",
        locationId: machine.location.id,
        mute: machine.mute,
        enableWorkOrderCreation: machine.enableWorkOrderCreation,
        recommendTickets: machine.recommendTickets,
        additional: {
          notes: machine.additional?.notes ?? "", // Standby notes
        },
      };
      initialFormValues.current = clone(newFormValues);
      setFormValues(newFormValues);
      // TODO: Remove this when switching to graphql codegen
      queryClient.invalidateQueries(["machine", machineId]);
      queryClient.invalidateQueries(["GetLocationsDetailed"]);
    },
  });

  const [formValues, setFormValues] = useImmer<UpdateMachineInput>(() => ({
    deployed: machine.deploymentStatus === "OPERATING",
    locationId: machine.location.id,
    mute: machine.mute,
    enableWorkOrderCreation: machine.enableWorkOrderCreation,
    recommendTickets: machine.recommendTickets,
    additional: {
      notes: machine.additional?.notes ?? "", // Standby notes
    },
  }));
  const {
    location: locationSelectedData,
    isLoading: isLoadingLocationSelectedData,
  } = locationIdCache.useGetLocationById(formValues.locationId || undefined, {
    includeOrganisation: true,
  });
  const initialFormValues = React.useRef<UpdateMachineInput>(clone(formValues));

  function onSubmit() {
    if (formValues.deployed) {
      mutate({
        updateMachineId: machineId,
        machine: {
          ...formValues,
          additional: {
            notes: "", // Reset standby note on deployed
          },
        },
      });
    } else {
      mutate({
        updateMachineId: machineId,
        machine: {
          deployed: formValues.deployed,
          additional: { notes: formValues.additional.notes },
          locationId: formValues.locationId,
        },
      });
    }
  }

  const [isUnregisterMachineModelOpen, setIsUnregisterMachineModalOpen] =
    React.useState(false);

  return (
    <>
      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_general
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <MuiLabel label="label_status" required={true}>
                <div className={styles.StatusContainer}>
                  <div className={styles.CheckBoxAndLabel}>
                    <Checkbox
                      roundCheckbox
                      checked={formValues.deployed}
                      onClick={() => {
                        if (formValues.deployed) {
                          return;
                        }

                        setFormValues((draft) => {
                          draft.deployed = true;
                          draft.locationId = "";
                        });
                      }}
                    ></Checkbox>
                    <Typography type="subtitle-1" translate>
                      label_operating
                    </Typography>
                  </div>
                  <div className={styles.CheckBoxAndLabel}>
                    <Checkbox
                      roundCheckbox
                      checked={!formValues.deployed}
                      onClick={() => {
                        if (!formValues.deployed) {
                          return;
                        }

                        setFormValues((draft) => {
                          draft.deployed = false;
                          draft.locationId = "";
                        });
                      }}
                    />
                    <Typography type="subtitle-1" translate>
                      label_standby
                    </Typography>
                  </div>
                </div>
              </MuiLabel>
              <LocationIdAutoComplete
                value={formValues.locationId}
                onChange={(newValue) => {
                  setFormValues((draft) => {
                    draft.locationId = newValue;
                  });
                }}
                filter={{
                  kind: formValues.deployed
                    ? [LocationKind.Location, LocationKind.Store]
                    : [LocationKind.Factory],
                }}
              />

              <TextFieldViewOnly
                value={locationSelectedData?.node?.locationName}
                label="label_location_name"
                isLoading={isLoadingLocationSelectedData}
              />
              <TextFieldViewOnly
                value={locationSelectedData?.node?.organisation?.name}
                label="label_organisation"
                isLoading={isLoadingLocationSelectedData}
              />
            </div>
          </div>
        </div>
      </div>

      {formValues.deployed && (
        <div className={styles.SectionPadding}>
          <div className={styles.ContainerPadding}>
            <div className={styles.TwoColumnLayoutContainer}>
              <div className={styles.LeftColumn}>
                <Typography type="headline-6" translate>
                  label_setting
                </Typography>
              </div>
              <div className={styles.RightColumn}>
                <div className={styles.Setting}>
                  <div>
                    <ToggleSwitch
                      isOn={formValues.mute}
                      onClick={() =>
                        setFormValues((draft) => {
                          draft.mute = !draft.mute;
                        })
                      }
                    />
                    <Typography type="subtitle-1" translate>
                      label_mute
                    </Typography>
                  </div>
                  <div>
                    <ToggleSwitch
                      isOn={formValues.enableWorkOrderCreation}
                      onClick={() =>
                        setFormValues((draft) => {
                          draft.enableWorkOrderCreation =
                            !draft.enableWorkOrderCreation;
                          analytics.track({
                            name: "work order creation toggled",
                            properties: {
                              enable_work_order_creation:
                                draft.enableWorkOrderCreation,
                              location_id: machine.location.friendlyId,
                              location_grade:
                                machine.location.__typename === "Location" ||
                                machine.location.__typename === "Store"
                                  ? machine.location.locationGrade
                                  : "-",
                              location_name: machine.location.locationName,
                              location_type: machine.location.locationType,
                              machine_id: machineId,
                            },
                          });
                        })
                      }
                    />
                    <div className={styles.WithCaption}>
                      <div className={styles.WithInfoButton}>
                        <Typography type="subtitle-1" translate>
                          label_enable_work_order_creation
                        </Typography>
                        <EnableWorkOrderCreationInfoModalButton
                          value={formValues.enableWorkOrderCreation}
                          isEditView={true}
                          machineId={machineId}
                          locationId={machine.location.friendlyId}
                        />
                      </div>
                      {machine.activeRefillOrders.length > 0 &&
                        !isPlanLoading && (
                          <Tooltip
                            disabled={!isPlanLoading && !getPlanByIdQuery.plan}
                            content={
                              <div className={styles.WorkOrderToolTip}>
                                <Typography type="caption" color="surface">
                                  {t("label_plan")}:{" "}
                                  {getPlanByIdQuery.plan.name}
                                </Typography>
                                <Typography type="caption" color="surface">
                                  {t("label_create_date")}:{" "}
                                  {moment(
                                    Number(getPlanByIdQuery.plan.startTime)
                                  ).format("DD/MM/YYYY, h:mma")}
                                </Typography>
                                <Typography type="caption" color="surface">
                                  {t("label_usually_completed_in_few_days")}
                                </Typography>
                              </div>
                            }
                          >
                            <Typography
                              type="caption"
                              color="critical"
                              translate
                            >
                              label_work_order_in_progress
                            </Typography>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                  <div>
                    <ToggleSwitch
                      isOn={formValues.recommendTickets}
                      onClick={() =>
                        setFormValues((draft) => {
                          draft.recommendTickets = !draft.recommendTickets;
                        })
                      }
                    />
                    <Typography type="subtitle-1" translate>
                      label_recommend_tickets
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!formValues.deployed && (
        <div className={styles.SectionPadding}>
          <div className={styles.ContainerPadding}>
            <div className={styles.TwoColumnLayoutContainer}>
              <div className={styles.LeftColumn}>
                <Typography type="headline-6" translate>
                  label_standby
                </Typography>
              </div>
              <div className={styles.RightColumn}>
                <TextField
                  value={formValues.additional.notes}
                  label="label_standby_note"
                  onChange={(e) =>
                    setFormValues((draft) => {
                      draft.additional.notes = e.target.value;
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}></div>
            <div className={styles.RightColumn}>
              <div className={styles.ButtonsContainer}>
                <Button
                  type="secondary"
                  onClick={onBack}
                  disabled={isUpdatingMachine}
                >
                  action_cancel
                </Button>

                <div style={{ display: "flex", gap: "20px" }}>
                  {isForthFactory(machine.location) && (
                    <>
                      {isUnregisterMachineModelOpen && (
                        <UnregisterMachineModal
                          onClose={() => setIsUnregisterMachineModalOpen(false)}
                        />
                      )}
                      <Button
                        type="status"
                        disabled={isUpdatingMachine}
                        onClick={() => {
                          setIsUnregisterMachineModalOpen(true);
                        }}
                      >
                        label_unregister
                      </Button>
                    </>
                  )}
                  <Button
                    type="primary"
                    disabled={
                      isEqual(formValues, initialFormValues.current) ||
                      formValues.locationId === "" ||
                      (!formValues.deployed &&
                        formValues.additional.notes === "")
                    }
                    loading={isUpdatingMachine}
                    onClick={onSubmit}
                  >
                    action_save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function UnregisterMachineModal({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element {
  const { machineId }: { machineId: string } = useParams();

  const { data: getMachineQuery } = useGetMachineByIdQuery(
    {
      machineId,
    },
    {
      refetchOnMount: false,
    }
  );
  const machine = getMachineQuery.machine;

  const queryClient = useQueryClient();
  const { mutate, isLoading: isUnregisterMachine } =
    useUnregisterMachineMutation({
      onError: (err) => {
        toasti18n.error(err as Error);
      },
      onSuccess: async () => {
        // No need to close because the parent will conditionally render a different component naturally
        await queryClient.invalidateQueries(["GetMachineById", { machineId }]);
        toasti18n.success("label_changes_saved");
        analytics.track({
          name: "machine detail saved",
          properties: {
            type: "UnregisterMachine",
            location_id: machine.location.friendlyId,
            location_name: machine.location.locationName,
            machine_id: machineId,
            location_type: machine.location.locationType,
          },
        });
      },
    });

  return (
    <MuiModal
      open={true}
      onClose={isUnregisterMachine ? () => {} : onClose}
      padding="18px 19px"
      minWidth="390px"
      PaperProps={{ sx: { gap: "16px" } }}
    >
      <>
        <Typography type="headline-6" translate>
          label_unregister
        </Typography>
        <Typography type="body-1" translate>
          action_confirm_description
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="secondary"
            onClick={onClose}
            disabled={isUnregisterMachine}
          >
            action_cancel
          </Button>
          <Button
            type="status"
            loading={isUnregisterMachine}
            onClick={() =>
              mutate({
                machineId: machineId,
              })
            }
          >
            action_confirm
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

function MachineDetailsSkeleton(): JSX.Element {
  return (
    <>
      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_general
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <TextFieldViewOnly
                isLoading={true}
                label="label_status"
                required={true}
              />
              <TextFieldViewOnly
                isLoading={true}
                label="label_location_id"
                required={true}
              />
              <TextFieldViewOnly isLoading={true} label="label_location_name" />
              <TextFieldViewOnly isLoading={true} label="label_organisation" />
              <TextFieldViewOnly isLoading={true} label="label_location_type" />
              <TextFieldViewOnly isLoading={true} label="label_GPS_location" />
              <TextFieldViewOnly isLoading={true} label="label_address" />
              <TextFieldViewOnly isLoading={true} label="label_province" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MachineDetailsView(): JSX.Element {
  const { t } = useTranslation();

  const { machineId }: { machineId: string } = useParams();
  const { data: getMachineQuery } = useGetMachineByIdQuery(
    {
      machineId,
    },
    {
      enabled: false,
    }
  );
  const machine = getMachineQuery.machine;

  const planId = machine.activeRefillOrders[0]?.planId;
  const { data: getPlanByIdQuery, isLoading: isPlanLoading } =
    useGetPlanByIdQuery(
      { planId: planId },
      { enabled: machine.activeRefillOrders.length > 0 }
    );

  return (
    <>
      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_general
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <TextFieldViewOnly
                translateValue
                value={
                  machine.deploymentStatus === "OPERATING"
                    ? "label_operating"
                    : "label_standby"
                }
                label="label_status"
                required={true}
              />
              <TextFieldViewOnly
                value={machine.location.friendlyId}
                link={`/location/${machine.location.id}`}
                label="label_location_id"
                required={true}
              />
              <TextFieldViewOnly
                value={machine.location.locationName}
                label="label_location_name"
              />
              <TextFieldViewOnly
                value={machine.location.organisation.name}
                label="label_organisation"
              />
              <TextFieldViewOnly
                value={`label_${machine.location.locationType
                  .toLowerCase()
                  .split(" ")
                  .join("_")}`}
                translateValue={true}
                label="label_location_type"
              />
              <TextFieldViewOnly
                value={`${machine.location.latitude}, ${machine.location.longitude}`}
                label="label_GPS_location"
              />
              <TextFieldViewOnly
                value={machine.location.address}
                label="label_address"
              />
              <TextFieldViewOnly
                value={
                  (Object.values(getProvince()) as Array<string>).includes(
                    machine.location.province.split(" ").join("_").toUpperCase()
                  )
                    ? `label_${machine.location.province
                        .split(" ")
                        .join("_")
                        .toLowerCase()}`
                    : machine.location.province
                }
                translateValue={true}
                label="label_province"
                required={true}
              />
            </div>
          </div>
        </div>
      </div>

      {machine.deploymentStatus === "OPERATING" && (
        <div className={styles.SectionPadding}>
          <div className={styles.ContainerPadding}>
            <div className={styles.TwoColumnLayoutContainer}>
              <div className={styles.LeftColumn}>
                <Typography type="headline-6" translate>
                  label_operation
                </Typography>
              </div>
              <div className={styles.RightColumn}>
                <TextFieldViewOnly
                  value={
                    machine.location.__typename !== "Factory"
                      ? machine.location.refillZone.friendlyId
                      : null
                  }
                  label="label_refill_zone"
                  required
                />
                <TextFieldViewOnly
                  value={
                    machine.location.__typename !== "Factory"
                      ? machine.location.serviceZone.friendlyId
                      : null
                  }
                  label="label_service_zone"
                  required
                />
                <TextFieldViewOnly
                  value={
                    machine.location.__typename !== "Factory"
                      ? machine.location.warehouse.name
                      : null
                  }
                  label="label_warehouse"
                  required
                />
                <TextFieldViewOnly
                  value={
                    machine.location.__typename !== "Factory"
                      ? machine.location.operationNote
                      : null
                  }
                  label="label_operation_note"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {machine.deploymentStatus === "STANDBY" && (
        <div className={styles.SectionPadding}>
          <div className={styles.ContainerPadding}>
            <div className={styles.TwoColumnLayoutContainer}>
              <div className={styles.LeftColumn}>
                <Typography type="headline-6" translate>
                  label_standby
                </Typography>
              </div>
              <div className={styles.RightColumn}>
                <TextFieldViewOnly
                  value={machine.additional?.notes}
                  label="label_standby_note"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <MachineModel />

      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_registered_status
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <TextFieldViewOnly
                value={
                  machine.registered ? "label_registered" : "label_unregistered"
                }
                translateValue
                label="label_registered_status"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.SectionPadding}>
        <div className={styles.ContainerPadding}>
          <div className={styles.TwoColumnLayoutContainer}>
            <div className={styles.LeftColumn}>
              <Typography type="headline-6" translate>
                label_location_history
              </Typography>
            </div>
            <div className={styles.RightColumn}>
              <div className={styles.LocationHistory}>
                {(machine.locationHistory ?? [])
                  .sort((a, b) => Number(b.createdAt) - Number(a.createdAt))
                  .map((history) => (
                    <div key={history.createdAt}>
                      <div>
                        <Typography type="body-2">
                          {history.location.friendlyId}{" "}
                          {history.location.locationName}
                        </Typography>
                      </div>
                      <div>
                        <Typography type="caption" color={"onSurfaceDisabled"}>
                          {moment(Number(history.createdAt)).format(
                            "DD/MM/yyyy, H:mm"
                          )}
                          , {history.user.email}
                        </Typography>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {machine.deploymentStatus === "OPERATING" && (
        <div className={styles.SectionPadding}>
          <div className={styles.ContainerPadding}>
            <div className={styles.TwoColumnLayoutContainer}>
              <div className={styles.LeftColumn}>
                <Typography type="headline-6" translate>
                  label_setting
                </Typography>
              </div>
              <div className={styles.RightColumn}>
                <div className={styles.Setting}>
                  <div>
                    <ToggleSwitch isOn={machine.mute} disabled />
                    <Typography type="subtitle-1" translate>
                      label_mute
                    </Typography>
                  </div>
                  <div>
                    <ToggleSwitch
                      isOn={machine.enableWorkOrderCreation}
                      disabled
                    />
                    <div className={styles.WithCaption}>
                      <div className={styles.WithInfoButton}>
                        <Typography type="subtitle-1" translate>
                          label_enable_work_order_creation
                        </Typography>
                        <EnableWorkOrderCreationInfoModalButton
                          value={machine.enableWorkOrderCreation}
                          isEditView={false}
                          machineId={machineId}
                          locationId={machine.location.friendlyId}
                        />
                      </div>
                      {machine.activeRefillOrders.length > 0 &&
                        !isPlanLoading && (
                          <Tooltip
                            disabled={!isPlanLoading && !getPlanByIdQuery.plan}
                            content={
                              <div className={styles.WorkOrderToolTip}>
                                <Typography type="caption" color="surface">
                                  {t("label_plan")}:{" "}
                                  {getPlanByIdQuery.plan.name}
                                </Typography>
                                <Typography type="caption" color="surface">
                                  {t("label_create_date")}:{" "}
                                  {moment(
                                    Number(getPlanByIdQuery.plan.startTime)
                                  ).format("DD/MM/YYYY, h:mma")}
                                </Typography>
                                <Typography type="caption" color="surface">
                                  {t("label_usually_completed_in_few_days")}
                                </Typography>
                              </div>
                            }
                          >
                            <Typography
                              type="caption"
                              color="critical"
                              translate
                            >
                              label_work_order_in_progress
                            </Typography>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                  <div>
                    <ToggleSwitch disabled isOn={machine.recommendTickets} />
                    <Typography type="subtitle-1" translate>
                      label_recommend_tickets
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BucketStatus--2F9VPbuJg8{padding:20px;border-radius:4px;display:flex;flex-direction:column;flex:1;background-color:#fff;margin-bottom:20px}.BucketStatus--2F9VPbuJg8 .header--1zkkMkytj0{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-bottom:20px}.BucketStatus--2F9VPbuJg8 .header--1zkkMkytj0 .legend--VqKkTkt4XM{display:flex;flex-direction:row;align-items:center}.BucketStatus--2F9VPbuJg8 .header--1zkkMkytj0 .legend--VqKkTkt4XM .indicator--2y2AgZkeyB{margin-left:20px}@media screen and (max-width: 768px){.BucketStatus--2F9VPbuJg8 .header--1zkkMkytj0 .legend--VqKkTkt4XM{flex-direction:column;align-items:flex-end}}.BucketStatus--2F9VPbuJg8 .chart--39g6JTg0le{display:flex;flex-direction:row;height:60px}.BucketStatus--2F9VPbuJg8 .chart--39g6JTg0le .bar--2vcYpVc9XI{position:relative;margin:0 1px;flex:1}.BucketStatus--2F9VPbuJg8 .chart--39g6JTg0le .bar--2vcYpVc9XI .innerBar--1xkflnzaWF{transition:height 300ms ease-in;position:absolute;bottom:0;left:0;right:0}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"BucketStatus": "BucketStatus--2F9VPbuJg8",
	"header": "header--1zkkMkytj0",
	"legend": "legend--VqKkTkt4XM",
	"indicator": "indicator--2y2AgZkeyB",
	"chart": "chart--39g6JTg0le",
	"bar": "bar--2vcYpVc9XI",
	"innerBar": "innerBar--1xkflnzaWF"
};
module.exports = exports;

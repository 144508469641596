import Autocomplete from "components/Autocomplete";
import {
  getProvince,
  translateProvince
} from "containers/location/utils/getProvince";
import * as React from "react";

export function ProvinceAutoComplete({
  value, onChange, required
}: {
  value: string;
  onChange: (value: string) => void;
  required: boolean;
}) {
  return (
    <Autocomplete
      required={required}
      value={value}
      onChange={onChange}
      id="province"
      label="label_province"
      options={Object.values(getProvince()).map((type) => ({
        value: type,
        label: translateProvince(type),
      }))}
      disableClearable={true}
      translateLabels={true}
       />
  );
}

import * as React from "react";

import Typography from "components/Typography";

import { contractRoutes } from "containers/contract";

import { Link } from "react-router-dom";

import { Contracts, ContractArray } from "../types";

import { insertElementBetweenExcludingEnd } from "../utils/insertElementBetweenExcludingEnd";
import { TextFieldWithLabel } from "./ui/TextField";

export function LocationContracts({ contracts }: { contracts: Contracts; }) {
  return (
    <>
      <ContractList
        title="label_current_contract"
        contracts={contracts.active}
        collapsible={false} />
      <ContractList
        title="label_upcoming_contract"
        contracts={contracts.inactive} />
      <ContractList
        title="label_past_contract"
        contracts={contracts.pastContract} />
    </>
  );
}

function ContractList({
  contracts, title, collapsible = true,
}: {
  contracts: ContractArray;
  title: string;
  collapsible?: boolean;
}) {

  const sortedContracts = contracts.sort((a, b) => a.friendlyId.localeCompare(b.friendlyId)
  );

  if (contracts.length === 0) return null;

  const linksArray = sortedContracts?.map((contract) => (
    <Link
      to={contractRoutes.contractId(contract.id)}
      target="_blank"
      rel="noopener noreferrer"
      key={`contract-${contract.id}`}
    >
      <Typography type="subtitle-1" color="primary500">
        {contract.friendlyId}
      </Typography>
    </Link>
  ));
  const renderedLinks = insertElementBetweenExcludingEnd(
    linksArray,
    (index) => (
      <Typography type="subtitle-1" color="primary500" key={"$" + index}>
        {", "}
      </Typography>
    )
  );

  return (
    <>
      <TextFieldWithLabel label={title} value={renderedLinks} collapsible={collapsible} />
    </>
  );
}

// Hooks
import { useQuery } from 'react-query';
import { useState } from 'react';

// Service function
import { getMachinesWithExtraFields } from 'machines/service';

// Types
import { IMachineDataWithExtraFields } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';

function useGetMachinesWithExtraFields ({ enabled = true } = {}) {
  const [ isPolling, setIsPolling ] = useState(true);
  return useQuery<IMachineDataWithExtraFields[], AxiosError>(
    machineKeys.all({ extraFields: true }),
    getMachinesWithExtraFields,
    {
      enabled,
      refetchInterval: isPolling ? 5000 : undefined,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: () => setIsPolling(false)
    }
  );
}

export { useGetMachinesWithExtraFields };

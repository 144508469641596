import { IProduct, ITransferProductRequest } from 'product/interfaces';
import { ILot } from 'lot/interfaces';

import { httpClient } from 'common/services/transportService';

export async function getProducts (params?: object): Promise<IProduct[]> {
  const response = await httpClient.get<IProduct[]>('/products', { params });
  return response.data;
}

export async function createProducts (productsData: Array<Partial<IProduct>>): Promise<IProduct[]> {
  const response = await httpClient.post<IProduct[]>('/products', productsData);
  return response.data;
}

export async function transferProduct (transferProductData: ITransferProductRequest): Promise<ILot[]> {
  const { fromWarehouse, toWarehouse, products } = transferProductData;
  const response = await httpClient.put<ILot[]>('/lottransfer', products, {
    params: {
      fromWarehouse,
      toWarehouse
    }
  });

  return response.data;
}
export async function editProduct (editProductData: Partial<IProduct>): Promise<IProduct> {
  const { productId, ...data } = editProductData;
  const response = await httpClient.patch<IProduct>(`/products/${productId}`, data);
  return response.data;
}

export async function getProductsById (id:string): Promise<IProduct> {
  const response = await httpClient.get<IProduct>(`/products/${id}`);
  return response.data;
}

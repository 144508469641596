async function downloadXLSXFile(
  fn: () => Promise<ArrayBuffer>,
  fileName: string
) {
  const file = await fn();
  const fileNameWithExtension = `${fileName}.xlsx`;
  const XLSXfile = new File([file], fileNameWithExtension, {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const anchorElement = document.createElement("a");
  anchorElement.href = URL.createObjectURL(XLSXfile);
  anchorElement.download = fileNameWithExtension;
  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);
}

export { downloadXLSXFile };

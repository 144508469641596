// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IndicatorLabel--1Ai6UYumGO{display:flex;flex-direction:row;align-items:center}.IndicatorLabel--1Ai6UYumGO .indicator--WiiEHi4brB{width:8px;height:8px;min-width:8px;min-height:8px;border-radius:100%}", ""]);
// Exports
exports.locals = {
	"IndicatorLabel": "IndicatorLabel--1Ai6UYumGO",
	"indicator": "indicator--WiiEHi4brB"
};
module.exports = exports;

import Typography from "components/Typography";
import * as React from "react";

export function Title({ children }: { children: React.ReactNode; }) {
  return (
    <Typography type="headline-6" translate>
      {children}
    </Typography>
  );
}

import * as React from "react";
import { MachineModals } from "./modals";
import { DownloadDropdownMenu } from "./DownloadDropdownMenu";
import { MoreDropdownMenu } from "./MoreDropdownMenu";
import { OnboardingDropdownMenu } from "./OnboardingDropdownMenu";

export function HeaderCTA() {
  return (
    <>
      <div className="flex gap-4 mb-4 items-center">
        <div className="flex gap-4 items-center flex-1">
          <OnboardingDropdownMenu />
          <DownloadDropdownMenu />
          <MoreDropdownMenu />
        </div>
      </div>
      <MachineModals />
    </>
  );
}

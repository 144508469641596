import * as React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

interface TransferInventoryTableProps {
  transferInventoryData: Array<{
    id: string;
    name: string;
    unit: string | undefined;
    pricePerUnit: string | undefined;
    quantity: string | undefined;
  }>;
}

export function TransferInventoryTable({
  transferInventoryData
}: TransferInventoryTableProps) {
  return (
    <View>
      <TransferInventoryTableHeader />
      {transferInventoryData.map((inventory, index) => (
        <TransferInventoryTableRow
          inventoryData={{ ...inventory, index: String(index + 1) }}
          key={index}
        />
      ))}
    </View>
  );
}

interface TransferInventoryTableRowProps {
  inventoryData: {
    index: string;
    id: string;
    name: string;
    unit: string;
    pricePerUnit: string;
    quantity: string;
  };
}

function TransferInventoryTableRow({
  inventoryData
}: TransferInventoryTableRowProps): JSX.Element {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", marginTop: 1 }}
      wrap={false}
    >
      <View style={{ ...stylesPDF.indexColumn, alignItems: "flex-start" }}>
        <Text style={{ paddingLeft: 5, ...stylesPDF.rowFont }}>
          {inventoryData.index}
        </Text>
      </View>
      <View style={{ ...stylesPDF.codeColumn, alignItems: "flex-start" }}>
        <Text style={stylesPDF.rowFont}>{inventoryData.id}</Text>
      </View>
      <View style={{ ...stylesPDF.productColumn, alignItems: "flex-start" }}>
        <Text
          style={{ ...stylesPDF.rowFont, paddingRight: 10 }}
          breakWordAnywhere
        >
          {inventoryData.name}
        </Text>
      </View>
      <View style={{ ...stylesPDF.unitColumn, alignItems: "flex-start" }}>
        {inventoryData.unit && (
          <Text style={stylesPDF.rowFont}>{inventoryData.unit}</Text>
        )}
      </View>
      <View
        style={{ ...stylesPDF.pricePerUnitColumn, alignItems: "flex-start" }}
      >
        {inventoryData.pricePerUnit && (
          <Text style={stylesPDF.rowFont}>{inventoryData.pricePerUnit}</Text>
        )}
      </View>
      <View style={{ ...stylesPDF.totalColumn, alignItems: "flex-start" }}>
        <Text style={{ paddingRight: 11, ...stylesPDF.rowFont }}>
          {inventoryData.quantity}
        </Text>
      </View>
    </View>
  );
}

function TransferInventoryTableHeader(): JSX.Element {
  return (
    <View
      style={{
        borderTopWidth: 2,
        borderTopStyle: "solid",
        borderTopColor: COLORS.primary500,
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderBottomColor: COLORS.primary500,
        minHeight: 20,
        display: "flex",
        flexDirection: "row"
      }}
    >
      <View style={{ ...stylesPDF.indexColumn, alignItems: "center" }}>
        <Text
          style={{
            paddingLeft: 5,
            ...stylesPDF.headerFont
          }}
        >
          #
        </Text>
      </View>
      <View style={{ ...stylesPDF.codeColumn, alignItems: "center" }}>
        <Text translate style={stylesPDF.headerFont}>
          label_code
        </Text>
      </View>
      <View style={{ ...stylesPDF.productColumn, alignItems: "center" }}>
        <Text translate style={stylesPDF.headerFont}>
          label_product
        </Text>
      </View>
      <View style={{ ...stylesPDF.unitColumn, alignItems: "center" }}>
        <Text translate style={stylesPDF.headerFont}>
          label_unit
        </Text>
      </View>
      <View style={{ ...stylesPDF.pricePerUnitColumn, alignItems: "center" }}>
        <Text translate style={stylesPDF.headerFont}>
          label_price_per_unit
        </Text>
      </View>
      <View style={{ ...stylesPDF.totalColumn, alignItems: "center" }}>
        <Text
          translate
          style={{
            ...stylesPDF.headerFont,
            paddingRight: 11
          }}
        >
          {"label_quantity_received"}
          {""} {/* Sometimes need to avoid truncation, not sure why */}
        </Text>
      </View>
    </View>
  );
}

const stylesPDF = StyleSheet.create({
  headerFont: {
    fontFamily: "Kanit-light",
    fontSize: 8,
    color: COLORS.onSurfaceHigh
  },
  rowFont: {
    fontFamily: "Kanit-regular",
    fontSize: 8,
    paddingTop: 2,
    paddingBottom: 2,
    color: COLORS.onSurfaceMedium
  },
  indexColumn: {
    flexBasis: 24,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  codeColumn: {
    flexBasis: 69,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  productColumn: {
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  unitColumn: {
    flexBasis: 46,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  pricePerUnitColumn: {
    flexBasis: 44,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  totalColumn: {
    flexBasis: 162,
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }
});

import Button from "components/Button/Button";
import { Modal, Title } from "containers/warehousing/components/ReusableModal";
import * as React from "react";
import {
  ADD_NEW_LOT_REASONS,
  AddNewLotReason,
  getAddNewLotReasonTranslation,
  useAddNewLotInWarehouse,
} from "./use-get-product-lots";
import { toasti18n } from "utils/toast";
import { useImmer } from "use-immer";
import * as moment from "moment";
import DatePicker from "components/DatePicker/DatePicker";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";

function useCanAddLot() {
  const canAddLot = useIsAdmin() || useIsAdminContract();
  return canAddLot;
}

export function AddNewWarehouseLotModalButton(props: {
  warehouseId: string;
  productId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canAddLot = useCanAddLot();
  if (!canAddLot) {
    return null;
  }

  return (
    <>
      <Button iconName="Plus" type="primary" onClick={() => setIsOpen(true)}>
        label_add_new_lot
      </Button>
      {isOpen ? (
        <AddNewWarehouseLotModal onClose={() => setIsOpen(false)} {...props} />
      ) : null}
    </>
  );
}

function AddNewWarehouseLotModal(props: {
  warehouseId: string;
  onClose: () => void;
  productId: string;
}) {
  const [form, setForm] = useImmer<{
    receivedDate: moment.Moment | null;
    expiryDate: moment.Moment | null;
    quantity: string;
    reason: AddNewLotReason | "";
  }>({
    expiryDate: null,
    receivedDate: null,
    quantity: "",
    reason: "",
  });

  const isFormDisabled =
    !form.expiryDate ||
    !form.expiryDate.isValid() ||
    !form.receivedDate ||
    !form.receivedDate.isValid() ||
    !form.reason ||
    !form.quantity ||
    isNaN(Number(form.quantity));

  const { mutate, isLoading } = useAddNewLotInWarehouse({
    onSuccess: () => {
      toasti18n.success();
      props.onClose();
    },
  });

  function handleCreate() {
    if (!form.reason) {
      throw new Error("Reason is required");
    }

    mutate({
      productId: props.productId,
      warehouseId: props.warehouseId,
      expiryDate: form.expiryDate.toString(),
      receivedDate: form.receivedDate.toString(),
      quantity: Number(form.quantity),
      additional: {
        reason: form.reason,
      },
    });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "720px",
        alignment: "start",
        heightStyles: {
          flex: "1 1 auto",
          maxHeight: "679px",
        },
      }}
    >
      <Title>label_add_new_lot</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          marginTop: "25px",
        }}
      >
        <TextField
          required
          label="label_quantity"
          value={form.quantity}
          onChange={(e) =>
            setForm((draft) => {
              draft.quantity = e.target.value;
            })
          }
        />

        <DatePicker
          label="label_received"
          value={form.receivedDate}
          onChange={(value) => {
            setForm((draft) => {
              draft.receivedDate = value;
            });
          }}
          required
        />
        <DatePicker
          label="label_expiration"
          value={form.expiryDate}
          onChange={(value) => {
            setForm((draft) => {
              draft.expiryDate = value;
            });
          }}
          required
        />

        <Select
          required
          label="label_reason"
          id="type"
          value={form.reason}
          onChange={(e) =>
            setForm((draft) => {
              draft.reason = e.target.value;
            })
          }
          options={Object.values(ADD_NEW_LOT_REASONS).map((reason) => ({
            value: reason,
            label: getAddNewLotReasonTranslation(reason),
          }))}
        />
      </div>
      <div style={{ flex: "1 1 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={handleCreate}
          loading={isLoading}
          disabled={isFormDisabled}
        >
          action_create
        </Button>
      </div>
    </Modal>
  );
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { httpClient } from 'common/services/transportService';
import Select from 'common/components/select/Select';
import Input from 'common/components/input/Input';

import TextButton from 'common/components/textbutton/TextButton';

import * as styles from './Debug.module.scss';

import Typography from 'common/components/typography/Typography';

import useIsVendiiUser from 'common/hooks/useIsVendiiUser';
import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';
import {
  useIsClerk,
  useIsRouteman,
  useIsSuperViewer,
  useIsTechnician,
  useIsViewer,
} from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

type IHTTPMethods = 'get' | 'post' | 'put' | 'patch' | 'delete';

function Debug(): JSX.Element {
  const cannotViewContent =
    useIsClerk() ||
    useIsTechnician() ||
    useIsRouteman() ||
    useIsSuperViewer() ||
    useIsViewer();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const { machineId }: { machineId: string } = useParams();
  const { data: userInfo } = useGetCurrentUser();
  const allowedToView = useIsVendiiUser() || userInfo.canDebug;

  const [selectedMethod, setSelectedMethod] = useState<null | IHTTPMethods>(
    'get'
  );

  const methodOptions = [
    { label: 'GET', value: 'get' },
    { label: 'POST', value: 'post' },
    { label: 'PUT', value: 'put' },
    { label: 'PATCH', value: 'patch' },
    { label: 'DELETE', value: 'delete' },
  ];

  function onSelect(method: IHTTPMethods) {
    setSelectedMethod(method);
  }

  const [endPoint, setEndPoint] = useState<null | string>(null);
  const [requestBody, setRequestBody] = useState<null | string>(null);
  const [responseBody, setResponseBody] = useState<object | null>(null);
  const [responseStatus, setResponseStatus] = useState<object | null>(null);

  // When new method selected, clear response and toggle visibility of request body field
  useEffect(() => {
    if (['get', 'delete'].includes(selectedMethod)) {
      // Do not show request body for get and delete methods
      setRequestBody(null);
    } else {
      // Ready to accept inputs for any other methods
      setRequestBody('');
    }
    // Clear response
    setResponseStatus(null);
    setResponseBody(null);
  }, [selectedMethod]);

  async function sendRequest() {
    try {
      setResponseStatus({ status: 'Loading...', statusText: 'Loading...' });
      setResponseBody(null);
      const response = await httpClient({
        method: selectedMethod,
        url: endPoint,
        data: ['get', 'delete'].includes(selectedMethod)
          ? undefined
          : JSON.parse(requestBody),
      });

      setResponseBody(response.data);
      setResponseStatus({
        status: response.status,
        statusText: response.statusText,
      });
    } catch (err) {
      setResponseStatus({
        message: err.message,
        status: err.response?.status,
        responseText: err.response?.statusText,
      });
      if (err.response?.data === undefined) setResponseBody(null);
      else {
        setResponseBody(err.response.data);
      }
    }
  }

  // If user is not a vendii user, render redirect to first tab (Inventory)
  if (!allowedToView) {
    return <Redirect to={`/machines/detail/${machineId}/inventory`} />;
  }

  return (
    <div className={styles.Debug}>
      <div className={styles.header}>
        <div className={styles.left}>
          <Typography type="headline-5" translationKey="workspace_debug" />

          <Typography type="headline-5" translationKey={' - ' + machineId} />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.subHeader}>
          <Typography type="body-1" text="Path Presets" />
        </div>
        <div className={styles.pathPresets}>
          <TextButton
            onClick={() => setEndPoint(`/debug/refillInProgress/${machineId}`)}
            icon="Plus"
            text="Verify Refill In Progress flag"
          />
          <TextButton
            onClick={() =>
              setEndPoint(`/debug/refillOrderCheck/${machineId}/false`)
            }
            icon="Plus"
            text="Trigger Refill Order"
          />
          {/* <TextButton
            onClick={() => setEndPoint(`/debug/refillOrderCheckAll/${machineId}/false`)}
            icon="Plus"
            text="Trigger Refill Order for all machines"
          /> */}
          <TextButton
            onClick={() =>
              setEndPoint(`/debug/slotConfigDifferences/${machineId}`)
            }
            icon="Plus"
            text="Boonterm VS vendii slots"
          />
          <TextButton
            onClick={() =>
              setEndPoint(`/debug/slotValueDifferences/${machineId}`)
            }
            icon="Plus"
            text="Inventory value differences"
          />
          <TextButton
            onClick={() =>
              setEndPoint(
                `/debug/modifyVendiiSlotNameValueFromBoontermByMaterialId/${machineId}`
              )
            }
            icon="Plus"
            text="Use boonterm value"
          />
          <TextButton
            onClick={() => setEndPoint('/debug/getOrgs')}
            icon="Plus"
            text="Get all orgs"
          />
          <TextButton
            onClick={() => setEndPoint(`/debug/getMachineOrg/${machineId}`)}
            icon="Plus"
            text="Get Machine Org"
          />
          <TextButton
            onClick={() =>
              setEndPoint(`/debug/moveMachine/${machineId}/enter_org_id_here`)
            }
            icon="Plus"
            text="Move Machine Org"
          />
          <TextButton
            onClick={() =>
              setEndPoint(
                `debug/calculateExpectedValues/${machineId}/enter_timestamp_here`
              )
            }
            icon="Plus"
            text="Calculate Expected Slot Values"
          />
          <TextButton
            onClick={() =>
              setEndPoint(
                `debug/inventoryEventHistory/${machineId}/enter_timestamp_here`
              )
            }
            icon="Plus"
            text="Inventory Event History"
          />
          <TextButton
            onClick={() =>
              setEndPoint(`debug/getSlotStatusFromBoonterm/${machineId}`)
            }
            icon="Plus"
            text="Get Machine Slot Statuses From Boonterm"
          />
          <TextButton
            onClick={() =>
              setEndPoint(`debug/syncSlotStatusFromBoonterm/${machineId}`)
            }
            icon="Plus"
            text="Sync Machine Slot Statuses From Boonterm"
          />
          <TextButton
            onClick={() => setEndPoint(`debug/createNewOrganisation`)}
            icon="Plus"
            text="Create New Organisation"
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.subHeader}>
          <Typography type="body-1" text="Request Line" />
        </div>
        <div className={styles.requestLineInputs}>
          <Select
            onSelect={onSelect}
            options={methodOptions}
            value={selectedMethod}
            className={styles.requestLineInputs__method}
          />
          <Input
            value={endPoint}
            onChange={(endPoint) => setEndPoint(endPoint)}
            label="Path"
            className={styles.requestLineInputs__path}
          />
          <TextButton
            translationKey="Send request"
            icon="Upload"
            onClick={sendRequest}
            className={styles.requestLineInputs__button}
          />
        </div>

        {/* Request body */}
      </div>
      {requestBody !== null && (
        <div className={styles.section}>
          <div className={styles.subHeader}>
            <Typography type="body-1" text="Request body" />
          </div>
          <textarea
            className={styles.textarea}
            placeholder="paste JSON here"
            value={requestBody}
            onChange={(e) => setRequestBody(e.target.value)}
          ></textarea>
        </div>
      )}

      {/* Response status & Error */}

      {responseStatus !== null && (
        <div className={styles.section}>
          <div className={styles.subHeader}>
            <Typography type="body-1" text="Response status line" />
          </div>

          <textarea
            className={`${styles.textarea} ${styles.textarea__small}`}
            readOnly={true}
            value={JSON.stringify(responseStatus, null, 4)}
          ></textarea>
        </div>
      )}

      {/* Response body */}

      {responseBody !== null && (
        <div className={styles.section}>
          <div className={styles.subHeader}>
            <Typography type="body-1" text="Response body" />
          </div>

          <textarea
            className={`${styles.textarea} ${styles.textarea__large}`}
            readOnly={true}
            value={JSON.stringify(responseBody, null, 4)}
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default Debug;

import * as React from 'react';
import { useMemo } from 'react';

import Typography from 'common/components/typography/Typography';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';
import Tooltip from 'common/components/tooltip/Tooltip';
import getHorizontalAlign from 'common/components/tooltip/util/getHoriztonalAlign';

import TooltipContent from './tooltipcontent/TooltipContent';
import getPositionData from './util/getPositionData';

import * as colors from 'common/styles/colors.scss';
import * as styles from './BinaryStatus.module.scss';

export interface IPosition extends IBinaryState {
  renderWidth: number,
  timeToNextDataPoint: number,
  nextTimestamp: number
}

export interface IBinaryState {
  timestamp: number,
  state: 0 | 1 | 2
}

interface IBinaryStatusProps {
  data: IBinaryState[],
  from: number,
  to: number,
  onState: {
    label: string,
    color: string
  },
  offState: {
    label: string,
    color: string
  },
  title: string
}

function BinaryStatus ({
  data = [],
  from,
  to,
  onState,
  offState,
  title
}: IBinaryStatusProps): JSX.Element {
  const positions = useMemo(() => {
    return getPositionData(data, from, to);
  }, [ data, from, to ]);

  const colorMap = {
    2: 'onSurfaceDisabled',
    1: onState.color,
    0: offState.color
  };

  return (
    <div className={styles.BinaryStatus}>
      <div className={styles.header}>
        <Typography
          type='subtitle-1'
          translationKey={title}
        />
        <div className={styles.legend}>
          <IndicatorLabel
            color={onState.color}
            label={onState.label}
            className={styles.indicator}
          />
          <IndicatorLabel
            color={offState.color}
            label={offState.label}
            className={styles.indicator}
          />
          <IndicatorLabel
            color='onSurfaceDisabled'
            label='label_unknown'
            className={styles.indicator}
          />
        </div>
      </div>
      <div className={styles.bar}>
        {positions.map((position: IPosition, index) => {
          const align = getHorizontalAlign(index, positions.length);
          return (
            <Tooltip
              key={index}
              align={align}
              content={<TooltipContent position={position} />}
              className={styles.positionWrapper}
              contentClassName={styles.tooltipContent}
              style={{
                width: `${position.renderWidth}%`
              }}
            >
              <div
                className={styles.position}
                style={{
                  backgroundColor: colors[colorMap[position.state]]
                }}
              />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

export default BinaryStatus;

import { Package } from "./types";

export function computeAmountInPackagesWithRemainder(
  p: Package,
  value: number
): { numberOfPackages: number; remainder: number } {
  const isValueNegative = value < 0;

  value = Math.abs(value);
  let numberOfPackages = Math.floor(
    value / p.packageInfo.productQuantityPerPackage
  );
  let remainder =
    value - numberOfPackages * p.packageInfo.productQuantityPerPackage;

  numberOfPackages = isValueNegative ? -numberOfPackages : numberOfPackages;
  remainder = isValueNegative ? -remainder : remainder;

  return { numberOfPackages, remainder };
}

export function stringifyToAmountInPackageWithRemainder({
  p,
  value,
  oldQuantity,
}: {
  p: Package;
  value: number;
  oldQuantity?: number;
}): string {
  let { numberOfPackages, remainder } = computeAmountInPackagesWithRemainder(
    p,
    value
  );
  remainder = (oldQuantity ?? 0) + remainder;
  return [
    numberOfPackages !== 0
      ? `${numberOfPackages} ${p.packageInfo.packageUOM}`
      : null,
    remainder !== 0
      ? `${remainder} ${p.packageInfo.productUOMInPackage}`
      : null,
  ]
    .filter(Boolean)
    .join(' + ') || "-";
}

export function getPackageProductId(p: Package) {
  return p.packageInfo.product.id;
}

export function cs(...arg: Array<string>): string {
  return arg.join(' ');
}
import * as React from "react";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import Typography from "components/Typography/Typography";
import { GeneralContractInfo } from "containers/contract/hooks/useGetContractById";
import TextField from "components/TextField/TextField";
import DatePicker from "components/DatePicker/DatePicker";
import { OrganisationIdAutoComplete } from "./OrganisationIdAutoComplete";
import { AutocompleteOption } from "components/Autocomplete";

export function GeneralContractInfoView({
  generalInfo,
}: {
  generalInfo: GeneralContractInfo;
}) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_general
      </Typography>
      <div style={{ height: "20px" }} />

      <TextFieldViewOnly
        label="label_contract_id"
        value={generalInfo.contractFriendlyId}
      />

      <TextFieldViewOnly
        label="label_contract_period"
        value={
          generalInfo.contractStartDate.format("DD/MM/YYYY") +
          " - " +
          generalInfo.contractEndDate.format("DD/MM/YYYY")
        }
      />
      <TextFieldViewOnly
        label="label_organisation"
        value={generalInfo.organisation.name}
      />
      <TextFieldViewOnly
        label="label_number_of_locations_in_contract"
        value={String(generalInfo.numberOfLocationsPerContract)}
      />
    </div>
  );
}

export type GeneralContractInfoFormValue = Omit<
  GeneralContractInfo,
  "organisation" | "project" | "numberOfLocationsPerContract" | "contractId"
> & {
  organisationId: string;
  numberOfLocationsPerContract: string;
};

type GeneralContractInfoFormProps = {
  onChange: (value: GeneralContractInfoFormValue) => void;
  value: GeneralContractInfoFormValue;
  initialOrgOptions?: Array<AutocompleteOption>;
};

export function mapGeneralContractInfoToGeneralContractInfoFormValue(
  generalInfo: GeneralContractInfo
): GeneralContractInfoFormValue {
  return {
    contractFriendlyId: generalInfo.contractFriendlyId,
    contractStartDate: generalInfo.contractStartDate,
    contractEndDate: generalInfo.contractEndDate,
    organisationId: generalInfo.organisation.id,
    numberOfLocationsPerContract: String(
      generalInfo.numberOfLocationsPerContract
    ),
  };
}

export function initializeGeneralContractInfoFormValue(): GeneralContractInfoFormValue {
  return {
    contractFriendlyId: "",
    contractStartDate: null,
    contractEndDate: null,
    organisationId: "",
    numberOfLocationsPerContract: "",
  };
}

export function GeneralContractInfoForm({
  value,
  onChange,
  initialOrgOptions,
}: GeneralContractInfoFormProps) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_general
      </Typography>
      <div style={{ height: "20px" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <TextField
          label="label_contract_id"
          value={value.contractFriendlyId}
          onChange={(e) => {
            onChange({
              ...value,
              contractFriendlyId: e.target.value,
            });
          }}
        />

        <div
          style={{
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
            display: "grid",
          }}
        >
          <DatePicker
            label="label_from"
            value={value.contractStartDate}
            onChange={(newValue) =>
              onChange({
                ...value,
                contractStartDate: newValue,
              })
            }
          />
          <DatePicker
            label="label_to"
            value={value.contractEndDate}
            onChange={(newValue) =>
              onChange({
                ...value,
                contractEndDate: newValue,
              })
            }
          />
        </div>

        <OrganisationIdAutoComplete
          value={value.organisationId}
          onChange={(orgId) => {
            onChange({
              ...value,
              organisationId: orgId,
            });
          }}
          fallBackOptions={initialOrgOptions}
        />

        <TextField
          label="label_number_of_locations_in_contract"
          value={value.numberOfLocationsPerContract}
          onChange={(e) => {
            onChange({
              ...value,
              numberOfLocationsPerContract: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
}


import * as React from "react";
import { flexRender, Table } from "@tanstack/react-table";
import { SkeletonCell } from "./SkeletonCell";
import {
  InputValuesState,
  getColumnInputValue,
  setColumnInputValue,
} from "../inputState";
import { InputStateType } from "../types/tableTypes";
import { SortIcon } from "./SortIcon";
import { tableV2Tracking } from "../tracking";
import { clsx } from "clsx";
import { getCommonPinningStyles } from "./getCommonPinningStyles";
import Icon from "common/components/icon/Icon";
import { useTableContext } from "../tableState";

type TableProps<T> = InputStateType & {
  table: Table<T>;
  isLoading: boolean;
  rowPerPage: number;
};

export function TableComponent<T>({
  table,
  isLoading,
  rowPerPage,
  inputState,
  onInputStateChange,
}: TableProps<T>) {
  const { setActiveInPlaceFilterColumnId } = useTableContext();
  const tableName = table.getState().tableName;
  const [inputClientState, setInputClientState] =
    React.useState<InputValuesState>([]);
  const totalPinnedLeft = table
    .getAllLeafColumns()
    .filter((col) => col.getIsPinned() && col.getIsVisible()).length;
  const headerGroups = table.getHeaderGroups();

  return (
    <table className="table-auto border-separate border-spacing-0 overflow-hidden min-w-full font-kanit max-w-full overflow-x-auto border-t !border-outline border-solid">
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            <tr
              key={headerGroup.id}
              className={headerGroups.length === 1 ? "h-[50px]" : "h-[25px]"}
            >
              {headerGroup.headers.map((header) => {
                const { className, style } = getCommonPinningStyles({
                  column: header.column,
                  totalPinnedLeft,
                });
                if (header.column.columnDef.meta?.hide) return null;

                const columnRelativeDepth = header.depth - header.column.depth;

                if (columnRelativeDepth > 1) {
                  return null;
                }

                let rowSpan = 1;
                if (header.isPlaceholder) {
                  const leafs = header.getLeafHeaders();
                  rowSpan = leafs[leafs.length - 1].depth - header.depth;
                }

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    rowSpan={rowSpan}
                    className={clsx(
                      "relative py-0 gap-1 bg-overlay-surface-04 text-left !border-outline border-solid text-on-surface-medium text-caption",
                      header.column.columns ? "h-[25px]" : "h-[50px]",
                      !(header.column.columnDef.meta as any)?.rowSelection &&
                        "px-2",
                      header.column.columnDef.meta?.className,
                      header.column.columnDef.meta?.headerClassName,
                      header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : "",
                      className
                    )}
                    style={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                      ...style,
                    }}
                    onClick={() => {
                      header.column.toggleSorting();
                      tableV2Tracking({
                        name: "table header cell clicked",
                        properties: {
                          column_id: header.column.id,
                          can_sort: header.column.getCanSort(),
                          is_sorted: header.column.getIsSorted(),
                        },
                        tableName,
                      });
                    }}
                  >
                    <div className="flex items-center justify-between w-full">
                      {/* <p>{header.id === header.column.id && "row" + rowSpan}</p> */}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {/* Sort Icon */}
                      {(header.column.getCanSort() &&
                        {
                          asc: (
                            <SortIcon canSort isSorted isSortedDesc={false} />
                          ),
                          desc: (
                            <SortIcon canSort isSorted isSortedDesc={true} />
                          ),
                        }[header.column.getIsSorted() as string]) ??
                        null}
                      {!header.column.getIsSorted() &&
                        header.column.getCanSort() && (
                          <SortIcon
                            canSort
                            isSorted={false}
                            isSortedDesc={true}
                          />
                        )}
                      {/* In-Place Filter Icon */}
                      {header.column.columnDef?.enableColumnFilter &&
                        header.column.columnDef?.meta?.columnFilter
                          ?.location === "in-place" && (
                          <div
                            onClick={() => setActiveInPlaceFilterColumnId(header.column.id)}
                            className={clsx(
                              "group flex p-px cursor-pointer rounded-sm",
                              header.column.getFilterValue()
                                ? "bg-primary"
                                : "hover:bg-primary"
                            )}
                          >
                            <Icon
                              name="Filter2"
                              color={
                                header.column.getFilterValue()
                                  ? "white"
                                  : "onSurfaceMedium"
                              }
                              className={clsx(
                                "w-3 h-3",
                                header.column.getFilterValue()
                                  ? ""
                                  : "group-hover:hidden"
                              )}
                            />
                            {!header.column.getFilterValue() && (
                              <Icon
                                name="Filter2"
                                color="white"
                                className="w-3 h-3 hidden group-hover:block"
                              />
                            )}
                          </div>
                        )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {isLoading &&
          Array(rowPerPage)
            .fill("")
            .map((_, key) => (
              <tr key={key}>
                {table.getAllLeafColumns().map((column) => (
                  <td
                    key={column.id}
                    className="px-2.5 py-4 !border-t !border-x-0 !border-outline border-solid"
                  >
                    <SkeletonCell />
                  </td>
                ))}
              </tr>
            ))}
        {!isLoading &&
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={clsx(
                "h-[50px]",
                row.getIsSelected() && "bg-primary-50"
              )}
            >
              {row.getVisibleCells().map((cell) => {
                const { className, style } = getCommonPinningStyles({
                  column: cell.column,
                  totalPinnedLeft,
                });
                if (cell.column.columnDef.meta?.hide) return null;

                return (
                  <td
                    key={`${cell.id}`}
                    className={clsx(
                      "relative bg-white py-2 !border-t !border-outline border-solid text-body2 text-on-surface-high",
                      !cell.column.columnDef.meta?.rowSelection && "px-2",
                      cell.column.columnDef.meta?.className,
                      cell.column.columnDef.meta?.bodyClassName,
                      className
                    )}
                    width={cell.column.columnDef.size}
                    style={{
                      width: cell.column.columnDef.size,
                      minWidth: cell.column.columnDef.minSize,
                      maxWidth: cell.column.columnDef.maxSize,
                      ...style,
                    }}
                    onClick={() =>
                      tableV2Tracking({
                        name: "table body cell clicked",
                        properties: {
                          cell_id: cell.id,
                          column_id: cell.column.id,
                        },
                        tableName,
                      })
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      getInputValue: getColumnInputValue({
                        id: cell.id,
                        inputState: inputState || inputClientState,
                      })(),
                      onInputChange: (val) => {
                        onInputStateChange
                          ? onInputStateChange(
                              setColumnInputValue({
                                id: cell.id,
                                inputState: inputState,
                                val,
                              })
                            )
                          : setInputClientState(
                              setColumnInputValue({
                                id: cell.id,
                                inputState: inputClientState,
                                val,
                              })
                            );
                      },
                    })}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

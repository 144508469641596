import * as React from "react";

interface IRotateCWProps {
  color?: string;
  className?: string;
}

function RotateCW({ color = "white", className }: IRotateCWProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8069 7C16.4465 5.0787 14.3936 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12H22C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C14.8042 2 17.2749 3.18251 19 5.27035V2H21V9H14V7H17.8069Z"
        fill={color}
      />
    </svg>
  );
}

export default RotateCW;

import * as React from 'react';
import { HistogramCard, HistogramCardProps } from './HistogramCard';
import { LineGraphCard, LineGraphCardProps } from './LineGraphCard';
import { PieChartCard, PieChartCardProps } from './PieChartCard';
import { TextCard, TextCardProps } from './TextCard';
import { PolarChartCard, PolarChartCardProps } from './PolarChartCard';

export interface DashboardCardLineGraphProps {
  type: 'line';
  cardProps: Omit<LineGraphCardProps, 'className'>;
  className?: string;
}
export interface DashboardCardPieChartProps {
  type: 'pie';
  cardProps: Omit<PieChartCardProps, 'className'>;
  className?: string;
}

export interface DashboardCardHistogramProps {
  type: 'histogram';
  cardProps: Omit<HistogramCardProps, 'className'>;
  className?: string;
}

export interface DashboardCardTextProps {
  type: 'text';
  cardProps: Omit<TextCardProps, 'className'>;
  className?: string;
}

export interface DashboardCardPolarChartCardProps {
  type: 'polar';
  cardProps: Omit<PolarChartCardProps, 'className'>;
  className?: string;
}

export type DashboardCardProps =
  | DashboardCardLineGraphProps
  | DashboardCardPieChartProps
  | DashboardCardHistogramProps
  | DashboardCardPolarChartCardProps
  | DashboardCardTextProps;

export function DashboardCard(props: DashboardCardProps): JSX.Element {
  switch (props.type) {
    case 'line':
      return <LineGraphCard className={props.className} {...props.cardProps} />;
    case 'pie':
      return <PieChartCard className={props.className} {...props.cardProps} />;
    case 'histogram':
      return <HistogramCard className={props.className} {...props.cardProps} />;
    case 'text':
      return <TextCard className={props.className} {...props.cardProps} />;
    case 'polar':
      return <PolarChartCard className={props.className} {...props.cardProps} />;
  }
}

import { httpClient } from 'common/services/transportService';
import {
  IRefillOrder,
  ICreateRefillOrder,
  IPatchCreateRefillOrder,
  IPatchRefillRefillOrder,
  IPatchAdjustRefillOrder,
} from 'refillorders/interfaces';
import * as moment from 'moment';

export async function getRefillOrders(): Promise<IRefillOrder[]> {
  const response = await httpClient.get<IRefillOrder[]>('/refillorder');
  return response.data;
}

export async function createRefillOrder(
  refillOrderData: ICreateRefillOrder
): Promise<IRefillOrder> {
  const response = await httpClient.post<IRefillOrder>(
    '/refillorder',
    refillOrderData
  );
  return response.data;
}

export async function patchDismissRefillOrder(
  orderNumber: string
): Promise<string> {
  const response = await httpClient.patch<string>(
    `/refillorder/${orderNumber}/dismiss`
  );
  return response.data;
}

export async function patchCreateRefillOrder(
  data: IPatchCreateRefillOrder
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${data.orderNumber}/create`,
    {
      assignee: data.assignee,
      warehouseId: data.warehouseId,
    }
  );
  return response.data;
}

export async function patchPrekitRefillOrder(
  orderNumber: string
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${orderNumber}/prekit`
  );
  return response.data;
}

export async function patchPickupRefillOrder(
  orderNumber: string
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${orderNumber}/pickup`
  );
  return response.data;
}

export async function patchRefillRefillOrder(
  data: IPatchRefillRefillOrder
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${data.orderNumber}/complete`,
    {
      needAdjustment: data.needAdjustment,
    }
  );
  return response.data;
}

export async function patchAdjustRefillOrder(
  data: IPatchAdjustRefillOrder
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${data.orderNumber}/adjust`,
    {
      itemsAdjusted: data.itemsAdjusted,
    }
  );
  return response.data;
}

export async function patchCancelRefillOrder(
  orderNumber: string
): Promise<string> {
  const response = await httpClient.patch<string>(
    `/refillorder/${orderNumber}/cancel`
  );
  return response.data;
}

export async function patchUndoRefillOrder(
  orderNumber: string
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${orderNumber}/undo`
  );
  return response.data;
}

export async function acceptAdjustRefillOrder(
  orderNumber: string
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${orderNumber}/adjust`,
    undefined,
    {
      params: {
        action: 'adjustQuantity',
      },
    }
  );
  return response.data;
}

export async function dismissAdjustRefillOrder(
  orderNumber: string
): Promise<IRefillOrder> {
  const response = await httpClient.patch<IRefillOrder>(
    `/refillorder/${orderNumber}/adjust`,
    undefined,
    {
      params: {
        action: 'noAdjustQuantity',
      },
    }
  );

  return response.data;
}

export async function getTriggerRefill(
  machineId: string
): Promise<IRefillOrder> {
  const response = await httpClient.get<IRefillOrder>(
    `/refillorder/trigger/${machineId}`
  );
  return response.data;
}

export async function getRefillSnapshotCSV(queryParams: {
  timestampMS: string;
  status: 'en_route' | 'adjustment' | 'completed';
  unitType: 'uom' | 'package';
  reportType: 'warehouse' | 'machine' | 'snapshot' | 'machine-inventory';
}): Promise<string> {
  const response = await httpClient.get<string>(
    `/refillOrder/snapshot/report`,
    {
      params: {
        status: queryParams.status,
        date: queryParams.timestampMS,
        unitType: queryParams.unitType,
        reportType: queryParams.reportType,
      },
    }
  );
  return response.data;
}

type State = 'kitted' | 'en_route' | 'refilled' | 'completed';
export async function getMaterialMovementReport({ timestampMS, state }: { timestampMS: string, state: State }) {
  const isoString = moment(Number(timestampMS)).toISOString();
  const response = await httpClient.get<string>(
    `/warehouse/materialMovementReport?timestamp=${encodeURIComponent(isoString)}&state=${state}`
  );
  return response.data;
}

export async function getMaterialMovementReportByRefillZone({ timestampMS, state }: { timestampMS: string, state: State }) {
  const isoString = moment(Number(timestampMS)).toISOString();
  const response = await httpClient.get<string>(
    `/warehouse/materialMovementReportByRefillZone?timestamp=${encodeURIComponent(isoString)}&state=${state}`
  );
  return response.data;
}
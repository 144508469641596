import * as React from "react";

import { useTranslation } from "react-i18next";

import {
  TimePicker as MUITimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers/TimePicker";
import { Moment } from "moment";
import Icon from "common/components/icon/Icon";
import TextField from "components/TextField";

import { createTheme, TextFieldProps, ThemeProvider } from "@mui/material";
import * as colors from "common/styles/colors.scss";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface MyTimePickerProps<TDate = unknown>
  extends Omit<TimePickerProps<TDate>, "renderInput"> {
  required?: boolean;
  label: string;
}

/**
 *
 * ## Usage
 * ```
 * function ExampleTimePicker() {
 * const [value, setValue] = React.useState<moment.Moment | null>(null);
 * return (
 *  <TimePicker
 *    label="label_start_time"
 *    value={value}
 *    onChange={newValue => setValue(newValue)}
 *  />
 * )
 * }
 * ```
 */

// Impossible to use CSS to customize the color
const theme = createTheme({
  palette: {
    primary: {
      "500": colors.primary500,
    },
  },
});

function TimePicker({
  label,
  required = false,
  ...props
}: MyTimePickerProps<Moment>) {
  const { t } = useTranslation();

  const MemoizedTextField = React.useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: TextFieldProps) => (
      <TextField required={required} {...props} />
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MUITimePicker
          label={t(label)}
          slots={{
            openPickerIcon: function CalendarIcon() {
              return <Icon name="Clock" color="primary" />;
            },
            textField: MemoizedTextField,
          }}
          {...props}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default TimePicker;

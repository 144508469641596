import TextButton from "common/components/textbutton/TextButton";
import * as React from "react";
import {
  useAcceptInventoryTransfer,
  useGetWarehouseLotTransfers,
  useRejectInventoryTransfer,
} from "./use-get-lot-transfer";
import {
  Modal,
  Title,
  modalBorderStyle,
  modalSpacing,
} from "containers/warehousing/components/ReusableModal";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import * as moment from "moment";
import { useGetUsers } from "userandauth/hooks/useGetUsers";
import { useGetWarehouses } from "warehousing/hooks/useGetWarehouses";
import { Skeleton } from "@mui/material";
import { useDownloadTransferInventoryPDF } from "pdf/hooks/useDownloadTransferInventoryPDF";
import { toasti18n } from "utils/toast";
import { useIsAdmin, useIsAdminContract, useIsClerk } from "utils/user-role";

function usCanAcceptInventory() {
  const canAcceptInventory = useIsAdmin() || useIsClerk() || useIsAdminContract();
  return canAcceptInventory;
}

export function AcceptInventoryModalButton({
  warehouseId,
}: {
  warehouseId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canAcceptInventory = usCanAcceptInventory();

  if (!canAcceptInventory) {
    return null;
  }

  const warehouseLotsTransferQuery = useGetWarehouseLotTransfers(warehouseId);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        {warehouseLotsTransferQuery.data?.length ? (
          <div
            style={{
              position: "absolute",
              width: "16px",
              height: "16px",
              display: "grid",
              placeContent: "center",
              backgroundColor: "#E95D50",
              borderRadius: "50%",
              whiteSpace: "nowrap",
              left: "-8px",
              top: "-8px",
            }}
          >
            <span
              style={{
                fontFamily: "Kanit",
                fontSize: "8px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "white",
              }}
            >
              {warehouseLotsTransferQuery.data?.length}
            </span>
          </div>
        ) : null}

        <TextButton
          icon="AcceptInventory"
          translationKey="label_accept_inventory"
          onClick={() => setIsOpen(true)}
        />
      </div>
      {isOpen ? (
        <AcceptInventoryModal
          onClose={() => setIsOpen(false)}
          warehouseId={warehouseId}
        />
      ) : null}
    </>
  );
}

function AcceptInventoryModal({
  onClose,
  warehouseId,
}: {
  onClose: () => void;
  warehouseId: string;
}) {
  const warehouseLotsTransferQuery = useAcceptInventoryModalQuery(warehouseId);
  const [selectedTransferId, setSelectedTransferId] = React.useState<
    null | string
  >(null);
  const selectedTransfer = warehouseLotsTransferQuery.data?.find(
    (transfer) => transfer.id === selectedTransferId
  );

  return (
    <Modal
      onClose={onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "712px",
        heightStyles: {
          flex: "1 1 0",
          maxHeight: "760px",
          minHeight: "600px",
        },
        alignment: "start",
      }}
    >
      {selectedTransferId === null ? (
        <>
          <Title>label_accept_inventory</Title>
          <div style={{ overflow: "auto", flex: "1 1 0", minHeight: "0px" }}>
            <div
              style={{
                minWidth: "fit-content",
              }}
            >
              <div
                style={{
                  display: "flex",
                  minHeight: "45px",
                  position: "sticky",
                  zIndex: 2,
                  top: "0",
                  backgroundColor: "white",
                  borderBottom: modalBorderStyle,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: "1 1 100px",
                    minWidth: "100px",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceDisabled" translate>
                    label_created
                  </Typography>
                </div>
                <div
                  style={{
                    flex: "1 1 107px",
                    minWidth: "107px",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceDisabled" translate>
                    label_order_number
                  </Typography>
                </div>
                <div
                  style={{
                    flex: "1 1 107px",
                    minWidth: "107px",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceDisabled" translate>
                    label_from
                  </Typography>
                </div>
                <div
                  style={{
                    flex: "1 1 107px",
                    minWidth: "107px",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceDisabled" translate>
                    label_created_by
                  </Typography>
                </div>
                <div
                  style={{
                    flex: "1 1 100px",
                    minWidth: "100px",
                  }}
                ></div>
              </div>

              {warehouseLotsTransferQuery.isLoading
                ? new Array(10).fill(null).map((_, index) => (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: modalBorderStyle,
                        minHeight: "45px",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          flex: "1 1 100px",
                          minWidth: "100px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Skeleton width={"100%"} />
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1 1 107px",
                          minWidth: "107px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Skeleton width={"100%"} />
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1 1 107px",
                          minWidth: "107px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Skeleton width={"100%"} />
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1 1 107px",
                          minWidth: "107px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Skeleton width={"100%"} />
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1 1 100px",
                          minWidth: "100px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Skeleton width={"100%"} />
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {warehouseLotsTransferQuery.data?.map((lotTransfer) => (
                <div
                  style={{
                    display: "flex",
                    minHeight: "45px",
                    alignItems: "center",
                    borderBottom: modalBorderStyle,
                  }}
                  key={lotTransfer.id}
                >
                  <div
                    style={{
                      flex: "1 1 100px",
                      minWidth: "100px",
                    }}
                  >
                    <Typography type="body-2" translate>
                      {moment(lotTransfer.createdAt).format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      flex: "1 1 107px",
                      minWidth: "107px",
                    }}
                  >
                    <Typography type="body-2" translate>
                      {lotTransfer.id.slice(0, 8)}
                    </Typography>
                  </div>
                  <div
                    style={{
                      flex: "1 1 107px",
                      minWidth: "107px",
                    }}
                  >
                    <Typography type="body-2" translate>
                      {lotTransfer.fromWarehouse.name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      flex: "1 1 107px",
                      minWidth: "107px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={lotTransfer.user.userAvatar}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <Typography type="body-2" translate>
                        {lotTransfer.user.firstName}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1 1 100px",
                      minWidth: "100px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={() => setSelectedTransferId(lotTransfer.id)}
                      type="primary"
                      style={{
                        lineHeight: 1.25,
                      }}
                    >
                      label_review
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}

      {selectedTransfer ? (
        <LotTransferIdView
          warehouseId={warehouseId}
          selectedTransfer={selectedTransfer}
          onBack={() => setSelectedTransferId(null)}
          onRejectOrAccept={onClose}
        />
      ) : null}
    </Modal>
  );
}

function LotTransferIdView({
  selectedTransfer,
  warehouseId,
  onBack,
  onRejectOrAccept,
}: {
  warehouseId: string;
  selectedTransfer: LotTransfer;
  onBack: () => void;
  onRejectOrAccept: () => void;
}) {
  const { downloadPDF } = useDownloadTransferInventoryPDF({
    transferId: selectedTransfer.id,
    warehouseId: warehouseId,
  });

  function onSuccess() {
    toasti18n.success();
    onRejectOrAccept();
  }

  const { mutate: acceptTransfer, isLoading: isAccepting } =
    useAcceptInventoryTransfer({ onSuccess });
  const { mutate: rejectTransfer, isLoading: isRejecting } =
    useRejectInventoryTransfer({ onSuccess });

  const isLoading = isRejecting || isAccepting;

  return (
    <>
      <Title>{["label_review", ` (${selectedTransfer.id.slice(0, 8)})`]}</Title>
      <div
        style={{
          overflow: "auto",
          flex: "1 1 0",
        }}
      >
        <div
          style={{
            display: "flex",
            borderBottom: modalBorderStyle,
            minHeight: "45px",
            alignItems: "center",
            position: "sticky",
            top: "0",
            left: "0",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              flex: "1 1 317px",
              paddingLeft: "14px",
              boxSizing: "border-box",
            }}
          >
            <Typography type="caption" color="onSurfaceMedium" translate>
              label_ingredient
            </Typography>
          </div>
          <div style={{ flex: "1 1 82px" }}>
            <Typography type="caption" color="onSurfaceMedium" translate>
              label_lots
            </Typography>
          </div>
          <div style={{ flex: "0 0 60px" }}>
            <Typography type="caption" color="onSurfaceMedium" translate>
              label_quantity
            </Typography>
          </div>
        </div>

        {selectedTransfer.payload.incomingInventory.map((item) => (
          <div
            key={item.productId}
            style={{
              display: "flex",
              borderBottom: modalBorderStyle,
              minHeight: "45px",
              alignItems: "center",
            }}
          >
            <div style={{ flex: "1 1 317px", minWidth: "0px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: "0 0 40px",
                    minWidth: "40px",
                  }}
                >
                  <img
                    src={item.image}
                    style={{
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1px",
                    paddingRight: "30px",
                  }}
                >
                  <Typography type="body-2">{item.productName}</Typography>
                  <Typography type="caption" color="onSurfaceDisabled">
                    {item.UPC}
                  </Typography>
                </div>
              </div>
            </div>
            <div style={{ flex: "1 1 82px" }}>
              <Typography type="body-2">
                {item.lotNo + ` (${item.lotId.slice(0, 6)})`}
              </Typography>
            </div>

            <div style={{ flex: "0 0 60px" }}>
              <Typography type="body-2">{item.quantity}</Typography>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: modalBorderStyle,
          marginLeft: `-${modalSpacing.horizontalSpace}`,
          marginRight: `-${modalSpacing.horizontalSpace}`,
          paddingLeft: modalSpacing.horizontalSpace,
          paddingRight: modalSpacing.horizontalSpace,
          paddingTop: modalSpacing.verticalSpace,
        }}
      >
        <Button
          type="secondary"
          onClick={onBack}
          disabled={isLoading}
          style={{
            flexShrink: 0,
          }}
        >
          label_back
        </Button>
        <div style={{ display: "flex", gap: "16px" }}>
          <TextButton
            icon="Printer"
            disabled={isLoading}
            translationKey="action_print_order"
            onClick={() => {
              downloadPDF();
            }}
          />
          <Button
            type="primary"
            loading={isRejecting}
            disabled={isAccepting}
            onClick={() => {
              rejectTransfer({ id: selectedTransfer.id });
            }}
            style={{
              flexShrink: 0,
            }}
          >
            action_reject
          </Button>
          <Button
            type="primary"
            loading={isAccepting}
            disabled={isRejecting}
            onClick={() => {
              acceptTransfer({ id: selectedTransfer.id });
            }}
            style={{
              flexShrink: 0,
            }}
          >
            action_confirm
          </Button>
        </div>
      </div>
    </>
  );
}

function useAcceptInventoryModalQuery(warehouseId: string) {
  const useGetWarehouseLotTransfersQuery =
    useGetWarehouseLotTransfers(warehouseId);
  const useGetAllUsersResult = useGetUsers();
  const useGetWarehousesResult = useGetWarehouses();

  const isLoading =
    useGetWarehouseLotTransfersQuery.isLoading ||
    useGetAllUsersResult.isLoading ||
    useGetWarehousesResult.isLoading;

  const data = isLoading
    ? undefined
    : useGetWarehouseLotTransfersQuery.data.map((lotTransfer) => {
        return {
          ...lotTransfer,
          fromWarehouse: useGetWarehousesResult.data?.find(
            (warehouse) => warehouse.warehouseId === lotTransfer.fromWarehouseId
          ),
          user: useGetAllUsersResult.data?.find(
            (user) => user.firstName === lotTransfer.payload.createdBy
          ),
        };
      });

  return {
    isLoading,
    data,
  };
}
type LotTransfer = ReturnType<typeof useAcceptInventoryModalQuery>["data"][0];

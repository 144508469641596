import * as React from 'react';

import * as SCSScolors from 'common/styles/colors.scss';
import * as styles from './Indicator.module.scss';

interface IIndicatorProps {
  className?: string;
  color?: 'error' | 'success' | 'warning' | 'investigate' | undefined ;
  type: 'circle' | 'square';
}

function Indicator ({
  color,
  type = 'circle',
  className = ''
}: IIndicatorProps): JSX.Element {
  return (
    <div
      style={{ backgroundColor: SCSScolors[color] }}
      className={[
        styles.indicator,
        type === 'circle' ? styles.circle : '',
        type === 'square' ? styles.square : '',
        className
      ].join(' ')}
    ></div>
  );
}

export { Indicator, IIndicatorProps };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button--3GYqBxrxum{cursor:pointer;display:flex;justify-content:center;align-items:center;height:36px;padding:10px;border-radius:4px;transition:all 100ms ease-in;box-sizing:border-box;user-select:none}.Button--3GYqBxrxum.primary--HIVnL_y6YK{background-color:#4b4d9b;color:#fff}.Button--3GYqBxrxum.primary--HIVnL_y6YK:hover{background:#52549f}.Button--3GYqBxrxum.primary--HIVnL_y6YK:active{background:#6163a7}.Button--3GYqBxrxum.primary--HIVnL_y6YK.loading--1JvJaK8Ngp{pointer-events:none;background:#6163a7}.Button--3GYqBxrxum.secondary--2FAdEJ9YKa{background-color:#fff;color:#4b4d9b;border:1px solid rgba(0,0,0,.12)}.Button--3GYqBxrxum.secondary--2FAdEJ9YKa:hover{background:#f5f5f5}.Button--3GYqBxrxum.secondary--2FAdEJ9YKa:active{background:#e0e0e0}.Button--3GYqBxrxum.secondary--2FAdEJ9YKa.loading--1JvJaK8Ngp{pointer-events:none;background:#6163a7}.Button--3GYqBxrxum.status--1i1RMJILDd{background-color:#e95d50;color:#fff}.Button--3GYqBxrxum.status--1i1RMJILDd:hover{background:hsl(4,75%,63%)}.Button--3GYqBxrxum.status--1i1RMJILDd:active{background:hsl(4,71%,68%)}.Button--3GYqBxrxum.status--1i1RMJILDd.loading--1JvJaK8Ngp{pointer-events:none;background:hsl(5,71%,68%)}.Button--3GYqBxrxum.disabled--ymFPOy8SEO{background:#d7d7d7;color:rgba(0,0,0,.38);pointer-events:none}.Button--3GYqBxrxum .preIcon--1GnFjRKdRG{margin-right:5px}.Button--3GYqBxrxum .postIcon--3hpzMwen_u{margin-left:5px}.Button--3GYqBxrxum .onlyIcon--3ou2twdsfI{margin:0}", ""]);
// Exports
exports.locals = {
	"primary": "primary--HIVnL_y6YK",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "secondary--2FAdEJ9YKa",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "disabled--ymFPOy8SEO",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Button": "Button--3GYqBxrxum",
	"loading": "loading--1JvJaK8Ngp",
	"status": "status--1i1RMJILDd",
	"preIcon": "preIcon--1GnFjRKdRG",
	"postIcon": "postIcon--3hpzMwen_u",
	"onlyIcon": "onlyIcon--3ou2twdsfI"
};
module.exports = exports;

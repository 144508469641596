import Icon, { IIconProps } from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import * as React from "react";
import * as styles from "./IconAndTextCell.module.scss";

export interface IconAndTextCellProps {
  iconProps?: Pick<IIconProps, "color" | "name">;
  translationKey?: string;
}

function IconAndTextCell(
  iconAndTextCellArgs: IconAndTextCellProps | null | undefined
) {
  if (iconAndTextCellArgs === undefined || iconAndTextCellArgs === null) {
    iconAndTextCellArgs = {
      iconProps: { name: undefined },
      translationKey: undefined
    };
  }

  return (
    <div className={styles.IconAndTextCell}>
      <div className={styles.IconContainer}>
        <Icon {...iconAndTextCellArgs.iconProps} />
      </div>
      <Typography
        type="body-2"
        translationKey={iconAndTextCellArgs.translationKey}
      />
    </div>
  );
}

export { IconAndTextCell };

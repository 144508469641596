import * as React from 'react';

interface IChevronProps {
  color?: string,
  className?: string
}

function Chevron ({
  color = 'white',
  className
}: IChevronProps): JSX.Element {
  return (
    <svg className={className} width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 13L1 7L7 1' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
}

export default Chevron;

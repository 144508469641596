import { SortingState, TableV2 } from "components/tableV2";
import * as React from "react";
import { IMachineInventoryWithPercentageData } from "./mapDataToTable";
import { generateInventoryColumns } from "./generateNonAdminColumns";
import { useTranslation } from "react-i18next";

export type TableActionHandlerProps = {
  handleQuantityEdit: (data: IMachineInventoryWithPercentageData) => void;
  handleIngredientEdit: (data: IMachineInventoryWithPercentageData) => void;
  handleMinMaxEdit: (data: IMachineInventoryWithPercentageData) => void;
  handleSwapInventoryCancel: (machineInventoryId: string) => void;
  handleActive: (data: IMachineInventoryWithPercentageData) => void;
  handleDisable: (data: IMachineInventoryWithPercentageData) => void;
}

export type  TablePermissionProps = {
  isForthCKUser: boolean;
  isAdmin: boolean;
  isMachineEnableWorkOrderCreation: boolean;
}

type InventoryTableProps = {
  isLoading: boolean;
  data: IMachineInventoryWithPercentageData[];
  action: TableActionHandlerProps;
  permission: TablePermissionProps;
};

export function InventoryTable({ isLoading, data, permission, action }: InventoryTableProps) {
  const { t } = useTranslation();
  const [sortingState, setSortingState] = React.useState<SortingState>([{
    id: "slot",
    desc: false,
  }]);
  const columns = generateInventoryColumns({ t, permission, action });
  return (
    <div>
      <TableV2
        rowSelection={{
          enable: true,
          getRowId: info => info.id,
        }}
        data={data}
        columns={columns}
        tableName="InventoryTable"
        isLoading={isLoading}
        manualFiltering={false}
        manualSorting={false}
        sorting={sortingState}
        onSortingChange={setSortingState}
      />
    </div>
  );
}

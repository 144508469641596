import { fetcher } from "gql/fetcher";
import {
  BankAccountType,
  ElectricityRateType,
  GetLocationContractAssociationDocument,
  GetLocationContractAssociationQuery,
  GetLocationContractAssociationQueryVariables,
  ServiceFeeType,
} from "gql/generated";
import { queryClient } from "index";
import * as moment from "moment";
import { useQuery } from "react-query";

async function fetchLocationFee({
  locationId,
  contractId,
}: {
  locationId: string;
  contractId: string;
}): Promise<ContractLocationFee> {
  const response = await fetcher<
    GetLocationContractAssociationQuery,
    GetLocationContractAssociationQueryVariables
  >(GetLocationContractAssociationDocument, {
    contractId: contractId,
    locationId: locationId,
    contract2ContractId2: contractId,
    locationId2: locationId,
  })();

  const data = response;
  if (data.contract2 === null) {
    throw new Error(`Contract ${contractId} not found`);
  }
  if (data.contractLocationAssociation === null) {
    throw new Error(
      `contractLocationAssociation not found for: Contract: ${contractId} Location: ${locationId}`
    );
  }

  const fees = data.contractLocationAssociation;

  let rentFee: RentFee;
  if (fees.rentFee.type === ServiceFeeType.FixedFee) {
    rentFee = {
      payWithCode: fees.rentFee.payWithCode,
      type: RENT_FEE_TYPE.FIXED,
      value: String(fees.rentFee.fixedFee),
    };
  } else if (fees.rentFee.type === ServiceFeeType.PercentageFee) {
    rentFee = {
      payWithCode: fees.rentFee.payWithCode,
      type: RENT_FEE_TYPE.PERCENTAGE,
      value: {
        minimumAmount: String(fees.rentFee.percentageFee.minimumAmount),
        segments: fees.rentFee.percentageFee.ladderPercentages.map(
          (segment) => ({
            lower: String(segment.lowerSales),
            upper: String(segment.upperSales),
            percentage: String(segment.percentage),
          })
        ),
      },
    };
  } else {
    rentFee = {
      payWithCode: fees.rentFee.payWithCode,
      type: RENT_FEE_TYPE.LADDER,
      value: fees.rentFee.steps.map((step) => ({
        from: moment(step.period.start),
        to: moment(step.period.end),
        rentalPrice: String(step.stepFee.amount),
      })),
    };
  }

  return {
    contract: {
      friendlyId: data.contract2.friendlyId,
      id: contractId,
    },
    location: {
      friendlyId: data.location.friendlyId,
      id: locationId,
      name: data.location.locationName,
    },
    fees: {
      electricFee: {
        fee: {
          type: ELECTRIC_FEE_TYPE_MAP[fees.electricFee.type],
          value: String(fees.electricFee.value),
        },
        paymentInformation: {
          accountNumber: fees.electricPaymentAccount.bankAccountNumber,
          bankName: fees.electricPaymentAccount.bankName,
          branchName: fees.electricPaymentAccount.bankBranch,
          email: fees.electricPaymentAccount.email,
          nameOnAccount: fees.electricPaymentAccount.bankAccountName,
          typeOfAccount:
            ACCOUNT_TYPE_MAP[fees.electricPaymentAccount.bankAccountType],
        },
      },
      rentFee: {
        fee: rentFee,
        paymentInformation: {
          accountNumber: fees.rentPaymentAccount.bankAccountNumber,
          bankName: fees.rentPaymentAccount.bankName,
          branchName: fees.rentPaymentAccount.bankBranch,
          email: fees.rentPaymentAccount.email,
          nameOnAccount: fees.rentPaymentAccount.bankAccountName,
          typeOfAccount:
            ACCOUNT_TYPE_MAP[fees.rentPaymentAccount.bankAccountType],
        },
      },
      insuranceFee: {
        fee: {
          value: String(fees.insuranceFee.amount),
        },
        paymentInformation: {
          accountNumber: fees.insurancePaymentAccount.bankAccountNumber,
          bankName: fees.insurancePaymentAccount.bankName,
          branchName: fees.insurancePaymentAccount.bankBranch,
          email: fees.insurancePaymentAccount.email,
          nameOnAccount: fees.insurancePaymentAccount.bankAccountName,
          typeOfAccount:
            ACCOUNT_TYPE_MAP[fees.insurancePaymentAccount.bankAccountType],
        },
      },
    },
  };
}

export function useGetLocationFee({
  locationId,
  contractId,
}: {
  locationId: string;
  contractId: string;
}) {
  return useQuery({
    queryFn: () => fetchLocationFee({ contractId, locationId }),
    queryKey: getQueryKeyForLocationFeeInContract({ contractId, locationId }),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

function getQueryKeyForLocationFeeInContract({
  contractId,
  locationId,
}: {
  contractId: string;
  locationId: string;
}) {
  return [
    {
      scope: "location-fee",
      contractId: contractId,
      locationId: locationId,
      graphqlQueries: ["GetLocationContractAssociation"],
    },
  ];
}

// Location fee cache

export const locationFeeCache = {
  addNewLocationFee,
  updateLocationFee,
  removeLocationFee,
};

function addNewLocationFee({
  contractId,
  locationId,
  data,
}: {
  contractId: string;
  locationId: string;
  data: ContractLocationFee;
}) {
  return setCachedLocationFee({ contractId, locationId, data });
}

function updateLocationFee({
  contractId,
  oldLocationId,
  newLocationId,
  data,
}: {
  contractId: string;
  oldLocationId: string;
  newLocationId: string;
  data: Pick<ContractLocationFee, "location" | "fees">;
}) {
  const oldData = getCachedLocationFee({
    contractId,
    locationId: oldLocationId,
  });
  if (!oldData) {
    return;
  }

  const newData = {
    ...oldData,
    ...data,
  };

  setCachedLocationFee({
    contractId,
    locationId: newLocationId,
    data: newData,
  });

  if (oldLocationId !== newLocationId) {
    setTimeout(
      () => removeLocationFee({ contractId, locationId: oldLocationId }),
      0
    );
  }
}

function removeLocationFee({
  locationId,
  contractId,
}: {
  locationId: string;
  contractId: string;
}) {
  return queryClient.removeQueries({
    queryKey: getQueryKeyForLocationFeeInContract({ contractId, locationId }),
  });
}

function getCachedLocationFee({
  contractId,
  locationId,
}: {
  contractId: string;
  locationId: string;
}) {
  return queryClient.getQueryData<ContractLocationFee>(
    getQueryKeyForLocationFeeInContract({ contractId, locationId })
  );
}

function setCachedLocationFee({
  contractId,
  locationId,
  data,
}: {
  contractId: string;
  locationId: string;
  data: ContractLocationFee;
}) {
  queryClient.setQueryData<ContractLocationFee>(
    getQueryKeyForLocationFeeInContract({ contractId, locationId }),
    data
  );
}

// Types
export type ContractLocationFee = {
  location: {
    id: string;
    name: string;
    friendlyId: string;
  };
  contract: {
    id: string;
    friendlyId: string;
  };
  fees: {
    electricFee: ElectricFeeWithPaymentInfo;
    rentFee: RentFeeWithPaymentInfo;
    insuranceFee: InsuranceFeeWithPaymentInfo;
  };
};

export type ElectricFeeWithPaymentInfo = WithPaymentInfo<ElectricFee>;

export type ElectricFee = ElectricFeePayPerUsage | ElectricFeeFixed;

export const ELECTRIC_FEE_TYPE = {
  FIXED: "FIXED",
  PAY_PER_USAGE: "PAY_PER_USAGE",
} as const;
type ElectricFeePayPerUsage = {
  type: typeof ELECTRIC_FEE_TYPE.PAY_PER_USAGE;
  value: string;
};
type ElectricFeeFixed = {
  type: typeof ELECTRIC_FEE_TYPE.FIXED;
  value: string;
};
export const ELECTRIC_FEE_TYPE_TRANSLATIONS = {
  [ELECTRIC_FEE_TYPE.FIXED]: "label_fixed_rate",
  [ELECTRIC_FEE_TYPE.PAY_PER_USAGE]: "label_pay_per_usage",
};

export const ELECTRIC_FEE_TYPE_MAP = {
  [ElectricityRateType.ActualUsage]: ELECTRIC_FEE_TYPE.PAY_PER_USAGE,
  [ElectricityRateType.FixedRate]: ELECTRIC_FEE_TYPE.FIXED,
};
export const ELECTRIC_FEE_TYPE_MAP_REVERSE = {
  [ELECTRIC_FEE_TYPE.PAY_PER_USAGE]: ElectricityRateType.ActualUsage,
  [ELECTRIC_FEE_TYPE.FIXED]: ElectricityRateType.FixedRate,
};

export function getElectricFeeTypeTranslation(type: ElectricFeeType) {
  return ELECTRIC_FEE_TYPE_TRANSLATIONS[type];
}
type ElectricFeeType = keyof typeof ELECTRIC_FEE_TYPE;
// Rent fees
export type RentFeeWithPaymentInfo = WithPaymentInfo<RentFee>;

export type RentFee = (RentFeePercentage | RentFeeFixed | RentFeeLadder) & {
  payWithCode: boolean;
};

export type RentFeePercentage = {
  type: typeof RENT_FEE_TYPE.PERCENTAGE;
  value: {
    minimumAmount: string;
    segments: Array<{
      lower: string;
      upper: string;
      percentage: string;
    }>;
  };
};
export type RentFeeFixed = {
  type: typeof RENT_FEE_TYPE.FIXED;
  value: string;
};
export type RentFeeLadder = {
  type: typeof RENT_FEE_TYPE.LADDER;
  value: Array<{
    from: moment.Moment;
    to: moment.Moment;
    rentalPrice: string;
  }>;
};

export const RENT_FEE_TYPE = {
  FIXED: "FIXED",
  LADDER: "LADDER",
  PERCENTAGE: "PERCENTAGE",
} as const;
export const RENT_FEE_TYPE_TRANSLATIONS = {
  [RENT_FEE_TYPE.FIXED]: "label_fixed_rate",
  [RENT_FEE_TYPE.LADDER]: "label_ladder_pricing",
  [RENT_FEE_TYPE.PERCENTAGE]: "label_percentage_by_project",
};
export const RENT_FEE_TYPE_MAP = {
  [ServiceFeeType.FixedFee]: RENT_FEE_TYPE.FIXED,
  [ServiceFeeType.PercentageFee]: RENT_FEE_TYPE.PERCENTAGE,
  [ServiceFeeType.Steps]: RENT_FEE_TYPE.LADDER,
};
export const RENT_FEE_TYPE_MAP_REVERSE = {
  [RENT_FEE_TYPE.FIXED]: ServiceFeeType.FixedFee,
  [RENT_FEE_TYPE.PERCENTAGE]: ServiceFeeType.PercentageFee,
  [RENT_FEE_TYPE.LADDER]: ServiceFeeType.Steps,
};

export function getRentFeeTypeTranslation(type: RentFeeType) {
  return RENT_FEE_TYPE_TRANSLATIONS[type];
}
export type RentFeeType = keyof typeof RENT_FEE_TYPE;

export type InsuranceFeeWithPaymentInfo = WithPaymentInfo<InsuranceFee>;

export type InsuranceFee = {
  value: string;
};
type WithPaymentInfo<T> = {
  fee: T;
  paymentInformation: PaymentInfo;
};

export type PaymentInfo = {
  accountNumber: string;
  nameOnAccount: string;
  typeOfAccount: AccountType;
  bankName: string;
  branchName: string;
  email: string;
};
export const ACCOUNT_TYPE = {
  SAVINGS: "SAVINGS",
  CURRENT: "CURRENT",
  FIXED_DEPOSIT: "FIXED_DEPOSIT",
} as const;
export type AccountType = keyof typeof ACCOUNT_TYPE;

export const ACCOUNT_TYPE_TRANSLATIONS = {
  [ACCOUNT_TYPE.SAVINGS]: "label_savings",
  [ACCOUNT_TYPE.CURRENT]: "label_current",
  [ACCOUNT_TYPE.FIXED_DEPOSIT]: "label_fixed_deposit",
};
export function getAccountTypeLabel(type: keyof typeof ACCOUNT_TYPE) {
  return ACCOUNT_TYPE_TRANSLATIONS[type];
}

export const ACCOUNT_TYPE_MAP = {
  [BankAccountType.Savings]: ACCOUNT_TYPE.SAVINGS,
  [BankAccountType.Current]: ACCOUNT_TYPE.CURRENT,
  [BankAccountType.FixedDeposit]: ACCOUNT_TYPE.FIXED_DEPOSIT,
};
export const ACCOUNT_TYPE_MAP_REVERSE = {
  [ACCOUNT_TYPE.SAVINGS]: BankAccountType.Savings,
  [ACCOUNT_TYPE.CURRENT]: BankAccountType.Current,
  [ACCOUNT_TYPE.FIXED_DEPOSIT]: BankAccountType.FixedDeposit,
};

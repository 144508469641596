import * as React from 'react';

interface ITrashInventoryV2Props {
  color?: string;
  className?: string;
}

function TrashInventoryV2({
  color = 'white',
  className,
}: ITrashInventoryV2Props): JSX.Element {
  return (
    <svg
      className={className}
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.05 23.5H16.95C17.9427 23.5 18.75 22.6778 18.75 21.6667V10.6667H20.1C20.5968 10.6667 21 10.256 21 9.75V7C21 5.98889 20.1927 5.16667 19.2 5.16667H15.6V3.33333C15.6 2.32222 14.7927 1.5 13.8 1.5H10.2C9.20728 1.5 8.4 2.32222 8.4 3.33333V5.16667H4.8C3.80728 5.16667 3 5.98889 3 7V9.75C3 10.256 3.4032 10.6667 3.9 10.6667H5.25V21.6667C5.25 22.6778 6.05728 23.5 7.05 23.5ZM16.95 21.6667H7.05V10.6667H16.95V21.6667ZM10.2 3.33333H13.8V5.16667H10.2V3.33333ZM4.79912 7H19.2V8.83333H4.79956L4.79912 7Z'
        fill={color}
      />
      <path
        d='M14 20.5C14.552 20.5 15 20.052 15 19.5V13.5C15 12.948 14.552 12.5 14 12.5C13.448 12.5 13 12.948 13 13.5V19.5C13 20.052 13.448 20.5 14 20.5Z'
        fill={color}
      />
      <path
        d='M10 20.5C10.552 20.5 11 20.052 11 19.5V13.5C11 12.948 10.552 12.5 10 12.5C9.448 12.5 9 12.948 9 13.5V19.5C9 20.052 9.448 20.5 10 20.5Z'
        fill={color}
      />
    </svg>
  );
}

export default TrashInventoryV2;

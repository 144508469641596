import * as React from "react";

import { useTranslation } from "react-i18next";

import Icon from "common/components/icon/Icon";
import TextField from "components/TextField";

import * as styles from "./DatePicker.module.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import { Moment } from "moment";

interface MyDatePickerProps<TDate = Moment>
  extends Omit<DatePickerProps<TDate>, "renderInput"> {
  required?: boolean;
  label: string;
}

/**
 *
 * ## Usage
 * ```
 * function ExampleDatePicker() {
 * const [value, setValue] = React.useState<moment.Moment | null>(null);
 * return (
 *  <DatePicker
 *    label="label_start_date"
 *    value={value}
 *    onChange={newValue => setValue(newValue)}
 *  />
 * )
 * }
 * ```
 */

function DatePicker({ label, required = false, ...props }: MyDatePickerProps) {
  const { t } = useTranslation();

  const MemoizedTextField = React.useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: TextFieldProps) => (
      <TextField required={required} {...props} />
    );
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MUIDatePicker
        label={t(label)}
        format="DD/MM/YYYY"
        slotProps={{
          popper: {
            className: styles.PopperProps,
          },
        }}
        slots={{
          openPickerIcon: function CalendarIcon() {
            return <Icon name="Calendar" color="primary" />;
          },
          textField: MemoizedTextField,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;

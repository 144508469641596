import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { get, keyBy } from 'lodash';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export function performFilter<T> (searchMap, collection): T {
  return collection?.filter(row => {
    let included = true;

    for (const key of Object.keys(searchMap)) {
      const filterObject = searchMap[key];

      const rowValue = get(row, filterObject.queryKey);
      if (!rowValue) {
        included = false;
        break;
      }

      if (filterObject.operator === 'or') {
        const filterValue = Array.isArray(filterObject.value) ? filterObject.value : [filterObject.value];
        if (!filterValue.includes(rowValue)) {
          included = false;
          break;
        }
      }

      if (filterObject.operator === 'incl') {
        if (!String(rowValue).toLowerCase().includes(String(filterObject.value).toLowerCase())) {
          included = false;
          break;
        }
      }

      if (filterObject.operator === 'lt') {
        if (Number(rowValue) >= Number(filterObject.value)) {
          included = false;
          break;
        }
      }

      if (filterObject.operator === 'gt') {
        if (Number(rowValue) <= Number(filterObject.value)) {
          included = false;
          break;
        }
      }

      if (filterObject.operator === 'lte') {
        if (Number(rowValue) > Number(filterObject.value)) {
          included = false;
          break;
        }
      }

      if (filterObject.operator === 'gte') {
        if (Number(rowValue) < Number(filterObject.value)) {
          included = false;
          break;
        }
      }
    }
    return included;
  });
}

export function parseSearchMap (searchMap: { [key: string]: any }) {
  const searchMapArray = Object.keys(searchMap)
    .filter(rawKey => rawKey !== 'groupBy')
    .map(rawKey => {
      let queryKey = rawKey;
      let operator = null;
      if (rawKey.includes('[')) {
        queryKey = rawKey.split('[')[0];
        operator = rawKey.split('[').pop().split(']')[0];
      }
      return {
        rawKey,
        queryKey,
        operator,
        value: searchMap[rawKey]
      };
    });

  return keyBy(searchMapArray, 'rawKey');
}

function useQueryFilter<T> (collection: T): T {
  const location = useLocation();

  const [ filteredData, setFilteredData ] = useState<T>(null);

  useDeepCompareEffectNoCheck(() => {
    const _searchMap = queryString.parse(location.search);
    const searchMap = parseSearchMap(_searchMap);
    const filtered = performFilter<T>(searchMap, collection);
    setFilteredData(filtered);
  }, [ location.search, collection ]);

  return filteredData;
}

export default useQueryFilter;

import * as React from "react";

import { Dialog, IconButton } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";
import Autocomplete from "components/Autocomplete";
import TextField from "components/TextField";
import Button from "components/Button";

import { useQueryClient } from "react-query";
import { useCreateTicketMutation, useGetMachinesQuery } from "gql/generated";

import { toast } from "react-toastify";
import i18n from "common/i18n";

import * as styles from "./CreateTicketModal.module.scss";

interface ICreateTicketModalProps {
  open: boolean;
  onClose: () => void;
}

export default function CreateTicketModal({
  open,
  onClose,
}: ICreateTicketModalProps): JSX.Element {
  const queryClient = useQueryClient();
  const { data } = useGetMachinesQuery();
  const { mutate, isLoading } = useCreateTicketMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        "GetTicketsByStatus",
        { status: "ready" },
      ]);
      queryClient.invalidateQueries([
        "GetTicketsByStatus",
        { status: "in_progress" },
      ]);
      queryClient.invalidateQueries([
        "GetTicketsByStatus",
        { status: "blocked" },
      ]);
      queryClient.invalidateQueries(["GetTicketStatusCounts"]);
      onClose();
      toast.success(i18n.t("toast_request_successful"));
    },
  });

  const [selectedMachineId, setSelectedMachineId] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [customerContactNumber, setCustomerContactNumber] =
    React.useState<string>("");

  const selectedMachine = data?.machines.find(
    (machine) => machine.id === selectedMachineId
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: styles.Paper,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={styles.IconButton}
      >
        <Icon name="Close" color="onSurfaceHigh" />
      </IconButton>
      <div>
        <Typography translate type="headline-6">
          label_create_ticket
        </Typography>
      </div>

      <div className={styles.MachineContainer}>
        <div className={styles.MachineAutocomplete}>
          <Autocomplete
            label="label_machine_id"
            id="machine_id"
            onChange={(newValue) => setSelectedMachineId(newValue)}
            value={selectedMachineId}
            options={
              data?.machines.map((machine) => ({
                label: machine.id,
                value: machine.id,
              })) ?? []
            }
            required
          />
        </div>

        <div className={styles.MachineNameContainer}>
          {selectedMachineId !== "" && (
            <>
              <div>
                <Typography type="caption" color="onSurfaceMedium" translate>
                  label_machine_name
                </Typography>
              </div>
              <div className={styles.MachineName}>
                <Typography type="subtitle-1">
                  {selectedMachine.name}
                </Typography>
              </div>
            </>
          )}
        </div>

        <div className={styles.ServiceZoneContainer}>
          {selectedMachineId !== "" && (
            <>
              <div>
                <Typography type="caption" color="onSurfaceMedium" translate>
                  label_service_zone
                </Typography>
              </div>
              <div className={styles.ServiceZone}>
                <Typography type="subtitle-1">
                  {selectedMachine.serviceZone}
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>

      <div>
        <TextField
          value={error}
          multiline
          rows={2}
          onChange={(e) => setError(e.target.value)}
          label="status_error"
          required
        />
      </div>

      <div className={styles.CustomerContact}>
        <TextField
          value={customerContactNumber}
          onChange={(e) => setCustomerContactNumber(e.target.value)}
          label="label_contact"
        />
      </div>

      <div className={styles.ButtonContainer}>
        <Button type="secondary" onClick={onClose} disabled={isLoading}>
          {"action_cancel"}
        </Button>
        <Button
          type="primary"
          disabled={error === "" || selectedMachineId === ""}
          onClick={() =>
            mutate({
              description: error,
              contact: customerContactNumber ?? null,
              machineId: selectedMachine.id,
            })
          }
          loading={isLoading}
        >
          {"action_create"}
        </Button>
      </div>
    </Dialog>
  );
}

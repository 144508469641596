import * as React from 'react';

interface IRefillSheetProps {
  color?: string,
  className?: string
}

function RefillSheet ({
  color = 'white',
  className
}: IRefillSheetProps): JSX.Element {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 18C0 19.1046 0.89543 20 2 20H15.4142L20 15.4142V2C20 0.89543 19.1046 0 18 0H2C0.89543 0 0 0.89543 0 2V18ZM2 2H18V13H15C13.8954 13 13 13.8954 13 15V18H2V2ZM15 15H17.5858L15 17.5858V15ZM5 13V15H11V13H5ZM5 11V9H15V11H5ZM5 5V7H15V5H5Z" fill={color}/>
    </svg>
  );
}

export default RefillSheet;

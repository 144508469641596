import { httpClient } from 'common/services/transportService';

import { APIDashboardResponse } from './interface';

export async function getDashboard (params: {
  lang: 'en' | 'th';
}): Promise<APIDashboardResponse> {
  const response = await httpClient.get<APIDashboardResponse>('/dashboard', { params });
  return response.data;
}

import * as React from "react";

interface IEyeNoProps {
  color?: string;
  className?: string;
}

function EyeNo({ color = "white", className }: IEyeNoProps): JSX.Element {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55734 3.60938L3.60933 7.55739L14.957 18.905C13.8999 19.7811 12.8537 20.7219 11.8191 21.7224C9.50421 23.9611 7.40994 26.351 5.56633 28.742C4.45385 30.1848 3.66399 31.3197 3.23154 31.9959L2.26953 33.5L3.23154 35.0042C3.66399 35.6804 4.45385 36.8153 5.56633 38.2581C7.40994 40.6491 9.50421 43.039 11.8191 45.2777C18.6046 51.8396 25.8856 55.8334 33.5 55.8334C38.3042 55.8334 42.9757 54.2435 47.4738 51.4219L59.4427 63.3907L63.3907 59.4427L7.55734 3.60938ZM43.3954 47.3435L39.1726 43.1206C37.5101 44.103 35.5709 44.6667 33.5 44.6667C27.3328 44.6667 22.3333 39.6672 22.3333 33.5C22.3333 31.4292 22.8971 29.4899 23.8794 27.8275L18.9267 22.8748C17.8452 23.7469 16.7689 24.7027 15.7004 25.736C13.595 27.7721 11.6758 29.9622 9.98788 32.1513C9.62332 32.6241 9.28645 33.0752 8.97847 33.5C9.28645 33.9249 9.62332 34.376 9.98788 34.8488C11.6758 37.0379 13.595 39.228 15.7004 41.2641C21.5447 46.9157 27.6233 50.25 33.5 50.25C36.7611 50.25 40.0845 49.2233 43.3954 47.3435ZM28.1058 32.0538C27.9824 32.5151 27.9167 32.9999 27.9167 33.5C27.9167 36.5836 30.4164 39.0834 33.5 39.0834C34.0002 39.0834 34.4849 39.0176 34.9462 38.8943L28.1058 32.0538ZM56.1071 44.3623L52.159 40.4142C53.9294 38.6246 55.5567 36.7363 57.0121 34.8488C57.3767 34.376 57.7136 33.9249 58.0215 33.5C57.7136 33.0752 57.3767 32.6241 57.0121 32.1513C55.3242 29.9622 53.405 27.7721 51.2996 25.736C45.4553 20.0843 39.3767 16.75 33.5 16.75C32.0419 16.75 30.5714 16.9553 29.0951 17.3503L24.6788 12.934C27.561 11.7847 30.5047 11.1667 33.5 11.1667C41.1144 11.1667 48.3954 15.1605 55.1809 21.7224C57.4958 23.9611 59.5901 26.351 61.4337 28.742C62.5462 30.1848 63.336 31.3197 63.7685 31.9959L64.7305 33.5L63.7685 35.0042C63.336 35.6804 62.5462 36.8153 61.4337 38.2581C59.8392 40.326 58.0572 42.3932 56.1071 44.3623Z"
        fill={color}
      />
    </svg>
  );
}

export default EyeNo;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CardTitle--1qNnDXooI-{display:flex;height:20px;align-items:center}.CardTitle--1qNnDXooI- .IconContainer--2X8pXRikX8{align-self:stretch;flex:0 0 20px;position:relative}.CardTitle--1qNnDXooI- .IconContainer--2X8pXRikX8 .Icon--3clDaYd_jF{position:absolute;left:50%;transform:translateX(-50%);height:100%}.CardTitle--1qNnDXooI- .TextContainer--6rQVlCDLZ6{margin-left:10px;line-height:1}", ""]);
// Exports
exports.locals = {
	"CardTitle": "CardTitle--1qNnDXooI-",
	"IconContainer": "IconContainer--2X8pXRikX8",
	"Icon": "Icon--3clDaYd_jF",
	"TextContainer": "TextContainer--6rQVlCDLZ6"
};
module.exports = exports;

import * as React from "react";
import {
  IPureFilterBarProps,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";

export function FilteBar(props: IPureFilterBarProps) {
  return (
    <PureFilterBar
      {...props}
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        borderTop: "none",
        borderBottom: "none",
      }}
    />
  );
}

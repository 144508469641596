import { ColumnFiltersState } from "components/tableV2";
import { MachineMonitorFilter, MachineStatusEnum } from "gql/generated";

export function mapFilterToGetMachineLocationFilter(columnFilters: ColumnFiltersState): MachineMonitorFilter {
  const locationFilter: MachineMonitorFilter = {};

  columnFilters.forEach((filter) => {
    const { id, value } = filter;
    switch (id) {
      case 'node_machineId':
        locationFilter.machineId = (value as string).trim();
        break;
      case 'node_status':
        locationFilter.machineStatus = [value] as MachineStatusEnum[];
        break;
      case 'node_locationName':
        locationFilter.locationName = (value as string).trim();
        break;
      case 'node_serviceZoneName':
        locationFilter.serviceZoneName = (value as string).split(",")?.map((item) => item.trim());
        break;
      case 'node_refillZoneName':
        locationFilter.refillZoneName = (value as string).split(",")?.map((item) => item.trim());
        break;
      default:
        break;
    }
  });

  return locationFilter;
}

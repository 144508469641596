/**
 * Immutably updates the `orderBy` and `sort` field of the `queryObject` argument based on the `orderBy` argument.
 * @param queryObject - Current query object
 * @param orderBy - Data field to order by
 * @returns a queryObject with `orderBy` and `sort` modified
 * @remarks
 * 1. If `queryObject.orderBy` is the same as the `orderBy` argument, toggle the sort field - changing from 'ASC' to 'DESC' or vice versa
 * 2. else set `queryObject.orderBy` to the new `orderBy` argment and set the sort to `ASC`
 * 3. If a page field is present in the `queryObject`, reset it to '1'
 *
 * Examples:
 * // Different orderBy - Change orderBy, set sort to 'ASC', set page to '1'
 * toggleQueryObjectOrderBy({orderBy: 'time', sort: 'DESC', page: '14', ...}, 'machineId')
 * => {orderBy: 'machineId', sort: 'ASC', page: '1', ...}
 *
 * // Same orderBy - Toggle sort & Reset page
 * toggleQueryObjectOrderBy({orderBy: 'time', sort: 'DESC', page: '14', ...}, 'time')
 * => {orderBy: 'machineId', sort: 'ASC', page: '1', ...}
 *
 * // Same orderBy without page property - Toggle sort
 * toggleQueryObjectOrderBy({orderBy: 'status', sort: 'DESC', ...}, 'status')
 * => {orderBy: 'status', sort: 'ASC', ...}
 * ```
 */

export function toggleQueryObjectOrderBy<
  T extends { sort: string; orderBy: string; page?: string }
  > (queryObject: T, orderBy: string): T {
  const queryObjectCopy = { ...queryObject };

  // If same orderBy, toggle sort otherwise change orderBy and set sort to 'ASC'
  if (queryObjectCopy.orderBy === orderBy) {
    queryObjectCopy.sort = queryObjectCopy.sort === 'ASC' ? 'DESC' : 'ASC';
  } else {
    queryObjectCopy.orderBy = orderBy;
    queryObjectCopy.sort = 'ASC';
  }

  // If there's a page parameter, always reset page number
  if ('page' in queryObjectCopy) {
    queryObjectCopy.page = '1';
  }
  return queryObjectCopy;
}

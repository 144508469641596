// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonCell--1K_JhcTGXX{width:100%}.ButtonCell--1K_JhcTGXX>span{text-transform:capitalize !important}.Tooltip--jDc-mDH12R{z-index:0;width:100%}.Tooltip--jDc-mDH12R .TooltipContent--14zLJTklYG{min-width:195px;padding:8px}", ""]);
// Exports
exports.locals = {
	"ButtonCell": "ButtonCell--1K_JhcTGXX",
	"Tooltip": "Tooltip--jDc-mDH12R",
	"TooltipContent": "TooltipContent--14zLJTklYG"
};
module.exports = exports;

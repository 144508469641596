import * as React from 'react';
import { useState } from 'react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker } from 'react-dates';

import withSuspense from 'common/hoc/withSuspense';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

import * as styles from './DatePicker.module.scss';

interface IDatePickerProps {
  id: string,
  label?: string,
  date: moment.Moment,
  placeholder: string,
  onDateChange?: (date: moment.Moment) => void,
  className?: string,
  maxPastDays?: number,
  maxFutureDays?: number,
}

function DatePicker ({
  id,
  date,
  label,
  placeholder,
  onDateChange,
  maxPastDays = 365 * 10,
  maxFutureDays = 365 * 10,
  className
}: IDatePickerProps): JSX.Element {
  const { t } = useTranslation();

  const [ focusedInput, setFocusedInput ] = useState<boolean>(null);

  return (
    <div
      className={[
        styles.DatePickerWrapper,
        className
      ].join(' ')}
    >
      <Typography
        translationKey={date && label}
        type='caption'
        className={[
          styles.label,
          date && label ? styles.active : ''
        ].join(' ')}
      />

      <div className={styles.field}>
        <SingleDatePicker
          id={id}
          placeholder={t(placeholder)}
          date={date}
          onDateChange={onDateChange}
          focused={focusedInput}
          onFocusChange={({ focused }) => setFocusedInput(focused)}
          numberOfMonths={1}
          keepOpenOnDateSelect={false}
          isOutsideRange={day =>
            day.isBefore(moment().set({ hour: 0, minute: 0, second: 0 }).subtract(maxPastDays, 'days')) ||
            day.isAfter(moment().set({ hour: 23, minute: 59, second: 59 }).add(maxFutureDays, 'days'))
          }
          noBorder
          hideKeyboardShortcutsPanel
        />

        <Icon
          className={styles.icon}
          name='Calendar'
          color='primary'
        />
      </div>
    </div>
  );
}

export default withSuspense(DatePicker);

import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { IUsers } from "userandauth/interfacesUser";
import { getAllUsers } from "userandauth/servicesUser";
import { userKeys } from "./userKeys";

function useGetUsers({ enabled = true } = {}) {
  return useQuery<IUsers[], AxiosError>(userKeys.all(), () => getAllUsers(), {
    staleTime: 5 * 60 * 1000,
    retry: 0,
    enabled,
  });
}

export { useGetUsers };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--2lMGFMKf2R{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--2lMGFMKf2R .ButtonsContainer--3UaUFE9G50{width:100%;display:flex;gap:25px}.TableHeader--2lMGFMKf2R .ButtonsContainer--3UaUFE9G50>*:last-child{margin-left:auto}@media screen and (max-width: 768px){.TableHeader--2lMGFMKf2R .ButtonsContainer--3UaUFE9G50{flex-direction:column}.TableHeader--2lMGFMKf2R .ButtonsContainer--3UaUFE9G50>*:last-child{margin-left:revert}}.TableContainer--3mfodMBG_e{overflow:auto}.TableContainer--3mfodMBG_e>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--2lMGFMKf2R",
	"ButtonsContainer": "ButtonsContainer--3UaUFE9G50",
	"TableContainer": "TableContainer--3mfodMBG_e"
};
module.exports = exports;

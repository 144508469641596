import * as React from 'react';

interface IAddWarehouseProps {
  color?: string;
  className?: string;
}

function AddWarehouse ({
  color = 'white',
  className
}: IAddWarehouseProps): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M9.25 18H1.49C0.668 18 0 17.331 0 16.51V9C0 4.037 4.038 0 9 0C13.962 0 18 4.037 18 9V9.25C18 9.664 17.664 10 17.25 10C16.836 10 16.5 9.664 16.5 9.25V9C16.5 4.864 13.136 1.5 9 1.5C4.864 1.5 1.5 4.864 1.5 9V16.51L9.25 16.5C9.664 16.5 10 16.836 10 17.25C10 17.664 9.664 18 9.25 18Z'
          fill={color}
        />
        <path
          d='M9.24976 18.0002H4.74976C4.33576 18.0002 3.99976 17.6642 3.99976 17.2502V8.75018C3.99976 8.33618 4.33576 8.00018 4.74976 8.00018H13.2498C13.6638 8.00018 13.9998 8.33618 13.9998 8.75018V10.2502C13.9998 10.6642 13.6638 11.0002 13.2498 11.0002C12.8358 11.0002 12.4998 10.6642 12.4998 10.2502V9.50018H5.49976V16.5002H9.24976C9.66376 16.5002 9.99975 16.8362 9.99975 17.2502C9.99975 17.6642 9.66376 18.0002 9.24976 18.0002Z'
          fill={color}
        />
        <path
          d='M11.2497 12H4.74975C4.33576 12 3.99976 11.664 3.99976 11.25C3.99976 10.836 4.33576 10.5 4.74975 10.5H11.2497C11.6637 10.5 11.9997 10.836 11.9997 11.25C11.9997 11.664 11.6637 12 11.2497 12Z'
          fill={color}
        />
        <path
          d='M9.24975 15H4.74976C4.33576 15 3.99976 14.664 3.99976 14.25C3.99976 13.836 4.33576 13.5 4.74976 13.5H9.24975C9.66375 13.5 9.99975 13.836 9.99975 14.25C9.99975 14.664 9.66375 15 9.24975 15Z'
          fill={color}
        />
        <path
          d='M17.5002 24.0002C13.9162 24.0002 11.0002 21.0842 11.0002 17.5002C11.0002 13.9162 13.9162 11.0002 17.5002 11.0002C21.0842 11.0002 24.0002 13.9162 24.0002 17.5002C24.0002 21.0842 21.0842 24.0002 17.5002 24.0002ZM17.5002 12.5002C14.7432 12.5002 12.5002 14.7432 12.5002 17.5002C12.5002 20.2572 14.7432 22.5002 17.5002 22.5002C20.2572 22.5002 22.5002 20.2572 22.5002 17.5002C22.5002 14.7432 20.2572 12.5002 17.5002 12.5002Z'
          fill={color}
        />
        <path
          d='M17.4998 21.0002C17.0858 21.0002 16.7498 20.6642 16.7498 20.2502V14.7502C16.7498 14.3362 17.0858 14.0002 17.4998 14.0002C17.9138 14.0002 18.2498 14.3362 18.2498 14.7502V20.2502C18.2498 20.6642 17.9138 21.0002 17.4998 21.0002Z'
          fill={color}
        />
        <path
          d='M20.2502 18.2498H14.7502C14.3362 18.2498 14.0002 17.9138 14.0002 17.4998C14.0002 17.0858 14.3362 16.7498 14.7502 16.7498H20.2502C20.6642 16.7498 21.0002 17.0858 21.0002 17.4998C21.0002 17.9138 20.6642 18.2498 20.2502 18.2498Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddWarehouse;

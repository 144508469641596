import { Modal } from "@mui/material";
import Button from "components/Button/Button";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";
import * as React from "react";
import { useImmer } from "use-immer";
import { toasti18n } from "utils/toast";
import { useAddNewWarehouse } from "./use-get-warehouse";
import TextButton from "common/components/textbutton/TextButton";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { getProvince } from "containers/location/utils/getProvince";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";

interface Form {
  name: string;
  province: string;
  address: string;
  lat: string;
  long: string;
}

function useCanAddWarehouse() {
  const canAdd = useIsAdmin() || useIsAdminContract();
  return canAdd;
}

export function AddNewWarehouseModalButton() {
  const [isOpen, setIsOpen] = React.useState(false);
  const canAdd = useCanAddWarehouse();

  if (!canAdd) {
    return null;
  }

  return (
    <>
      <TextButton
        icon="AddWarehouse"
        translationKey="label_add_warehouse"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <AddNewWarehouseModal onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

function AddNewWarehouseModal({ onClose }: { onClose: () => void }) {
  const [form, setForm] = useImmer<Form>({
    address: "",
    lat: "",
    long: "",
    name: "",
    province: "",
  });

  const { isLoading: isAddingWarehouse, mutate: addNewWarehouse } =
    useAddNewWarehouse({
      onSuccess: () => {
        onClose();
        toasti18n.success();
      },
    });

  function handleSubmit() {
    addNewWarehouse({
      address: form.address,
      lat: Number(form.lat),
      long: Number(form.long),
      name: form.name,
      province: form.province,
    });
  }

  const isFormDisabled =
    form.name === "" ||
    form.province === "" ||
    form.address === "" ||
    isNaN(Number(form.lat)) ||
    form.lat === "" ||
    isNaN(Number(form.long)) ||
    form.long === "" ||
    !form.long;

  return (
    <Modal open={true} onClose={isAddingWarehouse ? () => {} : onClose}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          pointerEvents: "none",
          boxSizing: "border-box",
          padding: "68px 0px",
          flexDirection: "column",
          justifyContent: "start",
          overflow: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "3px",
            width: "100%",
            maxWidth: "720px",
            pointerEvents: "auto",
            flex: "1 1 auto",
            maxHeight: "679px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "24px 33px",
              boxSizing: "border-box",
            }}
          >
            <Typography type="headline-6" translate>
              label_add_warehouse
            </Typography>

            <div
              style={{
                marginTop: "25px",
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <TextField
                label="label_name"
                value={form.name}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.name = e.target.value;
                  })
                }
              />
              <TextField
                label="label_address"
                value={form.address}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.address = e.target.value;
                  })
                }
              />
              <Autocomplete
                value={form.province}
                onChange={(newValue) =>
                  setForm((draft) => {
                    draft.province = newValue;
                  })
                }
                id="province"
                label="label_province"
                options={Object.values(getProvince()).map((type) => ({
                  value: type,
                  label: `label_${type.toLowerCase()}`,
                }))}
                disableClearable={true}
                translateLabels={true}
                required={true}
              />

              <TextField
                label="label_latitude"
                value={form.lat}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.lat = e.target.value;
                  })
                }
              />
              <TextField
                label="label_longitude"
                value={form.long}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.long = e.target.value;
                  })
                }
              />
            </div>
            <div style={{ flex: "1 1 0" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "32px",
              }}
            >
              <Button
                type="secondary"
                onClick={onClose}
                disabled={isAddingWarehouse}
              >
                action_cancel
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isAddingWarehouse}
                disabled={isFormDisabled}
              >
                action_create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

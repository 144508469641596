// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Fallback--16xT0jgyAV{background-color:#ffd8d4;width:100%;height:100%;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"Fallback": "Fallback--16xT0jgyAV"
};
module.exports = exports;

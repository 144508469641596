// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Portal--kJgp5DYzzF{display:flex;flex-direction:row;position:relative;min-height:100dvh}.Portal--kJgp5DYzzF .content--1v5tl-Jvsq{display:flex;flex-direction:column;flex:1;padding:20px 35px;min-width:0;flex:1 1 0px}@media screen and (max-width: 768px){.Portal--kJgp5DYzzF .content--1v5tl-Jvsq{margin-left:0;padding:20px;margin-bottom:60px}}.Portal--kJgp5DYzzF .contentWithNav--2IJpMjmehL{margin-left:60px;margin-top:60px}@media screen and (max-width: 768px){.Portal--kJgp5DYzzF .contentWithNav--2IJpMjmehL{margin-left:0}}", ""]);
// Exports
exports.locals = {
	"Portal": "Portal--kJgp5DYzzF",
	"content": "content--1v5tl-Jvsq",
	"contentWithNav": "contentWithNav--2IJpMjmehL"
};
module.exports = exports;

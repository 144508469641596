import {
  IMachineData,
  IMachineInventory,
  IMachineInventoryData,
  IMachineInventoryTemplate,
  IMachineStatusData,
  IMachineTransactionData,
  IPaginationMetaData,
  IMachineDataWithExtraFields,
  APIMonitorMachine,
  PatchInventoryMaintenance,
  MachineyMaintenanceSlot,
  MachineyMaintenanceComponent,
  IMachineUser,
  IMachineModuleStatus,
  IMachineSlots,
  PatchInventoryMaintenancePeriod,
  IDownloadMachineHealth,
  IGetHealthReportCSV,
} from 'machines/interfaces';
import { IFilter } from 'common/interfaces';
import { IUsers } from 'userandauth/interfacesUser';

import { httpClient } from 'common/services/transportService';

export async function createMachine(
  machineData: Partial<IMachineData>
): Promise<IMachineData> {
  const response = await httpClient.post<IMachineData>(
    '/machines',
    machineData
  );
  return response.data;
}

export async function deleteMachine(machineId: string): Promise<string> {
  const response = await httpClient.delete<string>(`/machines/${machineId}`);
  return response.data;
}

export async function patchMachine(
  machineId: string,
  machineData: Partial<IMachineData>
): Promise<IMachineData> {
  const response = await httpClient.patch<IMachineData>(
    `/machines/${machineId}`,
    machineData
  );
  return response.data;
}

export async function getMachine(machineId: string): Promise<IMachineData> {
  const response = await httpClient.get<IMachineData>(`/machines/${machineId}`);
  return response.data;
}

export async function getMachines(): Promise<IMachineData[]> {
  const response = await httpClient.get<IMachineData[]>('/machines');
  return response.data;
}

export async function getMachinesWithExtraFields(): Promise<
  IMachineDataWithExtraFields[]
> {
  const response = await httpClient.get<IMachineDataWithExtraFields[]>(
    '/machines',
    { params: { extraFields: true } }
  );
  return response.data;
}

export async function getMachineInventoryTemplates(
  type: string
): Promise<IMachineInventoryTemplate[]> {
  const response = await httpClient.get<IMachineInventoryTemplate[]>(
    `/machineinventoriestemplate/${type}`
  );
  return response.data;
}

export async function getMonitorMachines(): Promise<APIMonitorMachine[]> {
  const response = await httpClient.get<APIMonitorMachine[]>(
    '/machines/monitor'
  );
  return response.data;
}

export async function refillMachineInventories(
  machineId: string
): Promise<IMachineInventoryData[]> {
  const response = await httpClient.put<IMachineInventoryData[]>(
    `/machineinventories/${machineId}/refill`
  );
  return response.data;
}

export async function createMachineInventory(
  machineId: string,
  machineInventoryData: Array<Partial<IMachineInventory>>
) {
  const response = await httpClient.post<IMachineInventory[]>(
    `/machineinventories/${machineId}`,
    machineInventoryData
  );
  return response.data;
}

export async function getMachineInventory(
  machineId: string
): Promise<IMachineInventoryData[]> {
  const response = await httpClient.get<IMachineInventoryData[]>(
    `/machineinventories/${machineId}`
  );
  return response.data;
}

export async function patchMachineInventories(
  machineId: string,
  machineInventories: Partial<IMachineInventoryData>[]
) {
  const response = await httpClient.patch<IMachineInventoryData[]>(
    `/machineinventories/${machineId}`,
    machineInventories
  );
  return response.data;
}

export async function deleteMachineInventories(
  machineId: string,
  machineInventoryId: string
) {
  const response = await httpClient.delete<string>(
    `/machineinventories/${machineId}/${machineInventoryId}`
  );
  return response.data;
}

export async function cancelSwapInventory (machineInventoryId: string) {
  const response = await httpClient.post<string>(
    `/swapOrder/cancel`,
    { machineInventoryId }
  );

  return response.data;
}

export async function getMachineStatus(
  machineId: string,
  filters?: IFilter
): Promise<IMachineStatusData[]> {
  const response = await httpClient.get<IMachineStatusData[]>(
    `/status/${machineId}`,
    { params: filters }
  );
  return response.data;
}

export async function getMachineTransactions(
  machineId: string,
  filters?: IFilter | object
): Promise<{ results: IMachineTransactionData[]; meta: IPaginationMetaData }> {
  const response = await httpClient.get<{
    results: IMachineTransactionData[];
    meta: IPaginationMetaData;
  }>(`/sales/${machineId}`, { params: filters });
  return response.data;
}

export async function getAllTransactions(
  filters?: IFilter | object
): Promise<{ results: IMachineTransactionData[]; meta: IPaginationMetaData }> {
  const response = await httpClient.get<{
    results: IMachineTransactionData[];
    meta: IPaginationMetaData;
  }>('/sales', { params: filters });
  return response.data;
}

export async function getMachineForWarehouse(
  warehouseId: string
): Promise<IMachineData> {
  const response = await httpClient.get<IMachineData>('/machines', {
    params: { warehouseId: warehouseId },
  });
  return response.data;
}
export async function getMachineTransactionsCSV(
  machineId: string,
  filters?: IFilter | object
): Promise<string> {
  const response = await httpClient.get<string>(`/sales/${machineId}`, {
    params: { ...filters, download: 'true' },
  });
  return response.data;
}

export async function getAllTransactionsCSV(
  filters?: IFilter | object
): Promise<string> {
  const response = await httpClient.get<string>('/sales', {
    params: { ...filters, download: 'true' },
  });
  return response.data;
}

export async function getAllTransactionsServerTimestampCSV(
  filters?: IFilter | object
): Promise<string> {
  const response = await httpClient.get<string>('/sales/serverTimestamp', {
    params: { ...filters, download: 'true' },
  });
  return response.data;
}

export async function getMachineLimitsXLSX(): Promise<string> {
  const response = await httpClient.get<string>('/machineinventories/limit', {
    responseType: 'arraybuffer',
  });
  return response.data;
}

export async function postMachineLimitXLSX(XLSXData: string) {
  const response = await httpClient.post<{ file: string }>(
    '/machineinventories/limit',
    { limitFile: XLSXData }
  );
  return response.data;
}

export async function getMachineHealthXLSX({
  machineId,
  to,
  from,
}: IDownloadMachineHealth): Promise<string> {
  const response = await httpClient.get<string>(
    `/status/downloadHealthStatus/${machineId}`,
    { responseType: 'arraybuffer', params: { from, to } }
  );
  return response.data;
}

export async function getSelectedMachineReportCSV(
  params: string
): Promise<string> {
  const response = await httpClient.get<string>(
    `/machineinventories/downloadInventoryReport?${params}`,
    {
      responseType: 'arraybuffer',
    }
  );
  return response.data;
}

export async function postMachineMaintenance({
  machineId,
  type,
  body,
}: PatchInventoryMaintenance) {
  const result = await httpClient.post(
    `/machineMaintenance/${machineId}/${type}`,
    body
  );
  return result.data;
}
export async function patchMachineMaintenancePeriod({
  machineId,
  type,
  body,
}: PatchInventoryMaintenancePeriod) {
  const result = await httpClient.patch(
    `/machinemaintenance/${machineId}/${type}/updateMaintenancePeriod`,
    body
  );
  return result.data;
}

export async function getMachineMaintenanceSlot(
  machineId: string
): Promise<Array<MachineyMaintenanceSlot>> {
  const result = await httpClient.get(`/machineMaintenance/${machineId}/slot`);
  return result.data;
}

export async function getMachineMaintenanceComponent(
  machineId: string
): Promise<Array<MachineyMaintenanceComponent>> {
  const result = await httpClient.get(
    `/machineMaintenance/${machineId}/component`
  );
  return result.data;
}

export async function getMachineUsers(machineId: string): Promise<IUsers[]> {
  const response = await httpClient.get<IUsers[]>(
    `/machines/${machineId}/users`
  );
  return response.data;
}

export async function inviteMachineUser(
  userDetails: IMachineUser
): Promise<string> {
  const response = await httpClient.post<string>(
    '/machinePermission',
    userDetails
  );
  return response.data;
}

export async function deleteMachineUser(
  userDetails: IMachineUser
): Promise<string> {
  const response = await httpClient.delete<string>(
    `/machinePermission/${userDetails.machineId}/${userDetails.userId}`
  );
  return response.data;
}

export async function getMachineModuleStatus(
  machineId: string
): Promise<IMachineModuleStatus> {
  const response = await httpClient.get<IMachineModuleStatus>(
    `machinemodules/${machineId}`
  );
  return response.data;
}

export async function getMachinesSlots(
  machineId: Array<string>
): Promise<Array<IMachineSlots>> {
  const response = await httpClient.post<Array<IMachineSlots>>(
    `/machines/slots`,
    { machineId }
  );
  return response.data;
}
export async function getHealthReportCSV({
  from,
  to,
  machineId,
}: IGetHealthReportCSV): Promise<string> {
  const response = await httpClient.post<string>(
    `/status/downloadMachinesHealthReport?from=${from.valueOf()}&to=${to.valueOf()}`,
    {
      machineId: machineId,
    }
  );
  return response.data;
}
export async function getMachineTicketsXLSX(): Promise<ArrayBuffer> {
  const response = await httpClient.get<ArrayBuffer>('/ticketsDownload', {
    responseType: 'arraybuffer',
    timeout: 1000 * 90,
  });
  return response.data;
}

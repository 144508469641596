import * as React from 'react';
import { useState, useEffect } from 'react';

import Typography from 'common/components/typography/Typography';
import Button from 'common/components/button/Button';
import Input from 'common/components/input/Input';
import UploadImageModal from 'containers/settings/Modal/UploadModal/UploadImageModal';

import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';

import * as styles from './Organisation.module.scss';
import { useIsAdmin, useIsAdminContract } from 'utils/user-role';
import { usePatchOrganisation } from 'userandauth/hooks/usePatchOrganisation';

function Organisation(): JSX.Element {
  const { data: userInfo } = useGetCurrentUser();
  const [lineAlarmStatusToken, setLineAlarmStatusToken] = useState(
    userInfo.orgLineAlarmStatusToken
  );
  const [lineInventoryToken, setLineInventoryToken] = useState(
    userInfo.orgLineInventoryToken
  );
  const [modalOpen, setModalOpen] = useState<IModalOpen>('none');

  const { mutate: patchOrganisation } = usePatchOrganisation();

  useEffect(() => {
    setLineAlarmStatusToken(userInfo.orgLineAlarmStatusToken);
    setLineInventoryToken(userInfo.orgLineInventoryToken);
  }, [userInfo.orgLineInventoryToken, userInfo.orgLineAlarmStatusToken]);

  return (
    <div className={[styles.organisation, styles.test].join(' ')}>
      {modalOpen === 'uploadPicture' && (
        <UploadImageModal
          onClose={() => setModalOpen('none')}
          uploadAvatarFor="organisation"
          userId={userInfo.userId}
        />
      )}
      <div className={styles.header}>
        <Typography type="headline-5" translationKey="label_organisation" />
      </div>
      <div className={styles.organisationPicture}>
        {userInfo?.orgAvatarUrl === '' ||
        userInfo?.orgAvatarUrl === null ||
        userInfo?.orgAvatarUrl === undefined ? (
          <div className={styles.imagePlaceholder}></div>
        ) : (
          <div>
            <img
              className={styles.organisationAvatar}
              src={userInfo?.orgAvatarUrl}
              alt=""
            />
          </div>
        )}
        <div className={styles.photoInfo}>
          <div className={styles.organisationPhotoLabel}>
            <Typography
              translationKey="label_organisation_photo"
              type="caption"
            />
          </div>
          <Button
            className={styles.uploadButton}
            type="secondary"
            translationKey="action_upload"
            disabled={!(useIsAdmin() || useIsAdminContract())}
            onClick={() => setModalOpen('uploadPicture')}
          />
        </div>
      </div>
      <Input
        value={userInfo.orgName}
        type="text"
        label="label_name"
        disable={true}
        className={styles.organisationName}
        renderStyle="noLineBox"
      />
      <Input
        value={lineAlarmStatusToken}
        type="text"
        label="label_line_alarm_status_token"
        className={styles.organisationName}
        onChange={(value) => setLineAlarmStatusToken(value)}
        disable={!(useIsAdmin() || useIsAdminContract())}
      />
      <Input
        value={lineInventoryToken}
        type="text"
        label="label_line_inventory_token"
        className={styles.organisationName}
        onChange={(value) => setLineInventoryToken(value)}
        disable={!(useIsAdmin() || useIsAdminContract())}
      />
      <div className={styles.buttonContainer}>
        <Button
          translationKey="action_save"
          type="primary"
          disabled={
            userInfo.orgLineInventoryToken === lineInventoryToken &&
            userInfo.orgLineAlarmStatusToken === lineAlarmStatusToken
          }
          onClick={() => {
            patchOrganisation({
              orgId: userInfo.orgId,
              newOrgData: {
                lineInventoryToken: lineInventoryToken || null,
                lineAlarmStatusToken: lineAlarmStatusToken || null,
              },
            });
          }}
          className={styles.save}
        />
      </div>
    </div>
  );
}

export default Organisation;

type IModalOpen = 'none' | 'uploadPicture';

import { IconAndTextCellProps } from "common/components/table/cells/IconAndTextCell/IconAndTextCell";
import { LinkCellProps } from "common/components/table/cells/LinkCell/LinkCell";
import { GetTicketsByStatusQuery } from "gql/generated";

export interface ITableRow {
  misc: {
    ticketIdBackend: string;
    machineId: string;
    priority: string;
  };
  table: {
    ticketIdFrontend: string;
    machineId: LinkCellProps;
    machineName: string;
    technician: string;
    organisation: string;
    priority: IconAndTextCellProps | null;
    created: number | null;
    updated: number | null;
    issues: number;
    serviceZone: string;
  };
}

type DistributiveKeyOf<T> = T extends any ? keyof T : never;

type ExtractNestedKeys<T> = DistributiveKeyOf<
  {
    [Property in keyof T]: {
      [NestedProperty in keyof T[Property] as `${Property &
        string}.${NestedProperty & string}`]: undefined;
    };
  }[keyof T]
>;

export type ITableRowKeys = ExtractNestedKeys<ITableRow>;

export function mapDataToTable(
  data: GetTicketsByStatusQuery
): Array<ITableRow> {
  return data?.tickets
    .map((dataPoint) => ({
      misc: {
        ticketIdBackend: dataPoint.id,
        priority: dataPoint.priority,
        machineId: dataPoint.machine.id,
      },
      table: {
        ticketIdFrontend: dataPoint.ticketId,
        machineId: {
          to: `/machines/detail/${dataPoint.machine.id}/inventory`,
          translationKey: dataPoint.machine.id,
        },
        machineName: dataPoint.machine.name,
        technician: dataPoint.assignedTo,
        organisation: dataPoint.machine.organisation.name ?? "",
        priority: computePriorityField(dataPoint.priority),
        created: dataPoint.createdAt ? Number(dataPoint.createdAt) : null,
        updated: dataPoint.updatedAt ? Number(dataPoint.updatedAt) : null,
        issues: dataPoint.issuesCount,
        serviceZone: dataPoint.machine.serviceZone ?? "",
      },
    }))
    .sort((a, b) => a.misc.machineId.localeCompare(b.misc.machineId));
}

function computePriorityField(priority: string | null): IconAndTextCellProps {
  switch (priority) {
    case "urgent":
      return {
        iconProps: { name: "PriorityUrgent" },
        translationKey: "label_urgent",
      };
    case "highest":
      return {
        iconProps: { name: "PriorityVeryHigh" },
        translationKey: "label_highest",
      };
    case "high":
      return {
        iconProps: { name: "PriorityHigh" },
        translationKey: "label_high",
      };
    case "medium":
      return {
        iconProps: { name: "PriorityMedium" },
        translationKey: "label_medium",
      };
    case "low":
      return {
        iconProps: { name: "PriorityLow" },
        translationKey: "label_low",
      };
    case "lowest":
      return {
        iconProps: { name: "PriorityLowest" },
        translationKey: "label_lowest",
      };
    default:
      return null;
  }
}

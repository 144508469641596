import * as React from "react";

interface IAlertTriangle {
  color?: string;
  className?: string;
}

function AlertTriangle({
  color = "white",
  className,
  ...rest
}: IAlertTriangle): JSX.Element {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2467 17.9473L17.3657 4.47482C16.5745 3.1032 15.0998 2.25187 13.505 2.25C11.91 2.24814 10.4348 3.09595 9.63377 4.47488L1.74984 17.9523C0.93233 19.3269 0.913835 21.0494 1.70568 22.4541C2.49819 23.86 3.9821 24.7349 5.59618 24.7481L21.3888 24.7481C23.0196 24.7323 24.5007 23.86 25.2931 22.4554C26.0846 21.0524 26.0669 19.3337 25.2467 17.9473ZM3.68783 19.0954L11.5776 5.60802C11.9772 4.92023 12.71 4.49907 13.5024 4.5C14.2947 4.50093 15.0272 4.92382 15.4202 5.605L23.3074 19.0882C23.7208 19.787 23.7297 20.6474 23.3334 21.3499C22.9366 22.0533 22.1947 22.4902 21.3778 22.4982L5.60533 22.4981C4.8059 22.4916 4.06261 22.0533 3.66573 21.3492C3.26927 20.6459 3.27853 19.7836 3.68783 19.0954ZM13.5004 20.2481C14.1219 20.2481 14.6257 19.7445 14.6257 19.1231C14.6257 18.5018 14.1219 17.9981 13.5004 17.9981C12.8788 17.9981 12.375 18.5018 12.375 19.1231C12.375 19.7445 12.8788 20.2481 13.5004 20.2481ZM14.6291 8.99814H12.3783V16.8731H14.6291V8.99814Z"
        fill={color}
      />
    </svg>
  );
}

export default AlertTriangle;
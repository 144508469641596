import * as React from 'react';

interface IVendingMachineProps {
  color?: string,
  className?: string
}

function VendingMachine ({
  color = 'white',
  className
}: IVendingMachineProps): JSX.Element {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M17.5976 8.97191L15.7643 0.721909C15.6727 0.297928 15.2945 0 14.8706 0H3.87055C3.62986 0 3.38934 0.103058 3.21746 0.263519L0.467464 3.01352C-0.0939836 3.58638 0.318416 4.58339 1.12055 4.58339H2.1174L1.14355 8.97191C1.13197 9.02914 1.12055 9.09796 1.12055 9.16661V21.0834C1.12055 21.5874 1.53312 22 2.03717 22H13.0372C13.1748 22 13.3237 21.9656 13.4497 21.9084L14.8706 21.1979L16.2914 21.9084C16.8872 22.1949 17.6091 21.7593 17.6206 21.0834V9.16661C17.6206 9.09796 17.6091 9.02914 17.5976 8.97191ZM9.31332 1.83339H12.6592L11.7424 2.75H9.14144C9.21009 2.45207 9.2675 2.14273 9.31332 1.83339ZM7.45694 1.83339C7.3997 2.14273 7.33105 2.45207 7.25082 2.75H4.39759L4.60388 1.83339H7.45694ZM6.62056 4.58339V7.33339H8.45394V4.78951C8.47677 4.73227 8.51118 4.66362 8.54559 4.58339H12.1206C12.6362 4.58339 12.9685 4.125 13.2893 3.79266L12.3038 8.25H3.18305L3.99661 4.58339H6.62056ZM2.95394 10.0834H12.1206V20.1666H2.95394V10.0834ZM15.7872 19.6052L15.2831 19.3531C15.0194 19.227 14.7217 19.227 14.458 19.3531L13.9539 19.6052V9.26984L14.8706 5.14484L15.7872 9.26984V19.6052Z" fill={color}/>
    </svg>
  );
}

export default VendingMachine;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".indicator--MQ7OC8kzDm{width:8px;height:8px}.indicator--MQ7OC8kzDm.circle--1FWeinqcy9{border-radius:50%}", ""]);
// Exports
exports.locals = {
	"indicator": "indicator--MQ7OC8kzDm",
	"circle": "circle--1FWeinqcy9"
};
module.exports = exports;

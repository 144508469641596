import * as React from 'react';

// Styles
import * as styles from './OptionGroup.module.scss';

interface IOptionGroupProps {
  isOpen: boolean;
  className?: string;
  children: JSX.Element[];
}

function OptionGroup ({
  className,
  isOpen,
  children
}: IOptionGroupProps): JSX.Element {
  return (
    <>
      {isOpen && (
        <div className={[ className, styles.OptionGroup ].join(' ')}>
          {children}
        </div>
      )}
    </>
  );
}

export { OptionGroup };

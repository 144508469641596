// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoPermissionComponent--x314hkkd0o{display:flex;height:100%;flex-direction:column;justify-content:center;align-items:center}.NoPermissionComponent--x314hkkd0o .icon--3d2RopIz24{width:170px;height:170px}.NoPermissionComponent--x314hkkd0o .information--PPNit0iomX{display:flex}.NoPermissionComponent--x314hkkd0o .title--11TMC_O-4f{margin-top:20px}.NoPermissionComponent--x314hkkd0o .button--1_aGWDkJTL{width:200px;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"NoPermissionComponent": "NoPermissionComponent--x314hkkd0o",
	"icon": "icon--3d2RopIz24",
	"information": "information--PPNit0iomX",
	"title": "title--11TMC_O-4f",
	"button": "button--1_aGWDkJTL"
};
module.exports = exports;

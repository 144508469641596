import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { IPatchUser } from "userandauth/interfacesUser";
import { userKeys } from './userKeys';
import { patchUser } from "userandauth/servicesUser";
import { toasti18n } from "utils/toast";
import i18n from "common/i18n";


function usePatchUserDetails () {
    const queryClient = useQueryClient();

    return useMutation<string, AxiosError, IUsePatchUser>(
      ({newUserData, userId}) => patchUser(newUserData, userId),
      {
        onSuccess: (_, {userId}) => {
          toasti18n.success(i18n.t('toast_user_patch_success'));
          queryClient.invalidateQueries(userKeys.user(userId));
          queryClient.invalidateQueries(userKeys.all());
          queryClient.invalidateQueries();
        }
      }
    );
  }
  
  export { usePatchUserDetails };

  interface IUsePatchUser {
    userId: string,
    newUserData: IPatchUser
  }
import * as React from 'react';
import { useGetDashboard } from 'dashboard/hooks/useGetDashboard';

import { DashboardCard } from './organisms/DashboardCard';

import { mapDashboardDataToCards } from './utils/mapDashboardDataToCards';

import * as styles from './Dashboard.module.scss';
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
  useIsSuperViewer,
} from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
import { DrinkFailCard } from './organisms/DrinkFailCard';
import {
  useGetDrinkFailureOverviewQuery,
  useGetDashboardMaScoreGradeQuery,
  useGetDailyMachineSalesQuery,
  useGetDailyDrinkSalesQuery,
  useGetDailyDrinkFailureQuery,
  useGetDailyPaymentTypeSalesQuery,
  useGetDailyDrinkCategorySalesQuery,
  useGetDailyDrinkTypeSalesQuery,
} from 'gql/generated';
import { DailyGradeCard } from './organisms/DailyGradeCard';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import { BestSellingMachineCards } from './organisms/BestSellingMachinesCard';
import { BestSellingDrinkMenu } from './organisms/BestSellingDrinkMenu';
import { MostDrinkFails } from './organisms/MostDrinkFails';
import { BestSellingPaymentMethod } from './organisms/BestSellingPaymentMethod';
import { BestSellingDrinkCategory } from './organisms/BestSellingDrinkCategory';
import { BestSellingDrinkType } from './organisms/BestSellingDrinkType';

function Dashboard(): JSX.Element {
  const canViewContent =
    useIsAdmin() ||
    useIsAdminContract() ||
    useIsClerk() ||
    useIsSuperViewer() ||
    useIsOperator();

  if (!canViewContent) {
    return <NoPermissionComponent />;
  }
  const { t } = useTranslation();

  const dashboardResult = useGetDashboard();
  const drinkFailureOverviewResult = useGetDrinkFailureOverviewQuery(
    { failureType: null },
    {
      useErrorBoundary: false,
    }
  );
  const getDailyGradeResult = useGetDashboardMaScoreGradeQuery(undefined, {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  });
  const getDailyMachineSalesResult = useGetDailyMachineSalesQuery(undefined, {
    useErrorBoundary: false,
  });
  const getDailyDrinkSalesResult = useGetDailyDrinkSalesQuery(undefined, {
    useErrorBoundary: false,
  });
  const getDailyDrinkFailureQuery = useGetDailyDrinkFailureQuery(undefined, {
    useErrorBoundary: false,
  });
  const getDailyPaymentTypeSalesQuery = useGetDailyPaymentTypeSalesQuery(
    undefined,
    {
      useErrorBoundary: false,
    }
  );
  const getBestSellingDrinkCategoryQuery = useGetDailyDrinkCategorySalesQuery(
    undefined,
    {
      useErrorBoundary: false,
    }
  );
  const getBestSellingDrinkTypeQuery = useGetDailyDrinkTypeSalesQuery(
    undefined,
    {
      useErrorBoundary: false,
    }
  );

  const isLoading =
    dashboardResult.isLoading ||
    drinkFailureOverviewResult.isLoading ||
    getDailyGradeResult.isLoading ||
    getDailyMachineSalesResult.isLoading ||
    getDailyDrinkSalesResult.isLoading ||
    getDailyDrinkFailureQuery.isLoading ||
    getDailyPaymentTypeSalesQuery.isLoading ||
    getBestSellingDrinkCategoryQuery.isLoading ||
    getBestSellingDrinkTypeQuery.isLoading;

  const dashboardCards = mapDashboardDataToCards(dashboardResult.data ?? []);

  const cardsArray = dashboardCards.map((card, index) => (
    <DashboardCard key={index} {...card} className={styles.Card} />
  ));
  const salesByLocationType = cardsArray.pop();

  const reorderedCardsArray = [
    ...cardsArray.slice(0, 2),
    <DailyGradeCard key={'dailyGradeCard'} />,
    ...cardsArray.slice(2, 3),
    <DrinkFailCard key={'drinkFailCard'} />,
    ...cardsArray.slice(3),
    <MostDrinkFails key="mostDrinkFail" />,
    <BestSellingMachineCards key={'bestSellingMachineCard'} />,
    <BestSellingDrinkMenu key={'bestSellingDrinkMenu'} />,
    <BestSellingDrinkCategory key={'bestSellingDrinkCategory'} />,
    <BestSellingPaymentMethod key="best-selling-payment-method" />,
    <BestSellingDrinkType key={'best-selling-drink-type'} />,
    salesByLocationType,
  ];

  return (
    <div className={styles.Dashboard}>
      <div className={styles.Header}>
        <Typography type="headline-5">{t('workspace_dashboard')}</Typography>
      </div>
      {isLoading && (
        <div>
          <Typography type="subtitle-1">{t('label_loading')}</Typography>
        </div>
      )}
      {!isLoading && (
        <div className={styles.CardContainer}>{reorderedCardsArray}</div>
      )}
    </div>
  );
}

export default Dashboard;

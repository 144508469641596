import Typography from "components/Typography/Typography";
import * as React from "react";
import { Skeleton } from "@mui/material";
import * as moment from "moment";
import { useGetLotsForProduct } from "./hooks/use-get-lots-for-product";
import Icon from "common/components/icon/Icon";
import { useInventoryIdFromURL } from "./hooks/use-inventory-id-from-url";
import * as colors from "common/styles/colors.scss";

const COLUMNS = {
  WAREHOUSE: "warehouse",
  QUANTITY_PER_PACKAGE: "quantityPerPackage",
  UOM: "uom",
  PACKAGE_TYPE: "packageType",
  CURRENT_STOCK: "currentStock",
  ENOUGH_FOR: "enoughFor",
  USAGE_IN_PAST_MONTH: "usageInPastMonth",
  EXPIRATION: "expiration",
} as const;

type Columns = keyof typeof COLUMNS;

const columnStyles: Record<Columns, React.CSSProperties> = {
  WAREHOUSE: {
    flex: "2 0 150px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
  },
  QUANTITY_PER_PACKAGE: {
    flex: "1 0 76px",
    minWidth: "76px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  UOM: {
    flex: "1 0 49px",
    minWidth: "49px",
    display: "flex",
    alignItems: "center",
  },
  PACKAGE_TYPE: {
    flex: "1 0 68px",
    minWidth: "68px",
    display: "flex",
    alignItems: "center",
  },
  CURRENT_STOCK: {
    flex: "1 0 98px",
    minWidth: "98px",
    display: "flex",
    alignItems: "center",
  },
  ENOUGH_FOR: {
    flex: "1 0 83px",
    minWidth: "83px",
    display: "flex",
    alignItems: "center",
  },
  USAGE_IN_PAST_MONTH: {
    flex: "1 0 83px",
    minWidth: "83px",
    display: "flex",
    alignItems: "center",
  },
  EXPIRATION: {
    flex: "1 0 92px",
    minWidth: "92px",
    display: "flex",
    alignItems: "center",
  },
};

export function InventoryIdViewTable() {
  const inventoryId = useInventoryIdFromURL();
  const { data, isLoading } = useGetLotsForProduct(inventoryId);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          minWidth: "min-content",
          flex: "1 1 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid rgba(0,0,0,0.12)",
            backgroundColor: "white",
            position: "sticky",
            top: 0,
            left: 0,
            padding: "0 40px",
            minHeight: "45px",
          }}
        >
          <div style={columnStyles.WAREHOUSE}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_warehouse
            </Typography>
          </div>
          <div style={columnStyles.QUANTITY_PER_PACKAGE}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_package_value
            </Typography>
          </div>
          <div style={columnStyles.UOM}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_unit
            </Typography>
          </div>
          <div style={columnStyles.PACKAGE_TYPE}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_pack
            </Typography>
          </div>
          <div style={columnStyles.CURRENT_STOCK}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_stock
            </Typography>
          </div>
          <div style={columnStyles.ENOUGH_FOR}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_enough_for
            </Typography>
          </div>
          <div style={columnStyles.USAGE_IN_PAST_MONTH}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_30_days_usage
            </Typography>
          </div>
          <div style={columnStyles.EXPIRATION}>
            <Typography
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_expired
            </Typography>
          </div>
        </div>

        {isLoading &&
          Array.from({ length: 7 }).map((_, index) => (
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid rgba(0,0,0,0.12)",
                padding: "0 40px",
                minHeight: "45px",
              }}
              key={index}
            >
              <div style={columnStyles.WAREHOUSE}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.QUANTITY_PER_PACKAGE}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.UOM}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.PACKAGE_TYPE}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.CURRENT_STOCK}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.ENOUGH_FOR}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.USAGE_IN_PAST_MONTH}>
                <TableSkeleton />
              </div>
              <div style={columnStyles.EXPIRATION}>
                <TableSkeleton />
              </div>
            </div>
          ))}

        {!isLoading && data.length === 0 && (
          <div
            style={{ display: "grid", placeContent: "center", flex: "1 1 0px" }}
          >
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Icon name="Critical" color="disabled" />
              <Typography type="body-2" translate color="disabled">
                label_no_data
              </Typography>
            </div>
          </div>
        )}

        {!isLoading
          ? data.map((warehouse) => (
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                  padding: "0 40px",
                  minHeight: "45px",
                }}
                key={warehouse.warehouseId}
              >
                <div style={columnStyles.WAREHOUSE}>
                  <Typography type="body-2">
                    {warehouse.warehouseName ?? "-"}
                  </Typography>
                </div>
                <div style={columnStyles.QUANTITY_PER_PACKAGE}>
                  <Typography type="body-2">
                    {warehouse.packageValue ?? "-"}
                  </Typography>
                </div>
                <div style={columnStyles.UOM}>
                  <Typography type="body-2">
                    {warehouse.packageUnit ?? "-"}
                  </Typography>
                </div>
                <div style={columnStyles.PACKAGE_TYPE}>
                  <Typography type="body-2">
                    {warehouse.packageType ?? "-"}
                  </Typography>
                </div>
                <div style={columnStyles.CURRENT_STOCK}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "8px",
                        height: "8px",
                        backgroundColor: (() => {
                          const totalQuantity = warehouse.totalStock;
                          if (totalQuantity <= 50) {
                            return colors.error;
                          }
                          if (totalQuantity >= 100) {
                            return colors.success;
                          }
                          return colors.warning;
                        })(),
                      }}
                    ></div>
                    <Typography type="body-2">
                      {warehouse.totalStock}
                    </Typography>
                  </div>
                </div>
                <div style={columnStyles.ENOUGH_FOR}>
                  <Typography type="body-2" translate>
                    {
                      // warehouse.enoughFor ? parseISODuration(warehouse.enoughFor) :
                      "-"
                    }
                  </Typography>
                </div>
                <div style={columnStyles.USAGE_IN_PAST_MONTH}>
                  <Typography type="body-2">
                    {"-"}
                    {/* {warehouse.monthlyUsage} */}
                  </Typography>
                </div>
                <div style={columnStyles.EXPIRATION}>
                  <Typography type="body-2">
                    {moment(Number(warehouse.expiryDate)).format("DD/MM/YYYY")}
                  </Typography>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

function TableSkeleton() {
  return (
    <div
      style={{
        width: "100%",
        padding: "3px",
      }}
    >
      <Skeleton width={"100%"} />
    </div>
  );
}

import * as React from 'react';

interface IPauseProps {
  color?: string,
  className?: string
}

function Pause ({
  color = 'white',
  className
}: IPauseProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H9C10.1046 22 11 21.1046 11 20V4C11 2.89543 10.1046 2 9 2ZM17 2H15C13.8954 2 13 2.89543 13 4V20C13 21.1046 13.8954 22 15 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2ZM7 4V20H9V4H7ZM15 20V4H17V20H15Z' fill={color} />
    </svg>
  );
}

export default Pause;

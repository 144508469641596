import * as React from "react";
import { SortDir } from "../hooks/useSortQueryParam";
import Icon from "common/components/icon/Icon";

export function Chevron({
  onClick,
  sortDir,
}: {
  onClick?: () => void;
  sortDir?: SortDir | undefined;
}) {
  return (
    <button
      className="flex items-center justify-center p-0 border-0 bg-transparent w-5 h-5 cursor-pointer shrink-0"
      onClick={onClick}
    >
      {!sortDir ? (
        <Icon name="TableHeaderSortChevron" />
      ) : sortDir === "asc" ? (
        <Icon name="TableHeaderSortChevronAsc" />
      ) : (
        <Icon name="TableHeaderSortChevronAsc" className="rotate-180" />
      )}
    </button>
  );
}

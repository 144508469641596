import * as React from "react";
import { useTranslation } from "react-i18next";

function PageTitle({ children }: { children: string; }) {
  return (
    <h1
      style={{
        fontSize: "30px",
        fontWeight: 500,
        fontFamily: "Kanit",
        margin: 0,
        lineHeight: 0.99,
      }}
    >
      {children}
    </h1>
  );
}

function ScreenWrapper({ children }: { children: React.ReactNode; }) {
  return (
    <main
      style={{
        height: "100%",
        backgroundColor: "white",
        overflow: "auto",
        padding: "24px 40px",
        boxSizing: "border-box",
      }}
    >
      {children}
    </main>
  );
}

function MaxWidthContainer({ children }: { children: React.ReactNode; }) {
  return <div style={{ maxWidth: "900px" }}>{children}</div>;
}

export function Spacer() {
  return <div style={{ height: "20px" }} />;
}

function LoadingIndicator() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        fontFamily: "Kanit",
      }}
    >
      {t("label_loading")}
    </div>
  );
}
export const ContractWorkSpace = {
  MaxWidthContainer,
  Spacer,
  LoadingIndicator,
  ScreenWrapper,
  PageTitle,
};

import * as React from 'react';

import Icon from 'common/components/icon/Icon';

import * as styles from './CardTitle.module.scss';

interface CardTitleProps {
  icon: string;
  iconColor?: string;
  title: string;
  className?: string;
}

function CardTitle({
  icon,
  iconColor = 'onSurfaceDisabled',
  title,
  className = '',
}: CardTitleProps): JSX.Element {
  return (
    <div className={[styles.CardTitle, className].join(' ')}>
      <div className={styles.IconContainer}>
        <Icon className={styles.Icon} name={icon} color={iconColor} />
      </div>

      <div className="text-subtitle1 ml-[10px] leading-4">{title}</div>
    </div>
  );
}

export { CardTitle };

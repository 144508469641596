import { Modal } from "@mui/material";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";
import * as React from "react";
import { useImmer } from "use-immer";
import { InventoryImage } from "../../components/InventoryImage";
import { toasti18n } from "utils/toast";
import {
  Product,
  useAddNewInventory,
  useEditInventory,
} from "./hooks/use-get-product-catalog";
import { getMutatedFields } from "../../utils/getMutatedFields";
import {
  Category,
  CATEGORY,
  getCategoryTranslation,
} from "../Warehouses/constants";

interface Form {
  inventoryCode: string;
  sku: string;
  name: string;
  description: string;
  packaging: string;
  packageValue: string;
  unitOfMeasurement: UnitOfMeasurement | "";
  category: Category | "";
  brand: string;
  base64Img: string | null;
}

export type EditInventoryModalIntialValues = Form & { productId: string };

const UNIT_OF_MEASUREMENT = {
  GRAM: "g",
  MILLILITER: "ml",
  PIECES: "pcs",
  STRAW: "straw",
} as const;

export type UnitOfMeasurement =
  typeof UNIT_OF_MEASUREMENT[keyof typeof UNIT_OF_MEASUREMENT];

/**
 * Modal to create or edit an inventory
 * @param onClose - Callback to close the modal
 * @param initalValues - If this is defined, then the modal will be in edit mode
 */
export function CreateOrEditInventoryModal({
  onClose,
  initialValues,
}: {
  onClose: () => void;
  initialValues?: EditInventoryModalIntialValues;
}) {
  const editMode = initialValues !== undefined;

  const [form, setForm] = useImmer<Form>({
    inventoryCode: initialValues?.inventoryCode ?? "",
    sku: initialValues?.sku ?? "",
    name: initialValues?.name ?? "",
    description: initialValues?.description ?? "",
    category: initialValues?.category ?? "",
    packaging: initialValues?.packaging ?? "",
    packageValue: initialValues?.packageValue ?? "",
    unitOfMeasurement: initialValues?.unitOfMeasurement ?? "",
    brand: initialValues?.brand ?? "",
    base64Img: initialValues?.base64Img ?? null,
  });

  const { isLoading: isAddingNewInventory, mutate: addNewInventory } =
    useAddNewInventory({
      onSuccess: () => {
        onClose();
        toasti18n.success();
      },
    });
  const { isLoading: isEditingInventory, mutate: editInventory } =
    useEditInventory({
      onSuccess: () => {
        onClose();
        toasti18n.success();
      },
    });

  function handleSubmit() {
    const formToBeSubmitted: Product = {
      inventoryCode: form.inventoryCode,
      UPC: form.sku,
      name: form.name,
      image: form.base64Img,
      description: form.description,
      package: Number(form.packageValue),
      packageType: form.packaging,
      uom: form.unitOfMeasurement,
      machineCategory: form.category,
      manufacturer: form.brand,
    };

    if (editMode) {
      const initialFormValuesThatWasSubmitted: Product = {
        inventoryCode: initialValues.inventoryCode,
        UPC: initialValues.sku,
        name: initialValues.name,
        image: initialValues.base64Img,
        description: initialValues.description,
        package: Number(initialValues.packageValue),
        packageType: initialValues.packaging,
        uom: initialValues.unitOfMeasurement,
        machineCategory: initialValues.category,
        manufacturer: initialValues.brand,
      };
      editInventory({
        id: initialValues.productId,
        ...getMutatedFields(
          initialFormValuesThatWasSubmitted,
          formToBeSubmitted
        ),
      });
    } else {
      addNewInventory(formToBeSubmitted);
    }
  }

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [isReadingImage, setIsReadingImage] = React.useState(false);
  function handleFileSelection(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      setIsReadingImage(true);
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setForm((draft) => {
          draft.base64Img = base64;
        });
        setIsReadingImage(false);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <Modal
      open={true}
      onClose={isAddingNewInventory || isEditingInventory ? () => {} : onClose}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          boxSizing: "border-box",
          padding: "68px 0px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "3px",
            width: "100%",
            maxWidth: "715px",
            pointerEvents: "auto",
            maxHeight: "100%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div style={{ padding: "32px 33px 8px 33px" }}>
              <Typography type="headline-6" translate>
                {editMode
                  ? ["action_edit_inventory", ` ${initialValues.sku}`]
                  : "label_add_new_inventory"}
              </Typography>
            </div>

            <div
              style={{
                padding: "0px 33px 8px 33px",
                borderBottom: "1px solid rgba(0,0,0,0.12)",
              }}
            >
              <div style={{ display: "flex", gap: "23px" }}>
                <InventoryImage
                  width="96px"
                  height="96px"
                  imgUrl={form.base64Img}
                  isLoading={isReadingImage}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <Typography type="caption" translate>
                    label_inventory_photo
                  </Typography>
                  <Button
                    type="secondary"
                    onClick={() => {
                      fileInputRef?.current.click();
                    }}
                  >
                    action_upload
                  </Button>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileSelection}
                    ref={fileInputRef}
                    accept=".jpg,.jpeg,.png"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "33px",
                overflow: "auto",
                padding: "8px 33px",
              }}
            >
              <div style={{ display: "flex", gap: "33px" }}>
                <div style={{ flex: "1 1 0px" }}>
                  {editMode ? (
                    <div>
                      <Typography
                        type="caption"
                        color="onSurfaceMedium"
                        translate
                      >
                        label_sku
                      </Typography>
                      <br />
                      <Typography
                        type="body-2"
                        color="onSurfaceMedium"
                        translate
                      >
                        {initialValues.sku}
                      </Typography>
                    </div>
                  ) : (
                    <TextField
                      label="label_sku"
                      value={form.sku}
                      required
                      onChange={(e) =>
                        setForm((draft) => {
                          draft.sku = e.target.value;
                        })
                      }
                    />
                  )}
                </div>
                <div style={{ flex: "1 1 0px" }}>
                  <TextField
                    label="label_inventory_code"
                    value={form.inventoryCode}
                    required
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryCode = e.target.value;
                      })
                    }
                  />
                </div>
              </div>
              <TextField
                label="label_name"
                value={form.name}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.name = e.target.value;
                  })
                }
              />
              <TextField
                label="label_description"
                value={form.description}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.description = e.target.value;
                  })
                }
              />
              <Select
                required
                label="label_category"
                id="type"
                value={form.category}
                onChange={(e) =>
                  setForm((draft) => {
                    draft.category = e.target.value;
                  })
                }
                options={[
                  {
                    value: CATEGORY.SPIRAL,
                    label: getCategoryTranslation("SPIRAL"),
                  },
                  {
                    value: CATEGORY.COFFEE,
                    label: getCategoryTranslation("COFFEE"),
                  },
                  {
                    value: CATEGORY.BEVERAGE,
                    label: getCategoryTranslation("BEVERAGE"),
                  },
                ]}
              />
              <TextField
                label="label_packaging"
                value={form.packaging}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.packaging = e.target.value;
                  })
                }
              />
              <div style={{ display: "flex", gap: "33px" }}>
                <TextField
                  label="label_package_value"
                  value={form.packageValue}
                  required
                  onChange={(e) =>
                    setForm((draft) => {
                      draft.packageValue = e.target.value;
                    })
                  }
                />
                <Select
                  required
                  label="label_unit_of_measurement"
                  id="type"
                  value={form.unitOfMeasurement}
                  onChange={(e) =>
                    setForm((draft) => {
                      draft.unitOfMeasurement = e.target.value;
                    })
                  }
                  options={[
                    { value: UNIT_OF_MEASUREMENT.GRAM, label: "g" },
                    { value: UNIT_OF_MEASUREMENT.MILLILITER, label: "ml" },
                    { value: UNIT_OF_MEASUREMENT.PIECES, label: "pcs" },
                    { value: UNIT_OF_MEASUREMENT.STRAW, label: "straw" },
                  ]}
                />
              </div>

              <TextField
                label="label_brand"
                value={form.brand}
                required
                onChange={(e) =>
                  setForm((draft) => {
                    draft.brand = e.target.value;
                  })
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid rgba(0,0,0,0.12)",
                padding: "23px 33px",
              }}
            >
              <Button
                type="secondary"
                onClick={onClose}
                disabled={isAddingNewInventory || isEditingInventory}
              >
                action_cancel
              </Button>
              <div style={{ display: "flex", gap: "33px" }}>
                {editMode && (
                  <Button
                    type="status"
                    disabled={
                      // isAddingNewInventory || isEditingInventory
                      true
                    }
                  >
                    label_delete_inventory
                  </Button>
                )}

                <Button
                  type="primary"
                  onClick={handleSubmit}
                  loading={isAddingNewInventory || isEditingInventory}
                >
                  {editMode ? "action_save" : "action_create"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

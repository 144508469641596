import { Modal } from "@mui/material";
import Button from "components/Button/Button";
import Typography from "components/Typography/Typography";
import * as React from "react";
import { useImmer } from "use-immer";
import { toasti18n } from "utils/toast";

import TextButton from "common/components/textbutton/TextButton";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { downloadCSVFromNetworkRequest } from "utils/csv";
import { downloadXLSXFile } from "utils/xlsx";
import * as moment from "moment";
import { httpClient } from "common/services/transportService";
import { useGetWarehouse } from "./use-get-warehouse";
import CheckboxWithLabel from "components/CheckboxWithLabel/CheckboxWithLabel";
import DatePicker from "components/DatePicker/DatePicker";

export function DownloadHistoryModalButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <TextButton
        icon="DownloadDocument"
        translationKey="label_download_history"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DownloadHistoryModal onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

export function DownloadHistoryModal({ onClose }: { onClose: () => void }) {
  const [form, setForm] = useImmer<DownloadRefillOrdersForPlansByStateCSVArg>({
    groupByProduct: true,
    inventoryHistoryOptions: {
      adjustStock: true,
      backFromRefillMachine: true,
      damageGoods: true,
      startDate: moment().startOf("day").valueOf().toString(),
      endDate: moment().endOf("day").valueOf().toString(),
      expired: true,
      fromRepackage: true,
      generalWithdraw: true,
      moveToWarehouse: true,
      purchase: true,
      refillMachine: true,
      sellToOperator: true,
      stockAdjustment: true,
      warehouseId: "ALL",
      withdrawForRepackage: true,
    },
  });

  const useGetWarehouseResult = useGetWarehouse();

  const { downloadRefillOrdersForPlansByStateCSV, isLoading } =
    useDownloadInventoryHistory();

  function handleSubmit() {
    downloadRefillOrdersForPlansByStateCSV({
      groupByProduct: form.groupByProduct,
      inventoryHistoryOptions: {
        ...form.inventoryHistoryOptions,
        ...(form.inventoryHistoryOptions.warehouseId === "ALL"
          ? { warehouseId: undefined }
          : {}),
      },
    });
  }

  const isFormDisabled = false;

  return (
    <Modal open={true} onClose={isLoading ? () => {} : onClose}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          pointerEvents: "none",
          boxSizing: "border-box",
          padding: "50px 0px",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "3px",
            width: "100%",
            maxWidth: "720px",
            pointerEvents: "auto",
            flex: "1 1 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "24px 33px",
              boxSizing: "border-box",
            }}
          >
            <Typography type="headline-6" translate>
              label_download_history
            </Typography>

            <div
              style={{
                marginTop: "20px",
                gap: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Autocomplete
                label="label_warehouse"
                id="warehouse"
                value={form.inventoryHistoryOptions.warehouseId}
                onChange={(newValue) => {
                  setForm((draft) => {
                    draft.inventoryHistoryOptions.warehouseId = newValue;
                  });
                }}
                translateLabels={true}
                options={
                  useGetWarehouseResult.isLoading
                    ? []
                    : [
                        { label: "label_all_warehouses", value: "ALL" },
                        ...useGetWarehouseResult.data.map((warehouse) => {
                          return {
                            label: warehouse.name,
                            value: warehouse.warehouseId,
                          };
                        }),
                      ]
                }
              />

              <DatePicker
                label="label_start_date"
                disableFuture={true}
                required
                value={
                  form.inventoryHistoryOptions.startDate === ""
                    ? null
                    : moment(Number(form.inventoryHistoryOptions.startDate))
                }
                onChange={(e) => {
                  if (e === null || (moment.isMoment(e) && !e.isValid())) {
                    setForm((draft) => {
                      draft.inventoryHistoryOptions.startDate = "";
                    });
                  } else if (moment.isMoment(e)) {
                    setForm((draft) => {
                      draft.inventoryHistoryOptions.startDate = String(
                        e.startOf("day").valueOf()
                      );
                    });
                  }
                }}
              />

              <DatePicker
                label="label_end_date"
                disableFuture={true}
                required
                value={
                  form.inventoryHistoryOptions.endDate === ""
                    ? null
                    : moment(Number(form.inventoryHistoryOptions.endDate))
                }
                onChange={(e) => {
                  if (e === null || (moment.isMoment(e) && !e.isValid())) {
                    setForm((draft) => {
                      draft.inventoryHistoryOptions.endDate = "";
                    });
                  } else if (moment.isMoment(e)) {
                    setForm((draft) => {
                      draft.inventoryHistoryOptions.endDate = String(
                        e.endOf("day").valueOf()
                      );
                    });
                  }
                }}
              />

              <div>
                <div>
                  <Typography type="subtitle-1" translate>
                    label_withdraw_history
                  </Typography>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  <CheckboxWithLabel
                    label="label_refill_machine"
                    checked={form.inventoryHistoryOptions.refillMachine}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.refillMachine =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_expired"
                    checked={form.inventoryHistoryOptions.expired}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.expired =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_general_withdraw"
                    checked={form.inventoryHistoryOptions.generalWithdraw}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.generalWithdraw =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_damage_goods"
                    checked={form.inventoryHistoryOptions.damageGoods}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.damageGoods =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_withdraw_package"
                    checked={form.inventoryHistoryOptions.withdrawForRepackage}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.withdrawForRepackage =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_move_to_warehouse"
                    checked={form.inventoryHistoryOptions.moveToWarehouse}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.moveToWarehouse =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_adjust_stock"
                    checked={form.inventoryHistoryOptions.adjustStock}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.adjustStock =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_sell_to_operator"
                    checked={form.inventoryHistoryOptions.sellToOperator}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.sellToOperator =
                          e.target.checked;
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Typography type="subtitle-1" translate>
                    label_incoming_inventory
                  </Typography>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  <CheckboxWithLabel
                    label="label_purchase"
                    checked={form.inventoryHistoryOptions.purchase}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.purchase =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_from_repackage"
                    checked={form.inventoryHistoryOptions.fromRepackage}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.fromRepackage =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_back_from_refill_machine"
                    checked={form.inventoryHistoryOptions.backFromRefillMachine}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.backFromRefillMachine =
                          e.target.checked;
                      })
                    }
                  />

                  <CheckboxWithLabel
                    label="label_stock_adjustment"
                    checked={form.inventoryHistoryOptions.stockAdjustment}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.inventoryHistoryOptions.stockAdjustment =
                          e.target.checked;
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <Typography type="subtitle-1" translate>
                    label_configuration
                  </Typography>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                    marginTop: "8px",
                  }}
                >
                  <CheckboxWithLabel
                    checked={form.groupByProduct}
                    onChange={(e) =>
                      setForm((draft) => {
                        draft.groupByProduct = e.target.checked;
                      })
                    }
                    label="label_group_by_product"
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: "1 1 0" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "32px",
              }}
            >
              <Button type="secondary" onClick={onClose} disabled={isLoading}>
                action_cancel
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isFormDisabled}
              >
                action_download
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

type DownloadRefillOrdersForPlansByStateCSVArg = {
  groupByProduct: boolean;
  inventoryHistoryOptions: InventoryHistoryOptions;
};

export function useDownloadInventoryHistory() {
  const [isLoading, setIsLoading] = React.useState(false);

  async function downloadRefillOrdersForPlansByStateCSV(
    option: DownloadRefillOrdersForPlansByStateCSVArg,
    {
      onSuccess,
      onError,
    }: { onSuccess?: () => void; onError?: (err: Error) => void } = {}
  ) {
    setIsLoading(true);
    try {
      if (option.groupByProduct) {
        await downloadXLSXFile(
          () =>
            getInventoryHistoryGroupByProductXLSX(
              option.inventoryHistoryOptions
            ),
          getFileName(option.inventoryHistoryOptions)
        );
      } else {
        await downloadCSVFromNetworkRequest(
          () => getInventoryHistoryCSV(option.inventoryHistoryOptions),
          getFileName(option.inventoryHistoryOptions)
        );
      }
      toasti18n.success();
      onSuccess?.();
    } catch (err) {
      onError?.(err);
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadRefillOrdersForPlansByStateCSV, isLoading };
}

interface InventoryHistoryOptions {
  warehouseId: string;
  refillMachine: boolean;
  expired: boolean;
  generalWithdraw: boolean;
  damageGoods: boolean;
  withdrawForRepackage: boolean;
  moveToWarehouse: boolean;
  adjustStock: boolean;
  sellToOperator: boolean;
  purchase: boolean;
  fromRepackage: boolean;
  backFromRefillMachine: boolean;
  stockAdjustment: boolean;
  startDate: string;
  endDate: string;
}

export async function getInventoryHistoryGroupByProductXLSX(
  options: InventoryHistoryOptions
) {
  const param = buildSearchParams(options);
  param.append("groupByProduct", String(true));
  const response = await httpClient.get<ArrayBuffer>(
    buildWarehouseInventoryURL(param),
    {
      responseType: "arraybuffer",
    }
  );
  return response.data;
}

export async function getInventoryHistoryCSV(options: InventoryHistoryOptions) {
  const param = buildSearchParams(options);
  param.append("groupByProduct", String(false));
  const response = await httpClient.get<string>(
    buildWarehouseInventoryURL(param),
    {
      responseType: "text",
    }
  );
  return response.data;
}

function buildSearchParams(inventoryHistoryOptions: InventoryHistoryOptions) {
  const { startDate, endDate, warehouseId, ...opts } = inventoryHistoryOptions;
  const param = new URLSearchParams();
  warehouseId && param.append("warehouseId", warehouseId);
  param.append("startDate", startDate);
  param.append("endDate", endDate);

  const filteredOpts = Object.keys(opts).filter((key) => opts[key]);
  filteredOpts.forEach((option) => {
    return param.append("opts", camelCaseToSnakeCase(option));
  });

  return param;
}
function buildWarehouseInventoryURL(searchParams: URLSearchParams) {
  return `/warehouse/report/downloadInventory?${searchParams.toString()}`;
}

function getFileName(option: InventoryHistoryOptions): string {
  return `inventory-${moment(Number(option.startDate)).format(
    "DD-MM-YYYY"
  )}-${moment(Number(option.endDate)).format("DD-MM-YYYY")}`;
}

function camelCaseToSnakeCase(str: string) {
  return str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}

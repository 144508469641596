// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OptionGroup--3h7vmbakLn{box-sizing:border-box;min-width:210px;position:absolute;top:41px;right:-30px;overflow:hidden;background:#fff;box-shadow:0px 3px 14px rgba(0,0,0,.2);border-radius:4px;transform-origin:top right;animation:OptionGroupAnimation--1ZRM5HMIGs .1s ease-out}@keyframes OptionGroupAnimation--1ZRM5HMIGs{0%{opacity:0;transform:translateY(-10px) scale(0.9)}100%{opacity:1;transform:translateY(0px) scale(1)}}", ""]);
// Exports
exports.locals = {
	"OptionGroup": "OptionGroup--3h7vmbakLn",
	"OptionGroupAnimation": "OptionGroupAnimation--1ZRM5HMIGs"
};
module.exports = exports;

import Button from "components/Button/Button";
import { Modal } from "containers/warehousing/components/ReusableModal";
import { Title } from "components/ResponsiveModal";

import { useTranslation } from "react-i18next";

import Typography from "components/Typography/Typography";

import * as React from "react";
import {
  EditLocationFeeFormValue,
  useEditLocationFee,
} from "./useEditLocationFee";
import analytics from "utils/analytics";

type FieldsChanged = {
  rentFee: boolean;
  rentPaymentInfo: boolean;
  electricFee: boolean;
  electricPaymentInfo: boolean;
  insuranceFee: boolean;
  insurancePaymentInfo: boolean;
};

const TRANSLATION_MAP = {
  rentFee: "label_rent_fee",
  rentPaymentInfo: "label_rent_payment_information",
  electricFee: "label_electric_fee",
  electricPaymentInfo: "label_electric_payment_information",
  insuranceFee: "label_insurance_fee",
  insurancePaymentInfo: "label_insurance_fee_payment_information",
};

export function AlertFeeChangeModal(props: {
  onClose: () => void;
  fieldsModified: FieldsChanged;
  form: EditLocationFeeFormValue;
}) {
  const { t } = useTranslation();

  const editLocationResult = useEditLocationFee({
    form: props.form,
  });

  function handleConfirmClick() {
    trackLocationFeeChangeEvent({
      form: props.form,
      fieldsModified: props.fieldsModified,
    });
    editLocationResult.submit();
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "416px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>{`${t("label_change_to_payment_details")}`}</Title>
      <div style={{ height: "6px" }}></div>

      <Typography type="subtitle-1">
        {t("label_change_to_payment_details_description")}
      </Typography>

      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.keys(props.fieldsModified)
          .map((key) => {
            if (props.fieldsModified[key]) {
              return (
                <Typography type="subtitle-1" key={key} color="error" translate>
                  {`• ${t(TRANSLATION_MAP[key])}`}
                </Typography>
              );
            }
            return null;
          })
          .filter(Boolean)}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "35px",
        }}
      >
        <Button
          type="secondary"
          onClick={props.onClose}
          disabled={editLocationResult.isLoading}
        >
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={handleConfirmClick}
          loading={editLocationResult.isLoading}
        >
          action_save
        </Button>
      </div>
    </Modal>
  );
}

/**
 * Send event to user analytics service to track whenever location fee changes
 * Only send fields that change over otherwise default to empty string
 */
function trackLocationFeeChangeEvent({
  form,
  fieldsModified,
}: {
  form: EditLocationFeeFormValue;
  fieldsModified: FieldsChanged;
}) {
  const hasElectricBeenModified =
    fieldsModified.electricFee || fieldsModified.electricPaymentInfo;
  const electricFeeWithPaymentInfo = {
    fee: form.electricFee,
    paymentInfo: form.electricFeePaymentInfo,
  };

  const hasInsuranceBeenModified =
    fieldsModified.insuranceFee || fieldsModified.insurancePaymentInfo;
  const insuranceFeeWithPaymentInfo = {
    fee: form.insuranceFee,
    paymentInfo: form.insuranceFeePaymentInfo,
  };

  const hasRentBeenModified =
    fieldsModified.rentFee || fieldsModified.rentPaymentInfo;
  const rentFeeWithPaymentInfo = {
    fee: form.rentFee,
    paymentInfo: form.rentFeePaymentInfo,
  };

  const properties = {
    contract_id: form.contractId,
    old_electric_fee: hasElectricBeenModified
      ? JSON.stringify(electricFeeWithPaymentInfo)
      : "",

    old_insurance_fee: hasInsuranceBeenModified
      ? JSON.stringify(insuranceFeeWithPaymentInfo)
      : "",
    old_rent_fee:
      hasRentBeenModified && form.rentFee
        ? JSON.stringify(rentFeeWithPaymentInfo)
        : "",
  };

  analytics.track({
    name: "edit_location_fee",
    properties,
  });
}

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import * as queryString from 'query-string';

import { parseSearchMap } from 'common/hooks/useQueryFilter';
import { IFilter } from 'common/interfaces';

export default function useDateFilter (): IFilter {
  const location = useLocation();

  const [ filters, setFilters ] = useState<IFilter>({});

  useEffect(() => {
    const searchMap = queryString.parse(location.search);
    const parsedSearch = parseSearchMap(searchMap);
    const gte = get(parsedSearch, [ 'time[gte]', 'value' ], null);
    const lte = get(parsedSearch, [ 'time[lte]', 'value' ], null);

    setFilters({
      ...gte ? { from: Number(gte) } : {},
      ...lte ? { to: Number(lte) } : {}
    });
  }, [location.search]);

  return filters;
}

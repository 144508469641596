import * as React from 'react';
import * as moment from 'moment';

import { IPosition } from 'common/components/binarystatus/BinaryStatus';
import Typography from 'common/components/typography/Typography';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';

import * as styles from './TooltipContent.module.scss';

const colorMap = {
  2: 'onSurfaceDisabled',
  1: 'success',
  0: 'error'
};

const labelMap = {
  2: 'label_unknown',
  1: 'status_online',
  0: 'status_offline'
};

function TooltipContent ({ position }: { position: IPosition }): JSX.Element {
  return (
    <div className={styles.TooltipContent}>
      <div className={styles.topRow}>
        <IndicatorLabel
          label={labelMap[position.state]}
          color={colorMap[position.state]}
        />
        <Typography
          text={moment.duration(position.timeToNextDataPoint).humanize()}
          type='body-2'
        />
      </div>
      <div className={styles.bottomRow}>
        <Typography
          text={moment(position.timestamp).format('MMM D, HH:mm')}
          type='body-2'
        />
        <Typography
          text='-'
          type='body-2'
        />
        <Typography
          text={moment(position.nextTimestamp).format('MMM D, HH:mm')}
          type='body-2'
        />
      </div>
    </div>
  );
}

export default TooltipContent;

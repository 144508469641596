import * as React from "react";

import MapComponent from "common/components/map/Map";
import { GeoJsonProperties } from "geojson";

import {
  useIsAdmin,
  useIsOperator,
  useIsClerk,
  useIsAdminContract,
} from "utils/user-role";

import * as styles from "./Map.module.scss";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import {
  GetMachineLocationQuery,
  useGetMachineLocationQuery,
} from "gql/generated";
import { PointFeature } from "supercluster";
import { ColumnFiltersState, TableV2 } from "components/tableV2";
import { OnChangeFn } from "@tanstack/react-table";
import { generateColumnConfig } from "./columnConfig";
import { useTranslation } from "react-i18next";
import { mapFilterToGetMachineLocationFilter } from "./mapFilterToGetMachineLocationFilter";

export type MapProps = {
  points: Array<PointFeature<GeoJsonProperties>>;
};


function Map(): JSX.Element {
  const { t } = useTranslation("machine");
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const canViewContent =
    useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsClerk();

  if (!canViewContent) {
    return <NoPermissionComponent />;
  }

  const { data, isLoading } = useGetMachineLocationQuery({
    filter: mapFilterToGetMachineLocationFilter(columnFilters),
    pagination: {
      first: 100000
    }
  }, {
    refetchOnWindowFocus: false // do not refetch on window focus to prevent it refresh when user zoom in to map then change tab and back it refetch and zoom out to wide area
  });

  const handleColumnFilterChange: OnChangeFn<ColumnFiltersState> = React.useCallback((updater) => {
    let newFilter = typeof updater === "function" ? updater(columnFilters) : updater;
    // check if newFilter don't have state with id "machineInventoryStatus" in it
    // remove all state with id "module"
    if (newFilter.findIndex((filter) => filter.id === "machineInventoryStatus") === -1) {
      newFilter = newFilter.filter((filter) => filter.id !== "module");
      setColumnFilters(newFilter);
      return;
    }
    setColumnFilters(newFilter);
  }, [columnFilters]);


  const points = React.useMemo(
    () => buildMarkers(data?.machineMonitors?.edges || []),
    [data, isLoading]
  );


  return (
    <div className={styles.Map}>
      <div className="w-full h-full flex-1 relative flex flex-col items-start justify-center">
        <TableV2
          className="flex-1"
          tableName="machine map"
          data={[]}
          columns={generateColumnConfig(t)}
          isLoading={isLoading}
        
          columnFilters={columnFilters}
          onColumnFiltersChange={handleColumnFilterChange}
          customBodyRender={() => <MapComponent points={points} loading={isLoading} />}
        />
      </div>
    </div>
  );
}

export default Map;

function buildMarkers(
  machines: GetMachineLocationQuery["machineMonitors"]["edges"]
): MapProps["points"] {
  return machines
    .filter(
      (machine) => machine?.node?.coordinates.latitude && machine?.node?.coordinates.longitude
    )
    .map((machine) => ({
      type: "Feature",
      properties: {
        cluster: false,
        locationName: machine?.node?.locationName,
        machineId: machine?.node?.machineId,
        status: machine?.node?.status,
        text: `${machine?.node?.refillZoneName}`,
        link: `/machines/detail/${machine?.node?.machineId}/inventory`,
        refillZoneName: machine?.node?.refillZoneName,
        serviceZoneName: machine?.node?.serviceZoneName,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(machine?.node?.coordinates?.longitude),
          parseFloat(machine?.node?.coordinates?.latitude),
        ],
      },
    }));
}

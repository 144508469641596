import * as React from "react";
import { useGetLocationsNameQuery, GetLocationsNameQuery, GetLocationsNameQueryVariables, GetLocationsNameDocument } from "gql/generated";
import Autocomplete, {
  AutocompleteOption,
} from "components/Autocomplete/Autocomplete";
import { ArrayElement } from "types/utils";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import { fetcher } from "gql/fetcher";
import TextField from "components/TextField";

export type LocationIdAutocompleteValue = string;

type LocationIdAutocompleteProps = {
  onChange: (value: LocationIdAutocompleteValue) => void;
  value: LocationIdAutocompleteValue | null;
  fallBackOptions?: Array<AutocompleteOption>;
  filter?: Omit<GetLocationsNameQueryVariables["filter"], "locationName" | "friendlyId">
};

// Do same as above for location Id
async function getLocationById(id: string) {
  const response = await fetcher<GetLocationsNameQuery, GetLocationsNameQueryVariables>(GetLocationsNameDocument, {
    pagination: { first: 1 },
    filter: {
      id: [id]
    }
  })();
  
  
 
  
  if (response || response?.locationsV2?.edges?.length === 1) return response?.locationsV2?.edges[0];

  return undefined;
}

/**
 * useGetLocationById hook for fetch location by ID.
 * 
 * @param id - The ID of the location to fetch.
 * @param options - Additional options for fetching the location.
 * @returns An object containing the fetched location and loading state.
 */
function useGetLocationById(id: string, options?: { includeOrganisation?: boolean }) {
  const { data, isLoading } = useGetLocationsNameQuery({
    pagination: { first: 1 },
    filter: {
      id: [id]
    },
    includeOrganisation: options.includeOrganisation
  });
  return {
    location: data?.locationsV2?.edges.length > 0 ? data?.locationsV2?.edges[0]: undefined,
    isLoading,
  };
}

function getFirstMachineInLocation(
  location: ArrayElement<GetLocationsNameQuery["locationsV2"]["edges"]>
) {
  if (!location) return undefined;

  if ("machineIds" in location) {
    return location.machineIds[0];
  }

  if ("machineId" in location) {
    return location.machineId;
  }
}

export const locationIdCache = {
  getLocationById,
  useGetLocationById,
  utils: {
    getFirstMachineInLocation,
  },
};

// This function is necessary because our query uses the "AND" operator to filter by locationName and friendlyId
function isFriendlyIdFormat(value: string) {
  // check if value is in friendly id format eg. F000001 or L00020 when first character is any capital letter and rest is number 1-7 digits
  return /^[A-Z]\d{1,7}$/.test(value);
}

export function LocationIdAutoComplete({
  value,
  onChange,
  fallBackOptions = [],
  filter,
}: LocationIdAutocompleteProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [textInput, setTextInput] = React.useState("");
  const { data, isLoading, isFetching } = useGetLocationsNameQuery({
    pagination: {
      first: 20 // limit to 20 locations
    },
    filter: {
      locationName: textInput !== "" && !isFriendlyIdFormat(textInput) ? textInput: undefined, // filter by location name if not in friendly id format
      friendlyId: textInput !== "" && isFriendlyIdFormat(textInput) ? textInput: undefined, // filter by friendly id if in friendly id format
      id: value && textInput === "" && !open ? [value] : undefined, // this help to fetch location by id when value is not empty on initial render
      ...filter,
    }
  }, {
    enabled: open || textInput !== "" || !!value, // only fetch when open or textInput is not empty or value is not empty
    keepPreviousData: true, // this important to prevent flickering when searching with new key data old data will keep 
  });

  

  const options = React.useMemo(() => {
    const locations = data?.locationsV2.edges;
    if (!locations) return fallBackOptions;

    const options =
      locations.map((location) => ({
        label: location?.node?.friendlyId + " - " + location?.node?.locationName,
        value: location?.node?.id,
      })) ?? [];

    if (fallBackOptions.length !== 0) {
      fallBackOptions.forEach((option) => {
        if (
          options.findIndex((o) => o.value === option.value) === -1
        ) {
          options.push(option);
        }
      });
    }
    return options;
  }, [data, fallBackOptions]);

  return (
    <>
      <Autocomplete
        loading={isLoading}
        label="label_location"
        id="location autocomplete"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableClearable
        value={open ? textInput: value} // if open, use textInput as value, otherwise it will use location id in textfield when you type again
        onChange={(newValue) => {
          setTextInput(data.locationsV2?.edges?.find((edge) => edge?.node?.id === newValue)?.node?.locationName ?? "");
          onChange(newValue);
        }}
        
        options={options}
        customRenderInput={(params) => (
          <TextField
            {...params}
            onChange={e => {
              setTextInput(e.target.value);
            }}
            label={t("label_location")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching && <Icon className="w-4 h-4 animate-spin" name="loader" color="onSurfaceHigh"  />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

import * as React from 'react';

import * as styles from './Hamburger.module.scss';

interface IHamburgerProps {
  open: boolean,
  hamburgerClick: () => void
}

function Hamburger ({
  open,
  hamburgerClick
}: IHamburgerProps): JSX.Element {
  return (
    <div
      className={styles.Hamburger}
      onClick={hamburgerClick}
    >
      <div
        className={[
          styles.line,
          styles.line1,
          open ? styles.open : ''
        ].join(' ')}
      />
      <div
        className={[
          styles.line,
          styles.line2,
          open ? styles.open : ''
        ].join(' ')}
      />
      <div
        className={[
          styles.line,
          styles.line3,
          open ? styles.open : ''
        ].join(' ')}
      />
    </div>
  );
}

export default Hamburger;

import * as React from "react";
import { useImmer } from "use-immer";

import Button from "common/components/button/Button";
import Modal from "common/components/modal/Modal";
import Select from "common/components/select/Select";
import Input from "common/components/input/Input";
import Typography from "common/components/typography/Typography";

import { IInviteUser } from "userandauth/interfacesUser";
import { useInviteUser } from "userandauth/hooks/useInviteUser";

import * as styles from "./InviteMemberModal.module.scss";
import { useGetOwnPermission } from "hooks/permission";
import { ROLE, getRoleTranslation } from "utils/user-role";

function InviteMemberModal({
  onClose,
  inviterOrgId,
}: IInviteMemberProps): JSX.Element {
  const { allowed: isAllowedToInviteAdmin, isLoading: isLoadingPermission } =
    useGetOwnPermission({
      action: "WRITE",
      resourceType: "ADMIN_USER",
    });

  const [form, setForm] = useImmer<IForm>(initForm);
  const { mutate: inviteUser, isLoading: isInvitingUser } = useInviteUser({
    onSuccess: () => {
      onClose();
    },
  });

  function handleSelectRole(value: string): void {
    setForm((draft) => {
      draft["role"] = value;
    });
  }

  function setEmail(value: string): void {
    setForm((draft) => {
      draft["email"] = value;
    });
  }

  function setName(value: string): void {
    setForm((draft) => {
      draft["name"] = value;
    });
  }

  // function setPhone(value: string): void {
  //   setForm((draft) => {
  //     draft['phone'] = value;
  //   });
  // }

  function handleClose(): void {
    onClose();
  }

  function handleInvite(): void {
    inviteUser(userDetails);
  }

  const userDetails: IInviteUser = {
    email: form.email?.toLocaleLowerCase(),
    role: form.role,
    isProvider: false,
    organisationId: inviterOrgId,
    name: form.name,
    // phone: form.phone
  };

  return (
    <Modal isOpen={true} onClose={handleClose} contentClassName={styles.modal}>
      <div className={styles.InviteMemberModal}>
        <header className={styles.header}>
          <Typography type="headline-6" translationKey="label_invite_people" />
        </header>
        <div className={styles.body}>
          <Select
            label="label_role"
            value={form.role}
            onSelect={(value: string) => handleSelectRole(value)}
            options={[
              isAllowedToInviteAdmin && !isLoadingPermission
                ? { label: getRoleTranslation(ROLE.ADMIN), value: ROLE.ADMIN }
                : null,
              { label: getRoleTranslation(ROLE.VIEWER), value: ROLE.VIEWER },
              { label: getRoleTranslation(ROLE.CLERK), value: ROLE.CLERK },
              { label: getRoleTranslation(ROLE.ROUTEMAN), value: ROLE.ROUTEMAN },
              { label: getRoleTranslation(ROLE.SUPER_VIEWER), value: ROLE.SUPER_VIEWER },
              { label: getRoleTranslation(ROLE.OPERATOR), value: ROLE.OPERATOR },
              { label: getRoleTranslation(ROLE.TECHNICIAN), value: ROLE.TECHNICIAN },
              // Not allowed to invite admin contract - manual assignment in BE
              // { label: getRoleTranslation(ROLE.ADMIN_CONTRACT), value: ROLE.ADMIN_CONTRACT },
            ].filter(Boolean)}
          />
          <Input
            value={form.name}
            label="label_name"
            onChange={(value) => setName(value)}
            required={true}
          />
          {/* <Input
            value={form.phone}
            label='label_phone_number'
            onChange={(value) => setPhone(value)}
            required={true}
          />    */}
          <Input
            value={form.email}
            label="label_email"
            onChange={(value) => setEmail(value)}
            required={false}
          />
        </div>

        <footer className={styles.action}>
          <Button
            translationKey="action_cancel"
            onClick={handleClose}
            type="secondary"
            disabled={isInvitingUser}
          />
          <Button
            translationKey="action_invite"
            disabled={form.role === null || form.name === ""}
            onClick={handleInvite}
            type="primary"
            loading={isInvitingUser}
          />
        </footer>
      </div>
    </Modal>
  );
}

export default InviteMemberModal;

const initForm = { email: "", role: null, name: "" };

interface IInviteMemberProps {
  onClose: () => void;
  inviterOrgId: string;
}

interface IForm {
  email: string;
  name: string;
  role: string | null | undefined;
  // phone: string
}

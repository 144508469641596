import * as React from "react";

import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material";

import * as styles from "./Checkbox.module.scss";

/**
 * Usage examples:
 * ```tsx
*     <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />
      <Checkbox checked={true} disabled />
      <Checkbox checked={false} />
      <Checkbox indeterminate={false} checked={true} />
      <Checkbox indeterminate={true} checked={false} />
      <Checkbox indeterminate={true} checked={false} disabled />
      <Checkbox checked={false} disabled />
 * ```
 */
export default function Checkbox({ className, disabled, ...rest }: CheckboxProps): JSX.Element {
  return (
    <MuiCheckbox
      {...rest}
      disabled={disabled}
      checkedIcon={disabled ? <CheckedAndDisabledIcon /> : <CheckedIcon />}
      icon={disabled ? <UncheckedAndDisabledIcon /> : <UncheckedIcon />}
      indeterminateIcon={disabled ? <IndeterminateAndDisabledIcon /> : <IndeterminateIcon />}
      className={[styles.Checkbox, className].join(" ")}
    />
  );
}

function IndeterminateIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5443_73248" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.5H16C17.1 0.5 18 1.4 18 2.5V16.5C18 17.6 17.1 18.5 16 18.5H2C0.9 18.5 0 17.6 0 16.5V2.5C0 1.4 0.9 0.5 2 0.5ZM4 10.5H14V8.5H4V10.5Z"
          fill="white"
        />
      </mask>
      <rect x="2" y="2" width="14" height="14" fill="white" />
      <g mask="url(#mask0_5443_73248)">
        <rect x="-3" y="-2.5" width="24" height="24" fill="#4B4D9B" />
      </g>
    </svg>
  );
}

function IndeterminateAndDisabledIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5443_73248" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.5H16C17.1 0.5 18 1.4 18 2.5V16.5C18 17.6 17.1 18.5 16 18.5H2C0.9 18.5 0 17.6 0 16.5V2.5C0 1.4 0.9 0.5 2 0.5ZM4 10.5H14V8.5H4V10.5Z"
          fill="white"
        />
      </mask>
      <rect x="2" y="2" width="14" height="14" fill="white" />
      <g mask="url(#mask0_5443_73248)">
        <rect x="-3" y="-2.5" width="24" height="24" fill="#E0E0E0" />
      </g>
    </svg>
  );
}

function UncheckedIcon(): JSX.Element {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderColor: "rgba(102, 102, 102, 0.24)",
        borderWidth: "1px",
        borderRadius: "4px",
        boxSizing: "border-box",
        borderStyle: "solid",
      }}
    ></div>
  );
}

function UncheckedAndDisabledIcon(): JSX.Element {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        backgroundColor: "#E0E0E0",
        borderWidth: "0px",
        borderRadius: "4px",
        boxSizing: "border-box",
        borderStyle: "solid",
      }}
    ></div>
  );
}

function CheckedAndDisabledIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_119_40" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H16C17.11 0 18 0.9 18 2V16C18 17.1 17.11 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0ZM2 9L7 14L16 5L14.59 3.58L7 11.17L3.41 7.59L2 9Z"
          fill="white"
        />
      </mask>
      <rect x="2" y="2" width="14" height="14" fill="white" />
      <g mask="url(#mask0_119_40)">
        <rect x="-3" y="-3" width="24" height="24" fill="#E0E0E0" />
      </g>
    </svg>
  );
}

function CheckedIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_119_40" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H16C17.11 0 18 0.9 18 2V16C18 17.1 17.11 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0ZM2 9L7 14L16 5L14.59 3.58L7 11.17L3.41 7.59L2 9Z"
          fill="white"
        />
      </mask>
      <rect x="2" y="2" width="14" height="14" fill="white" />
      <g mask="url(#mask0_119_40)">
        <rect x="-3" y="-3" width="24" height="24" fill="#4B4D9B" />
      </g>
    </svg>
  );
}

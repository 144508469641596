// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EllipsisDropdown--2qk3AGs-BG{box-sizing:border-box;position:relative;z-index:5;display:inline-block;outline:none}", ""]);
// Exports
exports.locals = {
	"EllipsisDropdown": "EllipsisDropdown--2qk3AGs-BG"
};
module.exports = exports;

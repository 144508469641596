import * as React from "react";

type ClusterProps = {
  size: number;
  max: number; // max number of node in a cluster
  min: number; // min number of node in a cluster
};

type bubbleSizeArgs = {
  size: number;
  min?: number;
  max?: number;
};

const bubbleSize = ({ size, max, min }: bubbleSizeArgs): number => {
  const minSize = 30; // min size of the bubble in px
  const maxSize = 80; // max size of the bubble in px
  // if max and min are the same that mean only one cluster on the map, so we return the maxSize
  if (max === min) {
    return maxSize;
  }
  // scale the size to the range of (minSize, maxSize)
  return (size - min) / (max - min) * (maxSize - minSize) + minSize;
};

const textSize = (size: number): number => {
  // Calculate font size as a proportion of the bubble size
  return Math.max(size / 4, 10); // Ensure a minimum font size of 10px
};

export function Cluster({ size, max, min }: ClusterProps) {
  const sizeNumber = bubbleSize({ size, max, min });
  const fontSize = textSize(sizeNumber);

  return (
    <div
      className="bg-primary-400 min-w-2.5 min-h-2.5 border-2 border-solid border-white shadow-md rounded-full text-white flex items-center justify-center"
      style={{
        width: `${sizeNumber}px`,
        height: `${sizeNumber}px`,
        fontSize: `${fontSize}px`,
      }}
    >
      {size}
    </div>
  );
}

import * as React from "react";
import { getMachineLimitsXLSX } from "machines/service";
import { toasti18n } from "utils/toast";

async function downloadLimitXLSX() {
  const file = await getMachineLimitsXLSX();

  const XLSXFile = new File([file], "limits.xlsx", {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const anchorElement = document.createElement("a");
  anchorElement.href = URL.createObjectURL(XLSXFile);
  anchorElement.download = "limits.xlsx";
  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);
}

export function useDownloadLimitReportCSV() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function downloadLimitReportCSV() {
    try {
      setIsLoading(true);
      await toasti18n.promise(downloadLimitXLSX());
    } catch (error) {
      toasti18n.error({ message: "error" });
      throw new Error("Error downloading machine limit report");
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadLimitReportCSV, isLoading };
}

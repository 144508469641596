import {
  useGetWarehouseId,
  warehousingRoutes,
} from "containers/warehousing/routes";
import * as React from "react";
import {
  useEditWarehouseSettings,
  useGetWarehouseById,
} from "../WarehouseOverview/use-get-warehouse";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import { WarehousingWorkspacePaddingContainer } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import { useGetOrganisations } from "userandauth/hooks/useGetOrganisations";
import TextButton from "common/components/textbutton/TextButton";
import { Modal, Title } from "../../../../components/ReusableModal";
import { useImmer } from "use-immer";
import TextField from "components/TextField/TextField";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { getProvince } from "containers/location/utils/getProvince";
import Button from "components/Button/Button";
import { toasti18n } from "utils/toast";
import { useIsAdmin, useIsAdminContract, useIsClerk } from "utils/user-role";

export function WarehouseIdSettings() {
  const warehouseId = useGetWarehouseId();

  const { data: warehouse, isLoading: isWarehouseLoading } =
    useGetWarehouseById(warehouseId);
  const { data: organisations, isLoading: isOrganisationsLoading } =
    useGetOrganisations();

  const isLoading = isWarehouseLoading || isOrganisationsLoading;

  const organisationName = organisations?.find(
    (organisation) => organisation.id === warehouse?.organisationId
  )?.name;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WarehousingHeader
        title={"label_setting"}
        isLoading={isWarehouseLoading}
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
            link: warehousingRoutes.warehouses(),
          },
          {
            label: warehouse?.name,
            link: warehousingRoutes.warehousesWithWarehouseId(warehouseId),
          },
          {
            label: "label_setting",
          },
        ]}
        buttons={
          <div>
            {isLoading ? null : (
              <EditWarehouseDetailsModalButton warehouseId={warehouseId} />
            )}
          </div>
        }
      />
      <div style={{ flex: "1 1 0px", minHeight: "0px", background: "white" }}>
        <WarehousingWorkspacePaddingContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "18px",
              margin: "0 -14px",
              paddingTop: "40px",
              paddingBottom: "40px",
              maxWidth: "50%",
            }}
          >
            <TextFieldViewOnly
              label={"label_warehouse_name"}
              isLoading={isLoading}
              value={warehouse?.name}
            />
            <TextFieldViewOnly
              label={"label_warehouse_id"}
              isLoading={isLoading}
              value={warehouse?.warehouseId}
            />
            <TextFieldViewOnly
              label={"label_organisation"}
              isLoading={isLoading}
              translateValue
              value={organisationName ?? warehouse?.organisationId}
            />
            <TextFieldViewOnly
              label={"label_address"}
              isLoading={isLoading}
              value={warehouse?.address}
            />
          </div>
        </WarehousingWorkspacePaddingContainer>
      </div>
    </div>
  );
}

function useCanEditWarehouse() {
  return useIsAdmin() || useIsClerk() || useIsAdminContract();
}

function EditWarehouseDetailsModalButton({
  warehouseId,
}: {
  warehouseId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canEditWarehouse = useCanEditWarehouse();
  if (!canEditWarehouse) {
    return null;
  }

  return (
    <>
      <TextButton
        icon="PencilCreate"
        translationKey="label_edit_warehouse_details"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <EditWarehouseDetailsModal
          warehouseId={warehouseId}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
}

interface Form {
  name: string;
  province: string;
  address: string;
  lat: string;
  long: string;
}

function EditWarehouseDetailsModal({
  warehouseId,
  onClose,
}: {
  warehouseId: string;
  onClose: () => void;
}) {
  const { data: warehouse } = useGetWarehouseById(warehouseId);

  const [form, setForm] = useImmer<Form>({
    address: warehouse.address,
    lat: String(warehouse.lat),
    long: String(warehouse.long),
    name: String(warehouse.name),
    province: String(warehouse.province),
  });
  const initialFormValue = React.useMemo(() => {
    return {
      address: warehouse.address,
      lat: String(warehouse.lat),
      long: String(warehouse.long),
      name: String(warehouse.name),
      province: String(warehouse.province),
    };
  }, [warehouse]);

  const noFieldsChanged = React.useMemo(() => {
    return JSON.stringify(form) === JSON.stringify(initialFormValue);
  }, [form, initialFormValue]);

  const isFormDisabled =
    form.name === "" ||
    form.province === "" ||
    form.address === "" ||
    isNaN(Number(form.lat)) ||
    form.lat === "" ||
    isNaN(Number(form.long)) ||
    form.long === "" ||
    !form.long ||
    noFieldsChanged;

  const { mutate, isLoading } = useEditWarehouseSettings({
    onSuccess: () => {
      onClose();
      toasti18n.success();
    },
  });
  function handleSubmit() {
    mutate({
      warehouseData: {
        address: form.address,
        lat: Number(form.lat),
        long: Number(form.long),
        name: form.name,
        province: form.province,
      },
      warehouseId: warehouseId,
    });
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      modalStyles={{
        maxWidth: "720px",
        heightStyles: {
          flex: "1 1 auto",
          maxHeight: "679px",
        },
        alignment: "start",
      }}
    >
      <Title>label_edit_warehouse_details</Title>
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <TextField
          label="label_name"
          value={form.name}
          required
          onChange={(e) =>
            setForm((draft) => {
              draft.name = e.target.value;
            })
          }
        />
        <TextField
          label="label_address"
          value={form.address}
          required
          onChange={(e) =>
            setForm((draft) => {
              draft.address = e.target.value;
            })
          }
        />
        <Autocomplete
          value={form.province}
          onChange={(newValue) =>
            setForm((draft) => {
              draft.province = newValue;
            })
          }
          id="province"
          label="label_province"
          options={Object.values(getProvince()).map((type) => ({
            value: type,
            label: `label_${type.toLowerCase()}`,
          }))}
          disableClearable={true}
          translateLabels={true}
          required={true}
        />

        <TextField
          label="label_latitude"
          value={form.lat}
          required
          onChange={(e) =>
            setForm((draft) => {
              draft.lat = e.target.value;
            })
          }
        />
        <TextField
          label="label_longitude"
          value={form.long}
          required
          onChange={(e) =>
            setForm((draft) => {
              draft.long = e.target.value;
            })
          }
        />
      </div>
      <div style={{ flex: "1 1 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
      >
        <Button type="secondary" onClick={onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isFormDisabled}
        >
          action_save
        </Button>
      </div>
    </Modal>
  );
}

import { DashboardCardPieChartProps } from '../organisms/DashboardCard';

import {
  APIPieChart,
  APIPieChartSegment
} from 'dashboard/interface';

import * as colors from 'common/styles/colors.scss';

const PIE_CHART_COLORS = [
  'chartIndigo',
  'chartCornflowerBlue',
  'chartLightBlue',
  'chartPeanutBrown',
  'chartCoffeeBrown',
  'chartPink'
] as const;

export function mapDataToPieChartCard (
  dataPoint: APIPieChart): Omit<DashboardCardPieChartProps, 'className'> {
  return {
    type: 'pie',
    cardProps: {
      title: dataPoint.title,
      icon: dataPoint.icon,
      data: computeDataFieldForPieChartCard(dataPoint.data.pieces)
    }
  };
}

function computeDataFieldForPieChartCard (
  APIPieChartData: APIPieChart['data']['pieces']
): DashboardCardPieChartProps['cardProps']['data'] {
  const APIPieChartDataCopy = [...APIPieChartData];
  APIPieChartDataCopy.sort((a, b) => b.value - a.value);

  if (APIPieChartData.length > 6) {
    const others: APIPieChartSegment = APIPieChartDataCopy.splice(5).reduce(
      (acc, segment) => ({ ...acc, value: acc.value + segment.value }),
      { label: 'label_others', value: 0 }
    );
    APIPieChartDataCopy.push(others);
  }

  return APIPieChartDataCopy.map((segment, index) => ({
    amount: segment.value,
    label: segment.label,
    color: colors[PIE_CHART_COLORS[index % 6]],
    borderColor: colors[PIE_CHART_COLORS[index % 6]],
  }));
}

import * as React from 'react';
import { useState } from 'react';
import Typography from 'components/Typography';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import MuiModal from 'components/Modal';
import * as moment from 'moment';
import Select from 'components/Select/Select';
import { ReportType, useGenerateReportMutation } from 'gql/generated';
import { toasti18n } from 'utils/toast';

function DownloadMaterialMovementReportByRefillZoneModal({
  onCancel,
}: {
  onCancel: () => void;
}): JSX.Element {
  const [startTime, setStartTime] = useState<moment.Moment>(
    moment().startOf('day')
  );
  const [state, setState] = useState<'kitted' | 'en_route' | 'refilled' | 'completed'>('completed');
  const { mutate, isLoading } = useGenerateReportMutation({
    onSuccess: (data) => {
      try{
      window.open(`/download-report/${data.generateReport}`);
      }catch(err){
        toasti18n.error(err);
      }
    }
  });

  return (
    <MuiModal
      open={true}
      onClose={isLoading ? () => {} : onCancel}
      padding="18px 19px"
      minWidth="430"
      PaperProps={{ sx: { gap: '16px' } }}
    >
      <>
        <Typography type="headline-6" translate>
          label_download_material_movement_by_refill_zone
        </Typography>
        <Select
          id="status"
          label="label_status"
          value={state}
          onChange={(e) => setState(e.target.value)}
          options={[
            { label: 'label_kit', value: 'kitted' },
            { label: 'label_enroute', value: 'en_route' },
            { label: 'label_refilled', value: 'refilled' },
            { label: 'label_completed', value: 'completed' },
          ]}
          required
        />
        <DatePicker
          required
          label="label_start_date"
          value={startTime}
          onChange={(e) => {
            if (e === null || (moment.isMoment(e) && !e.isValid())) {
              setStartTime(moment().startOf('day'));
            } else if (moment.isMoment(e)) {
              setStartTime(e.startOf('day'));
            }
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="secondary" onClick={onCancel} disabled={isLoading}>
            action_cancel
          </Button>
          <Button
            type="primary"
            disabled={!startTime.isValid()}
            loading={isLoading}
            onClick={() => {
              mutate({
                params: {
                  startDate: startTime.format("yyyy-MM-DD"),
                  endDate: startTime.format("yyyy-MM-DD"),
                  reportType: ReportType.MaterialMovementByRefillZone,
                  state: state 
                }
              });     
            }
          }
          >
            action_download
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export default DownloadMaterialMovementReportByRefillZoneModal;

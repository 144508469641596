import { InventoryStatusString } from "../Components";
import * as moment from "moment";

export function timestampFormatter(timestamp: string | null): string {
  if (timestamp === null) return null;
  return moment(Number(timestamp)).format("MMM DD, YYYY");
}

export function computeInventoryStatus(status: string): InventoryStatusString {
  return !status
    ? undefined
    : status === "abnormal"
    ? "ERROR"
    : status.includes("disable")
    ? "DISABLE"
    : status === "normal"
    ? "AVAILABLE"
    : undefined;
}

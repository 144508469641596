import LoadingScreen from "components/LoadingScreen";
import * as React from "react";

export function LocationIdLoading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingScreen />
    </div>
  );
}

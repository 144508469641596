/**
 * Parses the slot name and returns the letter and number portion as strings in an array
 * @returns `[ letterPart, numberPart]`
 */
export function getLetterAndNumberPartsOfSlotName (slotName : string) : [string, string] {
  let indexThatNumberBegins : number;

  const startCharacterCode : number = '0'.codePointAt(0);
  const endCharacterCode : number = '9'.codePointAt(0);

  // Find where the number begins
  for (let i = 0; i < slotName.length; i++) {
    const currentCharacterCode : number = slotName[i].codePointAt(0);
    if (startCharacterCode <= currentCharacterCode && currentCharacterCode <= endCharacterCode) {
      indexThatNumberBegins = i;
      break;
    }
  }

  // If no number found, set to last index
  if (indexThatNumberBegins === undefined) {
    indexThatNumberBegins = slotName.length;
  }

  // Slice the string based on where the number begins
  const letterPart : string = slotName.slice(0, indexThatNumberBegins);
  const numberPart : string = slotName.slice(indexThatNumberBegins);
  return [ letterPart, numberPart ];
}

/**
 * Function used as an argument to the sort method of arrays when sorting by the slot name. `itemRequested.sort((a,b) => compareSlotNames(a.slot,b.slot))`
 */
export function compareSlotNames (slotA : string, slotB: string) {
  let [ slotALetter, slotANumber ] = getLetterAndNumberPartsOfSlotName(slotA) as [string, string | number];
  let [ slotBLetter, slotBNumber ] = getLetterAndNumberPartsOfSlotName(slotB) as [string, string | number];

  // First compare the letters
  if (slotALetter > slotBLetter) {
    return 1;
  }
  if (slotALetter < slotBLetter) {
    return -1;
  }

  // If slots have same the starting letter, compare their numbers
  slotANumber = Number(slotANumber);
  slotBNumber = Number(slotBNumber);

  if (slotANumber > slotBNumber) {
    return 1;
  }
  if (slotANumber < slotBNumber) {
    return -1;
  }

  return 0; // If everything is equal, return 0
}

// Functions to be used with array of objects with a slot property
interface IObjectWithSlotProperty {
  slot: string;
}

/** Sets `element[property] = value` for `element.slot = slot` immutably, in the array `arrayOfObjectsWithSlotProp` and returns it
 * @param slot - The slot string used to identify the element in the array. Matches first object with _`{slot: slot, ...}`_
 * @param arrayOfObjectsWithSlotProp - Array of objects, each having a slot property. _`[{slot: 'A1', ...}, ...]`_
 * @param property - Name of the property on the element to set the value of
 * @param value - Value to set the property to.
 * @returns Copy of the array with the matched element modified. Original order is maintained _`[..., {slot: slot, [property]: value, ...}, ...]`_
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 22-Jul-21
 *
 * __Code last updated__: 22-Jul-21
 */
export function setPropertyValueOfElementWithSlot <Type> (
  {
    slot,
    arrayOfObjectsWithSlotProp,
    property,
    value
  } :
  {
    slot: string,
    arrayOfObjectsWithSlotProp: Array<Type & IObjectWithSlotProperty>,
    property: string,
    value: any
  }
) {
  const indexOfItemThatNeedsChanging = arrayOfObjectsWithSlotProp.findIndex(
    object => object.slot === slot
  );

  const arrayOfObjectsCopy = [...arrayOfObjectsWithSlotProp];

  arrayOfObjectsCopy[indexOfItemThatNeedsChanging] = {
    ...arrayOfObjectsCopy[indexOfItemThatNeedsChanging],
    [property]: value
  };
  return arrayOfObjectsCopy;
}

/**
 * Immutability removes an element with `element.slot = slot` from the `arrayOfObjectsWithSlotProp` array and returns it
 * @param slot - The slot string used to identify the element in the array. Matches first object with _`{slot: slot, ...}`_ to be removed
 * @param arrayOfObjectsWithSlotProp - Array of objects, each having a slot property. _`[{slot: 'A1', ...}, ...]`_
 * @returns Copy of the array with the matched element removed
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 22-Jul-21
 *
 * __Code last updated__: 22-Jul-21
 */
export function removeElementWithSlot <Type> (
  {
    slot,
    arrayOfObjectsWithSlotProp
  }
  :
  {
    slot: string,
    arrayOfObjectsWithSlotProp: Array<Type & IObjectWithSlotProperty>
  }
) {
  return arrayOfObjectsWithSlotProp.filter(object => object.slot !== slot);
}

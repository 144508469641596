import * as React from "react";

import { GetPlanForOperationPdfQuery, MachineKind } from "gql/generated";
import { useTranslation } from "react-i18next";

import { COLORS } from "pdf/constants";
import { Checkbox, FormField } from "pdf/components/atoms";
import { Text } from "pdf/components/primitives/Text";
import ReactPDF, {
  Document,
  Page,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import * as moment from "moment";
import { SpiralIcon } from "./svg/SpiralIcon";

export type PlanMachineSummary = {
  planName: string;
  planFriendlyId: string;
  createdAt: moment.Moment;
  numberOfSpiralMachines: number;
  numberOfTaobinMachines: number;
  orders: Array<Order>;
  routeNames: Array<string>;
  warehouseName: string;
};

export type Order = {
  positionInPlan: number;
  machineId: string;
  kind: MachineKind;
  parentId?: string;
  locationName: string;
  routeName: string;
};

export function mapDataToPlanMachineSummary(
  data: GetPlanForOperationPdfQuery
): PlanMachineSummary {
  return {
    planName: data.plan.name,
    planFriendlyId: data.plan.friendlyId,
    numberOfSpiralMachines: data.plan.refillOrders?.filter(ro => ro.machine.kind === MachineKind.SpiralVending)?.length || 0,
    numberOfTaobinMachines: data.plan.refillOrders?.filter(ro => ro.machine.kind === MachineKind.BeverageVending)?.length || 0,
    routeNames: Array.from(
      new Set(
        data.plan.refillOrders.map(
          (refillOrder) => refillOrder.location.refillZone.friendlyId
        )
      )
    ).sort((a, b) => a.localeCompare(b)),
    orders: data.plan.refillOrders
        .map((refillOrder) => ({
          positionInPlan: refillOrder.positionInPlan,
          machineId: refillOrder.machineId,
          kind: refillOrder.machine.kind,
          parentId: refillOrder.machine.parentId,
          locationName: refillOrder.location.locationName,
          routeName: refillOrder.location.refillZone.friendlyId,
        }))
        .sort((a, b) => a.positionInPlan - b.positionInPlan),
    warehouseName: data.plan.warehouse.name,
    createdAt: moment(Number(data.plan.createdAt)),
  };
}

export function OperationFrontCover({ data }: { data: PlanMachineSummary }) {
  return (
    <Document>
      <Page size="A4" style={{ padding: 32 }}>
        <Header data={data} />
        <View style={{ height: 15 }} />
        <SummaryBox data={data} />
        <View style={{ height: 18 }} />
        <Table orders={data.orders} />
        <View
          wrap={false}
          style={{
            marginTop: 13,
          }}
        >
          <FooterForm />
        </View>
      </Page>
    </Document>
  );
}

function Header({ data }: { data: PlanMachineSummary }) {
  const { t } = useTranslation();
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: -3,
        }}
      >
        <Text
          style={{
            fontFamily: "Kanit-regular",
            fontSize: 20,
            letterSpacing: 0.15,
            color: COLORS.onSurfaceHigh,
          }}
        >
          {t("label_plan")}
        </Text>
        <Text
          style={{
            fontFamily: "Kanit-light",
            fontSize: 12,
            letterSpacing: 0.25,
            color: COLORS.onSurfaceHigh,
            marginTop: 5,
          }}
        >
          {`${t("label_date")}: ${data.createdAt.format("DD/MM/YYYY")}`}
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontFamily: "Kanit-medium",
            fontSize: 20,
          }}
        >
          {data.planName}
        </Text>
      </View>
    </>
  );
}

function SummaryBox({ data }: { data: PlanMachineSummary }) {
  const { t } = useTranslation();
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        paddingVertical: 6,
        paddingHorizontal: 16,
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 4,
        borderColor: "#000000",
      }}
    >
      <PlanHeaderItem title={t("label_plan_id")} value={data.planFriendlyId} />

      <PlanHeaderItem
        title={t("label_no_of_taobin_machine") + " "} // add empty string to fix pdf render thai character bug
        value={data.numberOfTaobinMachines.toString()}
        style={{
          marginLeft: 16,
          maxWidth: 150,
        }}
      />
      <PlanHeaderItem
        title={t("label_no_of_spiral_machine") + " "} // add empty string to fix pdf render thai character bug
        value={data.numberOfSpiralMachines.toString()}
        style={{
          marginLeft: 16,
          maxWidth: 170,
        }}
      />
      <PlanHeaderItem
        title={t("label_route")}
        value={data.routeNames.join(", ")}
        style={{
          marginLeft: 16,
          maxWidth: 127,
        }}
      />
      <PlanHeaderItem
        title={t("label_warehouse")}
        value={data.warehouseName}
        style={{
          marginLeft: 16,
          maxWidth: 127,
        }}
      />
    </View>
  );
}

function PlanHeaderItem({
  title,
  value,
  ...rest
}: { title: string; value: string } & ReactPDF.ViewProps) {
  return (
    <View {...rest}>
      <Text
        style={{
          fontFamily: "Kanit-regular",
          fontSize: 10,
          letterSpacing: 0.4,
          color: COLORS.onSurfaceMedium,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          fontFamily: "Kanit-medium",
          fontSize: 12,
          color: COLORS.onSurfaceHigh,
        }}
      >
        {value}
      </Text>
    </View>
  );
}

function Table({ orders }: { orders: Order[] }) {
  return (
    <>
      <TableHeader />
      {orders.map((order) => (
        <TableRow key={order.positionInPlan} order={order} />
      ))}
    </>
  );
}

function TableHeader() {
  const tableHeaderStyles: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceMedium,
    fontFamily: "Kanit-regular",
    fontSize: 10,
    lineHeight: 16 / 10,
    letterSpacing: 0.4,
  };

  const { t } = useTranslation();

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
      }}
    >
      <View style={tableStyles.orderCell}>
        <Text style={tableHeaderStyles}>{t("label_order")}</Text>
      </View>
      <View style={tableStyles.machineIdCell}>
        <Text style={tableHeaderStyles}>{t("label_machine_id")}</Text>
      </View>
      <View style={tableStyles.locationNameCell}>
        <Text style={tableHeaderStyles}>{t("label_location_name")}</Text>
      </View>
      <View style={tableStyles.routeCell}>
        <Text style={tableHeaderStyles}>{t("label_route")}</Text>
      </View>
      <View style={tableStyles.packCell}>
        <Text style={tableHeaderStyles}>{t("label_pack")}</Text>
      </View>
      <View style={tableStyles.refillCell}>
        <Text style={tableHeaderStyles}>{t("label_refill")}</Text>
      </View>
      <View style={tableStyles.returnCell}>
        <Text style={tableHeaderStyles}>{t("label_return")}</Text>
      </View>
    </View>
  );
}

function TableRow({ order }: { order: Order }) {
  const tableRowTextStyles: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceHigh,
    fontFamily: "Kanit-light",
    fontSize: 10,
  };
  const tableRowSmallTextStyles: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceDisabled,
    fontFamily: "Kanit-light",
    fontSize: 8,
  };

  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
        paddingTop: "2px",
        paddingBottom: "2px",
      }}
      wrap={false}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -2,
          marginBottom: -2,
        }}
      >
        <View style={tableStyles.orderCell}>
          <Text
            style={{
              fontFamily: "Kanit-medium",
              fontSize: 14,
              color: COLORS.onSurfaceHigh,
            }}
          >
            {order.positionInPlan}
          </Text>
        </View>

        {order.kind === MachineKind.SpiralVending ? (
          <View style={{ ...tableStyles.machineIdCell }}>
            <View style={{ marginRight: "8px" }}>
              <Text style={{ ...tableRowTextStyles, lineHeight: 1 }}>
                {order.machineId}
              </Text>
              <Text style={tableRowSmallTextStyles}>{order.parentId}</Text>
            </View>
            <SpiralIcon />
          </View>
        ) : (
          <View style={{ ...tableStyles.machineIdCell }}>
            <Text style={tableRowTextStyles}>{order.machineId}</Text>
          </View>
        )}

        <View style={tableStyles.locationNameCell}>
          <Text
            style={{
              paddingRight: 5,
              ...tableRowTextStyles,
            }}
            breakWordAnywhere
          >
            {order.locationName}
          </Text>
        </View>
        <View style={tableStyles.routeCell}>
          <Text style={tableRowTextStyles}>{order.routeName}</Text>
        </View>
        <View style={tableStyles.packCell}>
          <Checkbox />
        </View>
        <View style={tableStyles.refillCell}>
          <Checkbox />
        </View>
        <View style={tableStyles.returnCell}>
          <Checkbox />
        </View>
      </View>
    </View>
  );
}

const tableStyles = StyleSheet.create({
  orderCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 48,
    flexBasis: 48,
  },
  machineIdCell: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    minWidth: 120,
    flexBasis: 120,
  },

  locationNameCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    flexBasis: 0,
    flexGrow: 1,
  },

  routeCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 74,
    flexBasis: 74,
  },

  packCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 38,
    flexBasis: 38,
  },

  refillCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 54,
    flexBasis: 54,
  },
  returnCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 42,
    flexBasis: 42,
  },
});

function FooterForm() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flexBasis: 265,
          minWidth: 265,
        }}
      >
        <FormField
          label="label_notes"
          numberOfLines={3}
          numberOfDots={85}
          type="text"
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          flexBasis: 0,
          minWidth: 0,
        }}
      >
        <FormField
          label="label_pack_by"
          numberOfDots={27 * 3 + 4}
          type="text"
        />
        <FormField label="label_pack_date" numberOfDots={27} type="date" />
      </View>
    </View>
  );
}

import * as React from "react";
import { Dialog, DialogProps } from "@mui/material";

interface IModalProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  children?: JSX.Element;
  minWidth: string;
  minHeight?: string;
  padding: string;
}

// USAGE
// <MuiModal
//    open={isModalOpen}
//    onClose={onCloseFunction}
//    minWidth='500px'
//    minHeight='500px'
//    padding="25px 26px"
//  >
// <TheContent/>
// </MuiModal>

export default function MuiModal({
  minHeight,
  minWidth,
  padding,
  PaperProps,
  ...rest
}: IModalProps): JSX.Element {
  return (
    <Dialog
      PaperProps={{
        style: {
          overflowY: "auto",
          overflowX: "hidden",
          width: `min(100%, ${minWidth})`,
          height: minHeight ? `min(100%, ${minHeight})` : undefined,
          padding: `${padding}`,
          position: "relative",
          boxSizing: "border-box",
          maxHeight: "unset",
          maxWidth: "unset",
          margin: "0",
        },
        ...PaperProps,
      }}
      {...rest}
    ></Dialog>
  );
}

import * as React from 'react';
import { useState } from 'react';

import Button from 'common/components/button/Button';
import DatePicker from 'common/components/datepicker/DatePicker';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';

import * as moment from 'moment';
import { getUNIXTimeStampInMS } from 'common/utils/momentUtils';

import { usePostMachineMaintenance } from 'machines/hooks/usePostMachineInventoryMaintenance';

import * as styles from './MaintenanceRecordModal.module.scss';

interface IMaintenanceRecordModalProps {
  onClose: () => void;
  partInfo: {
    machineId: string;
    name: string;
    type: 'slot' | 'component';
  };
}

export function MaintenanceRecordModal({
  onClose,
  partInfo,
}: IMaintenanceRecordModalProps): JSX.Element {
  const { mutate: updateMaintenanceDate, isLoading } =
    usePostMachineMaintenance();
  const [maintenanceDate, setMaintenanceDate] = useState(moment());
  const [note, setNote] = useState<string>('');

  return (
    <Modal contentClassName={styles.MaintenanceRecordModal}>
      <div className={styles.Header}>
        <Typography
          type='headline-6'
          translationKey='label_maintenance_record'
        />
      </div>
      <DatePicker
        label='label_maintenance_on'
        date={maintenanceDate}
        id='maintenanceDatePicker'
        placeholder='label_maintenance_on'
        onDateChange={setMaintenanceDate}
        maxFutureDays={0}
        className={styles.DatePicker}
      />
      <Input
        label='label_notes'
        onChange={(value) => setNote(value)}
        value={note}
        type='text'
      />
      <div className={styles.ButtonGroup}>
        <Button
          type='secondary'
          translationKey='action_cancel'
          onClick={onClose}
          disabled={isLoading}
        />
        <Button
          type='primary'
          translationKey='action_update'
          onClick={() => {
            const maintenanceDateCopy = moment(maintenanceDate);
            maintenanceDateCopy.millisecond(0);
            maintenanceDateCopy.second(0);
            maintenanceDateCopy.minute(0);
            maintenanceDateCopy.hour(0);

            updateMaintenanceDate(
              {
                machineId: partInfo.machineId,
                type: partInfo.type,
                body: {
                  name: partInfo.name,
                  newMaintainDate: getUNIXTimeStampInMS(maintenanceDateCopy),
                  note: note,
                },
              },
              { onSuccess: onClose }
            );
          }}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
}

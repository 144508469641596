import * as React from "react";
import { Column } from "@tanstack/react-table";
import { clsx } from "clsx";
import FormControl from "@mui/material/FormControl";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export function MinMaxInputFilter<T>({
  column,
  className,
  onChange,
}: {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (e: [number | undefined, number | undefined]) => void;
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2.filter.min_max_filter",
  });
  const [minMaxState, setMinMaxState] = React.useState<
    [number | undefined, number | undefined]
  >([undefined, undefined]);

  React.useEffect(() => {
    setMinMaxState(
      Array.isArray(column.getFilterValue())
        ? [column.getFilterValue()[0], column.getFilterValue()[1]]
        : [undefined, undefined]
    );
  }, [column.getFilterValue()]);

  return (
    <div className="font-kanit w-full flex gap-3">
      <FormControl variant="standard">
        <TextField
          size="small"
          type="number"
          id={`${column.id}-min`}
          className={clsx("!font-kanit !font-light !text-on-surface-high", className)}
          value={isNotNullOrUndefined(minMaxState[0]) ? Number(minMaxState[0]) : ""}
          placeholder={t("label_min")}
          onChange={(e) => {
            // Update the minimum value of the filter
            if (e.target.value === "") {
              const value = [undefined, minMaxState[1]] as [
                number | undefined,
                number | undefined,
              ];
              setMinMaxState(value);
              column.setFilterValue(value);
              onChange?.(value as [number, number]);
            } else {
              const minValue = Number(e.target.value);
              const maxValue = isNotNullOrUndefined(minMaxState[1])
                ? Number(minMaxState[1])
                : undefined;
              const value = [minValue, maxValue] as [
                number | undefined,
                number | undefined,
              ];

              // update client state
              setMinMaxState(value);
              column.setFilterValue(value);
              onChange?.(value as [number, number]);
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "") return;
            // Adjust the minimum value if it is greater than the maximum value
            const minValue =isNotNullOrUndefined(e.target.value) ? Number(e.target.value): undefined;
            const maxValue = isNotNullOrUndefined(minMaxState[1]) ? Number(minMaxState[1]): undefined;
            const adjustedMaxValue = minValue > maxValue ? minValue : maxValue;
            const value = [minValue, adjustedMaxValue] as [
              number | undefined,
              number | undefined,
            ];

            // update client state
            setMinMaxState(value);
            column.setFilterValue(value);
            onChange?.(value as [number, number]);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">฿</InputAdornment>,
            className: "!font-kanit !font-light"
          }}
        />
      </FormControl>
      <div className="w-5 flex justify-center items-center">-</div>
      <FormControl variant="standard">
        <TextField
          size="small"
          type="number"
          id={`${column.id}-max`}
          className={clsx("!font-kanit", className)}
          value={isNotNullOrUndefined(minMaxState[1]) ? Number(minMaxState[1]) : ""}
          placeholder={t("label_max")}
          onChange={(e) => {
            // Update the maximum value of the filter
            if (e.target.value === "") {
              const value = [minMaxState[0], undefined] as [number, number];
              setMinMaxState(value);
              column.setFilterValue(value);
              onChange?.(value as [number, number]);
            } else {
              const minValue = Number(minMaxState[0]) || 0;
              const maxValue = Number(e.target.value);
              const value = [minValue, maxValue] as [number, number];
              // update client state
              setMinMaxState(value);
              column.setFilterValue(value);
              onChange?.(value as [number, number]);
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "") return;
            // Adjust the maximum value if it is less than the minimum value
            const minValue = isNotNullOrUndefined(minMaxState[0]) ? Number(minMaxState[0]): undefined;
            const maxValue = isNotNullOrUndefined(e.target.value) ? Number(e.target.value): undefined;
            const adjustedMinValue = maxValue < minValue ? maxValue : minValue;
            const value = [adjustedMinValue, maxValue] as [number, number];
            // update client state
            setMinMaxState(value);
            column.setFilterValue(value);
            onChange?.(value as [number, number]);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">฿</InputAdornment>,
            className: "!font-kanit !font-light"
          }}
        />
      </FormControl>
    </div>
  );
}


function isNotNullOrUndefined(value) {
  return value !== null && value !== undefined;
}

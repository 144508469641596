import * as React from 'react';

import VMWaterImage from 'common/images/VMWater.png';

interface IVMWaterProps {
  className?: string
}

function VMWater ({
  className
}: IVMWaterProps): JSX.Element {
  return (
    <img className={className} src={VMWaterImage} />
  );
}

export default VMWater;

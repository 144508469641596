import * as React from 'react';
import { View } from '@react-pdf/renderer';
import { Text } from 'pdf/components/primitives/Text';
import { COLORS } from 'pdf/constants';

interface FormFieldProps {
  label: string;
  numberOfDots: number;
  numberOfLines?: number; // Needed because dots will have hyphenation when wrap - another method is to use flex wrap but this reduces performance considerably
  type: 'date' | 'text';
}

export function FormField({
  label,
  numberOfDots,
  type,
  numberOfLines = 1,
}: FormFieldProps): JSX.Element {
  const dotsStyle = {
    fontFamily: 'Kanit-light',
    fontSize: 16,
    lineHeight: 24 / 16,
    color: COLORS.onSurfaceMedium,
    letterSpacing: 0,
  };

  return (
    <View>
      <Text
        translate
        style={{
          fontFamily: 'Kanit-regular',
          fontSize: 12,
          lineHeight: 16 / 12,
          letterSpacing: 0.4,
          color: COLORS.onSurfaceMedium,
          marginBottom: 8,
        }}
      >
        {label}
      </Text>
      {type === 'date' && (
        <Text style={dotsStyle}>
          {type === 'date' && ['', '/', '/', ''].join('.'.repeat(numberOfDots))}
        </Text>
      )}

      {type === 'text' &&
        new Array(numberOfLines).fill('').map((_,index) => (
          <Text style={dotsStyle} key={index}>
            {'.'.repeat(numberOfDots)}
          </Text>
        ))}
    </View>
  );
}

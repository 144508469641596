import { SortingState, TableV2 } from "components/tableV2";
import * as React from "react";
import { DailySalesGroupByErrorFormattedData } from "./mapDataToTable";
import { useGenerateColumnConfig } from "./generateColumnConfig";

type TransactionGroupByErrorTableProps = {
  name: string;
  isLoading: boolean;
  data: DailySalesGroupByErrorFormattedData[];
  dateRange: DateRange;
};

export type DateRange = {
  from: number;
  to: number;
};

export function TransactionGroupByErrorTable({
  name,
  isLoading,
  data,
  dateRange,
}: TransactionGroupByErrorTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "count", desc: true },
  ]);

  const columns = useGenerateColumnConfig(dateRange);

  return (
    <TableV2
      tableName={name}
      data={data}
      columns={columns}
      isLoading={isLoading}
      manualFiltering={false}
      manualSorting={false}
      sorting={sorting}
      onSortingChange={(state) => setSorting(state)}
    />
  );
}

import { useHistory, useLocation } from "react-router-dom";

export type SortDir = "asc" | "desc";

export function useSortQueryParam() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sortCol = searchParams.get("sort_col");
  const sortDir = searchParams.get("sort_dir") as SortDir | undefined;

  const history = useHistory();

  function toggleSort({
    col,
    firstDirection = "asc",
  }: {
    col: string;
    firstDirection?: SortDir;
  }) {
    if (sortCol !== col) {
      searchParams.set("sort_col", col);
      searchParams.set("sort_dir", firstDirection);
    } else if (firstDirection === sortDir) {
      searchParams.set("sort_dir", sortDir === "asc" ? "desc" : "asc");
    } else {
      searchParams.delete("sort_col");
      searchParams.delete("sort_dir");
    }
    history.push({
      search: searchParams.toString(),
    });
  }

  return [{ sortCol, sortDir }, toggleSort] as const;
}

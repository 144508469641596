import * as React from "react";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";
import Button from "components/Button";
import { FallbackProps } from "react-error-boundary";

import axios from "axios";

import * as styles from "./FallbackUI.module.scss";

function FallbackUI({ error }: FallbackProps): JSX.Element {
  if (axios.isAxiosError(error) && error?.code === "ECONNABORTED") {
    return null;
  }

  return (
    <div className={styles.FallbackUI}>
      <Icon className={styles.icon} name="Info" color="error" />
      <div className={styles.title}>
        <Typography translate type="headline-6">
          label_something_went_wrong
        </Typography>
      </div>
      <div className={styles.information}>
        <Typography translate type="subtitle-1">
          label_notes_for_developer
        </Typography>
        <Typography type="subtitle-1">{`: ${error.message}`}</Typography>
      </div>
      <Button
        className={styles.button}
        type="primary"
        onClick={() => window.location.reload()}
      >
        {"action_reload_page"}
      </Button>
    </div>
  );
}

export { FallbackUI };

import * as React from "react";
import TextButton from "common/components/textbutton/TextButton";
import { httpClient } from "common/services/transportService";
import { downloadCSVFromNetworkRequest } from "utils/csv";
import { toasti18n } from "utils/toast";
import * as moment from "moment";
import { useTranslation } from "react-i18next";

function useExportContractCSV() {
  const [isLoading, setIsLoading] = React.useState(false);

  async function download() {
    setIsLoading(true);
    try {
      await downloadCSVFromNetworkRequest(async () => {
        const response = await httpClient.get("/contract/download");
        return response.data;
      }, `contract-${moment().format("DD-MM-YYYY_HH-mm")}`);
      toasti18n.success();
      setIsLoading(false);
    } catch (err: unknown) {
      toasti18n.error(err as Error);
      setIsLoading(false);
    }
  }

  return { download, isLoading };
}

export function ExportCSVButton() {
  const { t } = useTranslation();
  const { download, isLoading } = useExportContractCSV();
  return (
    <TextButton
      loading={isLoading}
      text={t("label_export_csv")}
      onClick={download}
      icon="DownloadTray"
    />
  );
}

import * as React from 'react';
import { useEffect } from 'react';

// Library hooks
import { useLocation, Link } from 'react-router-dom';

// Custom components
import Typography from 'common/components/typography/Typography';
import Icon from 'common/components/icon/Icon';

// Custom utils
import { generateLinkToPage } from '../utils/generateLinkToPage';
import { generatePageRange } from '../utils/generatePageRange';

// Styles
import * as styles from './Paginator.module.scss';

interface IPaginator {
  metaData: { currentPage: number; totalPages: number };
  queryObject: { page: string };
}

export function Paginator ({ metaData, queryObject }: IPaginator): JSX.Element {
  const { pathname } = useLocation();

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [queryObject.page]);

  // When user view page that is out of range or when there's no data
  // Do not show pagination
  if (metaData.currentPage > metaData.totalPages) {
    return null;
  }

  // Generate page range
  const pageRange = generatePageRange(
    metaData.currentPage,
    metaData.totalPages
  );
  // Append path and active flag to each page
  const pageRangeWithLinksAndActiveFlag = pageRange.map(page => {
    const fullPath = generateLinkToPage(page, queryObject, pathname);
    return {
      pageNumber: page,
      active: metaData.currentPage === page,
      to: fullPath
    };
  });

  // Generate links for icons
  const linkToFirstPage = generateLinkToPage(
    1,
    queryObject,
    pathname
  );
  const linkToPreviousPage = generateLinkToPage(
    metaData.currentPage - 1 < 1 ? 1 : metaData.currentPage - 1,
    queryObject,
    pathname
  );
  const linkToNextPage = generateLinkToPage(
    metaData.currentPage + 1 > metaData.totalPages
      ? metaData.totalPages
      : metaData.currentPage + 1,
    queryObject,
    pathname
  );
  const linkToLastPage = generateLinkToPage(
    metaData.totalPages,
    queryObject,
    pathname
  );

  return (
    <div className={styles.Paginator}>

      {/* Back icons */}
      <Link to={linkToFirstPage}>
        <Icon
          className={styles.PaginationIconFirstPage}
          name="PaginationDoubleChevron"
          color="onSurfaceMedium"
        />
      </Link>
      <Link to={linkToPreviousPage}>
        <Icon
          className={styles.PaginationIconPreviousPage}
          name="PaginationChevron"
          color="onSurfaceMedium"
        />
      </Link>

      {/* List of pages */}
      {pageRangeWithLinksAndActiveFlag.map(page => (
        <Link
          key={page.pageNumber}
          to={page.to}
          className={[ styles.Page, page.active ? styles.PageActive : '' ].join(
            ' '
          )}
        >
          <Typography type="overline" text={page.pageNumber} />
        </Link>
      ))}

      {/* Foward icons */}
      <Link to={linkToNextPage}>
        <Icon
          className={styles.PaginationIconNextPage}
          name="PaginationChevron"
          color="onSurfaceMedium"
        />
      </Link>
      <Link to={linkToLastPage}>
        <Icon
          className={styles.PaginationIconLastPage}
          name="PaginationDoubleChevron"
          color="onSurfaceMedium"
        />
      </Link>
    </div>
  );
}

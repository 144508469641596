import * as React from "react";
import { InsuranceFee } from "../../hooks/useGetLocationFee";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";


export function InsuranceFeeView({ insuranceFee }: { insuranceFee: InsuranceFee; }) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_insurance_fee
      </Typography>
      <div style={{ height: "20px" }} />

      <TextFieldViewOnly
        label="label_amount"
        value={insuranceFee.value.toLocaleString()} />
    </div>
  );
}

export type InsuranceFeeFormValue = InsuranceFee;

type InsuranceFeeFormProps = {
  onChange: (value: InsuranceFeeFormValue) => void;
  value: InsuranceFeeFormValue;
};

export function mapInsuranceFeeToInsuranceFeeFormValue(insuranceFee: InsuranceFee): InsuranceFeeFormValue {
  return insuranceFee;
}

export function initializeInsuranceFeeFormValue(): InsuranceFeeFormValue {
  return {
    value: "",
  };
}

export function InsuranceFeeForm({ value, onChange }: InsuranceFeeFormProps) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_insurance_fee
      </Typography>
      <div style={{ height: "20px" }} />

      <TextField
        label="label_amount"
        value={value.value}
        onChange={(e) => {
          onChange({
            ...value,
            value: e.target.value,
          });
        }} />
    </div>
  );
}
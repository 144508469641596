import { omit } from 'lodash';

import { IAction } from 'common/interfaces';

import { IMachineData } from 'machines/interfaces';

interface IMachineListState {
  [machineId: string]: IMachineData
}

const initialState: IMachineListState = {};

function machineListReducer (
  state: IMachineListState = initialState,
  action: IAction
): IMachineListState {
  const [ domain, request, result ] = action.type.split('/');

  if (domain === 'MACHINES') {
    if (request.includes('GET_') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
    if (request === 'CREATE' && result === 'SUCCESS') {
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    }
    if (request.includes('PATCH_') && result === 'SUCCESS') {
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    }
  }

  if (action.type === 'MACHINES/DELETE/SUCCESS') {
    return omit(state, [action.payload.id]);
  }

  return state;
}

export default machineListReducer;

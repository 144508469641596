import * as React from "react";

interface IRefreshAlertProps {
  color?: string;
  className?: string;
}

function RefreshAlert({ color = "white", className }: IRefreshAlertProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33331 4.66683H4.12869C5.03567 3.38596 6.40423 2.66683 7.99998 2.66683C10.9455 2.66683 13.3333 5.05464 13.3333 8.00016H14.6666C14.6666 4.31826 11.6819 1.3335 7.99998 1.3335C6.13049 1.3335 4.48338 2.12184 3.33331 3.51373V2.66683H1.99998V6.00016H5.33331V4.66683ZM10.6666 11.3335H11.8713C10.9643 12.6144 9.59573 13.3335 7.99998 13.3335C5.05446 13.3335 2.66665 10.9457 2.66665 8.00016H1.33331C1.33331 11.6821 4.31808 14.6668 7.99998 14.6668C9.86947 14.6668 11.5166 13.8785 12.6666 12.4866V13.3335H14V10.0002H10.6666V11.3335ZM8.0002 10.6657C8.36851 10.6657 8.66708 10.3673 8.66708 9.99906C8.66708 9.63087 8.36851 9.33239 8.0002 9.33239C7.63189 9.33239 7.33331 9.63087 7.33331 9.99906C7.33331 10.3673 7.63189 10.6657 8.0002 10.6657ZM8.66905 4.66573H7.33528V8.66573H8.66905V4.66573Z"
        fill={color}
      />
    </svg>
  );
}

export default RefreshAlert;

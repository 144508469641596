import {
  useGetProductId,
  useGetWarehouseId,
  warehousingRoutes,
} from "containers/warehousing/routes";
import * as React from "react";
import { useGetWarehouseById } from "../WarehouseOverview/use-get-warehouse";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import { WarehousingNavigation } from "containers/warehousing/components/WarehousingNavigation";
import { useGetProductBybyId } from "./use-get-product";
import { warehouseHorizontalPadding } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import Typography from "components/Typography/Typography";
import { Skeleton } from "@mui/material";
import { InventoryImage } from "containers/warehousing/components/InventoryImage";
import { useGetProductLotsByWarehouseId } from "./use-get-product-lots";
import { Table } from "containers/warehousing/components/Table";
import { TableHeader } from "components/TableHeader";
import { AddNewWarehouseLotModalButton } from "./AddNewWarehouseLot";
import * as moment from "moment";
import * as colors from "common/styles/colors.scss";
import { WithdrawLotModalButton } from "./WithdrawStockModal";
import { EditLotModalButton } from "./EditLotModal";
import { useTranslation } from "react-i18next";

export function WarehouseIdWithProductId() {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WarehousingNavigation />
      <ErrorBoundary fallback={Fallback}>
        <WarehouseIdWithProductIdBody />
      </ErrorBoundary>
    </div>
  );
}

function WarehouseIdWithProductIdBody() {
  const warehouseId = useGetWarehouseId();
  const { data: warehouse, isLoading: areWarehousesLoading } =
    useGetWarehouseById(warehouseId);
  const productId = useGetProductId();
  const { data: product, isLoading: isProductLoading } =
    useGetProductBybyId(productId);

  const isLoading = isProductLoading || areWarehousesLoading;

  return (
    <>
      <WarehousingHeader
        title={product?.name}
        isLoading={isLoading}
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
            link: warehousingRoutes.warehouses(),
          },
          {
            label: warehouse?.name,
            link: warehousingRoutes.warehousesWithWarehouseId(warehouseId),
          },
          {
            label: product?.name,
          },
        ]}
        buttons={
          <div
            style={{
              display: "flex",
              gap: "27px",
              alignItems: "center",
            }}
          >
            {isProductLoading ? null : (
              <>
                <AddNewWarehouseLotModalButton
                  productId={productId}
                  warehouseId={warehouseId}
                />
                <WithdrawLotModalButton
                  productId={productId}
                  warehouseId={warehouseId}
                />
              </>
            )}
          </div>
        }
      />
      <div style={{ flex: "1 1 0px", minHeight: "0px", background: "white" }}>
        <ErrorBoundary fallback={Fallback}>
          <ProductIdPage />
        </ErrorBoundary>
      </div>
    </>
  );
}

function ProductIdPage() {
  return (
    <div
      style={{
        padding: `25px ${warehouseHorizontalPadding}`,
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <div style={{ height: "100%", display: "flex", gap: "26px" }}>
        <div style={{ flex: "0 0 181px" }}>
          <ProductDetails />
        </div>
        <div style={{ flex: "1 0 0px", minWidth: "0px" }}>
          <ErrorBoundary fallback={Fallback}>
            <ProductLotTable />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

function ProductLotTable() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetProductLotsByWarehouseId({
    warehouseId: useGetWarehouseId(),
    productId: useGetProductId(),
  });

  const sortedData = data?.sort(
    (lotA, lotB) => Number(lotB.lotNo) - Number(lotA.lotNo)
  );

  return (
    <Table
      data={sortedData}
      getKey={(lot) => lot.id}
      isLoading={isLoading}
      columns={[
        {
          id: "lotNo",
          headerCell: () => <TableHeader>{t("label_lot_no")}</TableHeader>,
          cell: (lot) => (
            <Typography type="body-2" color="primary500">
              {lot.lotNo}
            </Typography>
          ),
          style: {
            display: "flex",
            alignItems: "center",
            flex: "0 0 50px",
            minWidth: "50px",
          },
        },

        {
          id: "lotId",
          headerCell: () => <TableHeader>{t("label_lot_id")}</TableHeader>,
          cell: (lot) => (
            <Typography type="body-2" color="primary500">
              {lot.lotId.slice(-6)}
            </Typography>
          ),
          style: {
            display: "flex",
            alignItems: "center",
            flex: "0 0 80px",
            minWidth: "80px",
          },
        },

        {
          id: "stockDate",
          headerCell: () => <TableHeader>{t("label_stock_date")}</TableHeader>,
          cell: (lot) => (
            <Typography type="body-2">
              {moment(lot.receivedDate).format("DD/MM/YYYY")}
            </Typography>
          ),
          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 80px",
            minWidth: "80px",
          },
        },

        {
          id: "location",
          headerCell: () => <TableHeader>{t("label_location")}</TableHeader>,
          cell: () => <>-</>,
          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 67px",
            minWidth: "67px",
          },
        },
        {
          id: "purchaser",
          headerCell: () => <TableHeader>{t("label_purchaser")}</TableHeader>,
          cell: () => <>-</>,
          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 130px",
            minWidth: "130px",
          },
        },
        {
          id: "supplier",
          headerCell: () => <TableHeader>{t("label_supplier")}</TableHeader>,
          cell: () => <>-</>,
          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 65px",
            minWidth: "65px",
          },
        },
        {
          id: "inStock",
          headerCell: () => <TableHeader>{t("label_in_stock")}</TableHeader>,
          cell: (lot) => (
            <div
              style={{
                fontFamily: "Kanit",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              {lot.quantity}
            </div>
          ),
          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 52px",
            minWidth: "52px",
          },
        },
        {
          id: "expirationDate",
          headerCell: () => <TableHeader>{t("label_expiration")}</TableHeader>,
          cell: (lot) => {
            const date = moment(lot.expiryDate);
            const isExpired = date.isBefore(moment());
            const daysTillExpiry = date.diff(moment(), "days");

            let urgency: "HIGH" | "MEDIUM" | "LOW";

            if (daysTillExpiry >= 60) {
              urgency = "LOW";
            } else if (daysTillExpiry > 14 && daysTillExpiry < 60) {
              urgency = "MEDIUM";
            } else if (daysTillExpiry <= 14) {
              urgency = "HIGH";
            }

            const colorMap = {
              HIGH: colors.error,
              MEDIUM: colors.warning,
              LOW: colors.success,
            };

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginLeft: "-16px",
                }}
              >
                <div
                  style={{
                    backgroundColor: colorMap[urgency],
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <div>
                    <Typography type="body-2">
                      {moment(lot.expiryDate).format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                  <div>
                    {urgency === "LOW" ? null : (
                      <Typography
                        type="caption"
                        color={
                          urgency === "HIGH" ? "error" : "onSurfaceDisabled"
                        }
                        translate
                      >
                        {(urgency === "HIGH" && !isExpired) ||
                        urgency === "MEDIUM"
                          ? ["label_in", ` ${daysTillExpiry} `, "label_day"]
                          : null}
                        {isExpired ? "label_expired" : null}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            );
          },

          style: {
            display: "flex",
            alignItems: "center",
            flex: "1 0 92px",
            minWidth: "92px",
          },
        },
        {
          id: "editLot",
          headerCell: () => <></>,
          style: {
            flex: "0 0 24px",
            minWidth: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          cell: (lot) => (
            <EditLotModalButton
              lotId={lot.id}
              initialData={{
                expiryDate: lot.expiryDate,
                quantity: lot.quantity,
                receivedDate: lot.receivedDate,
              }}
            />
          ),
        },
      ]}
    />
  );
}

function ProductDetails() {
  const productId = useGetProductId();
  const { data: product, isLoading: isProductLoading } =
    useGetProductBybyId(productId);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100%",
      }}
    >
      <InventoryImage
        width="181px"
        height="181px"
        imgUrl={product?.image}
        isLoading={isProductLoading}
      />

      <div
        style={{
          backgroundColor: "#F3F3FD",
          borderRadius: "3px",
          padding: "6px 12px",
          flex: "1 0 0",
          display: "flex",
          flexDirection: "column",
          minHeight: "0px",
        }}
      >
        <Typography type="subtitle-2" translate>
          label_product_details
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "5px",
            margin: "5px -12px -6px -12px",
            padding: "0px 12px 6px 12px",
            overflow: "auto",
          }}
        >
          <ProductDetailItem
            itemName="label_name"
            itemValue={product?.name}
            isLoading={isProductLoading}
          />
          <ProductDetailItem
            itemName="label_sku"
            itemValue={product?.SKU}
            isLoading={isProductLoading}
          />
          <ProductDetailItem
            itemName="label_package"
            itemValue={`${product?.package} / ${product?.packageType} ${product?.uom}`}
            isLoading={isProductLoading}
          />
          <ProductDetailItem
            itemName="label_inventory_code"
            itemValue={product?.inventoryCode}
            isLoading={isProductLoading}
          />
          <ProductDetailItem
            itemName="label_brand"
            itemValue={product?.manufacturer}
            isLoading={isProductLoading}
          />
        </div>
      </div>
    </div>
  );
}

function ProductDetailItem({
  itemName,
  itemValue,
  isLoading = false,
}: {
  itemName: string;
  itemValue: string;
  isLoading?: boolean;
}) {
  return (
    <div>
      <div>
        <Typography type="caption" translate color="onSurfaceMedium">
          {itemName}
        </Typography>
      </div>
      <Typography type="body-1">
        {isLoading ? <Skeleton /> : itemValue}
      </Typography>
    </div>
  );
}

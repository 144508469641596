import * as React from 'react';

import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from 'common/components/typography/Typography';

import Table, { IColumn } from 'common/components/table/Table';
import TextButton from 'common/components/textbutton/TextButton';
import AddMachineUserModal from './Modals/AddMachineUserModal/AddMachineUserModal';
import RemoveMachineUserModal from './Modals/removeMachineUserModal/RemoveMachineUserModal';

import { StringCell } from 'common/components/table/cells/Cells';
import { MemberIconCell } from './table/cells/MemberIconCell';
import { useGetAllMachineUsers } from 'machines/hooks/useGetAllMachineUsers';
import { useGetCurrentUser } from 'userandauth/hooks/useGetCurrentUser';

import * as styles from './Members.module.scss';
import { useIsAdmin, isAdminRole, isAdminContractRole, useIsAdminContract } from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

function Members(): JSX.Element {
  const cannotViewContent = !(useIsAdmin() || useIsAdminContract());
  const canAddUser = useIsAdmin() || useIsAdminContract();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const { machineId }: { machineId: string } = useParams();
  const { data: currentUserInfo } = useGetCurrentUser();
  const { data: machineUsers } = useGetAllMachineUsers(machineId);
  const [modalOpen, setModalOpen] = useState<IMemberModal>('none');
  const [user, setUser] = useState<IUpdateUserData>(null);
  const tableData: Array<IUpdateUserData> = machineUsers
    ?.map((eachUser) => {
      return {
        name: eachUser.firstName,
        email: eachUser.email,
        role: eachUser.role,
        id: eachUser.id,
      };
    })
    .sort((a, b) => a.email.localeCompare(b.email));

  const onClickRemoveUser = (user: IUpdateUserData) => {
    setUser(user);
    setModalOpen('removeMember');
  };

  const onClickInvite = () => {
    setModalOpen('addMember');
  };

  const onCancelModal = () => {
    setModalOpen('none');
  };

  const adminColumns: IColumn[] = [
    {
      dataKey: 'name',
      headerLabel: 'label_name',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'email',
      headerLabel: 'label_email',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'role',
      headerLabel: 'label_role',
      columnFlex: 1,
      cellRenderer: StringCell,
      sortable: true,
    },
    {
      headerLabel: '',
      cellRenderer: MemberIconCell({
        onClick: onClickRemoveUser,
        icon: 'TrashInventoryV2',
      }),
      columnFlex: 0.2,
    },
  ];

  const columns: IColumn[] = [
    {
      dataKey: 'name',
      headerLabel: 'label_name',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'email',
      headerLabel: 'label_email',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'role',
      headerLabel: 'label_role',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
  ];

  return (
    <div className={styles.Members}>
      {modalOpen === 'addMember' && (
        <AddMachineUserModal onClose={onCancelModal} />
      )}
      {modalOpen === 'removeMember' && (
        <RemoveMachineUserModal onClose={onCancelModal} userData={user} />
      )}
      <div className={styles.header}>
        <div className={styles.left}>
          <Typography type="headline-5" translationKey="label_member" />
        </div>
        <div className={styles.right}>
          {canAddUser && (
            <TextButton
              className={styles.customiseTextButton}
              icon="Plus"
              onClick={onClickInvite}
              translationKey="label_add_user"
            />
          )}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table
          defaultSortColumn="role"
          defaultSortDirection="desc"
          data={tableData}
          columns={isAdminRole(currentUserInfo.role) || isAdminContractRole(currentUserInfo.role) ? adminColumns : columns}
        />
      </div>
    </div>
  );
}

export default Members;

type IMemberModal = 'removeMember' | 'addMember' | 'none';

export interface IUpdateUserData {
  name: string;
  email: string;
  role: string;
  id: string;
}

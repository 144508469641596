import * as React from "react";
import { View } from "@react-pdf/renderer";
import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

interface InfoTableProps {
  label: string;
  tableData: Array<{ label: string | string[]; value: string | undefined }>;
}

export function InfoTable({ label, tableData }: InfoTableProps): JSX.Element {
  return (
    <View>
      <View
        style={{
          borderBottom: 2,
          borderColor: COLORS.primary500,
          borderStyle: "solid",
          marginBottom: 19
        }}
      >
        <Text
          translate
          style={{
            fontFamily: "Kanit-light",
            fontSize: 8,
            letterSpacing: 0.25,
            color: COLORS.onSurfaceHigh,
            paddingLeft: 5
          }}
        >
          {label}
        </Text>
      </View>
      <View>
        {tableData.map(row => (
          <InfoTableRow key={row.value} label={row.label} value={row.value} />
        ))}
      </View>
    </View>
  );
}

interface InfoTableRowProps {
  label: string | string[];
  value: string | undefined;
}

function InfoTableRow({ label, value }: InfoTableRowProps): JSX.Element {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <View style={{ minWidth: 0, flexBasis: 55 }}>
        <Text
          translate
          style={{
            paddingLeft: 5,
            fontFamily: "Kanit-regular",
            fontSize: 8,
            color: COLORS.onSurfaceMedium
          }}
        >
          {Array.isArray(label) ? label.join("#,#").split("#") : label}
          {":"}
        </Text>
      </View>
      <View style={{ flexGrow: 1, minWidth: 0, flexBasis: 0 }}>
        {value && (
          <Text
            breakWordAnywhere
            style={{
              fontFamily: "Kanit-regular",
              fontSize: 8,
              color: COLORS.onSurfaceMedium
            }}
          >
            {value}
          </Text>
        )}
      </View>
    </View>
  );
}

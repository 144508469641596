import * as React from 'react';

interface IInventoryReportProps {
  color?: string;
  className?: string;
}

function InventoryReport({
  color = 'white',
  className,
}: IInventoryReportProps): JSX.Element {
  return (
    <svg
      className={className}
      width='18'
      height='22'
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 0H12.4142L18 5.58579V20C18 21.1046 17.1046 22 16 22H2C0.89543 22 0 21.1046 0 20V2C0 0.89543 0.89543 0 2 0ZM10 2H2V20H16V8H12C10.8954 8 10 7.10457 10 6V2ZM12 2.41421V6H15.5858L12 2.41421ZM5 16V14H11V16H5ZM5 10V12H13V10H5Z'
        fill={color}
      />
    </svg>
  );
}

export default InventoryReport;

import * as React from "react";

interface IFlameProps {
  color?: string;
  className?: string;
}

function Flame({ color = "white", className }: IFlameProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.31316 9.60786C9.76853 8.96874 9.71771 8.29563 9.40196 7.08484C8.77748 4.69024 9.02614 3.37775 11.1789 1.82385L12.2297 1.06543L12.4838 2.33612C12.7539 3.68656 13.1974 4.52181 14.3363 6.12842C14.3815 6.19224 14.3815 6.19224 14.427 6.25634C16.0675 8.57073 16.6667 9.96591 16.6667 12.4996C16.6667 15.5732 13.5595 18.3329 9.99999 18.3329C6.44033 18.3329 3.33332 15.5736 3.33332 12.4996C3.33332 12.4421 3.33338 12.4438 3.32364 12.1899C3.24835 10.2261 3.61149 8.68903 5.08088 7.02944C5.39133 6.67879 5.74422 6.34178 6.14134 6.01931L7.018 5.30744L7.4397 6.35504C7.7513 7.12911 8.1164 7.73761 8.52883 8.18415C8.87814 8.56235 9.13727 9.03794 9.31316 9.60786ZM6.32872 8.13427C5.19758 9.41183 4.92866 10.5501 4.98909 12.1261C5.00034 12.4195 4.99999 12.4091 4.99999 12.4996C4.99999 14.6061 7.31965 16.6662 9.99999 16.6662C12.6801 16.6662 15 14.6058 15 12.4996C15 10.3814 14.5264 9.27875 13.0673 7.22014C13.0221 7.15637 13.0221 7.15637 12.9766 7.09226C12.0904 5.84215 11.5622 4.97254 11.2128 3.99999C10.6421 4.68387 10.6756 5.36405 11.0147 6.66427C11.6392 9.05887 11.3905 10.3714 9.23771 11.9253L8.01021 12.8113L7.91819 11.3002C7.8594 10.3348 7.64087 9.67918 7.30448 9.31496C6.97212 8.95512 6.67204 8.53384 6.40294 8.05188C6.37784 8.07927 6.35311 8.10673 6.32872 8.13427Z"
        fill={color}
      />
    </svg>
  );
}

export default Flame;

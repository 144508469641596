type ErrorCodeMappingType = {
  error_code: number;
  error_message_th: string | null;
  error_message_en: string | null;
  error_type_color: string;
  error_type: string;
}

export const errorCodeMapping: ErrorCodeMappingType[] = [
  {
    "error_code": 300,
    "error_message_en": "Motor Lid 1 Timeout",
    "error_message_th": "สวิตจ่าย 1 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 301,
    "error_message_en": "Motor straw 1 Timeout",
    "error_message_th": "สวิตจ่ายหลอดมีปัญหา 1",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 302,
    "error_message_en": "Motor straw 2 Timeout",
    "error_message_th": "สวิตจ่ายหลอดมีปัญหา 1",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 303,
    "error_message_en": "Motor Door Cup Timeout",
    "error_message_th": "ประตูรับแก้วมีปัญหา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 304,
    "error_message_en": "Motor Door Lid Timeout",
    "error_message_th": "ประตูรับฝามีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 305,
    "error_message_en": "LID Out Of stock",
    "error_message_th": "ฝาหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 306,
    "error_message_en": "Cup hot type empty",
    "error_message_th": "แก้วร้อนหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 307,
    "error_message_en": "Cup cold type empty",
    "error_message_th": "แก้วเย็นหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 308,
    "error_message_en": "Cup shot type empty",
    "error_message_th": "แก้วเล็กหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 309,
    "error_message_en": "Cup termtem empty",
    "error_message_th": "แก้วเต็มเต็มหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 310,
    "error_message_en": "Cup slot 1 switch timeout",
    "error_message_th": "แก้วช่อง 1 สวิต!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 311,
    "error_message_en": "Cup slot 2 switch timeout",
    "error_message_th": "แก้วช่อง 2 สวิต!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 312,
    "error_message_en": "Cup slot 3 switch timeout",
    "error_message_th": "แก้วช่อง 3 สวิต!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 313,
    "error_message_en": "Cup slot 4 switch timeout",
    "error_message_th": "แก้วช่อง 4 สวิต!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 314,
    "error_message_en": "Cup slot 5 switch timeout",
    "error_message_th": "แก้วช่อง 5 สวิต!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 315,
    "error_message_en": "Sensor CUP 1 error",
    "error_message_th": "Sensor cup 1 error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 316,
    "error_message_en": "Sensor CUP 2 error",
    "error_message_th": "Sensor cup 2 error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 317,
    "error_message_en": "Sensor CUP 3 error",
    "error_message_th": "Sensor cup 3 error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 318,
    "error_message_en": "Sensor CUP 4 error",
    "error_message_th": "Sensor cup 4 error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 319,
    "error_message_en": "Brew data timeout",
    "error_message_th": "Brew data timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 321,
    "error_message_en": "Full tank",
    "error_message_th": "ถังน้ำทิ้งเต็ม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 322,
    "error_message_en": "Start boilder",
    "error_message_th": "กำลังทำความร้อน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 323,
    "error_message_en": "Out of water",
    "error_message_th": "น้ำหมด",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 324,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 325,
    "error_message_en": "switch cover timeout",
    "error_message_th": "สวิตปล่อยฝาเสีย",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 326,
    "error_message_en": "Temperature sensor",
    "error_message_th": "เซนเซอร์อุณหภุมิเสีย",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 327,
    "error_message_en": "Flow sensor fail",
    "error_message_th": "เซนเซอร์วัดน้ำเสีย",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 328,
    "error_message_en": "On heater fail",
    "error_message_th": "ทำความร้อนมีปัญหา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 329,
    "error_message_en": "Pay Double cup",
    "error_message_th": "ปล่อยแก้วสองครั้ง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 330,
    "error_message_en": "Didn't pick up the cup.",
    "error_message_th": "ไม่ได้หยิบแก้ว",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 331,
    "error_message_en": "Falling Cup timeout",
    "error_message_th": "แก้วไม่ตก!",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 332,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 333,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 334,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 335,
    "error_message_en": "Espresso switch error",
    "error_message_th": "สวิตส่วนทำกาแฟมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 336,
    "error_message_en": "Make espreeso error ( No water)",
    "error_message_th": "ทำน้ำกาแฟมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 337,
    "error_message_en": "Obselete",
    "error_message_th": "Obselete",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 338,
    "error_message_en": null,
    "error_message_th": null,
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 339,
    "error_message_en": "Ice pay timeout",
    "error_message_th": "จ่ายน้ำแข็งไม่ได้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 340,
    "error_message_en": null,
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 341,
    "error_message_en": "Take a look at vending",
    "error_message_th": "รีบไปดููที่ตู้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 342,
    "error_message_en": "Take a look at vending",
    "error_message_th": "รีบไปดููที่ตู้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 343,
    "error_message_en": "Didn't pick up the cup",
    "error_message_th": "มีแก้วค้างในตู้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 344,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 345,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 346,
    "error_message_en": "Obselete",
    "error_message_th": "ของเก่า",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 347,
    "error_message_en": null,
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 348,
    "error_message_en": "weight scale abnormal",
    "error_message_th": "ชั่งน้ำหนักผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 349,
    "error_message_en": "Water input error",
    "error_message_th": "น้ำไม่เข้าแกน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 350,
    "error_message_en": null,
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 351,
    "error_message_en": "Motor Overload!",
    "error_message_th": null,
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 352,
    "error_message_en": "Ice make timeout 2 hour",
    "error_message_th": "ทำน้ำแข็งนานเกิน 2ชม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 353,
    "error_message_en": "Ice module Time out",
    "error_message_th": "Ice module Time out",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 354,
    "error_message_en": null,
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 355,
    "error_message_en": "Soda No Water",
    "error_message_th": "นำหมด( soda)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 356,
    "error_message_en": "Gas out",
    "error_message_th": "แก๊สหมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 357,
    "error_message_en": "Gas system probe error.",
    "error_message_th": "Probe",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 358,
    "error_message_en": "Soda Pay timeout",
    "error_message_th": "Soda ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 359,
    "error_message_en": "MIX1 Pay timeout",
    "error_message_th": "Mix 1 (M1)ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 360,
    "error_message_en": "MIX2 Pay timeout",
    "error_message_th": "Mix 2 (M2)ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 361,
    "error_message_en": "Gas Sensor unavailable",
    "error_message_th": "ไม่มีเซนเซอร์แก๊ส",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 362,
    "error_message_en": "Ice Weight Motor timeout",
    "error_message_th": "มอเตอร์กระบะน้ำแข็งมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 363,
    "error_message_en": "Ice board was reboot",
    "error_message_th": "บอร์ดน้ำแข็ง รีบูต",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 364,
    "error_message_en": "Ice water pump timeout",
    "error_message_th": "ปั๊มน้ำระบบทำน้ำแข็งหมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 365,
    "error_message_en": "Ice not enough",
    "error_message_th": "น้ำแข็งไม่พอขาย",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 366,
    "error_message_en": "Soda temperature abnormal",
    "error_message_th": "อุณหภูมิโซดาผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 367,
    "error_message_en": "soda water pump timeout",
    "error_message_th": "ปั๊มน้ำไม่เข้าถังคาร์บอเนต",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 368,
    "error_message_en": "Bean 3 pay timeout",
    "error_message_th": "เมล็ด 3 จ่ายไม่ได้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 369,
    "error_message_en": "Internal Error",
    "error_message_th": "Internal Error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 370,
    "error_message_en": "Powder  1 pay timeout",
    "error_message_th": "ผงช่อง 1 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 371,
    "error_message_en": "Powder  2 pay timeout",
    "error_message_th": "ผงช่อง 2 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 372,
    "error_message_en": "Powder  3 pay timeout",
    "error_message_th": "ผงช่อง 3 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 373,
    "error_message_en": "Powder  4 pay timeout",
    "error_message_th": "ผงช่อง 4 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 374,
    "error_message_en": "Powder  5 pay timeout",
    "error_message_th": "ผงช่อง 5 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 375,
    "error_message_en": "Powder  6 pay timeout",
    "error_message_th": "ผงช่อง 6 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 376,
    "error_message_en": "Powder  7 pay timeout",
    "error_message_th": "ผงช่อง 7 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 377,
    "error_message_en": "Powder  8 pay timeout",
    "error_message_th": "ผงช่อง 8 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 378,
    "error_message_en": "Powder  9 pay timeout",
    "error_message_th": "ผงช่อง 9 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 379,
    "error_message_en": "Powder 10 pay timeout",
    "error_message_th": "ผงช่อง 10 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 380,
    "error_message_en": "Powder 11 pay timeout",
    "error_message_th": "ผงช่อง 11 ตัน/หมด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 381,
    "error_message_en": "Syrup 1 Pay timeout",
    "error_message_th": "S1 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 382,
    "error_message_en": "Syrup 2 Pay timeout",
    "error_message_th": "S2 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 383,
    "error_message_en": "Syrup 3 Pay timeout",
    "error_message_th": "S3 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 384,
    "error_message_en": "Syrup 4 Pay timeout",
    "error_message_th": "S4 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 385,
    "error_message_en": "Syrup 5 Pay timeout",
    "error_message_th": "S5 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 386,
    "error_message_en": "Syrup 6 Pay timeout",
    "error_message_th": "S6 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 387,
    "error_message_en": "Syrup 7 Pay timeout",
    "error_message_th": "S7 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 388,
    "error_message_en": "Syrup 8 Pay timeout",
    "error_message_th": "S8 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 389,
    "error_message_en": "Syrup 9 Pay timeout",
    "error_message_th": "S9 ไม่จ่าย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 391,
    "error_message_en": "Self-test timeout",
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 392,
    "error_message_en": "Double cup detect",
    "error_message_th": "แก้ว น้ำหนัก มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 393,
    "error_message_en": "Internal error ( esp-rbt )",
    "error_message_th": null,
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 394,
    "error_message_en": "Make espresso abnormal",
    "error_message_th": "เอฟเพสโซผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 395,
    "error_message_en": "Self-test error",
    "error_message_th": "ทดสอบ error",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 396,
    "error_message_en": "Set Zero fail 2",
    "error_message_th": "เริ่มชั่งน้ำหนัก มีปัญหา แขน 2",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 397,
    "error_message_en": "Active weight 2",
    "error_message_th": "เริ่มชั่งน้ำหนัก มีปัญหา แขน 2",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 398,
    "error_message_en": "Set Zero fail 3",
    "error_message_th": "เริ่มชั่งน้ำหนัก มีปัญหา แขน 3",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 399,
    "error_message_en": "Active weight 3",
    "error_message_th": "เริ่มชั่งน้ำหนัก มีปัญหา แขน 3",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 450,
    "error_message_en": "Powder  1 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (1)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 451,
    "error_message_en": "Powder  2 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (2)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 452,
    "error_message_en": "Powder  3 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (3)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 453,
    "error_message_en": "Powder  4 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (4)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 454,
    "error_message_en": "Powder  5 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (5)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 455,
    "error_message_en": "Powder  6 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (6)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 456,
    "error_message_en": "Powder  7 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (7)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 457,
    "error_message_en": "Powder  8 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (8)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 458,
    "error_message_en": "Powder  9 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (9)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 459,
    "error_message_en": "Powder 10 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (10)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 460,
    "error_message_en": "Powder 11 move to pay timeout",
    "error_message_th": "จ่ายผงมีปัญหา (11)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 461,
    "error_message_en": "Arm move position timeout 1",
    "error_message_th": "แขนหมุนมีปัญหา (1)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 462,
    "error_message_en": "Arm move position timeout 2",
    "error_message_th": "แขนหมุนมีปัญหา (2)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 463,
    "error_message_en": "Arm move position timeout 3",
    "error_message_th": "แขนหมุนมีปัญหา (3)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 464,
    "error_message_en": "Arm move position timeout 4",
    "error_message_th": "แขนหมุนมีปัญหา (4)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 465,
    "error_message_en": "Arm move position timeout 5",
    "error_message_th": "แขนหมุนมีปัญหา (5)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 466,
    "error_message_en": "Arm move position timeout 6",
    "error_message_th": "แขนหมุนมีปัญหา (6)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 467,
    "error_message_en": "Arm move position timeout 7",
    "error_message_th": "แขนหมุนมีปัญหา (7)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 468,
    "error_message_en": "Arm move position timeout 8",
    "error_message_th": "แขนหมุนมีปัญหา (8)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 469,
    "error_message_en": "Top board whipper process timeout",
    "error_message_th": "หัวชงทำงานหมดเวลา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 470,
    "error_message_en": "Whipper position 1 timeout",
    "error_message_th": "หัวชงทำงานหมดเวลา (ตำแหน่ง 1)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 471,
    "error_message_en": "Whipper position 2 timeout",
    "error_message_th": "หัวชงทำงานหมดเวลา (ตำแหน่ง 2)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 472,
    "error_message_en": "Powder Tower Rotation by timeout",
    "error_message_th": "ชุดหมุนผงมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 473,
    "error_message_en": "Powder Tower Rotation  by position error",
    "error_message_th": "ชุดหมุนผงมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 474,
    "error_message_en": "Powder box to drive Position timeout",
    "error_message_th": "เกียร์จ่ายผงมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 475,
    "error_message_en": "Powder box to origin Position timeout",
    "error_message_th": "จเกียร์จ่ายผงมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 476,
    "error_message_en": "Weight of espresso shot Abnormal by time",
    "error_message_th": "เอสเพสโซ มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 477,
    "error_message_en": "Weight of espresso shot Abnormal by weight",
    "error_message_th": "เอสเพสโซ มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 478,
    "error_message_en": "Powder Unknown error",
    "error_message_th": "ชุดผงมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 479,
    "error_message_en": "Deactive Weight Scale abnormal",
    "error_message_th": "ชั่งน้ำหนักมีปัญหา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 480,
    "error_message_en": "Bean 1 pay timeout",
    "error_message_th": "เมล็ด 1 จ่ายไม่ได้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 481,
    "error_message_en": "Bean 2 pay timeout",
    "error_message_th": "เมล็ด 2 จ่ายไม่ได้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 482,
    "error_message_en": "Arm set zero 4 fail",
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 483,
    "error_message_en": "Active weight 4 fail",
    "error_message_th": null,
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 484,
    "error_message_en": "Top board whipper timeout",
    "error_message_th": "หัวชง move timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 485,
    "error_message_en": "Top board whipper move up timeout",
    "error_message_th": "หัวชง move timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 486,
    "error_message_en": "Top board whipper move down timeout",
    "error_message_th": "หัวชง move timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 487,
    "error_message_en": "Cup slot 1 Falling timeout",
    "error_message_th": "ช่อง 1 แก้วไม่ตก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 488,
    "error_message_en": "Cup slot 2 Falling timeout",
    "error_message_th": "ช่อง 2 แก้วไม่ตก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 489,
    "error_message_en": "Cup slot 3 Falling timeout",
    "error_message_th": "ช่อง 3 แก้วไม่ตก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 490,
    "error_message_en": "Cup slot 4 Falling timeout",
    "error_message_th": "ช่อง 4 แก้วไม่ตก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 491,
    "error_message_en": "Cup slot 5 Falling timeout",
    "error_message_th": "ช่อง 5 แก้วไม่ตก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 492,
    "error_message_en": "Front Door",
    "error_message_th": "บอร์ดประตู",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 493,
    "error_message_en": "Communication error",
    "error_message_th": "การสื่อสารมีปัญหา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 494,
    "error_message_en": "Set Zero fail 1",
    "error_message_th": "set zero fail 1",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 495,
    "error_message_en": "Active weight 1",
    "error_message_th": "Active weight 1",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 496,
    "error_message_en": "Module espresso error",
    "error_message_th": "ระบบกาแฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 497,
    "error_message_en": "Arm move time out",
    "error_message_th": "Arm move time out",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 498,
    "error_message_en": "Arm encoder error",
    "error_message_th": "Arm encoder error",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 499,
    "error_message_en": "Internal error (Drop cup)",
    "error_message_th": "Internal error (Drop cup)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1000,
    "error_message_en": "process pay ice timeout",
    "error_message_th": "การจ่ายน้ำแข็งหมดเวลา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1001,
    "error_message_en": "Cup on arm 2 or 4",
    "error_message_th": "มีแก้วแขน2หรือ4",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1002,
    "error_message_en": "Cup time out",
    "error_message_th": "ปล่อยแก้วหมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1003,
    "error_message_en": "process ice pay cup time out",
    "error_message_th": "การจ่ายน้ำแข็งหมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1004,
    "error_message_en": "Shutter Open.",
    "error_message_th": "ฝาเปิด(ชัดเตอร์)น้ำแข็ง มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1005,
    "error_message_en": "Shutter Close.",
    "error_message_th": "ฝาปิด(ชัดเตอร์)น้ำแข็ง มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1006,
    "error_message_en": "Weight scale arm 1",
    "error_message_th": "ชั่งน้ำหนักแขน 1 เกิน1kg",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1007,
    "error_message_en": "Weight scale arm 2 abnormal",
    "error_message_th": "ชั่งน้ำหนักแขน 2 เกิน1kg",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1008,
    "error_message_en": "Weight scale arm 3 abnormal",
    "error_message_th": "ชั่งน้ำหนักแขน 3 เกิน1kg",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1009,
    "error_message_en": "Weight scale arm 4 abnormal",
    "error_message_th": "ชั่งน้ำหนักแขน 4 เกิน1kg",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1010,
    "error_message_en": "Weight scale bean abnormal",
    "error_message_th": "ชั่งน้ำหนักเมล็ดกาแฟผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1011,
    "error_message_en": "Drop cup arm 1 error",
    "error_message_th": "ปล่อยแก้วลงแขน 1 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1012,
    "error_message_en": "Drop cup arm 2 error",
    "error_message_th": "ปล่อยแก้วลงแขน 2 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1013,
    "error_message_en": "Drop cup arm 3 error",
    "error_message_th": "ปล่อยแก้วลงแขน 3 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1014,
    "error_message_en": "Drop cup arm 4 error",
    "error_message_th": "ปล่อยแก้วลงแขน 4 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1015,
    "error_message_en": "CUP proc recovery error",
    "error_message_th": "การกู้คืนแก้วมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1016,
    "error_message_en": "CUP 1 recovery error",
    "error_message_th": "การกู้คืนแก้วหลุม 1 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1017,
    "error_message_en": "CUP 2 recovery error",
    "error_message_th": "การกู้คืนแก้วหลุม 2 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1018,
    "error_message_en": "CUP 3 recovery error",
    "error_message_th": "การกู้คืนแก้วหลุม 3 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1019,
    "error_message_en": "CUP 4 recovery error",
    "error_message_th": "การกู้คืนแก้วหลุม 4 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1020,
    "error_message_en": "CUP 5 recovery error",
    "error_message_th": "การกู้คืนแก้วหลุม 5 มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1021,
    "error_message_en": "Tray open timeout",
    "error_message_th": "ถาดชั่งเมล็ดเปิดมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1022,
    "error_message_en": "Tray close timeout",
    "error_message_th": "ถาดชั่งเมล็ดปิดมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1023,
    "error_message_en": "Shutter ICED",
    "error_message_th": "ชัตเตอร์น้ำแข็ง timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1024,
    "error_message_en": "Arm 1 falling cup no weight",
    "error_message_th": "แก้วไม่ตกแขน 1",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1025,
    "error_message_en": "Arm 2 falling cup no weight",
    "error_message_th": "แก้วไม่ตกแขน 2",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1026,
    "error_message_en": "Arm 3 falling cup no weight",
    "error_message_th": "แก้วไม่ตกแขน 3",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1027,
    "error_message_en": "Arm 4 falling cup no weight",
    "error_message_th": "แก้วไม่ตกแขน 4",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1028,
    "error_message_en": "Arm 1 falling cup over weight scale",
    "error_message_th": "แก้วซ้อนกัน แขน 1",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1029,
    "error_message_en": "Arm 2 falling cup over weight scale",
    "error_message_th": "แก้วซ้อนกัน แขน 2",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1030,
    "error_message_en": "Arm 3 falling cup over weight scale",
    "error_message_th": "แก้วซ้อนกัน แขน 3",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1031,
    "error_message_en": "Arm 4 falling cup over weight scale",
    "error_message_th": "แก้วซ้อนกัน แขน 4",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1032,
    "error_message_en": "GAS low",
    "error_message_th": "แก๊สใกล้หมด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1033,
    "error_message_en": "TOP board error",
    "error_message_th": "บอร์ดท๊อฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1034,
    "error_message_en": "System error 249",
    "error_message_th": "System error 249",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1035,
    "error_message_en": "ICE part error",
    "error_message_th": "ICE part error",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1036,
    "error_message_en": "Error number 4 pay timeout",
    "error_message_th": "Error number 4 pay timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1037,
    "error_message_en": "Error make espresso 8",
    "error_message_th": "Error make espresso 8",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1038,
    "error_message_en": "Error code 19",
    "error_message_th": "Error code 19",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1039,
    "error_message_en": "Whipper position 3 timeout",
    "error_message_th": "หัวชงทำงานหมดเวลา (ตำแหน่ง 3)",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1040,
    "error_message_en": "Error powder pay timeout",
    "error_message_th": "Error powder pay timeout",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1041,
    "error_message_en": "Arm 1 Move timeout",
    "error_message_th": "แขน 1 เคลื่อนที่ไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1042,
    "error_message_en": "Arm 2 Move timeout",
    "error_message_th": "แขน 2 เคลื่อนที่ไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1043,
    "error_message_en": "Arm 3 Move timeout",
    "error_message_th": "แขน 3 เคลื่อนที่ไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1044,
    "error_message_en": "Arm 4 Move timeout",
    "error_message_th": "แขน 4 เคลื่อนที่ไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1045,
    "error_message_en": "Whipper move brew timeout",
    "error_message_th": "การเคลื่อนที่ชงหมดเวลา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1046,
    "error_message_en": "Whipper move wash timeout",
    "error_message_th": "การเคลื่อนที่ล้างหัวชงหมดเวลา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1047,
    "error_message_en": "Error code 20 Whipper",
    "error_message_th": "Error code 20 Whipper",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1048,
    "error_message_en": "Error code 21 Set zero",
    "error_message_th": "Error code 21 Set zero",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1049,
    "error_message_en": "Error code 22 Calibration",
    "error_message_th": "Error code 22 Calibration",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1050,
    "error_message_en": "Error code 24 Syrup pay timeout",
    "error_message_th": "Error code 24 Syrup pay timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1051,
    "error_message_en": "Main gate open with out password",
    "error_message_th": "ประตูตู้เปิด ไม่ได้กรอกรหัส",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1052,
    "error_message_en": "Espresso upper time",
    "error_message_th": "อัดน้ำกาแฟนานเกิน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1053,
    "error_message_en": "Espresso lower time",
    "error_message_th": "อัดน้ำกาแฟเร็วเกิน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1054,
    "error_message_en": "Espresso switch powder",
    "error_message_th": "สวิตส่วนทำกาแฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1055,
    "error_message_en": "Espresso switch press",
    "error_message_th": "สวิตส่วนทำกาแฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1056,
    "error_message_en": "Cup Pay process timeout",
    "error_message_th": "ปล่อยแก้วหมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1057,
    "error_message_en": "Make espresso weight scale abnormal more flow",
    "error_message_th": "กาแฟขั่งน้ำหนักผิดปกติมาก",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1058,
    "error_message_en": "Make espresso weight scale  error more flow",
    "error_message_th": "กาแฟั่งน้ำหนักมีปัญหาแปลกมาก",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1059,
    "error_message_en": "Grinder encoder timeout",
    "error_message_th": "encoder เมล็ดกาแฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1060,
    "error_message_en": "Grinder Move timeout",
    "error_message_th": "เปลี่ยนความละเอียดเมล็ดกาแฟมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1061,
    "error_message_en": "Pay ICE fail",
    "error_message_th": "จ่ายน้ำแข็งมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1062,
    "error_message_en": "TID duplicate",
    "error_message_th": "มีการสั่งจ่ายน้ำแข็ง แต่เลข TID ซ้ำ",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1063,
    "error_message_en": "TID duplicate when pay",
    "error_message_th": "มีการสั่งจ่ายน้ำแข็งมาใหม่ โดยที่การจ่ายน้ำแข็งของเลข TID ก่อนหน้ากำลังจ่ายอยู่",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1064,
    "error_message_en": "TID duplicate drop ice",
    "error_message_th": "มีการสั่งจ่ายน้ำแข็งมาใหม่ โดยที่การจ่ายน้ำแข็งของเลข TID ก่อนหน้ากำลังรอการสั่งเทลงแก้วอยู่",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1065,
    "error_message_en": "ICE bucket move limit switch not found.",
    "error_message_th": "มีการสั่งเทน้ำแข็งลงแก้ว แต่เลื่อนกระบะแล้ว limit switch ไม่กด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1066,
    "error_message_en": "ICE Drop. Open shutter timeout.",
    "error_message_th": "มีการสั่งเทน้ำแข็งลงแก้ว แต่เปิดชัตเตอร์แล้วไม่กด limit switch ในเวลา 5วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1067,
    "error_message_en": "ICE Drop. CLose shutter timeout.",
    "error_message_th": "มีการสั่งเทน้ำแข็งลงแก้ว แต่ปิดชัตเตอร์แล้วไม่กด limit switch ในเวลา 5วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1068,
    "error_message_en": "ICE Drop process timeout.",
    "error_message_th": "บอร์ดน้ำแข็งไม่มี result การสั่งเทน้ำแข็ง ตอบกลับมาใน 45วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1069,
    "error_message_en": "Fail weight scale move timeout",
    "error_message_th": "กระบะชั่งน้ำแข็งมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1070,
    "error_message_en": "Pay ice not enought",
    "error_message_th": null,
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1071,
    "error_message_en": "Pay Ice time out",
    "error_message_th": "บอร์ดน้ำแข็งไม่มี result การสั่งน้ำแข็ง ตอบกลับมาใน 45วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1072,
    "error_message_en": "Error code 25 weight scale timeout",
    "error_message_th": "จError code 25 weight scale timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1073,
    "error_message_en": "Error code 26 bean pay timeout",
    "error_message_th": "Error code 26 bean pay timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1074,
    "error_message_en": "Error code 27 Grinder adjust",
    "error_message_th": "Error code 27 Grinder adjust",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1075,
    "error_message_en": "Error code 28 Recovery CUP",
    "error_message_th": "Error code 28 Recovery CUP",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1076,
    "error_message_en": "Error code 29 Tray of bean",
    "error_message_th": "Error code 28 Tray of bean",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1077,
    "error_message_en": "Error code 30 CUP falling",
    "error_message_th": "Error code 30 CUP falling",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1078,
    "error_message_en": "Error code 54 abnormal weight scale",
    "error_message_th": "Error code 54 abnormal weight scale",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1079,
    "error_message_en": "Error code 252 board front door",
    "error_message_th": "Error code 252 board front door",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1080,
    "error_message_en": "Error code 253 pay soda or mix",
    "error_message_th": "Error code 253 pay soda or mix",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1081,
    "error_message_en": "SMD Error",
    "error_message_th": "MD Error",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1082,
    "error_message_en": "Arm move position timeout 9",
    "error_message_th": "แขนหมุนมีปัญหา (9)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1083,
    "error_message_en": "Arm move position timeout 10",
    "error_message_th": "แขนหมุนมีปัญหา (10)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1084,
    "error_message_en": "Arm move position timeout 12",
    "error_message_th": "แขนหมุนมีปัญหา (12)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1085,
    "error_message_en": "Arm move position timeout 13",
    "error_message_th": "แขนหมุนมีปัญหา (13)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1086,
    "error_message_en": "Arm move position timeout 14",
    "error_message_th": "แขนหมุนมีปัญหา (14)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1087,
    "error_message_en": "Arm move position timeout 15",
    "error_message_th": "แขนหมุนมีปัญหา (15)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1088,
    "error_message_en": "Arm move position timeout 16",
    "error_message_th": "แขนหมุนมีปัญหา (16)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1089,
    "error_message_en": "weight ice pay less then request",
    "error_message_th": "จ่ายน้ำแข็งไม่ครบตามที่ต้องการ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1090,
    "error_message_en": "AC wiring wrong phase.",
    "error_message_th": "ได้โปรดติดตั้งสายไฟ ac ให้ถูกต้อง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1091,
    "error_message_en": "Arm move position timeout 11",
    "error_message_th": "แขนหมุนมีปัญหา (11)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1092,
    "error_message_en": "ICE Dump timeout",
    "error_message_th": "บอร์ดน้ำแข็งไม่มี result การสั่งเคาะน้ำแข็ง ตอบกลับมาใน 45วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1093,
    "error_message_en": "Cup slot 1 error",
    "error_message_th": "แก้วหลุม 1 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1094,
    "error_message_en": "Cup slot 2 error",
    "error_message_th": "แก้วหลุม 2 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1095,
    "error_message_en": "Cup slot 3 error",
    "error_message_th": "แก้วหลุม 3 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1096,
    "error_message_en": "Cup slot 4 error",
    "error_message_th": "แก้วหลุม 4 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1097,
    "error_message_en": "Cup slot 5 error",
    "error_message_th": "แก้วหลุม 5 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1098,
    "error_message_en": "Heartbeat from top board timeout",
    "error_message_th": "Heartbeat from top board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1099,
    "error_message_en": "Heartbeat from bottom board timeout",
    "error_message_th": "Heartbeat from bottom board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1100,
    "error_message_en": "Heartbeat from front board timeout",
    "error_message_th": "Heartbeat from front board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1101,
    "error_message_en": "communcation from top board timeout",
    "error_message_th": "communcation from top board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1102,
    "error_message_en": "communcation from bottom board timeout",
    "error_message_th": "communcation from bottom board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1103,
    "error_message_en": "communcation from front board timeout",
    "error_message_th": "communcation from front board timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1104,
    "error_message_en": "guard of coffee bean not close",
    "error_message_th": "การ์ดจ่ายเมล็ดกาแฟ ไม่ได้ถูกปิด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1105,
    "error_message_en": "WDGReset",
    "error_message_th": "WDGReset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1106,
    "error_message_en": "Abnormal ice make rate",
    "error_message_th": "อัตราผลิตน้ำแข็งผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1107,
    "error_message_en": "Config weight scale of bean abnormal",
    "error_message_th": "Config weight scale of bean abnormal",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1108,
    "error_message_en": "Front door board WDGReset",
    "error_message_th": "Front door board WDGReset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1109,
    "error_message_en": "Top board WDGReset",
    "error_message_th": "Top board WDGReset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1113,
    "error_message_en": "Grinder addjust over",
    "error_message_th": "Grinder addjust over",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1114,
    "error_message_en": "Grinder addjust under",
    "error_message_th": "Grinder addjust under",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1115,
    "error_message_en": "Order Timeout",
    "error_message_th": "Order Timeout",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1116,
    "error_message_en": "LID 2 Out Of stock",
    "error_message_th": "ฝาหมด slot 2",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1117,
    "error_message_en": "Motor Lid2 Timeout",
    "error_message_th": "สวิตจ่าย 2 มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1118,
    "error_message_en": "Ice temperature less than -21",
    "error_message_th": "อุณหภูมิทำน้ำแข็งน้อยกว่า -21",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1120,
    "error_message_en": "Clean water pay timeout",
    "error_message_th": "น้ำล้าง จ่ายไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1121,
    "error_message_en": "Module disc not close",
    "error_message_th": "ชุดแขน disc ปิดไม่สนิท",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1122,
    "error_message_en": "Error number 250 unknow sub",
    "error_message_th": "Error number 250 unknow sub",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1123,
    "error_message_en": "Error no cup on powder pay",
    "error_message_th": "ไม่มีแก้วตอนจ่ายผง",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1124,
    "error_message_en": "Error switch press both",
    "error_message_th": "สวิตจ่ายผง โดนกดทั้งสอง",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1125,
    "error_message_en": "waste almost full effluent",
    "error_message_th": "ถังน้ำใกล้เต็ม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1126,
    "error_message_en": "Arm loop detect cup.",
    "error_message_th": "แขนมีปัญหาเนื่องจากตรวจสอบน้ำหนักเปลี่ยนแปลงบ่อย.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1130,
    "error_message_en": "whipper move down to position 1 timeout",
    "error_message_th": "การชง เคลื่อที่ลงไปที่ตำแหน่ง 1 (ล้าง)  Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1131,
    "error_message_en": "whipper move down to position 2 timeout",
    "error_message_th": "การชง เคลื่อที่ลงไปที่ตำแหน่ง 2 (แก้วร้อน)  Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1132,
    "error_message_en": "whipper move down to position 3 timeout",
    "error_message_th": "การชง เคลื่อที่ลงไปที่ตำแหน่ง 3 (แก้วเย็น)  Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1133,
    "error_message_en": "whipper move up to home timeout",
    "error_message_th": "การชง เคลื่อที่ขึ้นไปที่ตำแหน่ง home  Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1134,
    "error_message_en": "whipper move up to position 1 timeout",
    "error_message_th": "การชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 1 (ล้าง) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1135,
    "error_message_en": "whipper move up to position 2 timeout",
    "error_message_th": "การชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 2 (แก้วร้อน) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1136,
    "error_message_en": "whipper move up to position 3 timeout",
    "error_message_th": "การชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 3 (แก้วเย็น) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1137,
    "error_message_en": "whipper clean profcess timeout",
    "error_message_th": "process การล้างหัวชง timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1138,
    "error_message_en": "whipper (clean) move down to position 1 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ลงไปที่ตำแหน่ง 1 (ล้าง) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1139,
    "error_message_en": "whipper (clean) move down to position 2 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ลงไปที่ตำแหน่ง 2 (แก้วร้อน) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1140,
    "error_message_en": "whipper (clean) move down to position 3 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ลงไปที่ตำแหน่ง 3 (แก้วเย็น) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1141,
    "error_message_en": "whipper (clean) move up to position home timeout",
    "error_message_th": "การล้าง เคลื่อนที่ขึ้นไปที่ตำแหน่ง Home (บนสุด) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1142,
    "error_message_en": "whipper (clean) move up to position 1 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 1 (ล้าง) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1143,
    "error_message_en": "whipper (clean) move up to position 2 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 2 (แก้วร้อน) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1144,
    "error_message_en": "whipper (clean) move up to position 3 timeout",
    "error_message_th": "การล้าง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 3 (แก้วเย็น) Timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1145,
    "error_message_en": "whipper unknown position move down",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ลง",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1146,
    "error_message_en": "whipper unknown position move down to position 1",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ลงไปตำแหน่งที่ 1 (ล้าง)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1147,
    "error_message_en": "whipper unknown position move down to position 2",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ลงไปตำแหน่งที่ 2 (แก้วร้อน)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1148,
    "error_message_en": "whipper unknown position move down to position 3",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ลงไปตำแหน่งที่ 3 (แก้วเย็น)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1149,
    "error_message_en": "whipper unknown position move up to home",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ขึ้น",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1150,
    "error_message_en": "whipper unknown position move up to position 1",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 1 (ล้าง)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1151,
    "error_message_en": "whipper unknown position move up to position 2",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 2 (ร้อน)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1152,
    "error_message_en": "whipper unknown position move up to position 3",
    "error_message_th": "เคลื่อนที่หัวชงขึ้น โดยไม่อยู่ในตำแหน่งล้าง หรือตำแหน่งชง เคลื่อนที่ขึ้นไปที่ตำแหน่ง 3 (เย็น)",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1153,
    "error_message_en": "refill hot water alert",
    "error_message_th": "เติมน้ำร้อนเพิ่ม!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1154,
    "error_message_en": "board esp low power reset",
    "error_message_th": "board esp low power reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1155,
    "error_message_en": "board esp power on reset",
    "error_message_th": "board esp power on reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1156,
    "error_message_en": "board esp pin reset",
    "error_message_th": "board esp pin reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1157,
    "error_message_en": "board esp reset unknown cause",
    "error_message_th": "board esp reset unknown cause",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1158,
    "error_message_en": "board esp reset unknown cause",
    "error_message_th": "board esp reset unknown cause",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1159,
    "error_message_en": "mcu android pin reset",
    "error_message_th": "mcu android pin reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1160,
    "error_message_en": "mcu android power on reset",
    "error_message_th": "mcu android power on reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1161,
    "error_message_en": "mcu android IWDG reset",
    "error_message_th": "mcu android IWDG reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1162,
    "error_message_en": "mcu android WWDG reset",
    "error_message_th": "mcu android WWDG reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1163,
    "error_message_en": "mcu android low power reset",
    "error_message_th": "mcu android low power reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1164,
    "error_message_en": "mcu android unknown reset",
    "error_message_th": "mcu android unknown reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1166,
    "error_message_en": "top board pin reset",
    "error_message_th": "top board pin reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1167,
    "error_message_en": "top board IWDG reset",
    "error_message_th": "top board IWDG reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1168,
    "error_message_en": "top board power on reset",
    "error_message_th": "top board power on reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1169,
    "error_message_en": "top board reset unknown",
    "error_message_th": "top board reset unknown",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1171,
    "error_message_en": "bottom board pin reset",
    "error_message_th": "bottom board pin reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1172,
    "error_message_en": "bottom board power on reset",
    "error_message_th": "bottom board power on reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1173,
    "error_message_en": "bottom board IWDG reset",
    "error_message_th": "bottom board IWDG reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1174,
    "error_message_en": "bottom board WWDG reset",
    "error_message_th": "bottom board WWDG reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1175,
    "error_message_en": "bottom board low power reset",
    "error_message_th": "bottom board low power reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1176,
    "error_message_en": "bottom board reset unknown",
    "error_message_th": "bottom board reset unknown",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1178,
    "error_message_en": "front door pin reset",
    "error_message_th": "front door pin reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1179,
    "error_message_en": "front board iwdg reset",
    "error_message_th": "front board iwdg reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1180,
    "error_message_en": "front door low power reset",
    "error_message_th": "front door low power reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1181,
    "error_message_en": "front door unknown reset",
    "error_message_th": "front door unknown reset",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1182,
    "error_message_en": "Door cash open",
    "error_message_th": "ประตูเก็บเงินเปิด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1184,
    "error_message_en": "Cold water flow timeout",
    "error_message_th": "sensor น้ำเย็น timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1185,
    "error_message_en": "Hot water flow timeout",
    "error_message_th": "sensor น้ำร้อน timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1186,
    "error_message_en": "Espresso flow timeout",
    "error_message_th": "sensor น้ำอัดกาแฟ timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1187,
    "error_message_en": "Water clean flow timeout",
    "error_message_th": "sensor น้ำล้าง timeout",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1188,
    "error_message_en": "AC ground abnormal",
    "error_message_th": "สายกราวด์ ผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1190,
    "error_message_en": "LID 1 falling timeout",
    "error_message_th": "ฝา 1 ไม่ตก",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1191,
    "error_message_en": "LID 2 falling timeout",
    "error_message_th": "ฝา 2 ไม่ตก",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1192,
    "error_message_en": "I'm tired of trying",
    "error_message_th": "ฉันเหนื่อยที่จะลอง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1193,
    "error_message_en": "Air break ice. Water pump timeout",
    "error_message_th": "ปั๊มน้ำเข้า air break หมดเวลา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1194,
    "error_message_en": "Touch screen abnormal by more move",
    "error_message_th": "กดทัชกรีนมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1195,
    "error_message_en": "Touch screen abnormal by more press",
    "error_message_th": "กดทัชกรีนมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1196,
    "error_message_en": "Water DTS value abnormal please check",
    "error_message_th": "กรุณาตรวจสอบค่า TDS ของน้ำในถังแช่เย็น",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1197,
    "error_message_en": "Make ice not full in 3 hour",
    "error_message_th": "ผลิตน้ำแข็งภายใน 3 ชม. ไม่เต็ม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1198,
    "error_message_en": "Abnormal Compressor",
    "error_message_th": "Compressor ผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1201,
    "error_message_en": "Coffee grounds almost full",
    "error_message_th": "กากกาแฟเต็ม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1202,
    "error_message_en": "Bean1 pay abnormal",
    "error_message_th": "จ่ายเมล็ด1 ผิดปกติ น้ำหนักเกิน 2 กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1203,
    "error_message_en": "Bean2 pay abnormal",
    "error_message_th": "จ่ายเมล็ด2 ผิดปกติ น้ำหนักเกิน 2 กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1204,
    "error_message_en": "Bean3 pay abnormal",
    "error_message_th": "จ่ายเมล็ด3 ผิดปกติ น้ำหนักเกิน 2 กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1205,
    "error_message_en": "Cover ice tank stuck",
    "error_message_th": "ฝาน้ำแข็งค้าง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1207,
    "error_message_en": "E-lock abnormal",
    "error_message_th": "E-lock ผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1208,
    "error_message_en": "E-lock unknow status",
    "error_message_th": "E-lock ไม่รู้สถานะ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1215,
    "error_message_en": "Cup maybe leak.",
    "error_message_th": "แก้วอาจจะรั่ว",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1216,
    "error_message_en": "Ice Motor not rotating.",
    "error_message_th": "มอเตอร์เกียร์น้ำแข็งไม่หมุน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1217,
    "error_message_en": "Air break water leak.",
    "error_message_th": "น้ำรั่วที่ air break",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1218,
    "error_message_en": "Switch e-lock not found.",
    "error_message_th": "สวิต e-lock ไม่ถูกกด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1219,
    "error_message_en": "Still error.",
    "error_message_th": "ตู้ไม่สามารถขายได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1220,
    "error_message_en": "Open door cup timeout.",
    "error_message_th": "ประตูรับแก้วเปิดไม่ได้ 10 วิ้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1221,
    "error_message_en": "Cannot make next drink. Cup still on arm or cup sensor error.",
    "error_message_th": "ไม่สามารถทำเครื่องดื่มได้ จากรายการถัดไปแล้วลูกค้าไม่หยิบแก้ว",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1222,
    "error_message_en": "Espresso time less than 9 sec.",
    "error_message_th": "เวลาอัดน้ำกาแฟน้อยกว่า 9 วินาที",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1223,
    "error_message_en": "Weight value abnormal",
    "error_message_th": "น้ำหนักจากการจ่ายผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1224,
    "error_message_en": "Refill ice 3 cup happen",
    "error_message_th": "เติมน้ำแข็งเพิ่ม 3 แก้วติดกัน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1225,
    "error_message_en": "Water Cold refill happened",
    "error_message_th": "เติมน้ำเย็นไม่ได้ตามขนาดที่ต้องการน ้อยกว่า 20 ml",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1226,
    "error_message_en": "Boiler sensor read sometime no data",
    "error_message_th": "อ่านอุณหภูมิ หม้อต้ม มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1227,
    "error_message_en": "Boiler sensor no data",
    "error_message_th": "อ่านอุณหภูมิ หม้อต้ม มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1228,
    "error_message_en": "Stop make drink, Door cup have hacked",
    "error_message_th": "หยุดทำเครื่องดื่ม ประตูรับแก้วโดนเปิด",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1229,
    "error_message_en": "Stop make drink",
    "error_message_th": "หยุดทำเครื่องดื่ม",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1230,
    "error_message_en": "Arm 200 Move timeout",
    "error_message_th": "แขน 200 เคลื่อนที่ไม่ได้",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1231,
    "error_message_en": "Report straw problem",
    "error_message_th": "แจ้งเตื่อน หลอด มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1232,
    "error_message_en": "Arm move time out. Cause not found stop position.",
    "error_message_th": "Arm เคลื่อนที่ ไม่สามารถหาตำแหน่งหยุดได้ - อาจเป็นปัญหาส่วน IR ตำแหน่งหยุด",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1233,
    "error_message_en": "Arm move time out. Cause not found index 4bit position.",
    "error_message_th": "Arm เคลื่อนที่ ไม่สามารถหาตำแหน่งที่ต้องการจะไปได้ - อาจเป็นปัญหาส่วน IR ชุดบอก Index 4 Bit",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1234,
    "error_message_en": "Arm move time out. Stop position by encoder motor.",
    "error_message_th": "Arm เคลื่อนที่ ไม่สามารถหยุดในตำแหน่งตามระยะการคำนวนปกติได้ - อาจเป็นปัญหาส่วน IR ตำแหน่งหยุด เฉพาะ Index นั้น",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1235,
    "error_message_en": "Arm move time out. Motor problem.",
    "error_message_th": "Encoder หลัง Motor Arm ไม่พบการเคลื่อนที่ - ปัญหาจาก Motor มีปัญหา",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1236,
    "error_message_en": "Arm move time out. Switch whipper protect.",
    "error_message_th": "Encoder หลัง Motor Arm ไม่พบการเคลื่อนที่ - ปัญหาจาก Limit Switch Whipper ป้องกันการเคลื่อนที่ของแขนทำงานอยู่",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1237,
    "error_message_en": "Arm move time out. Sensor problem.",
    "error_message_th": "Encoder หลัง Motor Arm ไม่พบการเคลื่อนที่ - ปัญหาจาก sensor encoder เอง",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1238,
    "error_message_en": "Powder move timeout. Stop position not found",
    "error_message_th": "ชุดผงเคลื่อนที่ ไม่สามารถหาตำแหน่งหยุดได้ - อาจเป็นปัญหาส่วน IR ตำแหน่งหยุด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1239,
    "error_message_en": "Powder move timeout. Index position not found",
    "error_message_th": "ชุดผงเคลื่อนที่ ไม่สามารถหาตำแหน่งที่ต้องการจะไปได้ - อาจเป็นปัญหาส่วน IR ชุดบอก Index 4 Bit",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1240,
    "error_message_en": "Powder move timeout. Index position not found by encoder",
    "error_message_th": "ไม่สามารถหยุดในตำแหน่งตามระยะการคำนวนปกติได้ - อาจเป็นปัญหาส่วน IR ตำแหน่งหยุด เฉพาะ Index นั้น",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1241,
    "error_message_en": "Powder move timeout. Motor problem.",
    "error_message_th": "มอเตอร์ชุดผง เซนเซอร์ ไม่พบการเคลื่อนที่ - ปัญหาจาก Motor มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1242,
    "error_message_en": "Powder move timeout. Sensor protect rotation.",
    "error_message_th": "มอเตอร์ชุดผง เซนเซอร์ ไม่พบการเคลื่อนที่ - ปัญหาจาก Motor มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1243,
    "error_message_en": "Powder move timeout. Encoder sensor",
    "error_message_th": "มอเตอร์ชุดผง เซนเซอร์ ไม่พบการเคลื่อนที่ - ปัญหาจาก Motor มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1244,
    "error_message_en": "Bean weight scale abnormal value was swing.",
    "error_message_th": "ชั่งน้ำหนักเมล็ดกาแฟ ค่าผิดปกติ สาเหตุ หนุนตาชั่งเมล็ด อาจเกิดเหตุเมล็ดจ่ายล้นถาด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1245,
    "error_message_en": "Bean weight scale abnormal more value was swing.",
    "error_message_th": "ชั่งน้ำหนักเมล็ดกาแฟ ค่าผิดปกติ 3 ครั้ง สาเหตุ หนุนตาชั่งเมล็ด อาจเกิดเหตุเมล็ดจ่ายล้นถาด",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1246,
    "error_message_en": "Data bus fail",
    "error_message_th": "Data bus fail",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1247,
    "error_message_en": "Ice more request.",
    "error_message_th": "เติมน้ำแข็งมากเกินไป",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1248,
    "error_message_en": "Software handle flush coffee fail.",
    "error_message_th": "Software ส่วน flush กากกาแฟ ผิดปกติ.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1249,
    "error_message_en": "Software handle clear whipper fail.",
    "error_message_th": "Software ส่วน รอการล้างหัวชง ผิดปกติ.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1250,
    "error_message_en": "Software handle clean coffee fail.",
    "error_message_th": "Software ส่วน รอการล้างโมดูลกาแฟ ผิดปกติ.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1251,
    "error_message_en": "Software handle clean ice fail.",
    "error_message_th": "Software ส่วน รอการ clear status ice ผิดปกติ.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1252,
    "error_message_en": "Ice low",
    "error_message_th": "น้ำแข็งหมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1253,
    "error_message_en": "Bean slot 1 out stock",
    "error_message_th": "กาแฟช่อง 1 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1254,
    "error_message_en": "Bean slot 2 out stock",
    "error_message_th": "กาแฟช่อง 2 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1255,
    "error_message_en": "Bean slot 3 out stock",
    "error_message_th": "กาแฟช่อง 3 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1256,
    "error_message_en": "Powder slot 1 out stock",
    "error_message_th": "ผงช่อง 1 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1257,
    "error_message_en": "Powder slot 2 out stock",
    "error_message_th": "ผงช่อง 2 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1258,
    "error_message_en": "Powder slot 3 out stock",
    "error_message_th": "ผงช่อง 3 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1259,
    "error_message_en": "Powder slot 4 out stock",
    "error_message_th": "ผงช่อง 4 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1260,
    "error_message_en": "Powder slot 5 out stock",
    "error_message_th": "ผงช่อง 5 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1261,
    "error_message_en": "Powder slot 6 out stock",
    "error_message_th": "ผงช่อง 6 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1262,
    "error_message_en": "Powder slot 7 out stock",
    "error_message_th": "ผงช่อง 7 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1263,
    "error_message_en": "Powder slot 8 out stock",
    "error_message_th": "ผงช่อง 8 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1264,
    "error_message_en": "Powder slot 9 out stock",
    "error_message_th": "ผงช่อง 9 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1265,
    "error_message_en": "Powder slot 10 out stock",
    "error_message_th": "ผงช่อง 10 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1266,
    "error_message_en": "Powder slot 11 out stock",
    "error_message_th": "ผงช่อง 11 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1267,
    "error_message_en": "Syrup slot 1 out stock",
    "error_message_th": "ไซรับช่อง 1 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1268,
    "error_message_en": "Syrup slot 2 out stock",
    "error_message_th": "ไซรับช่อง 2 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1269,
    "error_message_en": "Syrup slot 3 out stock",
    "error_message_th": "ไซรับช่อง 3 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1270,
    "error_message_en": "Syrup slot 4 out stock",
    "error_message_th": "ไซรับช่อง 4 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1271,
    "error_message_en": "Syrup slot 5 out stock",
    "error_message_th": "ไซรับช่อง 5 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1272,
    "error_message_en": "Syrup slot 6 out stock",
    "error_message_th": "ไซรับช่อง 6 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1273,
    "error_message_en": "Syrup slot 7 out stock",
    "error_message_th": "ไซรับช่อง 7 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1274,
    "error_message_en": "Syrup slot 8 out stock",
    "error_message_th": "ไซรับช่อง 8 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1275,
    "error_message_en": "Syrup slot 9 out stock",
    "error_message_th": "ไซรับช่อง 9 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1276,
    "error_message_en": "MIX slot 1 out stock",
    "error_message_th": "mix ช่อง 1 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1277,
    "error_message_en": "MIX slot 2 out stock",
    "error_message_th": "mix ช่อง 1 หมด.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1278,
    "error_message_en": "Total weight lose 30 gram before drink finish",
    "error_message_th": "น้ำหนักรวม 30 กรัม หายก่อนส่งลูกค้า.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1279,
    "error_message_en": "Error top-board timeout. Software Check!",
    "error_message_th": "Error top-board timeout. Software Check!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1280,
    "error_message_en": "Error bottom-board timeout. Software Check!",
    "error_message_th": "Error bottom-board timeout. Software Check!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1281,
    "error_message_en": "Error front-board timeout. Software Check!",
    "error_message_th": "Error front-board timeout. Software Check!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1282,
    "error_message_en": "Error ice-board timeout. Software Check!",
    "error_message_th": "Error ice-board timeout. Software Check!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1283,
    "error_message_en": "Ice refill happened when ice stuck on pipe",
    "error_message_th": "เติมน้ำแข็งเพราะน้ำแข็งติดท่อ.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1284,
    "error_message_en": "Weight scale abnormal. arm or ice.",
    "error_message_th": "พบการจ่ายน้ำแข็งผิดปกติ ตราชั่ง แก้วหรือน้ำแข็ง.",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1285,
    "error_message_en": "Whipper not spin!",
    "error_message_th": "หัวชงไม่หมุน",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1286,
    "error_message_en": "Whipper spin abnormal",
    "error_message_th": "หัวชงหมุนผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1287,
    "error_message_en": "Make order fail. Because send data fail.",
    "error_message_th": "สั่งเครื่องดื่มไม่ได้เพราะข้อมูลส่งผิดพลาด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1288,
    "error_message_en": "Data Busfail!",
    "error_message_th": "Data Busfail!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1289,
    "error_message_en": "Sensor door cup detect object. abnormal",
    "error_message_th": "เซนเซอร์ตรงประตุรับแก้วมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1290,
    "error_message_en": "Sensor door lid detect object. abnormal",
    "error_message_th": "เซนเซอร์ตรงประตุรับฝา/หลอดมีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1291,
    "error_message_en": "Sensor door detect lid at door. abnormal alway detected",
    "error_message_th": "เซนเซอร์ตรวจสอบฝาจากการปล่อย มีปัญหา ตรวจเจอฝาเสมอ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1292,
    "error_message_en": "Sensor door cup active long time.",
    "error_message_th": "เซนเซอร์ตรงประตุรับแก้ว พบวัตถุนานเกินผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1293,
    "error_message_en": "Sensor door lid active long time.",
    "error_message_th": "เซนเซอร์ตรงประตุรับฝา/หลอด  พบวัตถุนานเกินผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1294,
    "error_message_en": "Limit switch of shutter both active.",
    "error_message_th": "สวิตซัตเตอร์ โดนกดทั้งสองฝั่ง(ปิดและเปิด)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1295,
    "error_message_en": "Limit switch of bucket both active.",
    "error_message_th": "BT200353",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1296,
    "error_message_en": "Door cup can not close.",
    "error_message_th": "ประตูรับแก้วปิดไม่ได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1297,
    "error_message_en": "Espresso time less than 8 sec.",
    "error_message_th": "เวลาอัดน้ำกาแฟน้อยกว่า 8 วินาที",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1298,
    "error_message_en": "Cup disc move to next slot timeout.",
    "error_message_th": "ชุดหมุนแก้วตำแหน่งถัดไป หมดเวลา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1299,
    "error_message_en": "Cup disc move timeout or sensor failed.",
    "error_message_th": "ชุดหมุนแก้วไม่ทำงาน หรือ สวิตถูกกดตลอดเวลา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1299,
    "error_message_en": "Cup disc move error hardware project.",
    "error_message_th": "ชุดหมุนแก้วไม่ทำงานจากระบบป้องกันแก้ว",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1300,
    "error_message_en": "CAN bus not response.",
    "error_message_th": "CAN bus not response",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1301,
    "error_message_en": "CAN bus not ready.",
    "error_message_th": "CAN bus not ready",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1302,
    "error_message_en": "Espresso time less than 7 sec.",
    "error_message_th": "เวลาอัดน้ำกาแฟน้อยกว่า 7 วินาที",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1303,
    "error_message_en": "Transection abnormal.",
    "error_message_th": "Transection abnormal.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1304,
    "error_message_en": "Software handle timeout!",
    "error_message_th": "ระบบสั่งงานมีปัญหา!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1305,
    "error_message_en": "Espresso module handle timeout!",
    "error_message_th": "ระบบสั่งงานมีปัญหา!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1306,
    "error_message_en": "Main air break stuck",
    "error_message_th": "Main air break มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1307,
    "error_message_en": "MCU Upgrade abnormal",
    "error_message_th": "MCU Upgrade abnormal",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1308,
    "error_message_en": "CUP disc limit switch press away",
    "error_message_th": "ชุดจานหมุนแก้ว limit switch ถูกกดตลอด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1309,
    "error_message_en": "Too little ice",
    "error_message_th": "น้ำแข็งได้น้อยเกินไป",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1310,
    "error_message_en": "MakeD. Abnormal",
    "error_message_th": "ชงเครื่องดื่มมีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1311,
    "error_message_en": "Transaction not complete 1",
    "error_message_th": "Transaction not complete 1",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1312,
    "error_message_en": "Transaction not complete 2",
    "error_message_th": "Transaction not complete 2",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1313,
    "error_message_en": "Espress time less than setting value",
    "error_message_th": "เวลาอัดน้ำกาแฟน้อยกว่าค่าที่ตั้งไว้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1314,
    "error_message_en": "Espress time more than setting value",
    "error_message_th": "เวลาอัดน้ำกาแฟนานกว่าค่าที่ตั้งไว้",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1315,
    "error_message_en": "Espresso v2 move prevent steam position timeout",
    "error_message_th": "หมุนหลบไอน้ำ หมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1316,
    "error_message_en": "Espresso v2 move to clean position timeout",
    "error_message_th": "หมุนไปตำแหน่งล้าง หมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1317,
    "error_message_en": "Espresso v2 move from clean position timeout",
    "error_message_th": "หมุนออกจากตำแหน่งล้าง หมดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1318,
    "error_message_en": "Espresso v2 move cover to open timeout",
    "error_message_th": "Cover ไปตำแหน่งเปิดหมดเวลา( Motor , Sensor )",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1319,
    "error_message_en": "Espresso v2 piston move up timeout current sensor error.",
    "error_message_th": "Piston เคลื่อนที่ขึ้นหมดเวลา จากเซ็นเซอร์กระแสเสีย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1320,
    "error_message_en": "Espresso v2 piston move up timeout current sensor error.",
    "error_message_th": "Piston เคลื่อนที่ลงหมดเวลา จากเซ็นเซอร์กระแสเสีย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1321,
    "error_message_en": "Espresso v2 piston move clean/flush timeout by encoder error.",
    "error_message_th": "Piston เคลื่อนที่ไปตำแหน่งล้างหมดเวลา จากเซ็นเซอร์วัดรอบเสีย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1322,
    "error_message_en": "Espresso v2 piston move up timeout by encoder error.",
    "error_message_th": "Piston เคลื่อนที่ขึ้นหมดเวลา จากเซ็นเซอร์วัดรอบเสีย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1323,
    "error_message_en": "Espresso v2 piston move up timeout by encoder error.",
    "error_message_th": "Piston เคลื่อนที่ลงหมดเวลา จากเซ็นเซอร์วัดรอบเสีย",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1324,
    "error_message_en": "Espresso v2 piston motor timeout.",
    "error_message_th": "มอเตอร์มีปัญหา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1325,
    "error_message_en": "Espresso v2 piston force press limit.",
    "error_message_th": "Piston เคลื่อนที่อัดขึ้นเพื่อรักษาระดับ Encoder ระหว่างการทำกาแฟถึง Limit",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1326,
    "error_message_en": "Gas too high pressure. More than 95psi",
    "error_message_th": "แรงดันแก๊สสูงเกินไป มากกว่า 95psi",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1327,
    "error_message_en": "Retry clean stage error ( Mark position error )",
    "error_message_th": "Retry Stage ล้างหัวชง ทดสอบนับร่องการเคลื่อนที่ไม่ครบ3",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1328,
    "error_message_en": "Powder  1 happened",
    "error_message_th": "กล่องผง (1)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1329,
    "error_message_en": "Powder  2 happened",
    "error_message_th": "กล่องผง (2)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1330,
    "error_message_en": "Powder  3 happened",
    "error_message_th": "กล่องผง (3)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1331,
    "error_message_en": "Powder  4 happened",
    "error_message_th": "กล่องผง (4)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1332,
    "error_message_en": "Powder  5 happened",
    "error_message_th": "กล่องผง (5)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1333,
    "error_message_en": "Powder  6 happened",
    "error_message_th": "กล่องผง (6)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1334,
    "error_message_en": "Powder  7 happened",
    "error_message_th": "กล่องผง (7)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1335,
    "error_message_en": "Powder  8 happened",
    "error_message_th": "กล่องผง (8)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1336,
    "error_message_en": "Powder  9 happened",
    "error_message_th": "กล่องผง (9)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1337,
    "error_message_en": "Powder 10 happened",
    "error_message_th": "กล่องผง (10)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1338,
    "error_message_en": "Powder 11 happened",
    "error_message_th": "กล่องผง (11)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1339,
    "error_message_en": "Bean 1 happened",
    "error_message_th": "กล่องเมล็ด (1)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1340,
    "error_message_en": "Bean 2 happened",
    "error_message_th": "กล่องเมล็ด (2)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1341,
    "error_message_en": "Bean 3 happened",
    "error_message_th": "กล่องเมล็ด (3)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1342,
    "error_message_en": "Espresso v2 move cover to close timeout",
    "error_message_th": "Cover ไปตำแหน่งปิดหมดเวลา( Motor , Sensor )",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1343,
    "error_message_en": "Report Lid problem",
    "error_message_th": "แจ้งเตื่อน ฝา มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1344,
    "error_message_en": "Press coffee ground long time.",
    "error_message_th": "อัดกากกาแฟนานเกิน",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1345,
    "error_message_en": "AC Voltage abnormal. Too low volt.",
    "error_message_th": "แรงดันไฟฟ้า AC ต่ำเกินผิดปกติ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1346,
    "error_message_en": "Ice make rate too low ( reduce 20%).",
    "error_message_th": "อัตราการผลิตน้ำแข็งลดลง 20%",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1347,
    "error_message_en": "Open cash box with out cut off.",
    "error_message_th": "เปิดกล่องเก็บเงิน โดยไม่ได้กดตัดยอด",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1348,
    "error_message_en": "Found noise from whipper spindisc.",
    "error_message_th": "encoder พบสัญญาณรบกวนจากหัวชง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1349,
    "error_message_en": "Switch case box abnormal.",
    "error_message_th": "สวิตประตูเก็บเงิน มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1350,
    "error_message_en": "Recovery error.",
    "error_message_th": "กำลังกู้คืน",
    "error_type_color": "RED",
    "error_type": "ERROR"
  },
  {
    "error_code": 1351,
    "error_message_en": "Powder. Driver motor error is set!",
    "error_message_th": "Powder. Driver motor error is set!",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1352,
    "error_message_en": "Brewing too slow.",
    "error_message_th": "ตู้ทำเครื่องดื่มช้าลง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1353,
    "error_message_en": "refill hot water alert",
    "error_message_th": "เติมน้ำร้อนเพิ่ม!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1354,
    "error_message_en": "refill cold water alert",
    "error_message_th": "เติมน้ำเย็นเพิ่ม!",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1355,
    "error_message_en": "Whipper module. Switch home press. When move down.",
    "error_message_th": "ส่วนหัวชง ตรวจพบ switch home (บนสุด) ถูกกดตลอดเวลา ระหว่างเคลื่อนที่ลง",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1356,
    "error_message_en": "Whipper module. Switch left and right both press.",
    "error_message_th": "ส่วนหัวชง ตรวจพบ switch brew / clean (ซ้าย-ขวา) ถูกกดตลอดเวลา",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1357,
    "error_message_en": "Sensor check CUP abnormal.",
    "error_message_th": "เซนเซอร์ตรวจสอบแก้วมีปัญหา ( ติดๆดับๆ)",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1358,
    "error_message_en": "Ice read current sensor error.",
    "error_message_th": "โมดูลอ่านกระแสสื่อสารไม่ได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1359,
    "error_message_en": "Ice module zero cross error.",
    "error_message_th": "zero cross มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1360,
    "error_message_en": "Ice bucket take time to decide.",
    "error_message_th": "กระบะมอเตอร์น้ำแข็งใช้เวลาในการตัดสินใจ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1361,
    "error_message_en": "Ice weight scale set zero fail.",
    "error_message_th": "ตราชั่งน้ำแข็ง เริ่มต้นการชั่งไม่ได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1362,
    "error_message_en": "Ice weight scale broken down.",
    "error_message_th": "ตราชั่งน้ำแข็งเสีย",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1363,
    "error_message_en": "Ice bucket lever abnormal.",
    "error_message_th": "ก้านมอเตอร์กระบะแตกหรือเสีย",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1364,
    "error_message_en": "Ice stuck in pipe.",
    "error_message_th": "น้ำแข็งค้างท่อ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1365,
    "error_message_en": "Ice stuck in bucket.",
    "error_message_th": "มีน้ำแข็งค้างอยู่บนกระบะ",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1366,
    "error_message_en": "Ice weight scale broken down.",
    "error_message_th": "แกนน้ำแข็งโยก",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1367,
    "error_message_en": "Soda temperature sensor error.",
    "error_message_th": "ตัววัดอุณหภูมิเสีย",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1368,
    "error_message_en": "Compressor sensor error.",
    "error_message_th": "แรงดันคอมเพรสเซอร์มีปัญหา",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1369,
    "error_message_en": "Brewing too slow from ice module.",
    "error_message_th": "ตู้ทำเครื่องดื่มช้าลงเพราะระบบน้ำแข็ง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1370,
    "error_message_en": "Main air break may be stuck.",
    "error_message_th": "air break ติด-แต่กลับมาใช้ได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1371,
    "error_message_en": "Error code 55 IR with ws arm",
    "error_message_th": "Error code 55 IR with ws arm",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1372,
    "error_message_en": "Error Drop cup arm 1 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1373,
    "error_message_en": "Error Drop cup arm 2 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 2 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1374,
    "error_message_en": "Error Drop cup arm 3 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 3 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1375,
    "error_message_en": "Error Drop cup arm 4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1376,
    "error_message_en": "Error code 56 IR with slot",
    "error_message_th": "Error code 55 IR with slot",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1377,
    "error_message_en": "Error Drop cup slot 1 weight not found and IR not found",
    "error_message_th": "ปล่อยแก้วหลุม 1 แต่ไม่เจอน้ำหนัก และ IR ไม่เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1378,
    "error_message_en": "Error Drop cup slot 2 weight not found and IR not found",
    "error_message_th": "ปล่อยแก้วหลุม 2 แต่ไม่เจอน้ำหนัก และ IR ไม่เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1379,
    "error_message_en": "Error Drop cup slot 3 weight not found and IR not found",
    "error_message_th": "ปล่อยแก้วหลุม 3 แต่ไม่เจอน้ำหนัก และ IR ไม่เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1380,
    "error_message_en": "Error Drop cup slot 4 weight not found and IR not found",
    "error_message_th": "ปล่อยแก้วหลุม 4 แต่ไม่เจอน้ำหนัก และ IR ไม่เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1381,
    "error_message_en": "Error Drop cup slot 5 weight not found and IR not found",
    "error_message_th": "ปล่อยแก้วหลุม 5 แต่ไม่เจอน้ำหนัก และ IR ไม่เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1382,
    "error_message_en": "Pay bean 1 over 10 gram",
    "error_message_th": "น้ำหนักเมล็ดจากช่อง 1 มากกว่า 10กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1383,
    "error_message_en": "Pay bean 2 over 10 gram",
    "error_message_th": "น้ำหนักเมล็ดจากช่อง 2 มากกว่า 10กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1384,
    "error_message_en": "Pay bean 3 over 10 gram",
    "error_message_th": "น้ำหนักเมล็ดจากช่อง 3 มากกว่า 10กรัม",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1385,
    "error_message_en": "Powder pay position stuck more 3 time.",
    "error_message_th": "แทงผงมีปัญหา เกิน 3 ครั้ง",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1386,
    "error_message_en": "espresso model setting error.",
    "error_message_th": "espresso model setting error.",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1389,
    "error_message_en": "Main air break stuck. Self-test comeback working",
    "error_message_th": "Main air break ค้าง. selftest กลับมาใช้ได้",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1390,
    "error_message_en": "The gas co2 should now be changed.",
    "error_message_th": "แก๊ส CO2 ควรเปลี่ยนได้แล้ว",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1391,
    "error_message_en": "Limit switch ice full abnormal. Because make ice think full but not switch not press",
    "error_message_th": "น้ำแข็งทำนานเกินปริมาณที่ฝาควรจะกดแต่ไม่กดสักที",
    "error_type_color": "YELLOW",
    "error_type": "WARNING"
  },
  {
    "error_code": 1392,
    "error_message_en": "Error Drop cup arm 1,2 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1,2     แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1393,
    "error_message_en": "Error Drop cup arm 1,2,3 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1,2,3   แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1394,
    "error_message_en": "Error Drop cup arm 1,2,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1 2   4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1395,
    "error_message_en": "Error Drop cup arm 1,2,3,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1 2 3 4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1396,
    "error_message_en": "Error Drop cup arm 2,3 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน   2 3   แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1397,
    "error_message_en": "Error Drop cup arm 2,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน   2   4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1398,
    "error_message_en": "Error Drop cup arm 2,3,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน   2 3 4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1399,
    "error_message_en": "Error Drop cup arm 3,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน     3,4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1400,
    "error_message_en": "Error Drop cup arm 1,3,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1   3,4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1401,
    "error_message_en": "Error Drop cup arm 1,3 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1   3   แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  },
  {
    "error_code": 1402,
    "error_message_en": "Error Drop cup arm 1,4 weight not found but IR found",
    "error_message_th": "ปล่อยแก้วลงแขน 1     4 แต่ไม่เจอน้ำหนัก แต่ IR เจอแก้ว",
    "error_type_color": "ORANGE",
    "error_type": "ERROR"
  }
];

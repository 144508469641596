import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";
import * as React from "react";

type MenuWithIconProps = MenuItemProps & {
  children: React.ReactNode;
  iconName: string;
};

export function MenuWithIcon ({ children, iconName, disabled, onClick, ...props }: MenuWithIconProps) {
  return <MenuItem disabled={disabled} {...props} className={clsx("gap-2 !py-3", props.className)} onClick={(e) => !disabled && onClick?.(e)}>
    <Icon name={iconName} color="primary500" />
    <p className="text-primary-500 font-main">{children}</p>
  </MenuItem>;
}

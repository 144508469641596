// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalOverlay--30NSZgeDpB{height:100%;display:flex;align-items:center;justify-content:center;padding:25px;box-sizing:border-box}.ModalOverlay--30NSZgeDpB *{box-sizing:border-box}.ModalOverlay--30NSZgeDpB .ModalContainer--2CrtH4I8m_{background-color:#fff;border-radius:3px;flex:1 1 0;min-width:0;height:100%;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"ModalOverlay": "ModalOverlay--30NSZgeDpB",
	"ModalContainer": "ModalContainer--2CrtH4I8m_"
};
module.exports = exports;

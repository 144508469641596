import i18n from 'common/i18n';

/**
 * Parses an ISO 8601 duration string into a more human-readable string.
 *
 * @param duration - The ISO 8601 duration string.
 * @returns The human-readable duration string.
 */
export function parseISODuration(duration: string): string {
  const regex = /P(?:(\d*)Y)?(?:(\d*)M)?(?:(\d*)W)?(?:(\d*)D)?T?(?:(\d*)H)?(?:(\d*)M)?(?:(\d*)S)?/;
  const matches = duration.match(regex);

  if (!matches) {
    return '-';
  }

  const years = parseInt(matches[1], 10) || 0;
  const months = parseInt(matches[2], 10) || 0;
  const weeks = parseInt(matches[3], 10) || 0;
  let days = parseInt(matches[4], 10) || 0;

  days += weeks * 7;

  const components = [
    { value: years, unit: 'year' },
    { value: months, unit: 'month' },
    { value: days, unit: 'day' }
  ];

  const formattedComponents = components
    .filter((component) => component.value > 0)
    .map((component) => {
      const translationKey = `label_unit_${component.unit}${component.value > 1 ? 's' : ''}`;
      const unit = i18n.t(translationKey);
      return `${component.value} ${unit}`;
    });

  if (formattedComponents.length === 0) {
    return `0 ${i18n.t('label_unit_day')}`;
  }

  return formattedComponents.join(', ');
}

import { IRootState } from 'common/store/reducers/rootReducer';

export function selectPendingRequest (actionType: string) {
  return function (state: IRootState): boolean {
    return state.pendingRequest[actionType];
  };
}

export function selectLastRequest (actionType: string) {
  return function (state: IRootState): number {
    return state.lastRequest[actionType];
  };
}

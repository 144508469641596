import { RentFee } from "containers/contract/hooks/useGetLocationFee";
import { ServiceFeeInput, ServiceFeeType } from "gql/generated";

export function mapRentFeeToServiceFeeInput (rentFee: RentFee): ServiceFeeInput {
  if(!rentFee) {
    throw new Error("rentFee can't be null");
  }
  if(rentFee.type === "FIXED") {
    return {
      type: ServiceFeeType.FixedFee,
      fixedFee: Number(rentFee.value),
      payWithCode: rentFee.payWithCode,
    };
  }

  if(rentFee.type === "LADDER") {
    return {
      type: ServiceFeeType.Steps,
      steps: rentFee.value.map(step => ({
        period: {
          start: step.from.format("YYYY-MM-DD"),
          end: step.to.format("YYYY-MM-DD"),
        },
        stepFee: {
          amount: Number(step.rentalPrice)
        }
      })),
      payWithCode: rentFee.payWithCode,
    };
  }

  if(rentFee.type === "PERCENTAGE") {
    return {
      type: ServiceFeeType.PercentageFee,
      percentageFee: {
        minimumAmount: Number(rentFee.value.minimumAmount),
        ladderPercentages: rentFee.value.segments.map(fee => ({
          lowerSales: Number(fee.lower),
          upperSales: Number(fee.upper),
          percentage: Number(fee.percentage)
        }))
      },
      payWithCode: rentFee.payWithCode,
    };
  }
}

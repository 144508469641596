import * as React from 'react';
import { useState } from 'react';

import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';

import { usePatchMachineMaintainencePeriod } from 'machines/hooks/usePatchMachineMaintainencePeriod';

import * as styles from './MaintainacePeriodModal.module.scss';

export function MaintainacePeriodModal({
  onClose,
  partInfo,
}: IMaintainacePeriodModalProps): JSX.Element {
  const { mutate: updateMaintenanceDate, isLoading } =
    usePatchMachineMaintainencePeriod();

  const [numberOfDates, setNumberOfDates] = useState<null | number>(null);

  return (
    <Modal contentClassName={styles.MaintainacePeriodModal}>
      <div className={styles.Header}>
        <Typography
          type='headline-6'
          translationKey='label_maintenance_set_date_period'
        />
      </div>
      <Input
        label='label_number_of_days'
        type='number'
        value={numberOfDates}
        onChange={(value) => setNumberOfDates(value)}
      />
      <div className={styles.ButtonGroup}>
        <Button
          type='secondary'
          translationKey='action_cancel'
          onClick={onClose}
          disabled={isLoading}
        />
        <Button
          type='primary'
          translationKey='action_update'
          onClick={() => {
            updateMaintenanceDate(
              {
                machineId: partInfo.machineId,
                type: partInfo.type,
                body: {
                  name: partInfo.name,
                  newMaintenancePeriod: numberOfDates,
                },
              },
              { onSuccess: onClose }
            );
          }}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
}

interface IMaintainacePeriodModalProps {
  onClose: () => void;
  partInfo: {
    machineId: string;
    name: string;
    type: 'slot' | 'component';
  };
}

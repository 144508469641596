import * as React from "react";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSortState } from "hooks/useSortState";
import * as styles from "./RouteModalView.module.scss";
import { clsx } from "clsx";
import {
  InventoryStatusCell,
  PriorityCell,
  refillPriorityValueMap,
} from "../../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";
import Button from "components/Button";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";
import Checkbox from "components/Checkbox";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { InventoryBar } from "containers/new-operations/refill-order/InventoryBar";
import {
  mapIncomingDataToRouteModalData,
  filterLocationsWithMachineAndSortByRoutePosition,
  computeRouteModalAggregates,
  initializeSelectionState,
  computeSelectionStatus,
  MachineToView,
  generateOverviewRows,
  toggleRefillOrderSelectionForMachine,
  toggleSwapOrderSelectionForMachine,
  generateMachineViewMap,
  handleCriticalNoteChangeForMachine,
  handleLocationNoteChangeForMachine,
  toggleCriticalForMachine,
  mapStateAndIncomingDataToOutgoingData,
  removeAllSelection,
  selectMachines,
  countNumberOfSelectedMachines,
  OverviewTableRow,
} from "./utils";
import { toasti18n } from "utils/toast";
import { formatCurrency } from "utils/currency";
import { timestampFormatter } from "../../utils";
import { trackMachineShortlistedToPlanStep } from "./tracking";
import {
  LocationKind,
  MachineKind,
  useCreateRefillOrderCandidatesMutation,
  useGetMachinesAndOrdersInRefillZoneQuery,
  useOptimizeLocationPositionMutation,
} from "gql/generated";
import RecommendationIcon from "./RecommendationIcon";
import analytics from "utils/analytics";

interface Props {
  routeId: string;
  onClose: () => void;
  onRouteSuccess: () => void;
}

export function RouteModalView(props: Props) {
  const { t } = useTranslation();
  const { data } = useGetMachinesAndOrdersInRefillZoneQuery(
    {
      refillZoneId: props.routeId,
      locationKind: [LocationKind.Location, LocationKind.Store],
      machineKind: [MachineKind.BeverageVending, MachineKind.BeverageUnit],
    },
    { cacheTime: 0 }
  );
  const queryClient = useQueryClient();

  const routeModalData = mapIncomingDataToRouteModalData(data);
  const sortedLocationsWithMachinesByRoutePosition = React.useMemo(
    () =>
      filterLocationsWithMachineAndSortByRoutePosition(
        routeModalData.locations
      ),
    [data]
  );

  const machineAggregates = React.useMemo(
    () => computeRouteModalAggregates(routeModalData),
    [routeModalData]
  );
  const [selectedMachines, setSelectedMachines] = useImmer(() =>
    initializeSelectionState(sortedLocationsWithMachinesByRoutePosition)
  );
  const selectionStatus = computeSelectionStatus(selectedMachines);
  const disabledForm = selectionStatus === "NONE";
  const numberOfSelectedMachines =
    countNumberOfSelectedMachines(selectedMachines);

  const [machineToView, _setMachineToView] =
    React.useState<MachineToView>(null);
  const machineRowsScrollContainer = React.useRef<HTMLDivElement>(null);
  const overviewScollContanier = React.useRef<HTMLDivElement>(null);
  const currentScroll = React.useRef<number>(null);
  function setMachineToView(newValue: React.SetStateAction<MachineToView>) {
    const transitioningBetweenMachineViews =
      machineToView !== null && newValue !== null;
    const closingMachineView = newValue === null && machineToView !== null;
    const openingMachineView = machineToView === null && newValue !== null;
    if (closingMachineView) {
      currentScroll.current = machineRowsScrollContainer.current.scrollTop;
    } else if (openingMachineView) {
      currentScroll.current = overviewScollContanier.current.scrollTop;
    } else if (transitioningBetweenMachineViews) {
      currentScroll.current = machineRowsScrollContainer.current.scrollTop;
    }
    _setMachineToView(newValue);
  }

  const spiralMachineData = React.useMemo(() => {
    return machineToView
      ? routeModalData.locations.filter(
          (location) =>
            location.machine.parentId === machineToView.machineId &&
            location.machine.kind === MachineKind.SpiralVending
        )
      : [];
  }, [machineToView, routeModalData.locations]);

  React.useLayoutEffect(
    function syncScrollBetweenOverviewAndMachineRowsContainer() {
      if (currentScroll.current === null) {
        return;
      }
      if (machineToView === null) {
        overviewScollContanier.current.scrollTop = currentScroll.current;
      } else {
        machineRowsScrollContainer.current.scrollTop = currentScroll.current;
      }
    },
    [machineToView]
  );

  const overviewRows = generateOverviewRows({
    locations: sortedLocationsWithMachinesByRoutePosition,
    handleCheckboxClick: ({ machineId, rowType }) =>
      setSelectedMachines((draft) => {
        const selectedMachineData = filteredOverviewRows.find(
          (row) => row.machineId === machineId
        );
        if (!selectedMachineData) return;

        if (selectedMachineData.kind === MachineKind.BeverageVending) {
          const childrenMachine = filteredOverviewRows.filter(
            (row) => row.parentId === selectedMachineData.machineId
          );
          if (rowType === "REFILL") {
            toggleRefillOrderSelectionForMachine(draft, machineId);

            // will select children machine only when select but not when user unselect
            if (draft[machineId]?.refillSelected === true) {
              childrenMachine?.forEach((machine) =>
                toggleRefillOrderSelectionForMachine(
                  draft,
                  machine.machineId,
                  true
                )
              );
            }
          } else if (rowType === "SWAP") {
            toggleSwapOrderSelectionForMachine(draft, machineId);
            if (draft[machineId]?.swapSelected === true) {
              childrenMachine?.forEach((machine) =>
                toggleSwapOrderSelectionForMachine(
                  draft,
                  machine.machineId,
                  true
                )
              );
            }
          }
        }

        if (selectedMachineData.kind === MachineKind.SpiralVending) {
          const parentMachine = filteredOverviewRows.find(
            (row) => row.machineId === selectedMachineData.parentId
          );
          if (rowType === "REFILL") {
            toggleRefillOrderSelectionForMachine(draft, machineId);
            if (draft[machineId]?.refillSelected === true) {
              parentMachine &&
                toggleRefillOrderSelectionForMachine(
                  draft,
                  parentMachine.machineId,
                  true
                );
            }
          } else if (rowType === "SWAP") {
            toggleSwapOrderSelectionForMachine(draft, machineId);
            if (draft[machineId]?.swapSelected === true) {
              parentMachine &&
                toggleSwapOrderSelectionForMachine(
                  draft,
                  parentMachine.machineId,
                  true
                );
            }
          }
        }
      }),
    handleRowClick: ({ machineId, rowType }) => {
      setMachineToView({ machineId, type: rowType });
    },
    isCheckboxDisabled: ({ machineId, rowType }) =>
      rowType === "SWAP" ? !selectedMachines[machineId].refillSelected : null,
    isCheckboxSelected: ({ machineId, rowType }) =>
      rowType === "REFILL"
        ? selectedMachines[machineId].refillSelected
        : selectedMachines[machineId].swapSelected,
  });
  const machineViewData = React.useMemo(
    () =>
      generateMachineViewMap({
        machineId: machineToView?.machineId,
        locations: sortedLocationsWithMachinesByRoutePosition,
        getCriticalNote: (machineId) =>
          selectedMachines[machineId].criticalNote,
        getLocationNote: (machineId) =>
          selectedMachines[machineId].locationNote,
        isCritical: (machineId) => selectedMachines[machineId].critical,
        handleCriticalNoteChange: (machineId, newValue) =>
          setSelectedMachines((draft) => {
            handleCriticalNoteChangeForMachine(draft, machineId, newValue);
          }),
        handleLocationNoteChange: (machineId, newValue) =>
          setSelectedMachines((draft) => {
            handleLocationNoteChangeForMachine(draft, machineId, newValue);
          }),
        toggleCritical: (machineId) =>
          setSelectedMachines((draft) => {
            toggleCriticalForMachine(draft, machineId);
          }),
      }),
    [
      machineToView,
      sortedLocationsWithMachinesByRoutePosition,
      selectedMachines,
      setSelectedMachines,
    ]
  );

  const [showActiveRefill, setShowActiveRefill] = React.useState(false);
  const [onlyShowSuggestedRefill, setOnlyShowSuggestedRefill] =
    React.useState(false);

  let filteredOverviewRows = overviewRows;
  if (!showActiveRefill) {
    filteredOverviewRows = overviewRows.filter(
      (machine) => !machine.activeRefill
    );
  }

  if (onlyShowSuggestedRefill) {
    filteredOverviewRows = overviewRows
      .filter(
        (machine) =>
          !!machine.refillOverview?.isSuggestedSelection &&
          machine.type !== "SWAP"
      )
      .sort(
        (a, b) =>
          a.refillOverview.suggestedOrder - b.refillOverview.suggestedOrder
      );
  }

  const { getSortDirectionByColumnId, toggleSortColumnByColumnId, sortState } =
    useSortState<Columns>({
      columnId: columns.routePosition,
      direction: "ASC",
    });
  const sortedFilteredRows = React.useMemo(() => {
    return sortState === null
      ? filteredOverviewRows
      : [...filteredOverviewRows].sort(
          (machineA, machineB) =>
            sortFnMap[sortState.columnId](machineA, machineB) *
            (sortState.direction === "DESC" ? -1 : 1)
        );
  }, [filteredOverviewRows]);

  React.useEffect(() => {

    if(overviewRows) return;
    
    const machineRecommendedListText = overviewRows
      ?.filter((machine) => machine.refillOverview.isSuggestedSelection)
      ?.map((machine) => machine.machineId)
      .join(", ");
    analytics.track({
      name: "route modal opened",
      properties: {
        refill_zone: routeModalData.refillZone,
        category: "REFILL OPERATION",
        machine_recommended: machineRecommendedListText,
      },
    });
  }, [!overviewRows]);

  const { mutate, isLoading } = useCreateRefillOrderCandidatesMutation({
    onSuccess: (_, variables) => {
      toasti18n.success();
      // tracking on machine added to order refill candidates success!
      trackMachineShortlistedToPlanStep({
        data,
        variables: variables.refillOrderCandidates,
      });
      props.onRouteSuccess();
    },
    onError: (err: Error) => {
      toasti18n.error(err);
    },
  });
  function createCandidates() {
    mutate(mapStateAndIncomingDataToOutgoingData(selectedMachines, data));
  }
  const { mutate: optimizeLocation, isLoading: isOptimizingLocation } =
    useOptimizeLocationPositionMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "GetMachinesAndOrdersInRefillZone",
        ]);
        toasti18n.success();
      },
      onError: (err: Error) => {
        toasti18n.error(err);
      },
    });

  return (
    <div className={styles.RouteModal}>
      <div className={styles.Header}>
        <Typography type="headline-6" color="onSurfaceHigh" translate>
          label_zone
          {` ${routeModalData.refillZone} `}
        </Typography>
        <Typography type="subtitle-1" color="onSurfaceMedium" translate>
          {"("}
          {machineAggregates.numberOfMachines}
          {` `}
          label_machines
          {`, `}
          {machineAggregates.numberOfRecommendedMachines}
          {` `}
          label_recommended
          {` `}
          {numberOfSelectedMachines}
          {` `}
          label_selected
          {")"}
        </Typography>
      </div>

      <div className={styles.ToolBar}>
        <div className={styles.ToggleSwitchContainer}>
          <ToggleSwitch
            isOn={showActiveRefill}
            onClick={() => setShowActiveRefill(!showActiveRefill)}
          />
          <Typography type="body-2" color="onSurfaceHigh" translate>
            label_show_active_refill_orders
          </Typography>
        </div>
        <div className={styles.ToggleSwitchContainer}>
          <ToggleSwitch
            isOn={onlyShowSuggestedRefill}
            onClick={() => setOnlyShowSuggestedRefill(!onlyShowSuggestedRefill)}
          />
          <Typography type="body-2" color="onSurfaceHigh" translate>
            label_recommended_only
          </Typography>
        </div>
      </div>

      <div className={styles.GrowArea}>
        {machineToView === null && (
          <div
            className={styles.OverviewScrollContainer}
            ref={overviewScollContanier}
          >
            <div className={styles.OverviewTableContainer}>
              <div className={styles.OverviewTableHeader}>
                <div className={styles.ColumnCheckbox}>
                  <Checkbox
                    onClick={() => {
                      setSelectedMachines((draft) => {
                        selectionStatus === "NONE"
                          ? selectMachines(
                              draft,
                              filteredOverviewRows.map((row) => row.machineId)
                            )
                          : removeAllSelection(draft);
                      });
                    }}
                    checked={selectionStatus === "ALL"}
                    indeterminate={selectionStatus === "PARTIAL"}
                  />
                </div>
                <div className={styles.ColumnRoutePosition}>
                  <TableHeader
                    columnId="route-modal: position"
                    sort={
                      getSortDirectionByColumnId(columns.routePosition) ??
                      "NONE"
                    }
                    onClick={() =>
                      toggleSortColumnByColumnId(columns.routePosition)
                    }
                  >
                    {t("label_route_position")}
                  </TableHeader>
                </div>
                <div className={styles.ColumnMachineId}>
                  <TableHeader
                    columnId="route-modal: machine id"
                    sort={
                      getSortDirectionByColumnId(columns.machineId) ?? "NONE"
                    }
                    onClick={() =>
                      toggleSortColumnByColumnId(columns.machineId)
                    }
                  >
                    {t("label_machine_id")}
                  </TableHeader>
                </div>
                <div className={styles.ColumnStatus} />
                <div className={styles.ColumnPriority}>
                  <TableHeader
                    columnId="route-modal: priority"
                    sort={
                      getSortDirectionByColumnId(columns.priority) ?? "NONE"
                    }
                    onClick={() => toggleSortColumnByColumnId(columns.priority)}
                  >
                    {t("label_priority")}
                  </TableHeader>
                </div>
                <div className={styles.ColumnLocationName}>
                  <Typography type="body-2" translate color="onSurfaceDisabled">
                    label_location_name
                  </Typography>
                </div>
                <div className={styles.ColumnLastRefill}>
                  <TableHeader
                    columnId="route-modal: last refill"
                    sort={
                      getSortDirectionByColumnId(columns.lastRefill) ?? "NONE"
                    }
                    onClick={() =>
                      toggleSortColumnByColumnId(columns.lastRefill)
                    }
                  >
                    {t("label_last_refill")}
                  </TableHeader>
                </div>
                <div className={styles.ColumnRevenue}>
                  <TableHeader
                    columnId="route-modal: revenue"
                    sort={getSortDirectionByColumnId(columns.revenue) ?? "NONE"}
                    onClick={() => toggleSortColumnByColumnId(columns.revenue)}
                  >
                    {t("label_revenue")}
                  </TableHeader>
                </div>
                <div className={styles.ColumnType}>
                  <Typography type="body-2" translate color="onSurfaceDisabled">
                    label_type
                  </Typography>
                </div>
                <div className={styles.ColumnError}>
                  <Typography type="body-2" translate color="onSurfaceDisabled">
                    status_error
                  </Typography>
                </div>
                <div className={styles.ColumnLowSlots}>
                  <Typography type="body-2" translate color="onSurfaceDisabled">
                    label_low_slot
                  </Typography>
                </div>
              </div>
              {sortedFilteredRows.map((machine) => (
                <div
                  key={machine.machineId + machine.type}
                  className={
                    machine.checkboxSelected
                      ? styles.RowSelectableSelected
                      : machine.hasRefillCandidates ||
                          machine.hasRefillOrdersNotInCompleted ||
                          !machine.enableWorkOrderCreation
                        ? styles.RowSelectableDisabled
                        : styles.RowSelectable
                  }
                  onClick={machine.handleRowClick}
                >
                  <div className={styles.ColumnCheckbox}>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        onClick={machine.handleCheckboxClick}
                        disabled={machine.checkboxDisabled}
                        checked={machine.checkboxSelected}
                      />
                    </div>
                  </div>
                  <div className={styles.ColumnRoutePosition}>
                    <Typography type="body-2" color="onSurfaceHigh">
                      {machine.routePosition}
                    </Typography>
                  </div>
                  <div className={styles.ColumnMachineId}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {machine.kind === MachineKind.SpiralVending ? (
                        <div>
                          <Typography
                            type="body-2"
                            translate
                            color="onSurfaceHigh"
                            className="leading-5"
                          >
                            {machine.machineId}
                          </Typography>
                          <p className="leading-4 font-kanit text-caption text-on-surface-disabled">
                            {machine.parentId}
                          </p>
                        </div>
                      ) : (
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceHigh"
                        >
                          {machine.machineId}
                        </Typography>
                      )}
                      {machine.kind === MachineKind.SpiralVending && (
                        <Icon name="Snack" color="primary" />
                      )}
                    </div>
                  </div>
                  <div className={styles.ColumnStatus}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "40px",
                      }}
                    >
                      {machine.activeRefill ? (
                        <Icon name="SmallTruck" color="primary500" />
                      ) : machine.type === "SWAP" ? (
                        <Icon name="RefreshAlert" color="primary500" />
                      ) : machine.refillOverview?.isSuggestedSelection ? (
                        <RecommendationIcon
                          icon="LightBulb"
                          message={t("label_smart_recommendation")}
                          recommendedOrder={
                            machine.refillOverview.suggestedOrder
                          }
                        />
                      ) : machine.recommended ? (
                        <RecommendationIcon
                          icon="Diamond"
                          message={t("label_expected_empty_slots_24h")}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.ColumnPriority}>
                    <PriorityCell priority={machine.refillPriority} />
                  </div>
                  <div className={styles.ColumnLocationName}>
                    <div className={styles.TwoLinesWrapper}>
                      <Typography type="body-2" translate color="onSurfaceHigh">
                        {machine.locationName}
                      </Typography>
                    </div>
                    <div
                      className={`${styles.TwoLinesWrapper} ${styles.Status}`}
                    >
                      {!machine.enableWorkOrderCreation ? (
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceHigh"
                        >
                          label_disabled_for_work_order_creation
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.ColumnLastRefill}>
                    <Typography type="body-2" translate color="onSurfaceHigh">
                      {timestampFormatter(machine.lastRefill)}
                    </Typography>
                  </div>
                  <div className={styles.ColumnRevenue}>
                    <Typography type="body-2" translate color="onSurfaceHigh">
                      {formatCurrency({
                        input: machine.revenue,
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </div>
                  <div className={styles.ColumnType}>
                    <Typography type="body-2" translate color="onSurfaceHigh">
                      {machine.typeDisplay}
                    </Typography>
                  </div>
                  <div className={styles.ColumnError}>
                    <Typography
                      type="body-2"
                      translate
                      color={
                        machine.recentErrorCode ? "error" : "onSurfaceHigh"
                      }
                    >
                      {machine.recentErrorCode ? machine.recentErrorCode : "-"}
                    </Typography>
                  </div>
                  <div className={styles.ColumnLowSlots}>
                    <Typography type="body-2" translate color="onSurfaceHigh">
                      {machine.lowSlots}
                    </Typography>
                  </div>
                </div>
              ))}
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
        )}
        {machineToView !== null && machineViewData && (
          <div className={styles.MachineViewContainer}>
            <div className={styles.MachineRowsContainer}>
              <div className={styles.Row}>
                <div className={styles.ColumnCheckbox}>
                  <Checkbox
                    onClick={() => {
                      setSelectedMachines((draft) => {
                        selectionStatus === "NONE"
                          ? selectMachines(
                              draft,
                              filteredOverviewRows.map((row) => row.machineId)
                            )
                          : removeAllSelection(draft);
                      });
                    }}
                    checked={selectionStatus === "ALL"}
                    indeterminate={selectionStatus === "PARTIAL"}
                  />
                </div>
                <div className={styles.ColumnMachineId}>
                  <Typography
                    className="flex-1"
                    type="body-2"
                    translate
                    color="onSurfaceDisabled"
                  >
                    label_machine_id
                  </Typography>
                  <div className="w-10" />
                </div>
              </div>
              <div style={{ flex: "1 1 0", minHeight: "0px" }}>
                <div
                  style={{ width: "100%", height: "100%", overflow: "auto" }}
                  ref={machineRowsScrollContainer}
                >
                  {sortedFilteredRows.map((machine) => (
                    <div
                      key={machine.machineId + machine.type}
                      className={
                        machine.checkboxSelected
                          ? styles.RowSelectableSelected
                          : machine.hasRefillCandidates ||
                              machine.hasRefillOrdersNotInCompleted ||
                              !machine.enableWorkOrderCreation
                            ? styles.RowSelectableDisabled
                            : styles.RowSelectable
                      }
                      onClick={machine.handleRowClick}
                    >
                      <div className={styles.ColumnCheckbox}>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            onClick={machine.handleCheckboxClick}
                            disabled={machine.checkboxDisabled}
                            checked={machine.checkboxSelected}
                          />
                        </div>
                      </div>
                      <div className={styles.ColumnMachineId}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="flex items-center flex-1 gap-2 pr-2">
                            {machine.kind === MachineKind.SpiralVending ? (
                              <div className="flex-1">
                                <Typography
                                  type="body-2"
                                  translate
                                  color="onSurfaceHigh"
                                  className="leading-5"
                                >
                                  {machine.machineId}
                                </Typography>
                                <p className="leading-4 font-kanit text-caption text-on-surface-disabled">
                                  {machine.parentId}
                                </p>
                              </div>
                            ) : (
                              <Typography
                                className="flex-1"
                                type="body-2"
                                translate
                                color="onSurfaceHigh"
                              >
                                {machine.machineId}
                              </Typography>
                            )}
                            {machine.kind === MachineKind.SpiralVending && (
                              <Icon name="Snack" color="primary" />
                            )}

                            {machine.activeRefill ? (
                              <Icon name="SmallTruck" color="primary500" />
                            ) : machine.type === "SWAP" ? (
                              <Icon name="RefreshAlert" color="primary500" />
                            ) : machine.refillOverview?.isSuggestedSelection ? (
                              <RecommendationIcon
                                icon="LightBulb"
                                message={t("label_smart_recommendation")}
                                recommendedOrder={
                                  machine.refillOverview.suggestedOrder
                                }
                              />
                            ) : machine.recommended ? (
                              <RecommendationIcon
                                icon="Diamond"
                                message={t("label_expected_empty_slots_24h")}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div style={{ height: "100px" }}></div>
                </div>
              </div>
            </div>

            <div className={styles.MachineView}>
              <div
                className={clsx(styles.Row, "!h-auto")}
                style={{
                  paddingLeft: "17px",
                  paddingRight: "17px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="flex gap-[30px] whitespace-nowrap overflow-hidden">
                    <Typography type="headline-6" color="onSurfaceHigh">
                      {machineViewData.id}
                    </Typography>
                    <Typography type="headline-6" color="onSurfaceHigh">
                      {machineViewData.locationName}
                    </Typography>
                  </div>
                  {machineViewData.kind === MachineKind.BeverageVending &&
                    spiralMachineData.length > 0 && (
                      <div className="flex items-center gap-1 py-3">
                        <Icon name="Snack" color="primary" />
                        <Typography type="body-2" color="onSurfaceMedium">
                          {spiralMachineData?.length}
                        </Typography>
                        <Typography
                          type="body-2"
                          color="onSurfaceMedium"
                          translate
                        >
                          label_spiral_machine
                        </Typography>
                        <div>
                          {spiralMachineData?.map((spiralMachine) => (
                            <Link
                              target="_blank"
                              to={`/machines/detail/${spiralMachine?.machine?.machineId}`}
                              key={spiralMachine?.machine?.machineId}
                              className="font-medium underline text-body2 text-primary"
                            >
                              {spiralMachine?.machine?.machineId}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  {machineViewData.kind === MachineKind.SpiralVending && (
                    <div className="flex items-center gap-1 py-3">
                      <Icon name="Machine" color="primary" />
                      <p className="text-body2 text-on-surface-medium">
                        {t("label_taobin_machine")} :{" "}
                      </p>
                      <Link
                        target="_blank"
                        to={`/machines/detail/${machineViewData?.parentId}`}
                        className="font-medium underline text-body2 text-primary"
                      >
                        {machineViewData?.parentId}
                      </Link>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => setMachineToView(null)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <Icon name="Close" color="primary500" />
                </div>
              </div>

              <div
                style={{ flex: "1 1 0", minHeight: 0, overflow: "auto" }}
                key={machineToView.machineId}
              >
                <div style={{ minWidth: "fit-content" }}>
                  <div>
                    <div
                      className={styles.Row}
                      style={{
                        paddingLeft: "17px",
                        paddingRight: "17px",
                        zIndex: 2,
                        backgroundColor: "white",
                        top: 0,
                        position: "sticky",
                      }}
                    >
                      <div className={styles.ColumnSlot}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_slot
                        </Typography>
                      </div>
                      <div className={styles.ColumnSKU}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_sku
                        </Typography>
                      </div>
                      <div className={styles.ColumnImage}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_image
                        </Typography>
                      </div>
                      <div className={styles.ColumnIngredient}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_ingredient
                        </Typography>
                      </div>
                      <div className={styles.ColumnLevel}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_level
                        </Typography>
                      </div>
                      <div className={styles.ColumnInventory}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_inventory
                        </Typography>
                      </div>
                      <div className={styles.ColumnStatus}>
                        <Typography
                          type="caption"
                          translate
                          color="onSurfaceMedium"
                        >
                          label_status
                        </Typography>
                      </div>
                    </div>
                    {machineViewData?.inventories?.map((inventory) => (
                      <div
                        key={inventory.slot}
                        className={styles.Row}
                        style={{
                          paddingLeft: "17px",
                          paddingRight: "17px",
                        }}
                      >
                        <div className={styles.ColumnSlot}>
                          <div className={styles.SlotCell}>
                            <div className={styles.SlotCellText}>
                              <Typography type="body-2">
                                {inventory.slot}
                              </Typography>
                            </div>
                            <div className={styles.RecommendedIconContainer}>
                              {machineToView.type === "REFILL" &&
                              inventory.recommended ? (
                                <RecommendationIcon
                                  icon="Diamond"
                                  message={t("label_expected_empty_slot_24h")}
                                />
                              ) : machineToView.type === "SWAP" &&
                                inventory.swappedProduct ? (
                                <Icon name="RefreshAlert" color="primary500" />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className={styles.ColumnSKU}>
                          <div style={{ width: "100%" }}>
                            <div className={styles.SKUTextContainer}>
                              <Typography
                                type="caption"
                                className={styles.SKUTextStyles}
                              >
                                {inventory.product.sku}
                              </Typography>
                            </div>
                            {machineToView.type === "SWAP" &&
                            inventory.swappedProduct ? (
                              <div className={styles.SKUTextContainer}>
                                <Typography
                                  type="caption"
                                  className={styles.SKUTextStyles}
                                  color="onSurfaceDisabled"
                                >
                                  {inventory.swappedProduct.sku}
                                </Typography>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className={styles.ColumnImage}>
                          <div className={styles.ImageContainer}>
                            <img
                              src={inventory.product.img || ""}
                              className={styles.Image}
                            />
                          </div>
                        </div>
                        <div className={styles.ColumnIngredient}>
                          {inventory.swappedProduct &&
                          machineToView.type === "SWAP" ? (
                            <div style={{ height: "100%" }}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography type="body-2" color="onSurfaceHigh">
                                  {inventory.product.name}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography
                                  type="caption"
                                  color="onSurfaceDisabled"
                                >
                                  {inventory.swappedProduct.name}
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.IngredientTextContainer}>
                              <Typography type="body-2" color="onSurfaceHigh">
                                {inventory.product.name}
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div className={styles.ColumnLevel}>
                          <Typography type="body-2" color="onSurfaceHigh">
                            {`${inventory.levels.current} ${inventory.product.uom}`}
                          </Typography>
                        </div>
                        <div className={styles.ColumnInventory}>
                          <InventoryBar
                            inventory={{
                              autoRefill: inventory.autoRefill,
                              capacity: inventory.levels.capacity,
                              currentLevel: inventory.levels.current,
                              highLevel: inventory.levels.high,
                              lowLevel: inventory.levels.low,
                              pendingSwap: inventory.pendingSwap,
                              uom: inventory.product.uom,
                            }}
                          />
                        </div>
                        <div className={styles.ColumnStatus}>
                          <InventoryStatusCell
                            status={inventory.inventoryStatus}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={styles.NotesContainer}>
                    <div className={styles.NoteContainer}>
                      <Typography
                        translate
                        type="caption"
                        color="onSurfaceMedium"
                      >
                        label_cash_status
                      </Typography>
                      <div className={styles.NoteItems}>
                        {machineViewData.cashStatus.isCoinFull ? (
                          <Typography
                            type="subtitle-1"
                            translate
                            color="onSurfaceHigh"
                          >
                            label_coin_full
                          </Typography>
                        ) : null}
                        {machineViewData.cashStatus.isBankFull ? (
                          <Typography
                            type="subtitle-1"
                            translate
                            color="onSurfaceHigh"
                          >
                            label_bank_full
                          </Typography>
                        ) : null}
                      </div>
                    </div>

                    <div className={styles.NoteContainer}>
                      <Typography
                        translate
                        type="caption"
                        color="onSurfaceMedium"
                      >
                        label_error_detail
                      </Typography>
                      <div className={styles.NoteItems}>
                        {machineViewData.errorDetails.map(
                          (errorDetail, index) => (
                            <div key={index}>
                              <Typography
                                type="subtitle-1"
                                color="onSurfaceHigh"
                              >
                                {errorDetail.description}
                              </Typography>
                              <Typography
                                type="subtitle-1"
                                color="onSurfaceHigh"
                              >
                                {" "}
                              </Typography>
                              <Typography
                                type="body-3"
                                color="onSurfaceDisabled"
                              >
                                {errorDetail.createdAt}
                              </Typography>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className={styles.NoteContainer}>
                      <div
                        style={{
                          maxWidth: "454px",
                          display: "flex",
                          gap: "24px",
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          rows={3}
                          multiline
                          value={
                            machineViewData.refillCandidateMetaData.locationNote
                          }
                          onChange={(e) =>
                            machineViewData.refillCandidateMetaData.handleLocationNoteChange(
                              e.target.value
                            )
                          }
                          label="label_note"
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "7px",
                          }}
                        >
                          <ToggleSwitch
                            isOn={
                              machineViewData.refillCandidateMetaData.critical
                            }
                            onClick={
                              machineViewData.refillCandidateMetaData
                                .toggleCritical
                            }
                          />
                          <Typography
                            type="body-2"
                            color="onSurfaceHigh"
                            translate
                          >
                            label_mark_as_critical
                          </Typography>
                        </div>
                        {machineViewData.refillCandidateMetaData.critical ? (
                          <TextField
                            value={
                              machineViewData.refillCandidateMetaData
                                .criticalNote
                            }
                            onChange={(e) =>
                              machineViewData.refillCandidateMetaData.handleCriticalNoteChange(
                                e.target.value
                              )
                            }
                            label="label_critical_note"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "150px" }}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          type="secondary"
          onClick={props.onClose}
          disabled={isLoading || isOptimizingLocation}
        >
          label_close
        </Button>
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <Button
            type="secondary"
            loading={isOptimizingLocation}
            disabled={isLoading}
            onClick={() => {
              optimizeLocation({ refillZoneId: props.routeId });
            }}
          >
            label_reroute
          </Button>
          <Button
            type="primary"
            loading={isLoading}
            onClick={createCandidates}
            disabled={disabledForm || isOptimizingLocation}
          >
            action_generate
          </Button>
        </div>
      </div>
    </div>
  );
}

const columns = {
  checkbox: "checkbox",
  routePosition: "routePosition",
  machineId: "machineId",
  priority: "priority",
  locationName: "locationName",
  lastRefill: "lastRefill",
  revenue: "revenue",
  type: "type",
  error: "error",
  lowSlots: "lowSlots",
} as const;
type Columns = (typeof columns)[keyof typeof columns];

const sortFnMap: Record<
  Columns,
  (machineA: OverviewTableRow, machineB: OverviewTableRow) => number
> = {
  checkbox: () => {
    throw new Error("Cannot sort by checkbox");
  },
  error: () => {
    throw new Error("Cannot sort by error");
  },
  locationName: () => {
    throw new Error("Cannot sort by locationName");
  },
  lowSlots: () => {
    throw new Error("Cannot sort by lowSlots");
  },
  type: () => {
    throw new Error("Cannot sort by type");
  },
  routePosition: (machineA, machineB) =>
    machineA.routePosition - machineB.routePosition,
  machineId: (machineA, machineB) =>
    machineA.machineId.localeCompare(machineB.machineId),
  priority: (machineA, machineB) => {
    const comparisonValue =
      (refillPriorityValueMap[machineA.refillPriority] ?? -100) -
      (refillPriorityValueMap[machineB.refillPriority] ?? -100);

    if (comparisonValue !== 0) return comparisonValue;
    return sortFnMap.routePosition(machineA, machineB);
  },
  lastRefill: (machineA, machineB) =>
    Number(machineA.lastRefill ?? 0) - Number(machineB.lastRefill ?? 0),
  revenue: (machineA, machineB) =>
    (machineA.revenue ?? 0) - (machineB.revenue ?? 0),
};

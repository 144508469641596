import i18n from "common/i18n";
import { GetLocationsV2Query, LocationKind } from "gql/generated";

export type LocationsTableDataRow = {
  id: string;
  friendlyId: string;
  locationName: string;
  kind: keyof typeof LocationKind;
  machineId: string[];
  locationType: string;
  refillZoneName: string;
  serviceZoneName: string;
  locationGrade?: string;
};

export function mapDataToTableLocationsV2(
  data: GetLocationsV2Query
): LocationsTableDataRow[] {
  const label_empty = i18n.t("table.column.label_empty", { ns: "location" });

  return data?.locationsV2?.edges
    ?.map((edge) => {
      if (!edge?.node) return null;

      const { id, friendlyId, locationName, locationType, __typename } =
        edge.node;

      let machineId: string[] = [];
      let refillZoneName = label_empty;
      let serviceZoneName = label_empty;
      let gradeWeekday = '-';
      let gradeWeekend = '-';

      if (__typename === "Factory" || __typename === "Store") {
        machineId = edge.node.machineIds?.filter(
          (id) => id !== null
        ) as string[];
      }

      if (__typename === "Location") {
        if (edge.node.machineId) {
          machineId = [edge.node.machineId];
        }
        refillZoneName = edge.node.refillZone.name;
        serviceZoneName = edge.node.serviceZone.name;
        gradeWeekday = edge.node.gradeWeekday || '-';
        gradeWeekend = edge.node.gradeWeekend || '-';
      }

      if (__typename === "Store") {
        refillZoneName = edge.node.refillZone.name;
        serviceZoneName = edge.node.serviceZone.name;
        gradeWeekday = edge.node.gradeWeekday || '-';
        gradeWeekend = edge.node.gradeWeekend || '-';
      }

      return {
        id,
        kind: __typename,
        friendlyId,
        locationName,
        machineId,
        locationType,
        refillZoneName,
        serviceZoneName,
        locationGrade: `${gradeWeekday}/${gradeWeekend}`
      };
    })
    .filter((row) => row !== null);
}

import { IAction } from 'common/interfaces';

import { ISupplier } from 'supplier/interfaces';

interface ISupplierState {
  [supplierId: string]: ISupplier
}

const initialState: ISupplierState = {};

function supplierReducer (
  state: ISupplierState = initialState,
  action: IAction
): ISupplierState {
  const [ domain, request, result ] = action.type.split('/');

  if (domain === 'SUPPLIER') {
    if (request.includes('GET_') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
  }

  return state;
}

export default supplierReducer;

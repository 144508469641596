import * as React from "react";
import { TextCard } from "./TextCard";
import { useGetDailyDrinkSalesQuery } from "gql/generated";

export function BestSellingDrinkMenu(): JSX.Element {
  const getDailyMachineSalesResult = useGetDailyDrinkSalesQuery(undefined, {
    enabled: false,
    useErrorBoundary: false,
  });
  return (
    <TextCard
      isError={getDailyMachineSalesResult.isError}
      data={getDailyMachineSalesResult.data?.dailyDrinkSales
        .slice(0, 5)
        .map((drink) => ({
          label: drink.drinkName,
          value: drink.totalCount.toLocaleString(),
        }))}
      icon="CoffeeCup"
      title="Best-Selling Drink Menu"
      labelType="Drink name"
      valueType="Total Cup Sales"
    />
  );
}

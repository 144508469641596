import * as React from "react";
import { clsx } from "clsx";
import { MachineStatusEnum } from "gql/generated";
import { useTranslation } from "react-i18next";

export const MachineStatus = ({
  status,
  className,
}: {
  status: MachineStatusEnum;
  className?: string;
}) => {
  const { t } = useTranslation();
  const baseClassName = clsx(
    "h-4 leading-4 text-caption px-4 py-1 rounded-full flex-1 whitespace-nowrap text-center font-kanit",
    {
      "bg-success-overlay": status === MachineStatusEnum.Online,
      "bg-on-surface-disabled text-white": status === MachineStatusEnum.Offline,
      "bg-error-200": status === MachineStatusEnum.OutOfService,
      "bg-warning-overlay": status === MachineStatusEnum.Maintenance,
    },
    className
  );

  function machineStatusLabel (status: MachineStatusEnum) {
    switch (status) {
      case MachineStatusEnum.Online:
        return t("machine_status.online_label");
      case MachineStatusEnum.Offline:
        return t("machine_status.offline_label");
      case MachineStatusEnum.Maintenance:
        return t("machine_status.maintenance_label");
      case MachineStatusEnum.OutOfService:
        return t("machine_status.out_of_service_label");
      default:
        return "";
    }
  }

  return <div className={baseClassName}>{machineStatusLabel(status)}</div>;
};

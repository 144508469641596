import { GetMachineCashQuery, MachineStatusEnum } from "gql/generated";

export type CashTableDataRow = {
  machineId: string;
  status: MachineStatusEnum;
  organizationName: string | null;
  locationName: string | null;
  cashValue: string | null;
  bankNoteCount: number | null;
  refillZoneName: string | null;
};

export function mapDataToTable(data: GetMachineCashQuery): CashTableDataRow[] {
  return data?.machineMonitors?.edges?.map((edge) => {
    const node = edge?.node;
    return {
      status: node?.status,
      machineId: node?.machineId,
      locationName: node?.locationName,
      organizationName: node?.organizationName,
      refillZoneName: node?.refillZoneName,
      bankNoteCount: node?.bankNoteCount,
      cashValue: node?.cashValue.toString(),
    };
  });
}

/* eslint-disable react/display-name */
import * as React from "react";

import * as moment from "moment";

import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";

import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import {
  DataPage,
  UsePaginatedPlanArchivesFilter,
  ROWS_PER_PAGE,
  usePaginatedPlanArchives,
} from "./usePaginatedPlanArchives";
import { generatePageRange } from "transactions/utils/generatePageRange";
import { useImmer } from "use-immer";
import Checkbox from "components/Checkbox";
import TextButton from "common/components/textbutton/TextButton";
import {
  PaginatorNavigationContainer,
  PaginatorDoubleLeftChevron,
  PaginatorLeftChevron,
  PaginatorPage,
  PaginatorRightChevron,
  PaginatorDoubleRightChevoron,
} from "./Paginator";
import {
  Header,
  TextButtonsContainer,
  OperationTable,
  Container,
} from "containers/new-operations/Components";
import { useDownloadInventorySummaryByPlanId } from "containers/new-operations/hooks";

export function Complete() {
  const [appliedFilter, setAppliedFilter] = React.useState<
    Array<IAppliedFilter>
  >([]);
  const memoizedAppliedFilter = React.useMemo(
    () => mapFilterStateToPaginatedArchiveArgs(appliedFilter),
    [appliedFilter]
  );

  const {
    isLoading,
    currentPageData,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPage,
    currentPageNumber,
    totalNumberOfPages,
    totalNumberOfRecords,
    goToPreviousPage,
  } = usePaginatedPlanArchives({
    filter: memoizedAppliedFilter,
  });

  const plans = React.useMemo(
    () => (isLoading ? undefined : mapDataToTableRow(currentPageData)),
    [currentPageData, isLoading]
  );

  const { isLoading: isDownloadingReport, downloadInventorySummaryByPlanId } =
    useDownloadInventorySummaryByPlanId();

  const [selectedIds, setSelectedPlanIds] = useImmer<{
    [planId: string]: boolean;
  }>({});
  function getSelectedIdsLength() {
    return Object.keys(selectedIds).length;
  }
  function isIdSelected(id: string) {
    return Boolean(selectedIds[id]);
  }
  function toggleId(id: string) {
    setSelectedPlanIds((draft) => {
      if (isIdSelected(id)) {
        delete draft[id];
      } else {
        draft[id] = true;
      }
    });
  }

  return (
    <>
      <Container>
        <Header>
          <TextButtonsContainer>
            <TextButton
              icon="DownloadTray"
              translationKey="action_download"
              disabled
            />
          </TextButtonsContainer>

          <Typography type="body-2" translate>
            {getSelectedIdsLength()}
            {` `}
            {getSelectedIdsLength() === 1 ? "label_plan" : "label_plans"}
            {` `}
            label_selected
          </Typography>
        </Header>
        <PureFilterBar
          placeholder="label_search"
          filterOptions={[
            {
              field: columns.planName,
              queryType: "contains",
              translationKey: "label_plan",
            },
            {
              field: columns.route,
              queryType: "contains",
              translationKey: "label_route",
            },
            {
              field: columns.warehouse,
              queryType: "contains",
              translationKey: "label_warehouse",
            },
          ]}
          onChange={setAppliedFilter}
          value={appliedFilter}
        />

        <OperationTable
          isLoading={isLoading}
          getKey={(plan) => plan.planId}
          isSelected={(plan) => isIdSelected(plan.planId)}
          data={plans}
          columns={[
            {
              cell: (plan) => (
                <Checkbox
                  checked={isIdSelected(plan.planId)}
                  onClick={() => {
                    toggleId(plan.planId);
                  }}
                />
              ),
              headerCell: () => <div></div>,
              id: columns.checkbox,
              style: {
                flex: "0 0 42px",
                minWidth: "42px",
                paddingRight: "var(--table-h-padding)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            },
            {
              id: columns.planName,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_plan
                </Typography>
              ),
              cell: (plan) => (
                <div
                  style={{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {plan.planName}
                </div>
              ),
              style: {
                flex: "1 0 160px",
                minWidth: "160px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.route,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_route
                </Typography>
              ),
              cell: (plan) => (
                <div
                  style={{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {plan.route}
                </div>
              ),
              style: {
                flex: "1 0 96px",
                minWidth: "96px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.machinesInRoute,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_machines_in_route
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.machinesInRoute}
                </Typography>
              ),
              style: {
                flex: "1 0 115px",
                minWidth: "115px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.complete,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_complete
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.completed}
                </Typography>
              ),
              style: {
                flex: "0 0 94px",
                minWidth: "94px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.incomplete,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_incomplete
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.incomplete}
                </Typography>
              ),
              style: {
                flex: "0 0 114px",
                minWidth: "114px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.completeDate,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_complete_date
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.completedDate
                    ? moment(Number(plan.completedDate)).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              ),
              style: {
                flex: "1 0 150x",
                minWidth: "150px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.warehouse,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_warehouse
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.warehouseName}
                </Typography>
              ),
              style: {
                flex: "1 0 154px",
                minWidth: "154px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.status,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_status
                </Typography>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh" translate>
                  {plan.status === "COMPLETE"
                    ? "label_complete"
                    : "action_cancel"}
                </Typography>
              ),
              style: {
                flex: "1 0 154px",
                minWidth: "154px",
                display: "flex",
                alignItems: "center",
              },
            },

            {
              id: columns.inventorySummary,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_inventory_summary
                </Typography>
              ),
              cell: (plan) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "grid",
                      placeContent: "center",
                    }}
                    onClick={
                      isDownloadingReport
                        ? () => {}
                        : () =>
                            downloadInventorySummaryByPlanId(
                              plan.planId,
                              plan.planName
                            )
                    }
                  >
                    <Icon name="DownloadTray" color="primary500" />
                  </div>
                </div>
              ),
              style: {
                flex: "0 0 170px",
                minWidth: "170px",
                display: "flex",
                alignItems: "center",
              },
            },
          ]}
        />
        <div style={{ height: "13px" }}></div>
        <div
          style={{
            visibility: totalNumberOfPages === undefined ? "hidden" : "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "-13px",
          }}
        >
          <PaginatorNavigationContainer>
            <PaginatorDoubleLeftChevron onClick={goToFirstPage} />
            <PaginatorLeftChevron onClick={goToPreviousPage} />
            {generatePageRange(currentPageNumber, totalNumberOfPages).map(
              (pageNumber) => (
                <PaginatorPage
                  key={pageNumber}
                  pageNumber={pageNumber}
                  active={pageNumber === currentPageNumber}
                  onClick={() => goToPage(pageNumber)}
                />
              )
            )}
            <PaginatorRightChevron onClick={goToNextPage} />
            <PaginatorDoubleRightChevoron onClick={goToLastPage} />
          </PaginatorNavigationContainer>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: "6px",
              fontWeight: 500,
            }}
          >
            {generateRecordRange(
              computeStartAndEndRecordNumber(
                currentPageNumber,
                totalNumberOfRecords,
                totalNumberOfPages
              )
            )}{" "}
            Of {totalNumberOfRecords} items
          </div>
        </div>
      </Container>
    </>
  );
}

function generateRecordRange({
  startingRecordNumber,
  endingRecordNumber,
}: {
  startingRecordNumber: number;
  endingRecordNumber: number;
}) {
  if (startingRecordNumber === 0) {
    return "0";
  } else {
    return `${startingRecordNumber} - ${endingRecordNumber}`;
  }
}

function computeStartAndEndRecordNumber(
  currentPageNumber: number,
  totalNumberOfRecords: number,
  totalNumberOfPages: number
) {
  let startingRecordNumber: number;
  let endingRecordNumber: number;
  let rowsOnPage: number;

  const orphanLength = totalNumberOfRecords % ROWS_PER_PAGE;

  if (totalNumberOfRecords === 0) {
    startingRecordNumber = 0;
    endingRecordNumber = 0;
  } else if (currentPageNumber === totalNumberOfPages && orphanLength !== 0) {
    rowsOnPage = orphanLength;
    startingRecordNumber = (currentPageNumber - 1) * 10 + 1;
    endingRecordNumber = startingRecordNumber + rowsOnPage - 1;
  } else {
    rowsOnPage = ROWS_PER_PAGE;
    startingRecordNumber = (currentPageNumber - 1) * 10 + 1;
    endingRecordNumber = startingRecordNumber + rowsOnPage - 1;
  }

  return {
    startingRecordNumber,
    endingRecordNumber,
  };
}

function mapFilterStateToPaginatedArchiveArgs(
  appliedFilter: IAppliedFilter[]
): UsePaginatedPlanArchivesFilter {
  const filterObject: UsePaginatedPlanArchivesFilter = {
    range: {
      startTime: moment().subtract(3, "days").startOf("day").valueOf() + "",
      endTime: moment().endOf("day").valueOf() + "",
    },
  };

  const filteredPlan = appliedFilter.find(
    (filter) => filter.field === columns.planName
  );
  if (filteredPlan) {
    filterObject.planName = filteredPlan.value;
  }

  const filteredWarehouse = appliedFilter.find(
    (filter) => filter.field === columns.warehouse
  );
  if (filteredWarehouse) {
    filterObject.warehouseName = filteredWarehouse.value;
  }

  const filteredRoute = appliedFilter.find(
    (filter) => filter.field === columns.route
  );
  if (filteredRoute) {
    filterObject.routeName = filteredRoute.value;
  }

  return filterObject;
}

interface TableRow {
  planId: string;
  planName: string;
  route: string;
  machinesInRoute: number;
  completed: number;
  incomplete: number;
  completedDate: string;
  warehouseName: string;
  status: "COMPLETE" | "FAIL" | "CANCEL";
}

function mapDataToTableRow(data: DataPage): Array<TableRow> {
  return data.map((element) => ({
    planId: element.node.id,
    planName: element.node.name,
    route: element.node.refillZoneName.join(", "),
    machinesInRoute: element.node.refillOrderArchives.length,
    completed: element.node.refillOrderArchives.filter(
      (roa) => roa.state === "completed"
    ).length,
    incomplete: element.node.refillOrderArchives.filter(
      (roa) => roa.state === "failed"
    ).length,
    completedDate: element.node.completedAt,
    warehouseName: element.node.warehouseName,
    status: element.node.status === "completed" ? "COMPLETE" : "CANCEL",
  }));
}

const columns = {
  checkbox: "checkbox",
  planName: "planName",
  machinesInRoute: "machinesInRoute",
  complete: "complete",
  incomplete: "incomplete",
  completeDate: "completeDate",
  warehouse: "warehouse",
  route: "route",
  status: "status",
  inventorySummary: "inventorySummary",
} as const;

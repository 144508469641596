import * as React from 'react';

import Icon from 'common/components/icon/Icon';
import * as styles from './MemberIconCell.module.scss';
import { isAdminContractRole, isAdminRole } from "utils/user-role";

interface IRowData {
  role: string;
}

interface IMemberIconCellProps {
  onClick: (rowData: IRowData) => void;
  icon: string;
}

export function MemberIconCell({ onClick, icon }: IMemberIconCellProps) {
  return function Component(rowData: IRowData): JSX.Element {
    if (isAdminRole(rowData.role) || isAdminContractRole(rowData.role)) {
      return <></>;
    }
    return (
      <>
        <div onClick={() => onClick(rowData)} className={styles.MemberIconCell}>
          <Icon color='primary' name={icon} />
        </div>
      </>
    );
  };
}

import * as React from "react";
import { Dialog } from "@mui/material";
import { FilePicker } from "common/components/FilePicker";
import Button from "components/Button";
import Typography from "components/Typography";
import { toasti18n } from "utils/toast";
import * as styles from "./Base64FileUploadModal.module.scss";

interface Props {
  onFileUploadSuccess?: () => void;
  accept?: string | string[];
  uploadFn: (base64XLSXFile: string) => Promise<any>;
  onClose: () => void;
  placeHolderText?: string;
  uploadButtonLabel?: string;
  modalTitle?: string;
}

export const ACCEPT_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV: ".csv",
  XLS: "application/vnd.ms-excel",
};

function Base64FileUploadModal({
  onFileUploadSuccess = () => {},
  uploadFn,
  accept,
  onClose,
  placeHolderText,
  uploadButtonLabel,
  modalTitle,
}: Props) {
  const [based64File, setBase64File] = React.useState<string>("");
  const [fileName, setFileName] = React.useState("");
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);
  const isUploadDisabled = based64File === "";

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files.length === 0) {
      return;
    }
    const xlsx = new FileReader();
    xlsx.readAsDataURL(e.target.files[0]);
    xlsx.onload = () => {
      setBase64File(xlsx.result.toString());
      setFileName(e.target.files[0].name);
    };
  }

  async function handleSubmit() {
    setIsUploadingFile(true);
    try {
      await uploadFn(based64File);
      toasti18n.success();
      onClose();
      onFileUploadSuccess();
    } catch (err) {
      setIsUploadingFile(false);
    }
  }

  return (
    <Dialog open={true} onClose={isUploadingFile ? () => {} : onClose}>
      <div className={styles.Base64FileUploadModal}>
        <div className={styles.Header}>
          <Typography type="headline-6" translate>
            {modalTitle ?? "action_upload"}
          </Typography>
        </div>
        <FilePicker
          id="XLSXFile"
          onChange={handleChange}
          accept={
            accept
              ? typeof accept === "string"
                ? accept
                : accept.join(", ")
              : undefined
          }
        >
          <FilePicker.FormField
            icon="Upload"
            iconColor="primary500"
            placeHolderText={placeHolderText ?? "action_select_file"}
            fileName={fileName}
          />
        </FilePicker>
        <div className={styles.ButtonsGroup}>
          <Button onClick={onClose} disabled={isUploadingFile} type="secondary">
            action_cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={isUploadDisabled}
            loading={isUploadingFile}
          >
            {uploadButtonLabel ?? "action_upload"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default Base64FileUploadModal;

import * as React from "react";
import Input from "common/components/input/Input";
import * as z from "zod";
import { httpClient } from "common/services/transportService";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";

function useGetChangePasswordSchema() {
  const { t } = useTranslation("setting", {
    keyPrefix: "change_password_page",
  });

  const changePasswordSchema = z
  .object({
    oldPassword: z
      .string()
      .min(1, { message: t("label_old_password_required") }),
    newPassword: z
      .string({ message: t("label_new_password_required") })
      .min(8, t("label_new_password_min_length")),
    confirmPassword: z.string().min(1, t("label_confirm_password_required")),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: t("label_passwords_dont_match"),
    path: ["confirmPassword"], // set the path of the error message
  });
  return { changePasswordSchema };
};

export function ChangePassword() {
  const { t } = useTranslation("setting", {
    keyPrefix: "change_password_page",
  });
  const { changePasswordSchema } = useGetChangePasswordSchema();
  const { data: userInfo } = useGetCurrentUser();

  // State for form data
  const [formData, setFormData] = React.useState<
    z.infer<typeof changePasswordSchema>
  >({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // State for form errors
  const [formErrors, setFormErrors] = React.useState<Partial<z.ZodIssue[]>>([]);

  // Handle form field change
  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    validateForm();
  };

  // Validate the form using Zod schema
  const validateForm = () => {
    try {
      changePasswordSchema.parse(formData); // This throws if validation fails
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        setFormErrors(error.errors); // Set validation errors
      }
      return false;
    }
  };

  // Function to get error for a field
  const getErrorForField = (field: keyof z.infer<typeof changePasswordSchema>) => {
    const error = formErrors.find((err) => err.path.includes(field));
    return error ? error.message : null;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await httpClient.post<string>("/auth/reset-password", {
          newPassword: formData.newPassword,
          oldPassword: formData.oldPassword,
          userId: userInfo?.userId,
        });

        if (response.status === 200) {
          toast.success(t("password_change_success"));
          setFormErrors([]);
          setFormData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }
      } catch (error) {
        toast.error(t("label_something_went_wrong"));
      }
    }
  };

  return (
    <div className="flex-1">
      <h1 className="text-h5 font-main font-medium">{t("label_title")}</h1>
      <hr className="my-3" />
      <form className="space-y-3">
        <div>
          <Input
            className="max-w-[300px]"
            type="password"
            label={t("label_old_password")}
            value={formData.oldPassword}
            onChange={(value) =>
              handleChange({ name: "oldPassword", value: value })
            }
          />
          {getErrorForField("oldPassword") && (
            <span className="text-error text-caption">
              {getErrorForField("oldPassword")}
            </span>
          )}
        </div>
        <div>
          <Input
            className="max-w-[300px]"
            type="password"
            label={t("label_new_password")}
            value={formData.newPassword}
            onChange={(value) =>
              handleChange({ name: "newPassword", value: value })
            }
          />
          {getErrorForField("newPassword") && (
            <span className="text-error text-caption">
              {getErrorForField("newPassword")}
            </span>
          )}
        </div>
        <div>
          <Input
            className="max-w-[300px]"
            type="password"
            label={t("label_confirm_password")}
            value={formData.confirmPassword}
            onChange={(value) =>
              handleChange({ name: "confirmPassword", value: value })
            }
          />
          {getErrorForField("confirmPassword") && (
            <span className="text-error text-caption">
              {getErrorForField("confirmPassword")}
            </span>
          )}
        </div>
        <Button onClick={handleSubmit} size="medium" type="primary">
          {t("label_change_password")}
        </Button>
      </form>
    </div>
  );
}

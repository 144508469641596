import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectPendingRequest } from 'common/store/selectors/requestSelectors';
import useCreatePdf from 'common/hooks/useCreatePdf';

import { selectRefillOrder } from 'refillorders/selectors';
import {
  patchAdjustRefillOrder,
  patchRefillRefillOrder,
  patchPrekitRefillOrder,
  patchPickupRefillOrder,
} from 'refillorders/actions';

import {
  StringCell,
  InputCell,
  NumberCell,
  ImageCell,
} from 'common/components/table/cells/Cells';
import Table, { IColumn } from 'common/components/table/Table';
import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';
import TextButton from 'common/components/textbutton/TextButton';

import OrderForm from 'warehousing/components/orderform/OrderForm';

import { prepareItems } from '../refillOrderUtils';

import * as styles from './AdjustOrderModal.module.scss';

interface IAdjustOrderModalProps {
  orderNumber: string;
  onSubmit?: () => void;
  onClose: () => void;
}

function AdjustOrderModal({
  orderNumber,
  onSubmit,
  onClose,
}: IAdjustOrderModalProps): JSX.Element {
  const dispatch = useDispatch();

  const { loading: createPdfLoading, createPdf } = useCreatePdf(
    `ordernumber-${orderNumber}`,
    `ordernumber-${orderNumber}`
  );

  const [justification, setJustification] = useState<string>(null);
  const [changedQuantities, setChangedQuantities] = useState({});
  const [mode, setMode] = useState<'refill' | 'adjust'>('refill');

  const order = useSelector(selectRefillOrder(orderNumber));
  const adjustLoading: boolean = useSelector(
    selectPendingRequest('REFILL_ORDER/PATCH_ADJUST')
  );

  useEffect(() => {
    const adjustments = Object.values(changedQuantities)
      .filter((i) => i !== null)
      .filter((i) => i !== undefined);
    if (adjustments.length) {
      setMode('adjust');
    } else {
      setJustification(null);
      setMode('refill');
    }
  }, [changedQuantities]);

  function handleQuantityChange(id: string, newValue: number | string): void {
    setChangedQuantities((rest) => {
      return {
        ...rest,
        [id]: newValue,
      };
    });
  }

  function handleClose(): void {
    setJustification(null);
    setChangedQuantities({});
    setMode('refill');
    onClose();
  }

  async function handleFlagAdjustment(): Promise<void> {
    const result = await dispatch(
      patchRefillRefillOrder({
        orderNumber: order.orderNumber,
        itemsRefilled: order.itemsRequested.map((i) => {
          return {
            ...i,
            workInPackages: false,
          };
        }),
        needAdjustment: true,
      })
    );

    if (result) {
      if (onSubmit) {
        onSubmit();
      }
      return handleClose();
    }
  }

  async function handleSubmit(): Promise<void> {
    let result;

    if (order?.status === 'created') {
      result = await dispatch(patchPrekitRefillOrder(order.orderNumber));
    }
    if (order?.status === 'ready_for_collection') {
      result = await dispatch(patchPickupRefillOrder(order.orderNumber));
    }

    if (order?.status === 'en_route' || order?.status === 'adjustment') {
      if (mode === 'refill') {
        result = await dispatch(
          patchRefillRefillOrder({
            orderNumber,
            itemsRefilled: order.itemsRequested,
            needAdjustment: false,
          })
        );
      }
      if (mode === 'adjust') {
        result = await dispatch(
          patchAdjustRefillOrder({
            orderNumber,
            itemsAdjusted: order.itemsAdjusted.map((i) => {
              return {
                ...i,
                // This is using the old adjust API, will need to change this later
                packageQuantity:
                  changedQuantities[(i as any).lotId] || (i as any).value,
              };
            }),
          })
        );
      }
    }

    if (result) {
      if (onSubmit) {
        onSubmit();
      }
      return handleClose();
    }
  }

  function checkSubmitDisabled() {
    if (mode === 'adjust') {
      if (!justification) {
        return true;
      }
    }
    return false;
  }

  function getSubmitLabel(): string {
    if (order.status === 'created') {
      return 'refill_ready_for_collection';
    }
    if (order.status === 'ready_for_collection') {
      return 'refill_collect_prekit';
    }
    if (mode === 'adjust') {
      return 'action_adjust';
    }
    if (mode === 'refill') {
      return 'action_refill';
    }
  }

  if (!order) {
    return null;
  }

  return (
    <>
      <OrderForm order={order} />

      <Modal
        isOpen={!!orderNumber}
        onClose={handleClose}
        contentClassName={styles.AdjustOrderModal}
      >
        <div className={styles.header}>
          <Typography type='headline-6' translationKey='label_order_number' />
          &nbsp;
          <Typography type='headline-6' text={orderNumber} />
        </div>

        <Table
          className={styles.table}
          data={prepareItems(order) || []}
          disableScrollToTop={true}
          columns={[
            {
              dataKey: 'slot',
              headerLabel: 'label_slot',
              cellRenderer: StringCell,
              align: 'flex-start',
              columnFlex: 0.4,
              showMobile: true,
            },
            {
              dataKey: 'image',
              cellRenderer: ImageCell,
              align: 'flex-start',
              sortable: false,
              columnWidth: 50,
            },
            {
              dataKey: 'name',
              headerLabel: 'label_ingredient',
              cellRenderer: StringCell,
              align: 'flex-start',
              columnFlex: 1.5,
              showMobile: true,
            },
            {
              dataKey: 'lots',
              headerLabel: 'label_lot_nos',
              cellRenderer: StringCell,
              align: 'center',
              columnFlex: 0.6,
              showMobile: true,
            },
            {
              dataKey: 'packageQuantity',
              headerLabel: 'label_quantity_per_package',
              cellRenderer: StringCell,
              align: 'center',
              columnFlex: 1,
            },
            {
              dataKey: 'quantity',
              headerLabel: 'label_quantity',
              cellRenderer: NumberCell(),
              align: 'center',
              columnFlex: 0.8,
              showMobile: true,
            },
            {
              dataKey: 'total',
              headerLabel: 'label_total',
              cellRenderer: StringCell,
              align: 'flex-start',
              columnFlex: 1,
              showMobile: true,
            },
            ...(order?.status === 'adjustment'
              ? [
                  {
                    headerLabel: 'label_adjustment',
                    cellRenderer: InputCell({
                      inputMap: changedQuantities,
                      onChange: handleQuantityChange,
                      idKey: 'lotId',
                      type: 'number',
                    }),
                    align: 'flex-start',
                    columnFlex: 1,
                    showMobile: true,
                  } as IColumn,
                ]
              : []),
          ]}
        />

        <div className={styles.divider} />

        {mode === 'adjust' && (
          <div className={styles.justification}>
            <Input
              value={justification}
              onChange={setJustification}
              label='label_justification'
              renderStyle='box'
            />
            <Typography
              translationKey='refill_adjustment_description'
              type='caption'
              className={styles.description}
            />
          </div>
        )}

        <div className={styles.bottomSection}>
          <div className={styles.left}>
            <div className={styles.row}>
              <Input
                value={`${order.machineId}/${order.type}`}
                label='label_machine_id_type'
                renderStyle='none'
              />
              <Input
                value={'N/A'}
                label='label_warehouse'
                renderStyle='none'
              />
            </div>
            <div className={styles.row}>
              <Input
                value={order.name}
                label='label_machine_name'
                renderStyle='none'
              />
              <Input
                value={order.assignee}
                label='label_operator'
                renderStyle='none'
              />
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <Button
            translationKey='action_cancel'
            onClick={handleClose}
            type='secondary'
          />
          <div className={styles.right}>
            <TextButton
              translationKey='action_print_order'
              onClick={createPdf}
              icon='Printer'
              className={styles.print}
              loading={createPdfLoading}
            />
            {order?.status === 'en_route' && (
              <Button
                translationKey='refill_flag_adjustment'
                onClick={handleFlagAdjustment}
                type='primary'
                className={styles.adjustment}
                disabled={true}
              />
            )}
            {order?.status !== 'completed' && (
              <Button
                translationKey={getSubmitLabel()}
                onClick={handleSubmit}
                disabled={checkSubmitDisabled()}
                type='primary'
                loading={adjustLoading}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdjustOrderModal;

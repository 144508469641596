import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { deleteMachineUser } from 'machines/service';
import { machineKeys } from './machineKeys';
import { IMachineUser } from 'machines/interfaces';
import { toasti18n } from 'utils/toast';

function useDeleteMachineUser () {
    const queryClient = useQueryClient();
    // use mutation , return value , error , var
    return useMutation<string, AxiosError, IMachineUser>(
        (userDetails:IMachineUser) => deleteMachineUser(userDetails),{
            onSuccess:(_,{machineId}) => {
                toasti18n.success("toast_user_delete_success");
                queryClient.invalidateQueries(machineKeys.machineUsers(machineId));
            }
        }
    );
}

export { useDeleteMachineUser };
import * as React from "react";
import * as moment from "moment";
import { formatMomentDateFullYear } from "common/utils/format";
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";

type ExpirationDateCellProps = {
  lastRefilled?: number;
  expiryDate?: string;
}

export function ExpirationDateCell({ expiryDate, lastRefilled }: ExpirationDateCellProps) {
  const { t } = useTranslation();
  // --- Last refilled date ---
  let refillDateString: string;

  if (lastRefilled === null) {
    refillDateString = "N/A";
  } else {
    refillDateString = formatMomentDateFullYear(
      moment.unix(Number(lastRefilled) / 1000)
    );
  }

  // --- Expiry date ---
  let expiryDateString: string;
  let expired: boolean;

  if (expiryDate === null) {
    expiryDateString = "N/A";
    expired = false;
  } else if (Date.now() > new Date(expiryDate).getTime()) {
    expired = true;
  } else {
    expiryDateString = formatMomentDateFullYear(
      moment.unix(new Date(expiryDate).getTime() / 1000)
    );
    expired = false;
  }

  return (
    <div>
      {/* Expiry date */}
      <p className={clsx("text-body2", expired && "text-error")}>{expiryDateString || t("label_expired")}</p>

      {/* Last refill */}
      <p className="text-caption text-on-surface-disabled whitespace-nowrap">{t("label_last_refill") + " " + refillDateString}</p>
    </div>
  );
}


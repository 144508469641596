import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';

import Icon from 'common/components/icon/Icon';

import { clsx } from 'clsx';

export interface IViewNavOption {
  icon: string,
  to: string,
  rootPath?: string;
}

export interface IViewNavProps {
  options: IViewNavOption[]
}

function ViewNav({ options }: IViewNavProps) {
  const { pathname } = useLocation();

  return (
    <div className="flex rounded-[4px]">
      {options.map((i, index) => (
        <Link
          key={index}
          to={i.to}
          className={clsx(
            "flex items-center justify-center w-12 h-12 border border-solid !border-r-0 border-outline",
            "first:rounded-l-[4px] last:rounded-r-[4px] last:!border-r",
            "cursor-pointer hover:bg-primary-200",
            pathname.includes(i.rootPath || i.to) && "bg-primary-200"
          )}
        >
          <Icon name={i.icon} color="onBackground" />
        </Link>
      ))}
    </div>
  );
}

export default ViewNav;

import * as React from "react";

import Typography from "components/Typography";
import { Dialog, Transition } from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";

import { useTranslation } from "react-i18next";
import { useErrorBoard } from "./hooks/useErrorBoard";

import * as moment from "moment";

export function ModuleScoreBreakdownModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation("errorBoard");
  const errorBoard = useErrorBoard();

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed top-0 h-[100dvh] z-10 w-screen overflow-y-hidden flex items-end justify-center box-border sm:items-center sm:p-0 ">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
            enterTo="translate-y-[0%] opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100 translate-y-[0%]"
            leaveTo="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="h-[calc(100%-170px)] w-full sm:w-[unset] sm:h-[calc(100%-80px)] sm:max-h-[600px] sm:relative rounded-lg rounded-b-none sm:rounded-b-lg bg-white overflow-hidden shadow-xl flex flex-col">
              <div className="bg-white p-6 pb-0">
                <div className="flex items-center justify-between">
                  <Dialog.Title>
                    <Typography type="headline-6" color="onSurfaceHigh">
                      {t("moduleScoreBreakdown.moduleScore")}
                    </Typography>
                  </Dialog.Title>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-transparent outline-none p-0 flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="black"
                        fillOpacity="0.87"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-6 flex items-center">
                {errorBoard.state.data.lastUpdated ? (
                  <Typography
                    type="body-2"
                    color="onSurfaceMedium"
                    className="animate-fade-in"
                  >
                    {moment(errorBoard.state.data.lastUpdated).format(
                      "MMM DD, YYYY HH:mm"
                    )}
                  </Typography>
                ) : null}
              </div>
              <div className="flex-[1_1_0] min-h-0">
                <ErrorBoundary
                  fallback={({ error }) => <Fallback error={error} />}
                >
                  <ModelContents />
                </ErrorBoundary>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function ModelContents() {
  const errorBoard = useErrorBoard();
  const { t } = useTranslation("errorBoard");

  const translatedErrors =
    errorBoard.state.data.errorOverviews.moduleScore.breakDown.details.map(
      (error) => ({
        ...error,
        label: TRANSLATION_MAP[error.name] ?? error.name,
      })
    );

  return (
    <div className="h-full box-border py-6 overflow-auto hide-scroll-bar flex-col px-6 flex">
      <div className="grid [grid-template-columns:1fr_auto_minmax(auto,80px)]">
        <span className="py-3 px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-solid border-r-0 border-outline rounded-tl-lg">
          {t("moduleScoreBreakdown.errorScorePoint")}
        </span>
        <span className="px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-solid border-outline max-w-[78px] box-border">
          {t("moduleScoreBreakdown.noOfMachine")}
        </span>
        <span className="py-3 px-[14px] flex items-center text-[10px] font-kanit leading-4 tracking-[0.4px] text-on-surface-medium bg-black-overlay-4 border-[1px] border-l-0 border-solid border-outline rounded-tr-lg box-border">
          {t("moduleScoreBreakdown.score")}
        </span>

        {translatedErrors.map((error) => (
          <>
            <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap border-b-[1px] border-l-[1px] border-outline border-solid">
              {t(error.label)} {`(${error.multiplier})`}
            </span>
            <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap border-[1px] border-t-0 border-outline border-solid">
              {error.count}
            </span>
            <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap text-error border-r-[1px] border-b-[1px] border-outline border-solid">
              {error.score}
            </span>
          </>
        ))}

        <span className="py-3 px-[14px] flex items-center text-subtitle2 leading-6 bg-primary-50 border-b-[1px] border-l-[1px] border-outline border-solid rounded-bl-lg">
          {t("moduleScoreBreakdown.totalRawScore")}
        </span>
        <span className="py-3 px-[14px] flex items-center text-body2 bg-primary-50 border-[1px] border-t-0 border-outline border-solid">
          {
            errorBoard.state.data.errorOverviews.moduleScore.breakDown
              .totalCount
          }
        </span>
        <span className="py-3 px-[14px] flex items-center text-subtitle2 leading-6 text-error bg-primary-50 border-r-[1px] border-b-[1px] border-outline border-solid rounded-br-lg">
          {
            errorBoard.state.data.errorOverviews.moduleScore.breakDown
              .totalRawScore
          }
        </span>

        <div className="h-6 col-span-3"></div>

        <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap border-[1px] border-outline border-solid rounded-tl-lg">
          {t("moduleScoreBreakdown.totalRawScore")}
        </span>
        <span className="py-3 px-[14px] flex items-center justify-center text-subtitle2 whitespace-nowrap border-[1px] border-l-0 border-outline border-solid col-span-2 rounded-tr-lg">
          {
            errorBoard.state.data.errorOverviews.moduleScore.breakDown
              .totalRawScore
          }
        </span>

        <span className="py-3 px-[14px] flex items-center text-body2 whitespace-nowrap border-[1px] border-t-0 border-outline border-solid ">
          {t("moduleScoreBreakdown.machineCount")}
        </span>
        <span className="py-3 px-[14px] flex items-center justify-center text-subtitle2 whitespace-nowrap border-[1px] border-t-0 border-l-0 border-outline border-solid col-span-2">
          {
            errorBoard.state.data.errorOverviews.moduleScore.breakDown
              .machineCount
          }
        </span>

        <span className="py-3 px-[14px] flex items-center text-subtitle2 leading-6 bg-primary-50 border-[1px] border-t-[0px] border-outline border-solid rounded-bl-lg">
          {t("moduleScoreBreakdown.moduleScorePoint")}
        </span>
        <span className="py-3 px-[14px] flex items-center justify-center text-subtitle2 leading-6 bg-primary-50 border-r-[1px] border-b-[1px] border-outline border-solid rounded-br-lg col-span-2">
          {errorBoard.state.data.errorOverviews.moduleScore.percentage.toFixed(
            2
          )}
        </span>
      </div>
    </div>
  );
}

export function Fallback({ error }: { error: Error }) {
  const { t } = useTranslation();
  return (
    <div className="h-full flex justify-center items-center overflow-hidden rounded-lg animate-fade-in">
      <div className={"text-center w-full min-w-96 pb-20"}>
        <Typography color="onSurfaceMedium" translate type="headline-6">
          {t("label_an_error_has_occured")}
        </Typography>

        <div>
          <Typography color="onSurfaceMedium" translate type="caption">
            {error.message}
          </Typography>
        </div>
      </div>
    </div>
  );
}

const TRANSLATION_MAP = {
  "Ice Error": "moduleScoreBreakdown.iceError",
  "Machine Error": "moduleScoreBreakdown.machineError",
  Offline: "moduleScoreBreakdown.offline",
  "Out of Service": "moduleScoreBreakdown.outOfService",
  "Coffee Error": "moduleScoreBreakdown.coffeeError",
  "Soda Error": "moduleScoreBreakdown.sodaError",
  "Ice Rate Low": "moduleScoreBreakdown.iceRateLow",
};

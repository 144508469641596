import * as React from "react";

interface IPriorityUrgentProps {
  className?: string;
}

function PriorityUrgent({ className }: IPriorityUrgentProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.98446 3.20084L3.48446 5.90084C2.98446 6.20084 2.38446 6.00084 2.18446 5.50084C1.98446 5.00084 1.98446 4.40084 2.48446 4.20084L7.48446 1.20084C7.78446 1.00084 8.18446 1.00084 8.48446 1.20084L13.4845 4.20084C13.9845 4.50084 14.0845 5.10084 13.7845 5.60084C13.4845 6.10084 12.8845 6.20084 12.3845 5.90084L7.98446 3.20084Z"
        fill="#E95D50"
      />
      <path
        d="M3.48446 10.2009C2.98446 10.5009 2.38446 10.3009 2.18446 9.90089C1.98446 9.50089 1.98446 8.80089 2.48446 8.50089L7.48446 5.50089C7.78446 5.30089 8.18446 5.30089 8.48446 5.50089L13.4845 8.50089C13.9845 8.80089 14.0845 9.40089 13.7845 9.90089C13.4845 10.4009 12.8845 10.5009 12.3845 10.2009L7.98446 7.50089L3.48446 10.2009Z"
        fill="#F89389"
      />
      <path
        d="M3.48446 14.5009C2.98446 14.8009 2.38446 14.7009 2.18446 14.2009C1.98446 13.7009 1.98446 13.1009 2.48446 12.8009L7.48446 9.80088C7.78446 9.60088 8.18446 9.60088 8.48446 9.80088L13.4845 12.8009C13.9845 13.1009 14.0845 13.7009 13.7845 14.2009C13.4845 14.7009 12.8845 14.8009 12.3845 14.5009L7.98446 11.8009L3.48446 14.5009Z"
        fill="#F2CDCA"
      />
    </svg>
  );
}

export default PriorityUrgent;

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { capitalize } from 'lodash';

import { DateCell, StatusCell } from 'common/components/table/cells/Cells';
import Table from 'common/components/table/Table';
import Icon from 'common/components/icon/Icon';
import FilterBar from 'common/components/filterbar/FilterBar';
import Typography from 'common/components/typography/Typography';
import TextButton from 'common/components/textbutton/TextButton';
import DateRangePicker from 'common/components/daterangepicker/DateRangePicker';
import BinaryStatus from 'common/components/binarystatus/BinaryStatus';
import BucketStatus from 'common/components/bucketstatus/BucketStatus';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';

import useQueryFilter from 'common/hooks/useQueryFilter';
import useDateFilter from 'common/hooks/useDateFilter';
import withSuspense from 'common/hoc/withSuspense';

import { selectPendingRequest } from 'common/store/selectors/requestSelectors';

import { IMachineStatusData } from 'machines/interfaces';
import { getMachineStatus, getMachine } from 'machines/actions';
import { selectMachineStatus } from 'machines/selectors';
import { getMachineHealthXLSX } from 'machines/service';
import { useIsAdmin, useIsAdminContract, useIsOperator, useIsRouteman } from 'utils/user-role';

import { toast } from 'react-toastify';

import * as styles from './Health.module.scss';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
function Health(): JSX.Element {
  const cannotViewContent = useIsRouteman();
  const canUploadAndDownloadContent = useIsAdmin() || useIsOperator() || useIsAdminContract();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { machineId }: { machineId: string } = useParams();
  const { from, to } = useDateFilter();

  const languageCode = i18n.language || 'en';

  async function downloadHealthReportXLSX() {
    const file = await getMachineHealthXLSX({ machineId, from, to });

    const XLSXFile = new File([file], 'machineHeathReport.xlsx', {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(XLSXFile);
    anchorElement.download = 'machineHeathReport.xlsx';
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  }

  const loading = useSelector(
    selectPendingRequest(`MACHINE_STATUS/GET_${machineId}`)
  );
  const rawData = useSelector(selectMachineStatus(machineId));

  useEffect(() => {
    if (from || to) {
      dispatch(getMachineStatus(machineId, { from, to }));
    }
  }, [from, to, machineId]);

  function handleRefresh(): void {
    dispatch(getMachineStatus(machineId, { from, to }));
    dispatch(getMachine(machineId));
  }

  // don't show status updated row (code 777, 888, 999)
  const filtered = useQueryFilter<IMachineStatusData[]>(
    rawData.filter(
      (each) => each.code !== 777 && each.code !== 888 && each.code !== 999
    )
  );

  // Type and code rendering logic
  const statusUpdateCodes = [777, 888, 999];

  /**
   * Function to render the cells in the code column
   */
  function CodeCell(rowData: IMachineStatusData) {
    const machineStatusObject = rowData;
    const errorCode = machineStatusObject.code;
    // If error code is a status update code, do not show code
    if (errorCode === undefined || statusUpdateCodes.includes(errorCode)) {
      return null;
    } else {
      return <Typography text={errorCode} type="body-2" />;
    }
  }

  /**
   * Function to render cells in the type column
   */
  function TypeCell(rowData: IMachineStatusData) {
    const machineStatusObject = rowData;
    const errorCode = machineStatusObject.code;
    // If error code is a status update code, do not show type
    if (
      errorCode === undefined ||
      statusUpdateCodes.includes(errorCode) ||
      !machineStatusObject.codeDetails
    ) {
      return null;
    }

    const machineStatus = machineStatusObject.codeDetails?.status;

    const successColorMachineStatus = ['online', 'success', 'normal'];
    const warningColorMachineStatus = ['warning'];
    const investigateColorMachineStatus = ['investigate'];
    const errorColorMachineStatus = ['fail', 'failed'];

    let indicatorColor;
    if (successColorMachineStatus.includes(machineStatus)) {
      indicatorColor = 'success';
    } else if (warningColorMachineStatus.includes(machineStatus)) {
      indicatorColor = 'warning';
    } else if (investigateColorMachineStatus.includes(machineStatus)) {
      indicatorColor = 'investigate';
    } else if (errorColorMachineStatus.includes(machineStatus)) {
      indicatorColor = 'error';
    }

    return (
      <IndicatorLabel
        label={isMobile ? capitalize(machineStatus) : undefined}
        color={isMobile ? undefined : indicatorColor}
      />
    );
  }

  function ErrorTitleCell(rowData: IMachineStatusData) {
    const machineStatusObject = rowData;
    const errorCode = machineStatusObject.code;
    if (errorCode === undefined) {
      return null;
    }
    // If error code is status update code, render "Status Update"
    if (statusUpdateCodes.includes(errorCode)) {
      return <Typography text="Status Update" type="body-2" />;
    } else {
      return (
        <Typography
          text={machineStatusObject.codeDetails?.[languageCode]}
          type="body-2"
        />
      );
    }
  }

  /**
   * Function to render the cells in the description column
   */
  function DescriptionCell(rowData: IMachineStatusData) {
    const machineStatusObject = rowData;
    const errorCode = machineStatusObject.code;
    if (errorCode === undefined) {
      return null;
    }
    // If error code is status update code, render "Status Update"
    if (statusUpdateCodes.includes(errorCode)) {
      return null;
    }

    return (
      <Typography
        text={machineStatusObject.codeDetails?.description}
        type="body-2"
      />
    );
  }

  return (
    <div className={styles.Health}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography type="headline-5" translationKey="menu_status" />
            <div className={styles.refresh} onClick={handleRefresh}>
              <Icon name="Refresh" color="primary" />
            </div>
          </div>
          {/* <TextButton
            translationKey='action_self_test'
            onClick={console.log}
            icon='Machine'
            className={styles.action}
          />
          <TextButton
            translationKey='action_set_offline'
            onClick={console.log}
            icon='Pause'
            className={styles.action}
          /> */}
          {canUploadAndDownloadContent ? (
            <TextButton
              translationKey="action_download_health_report"
              onClick={async () => {
                const loadingToast = toast.loading(i18n.t('label_loading'));
                try {
                  await downloadHealthReportXLSX();
                  toast.update(loadingToast, {
                    type: 'success',
                    isLoading: false,
                    render: i18n.t('toast_request_successful'),
                    autoClose: 5000,
                  });
                } catch (e) {
                  toast.update(loadingToast, {
                    type: 'error',
                    isLoading: false,
                    render: i18n.t('toast_request_failed'),
                    autoClose: 5000,
                  });
                }
              }}
              icon="Download"
              className={styles.action}
            />
          ) : null}
        </div>
        <div className={styles.right}>
          <DateRangePicker className={styles.action} defaultStartDate={30} />
        </div>
      </div>

      <BucketStatus
        title="label_health"
        data={filtered?.map((i) => {
          let state: string = i.codeDetails?.status || 'online';
          if (i.codeDetails?.status === 'normal') {
            state = 'online';
          }
          return {
            timestamp: Number(i.time),
            state,
          };
        })}
        stateMap={[
          {
            state: 'online',
            label: 'Online',
            color: 'success',
            isSuccessState: true,
            priority: 0,
          },
          {
            state: 'warning',
            label: 'Warning',
            color: 'warning',
            isSuccessState: false,
            priority: 1,
          },
          {
            state: 'error',
            label: 'Error',
            color: 'error',
            isSuccessState: false,
            priority: 2,
          },
        ]}
        from={from}
        to={to}
        buckets={24}
        minimumEventHeight={5}
      />

      <BinaryStatus
        title="label_online_status"
        data={filtered?.map((i) => {
          return {
            timestamp: Number(i.time),
            state: i.availability === 'online' ? 1 : 0,
          };
        })}
        onState={{
          label: 'status_online',
          color: 'success',
        }}
        offState={{
          label: 'status_offline',
          color: 'error',
        }}
        from={from}
        to={to}
      />

      <FilterBar
        menu={[
          {
            title: 'label_online_status',
            queryKey: 'availability',
            type: 'checkbox',
            checkboxOptions: [
              {
                title: 'status_online',
                queryValue: 'online',
              },
              {
                title: 'status_offline',
                queryValue: 'offline',
              },
              {
                title: 'status_out_of_service',
                queryValue: 'out_of_service',
              },
              {
                title: 'status_maintenance',
                queryValue: 'maintenance',
              },
            ],
          },
          {
            title: 'label_status',
            queryKey: 'codeDetails.status',
            type: 'checkbox',
            checkboxOptions: [
              {
                title: 'status_normal',
                queryValue: 'normal',
              },
              {
                title: 'status_warning',
                queryValue: 'warning',
              },
              {
                title: 'status_error',
                queryValue: 'error',
              },
            ],
          },
          {
            title: 'label_description',
            queryKey: 'codeDetails.en',
            type: 'contains',
          },
        ]}
      />
      <Table
        infiniteScroll
        className={styles.table}
        data={filtered}
        loading={loading}
        columns={[
          {
            dataKey: 'availability',
            headerLabel: 'label_status',
            cellRenderer: StatusCell,
            align: 'flex-start',
            columnWidth: 100,
            showMobile: true,
          },
          {
            dataKey: 'time',
            headerLabel: 'label_time',
            cellRenderer: DateCell,
            align: 'flex-start',
            columnFlex: 0.6,
            showMobile: true,
          },
          {
            headerLabel: 'label_type',
            cellRenderer: TypeCell,
            align: 'center',
            columnFlex: 0.4,
          },
          {
            headerLabel: 'label_code',
            cellRenderer: CodeCell,
            align: 'center',
            columnFlex: 0.4,
          },
          {
            headerLabel: 'label_error_title',
            cellRenderer: ErrorTitleCell,
            align: 'flex-start',
            columnFlex: 2,
            showMobile: true,
          },
          {
            headerLabel: 'label_description',
            cellRenderer: DescriptionCell,
            align: 'flex-start',
            columnFlex: 2,
            showTablet: true,
          },
        ]}
      />
    </div>
  );
}

export default withSuspense(Health);

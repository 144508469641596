export function getMutatedFields<T extends Record<string, any>>(original: T, mutated: T): Partial<T> {
  const mutatedFields: Partial<T> = {};

  for (const key in original) {
    if (Object.prototype.hasOwnProperty.call(original, key) &&
      Object.prototype.hasOwnProperty.call(mutated, key)) {
      if (original[key] !== mutated[key]) {
        mutatedFields[key] = mutated[key];
      }
    }
  }

  return mutatedFields;
}

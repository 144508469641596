import { httpClient } from 'common/services/transportService';

type ResourceAction = 'read' | 'write';
export type ResourceType = 'refillZone' | 'serviceZone';

export type GetUserPermissionResponse = Array<string>;
export async function getUserPermission(
  userId: string,
  permissionType: { action: ResourceAction; resourceType: ResourceType }
): Promise<GetUserPermissionResponse> {
  const result = await httpClient.post<GetUserPermissionResponse>('/auth/query-resource-ids', [{
    userId,
    ...permissionType,
  }]);
  return result.data;
}

type SetUserPermissionBody = Array<{
  userId: string;
  resourceId: string;
  resourceType: ResourceType;
  action: ResourceAction;
  operation?: 'delete' | 'add';
}>;
export async function setUserPermissions(body: SetUserPermissionBody): Promise<void> {
  await httpClient.post('/auth/set-permission', body);
}

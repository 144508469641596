import * as React from "react";

interface IMaintenanceTestProps {
  color?: string;
  className?: string;
}

function MaintenanceTest({
  color = "white",
  className,
}: IMaintenanceTestProps): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62822 0.658188C4.20402 -0.110179 6.16571 0.155104 7.47748 1.46687C8.61505 2.60444 8.96778 4.22425 8.54511 5.66392L13.4195 10.5437C13.8603 10.9845 13.8603 11.698 13.4195 12.1389L12.1204 13.438C11.6795 13.8788 10.966 13.8788 10.5252 13.438L5.6244 8.53719C4.20342 8.9333 2.60789 8.5764 1.48792 7.45643C0.176187 6.14469 -0.088424 4.19544 0.672371 2.61533L1.01774 1.89803L3.63264 4.51293L4.52833 3.61723L1.91638 1.00528L2.62822 0.658188ZM3.92028 1.4116L6.12591 3.61723L3.63264 6.11051L1.42975 3.90762C1.25402 4.87819 1.53954 5.91047 2.28671 6.65764C3.17392 7.54486 4.47391 7.78095 5.57991 7.3644L5.9208 7.23601L11.3228 12.638L12.6195 11.3413L7.23611 5.95193L7.37143 5.60775C7.81215 4.48677 7.581 3.16797 6.67869 2.26566C5.93081 1.51778 4.89108 1.23285 3.92028 1.4116Z"
        fill={color}
      />
    </svg>
  );
}

export default MaintenanceTest;

import { IAction } from 'common/interfaces';

import { ILot } from 'lot/interfaces';

interface ILotState {
  [lotId: string]: ILot
}

const initialState: ILotState = {};

function lotReducer (
  state: ILotState = initialState,
  action: IAction
): ILotState {
  const [ domain, request, result ] = action.type.split('/');

  if (domain === 'LOT') {
    if (request.includes('GET_') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
    if (request.includes('CREATE') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
    if (request.includes('EDIT') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
    if (request.includes('WITHDRAW') && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
  }

  if (domain === 'PRODUCTS') {
    if (request === 'TRANSFER' && result === 'SUCCESS') {
      return { ...state, ...action.payload };
    }
  }

  return state;
}

export default lotReducer;

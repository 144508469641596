export default {
  API_URL: process.env.API_URL || "http://localhost:3000",
  SENTRY_DSN: process.env.SENTRY_DSN || "",
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT || "development",
  LOCAL_STORAGE_KEY: process.env.LOCAL_STORAGE_KEY || "vendii-portal",
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID || "",
  MAPBOX_TOKEN: process.env.MAPBOX_TOKEN || "",
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || "",
  GOOGLE_MAPS_MAP_ID: process.env.GOOGLE_MAPS_MAP_ID || "",
  AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY || "",
  CLARITY_API_KEY: process.env.CLARITY_API_KEY || "",
};

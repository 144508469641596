import * as React from "react";

interface IListProps {
  color?: string;
  className?: string;
}

function Loader({ color = "white", className }: IListProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" color={color} />
    </svg>
  );
}

export default Loader;

import { useAuthStore } from 'userandauth/useAuthStore';
import { useGetUser, useGetUserOptions } from './useGetUser';
import { updateDefaultTimezone } from 'utils/date';

function useGetCurrentUser(options?: useGetUserOptions) {
  const userId = useAuthStore((state) => state.userId);
  return useGetUser(userId, {
    ...options,
    onSuccess: (user) => {
      updateDefaultTimezone(user.timezone);
    },
  });
}

export { useGetCurrentUser };

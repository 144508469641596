import {
  GetMaintenanceBoardEspressoCupErrorDocument,
  GetMaintenanceBoardEspressoCupErrorQuery,
  GetMaintenanceBoardEspressoCupErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getEspressoCups({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardEspressoCupErrorQuery,
    GetMaintenanceBoardEspressoCupErrorQueryVariables
  >(GetMaintenanceBoardEspressoCupErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

import * as React from 'react';
import MuiModal from 'components/Modal';
import Button from 'components/Button';
import Typography from 'components/Typography';

function InformationModal({
  onClose,
  isOpen,
}: InformationModalProp): JSX.Element {
  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      padding="20px 20px"
      minWidth="400px"
    >
      <div>
        <div>
          <Typography translate type="headline-6">
            label_request_has_been_sent
          </Typography>
        </div>
        <div>
          <Typography translate type="body-2">
            label_update_information_text
          </Typography>
        </div>

        <div
          style={{
            marginTop: 15,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={onClose} type="primary">
            label_close
          </Button>
        </div>
      </div>
    </MuiModal>
  );
}

export default InformationModal;

interface InformationModalProp {
  onClose: () => void;
  isOpen: boolean;
}

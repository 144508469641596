import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import { queryClient } from "index";
import { QueryFunctionContext, UseQueryOptions, useQuery } from "react-query";

const productQueryKey = {
  product: (productId: string) =>
    [
      {
        scope: "products",
        productId: productId,
        url: "/products/:productId",
        resolvedUrl: `/products/${productId}`,
      },
    ] as const,
};
type ProductQueryKey = ReturnType<typeof productQueryKey["product"]>;

export function invalidateProductDetails(productId: string){
  queryClient.invalidateQueries(productQueryKey.product(productId));
}

type Product = {
  id: string;
  image: string;
  inventoryCode: string;
  SKU: string;
  name: string;
  description: string;
  manufacturer: string;
  package: number;
  uom: string;
  machineCategory: string;
  additional: any;
  packageType: string;
  organisationId: string;
  disabled: boolean;
  updatedAt: string;
  createdAt: string;
  updatedAtUTC: any;
  createdAtUTC: any;
  catalogId: string;
  productId: string;
  UPC: string;
  machineCategories: any[];
};

async function getProductById(context: QueryFunctionContext<ProductQueryKey>) {
  const [{ productId }] = context.queryKey;
  const response = await httpClient.get<Product>(`products/${productId}`);
  return response.data;
}

type GetWarehouseProductByIdQueryOptions = Omit<
  UseQueryOptions<Product, AxiosError, Product, ProductQueryKey>,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetProductByIdBase({
  productId,
  options = {},
}: {
  productId: string;
  options?: GetWarehouseProductByIdQueryOptions;
}) {
  return useQuery({
    queryKey: productQueryKey.product(productId),
    queryFn: getProductById,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetProductBybyId(
  productId: string,
  options?: GetWarehouseProductByIdQueryOptions
) {
  return useGetProductByIdBase({
    productId: productId,
    options,
  });
}

import Typography from "components/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import { insertElementBetweenExcludingEnd } from "../utils/insertElementBetweenExcludingEnd";
import { TextFieldWithLabel } from "./ui/TextField";

export function MachineIds({
  machineIds,
}: {
  machineIds: Array<string> | null;
}) {
  let muiLabelChildren: React.ReactNode;

  if (machineIds === null || machineIds.length === 0) {
    muiLabelChildren = (
      <Typography type="subtitle-1" color="primary500">
        <span>&nbsp;</span>
      </Typography>
    );
  } else {
    const sortedMachineIds = machineIds?.sort((a, b) => a.localeCompare(b));

    const linksArray = sortedMachineIds?.map((machineId) => (
      <Link
        to={`/machines/detail/${machineId}/details`}
        target="_blank"
        rel="noopener noreferrer"
        key={`machine-${machineId}`}
      >
        <Typography type="subtitle-1" color="primary500">
          {machineId}
        </Typography>
      </Link>
    ));
    muiLabelChildren = insertElementBetweenExcludingEnd(linksArray, (index) => (
      <Typography type="subtitle-1" color="primary500" key={"$" + index}>
        {", "}
      </Typography>
    ));
  }

  return (
    <TextFieldWithLabel label="label_machine_ids" value={muiLabelChildren} />
  );
}



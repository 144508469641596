// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PolarChartCard--1CTvkZuCqi{display:flex;flex-direction:column;justify-content:space-between}.PolarChartCard--1CTvkZuCqi .ChartContainer--28HoLdoi7H{display:flex;justify-content:space-between;align-items:flex-start}.PolarChartCard--1CTvkZuCqi .ChartContainer--28HoLdoi7H .Legend--3aB98RAaPG{min-width:140px;margin-top:20px;display:flex;flex-direction:column;gap:6px}", ""]);
// Exports
exports.locals = {
	"PolarChartCard": "PolarChartCard--1CTvkZuCqi",
	"ChartContainer": "ChartContainer--28HoLdoi7H",
	"Legend": "Legend--3aB98RAaPG"
};
module.exports = exports;

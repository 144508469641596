import { IRootState } from 'common/store/reducers/rootReducer';

import { IRefillOrder } from 'refillorders/interfaces';

export function selectRefillOrder (orderId: string, truncated: boolean = false) {
  return function (state: IRootState): IRefillOrder {
    if (truncated) {
      if (state.refillOrder[orderId]) {
        return state.refillOrder[orderId];
      }

      const keys = Object.keys(state.refillOrder);
      const substrings = keys.map(i => {
        return {
          original: i,
          substring: i.substr(i.length - 6)
        };
      });
      const key = substrings.find(i => i.substring === orderId);
      return state.refillOrder[key?.original];
    }
    return state.refillOrder[orderId];
  };
}

export function selectAllRefillOrders (state: IRootState): IRefillOrder[] {
  return Object.values(state.refillOrder);
}

export function selectRefillableOrderForMachine (machineId: string) {
  return function (state: IRootState): IRefillOrder[] {
    const allOrders = Object.values(state.refillOrder);
    return allOrders.filter(i => {
      if (i.machineId !== machineId) {
        return false;
      }
      if (i.status === 'en_route' || i.status === 'adjustment') {
        return true;
      }
      return false;
    });
  };
}

export function selectRecommendedRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'recommended';
  });
}

export function selectNewRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'created';
  });
}

export function selectReadyForCollectionRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'ready_for_collection';
  });
}

export function selectEnRoutenRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'en_route';
  });
}

export function selectAdjustmentRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'adjustment';
  });
}

export function selectCompletedRefillOrders (state: IRootState): IRefillOrder[] {
  const allOrders = Object.values(state.refillOrder);
  return allOrders.filter(i => {
    return i.status === 'completed' || i.status === 'refilled';
  });
}

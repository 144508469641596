import * as React from "react";
import {
  contractRoutes,
  useContractIdUrlParams,
} from "containers/contract/routes";
import { Link, useHistory } from "react-router-dom";
import { ContractWorkSpace } from "../../components/ContractWorkSpace";

import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { toasti18n } from "utils/toast";
import {
  ContractContactInfo,
  ContractDetails,
  contractByIdCache,
} from "containers/contract/hooks/useGetContractById";
import { useGetContractById } from "../../hooks/useGetContractById";
import {
  GeneralContractInfoForm,
  GeneralContractInfoFormValue,
  mapGeneralContractInfoToGeneralContractInfoFormValue,
} from "../../components/contract/GeneralContractInfo";
import {
  ContractContactInfoForm,
  ContractContactInfoFormValue,
  mapContractContactInfoToContractContactInfoFormValue,
} from "../../components/contract/ContractContactInfo";
import Typography from "components/Typography/Typography";
import { LocationCardDisabled } from "./LocationCardDisabled";
import { useUpdateContractMutation } from "gql/generated";
import { organisationIdCache } from "containers/contract/components/contract/OrganisationIdAutoComplete";
import { ContractDocumentForm } from "containers/contract/components/contract/ContractDocument";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";

function EditContract() {
  const { t } = useTranslation();
  const contractId = useContractIdUrlParams();
  const getContractResult = useGetContractById(contractId);
  const permissionResult = useCanAccessContractFeature();
  const isLoading = getContractResult.isLoading || permissionResult.isLoading;

  return (
    <ContractWorkSpace.ScreenWrapper>
      <ContractWorkSpace.PageTitle>
        {t("label_contract")}
      </ContractWorkSpace.PageTitle>

      {isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <EditContractForm initialValue={getContractResult.data} />
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function EditContractForm({ initialValue }: { initialValue: ContractDetails }) {
  const [generalContactInfo, setGeneralContactInfo] = React.useState(
    mapGeneralContractInfoToGeneralContractInfoFormValue(initialValue.general)
  );
  const [contractContactInfo, setContractContactInfo] = React.useState(
    mapContractContactInfoToContractContactInfoFormValue(initialValue.contact)
  );

  const history = useHistory();
  const editContractResult = useEditContract({
    onSuccess: () => {
      toasti18n.success();
      contractByIdCache.updateContract(
        computeOptimisticContract(
          initialValue.general.contractId,
          contractContactInfo,
          generalContactInfo
        )
      );
      history.push(contractRoutes.contractId(initialValue.general.contractId));
    },
  });

  function submitForm() {
    editContractResult.submit({
      contractId: initialValue.general.contractId,
      contact: contractContactInfo,
      general: generalContactInfo,
    });
  }

  return (
    <ContractWorkSpace.MaxWidthContainer>
      <ContractWorkSpace.Spacer />
      <GeneralContractInfoForm
        value={generalContactInfo}
        onChange={setGeneralContactInfo}
        initialOrgOptions={[
          {
            label: initialValue.general.organisation.name,
            value: initialValue.general.organisation.id,
          },
        ]}
      />
      <ContractWorkSpace.Spacer />

      <Typography type="headline-6" translate>
        label_location
      </Typography>
      <div style={{ height: "10px" }}></div>
      <Typography type="caption" color="onSurfaceMedium" translate>
        label_unable_to_make_changes_to_location_list
      </Typography>
      <div style={{ height: "10px" }}></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fill, minmax(min(100%, 140px), 1fr))",
          gap: "13px",
        }}
      >
        {initialValue.locations.map((location) => (
          <LocationCardDisabled
            key={location.id}
            locationFriendlyId={location.friendlyId}
            machineId={location.machineId}
          />
        ))}
      </div>
      <ContractWorkSpace.Spacer />
      <ContractContactInfoForm
        value={contractContactInfo}
        onChange={setContractContactInfo}
      />
      <ContractWorkSpace.Spacer />
      <ContractDocumentForm
        contractId={initialValue.general.contractId}
        documents={initialValue.documents}
      />
      <ContractWorkSpace.Spacer />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to={contractRoutes.contractId(initialValue.general.contractId)}
          style={{
            pointerEvents: editContractResult.isLoading ? "none" : "auto",
          }}
        >
          <Button type="secondary" disabled={editContractResult.isLoading}>
            action_cancel
          </Button>
        </Link>
        <Button
          type="primary"
          onClick={submitForm}
          loading={editContractResult.isLoading}
        >
          action_save
        </Button>
      </div>
    </ContractWorkSpace.MaxWidthContainer>
  );
}

export default EditContract;

type EditContractFormValue = {
  contractId: string;
  general: GeneralContractInfoFormValue;
  contact: ContractContactInfoFormValue;
};

function computeOptimisticContract(
  contractId: string,
  contractContactInfo: ContractContactInfo,
  generalContactInfo: GeneralContractInfoFormValue
): {
  contractId: string;
  updatedContractDetails: Pick<ContractDetails, "general" | "contact">;
} {
  return {
    contractId: contractId,
    updatedContractDetails: {
      contact: {
        accountPayableId: contractContactInfo.accountPayableId,
        contactPerson: contractContactInfo.contactPerson,
        contractOwnerName: contractContactInfo.contractOwnerName,
        phoneNumber: contractContactInfo.phoneNumber,
      },
      general: {
        contractId: contractId,
        contractEndDate: generalContactInfo.contractEndDate,
        contractFriendlyId: generalContactInfo.contractFriendlyId,
        contractStartDate: generalContactInfo.contractStartDate,
        numberOfLocationsPerContract: Number(
          generalContactInfo.numberOfLocationsPerContract
        ),

        organisation: {
          id: generalContactInfo.organisationId,
          name: organisationIdCache.getOrganisationNameById(
            generalContactInfo.organisationId
          ),
        },
      },
    },
  };
}

function useEditContract({ onSuccess = () => {} }: { onSuccess?: () => void }) {
  const { mutate, ...rest } = useUpdateContractMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async () => {
      onSuccess();
    },
  });

  function submit(form: EditContractFormValue) {
    mutate({
      input: {
        id: form.contractId,
        accountPayableId: form.contact.accountPayableId,
        contactPerson: {
          name: form.contact.contactPerson,
        },
        contractOwnerName: form.contact.contractOwnerName,
        contractPeriod: {
          start: form.general.contractStartDate.format("YYYY-MM-DD"),
          end: form.general.contractEndDate.format("YYYY-MM-DD"),
        },
        friendlyId: form.general.contractFriendlyId,
        numberOfLocations: Number(form.general.numberOfLocationsPerContract),
        organizationId: form.general.organisationId,
        phoneNumber: form.contact.phoneNumber,
      },
    });
  }
  return { submit, ...rest };
}

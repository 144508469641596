import * as React from 'react';
import * as styles from './SnapshotTable.module.scss';

import { IMachineAPIData } from '../Snapshot';
import Typography from 'components/Typography';

const SnapshotTable = ({ data }: ISnapshotProp) => {
  function getChangeColumn(change: number): JSX.Element {
    if (change > 0) {
      return (
        <Typography type="caption" color="error">
          +{change}
        </Typography>
      );
    }
    if (change < 0) {
      return (
        <Typography type="caption" color="success">
          {change}
        </Typography>
      );
    }
    return (
      <Typography type="caption" color="caption-disabled">
        {change}
      </Typography>
    );
  }

  return (
    <div className={styles.SnapshotTable}>
      <div className={styles.ColumnContainer}>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_error
          </Typography>
        </div>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_total_disable
          </Typography>
        </div>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_change
          </Typography>
        </div>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_new
          </Typography>
        </div>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_old
          </Typography>
        </div>
        <div className={styles.Column}>
          <Typography type="caption" translate>
            label_fix
          </Typography>
        </div>
      </div>

      {data.map((each, id) => {
        return (
          <div className={styles.ColumnContainer} key={`${each.name}-${id}`}>
            <div className={styles.MainColumn}>
              <Typography type="caption">{each.name}</Typography>
            </div>
            <div className={styles.Column}>
              <Typography type="caption">{each.totalDisable}</Typography>
            </div>
            <div className={styles.ChangeColumn}>
              {getChangeColumn(each.change)}
            </div>
            <div className={styles.Column}>
              <Typography type="caption">{each.new}</Typography>
            </div>
            <div className={styles.Column}>
              <Typography type="caption">{each.old}</Typography>
            </div>
            <div className={styles.Column}>
              <Typography type="caption">{each.fixed}</Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SnapshotTable;

interface ISnapshotProp {
  data: IMachineAPIData[];
}

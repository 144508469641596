import * as React from "react";

interface IArrowTopProps {
  color?: string;
  className?: string;
}

function ArrowTop({ color = "white", className }: IArrowTopProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 5.41436V14.0002H7.00008V5.41436L3.70718 8.70726L2.29297 7.29304L8.00008 1.58594L13.7072 7.29304L12.293 8.70726L9.00008 5.41436Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowTop;

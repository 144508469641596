import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";

import { queryClient } from "index";
import {
  QueryFunctionContext,
  UseQueryOptions,
  useQuery,
} from "react-query";

export const warehouseProductKeys = {
  warehouseProducts: (warehouseId: string) =>
    [
      {
        ...getWarehouseProductsScope()[0],
        warehouseId,
        url: `/products?warehouseId=:warehouseId`,
        resolvedUrl: `/products?warehouseId=${warehouseId}`,
      },
    ] as const,
};

function getWarehouseProductsScope() {
  return [
    {
      scope: "warehouseProducts",
    },
  ] as const;
}

export function invalidateAllWarehouseProducts() {
  queryClient.invalidateQueries(getWarehouseProductsScope());
}

type WarehouseProductsQueryKey = ReturnType<
  typeof warehouseProductKeys["warehouseProducts"]
>;

type WarehouseProducts = Array<{
  oldestExpiry: string;
  totalQuantity: number;
  machineCategories: any[];
  id: string;
  productId: string;
  image?: string;
  inventoryCode: string;
  SKU: string;
  UPC: string;
  name: string;
  description?: string;
  manufacturer: string;
  package: number;
  uom: string;
  additional?: {} | null;
  packageType: string;
  machineCategory: string;
}>;

async function getWarehouseProductsById(
  context: QueryFunctionContext<WarehouseProductsQueryKey>
) {
  const [{ warehouseId }] = context.queryKey;
  const response = await httpClient.get<WarehouseProducts>("/products", {
    params: {
      warehouseId,
    },
  });
  return response.data;
}

type GetWarehouseProductByIdQueryOptions = Omit<
  UseQueryOptions<
    WarehouseProducts,
    AxiosError,
    WarehouseProducts,
    WarehouseProductsQueryKey
  >,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetWarehouseProductsByIdBase({
  warehouseId,
  options = {},
}: {
  warehouseId: string;
  options?: GetWarehouseProductByIdQueryOptions;
}) {
  return useQuery({
    queryKey: warehouseProductKeys.warehouseProducts(warehouseId),
    queryFn: getWarehouseProductsById,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetWarehouseProductsById(
  warehouseId: string,
  options?: GetWarehouseProductByIdQueryOptions
) {
  return useGetWarehouseProductsByIdBase({
    warehouseId: warehouseId,
    options,
  });
}


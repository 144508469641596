import "utils/date";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import "./utils/analytics";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import App from "App";
import store from "common/store";
import config from "common/config";

import "common/i18n";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../tailwind/tailwind.css";
import "../tailwind/reset.css";

import axios from "axios";
import { AnalyticClickElementTracking } from "components/AnalyticClickElementTracking";
import { ERROR_FAILED_TO_REFRESH_TOKEN } from "userandauth/utilsAuth";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      useErrorBoundary: (error, query) => {
        if (axios.isAxiosError(error) && error.code === "ETIMEDOUT") {
          const cachedData = queryClient.getQueryData(query.queryKey);
          if (cachedData !== undefined) {
            return false;
          }
        }
        return true;
      },
    },
  },
});

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  tracesSampleRate: config.SENTRY_ENVIRONMENT === "production" ? 0.5 : 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [process.env.API_URL],
      networkCaptureBodies: true,
    }),
    new Sentry.Feedback({
      autoInject: false,
      showBranding: false,
      buttonLabel: "Report Problem",
      submitButtonLabel: "Send",
      formTitle: "Report Problem",
      messageLabel: "What happened",
      messagePlaceholder: "What’s the problem?\nWhat did you expect?",
    }),
  ],
  ignoreErrors: [ERROR_FAILED_TO_REFRESH_TOKEN],
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
      <AnalyticClickElementTracking />
      <ReactQueryDevtools
        initialIsOpen={false}
        position="bottom-left"
      />
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);

import Typography from "components/Typography/Typography";
import * as React from "react";

export function InventoryIdViewPlaceHolder() {
  return (
    <div style={{ height: "100%", width: "100%", display: "grid", placeContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <svg
          width="240"
          height="249"
          viewBox="0 0 240 249"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M238.637 116.085C237.693 112.929 235.534 110.277 232.634 108.713C229.735 107.149 226.333 106.801 223.177 107.745L112.104 140.977C111.489 141.161 110.924 141.487 110.456 141.927L96.9616 154.607C96.4011 155.134 95.9984 155.806 95.7985 156.548C95.5985 157.291 95.6093 158.075 95.8296 158.811C96.0499 159.548 96.4711 160.209 97.0458 160.72C97.6205 161.231 98.3261 161.571 99.0836 161.704L117.325 164.89C117.958 165 118.608 164.962 119.224 164.778L209.349 137.812L209.739 146.339L181.102 154.907L183.475 162.842L215.21 153.346C216.095 153.082 216.866 152.529 217.4 151.776C217.935 151.023 218.202 150.113 218.16 149.191L217.527 135.369L230.297 131.549C233.454 130.604 236.105 128.445 237.669 125.545C239.233 122.646 239.581 119.244 238.637 116.088V116.085ZM117.788 156.565L108.667 154.972L115.414 148.631L134.312 142.978L136.686 150.911L117.788 156.565ZM144.619 148.537L142.246 140.603L208.569 120.759L208.959 129.286L144.619 148.537ZM230.382 121.611C230.125 122.09 229.775 122.514 229.353 122.857C228.932 123.201 228.446 123.457 227.924 123.612L217.138 126.839L216.747 118.312L225.551 115.678C226.335 115.443 227.172 115.447 227.955 115.687C228.737 115.928 229.431 116.395 229.948 117.03C230.465 117.665 230.782 118.439 230.86 119.255C230.937 120.07 230.771 120.89 230.382 121.611Z"
            fill="#CBCCE6" />
          <path
            d="M153.625 174.188H83.2344V112.078C83.2344 110.98 82.7981 109.927 82.0216 109.15C81.2451 108.374 80.1919 107.938 79.0938 107.938H4.5625C3.46434 107.938 2.41115 108.374 1.63464 109.15C0.858118 109.927 0.421875 110.98 0.421875 112.078V244.578C0.421875 245.676 0.858118 246.729 1.63464 247.506C2.41115 248.283 3.46434 248.719 4.5625 248.719H153.625C154.723 248.719 155.776 248.283 156.553 247.506C157.329 246.729 157.766 245.676 157.766 244.578V178.328C157.766 177.23 157.329 176.177 156.553 175.4C155.776 174.624 154.723 174.188 153.625 174.188ZM108.078 182.469H124.641V199.031H108.078V182.469ZM33.5469 116.219H50.1094V132.781H33.5469V116.219ZM8.70312 116.219H25.2656V136.922C25.2656 138.02 25.7019 139.073 26.4784 139.85C27.2549 140.626 28.3081 141.062 29.4062 141.062H54.25C55.3482 141.062 56.4013 140.626 57.1779 139.85C57.9544 139.073 58.3906 138.02 58.3906 136.922V116.219H74.9531V174.188H8.70312V116.219ZM33.5469 182.469H50.1094V199.031H33.5469V182.469ZM8.70312 182.469H25.2656V203.172C25.2656 204.27 25.7019 205.323 26.4784 206.1C27.2549 206.876 28.3081 207.312 29.4062 207.312H54.25C55.3482 207.312 56.4013 206.876 57.1779 206.1C57.9544 205.323 58.3906 204.27 58.3906 203.172V182.469H74.9531V240.438H8.70312V182.469ZM149.484 240.438H83.2344V182.469H99.7969V203.172C99.7969 204.27 100.233 205.323 101.01 206.1C101.786 206.876 102.839 207.312 103.938 207.312H128.781C129.879 207.312 130.933 206.876 131.709 206.1C132.486 205.323 132.922 204.27 132.922 203.172V182.469H149.484V240.438Z"
            fill="#CBCCE6" />
          <path d="M132.922 223.875H141.203V232.156H132.922V223.875Z" fill="#CBCCE6" />
          <path d="M58.3906 223.875H66.6719V232.156H58.3906V223.875Z" fill="#CBCCE6" />
          <path d="M58.3906 157.625H66.6719V165.906H58.3906V157.625Z" fill="#CBCCE6" />
          <path
            d="M66.6719 33.4062C66.6745 31.2107 67.5478 29.1059 69.1003 27.5534C70.6527 26.0009 72.7576 25.1276 74.9531 25.125H99.7969V33.4062H79.0938C77.9956 33.4062 76.9424 33.8425 76.1659 34.619C75.3894 35.3955 74.9531 36.4487 74.9531 37.5469V99.6562H83.2344V41.6875H182.609V107.938H190.891V37.5469C190.891 36.4487 190.454 35.3955 189.678 34.619C188.901 33.8425 187.848 33.4062 186.75 33.4062H166.047V25.125H190.891C193.086 25.1276 195.191 26.0009 196.743 27.5534C198.296 29.1059 199.169 31.2107 199.172 33.4062V103.797H207.453V33.4062C207.448 29.0151 205.702 24.8052 202.597 21.7002C199.492 18.5952 195.282 16.8487 190.891 16.8438H150.647L153.501 5.42598C153.654 4.81554 153.665 4.17836 153.535 3.56283C153.404 2.9473 153.135 2.36962 152.748 1.87367C152.361 1.37772 151.866 0.976539 151.3 0.700612C150.735 0.424686 150.114 0.281267 149.484 0.28125H116.359C115.73 0.281267 115.109 0.424686 114.544 0.700612C113.978 0.976539 113.483 1.37772 113.096 1.87367C112.709 2.36962 112.439 2.9473 112.309 3.56283C112.178 4.17836 112.19 4.81554 112.342 5.42598L115.197 16.8438H74.9531C70.562 16.8487 66.3521 18.5952 63.2471 21.7002C60.1421 24.8052 58.3956 29.0151 58.3906 33.4062V99.6562H66.6719V33.4062ZM108.078 25.125H120.5C121.129 25.125 121.75 24.9816 122.316 24.7056C122.881 24.4297 123.376 24.0285 123.764 23.5326C124.151 23.0366 124.42 22.459 124.55 21.8434C124.681 21.2279 124.67 20.5907 124.517 19.9803L121.662 8.5625H144.181L141.327 19.9803C141.174 20.5907 141.163 21.2279 141.293 21.8434C141.424 22.459 141.693 23.0366 142.08 23.5326C142.467 24.0285 142.963 24.4297 143.528 24.7056C144.094 24.9816 144.715 25.125 145.344 25.125H157.766V33.4062H108.078V25.125Z"
            fill="#CBCCE6" />
          <path
            d="M199.172 203.172C199.169 205.367 198.296 207.472 196.743 209.025C195.191 210.577 193.086 211.451 190.891 211.453H166.047V219.734H190.891C195.282 219.729 199.492 217.983 202.597 214.878C205.702 211.773 207.448 207.563 207.453 203.172V165.906H199.172V203.172Z"
            fill="#CBCCE6" />
          <path
            d="M190.891 199.031V170.047H182.609V194.891H166.047V203.172H186.75C187.848 203.172 188.901 202.736 189.678 201.959C190.454 201.183 190.891 200.129 190.891 199.031Z"
            fill="#CBCCE6" />
          <path
            d="M116.359 54.1094C116.359 53.0112 115.923 51.958 115.147 51.1815C114.37 50.405 113.317 49.9688 112.219 49.9688H95.6562C94.5581 49.9688 93.5049 50.405 92.7284 51.1815C91.9519 51.958 91.5156 53.0112 91.5156 54.1094V70.6719C91.5156 71.77 91.9519 72.8232 92.7284 73.5997C93.5049 74.3763 94.5581 74.8125 95.6562 74.8125H112.219C113.317 74.8125 114.37 74.3763 115.147 73.5997C115.923 72.8232 116.359 71.77 116.359 70.6719V54.1094ZM108.078 66.5312H99.7969V58.25H108.078V66.5312Z"
            fill="#CBCCE6" />
          <path
            d="M112.219 87.2344H95.6562C94.5581 87.2344 93.5049 87.6706 92.7284 88.4471C91.9519 89.2237 91.5156 90.2768 91.5156 91.375V107.938C91.5156 109.036 91.9519 110.089 92.7284 110.865C93.5049 111.642 94.5581 112.078 95.6562 112.078H112.219C113.317 112.078 114.37 111.642 115.147 110.865C115.923 110.089 116.359 109.036 116.359 107.938V91.375C116.359 90.2768 115.923 89.2237 115.147 88.4471C114.37 87.6706 113.317 87.2344 112.219 87.2344ZM108.078 103.797H99.7969V95.5156H108.078V103.797Z"
            fill="#CBCCE6" />
          <path d="M124.641 49.9688H132.922V58.25H124.641V49.9688Z" fill="#CBCCE6" />
          <path d="M124.641 66.5312H174.328V74.8125H124.641V66.5312Z" fill="#CBCCE6" />
          <path d="M141.203 49.9688H174.328V58.25H141.203V49.9688Z" fill="#CBCCE6" />
          <path d="M124.641 87.2344H132.922V95.5156H124.641V87.2344Z" fill="#CBCCE6" />
          <path d="M124.641 103.797H174.328V112.078H124.641V103.797Z" fill="#CBCCE6" />
          <path d="M141.203 87.2344H174.328V95.5156H141.203V87.2344Z" fill="#CBCCE6" />
        </svg>
        <div style={{ marginTop: "34px", textAlign: "center" }}>
          <Typography type="headline-5" color="onSurfaceMedium" translate>
            label_select_inventory_to_view_its_details
          </Typography>
        </div>
      </div>
    </div>
  );
}

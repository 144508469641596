import * as React from "react";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { Skeleton } from "@mui/material";
import { useErrorBoard } from "./hooks/useErrorBoard";
import * as styles from "./LastUpdatedIndicator.module.scss";

export function LastUpdatedIndicator() {
  const { t } = useTranslation("errorBoard");
  const { state } = useErrorBoard();

  return (
    <div className={styles.LastUpdatedIndicator}>
      <Typography type="body-3" color="onSurfaceMedium">
        {t("lastUpdated")}
      </Typography>

      <Typography type="subtitle-1" color="onSurfaceHigh">
        {state.status === "error" && "-"}
        {state.status !== "error" &&
          state.data.lastUpdated &&
          moment(state.data.lastUpdated).format("MMM DD, YYYY HH:mm")}
        {state.status !== "error" && !state.data.lastUpdated && (
          <Skeleton width={130} />
        )}
      </Typography>
    </div>
  );
}

import * as React from "react";
import { Modal } from "containers/warehousing/components/ReusableModal";
import { toasti18n } from "utils/toast";
import { PlanStatus, useBatchUpdatePlanStatusMutation } from "gql/generated";
import { useQueryClient } from "react-query";
import Button from "components/Button";
import { Title } from "components/ResponsiveModal";
import TextButton from "common/components/textbutton/TextButton";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";

type CompleteConfirmationModalProps = {
  onSuccess: () => void;
  planIds: string[];
  onClose: () => void;
  open: boolean;
};

export function CompleteConfirmationModal({
  planIds,
  onSuccess,
  onClose,
  open,
}: CompleteConfirmationModalProps) {
  const { mutate, isLoading } = useMarkEnroutePlansCompleted({ onSuccess });
  function handleConfirm() {
    mutate(planIds);
  }
  const { t } = useTranslation();

  return (
    <Modal
      onClose={isLoading ? () => {} : onClose}
      open={open}
      modalStyles={{
        maxWidth: "500px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_confirmation</Title>
      <Typography type="body-2">
        {t("labelAreYouSureToMarkPlanCompleted", { count: planIds.length })}
      </Typography>
      <Typography type="body-2">
        {t("label_warning_unfilled_machines")}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: '12px'
        }}
      >
        <Button type="secondary" onClick={onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button type="primary" onClick={handleConfirm} loading={isLoading}>
          action_confirm
        </Button>
      </div>
    </Modal>
  );
}

export function MarkEnroutePlansCompleteButton({
  planIds,
  onSuccess = () => {},
}: {
  planIds: string[];
  onSuccess?: () => void;
}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (planIds.length === 0) {
      setOpen(false);
    }
  }, [planIds]);
  return (
    <>
      <CompleteConfirmationModal
        onClose={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          onSuccess();
        }}
        open={open}
        planIds={planIds}
      />
      <TextButton
        icon="CheckedClipboard"
        translationKey="label_mark_completed"
        onClick={() => setOpen(true)}
        disabled={planIds.length === 0}
      />
    </>
  );
}

function useMarkEnroutePlansCompleted({
  onSuccess = () => {},
}: {
  onSuccess?: () => void;
} = {}) {
  const queryClient = useQueryClient();
  const { mutate: _mutate, ...rest } = useBatchUpdatePlanStatusMutation({
    onError: (err) => {
      toasti18n.error(err as Error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["GetOperationStatusCounts"]);
      queryClient.invalidateQueries(["GetEnroutePlans"]);
      queryClient.invalidateQueries(["GetCompletedPlans"]);
      toasti18n.success();
      onSuccess();
    },
  });

  function mutate(planIds: string[]) {
    _mutate({
      plans: planIds.map((planId) => ({
        id: planId,
        status: PlanStatus.Completed,
      })),
    });
  }

  return { mutate, ...rest };
}

import { httpClient } from "common/services/transportService";

export async function getInventorySummaryCSVByPlanId(
  planId: string
): Promise<string> {
  const response = await httpClient.get<string>(
    `/plan/${planId}/inventory-report`,
    {
      responseType: "text",
    }
  );
  return response.data;
}

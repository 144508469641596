// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FilePicker--3QPZx0ID_Y{display:block;position:relative}.FilePicker--3QPZx0ID_Y .input--wlf1yusKeK{width:100%;height:100%;position:absolute;top:0;left:0;opacity:0;outline:none;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"FilePicker": "FilePicker--3QPZx0ID_Y",
	"input": "input--wlf1yusKeK"
};
module.exports = exports;

import * as React from 'react';
import Typography from 'components/Typography';
import { Package } from '../types';


export function InventoryCell({ productPackage }: { productPackage: Package }) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '17px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={productPackage.packageInfo.img}
          style={{
            width: '21px',
            height: '31px',
            objectFit: 'cover',
            marginLeft: '-10.5px',
          }} />
      </div>
      <div
        style={{
          paddingRight: '8px',
        }}
      >
        <Typography type="body-1" color="onSurfaceHigh">
          {productPackage.packageInfo.product.name}
        </Typography>
      </div>
    </div>
  );
}

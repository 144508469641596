// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DateTimeContainer--3_A-r593Yf{display:flex;justify-content:space-between;margin-top:12px}.DateTimeContainer--3_A-r593Yf .widthContainer--2NfxhJGNSE{max-width:167px}.warn--38f7Gc-vQZ{margin-top:8px;color:#e95d50 !important}.placeholder--21_29nP8Ny{height:12px}.ButtonContainer--7K-lveddqe{display:flex;margin-top:8px}.ButtonContainer--7K-lveddqe .download--iwLgQt_Fbt{margin-left:auto}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"DateTimeContainer": "DateTimeContainer--3_A-r593Yf",
	"widthContainer": "widthContainer--2NfxhJGNSE",
	"warn": "warn--38f7Gc-vQZ",
	"placeholder": "placeholder--21_29nP8Ny",
	"ButtonContainer": "ButtonContainer--7K-lveddqe",
	"download": "download--iwLgQt_Fbt"
};
module.exports = exports;

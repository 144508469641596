import * as React from 'react';

import Typography from 'common/components/typography/Typography';

import * as SCSScolors from 'common/styles/colors.scss';

import * as styles from './StringFillBackgroundCell.module.scss';

export interface IStringFillBackgroundCell {
  className?: string;
}
export interface IStringFillBackgroundCellRenderProp {
  translationKey?: string | undefined;
  textColor?: string | undefined;
  backgroundColor?: string | undefined;
}

function StringFillBackgroundCell ({
  className = ''
}: IStringFillBackgroundCell = {}) {
  return function StringFillBackgroundCell ({
    translationKey,
    textColor,
    backgroundColor
  }: IStringFillBackgroundCellRenderProp): JSX.Element {
    return (
      <div
        className={[ styles.StringFillBackgroundCell, className ].join(' ')}
        style={{
          backgroundColor: SCSScolors[backgroundColor],
          color: SCSScolors[textColor]
        }}
      >
        <Typography
          className={styles.Typography}
          type="body-2"
          translationKey={translationKey}
        />
      </div>
    );
  };
}

export { StringFillBackgroundCell };

import * as React from 'react';

interface ITableProps {
  color?: string,
  className?: string
}

function Table ({
  color = 'white',
  className
}: ITableProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M9 4H21C22.1046 4 23 4.89543 23 6V19C23 20.1046 22.1046 21 21 21H9H7H3C1.89543 21 1 20.1046 1 19V6C1 4.89543 1.89543 4 3 4H7H9ZM7 6H3V9H7V6ZM9 9V6H21V9H9ZM7 11H3V14H7V11ZM9 14V11H21V14H9ZM7 16H3V19H7V16ZM9 19V16H21V19H9Z' fill={color} />
    </svg>
  );
}

export default Table;

import * as React from "react";
import { Table } from "@tanstack/table-core/build/lib/types";
import Button from "components/Button";
import { Dialog } from "@mui/material";
import Icon from "common/components/icon/Icon";
import { ColumnFilterInput } from "../inputs";
import { tableV2Tracking } from "../../tracking";
import { useTranslation } from "react-i18next";
import { useTableContext } from "components/tableV2/tableState";

type InPlaceFilterProps<T> = {
  table: Table<T>;
};

export function InPlaceFilter<T>({ table }: InPlaceFilterProps<T>) {
  const { activeInPlaceFilterColumnId, setActiveInPlaceFilterColumnId } = useTableContext();
  const tableName = table.getState().tableName;
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2.filter",
  });
  const allLeafColumns = table.getAllLeafColumns();

  const inPlaceFilterColumn = allLeafColumns.find((column) => {
    const { enableColumnFilter, meta } = column?.columnDef || {};

    if (!enableColumnFilter || !activeInPlaceFilterColumnId) return false;

    return (
      meta?.columnFilter?.location === "in-place" && activeInPlaceFilterColumnId === column.id
    );
  });

  if (!inPlaceFilterColumn) return null;

  return (
    <>
      <Dialog
        className="font-kanit flex items-center justify-center fixed !outline-none !outline-0"
        open={Boolean(inPlaceFilterColumn)}
        sx={{
          flexShrink: 0,
        }}
        onClose={() => {
          setActiveInPlaceFilterColumnId(null);
          tableV2Tracking({
            name: "in-place filter close",
            properties: {
              location: "in-place",
            },
            tableName,
          });
        }}
      >
        <div
          id="more-filter"
          className="w-full space-y-4 py-6 px-4 lg:w-[440px] max-w-full min-h-full box-border flex flex-col rounded-md shadow-sm max-h-[calc(100vh-6rem)] bg-white"
        >
          <div className=" flex items-start gap-1 text-on-surface-high">
            <div className="flex-1">
              <h3 className="text-body1 text-on-surface-high font-medium">
                {inPlaceFilterColumn?.columnDef.meta.columnFilter.label ||
                  inPlaceFilterColumn?.columnDef?.id}
              </h3>
              <p className="text-caption text-on-surface-medium">
                {inPlaceFilterColumn.columnDef.meta?.columnFilter
                  ?.helperText && (
                  <span className="!font-light">
                    {
                      inPlaceFilterColumn.columnDef.meta?.columnFilter
                        ?.helperText
                    }
                  </span>
                )}
              </p>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setActiveInPlaceFilterColumnId(null)}
            >
              <Icon name="Close" color="onSurfaceHigh" />
            </div>
          </div>
          <div className="flex flex-col gap-3 flex-1 overflow-auto divide-y divide-outline">
            {inPlaceFilterColumn && (
              <div className="py-4">
                <ColumnFilterInput
                  className="w-full"
                  column={inPlaceFilterColumn}
                  location="in-place"
                  tableName={tableName}
                />
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setActiveInPlaceFilterColumnId(null);
                tableV2Tracking({
                  name: "apply button clicked",
                  properties: {
                    location,
                  },
                  tableName,
                });
              }}
            >
              {t("label_apply_button")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import * as React from 'react';

interface IListProps {
  color?: string;
  className?: string;
}

function List({ color = 'white', className }: IListProps): JSX.Element {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M21.0976 9.97191L19.2643 1.72191C19.1727 1.29793 18.7945 1 18.3706 1H7.37055C7.12986 1 6.88934 1.10306 6.71746 1.26352L3.96746 4.01352C3.40602 4.58638 3.81842 5.58339 4.62055 5.58339H5.6174L4.64355 9.97191C4.63197 10.0291 4.62055 10.098 4.62055 10.1666V22.0834C4.62055 22.5874 5.03312 23 5.53717 23H16.5372C16.6748 23 16.8237 22.9656 16.9497 22.9084L18.3706 22.1979L19.7914 22.9084C20.3872 23.1949 21.1091 22.7593 21.1206 22.0834V10.1666C21.1206 10.098 21.1091 10.0291 21.0976 9.97191ZM12.8133 2.83339H16.1592L15.2424 3.75H12.6414C12.7101 3.45207 12.7675 3.14273 12.8133 2.83339ZM10.9569 2.83339C10.8997 3.14273 10.8311 3.45207 10.7508 3.75H7.89759L8.10388 2.83339H10.9569ZM10.1206 5.58339V8.33339H11.9539V5.78951C11.9768 5.73227 12.0112 5.66362 12.0456 5.58339H15.6206C16.1362 5.58339 16.4685 5.125 16.7893 4.79266L15.8038 9.25H6.68305L7.49661 5.58339H10.1206ZM6.45394 11.0834H15.6206V21.1666H6.45394V11.0834ZM19.2872 20.6052L18.7831 20.3531C18.5194 20.227 18.2217 20.227 17.958 20.3531L17.4539 20.6052V10.2698L18.3706 6.14484L19.2872 10.2698V20.6052Z'
        fill={color}
      />
      <path
        d='M11.0371 12.917C9.51323 12.917 8.28711 14.1431 8.28711 15.667C8.28711 17.191 9.51323 18.417 11.0371 18.417C12.5612 18.417 13.7871 17.191 13.7871 15.667C13.7871 14.1545 12.5497 12.917 11.0371 12.917ZM11.0371 16.5838C10.5331 16.5838 10.1205 16.1712 10.1205 15.667C10.1205 15.1629 10.5331 14.7504 11.0371 14.7504C11.5413 14.7504 11.9539 15.1629 11.9539 15.667C11.9539 16.1712 11.5413 16.5838 11.0371 16.5838Z'
        fill={color}
      />
    </svg>
  );
}

export default List;

import { httpClient } from "common/services/transportService";
import { useQuery } from "react-query";
import { useGetWarehouses } from "warehousing/hooks/useGetWarehouses";

type Response = Array<{
  warehouseId: string;
  productId: string;
  productName: string;
  productDescription: string;
  productSKU: string;
  productImage: string;
  inventoryCode: string;
  packageType: string;
  packageValue: number;
  packageUnit: string;
  organisationId: string;
  totalStock: number
  vendor: string;
  expiryDate: string;
  enoughFor: string;
  monthlyUsage: number;
}>;

const queryKeyFactory = {
  lotsForProductId: (productId: string) => [{ scope: "lots", productId }] as const,
};

async function getProductsCatalog(productId: string) {
  const response = await httpClient.get<Response>(`catalog/${productId}/details`);
  return response.data;
}

export function useGetLotsForProduct(productId: string) {
  const warehousesQuery = useGetWarehouses();

  const lotsForProductQuery = useQuery({
    queryKey: queryKeyFactory.lotsForProductId(productId),
    queryFn: () => getProductsCatalog(productId),
    staleTime: 1000 * 60,
  });

  const isLoading = warehousesQuery.isLoading || lotsForProductQuery.isLoading;
  const data = isLoading ? undefined : lotsForProductQuery.data.map((lot) => {
    const warehouse = warehousesQuery.data?.find((warehouse) => warehouse.warehouseId === lot.warehouseId);
    return {
      ...lot,
      warehouseName: warehouse?.name,
    };
  });

  return {
    ...lotsForProductQuery,
    isLoading,
    data,
  };
}

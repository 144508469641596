import { createColumnHelper } from "components/tableV2";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { CashTableDataRow } from "./tableDataMapper";
import * as React from "react";
import { MachineStatusEnum } from "gql/generated";
import { MachineStatusLabel } from "containers/machinesV2/views/monitor/pages/overview/utils/columnsConfig";
import { formatCurrency } from "utils/currency";

export function generateCashColumnConfig(t: TFunction<"machine", undefined>) {
  const columnHelper = createColumnHelper<CashTableDataRow>();
  return [
    columnHelper.accessor("machineId", {
      header: () => <>{t("cash_page.table.columns.machineId_label")}</>,
      cell: (info) => (
        <Link
          className="!font-medium text-primary hover:underline"
          to={`/machines/detail/${info.getValue()}/inventory`}
        >
          {info.getValue()}
        </Link>
      ),
      enableColumnFilter: true,
      minSize: 70,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("cash_page.table.filters.machineId_label"),
        },
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full text-center">
          {t("cash_page.table.columns.status_label")}
        </div>
      ),
      cell: (info) => {
        switch (info.getValue()) {
          case MachineStatusEnum.Online:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Online}
                label={t(
                  "cash_page.table.filters.machine_status.options.online_label"
                )}
              />
            );
          case MachineStatusEnum.Offline:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Offline}
                label={t(
                  "cash_page.table.filters.machine_status.options.offline_label"
                )}
              />
            );
          case MachineStatusEnum.Maintenance:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Maintenance}
                label={t(
                  "cash_page.table.filters.machine_status.options.maintenance_label"
                )}
              />
            );
          case MachineStatusEnum.OutOfService:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.OutOfService}
                label={t(
                  "cash_page.table.filters.machine_status.options.out_of_service_label"
                )}
              />
            );
          default:
            return null;
        }
      },
      enableColumnFilter: true,
      minSize: 115,
      meta: {
        columnFilter: {
          type: "select",
          location: "secondary",
          label: t("cash_page.table.filters.machine_status.label"),
          options: [
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Online}
                  label={t(
                    "cash_page.table.filters.machine_status.options.online_label"
                  )}
                />
              ),
              value: MachineStatusEnum.Online,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Offline}
                  label={t(
                    "cash_page.table.filters.machine_status.options.offline_label"
                  )}
                />
              ),
              value: MachineStatusEnum.Offline,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.OutOfService}
                  label={t(
                    "cash_page.table.filters.machine_status.options.out_of_service_label"
                  )}
                />
              ),
              value: MachineStatusEnum.OutOfService,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Maintenance}
                  label={t(
                    "cash_page.table.filters.machine_status.options.maintenance_label"
                  )}
                />
              ),
              value: MachineStatusEnum.Maintenance,
            },
          ],
        },
      },
    }),
    columnHelper.accessor("organizationName", {
      header: () => <>{t("cash_page.table.columns.organization_name_label")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 80,
    }),
    columnHelper.accessor("locationName", {
      header: () => <>{t("cash_page.table.columns.location_name_label")}</>,
      cell: (info) => (
        <div className="flex items-center gap-1">
          <p className="flex-1">{info.getValue()}</p>
        </div>
      ),
      enableColumnFilter: true,
      minSize: 150,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("cash_page.table.filters.location_name_label"),
        },
      },
    }),
    columnHelper.accessor("refillZoneName", {
      header: () => <>{t("cash_page.table.columns.refill_zone_name_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableColumnFilter: true,
      enableSorting: true,
      minSize: 60,
      meta: {
        columnFilter: {
          type: "text",
          location: "modal",
          label: t("cash_page.table.filters.refill_zone_name_label"),
        },
      },
    }),
    columnHelper.accessor("cashValue", {
      header: () => <>{t("cash_page.table.columns.cash_value_label")}</>,
      cell: (info) => <>{formatCurrency({ input: info.getValue() })}</>,
      enableSorting: true,
      minSize: 60,
    }),
    columnHelper.accessor("bankNoteCount", {
      header: () => <>{t("cash_page.table.columns.bank_note_count_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableSorting: true,
      minSize: 60,
    }),
  ];
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--3vs6FQnIwh{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--3vs6FQnIwh .ButtonsContainer--2asiook33e{width:100%;display:flex;gap:25px}.TableHeader--3vs6FQnIwh .ButtonsContainer--2asiook33e>*:last-child{margin-left:auto}@media screen and (max-width: 768px){.TableHeader--3vs6FQnIwh .ButtonsContainer--2asiook33e{flex-direction:column}.TableHeader--3vs6FQnIwh .ButtonsContainer--2asiook33e>*:last-child{margin-left:revert}}.TableContainer--2KZamokvIa{overflow:auto}.TableContainer--2KZamokvIa>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--3vs6FQnIwh",
	"ButtonsContainer": "ButtonsContainer--2asiook33e",
	"TableContainer": "TableContainer--2KZamokvIa"
};
module.exports = exports;

import * as React from "react";
import { createColumnHelper } from "components/tableV2";
import {
  GetMachineLocationQuery,
  MachineStatusEnum,
} from "gql/generated";
import { clsx } from "clsx";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<GetMachineLocationQuery["machineMonitors"]["edges"]>();

const BASE_KEY_PREFIX = "map_page.table";

export function generateColumnConfig(
  translation: TFunction<"machine", undefined>
) {

  function t(key: string) {
    return translation(`${BASE_KEY_PREFIX}.${key}`);
  }

  return [
    columnHelper.accessor("node.machineId", {
      header: () => <></>,
      cell: () => <></>,
      enableColumnFilter: true,
      minSize: 70,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("filters.machineId_label"),
        },
      },
    }),
    columnHelper.accessor("node.status", {
      header: () => <></>,
      cell: () => <></>,
      enableColumnFilter: true,
      minSize: 115,
      meta: {
        columnFilter: {
          type: "select",
          location: "secondary",
          label: t("filters.machine_status.label"),
          options: [
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Online}
                  label={t("filters.machine_status.options.online_label")}
                />
              ),
              value: MachineStatusEnum.Online,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Offline}
                  label={t("filters.machine_status.options.offline_label")}
                />
              ),
              value: MachineStatusEnum.Offline,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.OutOfService}
                  label={t(
                    "filters.machine_status.options.out_of_service_label"
                  )}
                />
              ),
              value: MachineStatusEnum.OutOfService,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Maintenance}
                  label={t("filters.machine_status.options.maintenance_label")}
                />
              ),
              value: MachineStatusEnum.Maintenance,
            },
          ],
        },
      },
    }),
    columnHelper.accessor("node.locationName", {
      header: () => <>{t("columns.location_name_label")}</>,
      cell: (info) => <>{info.getValue()}</>,
      enableColumnFilter: true,
      minSize: 150,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("filters.location_name_label"),
        },
      },
    }),
    columnHelper.accessor("node.serviceZoneName", {
      header: () => <>{t("columns.service_zone_name_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableColumnFilter: true,
      minSize: 80,
      meta: {
        columnFilter: {
          type: "text",
          location: "secondary",
          label: t("filters.service_zone_name_label"),
        },
      },
    }),
    columnHelper.accessor("node.refillZoneName", {
      header: () => <>{t("columns.refill_zone_name_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableColumnFilter: true,
      minSize: 80,
      meta: {
        columnFilter: {
          type: "text",
          location: "secondary",
          label: t("filters.refill_zone_name_label"),
        },
      },
    }),
  ];
}


const MachineStatusLabel = ({
  status,
  label,
  className,
}: {
  status: MachineStatusEnum;
  className?: string;
  label: string;
}) => {
  const baseClassName = clsx(
    "h-4 leading-4 text-caption px-4 py-1 rounded-full flex-1 whitespace-nowrap text-center font-kanit",
    {
      "bg-success-overlay": status === MachineStatusEnum.Online,
      "bg-on-surface-disabled text-white": status === MachineStatusEnum.Offline,
      "bg-error-200": status === MachineStatusEnum.OutOfService,
      "bg-warning-overlay": status === MachineStatusEnum.Maintenance,
    },
    className
  );

  return <div className={baseClassName}>{label}</div>;
};

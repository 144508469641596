import {
  GetMaintenanceBoardNoTransaction2HoursDocument,
  GetMaintenanceBoardNoTransaction2HoursQuery, GetMaintenanceBoardNoTransaction2HoursQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getNoTransaction2Hours({
  serviceZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransaction2HoursQuery,
    GetMaintenanceBoardNoTransaction2HoursQueryVariables
  >(GetMaintenanceBoardNoTransaction2HoursDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}

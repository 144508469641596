// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SideNav--1y_cTdEBBX{background-color:#2c2e78;position:fixed;display:flex;box-shadow:0px 3px 14px rgba(0,0,0,.2);z-index:6}.SideNavDesktop--2FmrD6BGRS{width:60px;height:100%;flex-direction:column;justify-content:space-between}@media screen and (max-width: 768px){.SideNavDesktop--2FmrD6BGRS{display:none}}.SideNavDesktop--2FmrD6BGRS .menu--20efakqtUK{display:flex;flex-direction:column}.SideNavDesktop--2FmrD6BGRS .logo--214LTz2Sbr{display:flex;justify-content:center;align-items:center;height:60px}.SideNavDesktop--2FmrD6BGRS .footer--2Pn9pcqZzr{margin-bottom:20px}.SideNavMobile--3ZUgT5opoE{z-index:4;bottom:0;width:100%;flex-direction:row;justify-content:space-evenly}@media screen and (min-width: 768px){.SideNavMobile--3ZUgT5opoE{display:none}}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"SideNav": "SideNav--1y_cTdEBBX",
	"SideNavDesktop": "SideNavDesktop--2FmrD6BGRS",
	"menu": "menu--20efakqtUK",
	"logo": "logo--214LTz2Sbr",
	"footer": "footer--2Pn9pcqZzr",
	"SideNavMobile": "SideNavMobile--3ZUgT5opoE"
};
module.exports = exports;

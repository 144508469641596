import * as React from "react";

interface IPriorityMajorProps {
  className?: string;
}

function PriorityMajor({ className }: IPriorityMajorProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.6802 11.9121L10.0747 2.20294C9.1591 0.599021 6.84379 0.599021 5.9315 2.20294L0.322735 11.9121C-0.592847 13.516 0.546691 15.5085 2.39432 15.5085H13.5888C15.4364 15.5085 16.5957 13.4962 15.6802 11.9121ZM7.9998 13.3546C7.51236 13.3546 7.10727 12.9495 7.10727 12.4621C7.10727 11.9746 7.51236 11.5695 7.9998 11.5695C8.48723 11.5695 8.89233 11.9746 8.87256 12.4851C8.89562 12.9495 8.46747 13.3546 7.9998 13.3546ZM8.81328 7.58446C8.77376 8.27608 8.73095 8.96442 8.69142 9.65604C8.67166 9.88 8.67166 10.0842 8.67166 10.3049C8.6519 10.6704 8.36537 10.9537 7.9998 10.9537C7.63422 10.9537 7.35099 10.6902 7.32793 10.3246C7.26865 9.24765 7.20607 8.19045 7.14679 7.11349C7.12703 6.83025 7.10727 6.54372 7.08421 6.26048C7.08421 5.79281 7.34769 5.40748 7.77584 5.28562C8.20399 5.18352 8.62885 5.38772 8.81328 5.79281C8.87586 5.93443 8.89562 6.07605 8.89562 6.24072C8.87586 6.69193 8.83304 7.13984 8.81328 7.58446Z"
        fill="#E95D50"
      />
    </svg>
  );
}

export default PriorityMajor;

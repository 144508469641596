import * as React from "react";

interface IFilter2Props {
  color?: string;
  className?: string;
}

function Filter2({ color = "white", className }: IFilter2Props): JSX.Element {
  return (
    <svg
      className={className}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.68787L4.5 7.68787V11.9715L7.5 10.8465V7.68787L11 3.68787V2.5C11 1.94772 10.5523 1.5 10 1.5H2C1.44772 1.5 1 1.94772 1 2.5V3.68787ZM10 2.5V3H2V2.5H10ZM2.60188 4H9.39812L6.77312 7H5.22688L2.60188 4ZM5.5 8V10.5285L6.5 10.1535V8H5.5Z"
        fill={color}
      />
    </svg>
  );
}

export default Filter2;

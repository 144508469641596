import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'gql/fetcher';

export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
};

export type GetErrorScoreboardQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetErrorScoreboardQuery = {
  __typename?: 'Query';
  moduleErrorSummarySnapshot?: {
    __typename?: 'ModuleErrorSummaryResponse';
    meta?: {
      __typename?: 'ErrorScoreBoardMeta';
      limit: number;
      totalResult: number;
      cursor?: {
        __typename?: 'ErrorScoreBoardCursor';
        next?: string | null;
      } | null;
    } | null;
    results?: Array<{
      __typename?: 'ErrorScoreBoardResult';
      date?: string | null;
      hour?: string | null;
      machineCount?: number | null;
      errors?: Array<{
        __typename?: 'ErrorScoreBoardDetail';
        name: string;
        totalDisable: number;
        change: number;
        new: number;
        old: number;
        fixed: number;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetErrorScoreboardDocument = `
    query getErrorScoreboard($cursor: String, $limit: Int) {
  moduleErrorSummarySnapshot(cursor: $cursor, limit: $limit) {
    meta {
      limit
      totalResult
      cursor {
        next
      }
    }
    results {
      date
      hour
      machineCount
      errors {
        name
        totalDisable
        change
        new
        old
        fixed
      }
    }
  }
}
    `;
export const useGetErrorScoreboardQuery = <
  TData = GetErrorScoreboardQuery,
  TError = unknown
>(
  variables?: GetErrorScoreboardQueryVariables,
  options?: UseQueryOptions<GetErrorScoreboardQuery, TError, TData>
) =>
  useQuery<GetErrorScoreboardQuery, TError, TData>(
    variables === undefined
      ? ['getErrorScoreboard']
      : ['getErrorScoreboard', variables],
    fetcher<GetErrorScoreboardQuery, GetErrorScoreboardQueryVariables>(
      GetErrorScoreboardDocument,
      variables
    ),
    options
  );

import * as React from "react";
import * as styles from "./AddNewFactoryModal.module.scss";
import { useImmer } from "use-immer";
import MuiModal from "components/Modal/MuiModal";
import Typography from "components/Typography";
import Button from "components/Button";
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Select from "components/Select/Select";
import {
  useGetOrganisationsQuery,
  useCreateFactoryMutation,
  FactoryInput,
  LocationType,
} from "gql/generated";
import { toasti18n } from "utils/toast";
import { useQueryClient } from "react-query";
import { getProvince } from "containers/location/utils/getProvince";

function AddNewFactoryModal({ onClose }: { onClose: () => void }) {
  const [formValues, setFormValues] = useImmer<
    Omit<FactoryInput, "province" | "locationType"> & {
      province: string;
      locationType: LocationType | "";
    }
  >(() => ({
    address: "",
    latitude: "",
    longitude: "",
    locationName: "",
    organisationId: "",
    province: "",
    locationType: "",
  }));

  const queryClient = useQueryClient();
  const { mutate, isLoading: isCreatingLocation } = useCreateFactoryMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: (response) => {
      toasti18n.success([
        "toast_request_successful",
        ": ",
        response.createFactory.friendlyId,
        " ",
        "label_created",
      ]);
      queryClient.invalidateQueries(["GetLocationsDetailed"]);
      queryClient.invalidateQueries(["GetLocations"]);
      onClose();
    },
  });

  const { isLoading: isOrganisationsLoading, data: organisationQuery } =
    useGetOrganisationsQuery();
  const organisations = organisationQuery?.organisations;

  return (
    <MuiModal
      open={true}
      onClose={isCreatingLocation ? () => {} : onClose}
      minWidth="548px"
      padding="18px"
      PaperProps={{ className: styles.Paper }}
    >
      <>
        <div className={styles.Header}>
          <Typography translate type="headline-6">
            label_add_new_factory
          </Typography>
        </div>
        <div className={styles.FormContainer}>
          <TextField
            label="label_location_name_not_editable"
            value={formValues.locationName}
            required
            onChange={(e) =>
              setFormValues((draft) => {
                draft.locationName = e.target.value;
              })
            }
          />
          <Autocomplete
            label="label_organisation"
            id="organization"
            disableClearable
            onChange={(newValue) =>
              setFormValues((draft) => {
                draft.organisationId = newValue;
              })
            }
            value={formValues.organisationId}
            options={
              isOrganisationsLoading
                ? []
                : organisations.map((org) => {
                    return { label: org.name, value: org.id };
                  })
            }
            required
          />
          <Select
            required={true}
            label="label_location_type"
            id="type"
            value={formValues.locationType}
            onChange={(e) =>
              setFormValues((draft) => {
                draft.locationType = e.target.value;
              })
            }
            options={Object.values(LocationType).map((type) => ({
              value: type,
              label: `label_${type.toLowerCase()}`,
            }))}
          />
          <div className={styles.TwoFormFieldsContainer}>
            <div>
              <TextField
                label="label_latitude"
                value={formValues.latitude}
                required
                onChange={(e) =>
                  setFormValues((draft) => {
                    draft.latitude = e.target.value;
                  })
                }
              />
            </div>
            <div>
              <TextField
                label="label_longitude"
                value={formValues.longitude}
                required
                onChange={(e) =>
                  setFormValues((draft) => {
                    draft.longitude = e.target.value;
                  })
                }
              />
            </div>
          </div>

          <TextField
            label="label_address"
            value={formValues.address}
            required
            onChange={(e) =>
              setFormValues((draft) => {
                draft.address = e.target.value;
              })
            }
          />
          <Autocomplete
            value={formValues.province}
            onChange={(newValue) =>
              setFormValues((draft) => {
                draft.province = newValue;
              })
            }
            id="province"
            label="label_province"
            options={Object.values(getProvince()).map((type) => ({
              value: type,
              label: `label_${type.toLowerCase()}`,
            }))}
            disableClearable={true}
            translateLabels={true}
            required={true}
          />
        </div>
        <div className={styles.Divider}></div>
        <div className={styles.ButtonsContainer}>
          <Button
            type="secondary"
            onClick={onClose}
            disabled={isCreatingLocation}
          >
            action_cancel
          </Button>
          <Button
            type="primary"
            loading={isCreatingLocation}
            disabled={
              formValues.address === "" ||
              formValues.latitude === "" ||
              formValues.longitude === "" ||
              formValues.locationName === "" ||
              formValues.organisationId === "" ||
              formValues.province === "" ||
              formValues.locationType === ""
            }
            onClick={() => {
              mutate({
                factory: formValues as FactoryInput,
              });
            }}
          >
            label_add_new_factory
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export default AddNewFactoryModal;

import {
  GetMaintenanceBoardLidErrorDocument,
  GetMaintenanceBoardLidErrorQuery,
  GetMaintenanceBoardLidErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getLidError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardLidErrorQuery,
    GetMaintenanceBoardLidErrorQueryVariables
  >(GetMaintenanceBoardLidErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

import * as React from "react";
import {
  ELECTRIC_FEE_TYPE,
  ElectricFee,
  getElectricFeeTypeTranslation
} from "../../hooks/useGetLocationFee";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";
import { RadioButton } from "common/components/radiobutton/RadioButton";

export function ElectricFeeView({ electricFee }: { electricFee: ElectricFee }) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_electric_fee
      </Typography>
      <div style={{ height: "20px" }} />

      <TextFieldViewOnly
        label="label_payment_arrangement"
        value={getElectricFeeTypeTranslation(electricFee.type)}
        translateValue
      />

      <TextFieldViewOnly
        label="label_amount"
        value={electricFee.value.toLocaleString()}
      />
    </div>
  );
}

export type ElectricFeeFormValue = ElectricFee;
type ElectricFeeFormProps = {
  onChange: (value: ElectricFeeFormValue) => void;
  value: ElectricFeeFormValue;
};

export function mapElectricFeeToElectricFeeFormValue(
  electricFee: ElectricFee
): ElectricFeeFormValue {
  return electricFee;
}

export function initializeElectricFeeFormValue(): ElectricFeeFormValue {
  return {
    type: ELECTRIC_FEE_TYPE.FIXED,
    value: "",
  };
}

export function ElectricFeeForm({ value, onChange }: ElectricFeeFormProps) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_electric_fee
      </Typography>
      <div style={{ height: "20px" }} />

      {Object.values(ELECTRIC_FEE_TYPE).map((type) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "12px" }}
          key={type}
        >
          <RadioButton
            key={type}
            checked={value.type === type}
            onClick={() => {
              onChange({
                type,
                value: "",
              });
            }}
          />
          <Typography type="subtitle-1" translate>
            {getElectricFeeTypeTranslation(type)}
          </Typography>
        </div>
      ))}

      <div style={{ height: "10px" }} />

      <Typography type="subtitle-1" translate>
        label_electric_fee
        {" ("}
        {getElectricFeeTypeTranslation(value.type)}
        {")"}
      </Typography>

      <div style={{ height: "10px" }} />

      <TextField
        label="label_amount"
        value={value.value}
        onChange={(e) => {
          onChange({
            ...value,
            value: e.target.value,
          });
        }}
      />
    </div>
  );
}

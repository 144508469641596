import * as React from "react";
import {
  GetPlanForOperationPdfDocument,
  GetPlanForOperationPdfQuery,
  GetPlanForOperationPdfQueryVariables,
  MachineKind,
} from "gql/generated";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import { fetcher } from "gql/fetcher";
import { downloadPdf } from "pdf/utils/downloadPdf";
import { OperationSheet, formatDate } from "./OperationSheetBeverage";
import { RefillOrder, Item } from "./type";
import {
  OperationFrontCover,
  mapDataToPlanMachineSummary,
} from "./OperationFrontCover";
import { OperationTaobinPackageChecklist } from "./OperationTaobinPackageChecklist";
import { OperationSheetSpiral } from "./OperationSheetSpiral";
import { OperationSpiralPackageChecklist } from "./OperationSpiralPackageChecklist";
import { mapDataToPlanPackageSummary } from "./mapDataToPlanPackageSummary";

async function getPlanForOperationPdfFetcher(planId: string) {
  const planDataForPDF = await fetcher<
    GetPlanForOperationPdfQuery,
    GetPlanForOperationPdfQueryVariables
  >(GetPlanForOperationPdfDocument, { planId })();
  return planDataForPDF;
}

async function downloadPdfForPlanId(planId: string) {
  const planQuery = await getPlanForOperationPdfFetcher(planId);
  const taobinRefillOrders = mapDataToRefillOrder(
    planQuery,
    MachineKind.BeverageVending
  );
  const spiralRefillOrders = mapDataToRefillOrder(
    planQuery,
    MachineKind.SpiralVending
  );
  const frontCoverData = mapDataToPlanMachineSummary(planQuery);
  const taobinPackageSummary = mapDataToPlanPackageSummary(
    planQuery,
    MachineKind.BeverageVending
  );
  const spiralPackageSummary = mapDataToPlanPackageSummary(
    planQuery,
    MachineKind.SpiralVending
  );

  // File name for individual files ignored in case of merged PDF
  const PLACE_HOLDER_FOR_MERGED_PDF = "";
  const fileName = `${planQuery.plan.name}-${formatDate(planQuery.plan.createdAt)}`;

  await downloadPdf(
    [
      {
        document: <OperationFrontCover data={frontCoverData} />,
        fileName: PLACE_HOLDER_FOR_MERGED_PDF,
      },
      // not render OperationPackageChecklist
      ...(taobinRefillOrders.length > 0
        ? [
            {
              document: (
                <OperationTaobinPackageChecklist data={taobinPackageSummary} />
              ),
              fileName: PLACE_HOLDER_FOR_MERGED_PDF,
            },
          ]
        : []),
      ...taobinRefillOrders.map((ro) => ({
        document: (
          <OperationSheet
            refillOrder={ro}
            planName={planQuery.plan.name}
            key={ro.refillOrderId}
          />
        ),
        fileName: PLACE_HOLDER_FOR_MERGED_PDF,
      })),
      ...(spiralRefillOrders.length > 0
        ? [
            {
              document: (
                <OperationSpiralPackageChecklist data={spiralPackageSummary} />
              ),
              fileName: PLACE_HOLDER_FOR_MERGED_PDF,
            },
          ]
        : []),
      ...spiralRefillOrders.map((ro) => ({
        document: (
          <OperationSheetSpiral
            refillOrder={ro}
            planName={planQuery.plan.name}
            key={ro.refillOrderId}
          />
        ),
        fileName: PLACE_HOLDER_FOR_MERGED_PDF,
      })),
    ],
    fileName,
    { merge: true }
  );
}

export function useDownloadOperationPDFByPlanId() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function downloadOperationPDFByPlanId<T>(
    planId: string,
    options?: {
      onSuccess?: (arg: T) => void;
      onError?: (err: Error, arg: T) => void;
      onStart?: () => T;
    }
  ) {
    setIsLoading(true);
    const arg = options?.onStart?.();
    try {
      await downloadPdfForPlanId(planId);
      options?.onSuccess?.(arg);
    } catch (err) {
      console.log(err);
      options?.onError?.(err, arg);
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, downloadOperationPDFByPlanId };
}
function mapDataToRefillOrder(
  planForOpeartionPDFQuery: GetPlanForOperationPdfQuery,
  kind: MachineKind
): RefillOrder[] {
  const plan = planForOpeartionPDFQuery.plan;

  const refillOrders: RefillOrder[] = plan.refillOrders
    .filter((ro) => ro?.machine?.kind === kind)
    .sort((a, b) => a.positionInPlan - b.positionInPlan)
    .map((refillOrder) => {
      const location = refillOrder.location;

      const refillItems: Array<Item> = refillOrder.requestedItems.map((ri) => ({
        // We send usage in packages, backend sends back usage in grams (Mind blown)
        numberOfPackages: ri.usage / ri.product.package,
        slot: ri.slot,
        type: getSwapMethod(ri.newSlotConfig?.swapMethod) ?? "REFILL",
        package: {
          packageSKU: ri.product.SKU,
          packageUOM: ri.product.packageType,
          product: {
            productName: ri.product.name,
          },
          productQuantityPerPackage: ri.product.package,
          productUOMInPackage: ri.product.uom,
        },
      }));

      return {
        refillOrderId: refillOrder.id,
        criticalNote: refillOrder.isCritical ? refillOrder.criticalNote : null,
        locationNote: refillOrder.note,
        createdDateTimestamp: plan.createdAt,
        estimatedArrivalTimestamp: refillOrder.estimatedArrival,
        machineId: refillOrder.machineId,
        parentId: refillOrder.machine?.parentId,
        kind: refillOrder.machine.kind,
        locationName: location.locationName,
        routeName: location.refillZone.friendlyId,
        positionInPlan: `${refillOrder.positionInPlan}/${plan.refillOrders.length}`,
        requestedItems: [...refillItems].sort((a, b) =>
          alphanumeric(a.slot, b.slot)
        ),
      };
    });

  return refillOrders;
}

const SWAP_METHODS_MAPPING = {
  append: "SWAP_REFILL_ON_TOP",
  immediate: "SWAP_REPLACE",
} as const;

function getSwapMethod(
  swapMethod: string
):
  | (typeof SWAP_METHODS_MAPPING)[keyof typeof SWAP_METHODS_MAPPING]
  | undefined {
  return SWAP_METHODS_MAPPING[swapMethod];
}

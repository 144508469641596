import * as React from 'react';

interface IForecastProps {
  color?: string;
  className?: string;
}

function Forecast ({ color = 'white', className }: IForecastProps): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M23 6L13.5 15.5L8.5 10.5L1 18'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 6H23V12'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default Forecast;

import * as React from 'react';

import icons from './icons';

import * as colors from 'common/styles/colors.scss';

export interface IIconProps {
  name: string,
  color?: string,
  className?: string
}

export type IconNames = keyof typeof icons

function Icon ({
  name,
  color,
  className,
  ...restProps
}: IIconProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  const IconComponent = icons[name];

  if (!IconComponent) {
    console.warn(`${name} is not a valid icon!`);
    return null;
  }

  return (
    <IconComponent
      className={className}
      color={colors[color || 'surface']}
      {...restProps}
    />
  );
}

export default React.memo(Icon);

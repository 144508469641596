import { IMachineInventoryData } from "machines/interfaces";

export type IMachineInventoryWithPercentageData = IMachineInventoryData & {
  refillPercentage: number;
  warningPercentage: number;
}

export function mapDataToTable (data: IMachineInventoryData[]): IMachineInventoryWithPercentageData[] {
  return (data || []).map((i) => {
    let refillPercentage = Number(i.value) / Number(i.capacity);
    if (isNaN(refillPercentage)) {
      refillPercentage = 0;
    }
    if (refillPercentage === Infinity) {
      refillPercentage = 1;
    }

    let warningPercentage = Number(i.parLevel) / Number(i.capacity);
    if (isNaN(warningPercentage)) {
      warningPercentage = 0;
    }
    if (warningPercentage === Infinity) {
      warningPercentage = 1;
    }
    return {
      ...i,
      refillPercentage,
      warningPercentage,
    };
  });
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PieChartKey--DUNsiTuVdX{display:flex;align-items:baseline}.PieChartKey--DUNsiTuVdX .Doughnut--3s8fQMAfBS{align-self:center}.PieChartKey--DUNsiTuVdX .Label--1uddr9-mCt{margin-left:8px}.PieChartKey--DUNsiTuVdX .Value--2GrbMeQVTi{font-weight:700;margin-left:auto}", ""]);
// Exports
exports.locals = {
	"PieChartKey": "PieChartKey--DUNsiTuVdX",
	"Doughnut": "Doughnut--3s8fQMAfBS",
	"Label": "Label--1uddr9-mCt",
	"Value": "Value--2GrbMeQVTi"
};
module.exports = exports;

import { Menu } from "@mui/material";
import * as React from "react";
import { useDownloadLimitReportCSV } from "./downloadReportHook/useDownloadLimitReport";
import { useDownloadLastSellReport } from "./downloadReportHook/useDownloadLastSellReport";
import { useDownloadSelectedMachineReport } from "./downloadReportHook/useDownloadSelectedMachineReport";
import { useDownloadMachineDetailReport } from "./downloadReportHook/useDownloadMachineDetailReport";
import { MenuWithIcon } from "../MenuWithIcon";
import { useIsAdmin, useIsAdminContract, useIsOperator } from "utils/user-role";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";
import Button from "components/Button/Button";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import analytics from "utils/analytics";
import { ReportType, useGenerateReportMutation } from "gql/generated";

export function DownloadDropdownMenu() {
  const { t } = useTranslation("machine", {
    keyPrefix: "overview_page.header"
  });
  const { mutate: downloadAllReport, isLoading: isLoadingAllReport } = useGenerateReportMutation({
    onSuccess: (data) => {
      window.open(`/download-report/${data.generateReport}`);
    },
  });
  const { setModalOpen } = useMachinesStore();
  const { selectedMachine } = useMachinesStore();
  const isAdminOrOperator =
    useIsAdmin() || useIsAdminContract() || useIsOperator();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // download report hook
  
  const { downloadLimitReportCSV, isLoading: isLoadingDownloadLimitReport } =
    useDownloadLimitReportCSV();
  const { downloadLastSellReport, isLoading: isLoadingDownloadLastSellReport } =
    useDownloadLastSellReport();
  const {
    downloadSelectedMachineReport,
    isLoading: isLoadingDownloadSelectedMachineReport,
  } = useDownloadSelectedMachineReport();
  const {
    downloadMachineDetailReport,
    isLoading: isLoadingDownloadMachineDetailReport,
  } = useDownloadMachineDetailReport();

  const handleClickDownloadDropdown = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    analytics.track({
      name: "click download dropdown",
      properties: { location: "header" },
    });
  };

  const handleCloseDownloadDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="small"
        type="secondary"
        iconName="DownloadTray"
        onClick={handleClickDownloadDropdown}
      >
        <div className="flex items-center pl-1 gap-2">
          {t("download_dropdown.label")}
          <Icon name="ChevronThick" color="primary" />
        </div>
      </Button>
      <Menu
        id="machine-monitor-page-download-dropdown"
        aria-labelledby="machine monitor page download dropdown"
        open={open}
        onClose={handleCloseDownloadDropdown}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuWithIcon
          iconName="FolderOpen"
          disabled={isLoadingAllReport}
          onClick={() => {
            downloadAllReport({
              params: {
                reportType: ReportType.MachineInventory
              }
            });
            
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download all report",
              properties: { location: "header"}
            });
          }}
        >
          {t("download_dropdown.options.all_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Box"
          disabled={isLoadingDownloadLimitReport || !isAdminOrOperator}
          onClick={() => {
            downloadLimitReportCSV();
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download limit report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.limit_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="CreditCard"
          disabled={isLoadingDownloadLastSellReport || !isAdminOrOperator}
          onClick={() => {
            downloadLastSellReport();
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download last sell report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.last_sell_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="File"
          disabled={
            isLoadingDownloadSelectedMachineReport ||
            !isAdminOrOperator ||
            selectedMachine?.length === 0
          }
          onClick={() => {
            downloadSelectedMachineReport(selectedMachine);
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download selected machine report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.selected_machine_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Health"
          disabled={
            isLoadingDownloadSelectedMachineReport ||
            !isAdminOrOperator ||
            selectedMachine?.length === 0
          }
          onClick={() => {
            setModalOpen("DownloadHealth");
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download health report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.selected_machine_health_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Machine"
          disabled={
            isLoadingDownloadMachineDetailReport ||
            !isAdminOrOperator ||
            selectedMachine?.length === 0
          }
          onClick={() => {
            downloadMachineDetailReport(selectedMachine);
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download machine detail report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.selected_machine_detail_report_dropdown_label")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Inventory"
          disabled={
            isLoadingDownloadMachineDetailReport ||
            !isAdminOrOperator ||
            selectedMachine?.length === 0
          }
          onClick={() => {
            setModalOpen("DownloadInventoryUsage");
            handleCloseDownloadDropdown();
            analytics.track({
              name: "click download inventory usage report",
              properties: { location: "header"}
             });
          }}
        >
          {t("download_dropdown.options.selected_machine_inventory_usage_dropdown_label")}
        </MenuWithIcon>
      </Menu>
    </>
  );
}

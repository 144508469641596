import * as moment from 'moment';

export function toFixed (value: string, decimals: number): number {
  if (!value) {
    return 0;
  }
  return +parseFloat(value).toFixed(decimals).toLocaleString();
}

export function formatMoment (moment: moment.Moment): string {
  return moment.format('DD/MM/YY, HH:mm');
}

export function formatMomentDate (moment: moment.Moment): string {
  return moment.format('DD/MM/YY');
}

export function formatMomentDateFullYear (moment: moment.Moment): string {
  return moment.format('DD/MM/YYYY');
}

export function capitalizeFirstLetter (word: string): string {
  if (word === null) {
    return;
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export function formatMomentDateShortDayAndMonth (moment: moment.Moment): string {
  return moment.format('DD/MM');
}

import Typography from "components/Typography/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import { WarehousingWorkspacePaddingContainer } from "./WarehousingWorkspacePaddingContainer";
import { insertBetweenElements } from "../utils/insertBetweenElements";
import { warehouseBorderStyle } from "./borderStyle";
import { Skeleton } from "@mui/material";

export function WarehousingHeader({
  title,
  buttons,
  breadCrumbs,
  isLoading = false,
  afterElement = null
}: {
  title?: string;
  buttons?: React.ReactNode;
  breadCrumbs: Array<{ link?: string; label: string }>;
  isLoading?: boolean;
  afterElement?: JSX.Element,
}) {
  return (
    <header
      style={{
        backgroundColor: "white",
        borderTop: warehouseBorderStyle,
        borderBottom: warehouseBorderStyle,
        padding: "20px 0px",
      }}
    >
      <WarehousingWorkspacePaddingContainer>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "baseline",
            marginBottom: "-3px",
          }}
        >
          {isLoading ? (
            <Typography type="caption">
              <Skeleton width="350px" />
            </Typography>
          ) : (
            insertBetweenElements({
              array: breadCrumbs.map((breadCrumb) =>
                breadCrumb.link ? (
                  <Link to={breadCrumb.link} key={breadCrumb.link}>
                    <Typography type="caption" color="primary400" translate>
                      {breadCrumb.label}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    type="caption"
                    color="onSurfaceDisabled"
                    translate
                    key={breadCrumb.label}
                  >
                    {breadCrumb.label}
                  </Typography>
                )
              ),
              elementToInsert: (
                <div
                  style={{
                    alignSelf: "center",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <BreadCrumbChevron />
                </div>
              ),
            })
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography type="headline-5" translate color="onSurfaceHigh">
              {isLoading ? <Skeleton width="250px" /> : title}
            </Typography>
          </div>

          <div style={{ flexShrink: "0", marginLeft: "20px" }}>{buttons}</div>
        </div>
        {afterElement}
      </WarehousingWorkspacePaddingContainer>
    </header>
  );
}

function BreadCrumbChevron() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07742 5.00003L3.03871 1.96133L3.62797 1.37207L7.25593 5.00003L3.62797 8.62799L3.03871 8.03874L6.07742 5.00003Z"
        fill="black"
        fillOpacity="0.38"
      />
    </svg>
  );
}

import * as React from "react";
import LocationList from "./screens/LocationList";
import LocationId from "./screens/LocationId";
import EditLocationId from "./screens/EditLocationId";

import { Redirect, Route, Switch } from "react-router-dom";
import { Fallback } from "components/Fallback";
import { ErrorBoundary } from "@sentry/react";

function Location(): JSX.Element {
  return (
    <Switch>
      <Route path="/location/list">
        <ErrorBoundary fallback={LocationFallback} key="location-list">
          <LocationList />
        </ErrorBoundary>
      </Route>
      <Route path="/location/:id/edit">
        <ErrorBoundary fallback={LocationFallback} key="location-edit">
          <EditLocationId />
        </ErrorBoundary>
      </Route>
      <Route path="/location/:id">
        <ErrorBoundary fallback={LocationFallback} key="location">
          <LocationId />
        </ErrorBoundary>
      </Route>
      <Redirect to="/location/list" />
    </Switch>
  );
}

function LocationFallback({ error }: { error: Error }) {
  return (
    <div
      style={{
        margin: "-20px -35px",
        flex: "1 1 0",
        minHeight: 0,
      }}
    >
      <Fallback error={error} />
    </div>
  );
}

export default Location;

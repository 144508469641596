import * as React from 'react';

// Custom components
import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

// Styles
import * as styles from './FormField.module.scss';

interface IFormFieldProps {
  className?: string;
  icon: string;
  iconColor?: string;
  placeHolderText: string;
  fileName: string | '' | null;
}

function FormField ({
  className = '',
  fileName,
  placeHolderText,
  icon,
  iconColor
}: IFormFieldProps): JSX.Element {
  const fileSelected = fileName !== '' && fileName !== null;

  return (
    <div className={[ styles.FormField, className ].join(' ')}>
      <div className={styles.textContainer}>
        {fileSelected
          ? (
          <Typography type="subtitle-1" text={fileName} className={styles.fileName} />
            )
          : (
          <Typography type="subtitle-1" translationKey={placeHolderText} className={styles.placeHolderText}/>
            )}
      </div>
      <Icon name={icon} color={iconColor} />
    </div>
  );
}

export { FormField };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"font-main-light\";src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"font-main-regular\";src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"font-main-medium\";src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"font-alt-light\";src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"font-alt-regular\";src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"font-alt-medium\";src:url(\"/fonts/Roboto-Medium.ttf\")}@font-face{font-family:\"Kanit\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"Kanit\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"Kanit\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"Roboto\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"Roboto\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"Roboto\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Medium.ttf\")}.ScrollContainer--12WIWQ_bq5{display:flex;gap:16px;align-items:center;justify-content:space-between}.ErrorBoardTableTabsContainer--3HklaheUrj{display:flex;overflow-x:auto;scroll-snap-type:x mandatory;scrollbar-width:none;overscroll-behavior:contain}.ErrorBoardTableTabsContainer--3HklaheUrj::-webkit-scrollbar{display:none}.ScrollButton--umCCeFzHUL{background:none;border:none;display:flex;align-items:center;justify-content:center;padding:0;margin:0;outline:none;user-select:none}.ScrollIcon--2AJTfq7d4V{flex-shrink:0;padding-left:8px;padding-right:8px}", ""]);
// Exports
exports.locals = {
	"ScrollContainer": "ScrollContainer--12WIWQ_bq5",
	"ErrorBoardTableTabsContainer": "ErrorBoardTableTabsContainer--3HklaheUrj",
	"ScrollButton": "ScrollButton--umCCeFzHUL",
	"ScrollIcon": "ScrollIcon--2AJTfq7d4V"
};
module.exports = exports;

import * as React from 'react';

interface IPrinterProps {
  color?: string,
  className?: string
}

function Printer ({
  color = 'white',
  className
}: IPrinterProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4 7H6V2H18V7H20C21.1046 7 22 7.89543 22 9V17C22 18.1046 21.1046 19 20 19H18V22H6V19H4C2.89543 19 2 18.1046 2 17V9C2 7.89543 2.89543 7 4 7ZM16 7H8V4H16V7ZM20 9V17H18V15H6V17H4V9H20ZM16 17H8V20H16V17ZM19 11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11C17 11.5523 17.4477 12 18 12C18.5523 12 19 11.5523 19 11Z' fill={color} />
    </svg>
  );
}

export default Printer;

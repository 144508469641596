import * as React from "react";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import Typography from "components/Typography/Typography";
import { ContractContactInfo } from "../../hooks/useGetContractById";
import TextField from "components/TextField/TextField";

export function ContractContactInfoView({
  contactInfo,
}: {
  contactInfo: ContractContactInfo;
}) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_contact_info
      </Typography>
      <div style={{ height: "20px" }} />

      <TextFieldViewOnly
        label="label_contract_owner_name"
        value={contactInfo.contractOwnerName}
      />

      <TextFieldViewOnly
        label="label_account_payable_id"
        value={contactInfo.accountPayableId}
      />
      <TextFieldViewOnly
        label="label_contact_person"
        value={contactInfo.contactPerson}
      />
      <TextFieldViewOnly
        label="label_phone_number"
        value={contactInfo.phoneNumber}
      />
    </div>
  );
}

export type ContractContactInfoFormValue = ContractContactInfo;

type ContractContactInfoFormProps = {
  onChange: (value: ContractContactInfoFormValue) => void;
  value: ContractContactInfoFormValue;
};

export function initializeContractContactInfoFormValue(): ContractContactInfoFormValue {
  return {
    contractOwnerName: "",
    accountPayableId: "",
    contactPerson: "",
    phoneNumber: "",
  };
}

export function mapContractContactInfoToContractContactInfoFormValue(
  contactInfo: ContractContactInfo
): ContractContactInfoFormValue {
  return contactInfo;
}

export function ContractContactInfoForm({
  value,
  onChange,
}: ContractContactInfoFormProps) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_contact_info
      </Typography>
      <div style={{ height: "20px" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <TextField
          label="label_contract_owner_name"
          value={value.contractOwnerName}
          onChange={(e) => {
            onChange({
              ...value,
              contractOwnerName: e.target.value,
            });
          }}
        />

        <TextField
          label="label_account_payable_id"
          value={value.accountPayableId}
          onChange={(e) => {
            onChange({
              ...value,
              accountPayableId: e.target.value,
            });
          }}
        />

        <TextField
          label="label_contact_person"
          value={value.contactPerson}
          onChange={(e) => {
            onChange({
              ...value,
              contactPerson: e.target.value,
            });
          }}
        />

        <TextField
          label="label_phone_number"
          value={value.phoneNumber}
          onChange={(e) => {
            onChange({
              ...value,
              phoneNumber: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
}

import * as React from "react";
import {
  contractRoutes,
  useContractIdUrlParams,
  useLocationIdUrlParams,
} from "containers/contract/routes";
import { useGetLocationFee } from "../../hooks/useGetLocationFee";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TextButton from "common/components/textbutton/TextButton";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import { PaymentInformationView } from "../../components/location/PaymentInformation";
import { ElectricFeeView } from "../../components/location/ElectricFee";
import { InsuranceFeeView } from "../../components/location/InsuranceFee";
import { RentFeeView } from "../../components/location/RentFee";
import { ContractWorkSpace, Spacer } from "../../components/ContractWorkSpace";
import MuiLabel from "components/MuiLabel";
import Typography from "components/Typography";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";

export default function LocationFee() {
  const contractId = useContractIdUrlParams();
  const locationId = useLocationIdUrlParams();
  const getLocationFeeResult = useGetLocationFee({ contractId, locationId });
  const permissionResult = useCanAccessContractFeature();
  const isLoading =
    getLocationFeeResult.isLoading || permissionResult.isLoading;

  const { t } = useTranslation();

  return (
    <ContractWorkSpace.ScreenWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ContractWorkSpace.PageTitle>
          {t("label_location_fee")}
        </ContractWorkSpace.PageTitle>

        {isLoading ? null : (
          <div
            style={{
              marginLeft: "auto",
              flexShrink: 0,
            }}
          >
            <LocationFeeNav locationId={locationId} contractId={contractId} />
          </div>
        )}
      </div>

      {getLocationFeeResult.isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <ContractWorkSpace.MaxWidthContainer>
          <Spacer />
          <TextFieldViewOnly
            label={"label_contract"}
            value={getLocationFeeResult.data.contract.friendlyId}
          />

          <MuiLabel label="label_location">
            <Link
              to={`/location/${locationId}`}
              target="_blank"
              rel="noopener noreferrer"
              key={`location-${locationId}`}
            >
              <Typography type="subtitle-1" color="primary500">
                {`${getLocationFeeResult.data.location.friendlyId} - ${getLocationFeeResult.data.location.name}`}
              </Typography>
            </Link>
          </MuiLabel>

          <RentFeeView rentFee={getLocationFeeResult.data.fees.rentFee.fee} />
          <PaymentInformationView
            title="label_rent_payment_information"
            paymentInformation={
              getLocationFeeResult.data.fees.rentFee.paymentInformation
            }
          />
          <ElectricFeeView
            electricFee={getLocationFeeResult.data.fees.electricFee.fee}
          />
          <PaymentInformationView
            title="label_electric_payment_information"
            paymentInformation={
              getLocationFeeResult.data.fees.electricFee.paymentInformation
            }
          />

          <InsuranceFeeView
            insuranceFee={getLocationFeeResult.data.fees.insuranceFee.fee}
          />
          <PaymentInformationView
            title="label_insurance_fee_payment_information"
            paymentInformation={
              getLocationFeeResult.data.fees.insuranceFee.paymentInformation
            }
          />
        </ContractWorkSpace.MaxWidthContainer>
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function LocationFeeNav({
  locationId,
  contractId,
}: {
  locationId: string;
  contractId: string;
}) {
  return (
    <nav
      style={{
        marginLeft: "auto",
        flexShrink: 0,
      }}
    >
      <ul
        style={{
          display: "flex",
          gap: "17px",
          alignItems: "center",
          padding: 0,
          margin: 0,
        }}
      >
        <li
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          <Link to={contractRoutes.editLocation(contractId, locationId)}>
            <TextButton icon="Edit" translationKey="action_edit" />
          </Link>
        </li>
        <li
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "grid",
            placeContent: "center",
          }}
        >
          <Link
            to={contractRoutes.contractId(contractId)}
            style={{ display: "grid" }}
          >
            <Close width="24" height="24" fill="#4b4d9b" />
          </Link>
        </li>
      </ul>
    </nav>
  );
}

type SVGProps = React.SVGProps<SVGSVGElement>;

function Close(props: SVGProps) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <g>
        <path
          d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25
		C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0
		L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467
		L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468
		c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467
		C19.033,16.725,19.033,17.138,18.78,17.394z"
        />
      </g>
    </svg>
  );
}

import { useDismissMachineTicketMutation } from "gql/generated";
import * as React from "react";
import { useQueryClient } from "react-query";
import { ConfirmationModal } from "warehousing/components/adjustmodalcontainer/ConfirmationModal";
import { toast } from "react-toastify";
import i18n from "common/i18n";

export function DismissTicketModal({
  ticketId,
  onCancel,
}: {
  ticketId: string;
  onCancel: () => void;
}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useDismissMachineTicketMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        "GetTicketsByStatus",
        { status: "ready" },
      ]);
      queryClient.invalidateQueries(["GetTicketStatusCounts"]);
      onCancel();
      toast.success(i18n.t("toast_request_successful"));
    },
  });
  return (
    <ConfirmationModal
      acceptButtonprops={{
        type: "primary",
        onClick: () => {
          mutate({ updateMaintenanceTicketId: ticketId, status: "cancelled" });
        },
        loading: isLoading,
        translationKey: "action_confirm",
      }}
      cancelButtonProps={{
        type: "secondary",
        onClick: onCancel,
        disabled: isLoading,
        translationKey: "action_cancel",
      }}
      modalTitle="dismiss_ticket"
      modalText="action_confirm_description"
    />
  );
}

import * as React from 'react';

interface ISmallTruckProps {
  color?: string;
  className?: string;
}

function SmallTruck({
  color = 'white',
  className,
}: ISmallTruckProps): JSX.Element {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9213 4.99251L12.1496 7.44901L14 8.68264V10.9925H13.2195C12.945 10.2157 12.2042 9.65918 11.3334 9.65918C10.4625 9.65918 9.72171 10.2157 9.44716 10.9925H6.55288C6.27833 10.2157 5.5375 9.65918 4.66669 9.65918C3.79587 9.65918 3.05505 10.2157 2.78049 10.9925H2.00002V4.99251H10.9213ZM14 12.3258H13.2195C12.945 13.1026 12.2042 13.6592 11.3334 13.6592C10.4625 13.6592 9.72171 13.1026 9.44716 12.3258H6.55288C6.27833 13.1026 5.5375 13.6592 4.66669 13.6592C3.79587 13.6592 3.05505 13.1026 2.78049 12.3258H2.00002C1.26364 12.3258 0.666687 11.7289 0.666687 10.9925V4.99251C0.666687 4.25613 1.26364 3.65918 2.00002 3.65918H10.9213C11.4264 3.65918 11.888 3.94452 12.1139 4.39623L13.1838 6.53602L15.3334 7.96906V10.9925C15.3334 11.7289 14.7364 12.3258 14 12.3258ZM5.33335 11.6592C5.33335 12.0274 5.03488 12.3258 4.66669 12.3258C4.2985 12.3258 4.00002 12.0274 4.00002 11.6592C4.00002 11.291 4.2985 10.9925 4.66669 10.9925C5.03488 10.9925 5.33335 11.291 5.33335 11.6592ZM12 11.6592C12 12.0274 11.7015 12.3258 11.3334 12.3258C10.9652 12.3258 10.6667 12.0274 10.6667 11.6592C10.6667 11.291 10.9652 10.9925 11.3334 10.9925C11.7015 10.9925 12 11.291 12 11.6592Z"
        fill={color}
      />
    </svg>
  );
}

export default SmallTruck;

import * as React from "react";

function TableHeaderSortChevronAsc({
  className,
}: {
  className: string;
}): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.10938 11.5356L9.50223 14.9284L12.8951 11.5356"
        stroke="#121212"
      />
    </svg>
  );
}

export default TableHeaderSortChevronAsc;

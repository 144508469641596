/* eslint-disable react/display-name */
import * as React from "react";

import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import Checkbox from "components/Checkbox";
import Typography from "components/Typography";

import {
  Container,
  Header,
  OperationTable,
  TextButtonsContainer,
} from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";

import { useDownloadRefillOrdersForPlansByStateCSV } from "../../hooks";
import { useIdSelection } from "hooks/useIdSelection";
import { useSortState } from "hooks/useSortState";
import { useQueryClient } from "react-query";
import TextButton from "common/components/textbutton/TextButton";
import { toasti18n } from "utils/toast";
import { PreKitsModal } from "./PreKitsModal";
import {
  GetKittedPlansQuery,
  MachineKind,
  PlanStatus,
  useBatchUpdatePlanStatusMutation,
  useGetKittedPlansQuery,
} from "gql/generated";
import { DownloadOperationPDFButton } from "../operation-pdf/DownloadOperationPDFButton";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { useTranslation } from "react-i18next";
import analytics from "utils/analytics";

export default function PreKits() {
  const { t } = useTranslation();
  const { isLoading, data: kittedPlansQuery } = useGetKittedPlansQuery({
    status: [PlanStatus.Kitted],
  });
  const plans = React.useMemo(
    () => (isLoading ? undefined : mapDataToTable(kittedPlansQuery)),
    [kittedPlansQuery]
  );

  const allPlanIds = React.useMemo(
    () => plans?.map((plan) => plan.planId) ?? [],
    [plans]
  );
  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredPlans = React.useMemo(
    () =>
      appliedFilter
        ? plans?.filter((plan) => {
            let value;
            switch (appliedFilter.field) {
              case columns.planName:
                value = plan.planName;
                break;
              case columns.warehouseName:
                value = plan.warehouseName;
                break;
              default:
                value = plan.planName;
                break;
            }
            return value
              .toLowerCase()
              .includes(appliedFilter.value.toLowerCase());
          })
        : plans,
    [plans, appliedFilter]
  );
  const filteredPlanIds = React.useMemo(
    () => filteredPlans?.map((plan) => plan.planId),
    [filteredPlans]
  );

  const {
    selectionStatus,
    isIdSelected,
    toggleId,
    clearSelectionInView,
    selectAllInView,
    clearSelection,
    selectedIds,
  } = useIdSelection(allPlanIds, filteredPlanIds);

  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({ columnId: columns.planName, direction: "ASC" });

  const sortedPlans = React.useMemo(
    () =>
      sortState
        ? filteredPlans
            ?.map((elem) => elem)
            ?.sort((planA, planB) => {
              return (
                sortFnMap[sortState.columnId](planA, planB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredPlans,
    [filteredPlans, sortState]
  );

  const [planIdToView, setPlanIdToView] = React.useState<null | string>(null);

  const queryClient = useQueryClient();

  const { mutate, isLoading: isMutating } = useBatchUpdatePlanStatusMutation({
    onError: (err) => {
      toasti18n.error(err as Error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["GetKittedPlans"]);
      queryClient.invalidateQueries(["GetEnroutePlans"]);
      queryClient.invalidateQueries(["GetOperationStatusCounts"]);
      toasti18n.success();
      clearSelection();
    },
  });

  const {
    downloadRefillOrdersForPlansByStateCSV,
    isLoading: isDownloadingRefillOrdersByPlanState,
  } = useDownloadRefillOrdersForPlansByStateCSV();

  return (
    <Container>
      {planIdToView ? (
        <PreKitsModal
          planId={planIdToView}
          onClose={() => setPlanIdToView(null)}
          onCollectSuccess={() => {
            queryClient.invalidateQueries(["GetKittedPlans"]);
            queryClient.invalidateQueries(["GetEnroutePlans"]);
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            setPlanIdToView(null);
          }}
        />
      ) : null}
      <Header>
        <TextButtonsContainer>
          <TextButton
            icon="Cart"
            translationKey="label_move_to_enroute"
            disabled={selectedIds.length === 0 || isMutating}
            onClick={() => {
              mutate({
                plans: selectedIds.map((planId) => ({
                  id: planId,
                  status: PlanStatus.EnRoute,
                })),
              });
            
              selectedIds.forEach(id => {
                const plan = plans?.find(plan => plan.planId === id);
                if (plan) {
                  analytics.track({
                    name: "plan moved to enroute",
                    properties: {
                      plan_id: plan.planId,
                      plan_name: plan.planName,
                      warehouse: plan.warehouseName,
                      no_of_machine_in_plan: plan.taobinMachinesInRoute + plan.spiralMachinesInRoute,
                      no_of_machine_selected: selectedIds?.length
                    }
                  });
                }
              });
            }}
          />
          <TextButton
            icon="DownloadTray"
            translationKey="label_download_refill_orders_in_all_plans"
            disabled={isDownloadingRefillOrdersByPlanState}
            onClick={() => downloadRefillOrdersForPlansByStateCSV("kitted")}
          />
        </TextButtonsContainer>

        <Typography type="body-2" translate>
          {selectedIds.length}
          {` `}
          {selectedIds.length === 1 ? "label_plan" : "label_plans"}
          {` `}
          label_selected
        </Typography>
      </Header>
      <PureFilterBar
        placeholder="label_search"
        filterOptions={[
          {
            field: columns.planName,
            queryType: "contains",
            translationKey: "label_plan",
          },
          {
            field: columns.warehouseName,
            queryType: "contains",
            translationKey: "label_warehouse",
          },
        ]}
        onChange={setAppliedFilter}
        value={appliedFilter}
      />
      <OperationTable
        data={sortedPlans}
        columns={[
          {
            cell: (plan) => (
              <Checkbox
                checked={isIdSelected(plan.planId)}
                onClick={() => {
                  toggleId(plan.planId);
                }}
              />
            ),
            headerCell: () => (
              <Checkbox
                onClick={() => {
                  if (selectionStatus === "ALL") {
                    clearSelectionInView();
                  } else {
                    selectAllInView();
                  }
                }}
                checked={selectionStatus === "ALL"}
                indeterminate={selectionStatus === "PARTIAL"}
              />
            ),
            id: columns.checkbox,
            style: {
              flex: "0 0 42px",
              minWidth: "42px",
              paddingRight: "var(--table-h-padding)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
          {
            id: columns.planName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_plan")}
              </TableHeader>
            ),
            cell: (plan) => (
              <div
                style={{
                  fontFamily: "Kanit",
                  fontSize: "14px",
                  fontWeight: 700,
                  textDecoration: "underline",
                  color: "#4B4D9B",
                  cursor: "pointer",
                }}
                onClick={() => setPlanIdToView(plan.planId)}
              >
                {plan.planName}
              </div>
            ),
            style: {
              flex: "1 0 202px",
              minWidth: "202px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.warehouseName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_warehouse")}
              </TableHeader>
            ),
            cell: (plan) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {plan.warehouseName}
              </Typography>
            ),
            style: {
              flex: "1 0 176px",
              minWidth: "176px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.taobinMachinesInRoute,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_machines_in_plan")}
              </TableHeader>
            ),
            cell: (plan) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {plan.taobinMachinesInRoute}
              </Typography>
            ),
            style: {
              flex: "1 0 176px",
              minWidth: "176px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.spiralMachinesInRoute,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_spiral_machines_in_plan")}
              </TableHeader>
            ),
            cell: (plan) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {plan.spiralMachinesInRoute}
              </Typography>
            ),
            style: {
              flex: "1 0 176px",
              minWidth: "176px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.swapSheet,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_swap_sheet")}
              </TableHeader>
            ),
            cell: (plan) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {plan.swapSheets > 0 ? plan.swapSheets : null}
              </Typography>
            ),
            style: {
              flex: "1 0 176px",
              minWidth: "176px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.download,
            headerCell: () => (
              <Typography
                type="body-2"
                translate
                color="brainStormingBlackTint300"
              >
                action_download
              </Typography>
            ),
            cell: (plan) => <DownloadOperationPDFButton planId={plan.planId} />,
            style: {
              flex: "0 0 68px",
              minWidth: "68px",
              display: "flex",
              alignItems: "center",
            },
          },
        ]}
        isLoading={isLoading}
        getKey={(plan) => plan.planId}
        isSelected={(plan) => isIdSelected(plan.planId)}
      />
    </Container>
  );
}

interface TableRow {
  planId: string;
  planName: string;
  warehouseName: string;
  taobinMachinesInRoute: number;
  spiralMachinesInRoute: number;
  swapSheets: number;
}

function countMachinesInPlan(plan: GetKittedPlansQuery["plans"][0], type: MachineKind) {
  return plan.refillOrders.reduce((agg, curr) => {
    if(curr.machine.kind === type) {
      return agg + 1;
    }
    return agg;
  }, 0);
}

function mapDataToTable(kittedPlanQuery: GetKittedPlansQuery): TableRow[] {
  const kittedPlans = kittedPlanQuery.plans;
  return kittedPlans.map((plan) => ({
    planId: plan.id,
    planName: plan.name,
    warehouseName: plan.warehouse.name,
    taobinMachinesInRoute: countMachinesInPlan(plan, MachineKind.BeverageVending),
    spiralMachinesInRoute: countMachinesInPlan(plan, MachineKind.SpiralVending),
    swapSheets: plan.refillOrders.reduce(
      (agg, curr) =>
        curr.swapOrders.reduce((agg, curr) => curr.swapItems.length + agg, 0) +
        agg,
      0
    ),
  }));
}

const columns = {
  planName: "planName",
  warehouseName: "warehouseName",
  taobinMachinesInRoute: "taobinMachinesInRoute",
  spiralMachinesInRoute: "spiralMachinesInRoute",
  swapSheet: "swapSheet",
  checkbox: "checkbox",
  download: "download",
} as const;
type Columns = keyof typeof columns;

const sortFnMap: Record<Columns, (planA: TableRow, planB: TableRow) => number> =
  {
    planName: (planA, planB) => {
      return alphanumeric(planA.planName, planB.planName);
    },
    warehouseName: (planA, planB) => {
      return planA.warehouseName.localeCompare(planB.warehouseName);
    },
    taobinMachinesInRoute: (planA, planB) => {
      let comparisonValue = planA.taobinMachinesInRoute - planB.taobinMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralMachinesInRoute: (planA, planB) => {
      let comparisonValue = planA.spiralMachinesInRoute - planB.spiralMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    swapSheet: (planA, planB) => {
      let comparisonValue = planA.swapSheets - planB.swapSheets;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    checkbox: () => {
      throw new Error("Check box column not sortable");
    },
    download: () => {
      throw new Error("Download column not sortable");
    },
  };

import { useQuery } from "react-query";

// Types
import { AxiosError } from "axios";
import { IWarehouse } from "warehousing/interfaces";

// Service
import { getWarehouses } from "warehousing/service";

// Keys
import { warehouseKeys } from "./warehouseKeys";

function useGetWarehouses({ enabled = true, keepPreviousData = false } = {}) {
  return useQuery<IWarehouse[], AxiosError>(
    warehouseKeys.all(),
    getWarehouses,
    { enabled: enabled,
      keepPreviousData: keepPreviousData,
      staleTime: 10 * 60 * 1000
    }
  );
}

export { useGetWarehouses };

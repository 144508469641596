import { AutocompleteOption } from "components/Autocomplete";
import TextField from "components/TextField";
import { WarehouseIdAutoComplete } from "containers/location/components/form/WarehouseIdAutoComplete";
import { ServiceZoneIdAutoComplete } from "containers/location/components/form/ServiceZoneIdAutoComplete";
import { RefillZoneIdAutoComplete } from "containers/location/components/form/RefillzoneIdAutoComplete";
import * as React from "react";
import { LocationOperation } from "../types";
import { TextFieldWithLabel } from "./ui/TextField";

export type OperationInfoFormValue = {
  refillZoneId: string;
  serviceZoneId: string;
  warehouseId: string;
  refillOperationNote: string;
};

type OperationInfoFormProps = {
  organizationId: string;
  value: OperationInfoFormValue;
  onChange: (value: OperationInfoFormValue) => void;
  initialWarehouseOptions: Array<AutocompleteOption>;
  initialServiceZoneOptions: Array<AutocompleteOption>;
  initialRefillZoneOptions: Array<AutocompleteOption>;
};

export function mapOperationInfoToOperationInfoFormValue(
  operationInfo: LocationOperation
): OperationInfoFormValue {
  return {
    refillZoneId: operationInfo.refillZone.id,
    serviceZoneId: operationInfo.serviceZone.id,
    warehouseId: operationInfo.warehouse.id,
    refillOperationNote: operationInfo.refillOperationNote,
  };
}

export function OperationInfoForm({
  value,
  onChange,
  organizationId,
  initialWarehouseOptions,
  initialServiceZoneOptions,
  initialRefillZoneOptions,
}: OperationInfoFormProps) {
  const currentOrgIdRef = React.useRef<string>(organizationId);
  const currentOrgId = currentOrgIdRef.current;
  function resetForm() {
    onChange({
      ...value,
      refillZoneId: "",
      serviceZoneId: "",
      warehouseId: "",
    });
  }

  React.useEffect(
    function clearFormOnOrgChange() {
      if (currentOrgId !== organizationId) {
        currentOrgIdRef.current = organizationId;
        resetForm();
      }
    },
    [organizationId]
  );

  return (
    <div className="py-2 grid gap-2.5">
      <RefillZoneIdAutoComplete
        required
        value={value.refillZoneId}
        onChange={(refillZoneId) => {
          onChange({
            ...value,
            refillZoneId,
          });
        }}
        organizationId={organizationId}
        fallBackOptions={initialRefillZoneOptions}
      />

      <ServiceZoneIdAutoComplete
        required
        value={value.serviceZoneId}
        onChange={(serviceZoneId) => {
          onChange({
            ...value,
            serviceZoneId,
          });
        }}
        organizationId={organizationId}
        fallBackOptions={initialServiceZoneOptions}
      />
      <WarehouseIdAutoComplete
        required
        value={value.warehouseId}
        onChange={(warehouseId) => {
          onChange({
            ...value,
            warehouseId,
          });
        }}
        organizationId={organizationId}
        fallBackOptions={initialWarehouseOptions}
      />

      <TextField
        label="label_refill_operation_note"
        value={value.refillOperationNote}
        onChange={(e) => {
          onChange({
            ...value,
            refillOperationNote: e.target.value,
          });
        }}
      />
    </div>
  );
}

export function OperationInfo({
  operationInfo,
}: {
  operationInfo: LocationOperation;
}) {
  return (
    <>
      <TextFieldWithLabel
        label="label_refill_zone"
        value={operationInfo.refillZone.friendlyId}
        required
      />
      <TextFieldWithLabel
        label="label_service_zone"
        value={operationInfo.serviceZone.friendlyId}
        required
      />
      <TextFieldWithLabel
        label="label_warehouse"
        value={operationInfo.warehouse.name}
        required
      />
      <TextFieldWithLabel
        label="label_refill_operation_note"
        value={operationInfo.refillOperationNote}
      />
    </>
  );
}

import * as React from "react";

import { useImmer } from "use-immer";
import Button from "components/Button";
import TextField from "components/TextField";
import { useCreateLocationMutation, LocationType } from "gql/generated";
import { toasti18n } from "utils/toast";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import ResponsiveModal, {
  Title,
  modalBorderStyle,
  modalSpacing,
} from "components/ResponsiveModal";
import { OrganisationIdAutoComplete } from "containers/contract/components/contract/OrganisationIdAutoComplete";
import { LocationTypeSelect } from "containers/location/components/form/LocationTypeSelect";
import { ProvinceAutoComplete } from "containers/location/components/form/ProvinceAutoComplete";
import { WarehouseIdAutoComplete } from "containers/location/components/form/WarehouseIdAutoComplete";
import { ServiceZoneIdAutoComplete } from "containers/location/components/form/ServiceZoneIdAutoComplete";
import { RefillZoneIdAutoComplete } from "containers/location/components/form/RefillzoneIdAutoComplete";
import { ProjectIdAutoComplete } from "../../form/ProjectIdAutoComplete";
import { useCanViewAndEditAdditionalContractInfo } from "containers/location/hooks/useGetLocationPermissions";

type AddNewLocationForm = {
  locationName: string;
  organisationId: string;
  locationType: LocationType | "";
  latitude: string;
  longitude: string;
  address: string;
  province: string;
  refillZoneId: string;
  serviceZoneId: string;
  warehouseId: string;
  operationNote: string;
  contract: {
    sale: string;
    projectTypeId: string;
  };
};

function initializeForm(): AddNewLocationForm {
  return {
    address: "",
    latitude: "",
    longitude: "",
    locationName: "",
    locationType: "",
    refillZoneId: "",
    organisationId: "",
    province: "",
    serviceZoneId: "",
    warehouseId: "",
    operationNote: "",
    contract: {
      sale: "",
      projectTypeId: "",
    },
  };
}

function AddNewLocationModal({ onClose }: { onClose: () => void }) {
  const [formValues, setFormValues] =
    useImmer<AddNewLocationForm>(initializeForm);

  const { submit: createLocation, isLoading: isCreatingLocation } =
    useAddNewLocation({ onSuccess: onClose });

  const { allowed: allowedToViewAdditionalContractInfo } =
    useCanViewAndEditAdditionalContractInfo();

  return (
    <ResponsiveModal
      onClose={onClose}
      open={true}
      modalStyles={{
        maxWidth: "712px",
        heightStyles: {
          flex: "1 1 0",
          maxHeight: "760px",
          minHeight: "600px",
        },
        alignment: "start",
      }}
    >
      <Title>label_add_new_location</Title>

      <div
        style={{
          display: "flex",
          flexDirection: 'column',
          gap: 18,
          overflowY: "auto",
          borderTop: modalBorderStyle,
          borderBottom: modalBorderStyle,
          marginLeft: "-33px",
          marginRight: "-33px",
          padding: `10px ${modalSpacing.horizontalSpace}`,
        }}
      >
        <TextField
          label="label_location_name_not_editable"
          value={formValues.locationName}
          required
          onChange={(e) =>
            setFormValues((draft) => {
              draft.locationName = e.target.value;
            })
          }
        />
        <OrganisationIdAutoComplete
          onChange={(orgId) =>
            setFormValues((draft) => {
              draft.organisationId = orgId;

              // Reset service zone and warehouse fields when org id changes
              draft.serviceZoneId = "";
              draft.refillZoneId = "";
              draft.warehouseId = "";
            })
          }
          required
          value={formValues.organisationId}
        />

        <LocationTypeSelect
          value={formValues.locationType}
          onChange={(type) =>
            setFormValues((draft) => {
              draft.locationType = type;
            })
          }
          required
        />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: 18,
          }}
        >
          <TextField
            label="label_latitude"
            value={formValues.latitude}
            required
            onChange={(e) =>
              setFormValues((draft) => {
                draft.latitude = e.target.value;
              })
            }
          />
          <TextField
            label="label_longitude"
            value={formValues.longitude}
            required
            onChange={(e) =>
              setFormValues((draft) => {
                draft.longitude = e.target.value;
              })
            }
          />
        </div>

        <TextField
          label="label_address"
          value={formValues.address}
          required
          onChange={(e) =>
            setFormValues((draft) => {
              draft.address = e.target.value;
            })
          }
        />
        <ProvinceAutoComplete
          value={formValues.province}
          onChange={(newValue) =>
            setFormValues((draft) => {
              draft.province = newValue;
            })
          }
          required
        />
        <RefillZoneIdAutoComplete
          organizationId={formValues.organisationId}
          value={formValues.refillZoneId}
          onChange={(newValue) =>
            setFormValues((draft) => {
              draft.refillZoneId = newValue;
            })
          }
          required
        />
        <ServiceZoneIdAutoComplete
          organizationId={formValues.organisationId}
          value={formValues.serviceZoneId}
          onChange={(newValue) =>
            setFormValues((draft) => {
              draft.serviceZoneId = newValue;
            })
          }
          required
        />
        <WarehouseIdAutoComplete
          organizationId={formValues.organisationId}
          value={formValues.warehouseId}
          onChange={(newValue) =>
            setFormValues((draft) => {
              draft.warehouseId = newValue;
            })
          }
          required
        />

        <TextField
          label="label_operation_note"
          value={formValues.operationNote}
          onChange={(e) =>
            setFormValues((draft) => {
              draft.operationNote = e.target.value;
            })
          }
        />
        {allowedToViewAdditionalContractInfo ? (
          <>
            <TextField
              label="label_sale_person"
              value={formValues.contract.sale}
              onChange={(e) =>
                setFormValues((draft) => {
                  draft.contract.sale = e.target.value;
                })
              }
            />
            <ProjectIdAutoComplete
              value={formValues.contract.projectTypeId}
              onChange={(newValue) =>
                setFormValues((draft) => {
                  draft.contract.projectTypeId = newValue;
                })
              }
            />
          </>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "18px",
        }}
      >
        <Button
          type="secondary"
          onClick={onClose}
          disabled={isCreatingLocation}
        >
          action_cancel
        </Button>
        <Button
          type="primary"
          loading={isCreatingLocation}
          disabled={isFormDisabled(formValues)}
          onClick={() => createLocation(formValues)}
        >
          label_add_new_location
        </Button>
      </div>
    </ResponsiveModal>
  );
}

function isFormDisabled(form: AddNewLocationForm) {
  return (
    form.address === "" ||
    form.latitude === "" ||
    form.longitude === "" ||
    form.locationName === "" ||
    form.locationType === "" ||
    form.organisationId === "" ||
    form.province === "" ||
    form.refillZoneId === "" ||
    form.serviceZoneId === "" ||
    form.warehouseId === ""
  );
}

export default AddNewLocationModal;

function useAddNewLocation({ onSuccess }: { onSuccess?: () => void }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useCreateLocationMutation({
    onError: (error) => {
      toasti18n.error(error as Error);
    },
    onSuccess: (response) => {
      toasti18n.success([
        "toast_request_successful",
        ": ",
        response.createLocation.friendlyId,
        " ",
        "label_created",
      ]);
      queryClient.invalidateQueries(["GetLocationsDetailed"]);
      queryClient.invalidateQueries(["GetLocations"]);
      onSuccess?.();
      history.push(`/location/${response.createLocation.id}`);
    },
  });
  function submit(data: AddNewLocationForm) {
    mutate({
      location: {
        address: data.address,
        latitude: data.latitude,
        longitude: data.longitude,
        locationName: data.locationName,
        locationType: data.locationType as LocationType,
        organisationId: data.organisationId,
        province: data.province,
        refillZoneId: data.refillZoneId,
        serviceZoneId: data.serviceZoneId,
        warehouseId: data.warehouseId,
        operationNote: data.operationNote,
        ...(data.contract.projectTypeId !== "" && {
          projectTypeId: Number(data.contract.projectTypeId),
        }),
        ...(data.contract.sale !== "" && {
          salesPerson: data.contract.sale,
        }),
      },
    });
  }
  return { submit, ...rest };
}

import { AxiosError } from "axios";
import { ILotTransfer } from "interfaces/lotTransfer";
import { getLotTransfers } from "lot/service";
import { useQuery } from "react-query";
import { lotKeys } from "./lotKeys";

function useGetWarehouseLotsTransfer(warehouseId: string) {
  return useQuery<ILotTransfer[], AxiosError>(
    lotKeys.warehouseTransfers(warehouseId),
    () => getLotTransfers(warehouseId),
    {
      retry: 0,
      staleTime: 10 * 60 * 1000
    }
  );
}

export { useGetWarehouseLotsTransfer };

import * as React from 'react';

interface IInfoProps {
  color?: string,
  className?: string
}

function Info ({
  color = 'white',
  className
}: IInfoProps): JSX.Element {
  return (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  <path fillRule="evenodd" clipRule="evenodd" d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM12.0036 12.9983H13.003V14.9983H9.00295V12.9983H10.003V10.9983H9.00295V8.99835H12.0036V12.9983ZM12.0007 6.99835C12.0007 7.55063 11.5528 7.99835 11.0003 7.99835C10.4479 7.99835 10 7.55063 10 6.99835C10 6.44606 10.4479 5.99835 11.0003 5.99835C11.5528 5.99835 12.0007 6.44606 12.0007 6.99835Z" fill={color}/>
</svg>
  );
}

export default Info;




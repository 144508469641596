import * as React from 'react';

interface ICoinProps {
  color?: string;
  className?: string;
}

function Coin ({ color = 'white', className }: ICoinProps): JSX.Element {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 0.625C9.7925 0.625 6.25 1.72 6.25 3.75V6.25C6.25 6.595 6.53 6.875 6.875 6.875C7.22 6.875 7.5 6.595 7.5 6.25V5.59875C8.82625 6.435 11.0163 6.875 13.125 6.875C15.2338 6.875 17.4237 6.435 18.75 5.59875V6.25C18.75 6.86125 17.2125 7.83625 14.3225 8.0775C13.98 8.105 13.7237 8.40875 13.7525 8.7525C13.7787 9.07875 14.0525 9.325 14.3737 9.325C14.3925 9.325 14.4087 9.32375 14.4275 9.3225C16.055 9.18625 17.6762 8.765 18.75 8.08125V8.75C18.75 9.28375 17.6175 10.0513 15.5163 10.4225C15.1775 10.4825 14.95 10.8075 15.01 11.1462C15.0638 11.4487 15.3275 11.6625 15.6237 11.6625C15.66 11.6625 15.6975 11.66 15.7337 11.6525C17.005 11.4287 18.0225 11.0625 18.75 10.5975V11.25C18.75 11.7837 17.6175 12.5513 15.5163 12.9225C15.1775 12.9825 14.95 13.3075 15.01 13.6462C15.0638 13.9487 15.3275 14.1625 15.6237 14.1625C15.66 14.1625 15.6975 14.16 15.7337 14.1525C17.005 13.9287 18.0225 13.5612 18.75 13.0975V13.75C18.75 14.2837 17.6175 15.0513 15.5163 15.4225C15.1775 15.4825 14.95 15.8075 15.01 16.1462C15.0638 16.4487 15.3275 16.6625 15.6237 16.6625C15.66 16.6625 15.6975 16.66 15.7337 16.6525C17.005 16.4287 18.0225 16.0612 18.75 15.5975V16.25C18.75 16.8612 17.2125 17.8363 14.3225 18.0775C13.98 18.105 13.7237 18.4088 13.7525 18.7525C13.7787 19.0788 14.0525 19.325 14.3737 19.325C14.3925 19.325 14.4087 19.3237 14.4275 19.3225C17.2013 19.09 20 18.0513 20 16.25V3.75C20 1.72 16.4575 0.625 13.125 0.625ZM13.125 5.625C9.69125 5.625 7.5 4.51375 7.5 3.75C7.5 2.98625 9.69125 1.875 13.125 1.875C16.5588 1.875 18.75 2.98625 18.75 3.75C18.75 4.51375 16.5588 5.625 13.125 5.625Z"
        fill={color}
      />
      <path
        d="M6.875 8.125C3.5425 8.125 0 9.22 0 11.25V16.25C0 18.28 3.5425 19.375 6.875 19.375C10.2075 19.375 13.75 18.28 13.75 16.25V11.25C13.75 9.22 10.2075 8.125 6.875 8.125ZM12.5 16.25C12.5 17.0138 10.3088 18.125 6.875 18.125C3.44125 18.125 1.25 17.0138 1.25 16.25V15.5988C2.57625 16.435 4.76625 16.875 6.875 16.875C8.98375 16.875 11.1737 16.435 12.5 15.5988V16.25ZM12.5 13.75C12.5 14.5138 10.3088 15.625 6.875 15.625C3.44125 15.625 1.25 14.5138 1.25 13.75V13.0988C2.57625 13.935 4.76625 14.375 6.875 14.375C8.98375 14.375 11.1737 13.935 12.5 13.0988V13.75ZM6.875 13.125C3.44125 13.125 1.25 12.0138 1.25 11.25C1.25 10.4862 3.44125 9.375 6.875 9.375C10.3088 9.375 12.5 10.4862 12.5 11.25C12.5 12.0138 10.3088 13.125 6.875 13.125Z"
        fill={color}
      />
    </svg>
  );
}

export default Coin;

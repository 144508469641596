import { DashboardCardTextProps } from '../organisms/DashboardCard';
import { APIText } from 'dashboard/interface';

export function mapDataToTextCard (
  dataPoint: APIText): Omit<DashboardCardTextProps, 'className'> {
  return {
    type: 'text',
    cardProps: {
      title: dataPoint.title,
      icon: dataPoint.icon,
      labelType: dataPoint.labelType,
      valueType: dataPoint.valueType,
      data: dataPoint.data
    }
  };
};

import * as React from "react";
import { Switch, Redirect, useParams } from "react-router-dom";

import Route from "common/components/route/Route";
import ViewMenu from "common/components/viewmenu/ViewMenu";

import Inventory from "containers/machinedetail/views/inventory/Inventory";
import Health from "containers/machinedetail/views/health/Health";
import Transaction from "containers/machinedetail/views/transaction/Transaction";
import CashEvent from "containers/machinedetail/views/cashEvent";
import MachineIndexTransation from "./views/transaction/IndexTransaction/MachineIndexTransation";
import Details from "containers/machinedetail/views/details/Details";
import Status from "./views/status/Status";
import Debug from "containers/machinedetail/views/debug/Debug";
import Members from "containers/machinedetail/views/members/Members";
import { Maintenance } from "./views/maintenance/Maintenance";

import useIsVendiiUser from "common/hooks/useIsVendiiUser";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";

import * as styles from "./MachineDetail.module.scss";
import {
  GetMachinesStatusByParentIdQuery,
  GetMachineStatusByIdQuery,
  MachineKind,
  MachineStatusEnum,
  GetMachineDetailByIdQuery,
  useGetMachineDetailByIdQuery,
  useGetMachinesStatusByParentIdQuery,
  useGetMachineStatusByIdQuery,
} from "gql/generated";
import { IMachineData } from "machines/interfaces";
import { MachineTransactionGroupByError } from "./views/transaction/groupByError";

function mapIMachineDataToMachineStatusEnum(
  status: IMachineData["status"]
): MachineStatusEnum {
  switch (status) {
    case "online":
      return MachineStatusEnum.Online;
    case "offline":
      return MachineStatusEnum.Offline;
    case "maintenance":
      return MachineStatusEnum.Maintenance;
    case "out_of_service":
      return MachineStatusEnum.OutOfService;
    default:
      return MachineStatusEnum.Offline;
  }
}

function getSubHeader(
  machine: GetMachineDetailByIdQuery["machine"],
  parentMachine: GetMachineStatusByIdQuery,
  spiralMachines: GetMachinesStatusByParentIdQuery
) {
  if (machine?.kind === MachineKind.SpiralVending) {
    return parentMachine?.machine
      ? [
          {
            status: mapIMachineDataToMachineStatusEnum(
              parentMachine?.machine?.status
            ),
            subTitle: "",
            title: parentMachine?.machine?.id,
            to: `/machines/detail/${parentMachine?.machine.id}`,
          },
        ]
      : [];
  }

  return (
    spiralMachines?.machines?.map((machine) => ({
      status: mapIMachineDataToMachineStatusEnum(machine?.status),
      subTitle: "",
      title: machine?.id,
      to: `/machines/detail/${machine?.id}`,
    })) || []
  );
}

function MachineDetail(): JSX.Element {
  const { machineId }: { machineId: string } = useParams();

  const { data } = useGetMachineDetailByIdQuery({ machineId });
  const machine = data?.machine;

  const { data: spiralMachines } = useGetMachinesStatusByParentIdQuery(
    {
      parentId: machineId,
    },
    {
      enabled: machine?.kind === MachineKind.BeverageVending,
    }
  );

  const { data: parentMachine } = useGetMachineStatusByIdQuery(
    {
      machineId: machine?.parentId,
    },
    {
      enabled: machine?.kind === MachineKind.SpiralVending && !!machine?.parentId,
    }
  );

  const { data: userInfo } = useGetCurrentUser();
  const showDebugTab = useIsVendiiUser() || userInfo.canDebug;

  const tabOptions = [
    {
      to: `/machines/detail/${machineId}/inventory`,
      icon: "Inventory",
      translationKey: "menu_inventory",
    },
    {
      to: `/machines/detail/${machineId}/health`,
      icon: "Health",
      translationKey: "menu_health",
    },
    {
      to: `/machines/detail/${machineId}/status`,
      icon: "StatusEye",
      translationKey: "menu_status",
    },
    {
      to: `/machines/detail/${machineId}/transaction/group-by-none`,
      icon: "CreditCard",
      translationKey: "menu_transaction",
    },
    {
      to: `/machines/detail/${machineId}/cash`,
      icon: "Cash",
      translationKey: "menu_cash",
    },
    {
      to: `/machines/detail/${machineId}/details`,
      icon: "Machine",
      translationKey: "menu_details",
    },
    {
      to: `/machines/detail/${machineId}/maintenance`,
      icon: "Maintenance",
      translationKey: "menu_maintenance",
    },
    {
      to: `/machines/detail/${machineId}/members`,
      icon: "Avatar",
      translationKey: "menu_members",
    },
  ];

  // If user is part of vendii, show the debug tab
  if (showDebugTab) {
    tabOptions.push({
      to: `/machines/detail/${machineId}/debug`,
      icon: "Settings",
      translationKey: "workspace_debug",
    });
  }

  return (
    <div className={styles.MachineDetail}>
      <ViewMenu
        backPath="/machines/monitor"
        header={{
          title: machineId,
          subTitle: machine?.name || null,
          status: mapIMachineDataToMachineStatusEnum(machine?.status),
        }}
        subHeader={getSubHeader(machine, parentMachine, spiralMachines)}
        type={
          machine?.kind === MachineKind.SpiralVending ? "SpiralMachine" : "TaobinMachine"
        }
        options={tabOptions}
      />

      <div className={styles.content}>
        <Switch>
          <Route
            exact
            path="/machines/detail/:machineId/inventory"
            component={Inventory}
          />
          <Route
            exact
            path="/machines/detail/:machineId/health"
            component={Health}
          />
          <Route
            exact
            path="/machines/detail/:machineId/status"
            component={Status}
          />
          <Route
            exact
            path="/machines/detail/:machineId/transaction"
            component={Transaction}
          />
          <Route
            exact
            path="/machines/detail/:machineId/cash"
            component={CashEvent}
          />
          <Route
            path="/machines/detail/:machineId/transaction/group-by-none"
            component={MachineIndexTransation}
          />
          <Route
            exact
            path="/machines/detail/:machineId/transaction/group-by-error"
            component={MachineTransactionGroupByError}
          />
          <Route
            exact
            path="/machines/detail/:machineId/details"
            component={Details}
          />
          <Route
            exact
            path="/machines/detail/:machineId/maintenance"
            component={Maintenance}
          />
          <Route
            exact
            path="/machines/detail/:machineId/members"
            component={Members}
          />

          <Route
            exact
            path="/machines/detail/:machineId/debug"
            component={Debug}
          />
          <Redirect to={`/machines/detail/${machineId}/inventory`} />
        </Switch>
      </div>
    </div>
  );
}

export default MachineDetail;

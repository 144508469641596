import Tooltip from "common/components/tooltip/Tooltip";
import Typography from "common/components/typography/Typography";
import { get } from "lodash";
import * as React from "react";
import * as styles from "./ToolTipCell.module.scss";

interface ToolTipCellProps {
  onClick: (rowData: object) => void;
  toolTipTextKey: string;
  cellTextKey: string;
}

function ToolTipCell({
  onClick,
  toolTipTextKey,
  cellTextKey,
}: ToolTipCellProps) {
  return function rowDataAcceptor(...args) {
    // TODO: Temporary workaround for Nick's table component
    let rowData: object;
    if (args.length === 1) {
      rowData = args[0];
    } else {
      rowData = args[1];
    }
    return (
      <div className={styles.ToolTipCell}>
        <Tooltip
          align="left"
          content={
            <div className={styles.tooltip}>
              <Typography text={get(rowData, toolTipTextKey)} type="caption" />
            </div>
          }
          onClick={() => onClick(rowData)}
        >
          <Typography text={get(rowData, cellTextKey)} type="body-2" />
        </Tooltip>
      </div>
    );
  };
}

export { ToolTipCell };
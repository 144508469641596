import * as React from 'react';
import { useState, useEffect } from 'react';

import Typography from 'common/components/typography/Typography';

import * as colors from 'common/styles/colors.scss';
import * as styles from './ProgressBar.module.scss';

interface IProgressBarProps {
  percentage: number,
  label?: string | string[],
  labelClassName? : string,
  color?: string,
  animate?: boolean
}

function ProgressBar ({
  percentage,
  label,
  labelClassName = '',
  color,
  animate = false
}: IProgressBarProps): JSX.Element {
  const [ width, setWidth ] = useState<number>(animate ? 0 : percentage);

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setWidth(percentage);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [ animate, percentage ]);

  return (
    <div className={styles.ProgressBar}>
      <div className={styles.topRow}>
        <div className={styles.labelContainer}>
          <div className={styles.labelOverflowWrapper}>
            <Typography
              translationKey={label}
              type='caption'
              className={[
                styles.label,
                labelClassName
              ].join(' ')
              }
            />
          </div>
        </div>
        <Typography
          text={`${width}%`}
          type='caption'
          className={styles.label}
        />
      </div>
      <div className={styles.barWrapper}>
        <div
          className={[
            styles.bar,
            styles.overlay
          ].join(' ')}
          style={{
            backgroundColor: colors[color || 'success'],
            width: `${Math.min(width, 100)}%`
          }}
        />
        <div
          className={[
            styles.bar,
            styles.background
          ].join(' ')}
        />
      </div>
    </div>
  );
}

export default ProgressBar;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Doughnut--2P3ffSqOu7{width:6.4px;height:6.4px;border-width:4.8px;border-radius:50%;border-style:solid}", ""]);
// Exports
exports.locals = {
	"Doughnut": "Doughnut--2P3ffSqOu7"
};
module.exports = exports;

import { combineReducers } from "redux";

import { IAction } from "common/interfaces";

import applicationReducer from "common/store/reducers/applicationReducer";
import pendingRequestReducer from "common/store/reducers/pendingRequestReducer";
import errorReducer from "common/store/reducers/errorReducer";
import lastRequestReducer from "common/store/reducers/lastRequestReducer";

import productReducer from "product/reducer";
import refillOrderReducer from "refillorders/reducer";
import machineListReducer from "machines/reducers/machineListReducer";
import machineInventoryReducer from "machines/reducers/machineInventoryReducer";
import machineInventoryTemplatesReducer from "machines/reducers/machineInventoryTemplatesReducer";
import machineStatusReducer from "machines/reducers/machineStatusReducer";
import machineTransactionReducer from "machines/reducers/machineTransactionReducer";
import supplierReducer from "supplier/reducer";
import lotReducer from "lot/reducer";

const allReducers = combineReducers({
  application: applicationReducer,
  pendingRequest: pendingRequestReducer,
  error: errorReducer,
  lastRequest: lastRequestReducer,
  machines: machineListReducer,
  machineInventory: machineInventoryReducer,
  machineInventoryTemplates: machineInventoryTemplatesReducer,
  machineStatus: machineStatusReducer,
  machineTransaction: machineTransactionReducer,
  product: productReducer,
  refillOrder: refillOrderReducer,
  supplier: supplierReducer,
  lot: lotReducer,
});

function rootReducer(state, action: IAction) {
  if (action.type === "ROOT/FLUSH") {
    state = undefined;
  }
  return allReducers(state, action);
}

export default rootReducer;
export type IRootState = ReturnType<typeof rootReducer>;

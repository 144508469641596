// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Map--3fZNxduwUi{display:flex;flex-direction:column;flex:1;margin:0 -35px -20px -35px}@media screen and (max-width: 768px){.Map--3fZNxduwUi{margin:0 -20px -20px -20px}}.Map--3fZNxduwUi .machineCard--3tIRsFzyUi{width:340px}", ""]);
// Exports
exports.locals = {
	"Map": "Map--3fZNxduwUi",
	"machineCard": "machineCard--3tIRsFzyUi"
};
module.exports = exports;

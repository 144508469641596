import * as React from "react";
import { useImmer, useImmerReducer } from "use-immer";

import * as _ from "lodash";
import * as moment from "moment";

import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";
import { PriorityCell } from "../../../Components";
import Button from "components/Button";

import { toasti18n } from "utils/toast";

import { MachineKind, useCreatePlanMutation } from "gql/generated";
import { DirectionsRenderer, Marker, VendiiGoogleMap } from "../GoogleMaps";
import { useDirectionsService } from "@ubilabs/google-maps-react-hooks";

import { PlanInfo, RefillCandidate } from "./types";

import * as styles from "./RoutingStep.module.scss";
import { useGetWarehouseCoordinatesById } from "./SelectWarehouseStep";
import { trackMachineAddedToPlan, trackPlanCreated } from "./tracking";
import { useTranslation } from "react-i18next";
interface Props {
  onBack: () => void;
  initialRoutingStatus:
    | {
        status: "success";
      }
    | { status: "error"; error: Error };
  planInfo: PlanInfo;
  initialRoutedRefillCandidates: Array<RefillCandidate>;
  onCreatePlanSuccess: () => void;
}

export default function RoutingStep({
  initialRoutingStatus,
  onBack,
  planInfo,
  initialRoutedRefillCandidates,
  onCreatePlanSuccess,
}: Props) {
  const [status, setStatus] = React.useState<
    "init" | "needs_reroute" | "route_success" | "route_failed" | "routing"
  >(initialRoutingStatus.status === "error" ? "route_failed" : "init");

  const [error, setError] = React.useState<Error | null>(
    initialRoutingStatus.status === "error" ? initialRoutingStatus.error : null
  );

  const [refillCandidateIdsPosition, _dispatch] = useImmerReducer(
    reducer,
    initialRoutedRefillCandidates.map(
      (refillCandidate) => refillCandidate.candidateId
    )
  );
  function dispatch(action: Action) {
    // Allows refill candidates to move up and down in these states
    if (
      status === "route_failed" ||
      status === "route_success" ||
      status === "needs_reroute"
    ) {
      _dispatch(action);
    }
  }

  const [currentlyRoutedPosition, setCurrentlyRoutedPosition] = React.useState<
    undefined | Array<string>
  >(undefined);
  React.useEffect(() => {
    // If routing succeeded and user changes the order of the refill candidates, needs rerouting
    if (
      status === "route_success" &&
      !_.isEqual(refillCandidateIdsPosition, currentlyRoutedPosition)
    ) {
      setStatus("needs_reroute");
    }
    // No longer need to rereoute if the order is the same as the last successful route
    if (
      status === "needs_reroute" &&
      _.isEqual(refillCandidateIdsPosition, currentlyRoutedPosition)
    )
      setStatus("route_success");
  }, [refillCandidateIdsPosition, currentlyRoutedPosition, status]);

  const directionServiceResult = useDirectionWithArrivalTimes({
    serviceTime: 20,
  });

  React.useEffect(() => {
    // If in the route failed state, immediately exit
    if (status === "route_failed") return;

    // If google maps does not exist / fail to load, transition to route failed immediately
    if (!directionServiceResult.routingServiceAvailable) {
      setError(new Error("Google maps failed to load"));
      setStatus("route_failed");
    }

    // Attempt to route the currently selected refill candidates on mount
    handleRoute();
  }, [directionServiceResult.routingServiceAvailable]);

  const warehouseCoordinates = useGetWarehouseCoordinatesById(
    planInfo.warehouseId
  );

  function handleRoute() {
    if (status !== "needs_reroute" && status !== "init") return;
    setStatus("routing");
    setCurrentlyRoutedPosition(undefined);
    directionServiceResult.route(
      {
        departureTime: new Date(Number(planInfo.startTime)),
        coordinates: [
          warehouseCoordinates,
          ...refillCandidateIdsPosition.map((candidateId) => {
            const candidate = findCandidateById(candidateId);
            return {
              lat: candidate.lat,
              lng: candidate.lng,
            };
          }),
        ],
      },
      {
        onSuccess() {
          setCurrentlyRoutedPosition(refillCandidateIdsPosition);
          setStatus("route_success");
        },
        onError: (err) => {
          setError(err);
          setStatus("route_failed");
        },
      }
    );
  }

  function findCandidateById(id: string) {
    return initialRoutedRefillCandidates.find(
      (candidate) => candidate.candidateId === id
    );
  }

  const { mutate: createPlan, isLoading: isCreatingPlan } =
    useCreatePlanMutation({
      onError: (error: Error) => {
        toasti18n.error(error);
      },
      onSuccess: (successData, variables) => {
        toasti18n.success();
        onCreatePlanSuccess();
        trackMachineAddedToPlan({
          data: initialRoutedRefillCandidates,
          planId: successData.createPlan.id,
          variables: variables.plan,
        });
        trackPlanCreated({
          planInfo,
          successData,
          variables: variables.plan
        });
      },
    });

  function handleCreatePlan() {
    // Only allow creating plan when routing has been attempted
    if (status !== "route_success" && status !== "route_failed") return;
    createPlan({
      plan: {
        startTime: planInfo.startTime,
        warehouseId: planInfo.warehouseId,
        name: planInfo.planName,
        refillOrderCandidates: refillCandidateIdsPosition.map(
          (candidateId, index) => ({
            candidateId: candidateId,
            positionInPlan: index + 1,
            // Estimated arrival to each machine is the start time if routing fails
            estimatedArrival:
              status === "route_success"
                ? String(directionServiceResult.estimatedArrivals[index + 1])
                : planInfo.startTime,
          })
        ),
      },
    });

  }

  return (
    <div className={styles.RoutingStep}>
      <div className={styles.Header}>
        <Typography type="headline-6" color="onSurfaceHigh">
          {planInfo.planName}{" "}
        </Typography>
        <Typography
          translate
          type="subtitle-1"
          color="onSurfaceMedium"
          className={styles.NumberOfMachines}
        >
          {"("}
          {`${refillCandidateIdsPosition.length} `}
          {"label_machines"}
          {")"}
        </Typography>
      </div>
      <div className={styles.Main}>
        <div className={styles.TableContainer}>
          <div className={styles.TableWrapper}>
            <div className={styles.TableHeader}>
              <div className={styles.TableRow}>
                <div className={styles.RoutePositionColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_route_position
                  </Typography>
                </div>
                <div className={styles.MachineIdColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_machine_id
                  </Typography>
                </div>
                <div className={styles.TimeToColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_time_to
                  </Typography>
                </div>
                <div className={styles.ChangePositionColumn}></div>
                <div className={styles.PriorityColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_priority
                  </Typography>
                </div>
                <div className={styles.LocationNameColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_location_name
                  </Typography>
                </div>
                <div className={styles.NoteColumn}>
                  <Typography
                    translate
                    type="body-2"
                    color="brainStormingBlackTint300"
                  >
                    label_note
                  </Typography>
                </div>
              </div>
            </div>

            <div className={styles.TableBody}>
              {refillCandidateIdsPosition
                .map((refillCandidateId) =>
                  findCandidateById(refillCandidateId)
                )
                .map((refillCandidate, index) => (
                  <div
                    className={styles.TableRow}
                    key={`refill-candidate-${refillCandidate.candidateId}`}
                  >
                    <div className={styles.RoutePositionColumn}>
                      <Typography type="body-2" color="onSurfaceHigh">
                        {index + 1}
                      </Typography>{" "}
                      {refillCandidate.orderMetaData.critical ? (
                        <Icon name="PriorityMajor" />
                      ) : null}
                    </div>
                    <div className={styles.MachineIdColumn}>
                      {refillCandidate?.kind === MachineKind.SpiralVending ? (
                        <div className="flex gap-2 items-center justify-between flex-1 pr-3">
                          <div>
                            <Typography
                              type="body-2"
                              translate
                              color="onSurfaceHigh"
                              className="leading-5"
                            >
                              {refillCandidate.machineId}
                            </Typography>
                            <p className="font-kanit text-caption leading-4 text-on-surface-disabled">{refillCandidate.parentId}</p>
                          </div>
                          <Icon name="Snack" color="primary" />
                        </div>
                      ): (
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceHigh"
                        >
                          {refillCandidate.machineId}
                        </Typography>
                      )}
                    </div>

                    <div className={styles.TimeToColumn}>
                      <Typography type="body-2" color="onSurfaceHigh">
                        {status === "route_success"
                          ? moment(
                              directionServiceResult.estimatedArrivals[
                                index + 1
                              ]
                            ).format("HH:mm:ss")
                          : "-"}
                      </Typography>
                    </div>
                    <div className={styles.ChangePositionColumn}>
                      <div
                        onClick={() =>
                          dispatch({
                            type: "MOVE_UP",
                            payload: {
                              id: refillCandidate.candidateId,
                            },
                          })
                        }
                        className={styles.ChangePositionIcons}
                      >
                        <Icon color="primary500" name="ArrowTop" />
                      </div>
                      <div
                        className={styles.ChangePositionIcons}
                        onClick={() =>
                          dispatch({
                            type: "MOVE_DOWN",
                            payload: {
                              id: refillCandidate.candidateId,
                            },
                          })
                        }
                      >
                        <Icon color="primary500" name="ArrowBottom" />
                      </div>
                    </div>
                    <div className={styles.PriorityColumn}>
                      <PriorityCell
                        priority={refillCandidate.orderMetaData.priority}
                      />
                    </div>
                    <div className={styles.LocationNameColumn}>
                      <Typography type="body-2" color="onSurfaceHigh">
                        {refillCandidate.locationName}
                      </Typography>
                    </div>

                    <div className={styles.NoteColumn}>
                      <div>
                        {refillCandidate.orderMetaData.criticalNote ? (
                          <Typography type="body-2" color="error">
                            {refillCandidate.orderMetaData.criticalNote}{" "}
                          </Typography>
                        ) : null}
                        {refillCandidate.orderMetaData.locationNote ? (
                          <Typography type="body-2" color="onSurfacehigh">
                            {(refillCandidate.orderMetaData.criticalNote
                              ? ", "
                              : "") +
                              (refillCandidate.orderMetaData.locationNote ??
                                "")}
                          </Typography>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.MapContainer} style={{ position: "relative" }}>
          <VendiiGoogleMap />
          <DirectionsRenderer
            directions={
              status === "route_success"
                ? directionServiceResult.directionResults
                : null
            }
          />
          <Marker
            markers={[
              { ...warehouseCoordinates, icon: "Warehouse" },
              ...refillCandidateIdsPosition.map((candidateId, index) => ({
                ...findCandidateById(candidateId),
                icon: index + 1,
              })),
            ]}
          />
          {status === "route_failed" ? <ErrorOverlay error={error} /> : null}
        </div>
      </div>
      <div className={styles.ButtonsContainer}>
        <Button
          onClick={onBack}
          type="secondary"
          disabled={status === "routing" || isCreatingPlan}
        >
          label_back
        </Button>
        <div className={styles.ForecastAndReRouteContainer}>
          <Button
            onClick={handleRoute}
            loading={status === "routing" || status === "init"}
            disabled={status !== "needs_reroute" || isCreatingPlan}
            type="primary"
          >
            label_reroute
          </Button>

          <Button
            disabled={status !== "route_success" && status !== "route_failed"}
            loading={isCreatingPlan}
            onClick={handleCreatePlan}
            type="primary"
          >
            label_kit
          </Button>
        </div>
      </div>
    </div>
  );
}

function reducer(draft: Array<string>, action: Action) {
  const lastIndex = draft.length - 1;
  const indexOfId = draft.findIndex((draft) => draft === action.payload.id);
  switch (action.type) {
    case "MOVE_UP": {
      if (indexOfId === 0) {
        break;
      }
      [draft[indexOfId], draft[indexOfId - 1]] = [
        draft[indexOfId - 1],
        draft[indexOfId],
      ];
      break;
    }
    case "MOVE_DOWN": {
      if (indexOfId === lastIndex) {
        break;
      }
      [draft[indexOfId], draft[indexOfId + 1]] = [
        draft[indexOfId + 1],
        draft[indexOfId],
      ];
      break;
    }
  }
}

type Action = {
  type: "MOVE_UP" | "MOVE_DOWN";
  payload: {
    id: string;
  };
};

interface UseDirectionWithArrivalTimesArgs {
  serviceTime: number;
}

interface RouteRequest {
  departureTime: google.maps.DirectionsRequest["drivingOptions"]["departureTime"];
  coordinates: Array<google.maps.LatLngLiteral>;
}

function useDirectionWithArrivalTimes({
  serviceTime,
}: UseDirectionWithArrivalTimesArgs) {
  const { directionsService } = useDirectionsService();
  const routingServiceAvailable = Boolean(directionsService);

  const [{ directionResults, estimatedArrivals, status }, setState] = useImmer<{
    directionResults: google.maps.DirectionsResult | undefined;
    estimatedArrivals: Array<number> | undefined;
    status: "idle" | "loading" | "success" | "error";
  }>({
    directionResults: undefined,
    estimatedArrivals: undefined,
    status: "idle",
  });

  async function route(
    request: RouteRequest,
    {
      onError,
      onSuccess,
    }: { onError: (err: Error) => void; onSuccess: () => void }
  ) {
    if (request.coordinates.length <= 1) {
      throw new Error("Routing requires more than one coordinate");
    }
    setState((draft) => {
      draft.status = "loading";
    });
    try {
      const { coordinates, departureTime } = request;
      const googleRequest: google.maps.DirectionsRequest = {
        origin: coordinates[0],
        destination: coordinates[coordinates.length - 1],
        ...(coordinates.length > 2
          ? {
              waypoints: coordinates
                .slice(0, coordinates.length - 1)
                .map((coordinate) => ({ location: coordinate })),
            }
          : {}),
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime,
        },
      };

      const response = await directionsService.route(googleRequest);

      setState((draft) => {
        draft.directionResults = response;
        draft.estimatedArrivals = calculateEstimateArrivals(
          response,
          departureTime,
          serviceTime
        );
        draft.status = "success";
      });
      onSuccess();
    } catch (err) {
      onError(err);
      setState((draft) => {
        draft.status = "error";
      });
    }
  }

  return {
    status,
    route,
    routingServiceAvailable,
    directionResults,
    estimatedArrivals,
  };
}

function calculateEstimateArrivals(
  directionResults: google.maps.DirectionsResult,
  startTime: Date,
  /** Time in minutes */
  serviceTime: number
) {
  const startTimestamp = startTime.valueOf();
  let currentTimestamp = startTimestamp;
  const serviceTimeInMs = serviceTime * 60 * 1000;
  const estimatedArrivalsTimestamps: Array<number> = [];

  let legs = directionResults.routes[0].legs;
  // For single machine, leg[0] is duration
  // For multiple machines (Way points), leg[0] duration will always be 0 and can be discarded
  if (legs.length !== 1) {
    legs = legs.slice(1);
  }
  estimatedArrivalsTimestamps.push(currentTimestamp);

  legs.forEach((leg) => {
    currentTimestamp = currentTimestamp + leg.duration.value * 1000;
    estimatedArrivalsTimestamps.push(currentTimestamp);
    currentTimestamp = currentTimestamp + serviceTimeInMs;
  });
  return estimatedArrivalsTimestamps;
}

function ErrorOverlay({ error }: { error: Error }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        top: 0,
        left: 0,
        zIndex: 2,
        display: "grid",
        gridTemplateRows: "repeat(3, 1fr)",
        padding: "25px",
        boxSizing: "border-box",
        overflow: "auto",
        pointerEvents: "none",
      }}
    >
      <div></div>
      <div>
        <p
          style={{
            fontFamily: "Kanit",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "white",
          }}
        >
          {t("label_could_not_establish_route")}
        </p>

        <p
          style={{
            fontFamily: "Kanit",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "white",
          }}
        >
          {t("label_cant_skip_this_step_click_kit")}
        </p>
      </div>

      <div
        style={{
          alignSelf: "end",
        }}
      >
        <p
          style={{
            fontFamily: "Kanit",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "white",
          }}
        >
          {t("label_error_detail")}
        </p>

        <p
          style={{
            fontFamily: "Kanit",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "white",
          }}
        >
          {error?.message ?? "Unknown error"}
        </p>
      </div>
    </div>
  );
}

import * as React from 'react';
import Typography from 'common/components/typography/Typography';
import DisableWorkOrderCreationTooltip from './DisableWorkOrderCreationTooltip';
import UncancellableSwapOrderTooltip from './UncancellableSwapOrderTooltip';

import { IMachineInventoryData, IMachineInventoryTableData } from 'machines/interfaces';

type ChangeIngredientOptionProps = {
  data: IMachineInventoryTableData
  isEnableWorkOrderCreation: boolean
  handleIngredientEdit: (data: IMachineInventoryData) => void
  handleSwapInventoryCancel: (machineInventoryId: string) => void
}

function ChangeIngredientOption({
  data,
  isEnableWorkOrderCreation,
  handleIngredientEdit,
  handleSwapInventoryCancel
}: ChangeIngredientOptionProps): JSX.Element {
  const swapStatus = data.swapStatus;

  const hasNoSwapOrder = swapStatus === 'idle';
  const isCancellableSwapOrder = swapStatus === 'pending';
  const isUncancellableSwapOrder = swapStatus === 'in_progress';

  const containerStyles: React.CSSProperties = {
    margin: '-14px -21px',
    padding: '14px 21px',
  };

  const disabledStyles: React.CSSProperties = {
    color: '#D7D7D7',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  };

  const cancellableStyles: React.CSSProperties = {
    color: '#E55246'
  };

  const actionTitle = hasNoSwapOrder ? "action_change_ingredient" : "label_cancel_material_change";

  const handleClick = () => {
    if (isEnableWorkOrderCreation && hasNoSwapOrder) {
      return handleIngredientEdit(data);
    }

    if (isCancellableSwapOrder) {
      return handleSwapInventoryCancel(data.id);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        onClick={handleClick}
        style={
          isEnableWorkOrderCreation && hasNoSwapOrder ?
          { ...containerStyles } :
          (isCancellableSwapOrder ? { ...containerStyles, ...cancellableStyles } : { ...containerStyles, ...disabledStyles })          
        }
      >
        <Typography type="body-1" translationKey={actionTitle} />
        <DisableWorkOrderCreationTooltip show={!isEnableWorkOrderCreation && hasNoSwapOrder} />
        <UncancellableSwapOrderTooltip show={isUncancellableSwapOrder} />
      </div>
    </div>
  );
}

export default ChangeIngredientOption;

import * as React from "react";

import { useGetServiceZonesByOrgIdQuery } from "gql/generated";
import { useServiceZoneQueryParam } from "./hooks/useServiceZoneQueryParam";
import { Combobox } from "@headlessui/react";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import Checkbox from "components/Checkbox";

export function ServiceZoneDropDown() {
  const { t } = useTranslation("errorBoard");

  const { isLoading, data } = useGetServiceZonesByOrgIdQuery(
    {
      organisationId: useGetCurrentUser().data.orgId,
    },
    {
      useErrorBoundary: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  // Headlessui doesn't expose controlled way to close / open dropdown unless manually rendered
  // Comboxbox.Button and manually dispatching click events
  // https://github.com/tailwindlabs/headlessui/discussions/1205
  const headlessuiButtonRef = React.useRef<HTMLButtonElement>(null);

  const [paramZoneIds, setParamZoneIds] = useServiceZoneQueryParam();
  const [dropDownZoneIds, setDropDownZoneIds] = React.useState<string[]>(
    paramZoneIds ?? []
  );
  const [query, setQuery] = React.useState("");

  React.useEffect(() => {
    setDropDownZoneIds(paramZoneIds ?? []);
  }, [paramZoneIds]);

  function handleClose() {
    setDropDownZoneIds(paramZoneIds ?? []);
    setQuery("");
  }
  function handleDeselect() {
    setParamZoneIds(null);
    headlessuiButtonRef.current?.click();
  }
  function handleApply() {
    setParamZoneIds(dropDownZoneIds);
    headlessuiButtonRef.current?.click();
  }
  function handleOpen() {
    if (isLoading) return;
    headlessuiButtonRef.current?.click();
  }

  const selectedServiceZones = paramZoneIds?.map((id) => {
    const serviceZone = data?.serviceZones.find((zone) => zone.id === id);
    return {
      label: serviceZone?.friendlyId ?? id,
      value: id,
    };
  });

  const sortedZones =
    data?.serviceZones.sort((a, b) =>
      a.friendlyId.localeCompare(b.friendlyId)
    ) ?? [];

  const paramZonesNotInResponse =
    paramZoneIds
      ?.filter((id) => !sortedZones.find((zone) => zone.id === id))
      ?.map((id) => ({ id, friendlyId: id })) ?? [];

  const combinedZones = [...sortedZones, ...paramZonesNotInResponse];

  const filteredZones =
    query === ""
      ? combinedZones
      : combinedZones.filter((zone) => {
          return zone.friendlyId.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <Combobox
        multiple={true}
        value={dropDownZoneIds}
        onChange={(ids) => setDropDownZoneIds(ids)}
      >
        {({ open }) => (
          <>
            <div className="w-40 relative min-w-32">
              <div className="absolute h-full w-full flex items-center pr-4 box-border justify-end pointer-events-none">
                <ArrowDropDown
                  className={
                    open
                      ? "rotate-180 transition-all"
                      : "rotate-0 transition-all"
                  }
                />
              </div>
              <div className="absolute pl-4 box-border h-full w-full pointer-events-none">
                <span
                  className={clsx(
                    "font-kanit absolute font-medium text-xs tracking-[0.4px] -translate-y-1/2 flex",
                    open ? "text-primary-500" : "text-on-surface-medium"
                  )}
                >
                  <span className="h-[calc(2px+50%)] w-[calc(5px+100%)] bottom-0 absolute bg-white -translate-x-[2.5px]"></span>
                  <span className="flex relative">{t("serviceZone")}</span>
                </span>
              </div>

              {!open ? (
                <button
                  onClick={handleOpen}
                  className="font-kanit text-black w-full h-full text-left text-base cursor-pointer bg-white ring-1 rounded-[4px] ring-outline hover:ring-black  whitespace-nowrap border-none text-ellipsis overflow-hidden py-3 pl-4 pr-12 box-border"
                >
                  {isLoading ? (
                    <span>{t("loading")}</span>
                  ) : (
                    <span>
                      {paramZoneIds === null
                        ? t("all")
                        : selectedServiceZones
                            .map((zone) => zone.label)
                            .join(",")}
                    </span>
                  )}
                </button>
              ) : (
                <Combobox.Options
                  static
                  className="w-full h-full list-none p-0"
                >
                  <input
                    onChange={(event) => setQuery(event.target.value)}
                    value={query}
                    className="font-kanit w-full h-full text-left text-base bg-white ring-2 rounded-[4px] ring-primary-500 border-none py-3 pl-4 pr-12 box-border"
                    placeholder={t("search")}
                    autoFocus
                  />

                  <div className="absolute top-[calc(100%+2px)] w-full bg-white z-10 rounded-[4px] ring-1 ring-outline shadow-md">
                    <div className=" w-full max-h-56 overflow-auto border-b-[1px] border-b-outline py-4 px-2 box-border">
                      <div className="flex flex-col gap-2">
                        {filteredZones.map((zone) => (
                          <Combobox.Option
                            key={zone.id}
                            value={zone.id}
                            as="div"
                            className="flex gap-3 items-center pr-1 pl-2 cursor-pointer py-[3px] hover:bg-primary-100/25 rounded-[4px]"
                          >
                            {({ selected }) => (
                              <>
                                <Checkbox
                                  checked={selected}
                                  className="pointer-events-none"
                                />
                                <span className="font-kanit text-body2 overflow-hidden whitespace-nowrap text-ellipsis min-w-0 select-none">
                                  {zone.friendlyId}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                        {filteredZones.length === 0 && (
                          <div className="grid place-items-center">
                            <div className="text-center text-on-surface-disabled text-caption text-pretty whitespace-pre-wrap">
                              {t("noServiceZoneFound")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="grid grid-cols-2 py-4 px-3 gap-2">
                      <button
                        onClick={handleDeselect}
                        className="font-kanit uppercase whitespace-nowrap cursor-pointer text-[10px] bg-transparent font-medium flex h-6 items-center justify-center border-[1px] border-outline text-on-surface-medium rounded-[4px] hover:bg-slate-100 transition-colors"
                      >
                        {t("clearAll")}
                      </button>

                      <button
                        onClick={handleApply}
                        className="font-kanit uppercase whitespace-nowrap cursor-pointer flex items-center text-[10px] h-6 font-medium justify-center bg-primary-500 text-white rounded-[4px] hover:bg-primary-400 transition-colors"
                      >
                        {t("apply")}
                      </button>
                    </div>
                  </div>
                </Combobox.Options>
              )}
            </div>

            <OnComboxBoxClose open={open} onClose={handleClose} />
            <Combobox.Button
              ref={headlessuiButtonRef}
              aria-hidden="true"
              className="sr-only"
            />
          </>
        )}
      </Combobox>
    </>
  );
}

function ArrowDropDown({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7 10L12 15L17 10H7Z" fill="black" fillOpacity="0.6" />
    </svg>
  );
}

/**
 * Needed because Headless UI doesn't expose onClose event
 * https://github.com/tailwindlabs/headlessui/discussions/975
 */
function OnComboxBoxClose({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  React.useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open]);
  return null;
}

import * as React from "react";
import Icon from "common/components/icon/Icon";
import { clsx } from "clsx";
import { MachineStatusEnum } from "gql/generated";
import { useTranslation } from "react-i18next";
import { MachineStatusBadge } from "./MachineStatusBadge";
import { CopyInput } from "./CopyInput";

interface IMachineCardProps {
  id: string;
  machineId: string;
  machineName: string;
  icon: string;
  badgeStatus: string;
  href: string;
  className?: string;
  skeleton?: boolean;
  refillZoneName?: string;
  serviceZoneName?: string;
  coordinates: CoordinatesType;
}

const statusMap = {
  [MachineStatusEnum.Online]: {
    label: "status_online",
    color: "bg-success",
  },
  [MachineStatusEnum.Offline]: {
    label: "status_offline",
    color: "bg-error",
  },
  [MachineStatusEnum.OutOfService]: {
    label: "status_out_of_service",
    color: "bg-error",
  },
  [MachineStatusEnum.Maintenance]: {
    label: "status_maintenance",
    color: "bg-warning",
  },
  null: {
    label: "status_none",
    color: "",
  },
};

type CoordinatesType = {
  latitude: number;
  longitude: number;
};

function  generateGoogleMapLink (coordinates: CoordinatesType) {
  return `https://www.google.com/maps/search/?api=1&query=${coordinates.latitude},${coordinates.longitude}`;
}

export function MachineCard({
  id,
  machineId,
  machineName,
  refillZoneName,
  serviceZoneName,
  icon,
  badgeStatus,
  href,
  className,
  coordinates,
}: IMachineCardProps): JSX.Element {
  const { t } = useTranslation();
  const { t: machineTranslation } = useTranslation("machine");
  return (
    <div
      className={clsx(
        "relative px-4 py-6 overflow-hidden bg-white rounded-lg outline-none ring-0 font-kanit",
        className
      )}
    >
      <div className="absolute top-4 right-4 w-5 h-5 flex justify-center items-center cursor-pointer map-popup-close-button hover:bg-outline rounded-md">
        <Icon name="Close" className="w-5 h-5" color="onSurfaceHigh" />
      </div>
      <div className="flex items-center gap-4">
        <div className="w-14 shrink-0 mb-4">
          <Icon name={icon} className="w-14" />
        </div>
        <div className="flex-1 space-y-1">
          <div className="flex justify-start mb-4">
            <MachineStatusBadge label={t(statusMap[badgeStatus]?.label)} status={badgeStatus as MachineStatusEnum} />
          </div>
          <a href={href} target="_blank" className="text-h6 text-primary font-medium hover:underline" rel="noreferrer">{machineId}</a>
          <p className="text-subtitle1 text-on-surface-high">{machineName}</p>
          <p className="text-subtitle1 text-on-surface-high font-light">{machineTranslation("map_page.map.service_zone_label")}: {serviceZoneName}</p>
          <p className="text-subtitle1 text-on-surface-high font-light">{machineTranslation("map_page.map.refill_zone_label")}: {refillZoneName}</p>
          <CopyInput id={id} input={generateGoogleMapLink(coordinates)} />
        </div>
      </div>
    </div>
  );
}

// Hook
import { useQuery } from "react-query";

// Interface
import { AxiosError } from "axios";
import { IProduct } from "product/interfaces";

// Keys
import { productKeys } from "./productKeys";

// Service
import { getProducts } from "product/service";


function useGetProducts({ enabled = true } = {}) {
  return useQuery<IProduct[], AxiosError>(
    productKeys.all(),
    () => getProducts(), 
    { enabled : enabled });
}

export { useGetProducts };
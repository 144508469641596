import * as React from "react";

import {
  GetLocationByIdQuery,
  LocationKind,
  useGetLocationByIdQuery,
} from "gql/generated";

import * as moment from "moment";

import {
  SingleMachineWithContract,
  Store,
  Factory,
  Location,
  Contracts,
  Contract,
  LocationOperation,
  LocationInfo,
} from "../types";
import { ArrayElement } from "types/utils";

export function useGetLocationById(id: string) {
  const useGetLocationByIdQueryResult = useGetLocationByIdQuery({
    locationAggregateId: id,
  });

  const mappedData = React.useMemo((): Location => {
    if (useGetLocationByIdQueryResult.isLoading) return undefined;

    const incLocation = useGetLocationByIdQueryResult.data.locationAggregate;
    if (incLocation === null)
      throw new Error(`Location is null: location ${id} does not exist`);

    const incLocationKind = incLocation.kind as LocationKind;

    if (incLocationKind === LocationKind.Location) {
      return mapIncomingLocationToLocation(incLocation);
    } else if (incLocationKind === LocationKind.Factory) {
      return mapIncomingLocationToFactory(incLocation);
    } else if (incLocationKind === LocationKind.Store) {
      return mapIncomingLocationToStore(incLocation);
    }
  }, [useGetLocationByIdQueryResult.data]);
  return { ...useGetLocationByIdQueryResult, data: mappedData };
}

type IncomingLocation = GetLocationByIdQuery["locationAggregate"];

function mapIncomingLocationToStore(location: IncomingLocation): Store {
  return {
    kind: LocationKind.Store,
    locationInfo: mapLocationTolocationInfo(location),
    machineIds: location.machineIds,
    operation: mapIncomingLocationToLocationOperation(location),
  };
}

function mapIncomingLocationToLocation(
  location: IncomingLocation
): SingleMachineWithContract {
  return {
    kind: LocationKind.Location,
    contracts: mapIncomingLocationToContracts(location),
    locationInfo: mapLocationTolocationInfo(location),
    machineId: location.machineIds[0],
    operation: mapIncomingLocationToLocationOperation(location),
    additionalContractInformation: {
      sales: location.sales?.name ?? "",
      projectType: {
        id: location.project ? String(location.project.projectNumber) : "",
        name: location.project ? location.project.projectName : "",
      },
    },
    rentFee: location.rentFee,
    electricFee: location.electricFee,
  };
}

function mapIncomingLocationToContracts(location: IncomingLocation): Contracts {
  function convertToContract(
    contract: ArrayElement<IncomingLocation["contracts"]>
  ): Contract {
    return {
      id: contract.contract.contractId,
      friendlyId: contract.contract.friendlyId,
    };
  }

  const incomingContracts = location.contracts.filter((contract) => {
    if (!contract.contract) {
      console.warn("Encountered a null contract");
      return false;
    }
    return true;
  });

  return {
    active: incomingContracts
      .filter((contract) => {
        const contractStart = moment(contract.period.start);
        const contractEnd = moment(contract.period.end);
        const isActive = moment().isBetween(contractStart, contractEnd);
        return isActive;
      })
      .map(convertToContract),
    inactive: incomingContracts
      .filter((contract) => {
        const contractStart = moment(contract.period.start);
        const isFuture = moment().isBefore(contractStart);
        return isFuture;
      })
      .map(convertToContract),
    pastContract: incomingContracts
      .filter((contract) => {
        const contractEnd = moment(contract.period.end);
        const isPast = moment().isAfter(contractEnd);
        return isPast;
      })
      .map(convertToContract),
  };
}

function mapIncomingLocationToLocationOperation(
  location: IncomingLocation
): LocationOperation {
  return {
    refillOperationNote: location.refillOperationNote,
    refillZone: location.refillZone,
    serviceZone: location.serviceZone,
    warehouse: {
      id: location.warehouse.warehouseId,
      name: location.warehouse.name,
    },
  };
}

function mapIncomingLocationToFactory(location: IncomingLocation): Factory {
  return {
    kind: LocationKind.Factory,
    locationInfo: mapLocationTolocationInfo(location),
    machineIds: location.machineIds,
  };
}

function mapLocationTolocationInfo(location: IncomingLocation): LocationInfo {
  return {
    address: {
      province: convertToProvinceEnumString(location.address.province),
      street: location.address.street,
    },
    coordinates: {
      latitude: location.coordinates.latitude,
      longitude: location.coordinates.longitude,
    },
    friendlyId: location.friendlyId,
    googleMapLink: location.googleMapLink ?? "",
    id: location.id,
    name: location.name ?? "",
    organization: {
      id: location.organization.id,
      name: location.organization.name,
    },
    type: location.type,
  };
}

function convertToProvinceEnumString(str: string) {
  return str.toUpperCase().split(" ").join("_");
}

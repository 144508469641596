import * as React from "react";

interface IPriorityMediumProps {
  className?: string;
}

function PriorityMedium({ className }: IPriorityMediumProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 4H13C13.6 4 14 4.4 14 5C14 5.6 13.6 6 13 6H3C2.4 6 2 5.6 2 5C2 4.4 2.4 4 3 4ZM3 10H13C13.6 10 14 10.4 14 11C14 11.6 13.6 12 13 12H3C2.4 12 2 11.6 2 11C2 10.4 2.4 10 3 10Z"
        fill="#FFB025"
      />
    </svg>
  );
}

export default PriorityMedium;

import * as React from 'react';

interface IMachineCashProps {
  color?: string;
  className?: string;
}

function MachineCash({
  color = 'white',
  className,
}: IMachineCashProps): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M15.75 0.75C11.751 0.75 7.5 2.064 7.5 4.5V7.5C7.5 7.914 7.836 8.25 8.25 8.25C8.664 8.25 9 7.914 9 7.5V6.7185C10.5915 7.722 13.2195 8.25 15.75 8.25C18.2805 8.25 20.9085 7.722 22.5 6.7185V7.5C22.5 8.2335 20.655 9.4035 17.187 9.693C16.776 9.726 16.4685 10.0905 16.503 10.503C16.5345 10.8945 16.863 11.19 17.2485 11.19C17.271 11.19 17.2905 11.1885 17.313 11.187C19.266 11.0235 21.2115 10.518 22.5 9.6975V10.5C22.5 11.1405 21.141 12.0615 18.6195 12.507C18.213 12.579 17.94 12.969 18.012 13.3755C18.0765 13.7385 18.393 13.995 18.7485 13.995C18.792 13.995 18.837 13.992 18.8805 13.983C20.406 13.7145 21.627 13.275 22.5 12.717V13.5C22.5 14.1405 21.141 15.0615 18.6195 15.507C18.213 15.579 17.94 15.969 18.012 16.3755C18.0765 16.7385 18.393 16.995 18.7485 16.995C18.792 16.995 18.837 16.992 18.8805 16.983C20.406 16.7145 21.627 16.2735 22.5 15.717V16.5C22.5 17.1405 21.141 18.0615 18.6195 18.507C18.213 18.579 17.94 18.969 18.012 19.3755C18.0765 19.7385 18.393 19.995 18.7485 19.995C18.792 19.995 18.837 19.992 18.8805 19.983C20.406 19.7145 21.627 19.2735 22.5 18.717V19.5C22.5 20.2335 20.655 21.4035 17.187 21.693C16.776 21.726 16.4685 22.0905 16.503 22.503C16.5345 22.8945 16.863 23.19 17.2485 23.19C17.271 23.19 17.2905 23.1885 17.313 23.187C20.6415 22.908 24 21.6615 24 19.5V4.5C24 2.064 19.749 0.75 15.75 0.75ZM15.75 6.75C11.6295 6.75 9 5.4165 9 4.5C9 3.5835 11.6295 2.25 15.75 2.25C19.8705 2.25 22.5 3.5835 22.5 4.5C22.5 5.4165 19.8705 6.75 15.75 6.75Z'
        fill={color}
      />
      <path
        d='M8.25 9.75C4.251 9.75 0 11.064 0 13.5V19.5C0 21.936 4.251 23.25 8.25 23.25C12.249 23.25 16.5 21.936 16.5 19.5V13.5C16.5 11.064 12.249 9.75 8.25 9.75ZM15 19.5C15 20.4165 12.3705 21.75 8.25 21.75C4.1295 21.75 1.5 20.4165 1.5 19.5V18.7185C3.0915 19.722 5.7195 20.25 8.25 20.25C10.7805 20.25 13.4085 19.722 15 18.7185V19.5ZM15 16.5C15 17.4165 12.3705 18.75 8.25 18.75C4.1295 18.75 1.5 17.4165 1.5 16.5V15.7185C3.0915 16.722 5.7195 17.25 8.25 17.25C10.7805 17.25 13.4085 16.722 15 15.7185V16.5ZM8.25 15.75C4.1295 15.75 1.5 14.4165 1.5 13.5C1.5 12.5835 4.1295 11.25 8.25 11.25C12.3705 11.25 15 12.5835 15 13.5C15 14.4165 12.3705 15.75 8.25 15.75Z'
        fill={color}
      />
    </svg>
  );
}

export default MachineCash;

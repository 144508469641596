// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"font-main-light\";src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"font-main-regular\";src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"font-main-medium\";src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"font-alt-light\";src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"font-alt-regular\";src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"font-alt-medium\";src:url(\"/fonts/Roboto-Medium.ttf\")}@font-face{font-family:\"Kanit\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"Kanit\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"Kanit\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"Roboto\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"Roboto\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"Roboto\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Medium.ttf\")}.SparkLineCell--2_aKb8PEDl{display:flex;align-items:center}.SparkLineContainer--3bwoOzXhof{margin-right:8px;flex:0 0 87px;min-width:0px;height:45px;display:flex;align-items:center}.SparkLineContainer--3bwoOzXhof .SparkLine--2hUGq8tnLg{height:17px}.ValueContainer--2_v9EgDjGi{flex:0 0 60px;margin-right:3px}.ValueContainer--2_v9EgDjGi .Prefix--1x3nQ0ZvVK{display:inline-block;width:7px}.PercentageIndicator--17MVmzCHol{margin-top:3px}", ""]);
// Exports
exports.locals = {
	"SparkLineCell": "SparkLineCell--2_aKb8PEDl",
	"SparkLineContainer": "SparkLineContainer--3bwoOzXhof",
	"SparkLine": "SparkLine--2hUGq8tnLg",
	"ValueContainer": "ValueContainer--2_v9EgDjGi",
	"Prefix": "Prefix--1x3nQ0ZvVK",
	"PercentageIndicator": "PercentageIndicator--17MVmzCHol"
};
module.exports = exports;

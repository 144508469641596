import * as React from 'react';
import { useState } from 'react';

import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Select from 'common/components/select/Select';
import Input from 'common/components/input/Input';
import Checkbox from 'common/components/checkbox/Checkbox';


import { IMachineInventoryData } from 'machines/interfaces';

import * as styles from './NewSlotModal.module.scss';
import { usePostMachineInventory } from 'machines/hooks/usePostMachineInventory';
import { useGetProducts } from 'product/hooks/useGetProducts';

interface INewSlotModalProps {
  isOpen: boolean,
  occupiedSlots: Set<string>,
  machineId: string,
  dataLoading: boolean,
  onClose: () => void
}

function NewSlotModal ({
  isOpen,
  machineId,
  occupiedSlots,
  dataLoading,
  onClose
}: INewSlotModalProps): JSX.Element {
  const { isLoading: productsLoading, data: products = [] } = useGetProducts();
  const { isLoading: createSlotLoading, mutate: createSlot } = usePostMachineInventory();

  const [ autoRefill, setAutoRefill ] = useState<boolean>(true);
  const [ slot, setSlot ] = useState<string>(null);
  const [ product, setProduct ] = useState<string>(null);
  const [ parLevel, setParLevel ] = useState<number>(null);
  const [ refillLevel, setRefillLevel ] = useState<number>(null);
  const [ capacity, setCapacity ] = useState<number>(null);
  const [ recommendRefillAbovePar, setRecommendRefillAbovePar ] = useState<boolean>(true);
  const [ noPartialRefill, setNoPartialRefill ] = useState<boolean>(false);
  const [ priority, setPriority ] = useState<string>('1');

  function handleClose (): void {
    onClose();
    setTimeout(() => {
      setAutoRefill(false);
      setSlot(null);
      setProduct(null);
      setParLevel(null);
      setRefillLevel(null);
      setCapacity(null);
      setRecommendRefillAbovePar(true);
      setNoPartialRefill(false);
      setPriority('1');
    }, 100);
  }

  async function handleSubmit (): Promise<void> {
    if (occupiedSlots.has(slot)) {
      return;
    }

    const productToAdd = products.find(prod => prod.productId === product);
    const newInventory: Partial<IMachineInventoryData> = {
      inventoryCode: productToAdd.inventoryCode,
      productId: productToAdd.productId,
      value: 0,
      parLevel,
      refillLevel,
      slot,
      capacity,
      recommendRefillAbovePar,
      noPartialRefill,
      autoRefill,
      additional: {
        inventoryInfo: {
          importanceLevel: priority
        }
      }
    };
    createSlot({ machineId, machineInventoryData: [newInventory] }, {
      onSuccess: () => {
        handleClose();
      }
    });
  }

  function isDisabled (): boolean {
    return productsLoading ||
      occupiedSlots.has(slot) ||
      !product ||
      dataLoading ||
      createSlotLoading ||
      !parLevel ||
      !refillLevel ||
      +refillLevel <= +parLevel ||
      (capacity && capacity < refillLevel);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className={styles.NewSlotModal}>
        <Typography
          type='headline-6'
          translationKey='action_create_new_slot'
          className={styles.title}
        />

        <Select
          value={slot}
          options={[
            "B1",
            "B2",
            "B3",
            "C1",
            "C2",
            "C3",
            "L1",
            "P1",
            "P10",
            "P11",
            "P2",
            "P3",
            "P4",
            "P5",
            "P6",
            "P7",
            "P8",
            "P9",
            "S1",
            "S2",
            "S3",
            "S4",
            "S5",
            "S6",
            "S7",
            "S8",
            "S9",
            "SM1",
            "SM2",
            "SODA",
            "T1",
            "W",
          ]
            .filter((slot) => !occupiedSlots.has(slot))
            .map((slot) => ({
              label: slot,
              value: slot,
            }))}
          label="label_slot"
          className={styles.input}
          onSelect={(value) => setSlot(value.toString())}
          searchable
        />
        <Select
          value={product}
          options={products.map(element => ({ label: element.name, value: element.productId }))}
          label='label_product'
          className={styles.input}
          onSelect={(value) => setProduct(value as string)}
          searchable
        />
        <Input
          value={parLevel}
          label='label_par_level'
          className={styles.input}
          onChange={(value) => setParLevel(value)}
        />
        <Input
          value={refillLevel}
          label='label_refill_target_level'
          className={styles.input}
          onChange={(value) => setRefillLevel(value)}
        />
        <Select
          value={priority}
          options={[ '1', '2', '3', '4', '5' ].map(element => ({ label: element, value: element }))}
          label='label_priority'
          className={styles.input}
          onSelect={value => setPriority(value as string)}
        />
        <Input
          value={capacity}
          label='label_capacity'
          type='number'
          className={styles.input}
          onChange={(value) => setCapacity(value)}
        />
        <div className={styles.checkbox}>
          <Checkbox
            checked={recommendRefillAbovePar}
            onClick={() => setRecommendRefillAbovePar(i => !i)}
          />
          <Typography
            translationKey='label_recommend_refill_above_par'
            type='body-2'
            className={styles.label}
          />
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            checked={noPartialRefill}
            onClick={() => setNoPartialRefill(i => !i)}
          />
          <Typography
            translationKey='label_no_partial_refill'
            type='body-2'
            className={styles.label}
          />
        </div>
        <div className={styles.actions}>
          <Button
            translationKey='action_cancel'
            onClick={handleClose}
            type='secondary'
          />
          <Button
            translationKey='action_create'
            onClick={handleSubmit}
            disabled={isDisabled()}
            type='primary'
          />
        </div>
      </div>
    </Modal>
  );
}

export default NewSlotModal;

import * as React from "react";

function withSuspense<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  fallback: React.SuspenseProps["fallback"] = null
) {
  function ComponentWithSuspense(props: P): JSX.Element {
    return (
      <React.Suspense fallback={fallback}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  }
  return ComponentWithSuspense;
}

export default withSuspense;

import * as React from "react";

import Autocomplete, {
  AutocompleteOption,
} from "components/Autocomplete/Autocomplete";

import { GetProjectsQuery, useGetProjectsQuery } from "gql/generated";
import { queryClient } from "index";

type ProjectIdFormprops = {
  onChange: (value: string) => void;
  value: string;
  fallBackOptions?: Array<AutocompleteOption>;
};

const useGetProjectsQueryQueryKey = ['GetProjects'];
function getProjectNameById(id: number){
  const cachedProject = queryClient.getQueryData<GetProjectsQuery>(useGetProjectsQueryQueryKey);
  if(!cachedProject) return '';
  const project = cachedProject.projectTypes.find(project => project.projectTypeId === id);
  if(!project) return '';
  return project.name;
}
export const projectIdCache = {
  getProjectNameById
};


export function ProjectIdAutoComplete({
  value,
  onChange,
  fallBackOptions = [],
}: ProjectIdFormprops) {
  const getOrganisationsQueryResult = useGetProjectsQuery();

  const projects = getOrganisationsQueryResult.data?.projectTypes;
  const optionsFromQuery =
    projects?.map((project) => ({
      label: `${project.projectTypeId} - ${project.name}`,
      value: String(project.projectTypeId),
    })) ?? [];
  const options = projects ? optionsFromQuery : fallBackOptions;

  return (
    <Autocomplete
      loading={getOrganisationsQueryResult.isLoading}
      label="label_project"
      id="projectId"
      disableClearable
      value={value}
      onChange={onChange}
      options={options}
    />
  );
}

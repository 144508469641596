import * as React from "react";
import { KittingModalState } from "./useKittingModalState";
import Typography from "components/Typography";
import * as moment from "moment";

import {
  PriorityCell,
  isValidRefillPriority,
} from "../../../Components";

import * as styles from "./KittingModal.module.scss";

export function ReviewTable({
  kittingModalState,
}: {
  kittingModalState: KittingModalState;
}) {
  const machines = Object.values(kittingModalState.machines);

  return (
    <div className={styles.ReviewTableScrollContainer}>
      <div className={styles.ReviewTableContainer}>
        <div className={styles.ReviewTableHeader}>
          <div className={styles.OrderColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              menu_order
            </Typography>
          </div>
          <div className={styles.MachineIdColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_machine_id
            </Typography>
          </div>
          <div className={styles.PriorityColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_priority
            </Typography>
          </div>
          <div className={styles.LocationNameColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_location_name
            </Typography>
          </div>
          <div className={styles.TypeColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_type
            </Typography>
          </div>
          <div className={styles.ErrorColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              status_error
            </Typography>
          </div>
          <div className={styles.RevenueColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_revenue
            </Typography>
          </div>
          <div className={styles.TotalColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_total
            </Typography>
          </div>
          <div className={styles.NoteColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_note
            </Typography>
          </div>
          <div className={styles.TimeToColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_time_to
            </Typography>
          </div>
        </div>
        {[...machines]
          .sort(
            (machineA, machineB) =>
              machineA.positionInPlan - machineB.positionInPlan
          )
          .map((machine) => (
            <div key={machine.machineId} className={styles.Row}>
              <div className={styles.OrderColumn}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {machine.positionInPlan}
                </Typography>
              </div>
              <div className={styles.MachineIdColumn}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {machine.machineId}
                </Typography>
              </div>
              <div className={styles.PriorityColumn}>
                <PriorityCell
                  priority={
                    isValidRefillPriority(machine.refillPriority)
                      ? machine.refillPriority
                      : null
                  }
                />
              </div>
              <div className={styles.LocationNameColumn}>
                <div className={styles.TwoLinesWrapper}>
                  <Typography type="body-2" translate color="onSurfaceHigh">
                    {machine.location.name}
                  </Typography>
                </div>
              </div>
              <div className={styles.TypeColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  label_refill
                  {(() => {
                    const numberOfSwapItems =
                      machine.refillOrder.swapOrders.reduce((curr, next) => {
                        return curr + next.swapItems.length;
                      }, 0);
                    return numberOfSwapItems > 0
                      ? [" & ", "label_swap", ` (${numberOfSwapItems})`]
                      : null;
                  })()}
                </Typography>
              </div>
              <div className={styles.ErrorColumn}>
                {machine.error.mostRecentErrorCode ? (
                  <Typography type="body-2" translate color="error">
                    {machine.error.mostRecentErrorCode}
                  </Typography>
                ) : (
                  <Typography type="body-2" translate color="onSurfaceHigh">
                    -
                  </Typography>
                )}
              </div>
              <div className={styles.RevenueColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  {machine.location.revenue}
                </Typography>
              </div>
              <div className={styles.TotalColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  {
                    kittingModalState.aggregates.totalNumberOfItemsPermachine[
                      machine.machineId
                    ]
                  }
                </Typography>
              </div>
              <div className={styles.NoteColumn}>
                <div className={styles.TwoLinesWrapper}>
                  <Typography type="body-2" color="onSurfaceDisabled">
                    {machine.refillOrder.criticalNote ? (
                      <Typography type="body-2" color="error">
                        {machine.refillOrder.criticalNote}{" "}
                      </Typography>
                    ) : null}
                    {machine.refillOrder.locationNote ? (
                      <Typography type="body-2" color="onSurfacehigh">
                        {(machine.refillOrder.criticalNote ? ", " : "") +
                          (machine.refillOrder.locationNote ?? "")}
                      </Typography>
                    ) : null}
                  </Typography>
                </div>
              </div>
              <div className={styles.TimeToColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  label_before
                  {` ${moment(Number(machine.refillOrder.refillTime)).format(
                    "HH:mm"
                  )}`}
                </Typography>
              </div>
            </div>
          ))}
        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
}

import * as moment from "moment";
import { formatMomentDateFullYear } from "common/utils/format";

import { ItextAndIndicatorCellRenderProp } from "common/components/table/cells/textAndIndicatorCell";
import { UseGetMachineMaintenanceTransformedData } from "machines/hooks/useGetMachineMaintenance";
export interface ITableRow {
  part: string;
  maintenancePeriod: Array<string> | string;
  lastMaintainDate: string;
  nextMaintainDate: ItextAndIndicatorCellRenderProp;
  type: 'component' | 'slot'
}

export function mapDataToTable(
  machineMaintenance: UseGetMachineMaintenanceTransformedData | undefined
): Array<ITableRow> {
  if (machineMaintenance === undefined) return undefined;

  return machineMaintenance.map(part => ({
    type: part.type,
    part: part.name,
    maintenancePeriod:
      part.maintenancePeriodInDays === null
        ? "-"
        : computeMaintenancePeriod(part.maintenancePeriodInDays),
    lastMaintainDate:
      part.lastMaintainDateTimeStamp === null
        ? "-"
        : formatMomentDateFullYear(moment(Number(part.lastMaintainDateTimeStamp))),
    nextMaintainDate:
      part.nextMaintainDateTimeStamp === null
        ? { translationKey: "-", indicatorColor: undefined }
        : computeNextMaintainDate(part.nextMaintainDateTimeStamp)
  }));
}

function computeMaintenancePeriod(maintenancePeriodInDays: number) {
  if (maintenancePeriodInDays < 29) {
    return [String(maintenancePeriodInDays), " ", "label_day"];
  }

  if (maintenancePeriodInDays >= 30 && maintenancePeriodInDays < 365) {
    return [
      String(Math.floor(maintenancePeriodInDays / 30)),
      " ",
      "label_month"
    ];
  }

  if (maintenancePeriodInDays >= 365) {
    return [
      String(Math.floor(maintenancePeriodInDays / 365)),
      " ",
      "label_year"
    ];
  }
}

function computeNextMaintainDate(
  nextMaintainDate: string
): ItextAndIndicatorCellRenderProp {
  const currentTime = moment();
  const nextMaintainDateMomentObject = moment(Number(nextMaintainDate));

  if (currentTime.isAfter(nextMaintainDateMomentObject, "day")) {
    return {
      indicatorColor: "error",
      textColor: "error",
      translationKey: [
        "label_overdue",
        ` ${formatMomentDateFullYear(nextMaintainDateMomentObject)}`
      ]
    };
  } else {
    return {
      indicatorColor: undefined,
      textColor: undefined,
      translationKey: formatMomentDateFullYear(nextMaintainDateMomentObject)
    };
  }
}

import * as React from 'react';

interface IPaginationChevron {
  color?: string;
  className?: string;
}

function PaginationChevron ({
  color = 'white',
  className
}: IPaginationChevron): JSX.Element {
  return (
    <svg
      className={className}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.04858 8L5 7.06L1.90958 4L5 0.94L4.04858 8.31755e-08L1.27146e-07 4L4.04858 8Z"
        fill={color}
      />
    </svg>
  );
}

export default PaginationChevron;

import * as React from "react";

interface IStarProps {
  className?: string;
  style: React.CSSProperties;
  color?: string;
}

function Star({ className, color }: IStarProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 1.30198L12.1834 6.55144L12.3007 6.83342L12.6051 6.85782L18.2723 7.31216L13.9545 11.0108L13.7225 11.2095L13.7934 11.5066L15.1126 17.0368L10.2606 14.0733L10 13.9141L9.73937 14.0733L4.88743 17.0368L6.2066 11.5066L6.27746 11.2095L6.04552 11.0108L1.72769 7.31216L7.39492 6.85782L7.69934 6.83342L7.81663 6.55144L10 1.30198Z"
        stroke={color}
      />
    </svg>
  );
}

export default Star;

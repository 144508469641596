import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import Button from "components/Button/Button";
import { ResponsiveModal, Title } from "components/ResponsiveModal";
import { machineKeys } from "machines/hooks/machineKeys";
import { toasti18n } from "utils/toast";
import { cancelSwapInventory } from "machines/service";
import { delay } from "utils/async";

type CancelSwapOrderModalProps = {
  machineId: string
  machineInventoryId: string
  onClose: () => void
}

export function CancelSwapOrderModal({ machineId, machineInventoryId, onClose }: CancelSwapOrderModalProps) {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: cancelSwapInventory,
    onSuccess: async () => {
      await delay(500);
      queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      toasti18n.success();
      onClose();
    },
  });

  return (
    <ResponsiveModal
      onClose={isLoading ? undefined : onClose}
      open={true}
      modalStyles={{
        heightStyles: {
          flex: "0 1 auto",
        },
        maxWidth: "416px",
        alignment: "center",
      }}
    >
      <Title>label_are_you_sure_you_want_to_cancel_this_swap_order</Title>
      <div style={{ height: "30px" }}></div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="secondary" onClick={onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="status"
          onClick={() => mutate(machineInventoryId)}
          loading={isLoading}
        >
          label_delete_swap_order
        </Button>
      </div>
    </ResponsiveModal>
  );
}

import { ElectricFeeView } from "containers/contract/components/location/ElectricFee";
import { RentFeeView } from "containers/contract/components/location/RentFee";
import * as React from "react";
import { mapDataToRentFeeType } from "../EditLocationId/utils/mapDataToRentFeeType";
import { mapDataToElectricFeeType } from "../EditLocationId/utils/mapDataToElectricFeeType";
import { useGetLocationContractAssociationFeeQuery } from "gql/generated";

type ContractLocationFeeProps = {
  locationId: string;
  contractId: string;
}

export function ContractLocationFee ({ contractId, locationId }: ContractLocationFeeProps) {
  const { data, isLoading } = useGetLocationContractAssociationFeeQuery({
    contractId,
    locationId,
  });

  if(isLoading) return null;

  return <>
    <RentFeeView rentFee={mapDataToRentFeeType(data.contractLocationAssociation.rentFee)} />
    <ElectricFeeView electricFee={mapDataToElectricFeeType(data.contractLocationAssociation.electricFee)} />
  </>;
}

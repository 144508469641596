import * as React from "react";

import * as colors from "common/styles/colors.scss";
import * as moment from "moment";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import * as styles from "./SyncLotButton.module.scss";
import Button from "components/Button/Button";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";
import { AxiosError } from "axios";
import { queryClient } from "index";
import { httpClient } from "common/services/transportService";
import { invalidateAllProductLots } from "../WarehouseIdWithProductId/use-get-product-lots";
import { invalidateAllWarehouseProducts } from "./use-get-products";
import { toasti18n } from "utils/toast";

const syncLotIndicatorQueryKey = [
  { scope: "syncLot", url: "/bcSync/sync-lot/status" },
];

export type FetchSyncLotStatusResponse = {
  status: "initial" | "in-progress" | "failed" | "success";
  startDate?: string;
  endDate?: string;
};
export async function fetchSyncLotStatus(): Promise<FetchSyncLotStatusResponse> {
  const response = await httpClient.get<FetchSyncLotStatusResponse>("/bcSync/sync-lot/status");
  return response.data;
}

/**
 * Cache getters and setters
 */
type SyncLotStatusCache = {
  status: "SUCCESS" | "IN_PROGRESS" | "FAILED" | "NO_DATA";
  date: string | undefined;
  _isMutationInProgress?: boolean;
};
function getCachedStatus() {
  return queryClient.getQueryData<SyncLotStatusCache>(syncLotIndicatorQueryKey);
}
function setCachedStatus(
  newDataFn: (newData: SyncLotStatusCache) => SyncLotStatusCache
) {
  queryClient.setQueryData<SyncLotStatusCache>(
    syncLotIndicatorQueryKey,
    newDataFn
  );
}

/**
 * Queries
 */
// Base query
const responseStatusToSyncLotStatus = {
  initial: "NO_DATA",
  "in-progress": "IN_PROGRESS",
  failed: "FAILED",
  success: "SUCCESS",
} as const;

function useGetSyncLotBaseQuery(
  options: Omit<
    UseQueryOptions<SyncLotStatusCache, AxiosError, SyncLotStatusCache>,
    "queryFn" | "queryKey"
  > = {}
) {
  return useQuery({
    queryFn: async () => {
      const response = await fetchSyncLotStatus();
      const cachedData = getCachedStatus();
      const isMutationInProgress = cachedData?._isMutationInProgress;
      if (isMutationInProgress) {
        return cachedData;
      }
      const responseSyncLotStatus =
        responseStatusToSyncLotStatus[response.status];

      if (
        cachedData?.status === "IN_PROGRESS" &&
        responseSyncLotStatus !== "IN_PROGRESS"
      ) {
        invalidateAllWarehouseProducts();
        invalidateAllProductLots();
      }

      return {
        _isMutationInProgress: false,
        date: response.endDate,
        status: responseSyncLotStatus,
      };
    },
    queryKey: syncLotIndicatorQueryKey,
    useErrorBoundary: false,
    staleTime: 30 * 1000,
    ...options,
  });
}

// Query for loading states
export function useQuerySyncLot() {
  const canUserViewSyncLotStatus = useCanUserViewSyncLotStatus();
  if (!canUserViewSyncLotStatus) {
    return { isLoading: false };
  }
  // Subscribe to query / data and apply base config
  return useGetSyncLotBaseQuery();
}

// Query for polling
export function usePollSyncLot() {
  const cachedQuery = useGetSyncLotBaseQuery({ enabled: false });
  const [shouldPoll, setShouldPoll] = React.useState(
    !cachedQuery.data || cachedQuery.isError
      ? false
      : cachedQuery.data?.status === "IN_PROGRESS"
  );
  // Adds extra config to the query based on should poll status
  useGetSyncLotBaseQuery({
    refetchInterval: 10000,
    enabled: shouldPoll,
    refetchOnMount: false,
    onSuccess: (data) => {
      if (data.status === "IN_PROGRESS") {
        setShouldPoll(true);
      } else {
        setShouldPoll(false);
      }
    },
    onError: () => {
      setShouldPoll(false);
    },
  });

  return cachedQuery;
}

/**
 * Mutation
 */
export async function syncLot() {
  await httpClient.post("/bcSync/sync-lot");
}

export function useSyncLot({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, unknown, unknown>,
  "mutationFn"
> = {}) {
  return useMutation({
    mutationFn: syncLot,
    onMutate: () => {
      setCachedStatus((data) => ({
        ...data,
        status: "IN_PROGRESS",
        _isMutationInProgress: true,
      }));
    },
    onSettled: () => {
      setCachedStatus((data) => ({
        ...data,
        _isMutationInProgress: false,
      }));
    },
    onError: () => {
      queryClient.invalidateQueries(syncLotIndicatorQueryKey);
    },
    onSuccess: (...args) => {
      toasti18n.success("label_initial_syncing_lot");
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

/**
 * Permissions
 */
function useCanUserViewSyncLotStatus() {
  const { data: user } = useGetCurrentUser();
  const canSeeSyncLotStatus = Boolean(user.syncLotFlag);
  return canSeeSyncLotStatus;
}
function useCanUserSyncLot() {
  const { data: user } = useGetCurrentUser();
  const canSyncLot = Boolean(user.syncLotFlag);
  return canSyncLot;
}

/**
 * Button
 */
export function SyncLotButton({ style }: { style: React.CSSProperties }) {
  const canUserViewSyncLotStatus = useCanUserViewSyncLotStatus();
  if (!canUserViewSyncLotStatus) return null;

  const pollQuery = usePollSyncLot();
  const syncLotStatus: SyncLotStatus = pollQuery.isError
    ? {
        status: "ERROR",
        date: undefined,
      }
    : {
        status: pollQuery.data.status,
        date: pollQuery.data.date ? moment(pollQuery.data.date) : undefined,
      };

  const canUserSyncLot = useCanUserSyncLot();
  const { mutate: syncLot } = useSyncLot();
  const canLotBeSynced =
    syncLotStatus?.status === "NO_DATA" ||
    syncLotStatus?.status === "FAILED" ||
    syncLotStatus?.status === "SUCCESS";

  return (
    <div
      style={{ display: "flex", gap: "8px", alignItems: "center", ...style }}
    >
      <SyncLotIndicator syncLotStatus={syncLotStatus} />
      {canUserSyncLot && canLotBeSynced ? (
        <Button
          type="secondary"
          sx={{ lineHeight: 0.1, flexShrink: 0 }}
          onClick={syncLot}
        >
          label_sync_lot
        </Button>
      ) : null}
    </div>
  );
}

/**
 * Indicator
 */
type SyncLotStatus = {
  status: "SUCCESS" | "IN_PROGRESS" | "FAILED" | "ERROR" | "NO_DATA";
  date: moment.Moment | undefined;
};
function SyncLotIndicator({
  syncLotStatus,
}: {
  syncLotStatus: SyncLotStatus | undefined;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "7px",
          fontFamily: "Kanit",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "11px",
            whiteSpace: "nowrap",
          }}
        >
          {t("label_last_sync")}
        </div>
        <div
          style={{
            display: "flex",
            gap: "3px",
            alignItems: "center",
            fontSize: "10px",
          }}
        >
          {syncLotStatus?.status === "NO_DATA" ? (
            <div style={{ color: colors.onSurfaceDisabled }}>-</div>
          ) : null}
          {syncLotStatus?.status === "ERROR" ? (
            <>
              <Icon
                name="AlertTriangle"
                color="error"
                style={{
                  width: "13px",
                  height: "13px",
                }}
              />
              <div style={{ color: colors.error }}>
                {t("label_failed_to_retrieve_information")}
              </div>
            </>
          ) : null}
          {syncLotStatus?.status === "IN_PROGRESS" ? (
            <>
              <Icon
                name="Refresh"
                color="onSurfaceDisabled"
                className={styles.Spin}
                style={{
                  width: "13px",
                  height: "13px",
                }}
              />
              <div style={{ color: colors.onSurfaceDisabled }}>
                {t("label_currently_syncing")}
              </div>
            </>
          ) : null}
          {syncLotStatus?.status === "SUCCESS" ? (
            <>
              <div
                style={{
                  width: "13px",
                  height: "13px",
                  backgroundColor: colors.success,
                  borderRadius: "50%",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                <Icon
                  name="Check"
                  color="white"
                  style={{
                    width: "11px",
                    height: "11px",
                  }}
                />
              </div>
              <div style={{ color: colors.success }}>
                {syncLotStatus.date.format("DD/MM/YYYY, HH:mm")}
              </div>
            </>
          ) : null}
          {syncLotStatus?.status === "FAILED" ? (
            <>
              <Icon
                name="AlertTriangle"
                color="error"
                style={{
                  width: "13px",
                  height: "13px",
                }}
              />
              <div style={{ color: colors.error }}>
                {t("label_sync_failed")}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
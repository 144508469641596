import * as React from 'react';

interface IAcceptInventoryProps {
  color?: string;
  className?: string;
}

function AcceptInventory ({
  color = 'white',
  className
}: IAcceptInventoryProps): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M16.5 20.2499C16.301 20.2499 16.11 20.1709 15.97 20.0299L14.22 18.2799C13.927 17.9869 13.927 17.5119 14.22 17.2189C14.513 16.9259 14.988 16.9259 15.281 17.2189L16.462 18.3999L19.184 15.2579C19.456 14.9459 19.928 14.9099 20.242 15.1829C20.555 15.4539 20.589 15.9269 20.318 16.2409L17.068 19.9909C16.931 20.1479 16.736 20.2419 16.528 20.2499C16.518 20.2499 16.509 20.2499 16.5 20.2499Z'
          fill={color}
        />
        <path
          d='M17.5001 24C13.9161 24 11.0001 21.084 11.0001 17.5C11.0001 13.916 13.9161 11 17.5001 11C21.0841 11 24.0001 13.916 24.0001 17.5C24.0001 21.084 21.0841 24 17.5001 24ZM17.5001 12.5C14.7431 12.5 12.5001 14.743 12.5001 17.5C12.5001 20.257 14.7431 22.5 17.5001 22.5C20.2571 22.5 22.5001 20.257 22.5001 17.5C22.5001 14.743 20.2571 12.5 17.5001 12.5Z'
          fill={color}
        />
        <path
          d='M9.25 18H1.49C0.668 18 0 17.331 0 16.51V6.662C0 6.42 0.117 6.193 0.313 6.053L8.563 0.141C8.825 -0.047 9.175 -0.047 9.437 0.141L17.687 6.053C17.883 6.193 18 6.42 18 6.662V9.25C18 9.664 17.664 10 17.25 10C16.836 10 16.5 9.664 16.5 9.25V7.047L9 1.673L1.5 7.047V16.51L9.25 16.5C9.664 16.5 10 16.836 10 17.25C10 17.664 9.664 18 9.25 18Z'
          fill={color}
        />
        <path
          d='M9.24994 18H4.74994C4.33594 18 3.99994 17.664 3.99994 17.25V8.75C3.99994 8.336 4.33594 8 4.74994 8H13.2499C13.6639 8 13.9999 8.336 13.9999 8.75V10.25C13.9999 10.664 13.6639 11 13.2499 11C12.8359 11 12.4999 10.664 12.4999 10.25V9.5H5.49994V16.5H9.24994C9.66394 16.5 9.99994 16.836 9.99994 17.25C9.99994 17.664 9.66394 18 9.24994 18Z'
          fill={color}
        />
        <path
          d='M11.2499 12H4.74994C4.33594 12 3.99994 11.664 3.99994 11.25C3.99994 10.836 4.33594 10.5 4.74994 10.5H11.2499C11.6639 10.5 11.9999 10.836 11.9999 11.25C11.9999 11.664 11.6639 12 11.2499 12Z'
          fill={color}
        />
        <path
          d='M9.24994 15H4.74994C4.33594 15 3.99994 14.664 3.99994 14.25C3.99994 13.836 4.33594 13.5 4.74994 13.5H9.24994C9.66394 13.5 9.99994 13.836 9.99994 14.25C9.99994 14.664 9.66394 15 9.24994 15Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AcceptInventory;

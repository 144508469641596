import * as React from "react";
import { CompletedModalFormProps } from "./CompletedModal";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { Tooltip } from "@mui/material";
import { PackageWithAdjustedInfo } from "../types";
import {
  AbnormalReturnQuantityNotifier,
  IsReturnQuantityAbnormalReturnType,
} from "../components/ReturnLessThanExpected";
import { InventoryCell } from "../components/InventoryCell";
import {
  cs,
  getPackageProductId,
  stringifyToAmountInPackageWithRemainder,
} from "../utils";
import * as styles from "./CompletedModal.module.scss";
import * as colors from "common/styles/colors.scss";
import analytics from "utils/analytics";
import Icon from "common/components/icon/Icon";
import { useGetOwnPermission } from "hooks/permission";
import { TableHeading } from "../components/TableHeading";
import { useCompletedModalStateReturnValue } from "./useCompletedModalState";

type TaobinInventoryListViewProps = Omit<CompletedModalFormProps, "onClose"> & {
  completedModalState: useCompletedModalStateReturnValue;
  isPackageActualReturnAbnormal: (
    p: PackageWithAdjustedInfo
  ) => IsReturnQuantityAbnormalReturnType;
};

export function TaobinInventoryListHeader() {
  return (
    <div className={styles.TableHeader}>
      <div className={styles.InventoryCodeColumn}>
        <TableHeading>label_inventory_code</TableHeading>
      </div>
      <div className={styles.InventoryColumn}>
        <TableHeading>label_inventory</TableHeading>
      </div>
      <div className={styles.PackedBagColumn}>
        <TableHeading>
          label_packed
          {" ("}
          label_bag
          {")"}
        </TableHeading>
      </div>
      <div className={styles.PackedUOMColumn}>
        <TableHeading>
          label_packed
          {" ("}
          label_unit
          {")"}
        </TableHeading>
      </div>
      <div className={styles.FilledColumn}>
        <TableHeading>
          label_filled
          {" ("}
          label_unit
          {")"}
        </TableHeading>
      </div>
      <div className={styles.PackageSizeColumn}>
        <TableHeading>label_package_size</TableHeading>
      </div>
      <div className={styles.ExpectedReturnColumn}>
        <TableHeading>label_expected_return</TableHeading>
      </div>
      <div className={styles.ActualReturnColumn}>
        <TableHeading>
          label_actual_return
          {" ["}
          label_full_pack
          {" + "}
          label_open_bag
          {"("}
          {"label_unit"}
          {")"}
          {"]"}
        </TableHeading>
      </div>
      <div className={styles.EditButtonColumn}></div>
    </div>
  );
}

export function TaobinInventoryListView({
  completedPlan,
  completedModalState,
  planData,
  isPackageActualReturnAbnormal,
}: TaobinInventoryListViewProps) {
  const { allowed: allowedToReadjustPlan } = useGetOwnPermission({
    action: "MANAGE",
    resourceType: "PLAN",
  });

  const TaobinCompletedPlan = completedPlan?.packages?.filter(
    (p) => p.type === "COFFEE"
  );

  return (
    <div>
      {TaobinCompletedPlan.map((p) => {
        const abnormalityStatus = isPackageActualReturnAbnormal(p);
        return (
          <div
            key={p.packageInfo.packageSKU}
            className={styles.TableRow}
            style={{
              background: abnormalityStatus.exceededThreshold
                ? colors.warningOverlay
                : "",
            }}
          >
            <div
              className={cs(
                styles.InventoryCodeColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" color="onSurfaceHigh">
                {p.packageInfo.packageSKU}
              </Typography>
            </div>
            <div
              className={cs(
                styles.InventoryColumn,
                styles.CellContentAlignment
              )}
            >
              <InventoryCell productPackage={p} />
            </div>
            <div
              className={cs(
                styles.PackedBagColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productPackedInPackages}
              </Typography>
            </div>
            <div
              className={cs(
                styles.PackedUOMColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productPackedInProductUOMInPackage}{" "}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(styles.FilledColumn, styles.CellContentAlignment)}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productFilledInProductUOMInPackage}{" "}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(
                styles.PackageSizeColumn,
                styles.CellContentAlignment
              )}
            >
              <div style={{ paddingRight: "5px" }}>
                <Typography type="body-1" translate color="onSurfaceMedium">
                  {p.packageInfo.productQuantityPerPackage}{" "}
                  {p.packageInfo.productUOMInPackage} /{" "}
                  {p.packageInfo.packageUOM}
                </Typography>
              </div>
            </div>
            <div
              className={cs(
                styles.ExpectedReturnColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {stringifyToAmountInPackageWithRemainder({
                  p,
                  value: p.refillInfo.expectedReturnInProductUOMInPackage,
                  oldQuantity:
                    p.refillInfo.totalOldQuantityInProductUOMInPackage,
                })}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ActualReturnColumn,
                styles.CellContentAlignment
              )}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1px",
                    alignItems: " center",
                  }}
                >
                  {completedModalState.checkIfEditingProduct(
                    getPackageProductId(p)
                  ) ? (
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "4px",
                      }}
                      className={cs(
                        styles.InputWhiteBgOnFocus,
                        completedModalState.product.actualReturnBag === ""
                          ? styles.InputEmpty
                          : styles.InputFilled
                      )}
                    >
                      <TextField
                        inputProps={{
                          style: {
                            textAlign: "right",
                          },
                        }}
                        value={completedModalState.product.actualReturnBag}
                        onChange={(e) =>
                          completedModalState.editActualReturnBag({
                            actualReturnBag: e.target.value,
                          })
                        }
                        required
                        label={"label_quantity_short"}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        fontFamily: "Kanit",
                        fontWeight: 700,
                        fontSize: "16px",
                        textAlign: "right",
                        paddingRight: "12px",
                      }}
                    >
                      {p.adjustedInfo.actualReturnInPackages}
                    </div>
                  )}

                  <div
                    style={{
                      width: "30px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography type="body-1" translate color="onSurfaceMedium">
                      label_bag
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "1px",
                    alignItems: " center",
                  }}
                >
                  {completedModalState.checkIfEditingProduct(
                    getPackageProductId(p)
                  ) ? (
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "4px",
                      }}
                      className={cs(
                        styles.InputWhiteBgOnFocus,
                        completedModalState.product.actualReturnRemaining === ""
                          ? styles.InputEmpty
                          : styles.InputFilled
                      )}
                    >
                      <TextField
                        inputProps={{
                          style: {
                            textAlign: "right",
                          },
                        }}
                        value={
                          completedModalState.product.actualReturnRemaining
                        }
                        onChange={(e) =>
                          completedModalState.editActualReturnRemaining({
                            actualReturnRemaining: e.target.value,
                          })
                        }
                        required
                        label={"label_unit"}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        fontFamily: "Kanit",
                        fontWeight: 700,
                        fontSize: "16px",
                        textAlign: "right",
                        paddingRight: "12px",
                      }}
                    >
                      {p.adjustedInfo.actualRemainingInProductUOMInPackage}
                    </div>
                  )}

                  <div
                    style={{
                      width: "30px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography type="body-1" color="onSurfaceMedium">
                      {p.packageInfo.productUOMInPackage}
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ position: "absolute", bottom: -1 }}>
                {abnormalityStatus.exceededThreshold ? (
                  <AbnormalReturnQuantityNotifier
                    abnormalStatus={abnormalityStatus.abnormalStatus}
                  />
                ) : null}
              </div>
            </div>

            <div className={styles.EditButtonColumn}>
              {completedModalState.isEditing ? null : (
                <Tooltip
                  onClick={() => alert("Tooltip click")}
                  placement="top"
                  title={
                    !completedPlan.canEditReturnItems ||
                    !allowedToReadjustPlan ? (
                      <Typography type="caption" translate color="background">
                        {!completedPlan.canEditReturnItems
                          ? "label_can_only_edit_within_that_day"
                          : !allowedToReadjustPlan
                            ? "label_you_dont_have_the_permission"
                            : null}
                      </Typography>
                    ) : null
                  }
                >
                  <button
                    onClick={() => {
                      if (
                        completedPlan.canEditReturnItems &&
                        allowedToReadjustPlan
                      ) {
                        completedModalState.selectProductForAdjusment({
                          productId: getPackageProductId(p),
                          actualReturnBag: String(
                            p.adjustedInfo.actualReturnInPackages
                          ),
                          actualReturnRemaining: String(
                            p.adjustedInfo.actualRemainingInProductUOMInPackage
                          ),
                        });
                      }
                      analytics.track({
                        name: "plan return edit button clicked",
                        properties: {
                          planName: planData?.planName,
                          planId: planData?.planId,
                          warehouse: planData?.warehouseName,
                          noOfCompletedMachine:
                            planData?.taoBinCompleted +
                            planData?.spiralCompleted,
                          noOfMachineInPlan:
                            planData?.taoBinInPlan + planData?.spiralInPlan,
                          // spiral machine
                          noOfCompletedSpiralMachine: planData?.spiralCompleted,
                          noOfSpiralMachineInPlan: planData?.spiralInPlan,
                          // taobin machine
                          noOfCompletedTaobinMachine: planData?.taoBinCompleted,
                          noOfTaobinMachineInPlan: planData?.taoBinInPlan,
                        },
                      });
                    }}
                    className={styles.EditButton}
                    style={{
                      backgroundColor:
                        completedPlan.canEditReturnItems &&
                        allowedToReadjustPlan
                          ? "#4B4D9B"
                          : "#D9D9D9",
                    }}
                  >
                    <Icon
                      name="PencilCreate"
                      color="white"
                      style={{
                        width: 14,
                        height: 14,
                      }}
                    />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

import * as React from "react";
import { useGetRefillZonesByOrgIdQuery } from "gql/generated";
import Autocomplete, {
  AutocompleteOption,
  IAutocompleteProps,
} from "components/Autocomplete/Autocomplete";

type RefillzoneIdFormProps = {
  organizationId: string;
  onChange: (value: string) => void;
  value: string;
  fallBackOptions?: Array<AutocompleteOption>;
} & Omit<IAutocompleteProps, "options" | "label" | "id">;

export function RefillZoneIdAutoComplete({
  value,
  onChange,
  organizationId,
  fallBackOptions = [],
  ...props
}: RefillzoneIdFormProps) {
  const getRefillZonesByOrgIdQueryResult = useGetRefillZonesByOrgIdQuery(
    {
      organisationId: organizationId,
    },
    {
      enabled: organizationId !== "",
    }
  );

  const refillZones = getRefillZonesByOrgIdQueryResult.data?.refillZones;
  const optionsFromQuery =
    refillZones?.map((refillZone) => ({
      label: refillZone.friendlyId,
      value: refillZone.id,
    })) ?? [];
  const options = refillZones ? optionsFromQuery : fallBackOptions;

  return (
    <Autocomplete
      loading={getRefillZonesByOrgIdQueryResult.isLoading}
      label="label_refill_zone"
      id="refill_zone"
      disableClearable
      value={value}
      onChange={onChange}
      options={options}
      {...props}
    />
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewSlotModal--2ZYzzFMIR7{display:flex;flex-direction:column;flex:1}.NewSlotModal--2ZYzzFMIR7 .title--a6-fjnQJo6{margin-bottom:20px}.NewSlotModal--2ZYzzFMIR7 .input--5CCOWksbol{margin-bottom:20px}.NewSlotModal--2ZYzzFMIR7 .subInput--3NCoYG7zmd{margin:-16px 0 5px 16px;color:rgba(0,0,0,.38)}.NewSlotModal--2ZYzzFMIR7 .checkbox--1UbRQrG-Sn{display:flex;flex-direction:row;align-items:center;margin-bottom:20px}.NewSlotModal--2ZYzzFMIR7 .checkbox--1UbRQrG-Sn .label--PBSS4iFV0H{margin-left:10px}.NewSlotModal--2ZYzzFMIR7 .actions--21vVSzUenZ{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-top:10px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"NewSlotModal": "NewSlotModal--2ZYzzFMIR7",
	"title": "title--a6-fjnQJo6",
	"input": "input--5CCOWksbol",
	"subInput": "subInput--3NCoYG7zmd",
	"checkbox": "checkbox--1UbRQrG-Sn",
	"label": "label--PBSS4iFV0H",
	"actions": "actions--21vVSzUenZ"
};
module.exports = exports;

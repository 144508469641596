import { useQueries } from 'react-query';
import { GetUserPermissionResponse, getUserPermission } from '../services/user-permission';
export interface GetRefillZonePermissionsResponse {
  [userId: string]: GetUserPermissionResponse;
}

export const REFILL_ZONE_PERMISSION_KEY = 'refillZonePermissions';

export function useGetRefillZonesPermissions(userIds: Array<string>) {
  const queries = useQueries(
    (userIds ?? []).map((userId) => {
      return {
        queryKey: [REFILL_ZONE_PERMISSION_KEY, { userId }],
        queryFn: () => getUserPermission(userId, { action: 'read', resourceType: 'refillZone' }),
        staleTime: 5 * 60 * 1000,
      };
    })
  );

  const data: Record<string, string[]> = {};
  queries.forEach((query, index) => {
    data[userIds[index]] = query.data;
  });

  return { data };
}

export interface GetServiceZonePermissionsResponse {
  [userId: string]: GetUserPermissionResponse;
}

export const SERVICE_ZONE_PERMISSION_KEY = 'serviceZonePermissionKey';

export function useGetServiceZonePermissions(userIds: Array<string>) {
  const queries = useQueries(
    (userIds ?? []).map((userId) => {
      return {
        queryKey: [SERVICE_ZONE_PERMISSION_KEY, { userId }],
        queryFn: () => getUserPermission(userId, { action: 'read', resourceType: 'serviceZone' }),
        staleTime: 5 * 60 * 1000,
      };
    })
  );

  const data: Record<string, string[]> = {};
  queries.forEach((query, index) => {
    data[userIds[index]] = query.data;
  });

  return { data };
}

import * as React from "react";
import { Document, contractByIdCache } from "../../hooks/useGetContractById";
import Typography from "components/Typography/Typography";
import Icon from "common/components/icon/Icon";
import { IconButton } from "@mui/material";
import {
  useRemoveContractDocumentMutation,
  useUploadContractDocumentMutation,
} from "gql/generated";
import { toasti18n } from "utils/toast";
import Button from "components/Button";
import { Spinner } from "components/LoadingScreen";
import { useImmer } from "use-immer";

export function ContractDocumentView({
  documents,
}: {
  documents: Array<Document>;
}) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_additional_document
      </Typography>
      <div style={{ height: "20px" }} />
      <DocumentListView documents={documents} />
    </div>
  );
}

export function ContractDocumentForm({
  contractId,
  documents,
}: {
  contractId: string;
  documents: Array<Document>;
}) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_additional_document
      </Typography>
      <div style={{ height: "20px" }} />
      <UploadContractDocument contractId={contractId} />
      <div style={{ height: "30px" }} />
      <DocumentListForm documents={documents} contractId={contractId} />
    </div>
  );
}

function UploadContractDocument({ contractId }: { contractId: string }) {
  const { mutateAsync: uploadContractDocument } =
    useUploadContractDocumentMutation();

  return (
    <DnDBase64FileUpload
      onSuccess={(file) => {
        contractByIdCache.addDocumentToContract({
          contractId: contractId,
          fileName: file.name,
          url: URL.createObjectURL(file),
        });
        toasti18n.success();
      }}
      onError={(error) => {
        toasti18n.error(error);
      }}
      uploadFn={async ({ fileName, base64FileData }) => {
        await uploadContractDocument({
          input: {
            contractId: contractId,
            documents: [
              {
                filename: fileName,
                dataUri: base64FileData,
              },
            ],
          },
        });
      }}
    />
  );
}

type FileUploadState = {
  state: "idle" | "dragging" | "converting" | "uploading";
  file: File | null;
};

function DnDBase64FileUpload({
  onError,
  onSuccess,
  uploadFn,
}: {
  onError: (error: Error) => void;
  onSuccess: (file: File) => void;
  uploadFn: ({
    fileName,
    base64FileData,
  }: {
    fileName: string;
    base64FileData: string;
  }) => Promise<void>;
}) {
  const [fileUploadState, setFileUploadState] = useImmer<FileUploadState>({
    state: "idle",
    file: null,
  });

  function onDragEnter() {
    if (fileUploadState.state === "idle") {
      setFileUploadState((draft) => {
        draft.state = "dragging";
      });
    }
  }

  function onDropFail() {
    onDragLeave();
  }

  function onDragLeave() {
    if (fileUploadState.state === "dragging") {
      setFileUploadState((draft) => {
        draft.state = "idle";
      });
    }
  }

  function onFileUpload(file: File) {
    if (
      fileUploadState.state !== "idle" &&
      fileUploadState.state !== "dragging"
    ) {
      return;
    }

    setFileUploadState((draft) => {
      draft.file = file;
      draft.state = "converting";
    });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = () => {
      setFileUploadState((draft) => {
        if (draft.state === "converting") {
          draft.state = "idle";
          draft.file = null;
        }
      });
      onError(new Error("Error converting file"));
    };
    reader.onload = () => {
      convertSuccess(reader.result as string);
    };
  }

  async function convertSuccess(base64File: string) {
    setFileUploadState((draft) => {
      if (draft.state !== "converting") {
        return;
      }
      draft.state = "uploading";

      uploadFn({
        fileName: draft.file.name,
        base64FileData: base64File,
      })
        .then(() => {
          setFileUploadState((draft) => {
            if (draft.state !== "uploading") {
              return;
            }
            onSuccess(draft.file);
            draft.state = "idle";
            draft.file = null;
          });
        })
        .catch((err: Error) => {
          onError(err);
          setFileUploadState((draft) => {
            if (draft.state !== "uploading") {
              return;
            }
            draft.state = "idle";
            draft.file = null;
          });
        });
    });
  }

  const isDraggingOver = fileUploadState.state === "dragging";
  const isLoading =
    fileUploadState.state === "converting" ||
    fileUploadState.state === "uploading";

  const ref = React.useRef<HTMLInputElement>(null);

  function onUploadClick() {
    ref.current.click();
  }

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        onDragEnter();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        onDragLeave();
      }}
      onDrop={(e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length === 0) {
          onDropFail();
          return;
        }
        onFileUpload(e.dataTransfer.files[0]);
      }}
      style={{
        border: "1px dashed rgba(0,0,0,0.12)",
        position: "relative",
        height: "145px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        transition: "all 200ms",
        backgroundColor: isDraggingOver ? "#CBCCE6" : undefined,
      }}
    >
      <input
        type="file"
        id="file"
        ref={ref}
        hidden
        onChange={(e) => {
          if (e.target.files?.length === 0) return;
          onFileUpload(e.target.files[0]);
        }}
      />
      <div
        style={{
          position: "absolute",
          left: "11px",
          top: "-10px",
          transition: "all 200ms",
          backgroundColor: isDraggingOver ? undefined : "white",
        }}
      >
        <Typography type="caption" color="onSurfaceMedium" translate>
          label_quotation_and_other_reference_files
        </Typography>
      </div>
      {isLoading ? (
        <>
          <Typography type="body-2" translate>
            {"label_uploading"}
            {"..."}
          </Typography>
          <Spinner />
        </>
      ) : null}
      {!isLoading ? (
        <>
          <Typography type="body-2" translate>
            label_drag_and_drop_files_here
          </Typography>
          <Typography type="body-1" color="onSurfaceDisabled" translate>
            label_or
          </Typography>
          <Button type="secondary" iconName="Upload" onClick={onUploadClick}>
            label_upload_from_computer
          </Button>
        </>
      ) : null}
    </div>
  );
}

function RemoveDocumentButton({
  document,
  contractId,
}: {
  document: Document;
  contractId: string;
}) {
  const { isLoading, mutate } = useRemoveContractDocumentMutation({
    onError: (error: Error) => {
      toasti18n.error(error);
    },
    onSuccess: () => {
      contractByIdCache.removeDocumentFromContract({
        contractId: contractId,
        documentName: document.name,
      });
      toasti18n.success();
    },
  });

  function handleRemoveDocumentClick() {
    mutate({
      input: {
        contractId: contractId,
        filenames: [document.name],
      },
    });
  }

  return (
    <IconButton disabled={isLoading} onClick={handleRemoveDocumentClick}>
      <Icon name="Trash" color={isLoading ? "onSurfaceDisabled" : "primary"} />
    </IconButton>
  );
}

export function DocumentListForm({
  documents,
  contractId,
}: {
  documents: Array<Document>;
  contractId: string;
}) {
  return (
    <div style={{ display: "grid", gap: "16px" }}>
      {documents.map((document) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={document.name}
        >
          <DocumentListItem name={document.name} url={document.url} />
          <RemoveDocumentButton document={document} contractId={contractId} />
        </div>
      ))}
    </div>
  );
}

function DocumentListView({ documents }: { documents: Array<Document> }) {
  return (
    <div style={{ display: "grid", gap: "16px" }}>
      {documents.map((document) => (
        <DocumentListItem
          name={document.name}
          url={document.url}
          key={document.name}
        />
      ))}
    </div>
  );
}

function DocumentListItem({ name, url }: Document) {
  return (
    <div
      style={{
        display: "flex",
        gap: "26px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "grid",
          placeContent: "center",
          backgroundColor: "#F3F3FD",
          width: "40px",
          height: "40px",
          borderRadius: "9px",
        }}
      >
        <PdfIcon />
      </div>
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          <Typography type="body-2">{name}</Typography>
        </a>
      </div>
    </div>
  );
}
function PdfIcon() {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14982 0H11.1738L17.9744 7.08846V20.8756C17.9744 22.6027 16.5771 24 14.8559 24H3.14982C1.42269 24 0.0253906 22.6027 0.0253906 20.8756V3.12443C0.0253603 1.3973 1.42266 0 3.14982 0V0Z"
        fill="#E5252A"
      />
      <path
        opacity="0.302"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1677 0V7.03449H17.9743L11.1677 0Z"
        fill="white"
      />
      <path
        d="M3.49756 17.907V13.5232H5.36263C5.82441 13.5232 6.19024 13.6491 6.46609 13.907C6.74195 14.1589 6.87989 14.5007 6.87989 14.9265C6.87989 15.3523 6.74195 15.6941 6.46609 15.946C6.19024 16.2039 5.82441 16.3298 5.36263 16.3298H4.619V17.907H3.49756ZM4.619 15.3763H5.2367C5.4046 15.3763 5.53654 15.3403 5.62651 15.2564C5.71645 15.1784 5.76446 15.0704 5.76446 14.9265C5.76446 14.7826 5.71648 14.6746 5.62651 14.5967C5.53657 14.5127 5.40463 14.4768 5.2367 14.4768H4.619V15.3763ZM7.34164 17.907V13.5232H8.89486C9.20071 13.5232 9.48857 13.5652 9.75843 13.6551C10.0283 13.7451 10.2742 13.871 10.4901 14.0449C10.706 14.2128 10.8799 14.4407 11.0058 14.7286C11.1257 15.0165 11.1917 15.3463 11.1917 15.7181C11.1917 16.0839 11.1258 16.4138 11.0058 16.7016C10.8799 16.9894 10.706 17.2173 10.4901 17.3852C10.2741 17.5591 10.0283 17.6851 9.75843 17.775C9.48857 17.865 9.20071 17.907 8.89486 17.907H7.34164ZM8.43909 16.9535H8.76292C8.93683 16.9535 9.09876 16.9355 9.24868 16.8935C9.3926 16.8515 9.53054 16.7856 9.66248 16.6956C9.78842 16.6057 9.89037 16.4797 9.96233 16.3118C10.0343 16.1439 10.0703 15.946 10.0703 15.7181C10.0703 15.4842 10.0343 15.2863 9.96233 15.1184C9.89037 14.9505 9.78842 14.8246 9.66248 14.7346C9.53054 14.6447 9.39263 14.5787 9.24868 14.5367C9.09876 14.4947 8.93683 14.4767 8.76292 14.4767H8.43909V16.9535ZM11.7554 17.907V13.5232H14.8739V14.4767H12.8769V15.1784H14.4721V16.1259H12.8769V17.907H11.7554Z"
        fill="white"
      />
    </svg>
  );
}

import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import {
  QueryFunctionContext,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

const warehouseTeamKey = {
  warehouseTeam: (warehouseId: string) =>
    [
      {
        scope: "warehouse",
        innerScope: "team",
        warehouseId,
        url: `/warehouse/:warehouseId/users`,
        resolvedUrl: `/warehouse/${warehouseId}/users`,
      },
    ] as const,
};
type WarehouseTeamQueryKey = ReturnType<
  typeof warehouseTeamKey["warehouseTeam"]
>;

type WarehouseTeam = Array<{
  userName: string;
  firstName: string;
  id: string;
  email: string;
  organization: string;
  canViewAllMachines: boolean;
  canViewAllWarehouses: boolean;
  role: string;
  lastLogin: number;
  serviceAccount: boolean;
  canImpersonate: boolean;
  userAvatar: string;
  lastName?: string;
}>;

async function getSupportedMachines(
  context: QueryFunctionContext<WarehouseTeamQueryKey>
) {
  const [{ warehouseId }] = context.queryKey;
  const response = await httpClient.get<WarehouseTeam>(
    `warehouse/${warehouseId}/users`
  );
  return response.data;
}

type GetWarehouseProductByIdQueryOptions = Omit<
  UseQueryOptions<
    WarehouseTeam,
    AxiosError,
    WarehouseTeam,
    WarehouseTeamQueryKey
  >,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetWarehouseTeamByIdBase({
  warehouseId,
  options = {},
}: {
  warehouseId: string;
  options?: GetWarehouseProductByIdQueryOptions;
}) {
  return useQuery({
    queryKey: warehouseTeamKey.warehouseTeam(warehouseId),
    queryFn: getSupportedMachines,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetWarehouseTeambyId(
  warehouseId: string,
  options?: GetWarehouseProductByIdQueryOptions
) {
  return useGetWarehouseTeamByIdBase({
    warehouseId: warehouseId,
    options,
  });
}

type RemoveUserFromWarehouseRequest = {
  warehouseId: string;
  userId: string;
};

function removeUserFromWarehouse(data: RemoveUserFromWarehouseRequest) {
  return httpClient.delete<string>(
    `/warehousePermission/${data.warehouseId}/${data.userId}`
  );
}

export function useRemoveUserFromWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, RemoveUserFromWarehouseRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeUserFromWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, variables] = args;
      queryClient.invalidateQueries(
        warehouseTeamKey.warehouseTeam(variables.warehouseId)
      );
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

type AddUserToWarehouseRequest = {
  userId: string;
  warehouseId: string;
};

function addUserToWarehouse(data: AddUserToWarehouseRequest) {
  return httpClient.post<string>(`/warehousePermission`, data);
}

export function useAddUserToWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, AddUserToWarehouseRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addUserToWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, variables] = args;
      queryClient.invalidateQueries(
        warehouseTeamKey.warehouseTeam(variables.warehouseId)
      );
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

import * as React from "react";
import {
  contractRoutes,
  useContractIdUrlParams,
} from "containers/contract/routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TextButton from "common/components/textbutton/TextButton";

import { GeneralContractInfoView } from "../../components/contract/GeneralContractInfo";
import { ContractWorkSpace } from "../../components/ContractWorkSpace";
import {
  ContractDetails,
  useGetContractById,
} from "../../hooks/useGetContractById";
import { ContractContactInfoView } from "../../components/contract/ContractContactInfo";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import { LocationCardLink } from "./LocationCardLink";
import { ContractDocumentView } from "../../components/contract/ContractDocument";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";
import { DuplicateContractButton } from "../EditContract/DuplicateContractButton";

export default function ContractId() {
  const contractId = useContractIdUrlParams();
  return <ContractIdPage contractId={contractId} key={contractId} />;
}

export function ContractIdPage({ contractId }: { contractId: string }) {
  const getContractByIdResult = useGetContractById(contractId);
  const permissionResult = useCanAccessContractFeature();
  const isLoading =
    getContractByIdResult.isLoading || permissionResult.isLoading;

  const { t } = useTranslation();

  return (
    <ContractWorkSpace.ScreenWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ContractWorkSpace.PageTitle>
          {t("label_contract")}
        </ContractWorkSpace.PageTitle>

        {isLoading ? null : (
          <div
            style={{
              marginLeft: "auto",
              flexShrink: 0,
            }}
          >
            <ContractNav contract={getContractByIdResult.data} />
          </div>
        )}
      </div>

      {isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <ContractWorkSpace.MaxWidthContainer>
          <ContractWorkSpace.Spacer />

          <GeneralContractInfoView
            generalInfo={getContractByIdResult.data.general}
          />

          <Typography type="headline-6" translate>
            label_location
          </Typography>
          <div style={{ height: "10px" }}></div>
          <div style={{ display: "flex" }}>
            <Link to={contractRoutes.addLocationToContract(contractId)}>
              <Button type="secondary">label_add_new_location</Button>
            </Link>
          </div>
          <div style={{ height: "10px" }}></div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(min(100%, 140px), 1fr))",
              gap: "13px",
            }}
          >
            {getContractByIdResult.data.locations.map((location) => (
              <LocationCardLink
                key={location.id}
                location={location}
                contract={{
                  id: getContractByIdResult.data.general.contractId,
                  friendlyId:
                    getContractByIdResult.data.general.contractFriendlyId,
                }}
              />
            ))}
          </div>
          <ContractWorkSpace.Spacer />
          <ContractContactInfoView
            contactInfo={getContractByIdResult.data.contact}
          />
          <ContractWorkSpace.Spacer />
          <ContractDocumentView
            documents={getContractByIdResult.data.documents}
          />
        </ContractWorkSpace.MaxWidthContainer>
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function ContractNav({ contract }: { contract: ContractDetails }) {
  return (
    <nav
      style={{
        marginLeft: "auto",
        flexShrink: 0,
      }}
    >
      <ul
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
          margin: 0,
          gap: 14,
        }}
      >
        <li
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          <Link to={contractRoutes.editContract(contract.general.contractId)}>
            <TextButton icon="Edit" translationKey="label_edit" />
          </Link>
        </li>
        <li
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          <DuplicateContractButton contract={contract} />
        </li>
      </ul>
    </nav>
  );
}

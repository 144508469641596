import * as React from 'react';
import { Route as DomRoute } from 'react-router-dom';

interface IRouteProps {
  path: string,
  component: React.FunctionComponent,
  exact?: boolean,
}

function Route ({
  path,
  component: Component,
  exact = false,
  ...routeProps
}: IRouteProps): JSX.Element {
  return (
    <DomRoute
      exact={exact}
      path={path}
      render={() => (
        <Component {...routeProps} />
      )}
    />
  );
}

export default Route;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StringFillBackgroundCell--15uZYtecW2{width:100%;display:flex;align-items:center}.StringFillBackgroundCell--15uZYtecW2 .Typography--3897HoAtka{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"StringFillBackgroundCell": "StringFillBackgroundCell--15uZYtecW2",
	"Typography": "Typography--3897HoAtka"
};
module.exports = exports;

import { IAction } from 'common/interfaces';

interface IApplicationState {
  initialized: boolean
}

const initialState: IApplicationState = {
  initialized: false
};

function applicationReducer (
  state: IApplicationState = initialState,
  action: IAction
): IApplicationState {
  switch (action.type) {
    case 'APPLICATION/INITIALIZED/SUCCESS':
      return { ...state, initialized: true };
    default:
      return state;
  }
}

export default applicationReducer;

import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { AaNoTransaction30MinsTable } from "./AaNoTransaction30MinsTable";
import { NoTransaction2HoursTable } from "./NoTransaction2HoursTable";
import { BillAndCoinTable } from "./BillAndCoinTable";
import { NoTransactionTodayTable } from "./NoTransactionTodayTable";
import { Fallback } from "../../components/Fallback";

export function TransactionsTable() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();
  return (
    <Switch>
      <Route path={routes.transactions.aaNoTransaction30Mins()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"aANoTransaction30Mins" + (serviceZone ?? +"")}
        >
          <AaNoTransaction30MinsTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.transactions.noTransaction2Hours()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"noTransaction2Hours" + (serviceZone ?? +"")}
        >
          <NoTransaction2HoursTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.transactions.noTransactionToday()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"noTransactionToday" + (serviceZone ?? +"")}
        >
          <NoTransactionTodayTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.transactions.billAndCoin()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"billAndCoin" + (serviceZone ?? +"")}
        >
          <BillAndCoinTable />
        </ErrorBoundary>
      </Route>
      <Redirect to={routes.transactions.aaNoTransaction30Mins() + search} />
    </Switch>
  );
}

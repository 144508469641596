import * as React from 'react';
import { useState } from 'react';
import { downloadCSVFromNetworkRequest } from 'utils/csv';
import Typography from 'components/Typography';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import { getMaterialMovementReport } from 'refillorders/service';
import MuiModal from 'components/Modal';
import { toasti18n } from 'utils/toast';
import * as moment from 'moment';
import Select from 'components/Select/Select';

function DownloadMaterialMovementReportModal({
  onCancel,
}: {
  onCancel: () => void;
}): JSX.Element {
  const [startTime, setStartTime] = useState<string>(
    String(moment().startOf('day').valueOf())
  );
  const [state, setState] = useState<
    'kitted' | 'en_route' | 'refilled' | 'completed'
  >('completed');

  const [isLoading, setIsLoading] = useState(false);

  async function downloadMaterialMovementReport() {
    setIsLoading(true);
    try {
      await downloadCSVFromNetworkRequest(
        () =>
          getMaterialMovementReport({
            timestampMS: startTime,
            state: state,
          }),
        `material-movement-report-${moment(Number(startTime)).format(
          'DD-MM-YYYY'
        )}`
      );

      toasti18n.success();
      onCancel();
    } catch (err) {
      toasti18n.error(err);
      setIsLoading(false);
    }
  }

  return (
    <MuiModal
      open={true}
      onClose={isLoading ? () => {} : onCancel}
      padding="18px 19px"
      minWidth="390px"
      PaperProps={{ sx: { gap: '16px' } }}
    >
      <>
        <Typography type="headline-6" translate>
          label_download_material_movement
        </Typography>
        <Select
          id="status"
          label="label_status"
          value={state}
          onChange={(e) => setState(e.target.value)}
          options={[
            { label: 'label_kit', value: 'kitted' },
            { label: 'label_enroute', value: 'en_route' },
            { label: 'label_refilled', value: 'refilled' },
            { label: 'label_completed', value: 'completed' },
          ]}
          required
        />
        <DatePicker
          required
          label="label_start_date"
          value={startTime === '' ? null : moment(Number(startTime))}
          onChange={(e) => {
            if (e === null || (moment.isMoment(e) && !e.isValid())) {
              setStartTime('');
            } else if (moment.isMoment(e)) {
              setStartTime(String(e.startOf('day').valueOf()));
            }
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="secondary" onClick={onCancel} disabled={isLoading}>
            action_cancel
          </Button>
          <Button
            type="primary"
            disabled={startTime === ''}
            loading={isLoading}
            onClick={downloadMaterialMovementReport}
          >
            action_download
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export default DownloadMaterialMovementReportModal;

import { httpClient } from 'common/services/transportService';

export async function getTransactionSales(
  queryObject: ISalesQuery
): Promise<IGroupSalesResult> {
  const response = await httpClient.get<IGroupSalesResult>('/sales', {
    params: queryObject,
  });
  return response.data;
}

export async function getMachineTransactionSales(
  machineId: string,
  queryObject: ISalesQuery
): Promise<IGroupSalesResult> {
  const response = await httpClient.get<IGroupSalesResult>(
    `/sales/${machineId}`,
    { params: queryObject }
  );
  return response.data;
}
export interface IGroupSalesResult {
  meta: IMeta;
  results: IGroupByNone[];
}

interface IMeta {
  isPaginated?: boolean;
  currentPage?: number;
  perPage?: number;
  totalPages?: number;
  totalResults?: number;
  queryId?: string;
  queryExecutionInMs?: number;
  executionInMs?: number;
  queryStart: string | Date;
  queryEnd: string | Date;
  pointer: IPointer;
}

export interface IGroupByNone {
  time: number;
  machineId: string;
  productCode: string;
  productName: string;
  status: string;
  errorcode: string;
  price: number;
  promotionDiscount: number;
  paymentType: string;
  telephoneNumber: string;
  machineName: string;
  totalResults?: number;
  transId: number;
  locationType: string;
  locationName?: string;
}

interface IPointer {
  prevTimestamp: number;
  prevTransId: number;
  nextTimestamp: number;
  nextTransId: number;
}

export interface ISalesQuery {
  from: string;
  to: string;
  limit: string;
  groupBy: string;
  action?: string;
  offsetTimestamp?: string;
  transId?: string;
}

import * as React from 'react';

// Custom components
import Button, { IButtonProps } from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';

// Styles
import * as styles from './ConfirmationModal.module.scss';

interface IConfirmationModalProps {
  contentClassName?: string;
  modalTitle: string;
  modalText?: string;
  cancelButtonProps: IButtonProps;
  acceptButtonprops: IButtonProps;
}
export function ConfirmationModal ({
  contentClassName = '',
  modalTitle,
  modalText,
  cancelButtonProps,
  acceptButtonprops
}: IConfirmationModalProps): JSX.Element {
  return (
    <Modal
      contentClassName={[ styles.ConfirmationModal, contentClassName ].join(' ')}
    >
      <div className={styles.modalTitle}>
        <Typography type="headline-6" translationKey={modalTitle} />
      </div>
      <div className={styles.modalText}>
        <Typography type="body-1" translationKey={modalText} />
      </div>
      <div className={styles.buttonContainer}>
        <Button {...cancelButtonProps}/>
        <Button {...acceptButtonprops}/>
      </div>
    </Modal>
  );
}

import * as React from 'react';

interface IOrganisation {
  color?: string;
  className?: string;
}

function Organisation ({
  color = 'white',
  className
}: IOrganisation): JSX.Element {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 16.6604 3 19.3271 9 22C15 19.3333 18 16.6667 18 14C18 13.6186 18.0017 12.8033 18.0038 11.7947C18.01 8.83711 18.0197 4.21723 18 4C18 3.23241 17.7054 3.05401 16.8901 2.56029C16.6432 2.4108 16.3486 2.23241 16 2C14.5 1 11.9952 0 9 0C5.56321 0 2.16179 1.89214 2 2C1.65139 2.23241 1.35679 2.4108 1.10993 2.56029C0.294599 3.05401 0 3.23241 0 4V14ZM16.0018 12.7547C16.0006 13.3838 16 13.7697 16 14C16 15.5142 13.7722 17.5737 9.00093 19.802C4.22911 17.5687 2 15.5085 2 14V4.36333C2.04022 4.33835 2.08307 4.31177 2.12779 4.28403C2.45116 4.08342 2.87264 3.82194 3.1094 3.6641C4.02864 3.05128 6.83809 2 9 2C11.2602 2 13.4143 2.67992 14.8906 3.6641C15.1274 3.82194 15.5488 4.08342 15.8722 4.28403C15.9205 4.31399 15.9666 4.34259 16.0096 4.36928C16.0117 4.96182 16.0127 6.87764 16.009 9.10978C16.0082 9.62725 16.0072 10.1695 16.0059 10.8014L16.0018 12.7547Z" fill={color} fillOpacity="1"/>
    </svg>
  );
}

export default Organisation;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalOverlay--1_Fzhe4xKl{height:100%;display:flex;align-items:center;justify-content:center;box-sizing:border-box;padding:25px}.ModalOverlay--1_Fzhe4xKl .ModalContainer--3O5_AnpsL7{background-color:#fff;border-radius:3px;min-width:0}", ""]);
// Exports
exports.locals = {
	"ModalOverlay": "ModalOverlay--1_Fzhe4xKl",
	"ModalContainer": "ModalContainer--3O5_AnpsL7"
};
module.exports = exports;

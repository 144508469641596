import * as React from "react";

import TextButton from "common/components/textbutton/TextButton";
import ViewNav from "common/components/viewnav/ViewNav";
import Typography from "components/Typography";

import AddNewZoneModal from "./modals/AddNewZoneModal";
import AddNewLocationModal from "./modals/AddNewLocationModal";
import AddNewFactoryModal from "./modals/AddNewFactoryModal";

import * as styles from './LocationHeader.module.scss';
import { useIsAdmin, useIsAdminContract, useIsClerk, useIsOperator } from 'utils/user-role';
import { useGetOwnPermission } from 'hooks/permission';
import { LocationDropDownV2 } from "./LocationDropdown";

function LocationHeader(): JSX.Element {
  const canEditContent = useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsClerk();

  const [isAddNewZoneModalOpen, setIsAddNewZoneModalOpen] =
    React.useState<boolean>(false);
  const [isAddNewLocationModalOpen, setIsAddNewLocationModalOpen] =
    React.useState<boolean>(false);
  const [isAddNewFactoryModalOpen, setIsAddNewFactoryModalOpen] =
    React.useState<boolean>(false);

  const {
    allowed: isAllowedToCreateLocation,
    isLoading: isCurrentUserPermissionLoading,
  } = useGetOwnPermission({
    action: "MANAGE",
    resourceType: "LOCATION",
  });

  return (
    <div className={styles.Header}>
      <div>
        <Typography type="headline-5" translate>
          workspace_location
        </Typography>
      </div>

      <div className={styles.ButtonsAndNav}>
        <div className={styles.Buttons}>
          {!isCurrentUserPermissionLoading && isAllowedToCreateLocation && (
            <>
              <TextButton
                translationKey="label_add_new_location"
                onClick={() => setIsAddNewLocationModalOpen(true)}
                icon="LocationAdd"
              />
              {isAddNewLocationModalOpen && (
                <AddNewLocationModal
                  onClose={() => setIsAddNewLocationModalOpen(false)}
                />
              )}
              <TextButton
                translationKey="label_add_new_factory"
                onClick={() => setIsAddNewFactoryModalOpen(true)}
                icon="LocationAdd"
              />
              {isAddNewFactoryModalOpen && (
                <AddNewFactoryModal
                  onClose={() => setIsAddNewFactoryModalOpen(false)}
                />
              )}
            </>
          )}
          <>
            {canEditContent && (
              <TextButton
                translationKey="label_add_new_zone"
                onClick={() => setIsAddNewZoneModalOpen(true)}
                icon="Plus"
              />
            )}

            {isAddNewZoneModalOpen && (
              <AddNewZoneModal
                onCancel={() => setIsAddNewZoneModalOpen(false)}
              />
            )}
          </>
          {canEditContent && <LocationDropDownV2 />}
        </div>

        <ViewNav
          options={[
            // {
            //   icon: "Map",
            //   to: "/location/map",
            // },
            {
              icon: "Location",
              to: "/location/list",
            },
          ]}
        />
      </div>
    </div>
  );
}

export default LocationHeader;

import { omit } from 'lodash';

import { IAction } from 'common/interfaces';

import { IMachineStatusData } from 'machines/interfaces';

interface IMachineStatusState {
  [machineId: string]: IMachineStatusData[]
}

const initialState: IMachineStatusState = {};

function machineStatusReducer (
  state: IMachineStatusState = initialState,
  action: IAction
): IMachineStatusState {
  const [ domain, request, result ] = action.type.split('/');

  if (domain === 'MACHINE_STATUS') {
    if (request.includes('GET_') && result === 'SUCCESS') {
      const machineIds = Object.keys(action.payload);
      const newState = { ...state };

      for (const machineId of machineIds) {
        newState[machineId] = {
          ...newState[machineId],
          ...action.payload[machineId]
        };
      }

      return newState;
    }
  }

  if (action.type === 'MACHINES/DELETE/SUCCESS') {
    return omit(state, [action.payload.id]);
  }

  return state;
}

export default machineStatusReducer;

import { MachineStoreType } from "containers/machinesV2/useMachinesStore";
import { fetcher } from "gql/fetcher";
import { GetMachinesDetailsForCsvDocument, GetMachinesDetailsForCsvQuery, GetMachinesDetailsForCsvQueryVariables } from "gql/generated";
import * as React from "react";
import { downloadCSVFromJSON } from "utils/csv";
import * as moment from "moment";
import { toasti18n } from "utils/toast";

export function useDownloadMachineDetailReport() {
  const [isLoading, setIsLoading] = React.useState(false);

  async function downloadMachineDetailReportCSV(selectedMachines: MachineStoreType["selectedMachine"]) {
    const machinesLocationQuery = await fetcher<
      GetMachinesDetailsForCsvQuery,
      GetMachinesDetailsForCsvQueryVariables
    >(GetMachinesDetailsForCsvDocument, { machinesId: selectedMachines })();
    const machinesData = machinesLocationQuery.machines;

    downloadCSVFromJSON(
      machinesData
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((machine) => ({
          "Machine ID": machine.id,
          "Location name": machine.location.locationName,
          "Location type": machine.location.locationType,
          Province: machine.location.province,
          Organisation: machine.organisation.name,
        })),
      `Machine Information ${moment().format("DD-MM-YYYY")}`
    );
  }

  async function downloadMachineDetailReport(electedMachines: MachineStoreType["selectedMachine"]) {
    try {
      setIsLoading(true);
      await toasti18n.promise(downloadMachineDetailReportCSV(electedMachines));
    } catch (error) {
      throw new Error("Error downloading machine detail report");
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, downloadMachineDetailReport };
}
import * as React from 'react';
import { useState, useEffect } from 'react';

import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Select from 'common/components/select/Select';
import Input from 'common/components/input/Input';
import { ToggleSwitch } from 'common/components/ToggleSwitch';

import { IMachineInventoryData } from 'machines/interfaces';

import * as styles from './EditMinMaxModal.module.scss';
import { usePatchMachineInventory } from 'machines/hooks/usePatchMachineInventory';

interface IEditMinMaxModalProps {
  data: IMachineInventoryData,
  onClose: () => void
}

function EditMinMaxModal ({
  data,
  onClose
}: IEditMinMaxModalProps): JSX.Element {
  const { isLoading: updateLoading, mutate: patchMachineInventory } = usePatchMachineInventory();

  const [ autoRefill, setAutoRefill ] = useState<boolean>(false);
  const [ min, setMin ] = useState<number>(null);
  const [ max, setMax ] = useState<number>(null);
  const [ capacity, setCapacity ] = useState<number>(null);
  const [ recommendRefillAbovePar, setRecommendRefillAbovePar ] = useState<boolean>(true);
  const [ noPartialRefill, setNoPartialRefill ] = useState<boolean>(false);
  const [ priority, setPriority ] = useState<string>('3');

  useEffect(() => {
    if (data) {
      setAutoRefill(data?.autoRefill || false);
      setMin(data?.parLevel || null);
      setMax(data?.refillLevel || null);
      setCapacity(data?.capacity || null);
      setRecommendRefillAbovePar(data?.recommendRefillAbovePar || false);
      setNoPartialRefill(data?.noPartialRefill || false);
      setPriority(String(data?.additional?.inventoryInfo?.importanceLevel) || '3');
    }
  }, [data]);

  function handleClose (): void {
    onClose();
    setTimeout(() => {
      setAutoRefill(false);
      setMin(null);
      setMax(null);
      setCapacity(null);
      setRecommendRefillAbovePar(true);
      setNoPartialRefill(false);
      setPriority('3');
    }, 100);
  }

  async function handleSubmit (): Promise<void> {
    patchMachineInventory(
      {
        machineId: data.machineId,
        machineInventories: [
          {
            autoRefill: autoRefill,
            id: data.id,
            parLevel: min,
            refillLevel: max,
            capacity,
            recommendRefillAbovePar,
            noPartialRefill,
            additional: {
              inventoryInfo: {
                importanceLevel: priority
              }
            }
          }
        ]
      },
      {
        onSuccess: handleClose
      }
    );
  }

  return (
    <Modal
      isOpen={!!data}
      onClose={handleClose}
    >
      <div className={styles.EditMinMaxModal}>
        <div className={styles.title}>
          <Typography
            type='headline-6'
            translationKey='action_update_min_max'
          />
          &nbsp;
          <Typography
            type='headline-6'
            text={`(${data?.slot}-${data?.inventory})`}
          />
        </div>
        <div className={styles.toggleSwitch}>
          <ToggleSwitch
            isOn={autoRefill}
            onClick={() => setAutoRefill(i => !i)}
          />
          <Typography
            translationKey='label_auto_refill_on'
            type='body-2'
            className={styles.label}
          />
        </div>
        <Input
          value={min}
          onChange={setMin}
          label='label_par_level'
          type='number'
          className={styles.field}
        />
        <Input
          value={max}
          onChange={setMax}
          label='label_refill_target_level'
          type='number'
          className={styles.field}
        />
        <Select
          value={priority}
          options={[ '0', '1', '2', '3', '4', '5' ].map(element => ({ label: element, value: element }))}
          label='label_priority'
          className={styles.field}
          onSelect={value => setPriority(value as string)}
        />
        <Input
          value={capacity}
          label='label_capacity'
          type='number'
          className={styles.field}
          onChange={(value) => setCapacity(value)}
        />
        <div className={styles.toggleSwitch}>
          <ToggleSwitch
            isOn={recommendRefillAbovePar}
            onClick={() => setRecommendRefillAbovePar(i => !i)}
          />
          <Typography
            translationKey='label_recommend_refill_above_par'
            type='body-2'
            className={styles.label}
          />
        </div>
        <div className={styles.toggleSwitch}>
          <ToggleSwitch
            isOn={noPartialRefill}
            onClick={() => setNoPartialRefill(i => !i)}
          />
          <Typography
            translationKey='label_no_partial_refill'
            type='body-2'
            className={styles.label}
          />
        </div>

        <div className={styles.actions}>
          <Button
            translationKey='action_cancel'
            onClick={handleClose}
            type='secondary'
          />
          <Button
            translationKey='action_update'
            onClick={handleSubmit}
            type='primary'
            disabled={!min || !max || min > max || max > capacity}
            loading={updateLoading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default EditMinMaxModal;

import { LocationKind } from "gql/generated";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Location } from "../types";


export function PageTitle({ location }: { location: Location; }) {
  const { t } = useTranslation();
  const translations = {
    [LocationKind.Factory]: t("label_factory"),
    [LocationKind.Store]: t("label_store"),
    [LocationKind.Location]: t("label_location"),
  };

  return (
    <h1
      className="flex-1 font-kanit text-h6 text-on-surface-high font-normal"
    >
      <span className="text-on-surface-medium">{`${translations[location.kind]}:`}</span> {location.locationInfo.friendlyId}
    </h1>
  );
}

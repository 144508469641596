import * as React from "react";
import * as moment from "moment";
import Select, { IOption } from "common/components/select/Select";
import useQuerySync from "common/hooks/useQuerySync";
import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import PureDateRangePicker from "common/components/daterangepicker/PureDateRangePicker";
import { UNIXTimeStampTodayInMS } from "common/utils/momentUtils";
import { TransactionGroupByErrorTable } from "./table";
import { mapDataToTable } from "./mapDataToTable";
import { useDailySalesGroupByErrorQuery } from "gql/generated";
import { toasti18n } from "utils/toast";
import { useTranslation } from "react-i18next";

type TransactionGroupByErrorProps = {
  name: string;
  machineId?: string;
  options: IOption[];
  onSelect: (value: string | boolean) => void
};

export function TransactionGroupByErrorPage({
  name,
  machineId,
  onSelect,
  options,
}: TransactionGroupByErrorProps) {
  const { t } = useTranslation("transaction", {
    keyPrefix: "transaction_group_by_non_page"
  });
  const { queryObject, setQueryObject } = useQuerySync(
    getDefaultQueryParamsForGroupBy("groupByError")
  );
  const [dateFrom, setDateFrom] = React.useState(
    queryObject?.from || +UNIXTimeStampTodayInMS({ timeOfDay: "dayStart" })
  );
  const [dateTo, setDateTo] = React.useState(
    queryObject?.to || +UNIXTimeStampTodayInMS({ timeOfDay: "dayEnd" })
  );

  // fetch data
  const { data, isLoading } = useDailySalesGroupByErrorQuery({
    filter: {
      date: {
        between: {
          from: queryObject.from
            ? moment(new Date(Number(queryObject.from))).format("YYYY-MM-DD")
            : null,
          to: queryObject.to
            ? moment(new Date(Number(queryObject.to))).format("YYYY-MM-DD")
            : null,
        },
      },
      machineId: machineId
    },
  });

  function onDatePickerBlur({
    startDate,
    endDate,
  }: {
    startDate: number;
    endDate: number;
  }) {
    const oneMonthInMs = 31 * 24 * 60 * 60 * 1000; // Approx. one month in milliseconds (31 days)

    // If startDate is greater than or equal to endDate, exit early
    if (startDate >= endDate) return;

    let adjustedEndDate = endDate;

    // Adjust endDate if the range exceeds one month
    if((endDate - startDate) > oneMonthInMs) {
      adjustedEndDate = startDate + oneMonthInMs;
      toasti18n.warning({ message: t("label_date_range_limit"), excludeDefaultTitle: true });
    }

    // Update the date range and query object
    setDateFrom(startDate);
    setDateTo(adjustedEndDate);
    setQueryObject((queryObject) => ({
      ...queryObject,
      from: startDate,
      to: adjustedEndDate,
    }));
  }

  const dailySalesData = React.useMemo(
    () =>
      mapDataToTable({
        data: data?.dailySalesGroupByError || [],
        from: Number(dateFrom),
        to: Number(dateTo),
      }),
    [data]
  );

  return (
    <>
      <div className="flex items-center mb-5">
        <div className="flex-1">
          <h1 className="text-h5 text-on-surface-high">{t("title")}</h1>
        </div>
        <div className="flex gap-3">
          <Select
            className="bg-background w-[326px]"
            label="label_group_by"
            value="groupByError"
            options={options}
            onSelect={onSelect}
          />
          <PureDateRangePicker
            key={`${dateFrom}-${dateTo}`}
            defaultStartDate={Number(dateFrom)}
            defaultEndDate={Number(dateTo)}
            onBlur={onDatePickerBlur}
          />
        </div>
      </div>
      <TransactionGroupByErrorTable
        dateRange={{
          from: Number(dateFrom),
          to: Number(dateTo),
        }}
        name={name}
        isLoading={isLoading}
        data={dailySalesData}
      />
    </>
  );
}

export const PRIORITY_ICON_LOOKUP: Record<string, { label: string; icon: string }> = {
  urgent: {
    label: "label_urgent",
    icon: "PriorityUrgent"
  },
  highest: {
    label: "label_highest",
    icon: "PriorityVeryHigh"
  },
  high: {
    label: "label_high",
    icon: "PriorityHigh"
  },
  medium: {
    label: "label_medium",
    icon: "PriorityMedium"
  },
  low: {
    label: "label_low",
    icon: "PriorityLow"
  },
  lowest: {
    label: "label_lowest",
    icon: "PriorityLowest"
  }
};
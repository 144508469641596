import {
  NumberCell,
  StringCell,
  DateCell,
  InlineStatusCell,
  CapitalCell,
  CurrencyCell,
  CurrencyCellWithoutSymbol
} from 'common/components/table/cells/Cells';
import { StringFillBackgroundCell } from 'common/components/table/cells/StringFillBackgroundCell/StringFillBackgroundCell';

import { IColumn } from 'common/components/table/Table';
import { SparkLineCell } from 'transactions/components/molecules/SparkLineCell';
import * as styles from './columnClasses.module.scss';

const tableColumnsConfigGroupByNone: IColumn[] = [
  {
    dataKey: 'machineId',
    headerLabel: 'label_machine_id',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnWidth: 85,
    minWidth: 85,
    showMobile: true
  },
  {
    dataKey: 'locationName',
    headerLabel: 'label_location_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 200,
    minWidth: 200,
    showMobile: true
  },
  {
    dataKey: 'time',
    headerLabel: 'label_time',
    cellRenderer: DateCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  },
  {
    dataKey: 'productCode',
    headerLabel: 'label_drink_code',
    cellRenderer: StringCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 110,
    minWidth: 110,
  },
  {
    dataKey: 'productName',
    headerLabel: 'label_drink_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    showMobile: true,
    columnWidth: 120,
    minWidth: 120,
  },
  {
    headerLabel: 'label_status',
    cellRenderer: InlineStatusCell({
      idKey: 'status'
    }),
    align: 'flex-start',
    showMobile: true,
    columnWidth: 74,
    minWidth: 74,
  },
  {
    dataKey: 'price',
    headerLabel: 'label_paid',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    showMobile: true,
    columnWidth: 65,
    minWidth: 65,
  },
  {
    dataKey: 'promotionDiscount',
    headerLabel: 'label_promotion',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    showMobile: true,
    columnWidth: 101,
    minWidth: 101,
  },
  {
    dataKey: 'paymentType',
    headerLabel: 'label_payment',
    cellRenderer: CapitalCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  },
  {
    dataKey: 'telephoneNumber',
    headerLabel: 'label_user',
    cellRenderer: StringCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  }
];

const tableColumnsConfigGroupByProductName: IColumn[] = [
  {
    dataKey: 'productCode',
    headerLabel: 'label_drink_code',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnWidth: 100,
    minWidth: 100,
    showMobile: true,
  },
  {
    dataKey: 'productName',
    headerLabel: 'label_drink_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 120,
    minWidth: 120,
    showMobile: true
  },
  {
    dataKey: 'averagePrice',
    headerLabel: 'label_average_price',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 80,
    minWidth: 80,
    showMobile: true,
  },
  {
    dataKey: 'minimumPrice',
    headerLabel: 'label_min_price',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 90,
    minWidth: 90,
    showMobile: true,
  },
  {
    dataKey: 'maximumPrice',
    headerLabel: 'label_max_price',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 95,
    minWidth: 95,
    showMobile: true,
  },
  {
    dataKey: 'totalPaid',
    headerLabel: 'label_total',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 70,
    minWidth: 70,
    showMobile: true
  },
  {
    dataKey: 'totalSales',
    headerLabel: 'label_total_sales',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    columnWidth: 70,
    minWidth: 70,
    showMobile: true
  }
];

const tableColumnsConfigGroupByTelephoneNumber: IColumn[] = [
  {
    dataKey: 'telephoneNumber',
    headerLabel: 'label_user',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 110,
    minWidth: 110,
    showMobile: true
  },
  {
    dataKey: 'totalPaid',
    headerLabel: 'label_total_paid',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 70,
    minWidth: 70,
    showMobile: true
  },
  {
    dataKey: 'totalSales',
    headerLabel: 'label_total_sales',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 70,
    minWidth: 70,
    showMobile: true
  },
  {
    dataKey: 'totalPromotion',
    headerLabel: 'label_total_promotion',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 95,
    minWidth: 95,
    showMobile: true
  }
];

const tableColumnsConfigGroupByStatus: IColumn[] = [
  {
    headerLabel: 'label_status',
    cellRenderer: InlineStatusCell({
      idKey: 'status'
    }),
    align: 'flex-start',
    minWidth: 50,
    columnWidth: 50,
    columnFlex: 1,
    showMobile: true,
  },
  {
    dataKey: 'totalPaid',
    headerLabel: 'label_total_paid',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    minWidth: 50,
    columnWidth: 50,
    columnFlex: 1,
    showMobile: true
  },
  {
    dataKey: 'totalCups',
    headerLabel: 'label_total_cups',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    minWidth: 50,
    columnWidth: 50,
    columnFlex: 1,
    showMobile: true
  }
];

const tableColumnsConfigGroupByMachineId: IColumn[] = [
  {
    dataKey: 'machineId',
    headerLabel: 'label_machine_id',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnWidth: 100,
    minWidth: 100,
    showMobile: true
  },
  {
    dataKey: 'machineName',
    headerLabel: 'label_machine_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    columnWidth: 200,
    minWidth: 200,
    showMobile: true
  },
  {
    dataKey: 'zone',
    headerLabel: 'label_zone',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnWidth: 70,
    minWidth: 70,
    showMobile: true
  },
  {
    dataKey: 'totalPaid',
    headerLabel: 'label_total_paid',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 80,
    minWidth: 80,
    showMobile: true
  },
  {
    dataKey: 'totalSales',
    headerLabel: 'label_total_sales',
    cellRenderer: NumberCell(),
    align: 'flex-start',
    columnWidth: 75,
    minWidth: 75,
    showMobile: true
  },
  {
    dataKey: 'totalPromotion',
    headerLabel: 'label_total_promotion',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    columnWidth: 100,
    minWidth: 100,
    showMobile: true
  },
  {
    dataKey: 'locationType',
    headerLabel: 'label_location_type',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnWidth: 100,
    minWidth: 100,
    showMobile: true
  }
];

const tableColumnsConfigGroupBydailyMachineSales: IColumn[] = [
  {
    dataKey: 'machineId',
    headerLabel: 'label_machine_id',
    cellRenderer: StringCell,
    align: 'flex-start',
    minWidth: 73,
    columnWidth: 73,
    showMobile: true,
    sortable: true,
  },
  {
    dataKey: 'name',
    headerLabel: 'label_machine_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    minWidth: 200,
    columnWidth: 200,
    showMobile: true
  },
  {
    dataKey: 'orgName',
    headerLabel: 'label_org',
    cellRenderer: StringCell,
    align: 'flex-start',
    showMobile: true,
    sortable: true,
    minWidth: 65,
    columnWidth: 65
  },
  {
    dataKey: 'expense',
    headerLabel: 'label_expense',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    showMobile: true,
    sortable: true,
    minWidth: 85,
    columnWidth: 85,
    className: styles.ExpenseCell
  },
  {
    dataKey: '0',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '1',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '2',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '3',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '4',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '5',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '6',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '7',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '8',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '9',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '10',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '11',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: '12',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 40,
    columnWidth: 40,
    showMobile: true
  },
  {
    dataKey: 'expected',
    headerLabel: 'label_expected',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 85,
    columnWidth: 85,
    showMobile: true,
    sortable: true,
    className: styles.ExpectedCell
  },
  {
    dataKey: '13',
    cellRenderer: CurrencyCellWithoutSymbol,
    align: 'flex-start',
    minWidth: 85,
    columnWidth: 85,
    showMobile: true,
    sortable: true,
  },
  {
    headerLabel: 'label_diff',
    dataKey: 'diff.table',
    cellRenderer: StringFillBackgroundCell({className: styles.DiffCell}),
    align: 'flex-start',
    minWidth: 50,
    columnWidth: 50,
    showMobile: true,
    sortable: true,
    sortingFn: (a: any, b: any) => a.diff.value - b.diff.value
  }
];

const tableColumnsConfigGroupByweeklyMachineSales: IColumn[] = [
  {
    dataKey: 'machineId',
    headerLabel: 'label_machine_id',
    cellRenderer: StringCell,
    align: 'flex-start',
    minWidth: 80,
    columnWidth: 80,
    showMobile: true,
    sortable: true
  },
  {
    dataKey: 'name',
    headerLabel: 'label_machine_name',
    cellRenderer: StringCell,
    align: 'flex-start',
    columnFlex: 1,
    minWidth: 200,
    columnWidth: 200,
    showMobile: true,
    sortable: true
  },
  {
    dataKey: 'organisation',
    headerLabel: 'label_org',
    cellRenderer: StringCell,
    align: 'flex-start',
    minWidth: 70,
    columnWidth: 70,
    sortable: true,
    showMobile: true
  },
  {
    dataKey: 'expense',
    headerLabel: 'label_expense',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    sortable: true,
    minWidth: 77,
    columnWidth: 77,
    showMobile: true,
  },
  {
    dataKey: '0',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '1',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '2',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '3',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '4',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '5',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 57,
    minWidth: 57,
  },
  {
    dataKey: '6',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    minWidth: 57,
    columnWidth: 57
  },
  {
    dataKey: '7',
    cellRenderer: CurrencyCell,
    align: 'flex-start',
    showMobile: true,
    minWidth: 57,
    columnWidth: 57
  },
  {
    dataKey: 'trend',
    headerLabel: 'label_trend',
    cellRenderer: SparkLineCell,
    align: 'flex-start',
    showMobile: true,
    columnWidth: 192,
    minWidth: 192,
    sortable: true,
    sortingFn: trendSortingFunction
  }
];

function trendSortingFunction (rowA, rowB) {
  const percentComparisonResult = rowA.trend.percentage - rowB.trend.percentage;
  if (percentComparisonResult !== 0) {
    return -percentComparisonResult;
  }

  if (percentComparisonResult) {
    rowA.machineId.localeCompare(rowB.machineId);
  }
  return -1 * (rowA.trend.percent - rowB.trend.percent);
}

const tableColumnsConfigGroupByCodeId: IColumn[] = [
  {
    headerLabel: 'label_code_id',
    dataKey: 'codeId',
    columnWidth:100,
    minWidth: 100,
    cellRenderer: StringCell,
  },
  {
    headerLabel: 'label_code_description',
    dataKey: 'codeDescription',
    columnFlex:1,
    minWidth: 120,
    cellRenderer: StringCell,
  },
  {
    headerLabel: 'label_total_baht',
    dataKey: 'totalSales',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: CurrencyCell,
  },
  {
    headerLabel: 'label_total_cups',
    dataKey: 'totalCups',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: StringCell,
  },
  {
    headerLabel: 'label_machine',
    dataKey: 'machines',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: StringCell,
  }
];

const tableColumnsConfigGroupByCodeType: IColumn[] = [
  {
    headerLabel: 'label_code_type',
    dataKey: 'codeType',
    columnFlex:1,
    minWidth: 100,
    cellRenderer: StringCell,
  },
  {
    headerLabel: 'label_total_baht',
    dataKey: 'totalServiceSale',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: CurrencyCell,
  },
  {
    headerLabel: 'label_total_cups',
    dataKey: 'totalServiceSaleCount',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: StringCell,
  },
];
const tableColumnsConfigGroupByLocationId: IColumn[] = [
  {
    headerLabel: 'label_location_id',
    dataKey: 'friendlyId',
    columnWidth:90,
    minWidth: 90,
    cellRenderer: StringCell,
    showMobile: true,
    sortable: true,
  },
  {
    headerLabel: 'label_location_name',
    dataKey: 'locationName',
    columnFlex:1,
    minWidth: 100,
    cellRenderer: StringCell,
    showMobile: true,
    sortable: true,
  },
  {
    headerLabel: 'label_total_sales',
    dataKey: 'totalServiceSale',
    columnWidth: 100,
    minWidth: 100,
    align:"flex-start",
    cellRenderer: CurrencyCell,
    showMobile: true,
    sortable: true,
  },
  {
    headerLabel: 'label_total_unit_of_sales',
    dataKey: 'totalServiceSaleCount',
    columnWidth: 100,
    minWidth: 100,
    align:"flex-start",
    cellRenderer: StringCell,
    showMobile: true,
    sortable: true,
  },
  {
    headerLabel: 'label_total_promotion',
    dataKey: 'totalCampaignDiscount',
    columnWidth: 100,
    minWidth: 100,
    align:"flex-start",
    cellRenderer: CurrencyCell,
    showMobile: true,
    sortable: true,
  }
];
const tableColumnsConfigGroupByLocationType: IColumn[] = [
  {
    headerLabel: 'label_location_type',
    dataKey: 'locationType',
    columnFlex:1,
    minWidth: 100,
    cellRenderer: StringCell,
    showMobile: true,
  },
  {
    headerLabel: 'label_number_machine',
    dataKey: 'numberMachine',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: StringCell,
    showMobile: true,
  },
  {
    headerLabel: 'label_average_sell_per_machine',
    dataKey: 'averageSellPerMachine',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: CurrencyCell,
    showMobile: true,
  },
  {
    headerLabel: 'label_total_sales',
    dataKey: 'totalSell',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: CurrencyCell,
    showMobile: true,
  },
  {
    headerLabel: 'label_total_unit_of_sell',
    dataKey: 'totalUnitOfSell',
    columnWidth: 100,
    minWidth: 100,
    cellRenderer: StringCell,
    showMobile: true,
  }
];
const salesTableColumnsConfig = {
  none: tableColumnsConfigGroupByNone,
  productName: tableColumnsConfigGroupByProductName,
  telephoneNumber: tableColumnsConfigGroupByTelephoneNumber,
  status: tableColumnsConfigGroupByStatus,
  machineId: tableColumnsConfigGroupByMachineId,
  dailyMachineSales: tableColumnsConfigGroupBydailyMachineSales,
  weeklyMachineSales: tableColumnsConfigGroupByweeklyMachineSales,
  codeId: tableColumnsConfigGroupByCodeId,
  codeType: tableColumnsConfigGroupByCodeType,
  locationId: tableColumnsConfigGroupByLocationId,
  locationType: tableColumnsConfigGroupByLocationType,
};

export default salesTableColumnsConfig;

import * as React from 'react';
import { useRef } from 'react';

// Interfaces
import { IMachineInventoryData } from 'machines/interfaces';

// Custom components
import Typography from 'common/components/typography/Typography';
import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';

// Styles
import * as styles from './DeleteSlotConfirmationModal.module.scss';
import { useDeleteMachineInventory } from 'machines/hooks/useDeleteMachineInventory';

interface IDeleteSlotConfirmationModalProps {
  inventoryData: IMachineInventoryData;
  onClose: () => void;
}
export default function DeleteSlotConfirmationModal ({
  inventoryData,
  onClose
}: IDeleteSlotConfirmationModalProps): JSX.Element {
  const inventoryDataReference = useRef<IMachineInventoryData>({
    ...inventoryData
  }).current;

  const { mutate: deleteSlot } = useDeleteMachineInventory();

  async function onConfirmDelete () {
    deleteSlot(
      {
        machineId: inventoryDataReference.machineId,
        machineInventoryId: inventoryDataReference.id
      },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  }

  return (
    <Modal contentClassName={styles.DeleteSlotConfirmationModal}>
      {/* Header - Action (Delete slot) and slot number */}
      <header className={styles.header}>
        <Typography type="headline-6" translationKey="action_delete_slot" />
        <Typography type="body-1" text="&nbsp;" />
        <Typography type="headline-6" text={inventoryDataReference.slot} />
      </header>

      {/* Confirmation string */}
      <div className={styles.confirmationStringContainer}>
        <Typography
          type="body-1"
          translationKey={'label_delete_slot_confirmation'}
        />
        <Typography type="body-1" text="&nbsp;" />
        <Typography
          type="body-1"
          text={`${inventoryDataReference.slot} - ${inventoryDataReference.inventory}?`}
        />
      </div>

      {/* Cancel and confirm buttons */}
      <div className={styles.cancelAndConfirmButtonsContainer}>
        <Button
          translationKey="action_cancel"
          onClick={onClose}
          type="secondary"
        />
        <Button
          translationKey="action_delete_slot"
          onClick={onConfirmDelete}
          type="status"
        />
      </div>
    </Modal>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Card--1dz-j64gSG{box-sizing:border-box;width:360px;height:240px;padding:24px;border-radius:8px;background-color:#fff;display:flex;flex-direction:column}", ""]);
// Exports
exports.locals = {
	"Card": "Card--1dz-j64gSG"
};
module.exports = exports;

import { IUser } from "userandauth/interfacesUser";
import * as Sentry from "@sentry/react";
import { createUserProperties } from "./userTracking";


export async function setSentryUser (userInfo: IUser) {
  const user = createUserProperties(userInfo);
  Sentry.setUser({
    username: user?.properties?.email,
    id: user?.userId,
    email: user?.properties?.email,
    ...user.properties
  });
  Sentry.setContext("User Properties",{
    ...user.properties
  });
}
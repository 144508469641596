import * as React from "react";

import { useGetWarehouseLotsTransfer } from "reactquery/lots/useGetWarehouseLotsTransfer";
import { useGetOrganisations } from "userandauth/hooks/useGetOrganisations";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { useGetWarehouses } from "warehousing/hooks/useGetWarehouses";

import { TransferInventorySheet } from "../components/organisms/TransferInventorySheet";

import { downloadPdf } from "pdf/utils/downloadPdf";
import { useGetWarehouseProducts } from "product/hooks/useGetWarehouseProducts";

interface useDownloadTransferInventoryPDFProps {
  warehouseId: string;
  transferId: string;
}

interface useDownloadTransferInventoryReturnType {
  downloadPDF: () => Promise<void> | undefined;
  isReady: boolean;
}

function useDownloadTransferInventoryPDF({
  warehouseId,
  transferId,
}: useDownloadTransferInventoryPDFProps): useDownloadTransferInventoryReturnType {
  const {
    data: warehouseLotsTransfer,
    isLoading: isWarehouseTransferLotsLoading,
  } = useGetWarehouseLotsTransfer(warehouseId);
  const { data: warehouses, isLoading: isWarehousesLoading } =
    useGetWarehouses();
  const { data: user, isLoading: isUserDataLoading } = useGetCurrentUser();
  const { data: organisation, isLoading: isOrganisationLoading } =
    useGetOrganisations();

  const {
    data: fromWarehouseProducts,
    isLoading: isFromWarehouseProductsLoading,
  } = useGetWarehouseProducts(
    warehouseLotsTransfer?.find((transfer) => transfer.id === transferId)
      .fromWarehouseId,
    { enabled: !isWarehouseTransferLotsLoading }
  );

  if (
    [
      isWarehouseTransferLotsLoading,
      isWarehousesLoading,
      isUserDataLoading,
      isOrganisationLoading,
      isFromWarehouseProductsLoading,
    ].some((loadingState) => loadingState === true)
  ) {
    return { downloadPDF: undefined, isReady: false };
  }

  const selectedTransfer = warehouseLotsTransfer.find(
    (transfer) => transfer.id === transferId
  );

  const fromWarehouse = warehouses.find(
    (warehouse) => selectedTransfer.fromWarehouseId === warehouse.warehouseId
  );
  const toWarehouse = warehouses.find(
    (warehouse) => selectedTransfer.toWarehouseId === warehouse.warehouseId
  );

  const orgInfo = organisation.find((org) => org.id === user.orgId);

  return {
    downloadPDF: () =>
      downloadPdf(
        <TransferInventorySheet
          transferSheetData={{
            transferId: transferId,
            fromWarehouse: {
              name: fromWarehouse.name,
              address: fromWarehouse.address ?? undefined,
              contact: "-",
              email: "-",
              tel: "-",
            },
            toWarehouse: {
              name: toWarehouse.name,
              address: toWarehouse.address ?? undefined,
              contact: "-",
              email: "-",
              tel: "-",
            },
            organizationInfo: {
              name: orgInfo.name,
              address: orgInfo.address ?? undefined,
              img: orgInfo.logoUrl ?? undefined,
              taxId: "-",
              tel: "-",
            },
            transferInventoryData:
              selectedTransfer.payload.incomingInventory.map((inventory) => {
                const productInfo = fromWarehouseProducts.find(
                  (product) => product.productId === inventory.productId
                );
                return {
                  id: inventory["UPC"], // Type not managed by backend
                  name: inventory["productName"], // Type not managed by backend
                  pricePerUnit: "-",
                  unit: `${productInfo.packageType} (${productInfo.uom})`,
                  quantity: String(inventory.quantity),
                };
              }),
          }}
        />,
        `Transfer inventory ${transferId}`
      ),
    isReady: true,
  };
}

export { useDownloadTransferInventoryPDF };

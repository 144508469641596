import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";
import * as React from "react";
import { useTranslation } from "react-i18next";
export function TextFieldWithLabel ({ label, value, collapsible = false, required }: { label: string, value: string | React.ReactNode, collapsible?: boolean, required?: boolean }) {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = React.useState(collapsible);
  return (
    <>
      <p className={clsx("flex items-center gap-1 text-body3 text-on-surface-medium", collapsible && "hover:font-medium cursor-pointer")} onClick={() => collapsible && setIsCollapsed(!isCollapsed)}>
        {t(label)}
        {required && <span className="text-error">*</span>}
        {collapsible && <Icon name="Chevron" color="onSurfaceHigh" className={clsx("w-3 h-3", isCollapsed ? "transform duration-100 -rotate-90" : "rotate-90")} />}
      </p>
      {isCollapsed ? collapsible ? null : "-" : value}
    </>
  );
}

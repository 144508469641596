export const SERVICE_ZONE_QUERY_PARAM = 'id';

export const routes = {
  index: () => '/maintenance',

  errorBoard: () => '/maintenance/error-board',

  majorTrouble: {
    index: () => routes.errorBoard() + '/major-trouble',
    machineUnsellable: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/machine-unsellable' +
      getServiceZoneQueryParam(serviceZoneId),

    outOfService: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/out-of-service' +
      getServiceZoneQueryParam(serviceZoneId),

    offline: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/offline' +
      getServiceZoneQueryParam(serviceZoneId),

    iceError: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/ice-error' +
      getServiceZoneQueryParam(serviceZoneId),

    coffeeError: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/coffee-error' +
      getServiceZoneQueryParam(serviceZoneId),

    iceRateLow: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/ice-rate-low' +
      getServiceZoneQueryParam(serviceZoneId),

    sodaError: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/soda-error' +
      getServiceZoneQueryParam(serviceZoneId),

    whipper: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/whipper' +
      getServiceZoneQueryParam(serviceZoneId),

    spiralUnsellable: (serviceZoneId?: string | string[]) =>
      routes.majorTrouble.index() +
      '/spiral-unsellable' +
      getServiceZoneQueryParam(serviceZoneId),
  },

  inventory: {
    index: () => routes.errorBoard() + '/inventory',

    milk: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/milk' +
      getServiceZoneQueryParam(serviceZoneId),

    sugar: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/sugar' +
      getServiceZoneQueryParam(serviceZoneId),

    lid: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/lid' +
      getServiceZoneQueryParam(serviceZoneId),

    other: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/other' +
      getServiceZoneQueryParam(serviceZoneId),

    coffeeBeans: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/coffee-beans' +
      getServiceZoneQueryParam(serviceZoneId),

    plasticCups: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/plastic-cups' +
      getServiceZoneQueryParam(serviceZoneId),

    paperCups: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/paper-cups' +
      getServiceZoneQueryParam(serviceZoneId),

    espressoCups: (serviceZoneId?: string | string[]) =>
      routes.inventory.index() +
      '/espresso-cups' +
      getServiceZoneQueryParam(serviceZoneId),
  },

  transactions: {
    index: () => routes.errorBoard() + '/transactions',
    aaNoTransaction30Mins: (serviceZoneId?: string | string[]) =>
      routes.transactions.index() +
      '/aa-no-transaction-30-mins' +
      getServiceZoneQueryParam(serviceZoneId),

    noTransaction2Hours: (serviceZoneId?: string | string[]) =>
      routes.transactions.index() +
      '/no-transaction-2-hours' +
      getServiceZoneQueryParam(serviceZoneId),

    noTransactionToday: (serviceZoneId?: string | string[]) =>
      routes.transactions.index() +
      '/no-transaction-today' +
      getServiceZoneQueryParam(serviceZoneId),

    billAndCoin: (serviceZoneId?: string | string[]) =>
      routes.transactions.index() +
      '/bill-and-coin' +
      getServiceZoneQueryParam(serviceZoneId),
  },
};

function getServiceZoneQueryParam(serviceZoneId?: string | string[]) {
  if (!serviceZoneId) return '';

  if (typeof serviceZoneId === 'string')
    return `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}`;

  if (serviceZoneId.length === 0) return '';
  const urlParams = new URLSearchParams();
  serviceZoneId.forEach((id) => urlParams.append(SERVICE_ZONE_QUERY_PARAM, id));
  return `?${urlParams.toString()}`;
}

import * as React from "react";

import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";

import * as styles from "./StatusChangeOverlay.module.scss";

function StatusChangeOverlay() {
  return (
    <div className={styles.Overlay}>
      <div className={styles.Content}>
        <div className={styles.IconWrapper}>
          <Icon name="Info" color="primary500" />
        </div>
        <div className={styles.Info}>
          <Typography type="body-3" translate>
            label_the_ticket_status_has_been_changed
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default StatusChangeOverlay;

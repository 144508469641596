import * as React from "react";
import { GetOrganisationsQuery, useGetOrganisationsQuery } from "gql/generated";
import Autocomplete, {
  AutocompleteOption,
  IAutocompleteProps,
} from "components/Autocomplete/Autocomplete";
import { queryClient } from "index";

type OrganisationIdFormProps = {
  onChange: (value: string) => void;
  value: string;
  fallBackOptions?: Array<AutocompleteOption>;
} & Omit<IAutocompleteProps, "options" | "label" | "id">;

const useGetOrganisationsQueryQueryKey = ["GetOrganisations"];
function getOrganisationNameById(id: string) {
  const cachedOrganisation = queryClient.getQueryData<GetOrganisationsQuery>(
    useGetOrganisationsQueryQueryKey
  );
  if (!cachedOrganisation) return "";
  const organisation = cachedOrganisation.organisations.find(
    (organisation) => organisation.id === id
  );
  if (!organisation) return "";
  return organisation.name;
}

export const organisationIdCache = {
  getOrganisationNameById,
};

export function OrganisationIdAutoComplete({
  value,
  onChange,
  fallBackOptions = [],
  ...props
}: OrganisationIdFormProps) {
  const getOrganisationsQueryResult = useGetOrganisationsQuery();

  const organisations = getOrganisationsQueryResult.data?.organisations;
  const optionsFromQuery =
    organisations
      ?.map((organisation) => ({
        label: organisation.name,
        value: organisation.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const options = organisations ? optionsFromQuery : fallBackOptions;

  return (
    <Autocomplete
      loading={getOrganisationsQueryResult.isLoading}
      label="label_organisation"
      id="organisation"
      disableClearable
      value={value}
      onChange={onChange}
      options={options}
      {...props}
    />
  );
}

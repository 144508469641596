import * as React from 'react';
import Typography from 'components/Typography';

export function TableHeading({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  return (
    <Typography type="caption" translate color="onSurfaceMedium">
      {children}
    </Typography>
  );
}

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import withSuspense from 'common/hoc/withSuspense';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

import * as styles from './Select.module.scss';

type IValue = string | boolean;
export interface IOption {
  label: string,
  value: IValue,
  hidden?: boolean
}

export interface ISelectProps {
  options: Array<IOption>;
  value: IValue;
  onSelect: (value: IValue) => void;
  label?: string;
  openDropdown?: boolean;
  className?: string;
  renderStyle?: 'box' | 'nobox';
  searchable?: boolean;
  required?: boolean;
  disabled?: boolean;
}

/**
 * Component to render a select drop down
 * @param ISelectProps
 * @param ISelectProps.options - Array of options object `[{label: string, value: string | boolean}, ...]`
 * @param ISelectProps.value - Currently selected value
 * @param ISelectProps.onSelect - () => onSelect(option.value) when option selected
 * @param ISelectProps.label - Label that will be displayed on top of select box. This is not the label associated with each option
 * @param ISelectProps.openDropDown - Boolean prop to open / close drop down
 * @param ISelectProps.className - Class name passed to select container
 * @param ISelectProps.renderStyle - Controls how select dropdown looks `"box" | "nobox"`
 * @param ISelectProps.searchable - Boolean flag to control whether slot is searchable
 * @remarks The select button has a local `search` state which is what's being displayed in the select box. The `search` state is also kept in sync with the `ISelectProps.value` that is being passed in. When an option is selected (via clicking or autocompletion)
 * 1) The `onSelect` function runs with `option.value`. The `onSelect` usually updates the state that is associated with the value prop in the parent component.
 * 2) The drop down is closed, which causes the local `search` state to be synced with the value being passed in. This is done through a `useEffect` which updates the `search` state to the corresponding `label` of the selected option when the drop down closes. `setSearch(selectedLabel)` given that `{label: selectedLabel, value: selectedValue}`.
 *
 * When value is `undefined | null | ''`, the `ISelectProps.label` will be used as the text to display and `search` will be set to `null`
 *
 * Currently, if `ISelectProps.searchable` is true, the dropdown will only show possible auto complete options else it will show all the options
 */
function Select({
  options,
  value,
  onSelect,
  label,
  openDropdown,
  className,
  renderStyle = 'box',
  searchable = false,
  required = false,
  disabled = false,
}: ISelectProps): JSX.Element {
  const { t } = useTranslation();
  const dropdownNode = useRef(null);

  const [search, setSearch] = useState<string>(value as string);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Open / close dropdown depending on openDropDown prop
  useEffect(() => {
    if (openDropdown) {
      setIsOpen(true);
    }
  }, [openDropdown]);

  // When dropdown transition from open to close
  // 1) If no value, set search field empty
  // 2) If there's a value, set search field to the label of the corresponding option
  // Search is variable only used when select box is searchable - Looks up what to display in the input box
  useEffect(() => {
    if (!isOpen) {
      if (value === null || value === undefined || value === '') {
        setSearch(null);
      } else {
        const currentlySelectedOption = options.find(
          (option) => option.value === value
        );
        if (currentlySelectedOption !== undefined) {
          setSearch(currentlySelectedOption.label);
        } else {
          setSearch(String(value));
        }
      }
    }
  }, [search, value, isOpen]);

  // Call onSelect with corresponding option value and close the drop down when option selected
  function handleSelectOption(option: IOption): void {
    onSelect(option.value);
    setIsOpen(false);
  }

  // Open / close dropdown when drop down clicked
  function handleFieldClick(): void {
    setIsOpen((open) => !open);
  }

  let selectedOption = options.find((i) => i.value === value);
  if(selectedOption === undefined){
    if(value !== '' && value !== undefined && value !== null ) {
      selectedOption = {
        label: String(value),
        value: value
      };
    } else {
      selectedOption = {
        label: undefined,
        value: value
      };
    }
  }
  

  const dropdownOptions = options
    .filter((option) => {
      if (searchable && search) {
        if (
          (t(option.label).toLowerCase() as string).includes(
            t(search).toLowerCase()
          )
        ) {
          return true;
        }
        return false;
      }
      return true;
    })
    .filter(option => option.hidden !== undefined ? !option.hidden : true)
    .map((option, index) => {
      return (
        <div
          key={index}
          onClick={() => handleSelectOption(option)}
          className={[
            styles.option,
            option.value === selectedOption.value ? styles.selected : '',
          ].join(' ')}
        >
          <Typography translationKey={option.label} type='body-1' />
        </div>
      );
    });

  // Get all autocomplete options using the text from the input field
  function getAutoCompleteOptions(input, options) {
    return options.filter((option) => {
      return t(option.label).toLowerCase().includes(t(input).toLowerCase());
    });
  }

  // Get the exact option that matches the input field
  // Returns option if found otherwise returns undefined
  function getExactOption(input, options) {
    return options.filter(
      (option) => t(option.label).toLowerCase() === t(input).toLowerCase()
    )[0];
  }

  function blurInputHandler(e) {
    // If one of the options clicked, do nothing
    // Clicking option handled in `handleSelectOption`
    if (dropdownNode.current.contains(e.relatedTarget)) {
      return;
    }

    // If search is empty on blur / tab, close drop down
    if (search === null || search === undefined || search === '') {
      setIsOpen(false);
      return;
    }

    // -- Auto complete logic --

    // Get all auto complete options
    const autoCompleteOptions = getAutoCompleteOptions(search, options);

    if (autoCompleteOptions.length === 1) {
      // If only one possible option, set it to that option on blur
      handleSelectOption(autoCompleteOptions[0]);
    } else if (autoCompleteOptions.length > 1) {
      // If more than one option, find the exact option
      const exactOption = getExactOption(search, autoCompleteOptions);

      if (exactOption === undefined) {
        // If no exact option found, close the drop down
        setIsOpen(false);
      } else {
        handleSelectOption(exactOption);
      }
    } else {
      // If no option, close drop down
      setIsOpen(false);
    }
  }

  return (
    <div ref={dropdownNode} className={[styles.Select, className].join(' ')}>
      {/* Show the label when an option is selected */}
      {label && (
        <Typography
          type='caption'
          translationKey={label}
          className={[
            styles.label,
            (searchable && search) || selectedOption.label ? styles.show : '',
          ].join(' ')}
        />
      )}

      <div
        onClick={disabled ? () => {} : handleFieldClick}
        className={[styles.input, styles[renderStyle]].join(' ')}
      >
        <div
          className={[
            styles.value,
            !selectedOption.label ? styles.placeholder : '',
          ].join(' ')}
        >
          {searchable && (
            <input
              value={t(search) as string}
              onChange={(e) => {
                setIsOpen(true);
                setSearch(e.target.value);
              }}
              placeholder={t(label)}
              className={styles.nativeInput}
              onBlur={blurInputHandler}
            />
          )}
          {!searchable && !!selectedOption.label && (
            <Typography translationKey={selectedOption.label} type='body-1' />
          )}
          {!searchable && !selectedOption.label && !required && (
            <Typography translationKey={label} type='body-1' />
          )}
          {!searchable && !selectedOption.label && required && (
            <>
              <Typography translationKey={label} type='body-1' />
              <Typography text='*' type='body-1' />
            </>
          )}
        </div>
        <Icon
          className={[styles.chevron, isOpen ? styles.open : ''].join(' ')}
          name='Chevron'
          color='onSurfaceHigh'
        />
      </div>

      <div
        className={[
          styles.dropdown,
          styles[renderStyle],
          isOpen ? styles.open : '',
        ].join(' ')}
        tabIndex={-1}
      >
        {!!dropdownOptions.length && dropdownOptions}
        {!dropdownOptions.length && (
          <div
            key='no-option'
            className={[styles.option, styles.nooption].join(' ')}
          >
            <Typography translationKey='label_no_options' type='body-1' />
          </div>
        )}
      </div>
    </div>
  );
}

export default withSuspense(Select);

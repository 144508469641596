import * as React from "react";
import { contractRoutes } from "containers/contract/routes";
import { Link } from "react-router-dom";
import * as styles from "./LocationCardLink.module.scss";
import { LocationCard } from "../../components/contract/LocationCard";
import { RemoveLocationFromContractButton } from "./RemoveLocationFromContractButton";

export function LocationCardLink({
  location,
  contract,
}: {
  location: {
    id: string;
    friendlyId: string;
    machineId: string | null;
  };
  contract: {
    id: string;
    friendlyId: string;
  };
}) {
  return (
    <div
      style={{
        position: "relative",
      }}
      className={styles.HoverHighlight}
    >
      <Link to={contractRoutes.locationId(contract.id, location.id)}>
        <LocationCard
          locationFriendlyId={location.friendlyId}
          machineId={location.machineId}
          className={styles.HoverCard}
        />
      </Link>
      <div
        style={{
          position: "absolute",
          bottom: "100%",
          left: "100%",
          transform: "translate(-50%, 50%)",
        }}
        className={styles.CloseButton}
      >
        <RemoveLocationFromContractButton
          contractId={contract.id}
          locationId={location.id}
          contractFriendlyId={contract.friendlyId}
        />
      </div>
    </div>
  );
}

import { fetcher } from "gql/fetcher";
import {
  GetContractByIdDocument,
  GetContractByIdQuery,
  GetContractByIdQueryVariables,
  GetMachineIdByLocationIdsDocument,
  GetMachineIdByLocationIdsQuery,
  GetMachineIdByLocationIdsQueryVariables,
} from "gql/generated";
import { queryClient } from "index";
import * as moment from "moment";
import { useQuery } from "react-query";

type GetContractByIdOptions = GetContractByIdQueryVariables;

export function useGetContractById(
  id: string,
  options?: GetContractByIdOptions
) {
  return useQuery({
    queryFn: () => getContractById(id, options),
    queryKey: getQueryKeyForContract(id),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

function getQueryKeyForContract(contractId: string) {
  return [
    {
      scope: "contract",
      contractId: contractId,
      graphqlQueries: ["GetContractByIdQuery", "GetMachineIdByLocationIds"],
    },
  ];
}

function getContract(variables: GetContractByIdQueryVariables) {
  return fetcher<GetContractByIdQuery, GetContractByIdQueryVariables>(
    GetContractByIdDocument,
    variables
  )();
}
function getLocations(variables: GetMachineIdByLocationIdsQueryVariables) {
  return fetcher<
    GetMachineIdByLocationIdsQuery,
    GetMachineIdByLocationIdsQueryVariables
  >(GetMachineIdByLocationIdsDocument, variables)();
}

async function getContractById(
  id: string,
  options: GetContractByIdOptions
): Promise<ContractDetails> {
  const contractResponse = await getContract({ contractId: id, ...options });
  const contract = contractResponse.contract2;
  if (contract === null) {
    throw new Error("Contract does not exist");
  }

  let locations: ContractLocations = [];
  if (contract.locationIds.length > 0) {
    const locationsId = contract.locationIds.map((location) => location.id);
    const locationResponse = await getLocations({ locationsId });
    const locationsWIthMachineId = locationResponse.locations;

    locations = contract.locationIds.map((location) => {
      const locationWithMachineId = locationsWIthMachineId.find(
        (loc) => loc.id === location.id
      );

      let machineId: string | undefined;
      if (locationWithMachineId === undefined) {
        machineId = undefined;
      } else if ("machineIds" in locationWithMachineId) {
        machineId = locationWithMachineId.machineIds[0];
      } else if ("machineId" in locationWithMachineId) {
        machineId = locationWithMachineId.machineId;
      }

      return {
        id: location.id,
        friendlyId: location.friendlyId,
        machineId,
      };
    });
  }

  return {
    general: {
      contractId: contract.contractId,
      contractFriendlyId: contract.friendlyId,
      contractStartDate: moment(contract.startDate),
      contractEndDate: moment(contract.endDate),
      organisation: {
        id: contract.organization.id,
        name: contract.organization.name,
      },
      numberOfLocationsPerContract: Number(contract.numberOfLocations),
    },

    locations: locations,
    contact: {
      contractOwnerName: contract.contractOwnerName,
      accountPayableId: contract.accountPayableId,
      contactPerson: contract.contactPerson,
      phoneNumber: contract.phoneNumber,
    },
    documents: contract.documents.map((document) => {
      return {
        name: document.filename,
        url: document.url,
      };
    }),
  };
}

// Optimisitc UI

export const contractByIdCache = {
  addContract,
  updateContract,
  addLocationToContract,
  duplicateContract,
  removeLocationFromContract,
  changeLocationInContract,
  addDocumentToContract,
  removeDocumentFromContract,
};

function addContract(newContractDetails: ContractDetails) {
  const contractId = newContractDetails.general.contractId;
  setCachedContractById(contractId, newContractDetails);
}

function duplicateContract({
  contractId,
  newContractId,
  newContractFriendlyId,
  newContractPeriod,
}: {
  contractId: string;
  newContractId: string;
  newContractFriendlyId: string;
  newContractPeriod: { startDate: moment.Moment; endDate: moment.Moment };
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const updatedContract: ContractDetails = {
    ...cachedContract,
    general: {
      ...cachedContract.general,
      contractFriendlyId: newContractFriendlyId,
      contractId: newContractId,
      contractStartDate: newContractPeriod.startDate,
      contractEndDate: newContractPeriod.endDate,
    },
    documents: [],
  };

  setCachedContractById(newContractId, updatedContract);
}

function updateContract({
  contractId,
  updatedContractDetails,
}: {
  contractId: string;
  updatedContractDetails: Pick<ContractDetails, "contact" | "general">;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const mergedContract = {
    ...cachedContract,
    ...updatedContractDetails,
  };

  setCachedContractById(contractId, mergedContract);
}

function changeLocationInContract({
  contractId,
  previousLocationId,
  newLocation,
}: {
  contractId: string;
  previousLocationId: string;
  newLocation: Location;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    return;
  }

  const updatedLocations = cachedContract.locations.map((location) => {
    if (location.id === previousLocationId) {
      return newLocation;
    }
    return location;
  });

  const updatedContract = {
    ...cachedContract,
    locations: updatedLocations,
  };

  setCachedContractById(contractId, updatedContract);
}

function addLocationToContract({
  location,
  contractId,
}: {
  location: Location;
  contractId: string;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const updatedLocations = [...cachedContract.locations, location];

  const updatedContract = {
    ...cachedContract,
    locations: updatedLocations,
  };

  setCachedContractById(contractId, updatedContract);
}

function removeLocationFromContract({
  locationId,
  contractId,
}: {
  locationId: string;
  contractId: string;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const updatedLocations = cachedContract.locations.filter(
    (location) => location.id !== locationId
  );

  const updatedContract = {
    ...cachedContract,
    locations: updatedLocations,
  };

  setCachedContractById(contractId, updatedContract);
}

function addDocumentToContract({
  fileName,
  url,
  contractId,
}: {
  fileName: string;
  url: string;
  contractId: string;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const updatedDocuments = [
    { name: fileName, url },
    ...cachedContract.documents,
  ];

  const updatedContract = {
    ...cachedContract,
    documents: updatedDocuments,
  };

  setCachedContractById(contractId, updatedContract);
}

function removeDocumentFromContract({
  documentName,
  contractId,
}: {
  documentName: string;
  contractId: string;
}) {
  const cachedContract = getCachedContractByContractId(contractId);
  if (cachedContract === undefined) {
    throw new Error("Contract does not exist");
  }

  const updatedDocuments = cachedContract.documents.filter(
    (document) => document.name !== documentName
  );

  const updatedContract = {
    ...cachedContract,
    documents: updatedDocuments,
  };

  setCachedContractById(contractId, updatedContract);
}

function getCachedContractByContractId(
  contractId: string
): ContractDetails | undefined {
  return queryClient.getQueryData<ContractDetails>(
    getQueryKeyForContract(contractId)
  );
}

function setCachedContractById(contractId: string, contract: ContractDetails) {
  return queryClient.setQueryData<ContractDetails>(
    getQueryKeyForContract(contractId),
    contract
  );
}

// Types

export type ContractDetails = {
  general: GeneralContractInfo;
  locations: ContractLocations;
  contact: ContractContactInfo;
  documents: Documents;
};

export type Documents = Array<Document>;

export type Document = {
  name: string;
  url: string;
};

export type GeneralContractInfo = {
  contractId: string;
  contractFriendlyId: string;
  contractStartDate: moment.Moment;
  contractEndDate: moment.Moment;
  organisation: {
    id: string;
    name: string;
  };
  numberOfLocationsPerContract: number;
};

export type ContractLocations = Array<Location>;

type Location = {
  id: string;
  friendlyId: string;
  machineId: string;
};

export type ContractContactInfo = {
  contractOwnerName: string;
  accountPayableId: string;
  contactPerson: string;
  phoneNumber: string;
};

import axios, { AxiosResponse } from 'axios';
import { httpClient } from 'common/services/transportService';
import { IAuthEndPoint } from './interfacesAuth';

export const AUTH_ERROR_TYPE = {
  InvalidUserOrPassword : "INVALID_USER_OR_PASSWORD",
  ServerError : "SERVER_ERROR",
  NetworkError : "NETWORK_ERROR",
  SigninWithGoogleError : "SIGNIN_WITH_GOOGLE_ERROR",
  UnknownAuthError: "UNKNOWN_AUTH_ERROR"
} as const;

export type AuthErrorType = (typeof AUTH_ERROR_TYPE)[keyof typeof AUTH_ERROR_TYPE];

export class AuthError extends Error {
  constructor(
    public errorType: AuthErrorType, 
    public errorCode?: number
  ) {
    super(errorType);
    this.name = 'AuthError';
  }
}

export interface IPostLogInWithPassword {
  email: string;
  password: string;
}

export async function postLoginWithPassword (
  postBody: IPostLogInWithPassword
): Promise<IAuthEndPoint> {
  try {
    const response = await httpClient.post<IAuthEndPoint>("/auth/login-password", postBody, {
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    checkAuthError(err, AUTH_ERROR_TYPE.InvalidUserOrPassword);
  }
}

export interface IPostLogInWithGoogle {
  idToken: string;
}

export async function postLoginWithGoogle ({
  idToken,
}: IPostLogInWithGoogle): Promise<IAuthEndPoint> {
  try {
    const response = await httpClient.post<IAuthEndPoint>(
      "/auth/login-google",
      {
        accessToken: idToken, // IdToken is being considered as access token on the backend
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (err) {
    checkAuthError(err, AUTH_ERROR_TYPE.SigninWithGoogleError);
  }
}

function checkAuthError(err: Error, defaultAuthErrorType: AuthErrorType) {
  if (!axios.isAxiosError(err)) throw err;

  if (!err.response) throw new AuthError(AUTH_ERROR_TYPE.NetworkError);

  switch (err.response.status) {
    case 401:
      throw new AuthError(defaultAuthErrorType, err.response.status);
    case 500:
      throw new AuthError(AUTH_ERROR_TYPE.ServerError, err.response.status);
    default:
      throw new AuthError(AUTH_ERROR_TYPE.UnknownAuthError, err.response.status);
  }
}

export function postLogOut (): Promise<AxiosResponse<void>> {
  return httpClient.post<void>('/auth/clean-cache', undefined, { withCredentials: true }); // Logout on backend revokes access token, hit clean cache instead to clear cookies
}

export async function postRefreshToken (): Promise<IAuthEndPoint> {
  const response = await httpClient.post<IAuthEndPoint>(
    '/auth/refresh-token',
    undefined,
    {
      withCredentials: true
    }
  );
  return response.data;
}

export interface IPostSignUpWithPassword {
  password: string;
  setupToken: string;
}

export async function postSignUpWithPassword (
  postBody: IPostSignUpWithPassword
): Promise<string> {
  const response = await httpClient.post<string>(
    '/auth/setup-account-password',
    postBody, {
      withCredentials: true
    }
  );
  return response.data;
}

export interface IPostSignUpWithGoogle {
  accessToken: string;
  setupToken: string;
}

export async function postSignUpWithGoogle ({
  accessToken,
  setupToken
}: IPostSignUpWithGoogle): Promise<string> {
  const response = await httpClient.post<string>(
    '/auth/setup-account-google', {
    accessToken,
    setupToken
  }, {
    withCredentials: true
  });
  return response.data;
}

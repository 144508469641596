import * as React from 'react';

import { Modal, Title } from '../../../../components/ReusableModal';
import Button from 'components/Button/Button';
import { toasti18n } from 'utils/toast';
import { downloadXLSXFile } from 'utils/xlsx';

import { httpClient } from 'common/services/transportService';
import DatePicker from 'components/DatePicker/DatePicker';
import * as moment from 'moment';
import TextButton from 'common/components/textbutton/TextButton';
import { useIsForthCKUser } from 'utils/user-role';

function useCanDownloadERPReport(){
  return useIsForthCKUser();
}

export function DownloadERPReportButton() {
  const [isOpen, setIsOpen] = React.useState(false);


  const canDownloadERP = useCanDownloadERPReport();

  if (!canDownloadERP) {
    return null;
  }

  return (
    <>
      <TextButton
        onClick={() => setIsOpen(true)}
        translationKey="label_download_erp_report"
        icon="DownloadDocument"
      />
      {isOpen ? (
        <DownloadERPReportModal onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

export function DownloadERPReportModal(props: { onClose: () => void }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState<moment.Moment>(moment());

  async function downloadERPReport() {
    setIsLoading(true);
    try {
      await downloadXLSXFile(
        () => downloadERPReportXLSXBuffer(date),
        `ERP-REPORT-${date.format('DD-MM-YYYY')}`
      );
      props.onClose();
      toasti18n.success();
    } catch {
      setIsLoading(false);
    }
  }

  const isFormInvalid = date === null || !date.isValid();

  return (
    <Modal
      onClose={isLoading ? () => {} : props.onClose}
      open={true}
      modalStyles={{
        maxWidth: '500px',
        heightStyles: {
          flex: '0 0 auto',
        },
      }}
    >
      <Title>label_download_erp_report</Title>

      <div
        style={{
          marginTop: '25px',
        }}
      >
        <DatePicker
          label="label_date"
          value={date}
          onChange={(value) => setDate(value)}
          required
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '29px',
        }}
      >
        <Button
          type="secondary"
          onClick={props.onClose}
          disabled={isLoading}
        >
          action_cancel
        </Button>
        <Button type="primary" onClick={downloadERPReport} loading={isLoading} disabled={isFormInvalid}>
          action_download
        </Button>
      </div>
    </Modal>
  );
}

export async function downloadERPReportXLSXBuffer(
  date: moment.Moment
): Promise<ArrayBuffer> {
  const response = await httpClient.get<ArrayBuffer>(`/warehouse/erp-report`, {
    responseType: 'arraybuffer',
    params: {
      date: date.startOf('day').toISOString(),
    },
  });
  return response.data;
}

import TextFieldViewOnly from "components/TextFieldViewOnly";
import Typography from "components/Typography";
import {
  MachineKind,
  useGetMachineByIdQuery,
  useGetMachineFirmwareVersionQuery,
} from "gql/generated";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function MachineModel() {
  const params = useParams<{ machineId: string }>();
  const machineByIdQuery = useGetMachineByIdQuery(
    {
      machineId: params.machineId,
    },
    {
      enabled: false,
    }
  );

  if (machineByIdQuery.data.machine.kind === MachineKind.SpiralVending) {
    return null;
  }

  return <MachineModelNonSpiral />;
}

function MachineModelNonSpiral() {
  const { t } = useTranslation();
  const params = useParams<{ machineId: string }>();
  const machineFirmwareVersionResult = useGetMachineFirmwareVersionQuery(
    {
      machineId: params.machineId,
    },
    {
      enabled: false,
      useErrorBoundary: false,
    }
  );

  if (machineFirmwareVersionResult.isError) {
    return (
      <div className="bg-[#f2f2f2] font-kanit py-[70px] px-10">
        <div className="flex">
          <div className="[flex-basis:236px]"></div>
          <div className="flex-grow min-w-0 [flex-basis:647px] pl-[14px]">
            <div className="flex items-center relative ">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-[calc(100%+26px)] text-error"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.0002 3.33334C10.8002 3.33334 3.3335 10.8 3.3335 20C3.3335 29.2 10.8002 36.6667 20.0002 36.6667C29.2002 36.6667 36.6668 29.2 36.6668 20C36.6668 10.8 29.2002 3.33334 20.0002 3.33334ZM21.6668 28.3333H18.3335V25H21.6668V28.3333ZM21.6668 21.6667H18.3335V11.6667H21.6668V21.6667Z"
                  fill="currentColor"
                />
              </svg>

              <div className="text-subtitle1 text-error !font-normal">
                <Trans
                  i18nKey={"label_unable_to_load_machine_model"}
                  components={{
                    bold: <strong />,
                  }}
                />
              </div>
            </div>
            <div className="mt-[10px] text-subtitle1 py-2.5 px-3 text-on-surface-medium bg-background max-w-96 border-solid border-[1px] border-outline">
              <Trans
                i18nKey={"label_suggestion_for_loading_machine_model"}
                components={{
                  bold: <span />,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { machineFirmwareVersion } = machineFirmwareVersionResult.data;

  return (
    <div className="flex py-7 px-10">
      <div className="[flex-basis:236px] pr-3 box-border">
        <Typography type="headline-6">{t("label_machine_model")}</Typography>
      </div>
      <div className="[flex-basis:647px] flex flex-col gap-[14px]">
        <TextFieldViewOnly
          value={
            machineFirmwareVersion.hardware === ""
              ? "-"
              : machineFirmwareVersion.hardware
          }
          label="label_hardware_version"
          iconProps={{
            name: "HardwareVersion",
            color: "primary500",
          }}
        />
        <div className="grid grid-cols-2 gap-[14px]">
          <TextFieldViewOnly
            value={
              machineFirmwareVersion.androidBoard === ""
                ? "-"
                : machineFirmwareVersion.androidBoard
            }
            label="label_android_board"
            iconProps={{
              name: "Android",
              color: "primary500",
            }}
          />
          <TextFieldViewOnly
            value={
              machineFirmwareVersion.topBoard === ""
                ? "-"
                : machineFirmwareVersion.topBoard
            }
            label="label_top_board"
            iconProps={{
              name: "TopBoard",
              color: "primary500",
            }}
          />

          <TextFieldViewOnly
            value={
              machineFirmwareVersion.brewBoard === ""
                ? "-"
                : machineFirmwareVersion.brewBoard
            }
            label="label_brew_board"
            iconProps={{
              name: "CoffeeCup",
              color: "primary500",
            }}
          />
          <TextFieldViewOnly
            value={
              machineFirmwareVersion.bottomBoard === ""
                ? "-"
                : machineFirmwareVersion.bottomBoard
            }
            label="label_bottom_board"
            iconProps={{
              name: "BottomBoard",
              color: "primary500",
            }}
          />
          <TextFieldViewOnly
            value={
              machineFirmwareVersion.iceBoard === ""
                ? "-"
                : machineFirmwareVersion.iceBoard
            }
            label="label_ice_board"
            iconProps={{
              name: "Kitchen",
              color: "primary500",
            }}
          />
          <TextFieldViewOnly
            value={
              machineFirmwareVersion.frontBoard === ""
                ? "-"
                : machineFirmwareVersion.frontBoard
            }
            label="label_front_board"
            iconProps={{
              name: "FrontBoard",
              color: "primary500",
            }}
          />
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import Icon from "common/components/icon/Icon";

export function LocationCard({
  locationFriendlyId, machineId, className = undefined,
}: {
  machineId: string;
  locationFriendlyId: string;
  className?: string;
}) {
  return (
    <div
      style={{
        padding: "10px 17px",
        borderRadius: "5px",
        border: "1px solid rgba(0,0,0,0.12)",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
      className={className}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Icon
          name="Location"
          color="onSurfaceMedium"
          style={{
            width: "17px",
            height: "17px",
          }} />
        <span
          style={{
            fontFamily: "Kanit",
            fontSize: "16px",
          }}
        >
          {locationFriendlyId}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Icon
          name="Machine"
          color="onSurfaceMedium"
          style={{
            width: "17px",
            height: "17px",
          }} />

        <span
          style={{
            fontFamily: "Kanit",
            fontSize: "16px",
          }}
        >
          {machineId ?? "-"}
        </span>
      </div>
    </div>
  );
}

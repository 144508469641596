import * as React from "react";

import { Modal } from "@mui/material";
import Button from "components/Button";
import Typography from "components/Typography";

export function ConfirmationModal({
  onAccept,
  onClose,
}: {
  onAccept: () => void;
  onClose: () => void;
}) {
  return (
    <Modal open={true} onClose={onClose} onBackdropClick={onClose}>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            pointerEvents: "auto",
            padding: "25px",
            maxWidth: "340px",
            borderRadius: "3px",
          }}
        >
          <div style={{ marginTop: "-10px" }}>
            <Typography type="headline-6" translate>
              status_warning
            </Typography>
          </div>

          <div>
            <Typography type="body-2" translate>
              action_unsaved_changes
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "18px",
            }}
          >
            <Button type="secondary" onClick={onClose}>
              action_cancel
            </Button>
            <Button type="status" onClick={onAccept}>
              label_close_window
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

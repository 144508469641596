import * as React from 'react';

interface IWaterProps {
  color?: string,
  className?: string
}

function Water ({
  color = 'white',
  className
}: IWaterProps): JSX.Element {
  return (
    <svg className={className} width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path d='M9.02226 5.15573L6.37865 1.19034C6.22271 0.981214 5.92679 0.938079 5.71766 1.09401C5.68108 1.1213 5.64863 1.15375 5.62134 1.19034L2.97772 5.15573C2.23434 6.28319 2.13616 7.7178 2.71904 8.93603C3.25486 10.0879 4.36288 10.8678 5.62798 10.9836C5.75044 10.9945 5.87442 11.0003 5.99999 11.0003C6.12556 11.0003 6.24954 10.9945 6.37223 10.9836C7.63724 10.8678 8.74516 10.0878 9.28094 8.93603C9.86382 7.7178 9.76565 6.28323 9.02226 5.15573ZM8.45982 8.54292C8.06717 9.4087 7.23784 9.99522 6.29066 10.0769C6.09734 10.0943 5.90284 10.0943 5.70952 10.0769C4.76231 9.99526 3.93285 9.40877 3.54017 8.54292C3.08712 7.61605 3.16138 6.51812 3.73504 5.66064L5.99999 2.26323L8.26495 5.66064C8.83861 6.51812 8.91287 7.61601 8.45982 8.54292Z' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='10' height='10' fill='white' transform='translate(1 1)'/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Water;

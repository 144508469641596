import * as React from "react";

import { Modal } from "@mui/material";
import Button from "components/Button";
import Typography from "components/Typography";
import { toasti18n } from "utils/toast";
import {
  isValidRefillPriority,
  LoadingScreen,
  PriorityCell,
  RefillPriorityString,
} from "../../Components";

import * as moment from "moment";
import * as styles from "./PreKitsModal.module.scss";
import {
  GetKittedPlanByIdQuery,
  MachineKind,
  PlanStatus,
  useBatchUpdatePlanStatusMutation,
  useGetKittedPlanByIdQuery,
} from "gql/generated";
import TextButton from "common/components/textbutton/TextButton";
import { useDownloadOperationPDFByPlanId } from "containers/new-operations/refill-order/operation-pdf/useDownloadOperationPDFByPlanId";
import { formatCurrency } from "utils/currency";
import Icon from "common/components/icon/Icon";
import { clsx } from "clsx";

interface Props {
  planId: string;
  onClose: () => void;
  onCollectSuccess: () => void;
}

export function PreKitsModal({ planId, onClose, onCollectSuccess }: Props) {
  const { data: planData, isLoading } = useGetKittedPlanByIdQuery(
    { planId },
    { cacheTime: 0 }
  );
  const data = isLoading ? undefined : mapIncomingDataToModalData(planData);
  const { mutate, isLoading: isMutating } = useBatchUpdatePlanStatusMutation({
    onSuccess: () => {
      onCollectSuccess();
      toasti18n.success();
    },
    onError: (error: Error) => {
      toasti18n.error(error);
    },
  });

  const {
    downloadOperationPDFByPlanId,
    isLoading: isDownloadingOperationSheet,
  } = useDownloadOperationPDFByPlanId();

  return (
    <Modal open={true} onClose={onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <div className={styles.PreKitsModal}>
              <div className={styles.Header}>
                <Typography type="headline-6" color="onSurfaceHigh" translate>
                  label_plan
                  {` ${data.planName} `}
                </Typography>
                <Typography type="subtitle-1" color="onSurfaceMedium" translate>
                  {"("}
                  {data.refillOrders.reduce(
                    (recommendedRefillOrderCount, refillOrder) =>
                      refillOrder.wasRecommended
                        ? recommendedRefillOrderCount + 1
                        : recommendedRefillOrderCount,
                    0
                  )}
                  {` `}
                  label_recommended
                  {` `}
                  label_refill
                  {")"}
                </Typography>
              </div>

              <div className={styles.GrowArea}>
                <div className={styles.ReviewTableScrollContainer}>
                  <div className={styles.ReviewTableContainer}>
                    <div className={styles.ReviewTableHeader}>
                      <div className={styles.OrderColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          menu_order
                        </Typography>
                      </div>
                      <div className={styles.MachineIdColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_machine_id
                        </Typography>
                      </div>
                      <div className={styles.PriorityColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_priority
                        </Typography>
                      </div>
                      <div className={styles.LocationNameColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_location_name
                        </Typography>
                      </div>
                      <div className={styles.TypeColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_type
                        </Typography>
                      </div>
                      <div className={styles.ErrorColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          status_error
                        </Typography>
                      </div>
                      <div className={styles.RevenueColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_revenue
                        </Typography>
                      </div>
                      <div className={styles.TotalColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_total
                        </Typography>
                      </div>
                      <div className={styles.NoteColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_note
                        </Typography>
                      </div>
                      <div className={styles.TimeToColumn}>
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceDisabled"
                        >
                          label_time_to
                        </Typography>
                      </div>
                    </div>
                    {[...data.refillOrders]
                      .sort(
                        (refillOrderA, refillOrderB) =>
                          refillOrderA?.positionInPlan -
                          refillOrderB?.positionInPlan
                      )
                      .map((refillOrder) => (
                        <div key={refillOrder.id} className={styles.Row}>
                          <div className={styles.OrderColumn}>
                            <Typography type="body-2" color="onSurfaceHigh">
                              {refillOrder.positionInPlan}
                            </Typography>
                          </div>
                          <div
                            className={clsx(
                              styles.MachineIdColumn,
                              "flex gap-2 items-center pr-2"
                            )}
                          >
                            {refillOrder.kind === MachineKind.SpiralVending ? (
                              <div className="flex-1">
                                <Typography
                                  type="body-2"
                                  translate
                                  color="onSurfaceHigh"
                                  className="leading-5"
                                >
                                  {refillOrder.machine.id}
                                </Typography>
                                <p className="font-kanit text-caption leading-4 text-on-surface-disabled">
                                  {refillOrder.parentId}
                                </p>
                              </div>
                            ) : (
                              <Typography
                                type="body-2"
                                translate
                                color="onSurfaceHigh"
                                className="flex-1"
                              >
                                {refillOrder.machine.id}
                              </Typography>
                            )}
                            <div className="flex justify-center">
                              {refillOrder.kind ===
                                MachineKind.SpiralVending && (
                                <Icon name="Snack" color="primary" />
                              )}
                            </div>
                          </div>
                          <div className={styles.PriorityColumn}>
                            <PriorityCell
                              priority={
                                isValidRefillPriority(
                                  refillOrder.machine.priority
                                )
                                  ? refillOrder.machine.priority
                                  : null
                              }
                            />
                          </div>
                          <div className={styles.LocationNameColumn}>
                            <div className={styles.TwoLinesWrapper}>
                              <Typography
                                type="body-2"
                                translate
                                color="onSurfaceHigh"
                              >
                                {refillOrder.machine.location.name}
                              </Typography>
                            </div>
                          </div>
                          <div className={styles.TypeColumn}>
                            <Typography
                              type="body-2"
                              translate
                              color="onSurfaceHigh"
                            >
                              label_refill
                              {refillOrder.swapOrders.length > 0
                                ? [
                                    " & ",
                                    "label_swap",
                                    ` (${refillOrder.swapOrders.length})`,
                                  ]
                                : null}
                            </Typography>
                          </div>
                          <div className={styles.ErrorColumn}>
                            {refillOrder.machine.recentErrorCode ? (
                              <Typography type="body-2" translate color="error">
                                {refillOrder.machine.recentErrorCode}
                              </Typography>
                            ) : (
                              <Typography
                                type="body-2"
                                translate
                                color="onSurfaceHigh"
                              >
                                -
                              </Typography>
                            )}
                          </div>
                          <div className={styles.RevenueColumn}>
                            <Typography
                              type="body-2"
                              translate
                              color="onSurfaceHigh"
                            >
                              {formatCurrency({
                                input: refillOrder.machine.location.revenue,
                                minimumFractionDigits: 0,
                              })}
                            </Typography>
                          </div>
                          <div className={styles.TotalColumn}>
                            <Typography
                              type="body-2"
                              translate
                              color="onSurfaceHigh"
                            >
                              {refillOrder.kittedItems.reduce(
                                (totalRefillItems, kittedItem) =>
                                  totalRefillItems + kittedItem.quantity,
                                0
                              ) +
                                refillOrder.swapOrders.reduce(
                                  (totalSwappedItems, swapOrder) =>
                                    totalSwappedItems +
                                    swapOrder.swapItem.quantity,
                                  0
                                )}
                            </Typography>
                          </div>
                          <div className={styles.NoteColumn}>
                            <div className={styles.TwoLinesWrapper}>
                              <Typography
                                type="body-2"
                                color="onSurfaceDisabled"
                              >
                                {refillOrder.criticalNote ? (
                                  <Typography type="body-2" color="error">
                                    {refillOrder.criticalNote}{" "}
                                  </Typography>
                                ) : null}
                                {refillOrder.note ? (
                                  <Typography
                                    type="body-2"
                                    color="onSurfacehigh"
                                  >
                                    {(refillOrder.criticalNote ? ", " : "") +
                                      (refillOrder.note ?? "")}
                                  </Typography>
                                ) : null}
                              </Typography>
                            </div>
                          </div>
                          <div className={styles.TimeToColumn}>
                            <Typography
                              type="body-2"
                              translate
                              color="onSurfaceHigh"
                            >
                              label_before
                              {` ${moment(Number(refillOrder.estimatedArrival)).format("HH:mm")}`}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    <div style={{ height: 100 }}></div>
                  </div>
                </div>
              </div>
              <div className={styles.ButtonsContainer}>
                <Button
                  type="secondary"
                  onClick={onClose}
                  disabled={isMutating}
                >
                  label_close
                </Button>
                <div className={styles.DownloadAndCollectContainer}>
                  <TextButton
                    translationKey="action_download_refill_paper"
                    onClick={() => downloadOperationPDFByPlanId(planId)}
                    icon="DownloadTray"
                    disabled={isDownloadingOperationSheet}
                  />
                  <Button
                    type="primary"
                    loading={isMutating}
                    onClick={() => {
                      mutate({
                        plans: {
                          id: planId,
                          status: PlanStatus.EnRoute,
                        },
                      });
                    }}
                  >
                    refill_collect_prekit
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

interface PreKitsModalData {
  planId: string;
  planName: string;
  refillOrders: Array<RefillOrder>;
}

export interface RefillOrder {
  id: string;
  kind: MachineKind;
  parentId: string;
  note: string;
  critical: boolean;
  criticalNote: string;
  estimatedArrival: string;
  positionInPlan: number;
  kittedItems: Array<Item>;
  swapOrders: Array<{ swapItem: Item }>;
  wasRecommended: boolean;
  machine: Machine;
}
interface Machine {
  id: string;
  recentErrorCode: number | null;
  priority: RefillPriorityString | null;
  location: {
    revenue: number | null;
    name: string;
  };
}

interface Item {
  inventoryId: string;
  productId: string;
  quantity: number;
}

function mapIncomingDataToModalData(
  kittedPlanByIdQuery: GetKittedPlanByIdQuery
): PreKitsModalData {
  const kp = kittedPlanByIdQuery.plan;

  return {
    planId: kp.id,
    planName: kp.name,
    refillOrders: kp.refillOrders.map((ro) => {
      const location = ro.machine.location;

      if (location.__typename !== "Factory") {
        return {
          critical: ro.isCritical,
          criticalNote: ro.criticalNote,
          estimatedArrival: ro.estimatedArrival,
          id: ro.id,
          kind: ro.machine.kind,
          parentId: ro.machine.parentId,
          note: ro.note,
          positionInPlan: ro.positionInPlan,
          wasRecommended: ro.isRecommended,
          kittedItems: ro.requestedItems.map((ri) => ({
            inventoryId: ri.machineInventory.id,
            productId: ri.machineInventory.productId,
            quantity: ri.usage,
          })),
          swapOrders: ro.swapOrders.map((so) => ({
            swapItem: {
              inventoryId: so.swapItems[0].machineInventoryId,
              productId: so.swapItems[0].productId,
              quantity: so.swapItems[0].quantity,
            },
          })),
          machine: {
            id: ro.machine.id,
            priority: isValidRefillPriority(location.refillPriority)
              ? location.refillPriority
              : null,
            recentErrorCode: ro.machine.latestStatus?.code,
            location: {
              name: location.locationName,
              revenue: location.revenueSinceLastRefilled,
            },
          },
        };
      }

      return {
        critical: ro.isCritical,
        criticalNote: ro.criticalNote,
        estimatedArrival: ro.estimatedArrival,
        id: ro.id,
        kind: ro.machine.kind,
        parentId: ro.machine.parentId,
        note: ro.note,
        positionInPlan: ro.positionInPlan,
        wasRecommended: ro.isRecommended,
        kittedItems: ro.requestedItems.map((ri) => ({
          inventoryId: ri.machineInventory.id,
          productId: ri.machineInventory.productId,
          quantity: ri.usage,
        })),
        swapOrders: ro.swapOrders.map((so) => ({
          swapItem: {
            inventoryId: so.swapItems[0].machineInventoryId,
            productId: so.swapItems[0].productId,
            quantity: so.swapItems[0].quantity,
          },
        })),
        machine: {
          id: ro.machine.id,
          priority: null,
          recentErrorCode: ro.machine.latestStatus?.code,
          location: {
            name: location.locationName,
            revenue: null,
          },
        },
      };
    }),
  };
}

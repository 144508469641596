import * as React from "react";
import { Column } from "@tanstack/react-table";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, MenuItem, InputLabel } from "@mui/material";
import { clsx } from "clsx";

export function SelectFilter<T>({
  column,
  className,
  onChange,
}: {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (e: SelectChangeEvent<string>) => void;
}) {
  const filterValue = column.getFilterValue() as string;

  if(column.columnDef.meta.columnFilter.type !== "select") return null;

  return (
    <FormControl
      className={clsx("!min-w-[220px]", className)}
      key={column.id}
    >
      <InputLabel className="!font-kanit !font-light !text-on-surface-high" size="small">
        {column.columnDef.meta?.columnFilter?.label || column.id}
      </InputLabel>
      <Select
        id={column.id}
        value={filterValue || ""}
        label={<p className="!text-body2 !font-light !text-on-surface-high">{column.columnDef.meta?.columnFilter?.label || column.id}</p>}
        onChange={(e) => {
          column.setFilterValue(e.target.value);
          onChange?.(e);
        }}
        size="small"
      >
        {column.columnDef.meta?.columnFilter?.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

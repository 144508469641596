import * as React from 'react';
import Icon from 'common/components/icon/Icon';
import Typography from 'components/Typography';

import * as styles from './NoPermissionComponent.module.scss';

function NoPermissionComponent(): JSX.Element {
  return (
    <div className={styles.NoPermissionComponent}>
      <Icon className={styles.icon} name="Info" color="error" />
      <div className={styles.title}>
        <Typography translate type="headline-6">
          label_no_permission
        </Typography>
      </div>
    </div>
  );
}

export default NoPermissionComponent;

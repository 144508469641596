import Typography from "common/components/typography/Typography";
import * as React from "react";
import * as moment from "moment";

function DateCell({ format }: { format: string }) {
  function dateAcceptor(date?: number | string | undefined | null) {
    return (
      <div>
        {date && (
          <Typography type="body-2" text={moment(date).format(format)} />
        )}
      </div>
    );
  }
  return dateAcceptor;
}

export { DateCell };

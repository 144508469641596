import * as React from "react";
import { useGetWarehousesByOrgIdQuery } from "gql/generated";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import {
  getRefillOrdersForPlansByStateCSV,
  PlanStates,
} from "services/plan/getRefillOrdersForPlansByStateCSV";
import { downloadCSVFromNetworkRequest } from "utils/csv";
import * as moment from "moment";
import { toasti18n } from "utils/toast";
import { getInventorySummaryCSVByPlanId } from "services/plan/getInventorySummaryCSVByPlanId";

export function useGetWarehousesForCurrentUserOrg() {
  const { data: user } = useGetCurrentUser();
  const { data: warehouseIdQuery, isLoading: isWarehousesLoading } =
    useGetWarehousesByOrgIdQuery({
      organisationId: user.orgId,
    });
  const warehouses = warehouseIdQuery?.warehouses;
  return { isWarehousesLoading, warehouses };
}

export function useDownloadRefillOrdersForPlansByStateCSV() {
  const [isLoading, setIsLoading] = React.useState(false);
  async function downloadRefillOrdersForPlansByStateCSV(
    state: PlanStates,
    {
      onSuccess,
      onError,
    }: { onSuccess?: () => void; onError?: (err: Error) => void } = {}
  ) {
    setIsLoading(true);
    const toast = toasti18n.loading();
    try {
      await downloadCSVFromNetworkRequest(
        () => getRefillOrdersForPlansByStateCSV(state),
        `refill-orders-in-${state}-plan-${moment().format("DD-MM-YYYY")}`
      );
      toasti18n.updateLoadingToastStatus(toast, "success");
      onSuccess?.();
    } catch (err) {
      onError?.(err);
      toasti18n.updateLoadingToastStatus(toast, "error");
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadRefillOrdersForPlansByStateCSV, isLoading };
}

export function useDownloadInventorySummaryByPlanId() {
  const [isLoading, setIsLoading] = React.useState(false);
  async function downloadInventorySummaryByPlanId(
    planId: string,
    fileName: string,
    {
      onSuccess,
      onError,
    }: { onSuccess?: () => void; onError?: (err: Error) => void } = {}
  ) {
    setIsLoading(true);
    try {
      await downloadCSVFromNetworkRequest(
        () => getInventorySummaryCSVByPlanId(planId),
        fileName
          ? fileName + "-inventory-summary-report"
          : `plan-${planId}-inventory-summary-report`
      );
      toasti18n.success();
      onSuccess?.();
    } catch (err) {
      onError?.(err);
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadInventorySummaryByPlanId, isLoading };
}

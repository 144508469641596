import { DashboardCardPolarChartCardProps } from "../organisms/DashboardCard";

import { APIPolarChart } from "dashboard/interface";

import * as colors from "common/styles/colors.scss";

const COLORS = ["chartCoffeeBrown", "chartPink", "chartCornflowerBlue", "primary400", "chartPeanutBrown" ];

export function mapDataToPolarChartCard(
  dataPoint: APIPolarChart
): Omit<DashboardCardPolarChartCardProps, "className"> {
  return {
    type: "polar",
    cardProps: {
      title: dataPoint.title,
      icon: dataPoint.icon,
      data: computeDataFieldForPolarChartCard(dataPoint.data.pieces, COLORS),
    },
  };
}

function computeDataFieldForPolarChartCard(
  APIPolarChartData: APIPolarChart["data"]["pieces"],
  COLORS
): DashboardCardPolarChartCardProps["cardProps"]["data"] {
  return [...APIPolarChartData]
    .sort((a, b) => b.value.percent - a.value.percent)
    .map((segment, index) => ({
      amount: segment.value.revenue,
      color: index >= COLORS.length ? colors[COLORS[4]] : colors[COLORS[index]],
      percent: segment.value.percent/100,
      label: segment.label
}));
}
import * as React from 'react';

import config from 'common/config';

import {
  GoogleLogin,
  GoogleLoginProps,
  GoogleOAuthProvider,
} from '@react-oauth/google';

function WrappedGoogleLogin(props: GoogleLoginProps) {
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <GoogleLogin {...props} />
    </GoogleOAuthProvider>
  );
}

export default WrappedGoogleLogin;

import * as React from 'react';

import Icon from 'common/components/icon/Icon';

import * as styles from './Checkbox.module.scss';

interface ICheckboxProps {
  checked: boolean,
  onClick: (newCheckedState: boolean) => void,
  roundCheckbox?: boolean
}

function Checkbox ({
  checked,
  onClick,
  roundCheckbox = false
}: ICheckboxProps): JSX.Element {
  return (
    <div
      className={[
        roundCheckbox ? styles.round : '',
        styles.Checkbox,
        checked ? styles.checked : ''
      ].join(' ')}
      onClick={(e) => { e.stopPropagation(); onClick(!checked); }}
    >
      <Icon
        name='Check'
        color='surface'
      />
    </div>
  );
}

export default Checkbox;

import * as React from "react";
import { Modal as MuiModal } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography/Typography";

export function Modal({
  open,
  onClose,
  children,
  modalStyles,
  displayCloseButton,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  displayCloseButton?: boolean;
  modalStyles: {
    alignment?: React.CSSProperties["justifyContent"];
    maxWidth: React.CSSProperties["maxWidth"];
    padding?: {
      paddingVertical?: React.CSSProperties["paddingTop"];
      paddingHorizontal?: React.CSSProperties["paddingLeft"];
    };
    heightStyles: {
      maxHeight?: React.CSSProperties["maxHeight"];
      minHeight?: React.CSSProperties["minHeight"];
      flex?: React.CSSProperties["flex"];
    };
  };
}) {
  return (
    <MuiModal open={open} onClose={onClose}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          padding: "68px 0px",
          flexDirection: "column",
          overflow: "auto",
          justifyContent: modalStyles.alignment ?? "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "3px",
            maxWidth: modalStyles.maxWidth,
            width: "100%",

            maxHeight: modalStyles.heightStyles.maxHeight,
            minHeight: modalStyles.heightStyles.minHeight,
            flex: modalStyles.heightStyles.flex,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "relative",
              boxSizing: "border-box",
              padding: `${
                modalStyles.padding?.paddingVertical ??
                modalSpacing.verticalSpace
              } ${
                modalStyles.padding?.paddingHorizontal ??
                modalSpacing.horizontalSpace
              }`,
            }}
          >
            {displayCloseButton ? (
              <div
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
              >
                <CloseButton onClick={onClose} />
              </div>
            ) : null}

            {children}
          </div>
        </div>
      </div>
    </MuiModal>
  );
}

export function Title({ children }: { children: string | Array<string> }) {
  return (
    <Typography type="headline-6" translate>
      {children}
    </Typography>
  );
}

function CloseButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Icon name="Close" color="onSurfaceHigh" />
    </div>
  );
}

export const modalBorderStyle = "1px solid rgba(0,0,0,0.12)";
export const modalSpacing = {
  verticalSpace: "24px",
  horizontalSpace: "33px",
};

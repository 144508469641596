import * as React from "react";
import {
  GetMachinesLocationForCsvDocument,
  GetMachinesLocationForCsvQuery,
  GetMachinesLocationForCsvQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";
import { downloadCSVFromJSON } from "utils/csv";
import { toasti18n } from "utils/toast";
import * as moment from "moment";

async function getMachineLocationsTypeFetcher() {
  const machineLocations = await fetcher<
    GetMachinesLocationForCsvQuery,
    GetMachinesLocationForCsvQueryVariables
  >(GetMachinesLocationForCsvDocument)();
  return machineLocations;
}

export function useDownloadMachineLocationsCSV() {
  const [isLoading, setIsLoading] = React.useState(false);

  async function downloadMachineLocationsMutate() {
    try {
      setIsLoading(true);
      const machinesLocationQuery = await toasti18n.promise(getMachineLocationsTypeFetcher());
      const machines = machinesLocationQuery.machines;

      downloadCSVFromJSON(
        machines
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((machine) => {
            if (machine.location.__typename === "Location") {
              return {
                "Machine ID": machine.id,
                "Location ID": machine.location?.friendlyId,
                "Location name": machine.location?.locationName,
                "Location kind": machine.location?.kind,
                Organisation: machine.location?.organisation.name,
                Province: machine.location?.province,
                "Location type": machine.location?.locationType,
                Sale: machine.location?.contract?.saleRepresentative,
                "Electric Price": machine.location?.contract?.electricUnitPrice,
                "Rental Price": machine.location?.contract?.rentalPrice,
                "Operating status": machine.deploymentStatus,
                "Machine Model": machine.model,
                Registered: machine.registered,
                machine_lat: machine.lat,
                machine_lng: machine.lng,
                location_lat: machine.location?.latitude,
                location_lng: machine.location?.longitude,
              };
            }

            return {
              "Machine ID": machine.id,
              "Location ID": machine.location?.friendlyId,
              "Location name": machine.location?.locationName,
              "Location kind": machine.location?.kind,
              Organisation: machine.location?.organisation.name,
              Province: machine.location?.province,
              "Location type": machine.location?.locationType,
              Sale: undefined,
              "Electric Price": undefined,
              "Rental Price": undefined,
              "Operating status": machine.deploymentStatus,
              "Machine Model": machine.model,
              Registered: machine.registered,
              machine_lat: machine.lat,
              machine_lng: machine.lng,
              location_lat: machine.location?.latitude,
              location_lng: machine.location?.longitude,
            };
          }),
        `machine-locations-${moment().format("DD-MM-YYYY")}`
      );
    } catch (error) {
      toasti18n.error({ message: "error" });
      throw new Error("Error in downloading machine locations: " + error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, downloadMachineLocationsMutate };
}

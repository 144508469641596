import * as React from "react";
import { debounce } from "lodash";

export function useResponsive() {
  // screen resolutions
  const [state, setState] = React.useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isXLDesktop: false
  });

  React.useLayoutEffect(() => {
    // update the state on the initial load
    onResizeHandler();

    // assign the event
    Setup();

    return () => {
      // remove the event
      Cleanup();
    };
  }, []);

  // update the state on window resize
  const onResizeHandler = () => {
    const isMobile = window.innerWidth < 768;
    const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;
    const isDesktop = window.innerWidth > 1024;
    const isXLDesktop = window.innerWidth > 1280;

    setState({ isMobile, isTablet, isDesktop, isXLDesktop });
  };

  // debounce the resize call using lodash's debounce
  const debouncedCall = React.useMemo(() => debounce(onResizeHandler, 100), []);

  // add event listener
  const Setup = () => {
    window.addEventListener("resize", debouncedCall, false);
  };

  // remove the listener
  const Cleanup = () => {
    window.removeEventListener("resize", debouncedCall, false);
    debouncedCall.cancel(); // Cancel any pending debounced calls
  };

  return state;
};

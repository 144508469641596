import * as React from 'react';

interface IInventoryProps {
  color?: string,
  className?: string
}

function Inventory ({
  color = 'white',
  className
}: IInventoryProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M20.5976 9.97191L18.7644 1.72191C18.6727 1.29793 18.2946 1 17.8706 1H6.8706C6.62991 1 6.38938 1.10306 6.21751 1.26352L3.46751 4.01352C2.90606 4.58638 3.31846 5.58339 4.1206 5.58339H5.11744L4.1436 9.97191C4.13201 10.0291 4.1206 10.098 4.1206 10.1666V22.0834C4.1206 22.5874 4.53317 23 5.03721 23H16.0372C16.1748 23 16.3237 22.9656 16.4498 22.9084L17.8706 22.1979L19.2914 22.9084C19.8873 23.1949 20.6092 22.7593 20.6206 22.0834V10.1666C20.6206 10.098 20.6092 10.0291 20.5976 9.97191ZM12.3134 2.83339H15.6592L14.7424 3.75H12.1415C12.2101 3.45207 12.2675 3.14273 12.3134 2.83339ZM10.457 2.83339C10.3997 3.14273 10.3311 3.45207 10.2509 3.75H7.39764L7.60392 2.83339H10.457ZM9.6206 5.58339V8.33339H11.454V5.78951C11.4768 5.73227 11.5112 5.66362 11.5456 5.58339H15.1206C15.6362 5.58339 15.9686 5.125 16.2893 4.79266L15.3039 9.25H6.1831L6.99665 5.58339H9.6206ZM5.95399 11.0834H15.1206V21.1666H5.95399V11.0834ZM18.7872 20.6052L18.2832 20.3531C18.0195 20.227 17.7217 20.227 17.458 20.3531L16.954 20.6052V10.2698L17.8706 6.14484L18.7872 10.2698V20.6052Z' fill={color} />
      <path d='M10.5372 12.9166C9.01332 12.9166 7.7872 14.1427 7.7872 15.6666C7.7872 17.1906 9.01332 18.4166 10.5372 18.4166C12.0612 18.4166 13.2872 17.1906 13.2872 15.6666C13.2872 14.1541 12.0498 12.9166 10.5372 12.9166ZM10.5372 16.5834C10.0332 16.5834 9.62059 16.1708 9.62059 15.6666C9.62059 15.1625 10.0332 14.75 10.5372 14.75C11.0414 14.75 11.454 15.1625 11.454 15.6666C11.454 16.1708 11.0414 16.5834 10.5372 16.5834Z' fill={color} />
    </svg>
  );
}

export default Inventory;

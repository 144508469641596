// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectWarehouseModal--1fWe94m88_{width:416px;max-width:100%;padding:24px 32px 28px;box-sizing:border-box}.SelectWarehouseModal--1fWe94m88_ .ActionFields--xFrmT0zy1S{display:flex;flex-direction:column;gap:19px;margin-top:11px}.SelectWarehouseModal--1fWe94m88_ .ButtonsContainer--5o1E0LfRlL{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"SelectWarehouseModal": "SelectWarehouseModal--1fWe94m88_",
	"ActionFields": "ActionFields--xFrmT0zy1S",
	"ButtonsContainer": "ButtonsContainer--5o1E0LfRlL"
};
module.exports = exports;

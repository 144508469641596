import * as React from 'react';
import { useTranslation } from 'react-i18next';

import withSuspense from 'common/hoc/withSuspense';
import Typography from 'common/components/typography/Typography';

import * as styles from './Input.module.scss';
import Icon from '../icon/Icon';

interface IInputProps {
  ref?: any;
  hidden?: boolean;
  value?: string | number;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
  type?: 'text' | 'number' | 'password';
  className?: string;
  label?: string;
  icon?: string;
  renderStyle?: 'box' | 'line' | 'none' | 'noLineBox';
  error?: string | string[] | boolean;
  unit?: string;
  required?: boolean;
  disable?: boolean;
}

// For disable "number" - type input's scrollable quantity (will occur on chrome browser only)
// @ts-ignore
document.addEventListener('wheel', function (event) {
  // @ts-ignore
  if (document.activeElement.type === 'number') {
    // @ts-ignore
    document.activeElement.blur();
  }
});

function Input({
  value = '',
  ref,
  hidden,
  onChange,
  onClick,
  type = 'text',
  className,
  icon,
  label,
  renderStyle = 'box',
  error,
  unit,
  required,
  disable = false,
}: IInputProps): JSX.Element {
  const { t } = useTranslation();

  function handleChange(event) {
    const value = event.target.value;
    if (type === 'number') {
      if (value === null || value === '') {
        return onChange(null);
      }
      if (value === 0) {
        return onChange(0);
      }
      return onChange(Number(value));
    }
    return onChange(value);
  }

  return (
    <div className={[styles.Input, className].join(' ')}>
      <Typography
        translationKey={value && label}
        type='caption'
        className={[
          styles.label,
          value && label ? styles.active : '',
          error ? styles.ErrorText : '',
          renderStyle === 'line' ? styles.line : '',
          renderStyle === 'noLineBox' ? styles.noLineBox : '',
          renderStyle === 'none' ? styles.none : '',
        ].join(' ')}
      />
      <input
        onKeyDown={
          type === 'number' ?
          ((evt) =>
            (evt.key === 'e' || evt.key === '+' || evt.key === '-') &&
            evt.preventDefault()) : () => {}
        }
        disabled={disable}
        className={[
          styles.field,
          styles[renderStyle],
          error ? styles.error : '',
        ].join(' ')}
        value={value}
        placeholder={required ? `${t(label)}*` : t(label)}
        type={type}
        onChange={onChange ? handleChange : null}
        onClick={onClick}
        ref={ref}
        hidden={hidden}
      />

      {unit && (
        <div className={styles.unit}>
          <Typography translationKey={unit} type='body-1' />
        </div>
      )}

      {(error && (typeof error === 'string' || Array.isArray(error))) && (
        <Typography
          className={styles.errorMessage}
          type='caption'
          translationKey={error}
        />
      )}

      {icon && <Icon name={icon} className={styles.icon} color='primary' />}
    </div>
  );
}

export default React.memo(withSuspense(Input));

import * as React from "react";
import Base64FileUploadModal, { ACCEPT_TYPES } from "./Base64FileUploadModal";
import { batchUpdateRefillZone } from "services/location";
import { useQueryClient } from "react-query";

interface Props {
  onClose: () => void;
}

function UploadRefillZoneModal({ onClose }: Props) {
  const queryClient = useQueryClient();
  return (
    <Base64FileUploadModal
      onClose={onClose}
      uploadFn={(base64File) =>
        batchUpdateRefillZone({ type: "csv", data: base64File })
      }
      accept={[ACCEPT_TYPES.XLSX, ACCEPT_TYPES.XLS, ACCEPT_TYPES.CSV]}
      onFileUploadSuccess={() => {
        queryClient.invalidateQueries(["GetLocationsDetailed"]);
        queryClient.invalidateQueries(["GetLocations"]);
      }}
      modalTitle="label_upload_refill_zone"
      placeHolderText="label_select_updating_file"
      uploadButtonLabel="action_update"
    />
  );
}

export default UploadRefillZoneModal;

// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { createMachineInventory } from 'machines/service';

// Types
import { IMachineInventory } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';
import { toasti18n } from 'utils/toast';

interface IUsePostMachineInventory {
  machineId: string,
  machineInventoryData: Partial<IMachineInventory>[]
}

function usePostMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<IMachineInventory[], AxiosError, IUsePostMachineInventory>(
    ({
      machineId,
      machineInventoryData
    }) => createMachineInventory(machineId, machineInventoryData),
    {
      onSuccess: (_, { machineId }) => {
        toasti18n.success("toast_machine_patch_success");
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { usePostMachineInventory };
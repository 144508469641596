import * as React from "react";
import { clsx } from "clsx";
import { MachineStatusEnum } from "gql/generated";

const statusMap = {
  [MachineStatusEnum.Online]: "!bg-success",
  [MachineStatusEnum.Offline]: "!bg-on-surface-disabled",
  [MachineStatusEnum.OutOfService]: "!bg-error",
  [MachineStatusEnum.Maintenance]: "!bg-warning",
  null: "!bg-on-surface-disabled",
};

type MaskerProps = {
  status: string;
  text?: string;
  className?: string;
};

export function Marker({ status, className, text }: MaskerProps): JSX.Element {
  
  return (
    <div
      className={clsx(
        statusMap[status] || "bg-error",
        "rounded-full border-2 border-solid border-white shadow-sm h-6 w-6 flex items-center justify-center",
        className
      )}
    >
      <p className="font-kanit text-body2 text-on-surface-high whitespace-nowrap">{text}</p>
    </div>
  );
}

import * as React from "react";

import { Modal } from "@mui/material";
import DatePicker from "components/DatePicker";
import * as moment from "moment";
import Button from "components/Button";
import analytics from "utils/analytics";
import {
  GenerateReportMutation,
  ReportType,
  useGenerateReportMutation,
} from "gql/generated";
import { useTranslation } from "react-i18next";

type DownloadTransactionReportGroupByLocationModalProps = {
  open: boolean;
  onError?: (data: unknown) => void;
  onSuccess?: (data: GenerateReportMutation) => void;
  onClose?: () => void;
};

export function DownloadTransactionReportGroupByLocationModal({
  onError,
  onSuccess,
  open,
  onClose,
}: DownloadTransactionReportGroupByLocationModalProps) {
  const { t } = useTranslation("location", {
    keyPrefix: "downloadTransactionGroupByLocationReport",
  });
  const [date, setDate] = React.useState<{ start?: Date; end?: Date } | null>(
    null
  );
  const { mutate, isLoading } = useGenerateReportMutation({
    onSuccess: (data) => {
      setDate(null);
      onSuccess?.(data);
    },
    onError: (data) => onError?.(data),
  });

  return (
    <>
      <Modal
        className="flex items-center justify-center fixed !outline-none !outline-0"
        open={open}
        onClose={() => {
          onClose?.();
          analytics.track({
            name: "modal close by backdrop clicked on transaction report modal",
            properties: {},
          });
        }}
      >
        <div className="relative w-full max-w-[420px] bg-white p-5 space-y-3 rounded-md !outline-none !outline-0">
          <h3 className="text-h6 text-on-surface-high font-kanit text-center">
            {t("modal.title")}
          </h3>

          <DatePicker
            value={date?.start ? moment(date?.start) : null}
            label={t("modal.start_date_input_placeholder")}
            maxDate={moment(Date.now())}
            onChange={(e) => {
              setDate((state) => ({
                start: e?.toDate(),
                end: state?.end,
              }));
              analytics.track({
                name: "onChange on download transaction report modal date picker",
                properties: {
                  date: e?.toDate(),
                },
              });
            }}
          />
          <DatePicker
            value={date?.end ? moment(date?.end) : null}
            label={t("modal.end_date_input_placeholder")}
            maxDate={moment(Date.now())}
            onChange={(e) => {
              setDate((state) => ({
                start: state?.start,
                end: e?.toDate(),
              }));
              analytics.track({
                name: "onChange on download transaction report modal date picker",
                properties: {
                  date: e?.toDate(),
                },
              });
            }}
          />
          <div className="flex justify-between gap-3">
            <Button
              type="secondary"
              onClick={() => onClose?.()}
              data-tracking-event="cancel button clicked on request transaction report"
            >
              {t("modal.cancel_button")}
            </Button>
            <Button
              disabled={!date?.start || !date?.end}
              type="primary"
              loading={isLoading}
              onClick={() =>
                date?.start &&
                date?.end &&
                mutate({
                  params: {
                    startDate: moment(date?.start).format("yyyy-MM-DD"),
                    endDate: moment(date?.end).format("yyyy-MM-DD"),
                    reportType: ReportType.SalesGroupByLocation,
                  },
                })
              }
              data-tracking-event="download button clicked on request transaction report modal"
              data-tracking-property-date={date}
            >
              {t("modal.download_button")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

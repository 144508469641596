import { httpClient } from 'common/services/transportService';

import { ILot, ILotTransfer, IProductLots, IWithdrawLotProps, IWithdrawMultipleLotsProps, IWithdrawSingleLotsProps, WithdrawLotResponse } from 'lot/interfaces';

export async function createLot (lotData: Partial<ILot>): Promise<ILot> {
  const response = await httpClient.post<ILot>('/lots', lotData);
  return response.data;
}

export async function editLot (lotData: Partial<ILot>): Promise<ILot> {
  const { lotId, ...data } = lotData;
  const response = await httpClient.patch<ILot>(`/lots/${lotData.lotId}`, data);
  return response.data;
}

export async function withdrawLot (lotData: IWithdrawLotProps): Promise<ILot> {
  const response = await httpClient.put<ILot>('/lots/withdraw', lotData);
  return response.data;
}

export async function getLotsByProduct (productId): Promise<IProductLots> {
  const response = await httpClient.get<IProductLots>('/lots', { params: { productId } });
  return response.data;
}
export async function getLotTransfers (warehouseId: string): Promise<any> {
  const response = await httpClient.get<any>(`/lottransfer?warehouseId=${warehouseId}`);
  return response.data;
}
export async function getLotsForProductAndWarehouse (productId: any, warehouseId: string): Promise<any> {
  const response = await httpClient.get<any>('/lots', { params: { productId, warehouseId } });
  return response.data;
}

export async function postTransferInventory (data: ILotTransfer): Promise<any> {
  const response = await httpClient.post<any>('/lottransfer', data);
  return response.data;
}
export async function postAcceptInventoryTransfer (lotTransactionId: string): Promise<any> {
  const response = await httpClient.post<any>('/lottransfer/accept', {lotTransactionId});
  return response.data;
}

export async function postRejectInventoryTransfer (lotTransactionId: string): Promise<any> {
  const response = await httpClient.post<any>('/lottransfer/reject', {lotTransactionId});
  return response.data;
}

export async function withdrawMultipleLot (data: IWithdrawMultipleLotsProps): Promise<WithdrawLotResponse[]> {
  const response = await httpClient.put<WithdrawLotResponse[]>('/lots/withdraw', data);
  return response.data;
}

export async function withdrawSingleLot (data: IWithdrawSingleLotsProps): Promise<[WithdrawLotResponse]> {
  const response = await httpClient.put<[WithdrawLotResponse]>('/lots/withdraw', data);
  return response.data;
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HistogramCard--2Q9nAy5A7r{display:flex;flex-direction:column}.HistogramCard--2Q9nAy5A7r .Histogram--2lmEkAZt3Y{margin-top:auto}", ""]);
// Exports
exports.locals = {
	"HistogramCard": "HistogramCard--2Q9nAy5A7r",
	"Histogram": "Histogram--2lmEkAZt3Y"
};
module.exports = exports;

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { locale } from 'moment';
import { initReactI18next } from 'react-i18next';

export const localStorageLanguageKey = 'vendii-language';

// Setting the preferred language in local storage if not found
if (localStorage.getItem(localStorageLanguageKey) === null) {
  localStorage.setItem(localStorageLanguageKey, 'en');
}

const lng = localStorage.getItem(localStorageLanguageKey);
// Set lng for moment when logging on
locale(lng);

const buildHash = process.env.BUILD_HASH;

const i18nOptions = {
  // Get preferred language from local storage
  ns: ["translation"],
  defaultNS: "translation",
  lng,
  fallbackLng: 'en',
  debug: false,
  supportedLngs: [ 'en', 'th' ],
  preload: [ 'en', 'th' ],
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json?v=${buildHash}`
  },
  interpolation: {
    escapeValue: false
  }
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init(i18nOptions);

export default i18n;

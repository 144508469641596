import { httpClient } from "common/services/transportService";

import { machineKeys } from "machines/hooks/machineKeys";
import * as React from "react";
import { Dialog } from "@mui/material";
import { FilePicker } from "common/components/FilePicker";
import Button from "components/Button";
import { toasti18n } from "utils/toast";

import { useQueryClient } from "react-query";
import Select from "components/Select";
import { useTranslation } from "react-i18next";
import Icon from "common/components/icon/Icon";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export function OnboardSpiralMachineModalModal({ onClose, isOpen }: Props) {
  const { t } = useTranslation("machine", {
    keyPrefix: "overview_page.onboard_spiral_machine_modal"
  });
  const queryClient = useQueryClient();
  const [based64File, setBase64File] = React.useState<string>("");
  const [fileName, setFileName] = React.useState("");
  const [country, setCountry] = React.useState<OnBoardMachineCountry | "">();
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);

  const isFormDisabled = based64File === "" || country === "";

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files.length === 0) {
      return;
    }
    const xlsx = new FileReader();
    xlsx.readAsDataURL(e.target.files[0]);
    xlsx.onload = () => {
      setBase64File(xlsx.result.toString());
      setFileName(e.target.files[0].name);
    };
  }

  async function handleSubmit() {
    if (!country) return;
    setIsUploadingFile(true);
    try {
      await onboardMachine({
        file: based64File,
        country: country,
      });
      toasti18n.success();
      queryClient.invalidateQueries(machineKeys.all({ extraFields: true }));

      // clear form
      setFileName(null);
      setCountry(null);
      setIsUploadingFile(false);

      onClose();
    } catch (err) {
      setIsUploadingFile(false);
    }
  }

  return (
    <Dialog className="font-kanit" open={isOpen} onClose={isUploadingFile ? () => {} : onClose}>
      <div className="box-border px-5 py-8 max-w-full w-[416px]">
        <div className="mb-8">
          <div className="flex items-center gap-1 justify-center">
            <Icon name="Snack" color="primary" />
            <h3 className="text-h6 text-center">{t("label_title")}</h3>
          </div>
          <a href="https://vendii.notion.site/11955b7f2b368036984fcb86617194e7" target="_blank" className="text-body2 text-center block text-primary-500 leading-5" rel="noreferrer">{t("label_how_to_onboard_link")}</a>
        </div>
        <FilePicker id="XLSXFile" onChange={handleChange} accept={".csv"}>
          <FilePicker.FormField
            icon="Upload"
            iconColor="primary500"
            placeHolderText={t("label_file_input")}
            fileName={fileName}
          />
        </FilePicker>
        <div style={{ height: "10px" }}></div>
        <Select
          required={true}
          label={t("label_country_select_input")}
          id="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          options={[
            {
              value: "Thailand",
              label: "label_thailand",
            },
            {
              value: "Malaysia",
              label: "label_malaysia",
            },
            {
              value: "Australia",
              label: "label_australia",
            },
            {
              value: "Singapore",
              label: "label_singapore",
            },
            {
              value: "United Arab Emirates",
              label: "label_united_arab_emirates",
            },
          ]}
        />
        <div className="flex justify-between mt-10">
          <Button onClick={onClose} disabled={isUploadingFile} type="secondary">
            {t("label_cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={isFormDisabled}
            loading={isUploadingFile}
          >
            {t("label_confirm")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export type OnBoardMachineCountry =
  | "Thailand"
  | "Malaysia"
  | "Australia"
  | "Singapore"
  | "United Arab Emirates";

export async function onboardMachine(body: {
  file: string;
  country: OnBoardMachineCountry;
}) {
  return httpClient.post("/machines/onboard-spiral", {
    file: body.file,
    country: body.country,
  });
}

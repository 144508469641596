import * as React from 'react';

interface IMoveInventoryProps {
  color?: string;
  className?: string;
}

function MoveInventory ({
  color = 'white',
  className
}: IMoveInventoryProps): JSX.Element {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 21.0001C5.30622 21.0001 6.41746 20.1653 6.82929 19.0001H15.5C17.9853 19.0001 20 16.9854 20 14.5001C20 12.0148 17.9853 10.0001 15.5 10.0001H4.5C3.11929 10.0001 2 8.88079 2 7.50008C2 6.11936 3.11929 5.00008 4.5 5.00008H7.58579L6.29289 6.29297L7.70711 7.70718L11.4142 4.00008L7.70711 0.292969L6.29289 1.70718L7.58579 3.00008H4.5C2.01472 3.00008 0 5.01479 0 7.50008C0 9.98536 2.01472 12.0001 4.5 12.0001H15.5C16.8807 12.0001 18 13.1194 18 14.5001C18 15.8808 16.8807 17.0001 15.5 17.0001H6.82929C6.41746 15.8349 5.30622 15.0001 4 15.0001C2.34315 15.0001 1 16.3432 1 18.0001C1 19.6569 2.34315 21.0001 4 21.0001ZM3 18.0001C3 17.4478 3.44772 17.0001 4 17.0001C4.55228 17.0001 5 17.4478 5 18.0001C5 18.5524 4.55228 19.0001 4 19.0001C3.44772 19.0001 3 18.5524 3 18.0001ZM16 1.00008C17.6569 1.00008 19 2.34322 19 4.00008C19 5.65693 17.6569 7.00008 16 7.00008C14.3431 7.00008 13 5.65693 13 4.00008C13 2.34322 14.3431 1.00008 16 1.00008ZM15 4.00008C15 3.44779 15.4477 3.00008 16 3.00008C16.5523 3.00008 17 3.44779 17 4.00008C17 4.55236 16.5523 5.00008 16 5.00008C15.4477 5.00008 15 4.55236 15 4.00008Z'
        fill={color}
      />
    </svg>
  );
}

export default MoveInventory;

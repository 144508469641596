import * as React from "react";
import { Package } from "../types";
import { AbnormalReturnQuantityNotifier, IsReturnQuantityAbnormalReturnType } from "../components/ReturnLessThanExpected";
import { TableHeading } from "../components/TableHeading";
import Typography from "components/Typography";
import * as styles from './AdjustmentModal.module.scss';
import * as colors from 'common/styles/colors.scss';
import { cs, stringifyToAmountInPackageWithRemainder } from '../utils';
import { InventoryCell } from "../components/InventoryCell";

export function ConfirmView({
  packages,
  getPackageActualReturnBag,
  getPackageActualReturnUnit,
  isPackageActualReturnAbnormal,
}: {
  packages: Array<Package>;
  getPackageActualReturnBag: (p: Package) => string;
  getPackageActualReturnUnit: (p: Package) => string;
  isPackageActualReturnAbnormal: (
    p: Package
  ) => IsReturnQuantityAbnormalReturnType;
}) {
  return (
    <div>
      <div className={styles.TableHeader}>
        <div className={styles.InventoryCodeColumn}>
          <TableHeading>label_inventory_code</TableHeading>
        </div>
        <div className={styles.InventoryColumn}>
          <TableHeading>label_inventory</TableHeading>
        </div>
        <div className={styles.PackageSizeColumn}>
          <TableHeading>label_package_size</TableHeading>
        </div>
        <div className={styles.ExpectedReturnColumn}>
          <TableHeading>label_expected_return</TableHeading>
        </div>
        <div className={styles.ActualReturnUnitColumn}>
          <TableHeading>label_actual_return</TableHeading>
        </div>
        <div className={styles.ActualReturnUnitColumn}>
          <TableHeading>
            label_actual_return
            {' ('}
            label_full_pack
            {')'}
          </TableHeading>
        </div>
        <div className={styles.ActualReturnUnitColumn}>
          <TableHeading>
            label_actual_return
            {' ('}
            label_open_bag
            {')'}
          </TableHeading>
        </div>
      </div>

      {packages.map((p) => {
        const actualReturnValue =
          Number(getPackageActualReturnBag(p)) *
            p.packageInfo.productQuantityPerPackage +
          Number(getPackageActualReturnUnit(p));

        const abnormalityStatus = isPackageActualReturnAbnormal(p);
        return (
          <div
            key={p.packageInfo.packageSKU}
            className={styles.TableRow}
            style={{
              backgroundColor: abnormalityStatus.exceededThreshold
                ? colors.warningOverlay
                : undefined,
            }}
          >
            <div
              className={cs(
                styles.InventoryCodeColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" color="onSurfaceHigh">
                {p.packageInfo.packageSKU}
              </Typography>
            </div>
            <div
              className={cs(
                styles.InventoryColumn,
                styles.CellContentAlignment
              )}
            >
              <InventoryCell productPackage={p} />
            </div>

            <div
              className={cs(
                styles.PackageSizeColumn,
                styles.CellContentAlignment
              )}
            >
              <div style={{ paddingRight: '5px' }}>
                <Typography type="body-1" translate color="onSurfaceMedium">
                  {p.packageInfo.productQuantityPerPackage}{' '}
                  {p.packageInfo.productUOMInPackage} /{' '}
                  {p.packageInfo.packageUOM}
                </Typography>
              </div>
            </div>
            <div
              className={cs(
                styles.ExpectedReturnColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {stringifyToAmountInPackageWithRemainder({
                  p,
                  value: p.refillInfo.expectedReturnInProductUOMInPackage,
                  oldQuantity:
                    p.refillInfo.totalOldQuantityInProductUOMInPackage,
                })}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ActualReturnUnitColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {actualReturnValue} {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ActualReturnUnitColumn,
                styles.CellContentAlignment
              )}
            >
              <div style={{ position: 'relative' }}>
                <Typography type="body-1" translate color="onSurfaceMedium">
                  {Number(getPackageActualReturnBag(p))} {'label_bag'}
                </Typography>
                <div style={{ position: 'absolute', top: '120%' }}>
                  {abnormalityStatus.exceededThreshold ? (
                    <AbnormalReturnQuantityNotifier
                      abnormalStatus={abnormalityStatus.abnormalStatus}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={cs(
                styles.ActualReturnUnitColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {Number(getPackageActualReturnUnit(p))}{' '}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}

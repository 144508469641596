import * as React from "react";
import { Column } from "@tanstack/react-table";
import { clsx } from "clsx";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CheckboxOptionFilterMeta } from "components/tableV2/types/columnMetaTypes";
import Checkbox from "components/Checkbox";

type CheckboxFilterProps<T> = {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (selectedValues: string[]) => void;
};

type handleCheckboxChangeType = {
  isCheck: boolean;
  value: string;
  children: string[];
  exclude: string[]; // use for exclude values in state for example parent of checkbox we want to have it in UI but not in state
};

export function CheckboxFilter<T>({
  column,
  className,
  onChange,
}: CheckboxFilterProps<T>) {
  const filterValue = (column.getFilterValue() as string[]) || [];

  if(column.columnDef.meta?.columnFilter?.type !== "checkbox") return null;

  const isNested = column.columnDef.meta?.columnFilter?.options?.some(option => option.parent);

  const handleCheckboxChange = ({
    children,
    exclude,
    value,
    isCheck,
  }: handleCheckboxChangeType) => {
    let newFilterValue: string[];

    if (!isCheck) {
      newFilterValue = filterValue.filter(
        (item) => item !== value && !children.includes(item)
      );
    } else {
      newFilterValue = [...filterValue, value, ...children];
    }

    // Exclude values from newFilterValue
    newFilterValue = newFilterValue.filter((item) => !exclude.includes(item));

    // remove duplicate
    newFilterValue = [...new Set(newFilterValue)];

    if (newFilterValue.length === 0) {
      newFilterValue = undefined;
    }

    column.setFilterValue(newFilterValue);
    onChange?.(newFilterValue);
  };

  const renderOptions = (parent?: string) => {
    const columnFilter = column.columnDef.meta?.columnFilter;
    if (columnFilter?.type !== "checkbox") return null;
    const options: CheckboxOptionFilterMeta[] = columnFilter?.options;
    return options
      .filter((option) => option.parent === parent)
      .map((option) => {
        const children = options
          .filter((child) => child.parent === option.value)
          .map((child) => child.value);
        const isParentChecked = filterValue.includes(option.value);
        const areAllChildrenChecked =
          children.every((child) => filterValue.includes(child)) &&
          children.length > 0;
        const isChecked = isParentChecked || areAllChildrenChecked;

        return (
          <div key={option.value} className={clsx(parent ? "pl-8 bg-overlay-surface-04 py-1" : "py-1")}>
            <FormControlLabel
              sx={{marginLeft: 0}}
              className="space-x-2 !font-kanit"
              control={
                <Checkbox
                  checked={
                    parent
                      ? isParentChecked
                      : isParentChecked || areAllChildrenChecked
                  }
                  indeterminate={
                    !isChecked &&
                    children.some((child) => filterValue.includes(child))
                  }
                  onChange={(e) =>
                    handleCheckboxChange({
                      value: option.value,
                      children,
                      isCheck: e.target.checked,
                      exclude: columnFilter.exclude || [],
                    })
                  }
                  name={option.value}
                  disabled={columnFilter.disabled}
                />
              }
              label={<p className="font-kanit">{option.label}</p>}
            />
            <div className="my-1">{renderOptions(option.value)}</div>
          </div>
        );
      });
  };

  return (
    <div className={clsx("w-full font-kanit", className)}>
      <div className={clsx(!isNested && "flex")}>{renderOptions()}</div>
    </div>
  );
}

/* eslint-disable no-redeclare */
import * as React from "react";

// Hooks
import { useState } from "react";

// Custom components
import Icon from "../icon/Icon";
import Typography from "components/Typography";
import Button from "../button/Button";
import Input from "../input/Input";
import DatePicker from "components/DatePicker/DatePicker";

import * as moment from "moment";

// Styles
import * as styles from "./FilterBar.module.scss";

export type IFilterOption =
  | {
      field: string;
      queryType: "contains";
      translationKey: string;
    }
  | {
      field: string;
      queryType: "date";
      translationKey: string;
    };

export type IAppliedFilter =
  | { field: string; queryVerb: "contains"; value: string }
  | { field: string; queryVerb: "date"; value: string };

export interface IPureFilterBarProps {
  className?: string;
  onChange: (appliedFilter: IAppliedFilter | null) => void;
  value: IAppliedFilter | null;
  placeholder?: string | undefined;
  filterOptions: Array<IFilterOption>;
  disabled?: boolean;
  style?: React.CSSProperties;
}
export interface IMultiplePureFilterBarProps {
  className?: string;
  onChange: (appliedFilter: Array<IAppliedFilter>) => void;
  value: Array<IAppliedFilter>;
  placeholder?: string | undefined;
  filterOptions: Array<IFilterOption>;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export function PureFilterBar(props: IPureFilterBarProps): JSX.Element;
export function PureFilterBar(props: IMultiplePureFilterBarProps): JSX.Element;
export function PureFilterBar({
  className,
  onChange,
  value,
  placeholder,
  filterOptions,
  disabled = false,
  style,
}: IPureFilterBarProps | IMultiplePureFilterBarProps): JSX.Element {
  const isMultipleSelectionMode = Array.isArray(value);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [step, setStep] = useState<"menu" | "picker">("menu");
  const [currentFilterOption, setCurrentFilterOption] =
    useState<IFilterOption | null>({
      field: "",
      queryType: "contains",
      translationKey: "",
    });

  function resetComponentState() {
    setStep("menu");
    setCurrentFilterOption({
      field: "",
      queryType: "contains",
      translationKey: "",
    });
  }

  function onFilterClose() {
    setIsFilterOpen(false);
    resetComponentState();
  }

  function onFilterChipClick(field?: string) {
    if (isMultipleSelectionMode) {
      onChange(value.filter((filter) => filter.field !== field) as any);
    } else {
      onChange(null);
    }
  }

  function onFilterOptionSelect(selectedFilterOption: IFilterOption) {
    setCurrentFilterOption(selectedFilterOption);
    setStep("picker");
  }

  function onFilterApply(appliedFilter: IAppliedFilter) {
    if (isMultipleSelectionMode) {
      onChange([
        ...value.filter((elem) => elem.field !== appliedFilter.field),
        appliedFilter,
      ] as any);
    } else {
      onChange(appliedFilter as any);
    }
    setIsFilterOpen(false);
    resetComponentState();
  }

  return (
    <div className={[styles.FilterBar, className].join(" ")} style={style}>
      <div
        onClick={() => setIsFilterOpen(!isFilterOpen)}
        className={[
          styles.iconWrapper,
          disabled || filterOptions.length === 0 ? styles.disabled : "",
        ].join(" ")}
      >
        <Icon
          name="Filter"
          color={isFilterOpen ? "primary" : "onSurfaceHigh"}
          className={styles.icon}
        />
      </div>

      {value === null || (Array.isArray(value) && value.length === 0) ? (
        <Typography
          type="body-1"
          className={styles.searchPlaceholder}
          translate
        >
          {placeholder}
        </Typography>
      ) : Array.isArray(value) ? (
        value.map((filter) => (
          <FilterChip
            key={filter.field}
            translationKey={
              filterOptions.find((option) => option.field === filter.field)
                .translationKey
            }
            appliedFilter={filter}
            onRemove={() => onFilterChipClick(filter.field)}
          />
        ))
      ) : (
        <FilterChip
          translationKey={
            filterOptions.find((option) => option.field === value.field)
              .translationKey
          }
          appliedFilter={value}
          onRemove={() => onFilterChipClick()}
        />
      )}

      {isFilterOpen && filterOptions.length !== 0 && (
        <div className={styles.Picker}>
          {step === "menu" && (
            <FilterMenu
              filterOptions={filterOptions}
              onFilterOptionSelect={onFilterOptionSelect}
            />
          )}
          {step === "picker" && (
            <Picker
              option={currentFilterOption}
              onApply={onFilterApply}
              onBack={() => setStep("menu")}
              onClose={() => onFilterClose()}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface IFilterChipProps {
  translationKey: string;
  appliedFilter: IAppliedFilter;
  onRemove: () => void;
}

function FilterChip({
  translationKey,
  appliedFilter,
  onRemove,
}: IFilterChipProps): JSX.Element {
  return (
    <div className={styles.ExistingQuery}>
      <span className={styles.field}>
        <Typography type="body-2" translate>
          {translationKey}
        </Typography>
      </span>
      <span className={styles.value}>
        <Typography type="body-2">{`: ${
          appliedFilter.queryVerb === "date"
            ? moment(Number(appliedFilter.value)).format("DD/MM/YYYY")
            : appliedFilter.value
        }`}</Typography>
      </span>
      &nbsp;
      <span className={styles.iconWrapper} onClick={onRemove}>
        <Icon name="CloseFilled" color="primary500" className={styles.icon} />
      </span>
    </div>
  );
}

interface IFilterMenuProps {
  filterOptions: Array<IFilterOption>;
  onFilterOptionSelect: (filterOption: IFilterOption) => void;
}

function FilterMenu({
  filterOptions,
  onFilterOptionSelect,
}: IFilterMenuProps): JSX.Element {
  return (
    <div className={styles.menuStep}>
      {filterOptions.map((option) => {
        return (
          <div
            key={option.translationKey}
            className={styles.menuOption}
            onClick={() => onFilterOptionSelect(option)}
          >
            <Typography type="body-1" translate>
              {option.translationKey}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}

interface IPickerProps {
  option: IFilterOption;
  onApply: (appliedFilter: IAppliedFilter) => void;
  onBack: () => void;
  onClose: () => void;
}

function Picker({
  option,
  onApply,
  onBack,
  onClose,
}: IPickerProps): JSX.Element {
  const [filterValue, setFilterValue] = useState<string | null>("");

  return (
    <div className={styles.pickerStep}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <div onClick={onBack} className={styles.backIcon}>
            <Icon name="Chevron" color="surface" />
          </div>
          <Typography
            type="body-1"
            translate
            className={styles.title}
            color="surface"
          >
            {option.translationKey}
          </Typography>
        </div>

        <div onClick={onClose} className={styles.iconWrapper}>
          <Icon name="Close" color="surface" />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.valuePicker}>
          {option.queryType === "date" ? (
            <DatePicker
              label={option.translationKey}
              value={filterValue === "" ? null : moment(Number(filterValue))}
              onChange={(e) => {
                if (e === null || (moment.isMoment(e) && !e.isValid())) {
                  setFilterValue("");
                } else if (moment.isMoment(e)) {
                  setFilterValue(String(e.startOf("day").valueOf()));
                }
              }}
            />
          ) : null}
          {option.queryType === "contains" ? (
            <Input
              label="label_contains"
              renderStyle="line"
              value={filterValue}
              onChange={(value) => setFilterValue(value)}
            />
          ) : null}
        </div>

        <div className={styles.footer}>
          <Button
            translationKey="label_apply"
            onClick={() => {
              onApply({
                field: option.field,
                queryVerb: option.queryType,
                value: filterValue,
              });
            }}
            type="secondary"
            disabled={filterValue === "" || filterValue === null}
          />
        </div>
      </div>
    </div>
  );
}

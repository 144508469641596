import * as React from "react";
import { FormProps } from "./FormView";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { AbnormalReturnQuantityNotifier } from "../components/ReturnLessThanExpected";
import { cs, stringifyToAmountInPackageWithRemainder } from "../utils";
import { InventoryCell } from "../components/InventoryCell";

import * as styles from "./AdjustmentModal.module.scss";
import * as colors from "common/styles/colors.scss";
import { TableHeading } from "../components/TableHeading";

export function TaobinInventoryListHeader() {
  return (
    <div className={styles.TableHeader}>
      <div className={styles.InventoryCodeColumn}>
        <TableHeading>label_inventory_code</TableHeading>
      </div>
      <div className={styles.InventoryColumn}>
        <TableHeading>label_inventory</TableHeading>
      </div>
      <div className={styles.PackedBagColumn}>
        <TableHeading>
          label_packed
          {" ("}
          label_bag
          {")"}
        </TableHeading>
      </div>
      <div className={styles.PackedUOMColumn}>
        <TableHeading>
          label_packed
          {" ("}
          label_unit
          {")"}
        </TableHeading>
      </div>
      <div className={styles.FilledColumn}>
        <TableHeading>
          label_filled
          {" ("}
          label_unit
          {")"}
        </TableHeading>
      </div>
      <div className={styles.PackageSizeColumn}>
        <TableHeading>label_package_size</TableHeading>
      </div>
      <div className={styles.ExpectedReturnColumn}>
        <TableHeading>label_expected_return</TableHeading>
      </div>
      <div className={styles.ActualReturnColumn}>
        <TableHeading>
          label_actual_return
          {" ["}
          label_full_pack
          {" + "}
          label_open_bag
          {"("}
          {"label_unit"}
          {")"}
          {"]"}
        </TableHeading>
      </div>
    </div>
  );
}

export function TaobinInventoryListView({
  getPackageActualReturnBag,
  getPackageActualReturnUnit,
  onPackageActualReturnUnitChange,
  onPackageActualReturnBagChange,
  isPackageActualReturnAbnormal,
  packages,
}: FormProps) {
  return (
    <div>
      {packages.map((p) => {
        const abnormalityStatus = isPackageActualReturnAbnormal(p);
        return (
          <div
            key={p.packageInfo.packageSKU}
            className={styles.TableRow}
            style={{
              backgroundColor: abnormalityStatus.exceededThreshold
                ? colors.warningOverlay
                : undefined,
            }}
          >
            <div
              className={cs(
                styles.InventoryCodeColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" color="onSurfaceHigh">
                {p.packageInfo.packageSKU}
              </Typography>
            </div>
            <div
              className={cs(
                styles.InventoryColumn,
                styles.CellContentAlignment
              )}
            >
              <InventoryCell productPackage={p} />
            </div>
            <div
              className={cs(
                styles.PackedBagColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productPackedInPackages}
              </Typography>
            </div>
            <div
              className={cs(
                styles.PackedUOMColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productPackedInProductUOMInPackage}{" "}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(styles.FilledColumn, styles.CellContentAlignment)}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productFilledInProductUOMInPackage}{" "}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(
                styles.PackageSizeColumn,
                styles.CellContentAlignment
              )}
            >
              <div style={{ paddingRight: "5px" }}>
                <Typography type="body-1" translate color="onSurfaceMedium">
                  {p.packageInfo.productQuantityPerPackage}{" "}
                  {p.packageInfo.productUOMInPackage} /{" "}
                  {p.packageInfo.packageUOM}
                </Typography>
              </div>
            </div>
            <div
              className={cs(
                styles.ExpectedReturnColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {stringifyToAmountInPackageWithRemainder({
                  p,
                  value: p.refillInfo.expectedReturnInProductUOMInPackage,
                  oldQuantity:
                    p.refillInfo.totalOldQuantityInProductUOMInPackage,
                })}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ActualReturnColumn,
                styles.CellContentAlignment
              )}
              style={{ position: "relative" }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1px",
                      alignItems: " center",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "4px",
                      }}
                      className={cs(
                        styles.InputWhiteBgOnFocus,
                        getPackageActualReturnBag(p) === ""
                          ? styles.InputEmpty
                          : styles.InputFilled
                      )}
                    >
                      <TextField
                        value={getPackageActualReturnBag(p)}
                        onChange={(e) =>
                          onPackageActualReturnBagChange(p, e.target.value)
                        }
                        required
                        label={"label_quantity_short"}
                      />
                    </div>

                    <div
                      style={{
                        width: "30px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        type="body-1"
                        translate
                        color="onSurfaceMedium"
                      >
                        label_bag
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "1px",
                      alignItems: " center",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "4px",
                      }}
                      className={cs(
                        styles.InputWhiteBgOnFocus,
                        getPackageActualReturnUnit(p) === ""
                          ? styles.InputEmpty
                          : styles.InputFilled
                      )}
                    >
                      <TextField
                        value={getPackageActualReturnUnit(p)}
                        onChange={(e) =>
                          onPackageActualReturnUnitChange(p, e.target.value)
                        }
                        required
                        label={"label_unit"}
                      />
                    </div>
                    <div
                      style={{
                        width: "30px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography type="body-1" color="onSurfaceMedium">
                        {p.packageInfo.productUOMInPackage}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ position: "absolute", bottom: -1 }}>
                {abnormalityStatus.exceededThreshold ? (
                  <AbnormalReturnQuantityNotifier
                    abnormalStatus={abnormalityStatus.abnormalStatus}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

import * as React from 'react';
import { Select, FormControl, SelectProps, FormControlProps } from '@mui/material';
import { clsx } from 'clsx';

type CustomSelectProps = SelectProps & {
  FormControlProps?: FormControlProps;
}

export function CustomSelect({ label, children, FormControlProps, ...props }: CustomSelectProps) {
  return (
    <FormControl variant="outlined"  {...FormControlProps}>
      <Select className={clsx("w-full", props.className)} {...props}>
        {children}
      </Select>
    </FormControl>
  );
};



import {
  IWarehouse,
  IAddWarehouseParams,
  IInviteWarehouseUser,
  IDeleteWarehouseTeam
} from 'warehousing/interfaces';

import { IUsers } from 'userandauth/interfacesUser';
import { httpClient } from 'common/services/transportService';

export async function getWarehouses (): Promise<IWarehouse[]> {
  const response = await httpClient.get<IWarehouse[]>('/warehouse');
  return response.data;
}

export async function postWarehouse (data: IAddWarehouseParams): Promise<IWarehouse[]> {
  const response = await httpClient.post<IWarehouse[]>('/warehouse', data);
  return response.data;
}

export async function getWarehouseById (warehouseId: string): Promise<IWarehouse[]> {
  const response = await httpClient.get<IWarehouse[]>(`/warehouse/${warehouseId}`);
  return response.data;
}

export async function editWarehouse (warehouseId, data): Promise<IWarehouse[]> {
  const response = await httpClient.patch<IWarehouse[]>(`/warehouse/${warehouseId}`, data);
  return response.data;
}
// can return either XLXS or CSV according to requirement (see usage in DownloadHistoryModal.tsx)
export async function getInventoryHistory (param:string): Promise<string> {
  const response = await httpClient.get<string>(`/warehouse/report/downloadInventory?${param}`, {
    responseType: 'arraybuffer'
  });
  return response.data;
};

export async function getDownloadSummaryXLSX (warehouseId:string): Promise<string> {
  const response = await httpClient.get<string>(`/warehouse/warehouseReport/${warehouseId}`, {
    responseType: 'arraybuffer'
  });
  return response.data;
};

export async function getWarehouseUsers (warehouseId:string): Promise<IUsers[]>{
  const response = await httpClient.get<IUsers[]>(`/warehouse/${warehouseId}/users`);
  return response.data;
}

export async function deleteWarehouseUser ( {warehouseId, userId} :IDeleteWarehouseTeam): Promise<string>{
  const response = await httpClient.delete<string>(`/warehousePermission/${warehouseId}/${userId}`);
  return response.data;
}

export async function inviteWarehouseUser ( userData :IInviteWarehouseUser): Promise<string>{
  const response = await httpClient.post<string>('/warehousePermission', userData);
  return response.data;
}

export async function syncBCLot(): Promise<string> {
  const response = await httpClient.post("/bcSync/syncLot", undefined, {
    timeout: 2 * 60 * 1000,
  });
  return response.data;
}

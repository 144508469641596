import { AxiosError } from "axios";
import i18n from "common/i18n";
import { useMutation, useQueryClient } from "react-query";

import { IOrganisation, IPatchOrganisation } from "userandauth/interfacesUser";
import { patchOrganisation } from "userandauth/servicesUser";
import { toasti18n } from "utils/toast";


function usePatchOrganisation () {
    const queryClient = useQueryClient();

    return useMutation<IOrganisation, AxiosError, IUsePatchOrganisation>(
      ({newOrgData, orgId}) => patchOrganisation(newOrgData, orgId),
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          toasti18n.success(i18n.t('toast_user_patch_success'));
        }
      }
    );
  }
  
  export { usePatchOrganisation };

  interface IUsePatchOrganisation {
    orgId: string,
    newOrgData: IPatchOrganisation
  }

import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import { QueryFunctionContext, UseQueryOptions, useQuery } from "react-query";

const warehouseSupportedMachineKey = {
  supportedMachines: (warehouseId: string) =>
    [
      {
        scope: "supportedMachines",
        warehouseId,
        url: `/machines?warehouseId=:warehouseId`,
        resolvedUrl: `/machines?warehouseId=${warehouseId}`,
      },
    ] as const,
};
type WarehouseSupportedMachineQueryKey = ReturnType<
  typeof warehouseSupportedMachineKey["supportedMachines"]
>;

type SupportedMachines = Array<{
  id: string;
  type: string;
  name: string;
  lat?: number;
  lng?: number;
  address?: string;
  addressType?: string;
  status?: string;
  inventoryIsLow: boolean;
  additional?: Additional;
  mute: boolean;
  autoCreated: boolean;
  serial?: string;
  latestStatus?: LatestStatus;
  latestAlarm?: LatestAlarm;
  lastRefilled?: string;
  lastTransaction?: string;
  deployed: boolean;
  rent: any;
  zone?: string;
  defaultRouteman: any;
  bankNoteCount?: number;
  cashValue?: string;
  notes: string;
  serviceZone?: string;
  recommendTickets: boolean;
  locationId: string;
  registered: boolean;
  createdAt: string;
  updatedAt: string;
  warehouseId: any;
  organisationId: string;
  organisationName: string;
  organisationAddress: string;
}>;

export interface Additional {
  notes: string;
}

export interface LatestStatus {
  code: number;
  time: string;
  machineId: string;
  availability: string;
}

export interface LatestAlarm {
  code: number;
  time: string;
  codeDetails: CodeDetails;
  availability: string;
}

export interface CodeDetails {
  en: string;
  th: string;
  raw: string;
  photo?: string;
  status: string;
  solution?: string;
  identifier?: string;
  description?: string;
}

async function getSupportedMachines(
  context: QueryFunctionContext<WarehouseSupportedMachineQueryKey>
) {
  const [{ warehouseId }] = context.queryKey;
  const response = await httpClient.get<SupportedMachines>("/machines", {
    params: { warehouseId },
  });
  return response.data;
}

type GetWarehouseProductByIdQueryOptions = Omit<
  UseQueryOptions<
    SupportedMachines,
    AxiosError,
    SupportedMachines,
    WarehouseSupportedMachineQueryKey
  >,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetWarehouseSupportedMachinesByIdBase({
  warehouseId,
  options = {},
}: {
  warehouseId: string;
  options?: GetWarehouseProductByIdQueryOptions;
}) {
  return useQuery({
    queryKey: warehouseSupportedMachineKey.supportedMachines(warehouseId),
    queryFn: getSupportedMachines,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetWarehouseSupportedMachinesById(
  warehouseId: string,
  options?: GetWarehouseProductByIdQueryOptions
) {
  return useGetWarehouseSupportedMachinesByIdBase({
    warehouseId: warehouseId,
    options,
  });
}

import Checkbox from "common/components/checkbox/Checkbox";
import * as React from "react";
import { get } from "lodash";

interface CheckBoxCellProps {
  checkedFn: (rowDataKey: string) => boolean;
  key: string;
  onClick: (rowDataKey: string, checked: boolean, rowData: object) => void;
}

function CheckBoxCell({ checkedFn, key, onClick }: CheckBoxCellProps) {
  function rowDataAcceptor(rowData: object) {
      const checked = Boolean(checkedFn(get(rowData, key)));
      return (
        <Checkbox
          checked={checked}
          onClick={() => {
            onClick(get(rowData, key), !checked, rowData);
          }}
        />
      );
    }
  return rowDataAcceptor;
}

function HeaderCheckBoxCell({ onClick, checked }) {
  return function Component() {
    return <Checkbox checked={checked} onClick={onClick} />;
  };
}

export { CheckBoxCell, HeaderCheckBoxCell };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LastUpdatedIndicator--HRU_CTUl2j{display:flex;flex-direction:column;align-items:start;min-width:140px}", ""]);
// Exports
exports.locals = {
	"LastUpdatedIndicator": "LastUpdatedIndicator--HRU_CTUl2j"
};
module.exports = exports;

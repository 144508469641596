import * as React from "react";
import { Column } from "@tanstack/react-table";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Icon from "common/components/icon/Icon";

export function CustomDebouncedInput<T>({
  column,
  onChange,
}: {
  column: Column<T, unknown>;
  onChange?: (e: string) => void;
}) {
  const filterValue = column.getFilterValue() as string;
  const label = column.columnDef.meta?.columnFilter?.label;

  return (
    <>
      <OutlinedInput
        id={column.id}
        className="sm:!min-w-[300px] !pl-2 !font-kanit"
        label={label}
        value={filterValue || ""}
        notched={false}
        size="small"
        placeholder={
          column.columnDef.meta?.columnFilter?.placeholder ||
          column.columnDef.meta?.columnFilter?.label ||
          column.id
        }
        onChange={(e) => {
          column.setFilterValue(e.target.value);
          onChange?.(e.target.value);
        }}
        inputProps={{
          className: "!rounded-l-none !border-l-0",
        }}
        startAdornment={
          <InputAdornment position="start">
            <Icon name="Search" color="onSurfaceMedium" />
          </InputAdornment>
        }
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderLeftColor: "transparent",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          },
        }}
      />
    </>
  );
}

import * as React from "react";
import {
  GetFactoryLocationsDocument,
  GetFactoryLocationsQuery,
  GetFactoryLocationsQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";
import { downloadCSVFromJSON } from "utils/csv";
import * as moment from 'moment';
import { toasti18n } from "utils/toast";

async function getLocationsWithFactoryTypeFetcher() {
  const factoryLocations = await fetcher<
    GetFactoryLocationsQuery,
    GetFactoryLocationsQueryVariables
  >(GetFactoryLocationsDocument)();
  return factoryLocations;
}

export function useDownloadFactoryLocationsCSV() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function downloadFactoryLocationsMutate() {
    try {
      setIsLoading(true);
      const factoryLocationsData = await toasti18n.promise(getLocationsWithFactoryTypeFetcher());
      downloadCSVFromJSON(
        factoryLocationsData?.locationsV2?.edges?.map((edge) => {
          if (edge.node.__typename === "Factory") {
            return {
              "Location ID": edge?.node?.friendlyId,
              "Location Name": edge?.node.locationName,
              Province: edge?.node.province,
              Organisation: edge?.node?.organisation?.name,
              "Machine IDs":
                edge.node.machines &&
                edge.node.machines
                  .filter((machine) => machine.registered)
                  .map((machine) => machine.id)
                  .join(", "),
            };
          }
          return null;
        })
        ,`factories-${moment().format("DD-MM-YYYY")}`
      );
    } catch (error) {
      toasti18n.error({ message: "error" });
      throw  new Error("Error in downloading factories locations");
    } finally {
      setIsLoading(false);
    }
  }
  return { isLoading, downloadFactoryLocationsMutate };
}

import { ElectricFee } from "containers/contract/hooks/useGetLocationFee";
import { ElectricityRateType, GetLocationByIdQuery } from "gql/generated";

export function mapDataToElectricFeeType (electricFee: GetLocationByIdQuery["locationAggregate"]["electricFee"]): ElectricFee {
  if(!electricFee) {
    return {
      type: "FIXED",
      value: "",
    };
  }

  if(electricFee.type === ElectricityRateType.FixedRate) {
    return {
      type: "FIXED",
      value: electricFee.value.toString(),
    };
  }

  if(electricFee.type === ElectricityRateType.ActualUsage) {
    return {
      type: "PAY_PER_USAGE",
      value: electricFee.value.toString()
    };
  }
}

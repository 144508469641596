/**
 * This is copied from https://github.com/ubilabs/google-maps-react-hooks/blob/develop/library/src/google-maps-provider.tsx
 * but the useEffect script tear down is removed to prevent
 * google maps from reinitializing
 */
import {useState, useEffect, PropsWithChildren} from 'react';
import * as React from 'react';
import { GoogleMapsContext, GoogleMapsProviderProps } from '@ubilabs/google-maps-react-hooks';

const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/js';

// https://developers.google.com/maps/documentation/javascript/url-params

/**
 * The global Google Maps provider
 */
export const GoogleMapsProvider: React.FunctionComponent<
  PropsWithChildren<GoogleMapsProviderProps>
> = props => {
  const {
    children,
    googleMapsAPIKey,
    mapContainer,
    mapOptions,
    libraries,
    language,
    region,
    version,
    authReferrerPolicy,
    onLoadScript,
    onLoadMap
  } = props;

  const [isLoadingAPI, setIsLoadingAPI] = useState<boolean>(true);
  const [map, setMap] = useState<google.maps.Map>();

  // Handle Google Maps API loading
  // eslint-disable-next-line complexity
  useEffect(() => {
    const apiLoadingFinished = () => {
      setIsLoadingAPI(false);
      onLoadScript && onLoadScript();
    };

    const defaultLanguage = navigator.language.slice(0, 2);
    const defaultRegion = navigator.language.slice(3, 5);

    /* eslint-disable camelcase */
    const params = new URLSearchParams({
      key: googleMapsAPIKey,
      language: language || defaultLanguage,
      region: region || defaultRegion,
      ...(libraries?.length && {libraries: libraries.join(',')}),
      ...(version && {v: version}),
      ...(authReferrerPolicy && {auth_referrer_policy: authReferrerPolicy})
    });
    /* eslint-enable camelcase */

    const existingScriptTag: HTMLScriptElement | null = document.querySelector(
      `script[src^="${GOOGLE_MAPS_API_URL}"]`
    );

    // Check if Google Maps API was loaded with the passed parameters
    if (existingScriptTag) {
      const loadedURL = new URL(existingScriptTag.src);
      const loadedParams = loadedURL.searchParams.toString();
      const passedParams = params.toString();

      if (loadedParams !== passedParams) {
        console.error(
          'The Google Maps API Parameters passed to the `GoogleMapsProvider` components do not match. The Google Maps API can only be loaded once. Please make sure to pass the same API parameters to all of your `GoogleMapsProvider` components.',
          '\n\nExpected parameters:',
          Object.fromEntries(loadedURL.searchParams),
          '\n\nReceived parameters:',
          Object.fromEntries(params)
        );
      }
    }

    if (typeof google === 'object' && typeof google.maps === 'object') {
      // Google Maps API is already loaded
      apiLoadingFinished();
    } else if (existingScriptTag) {
      // Google Maps API is already loading
      setIsLoadingAPI(true);

      const onload = existingScriptTag.onload;
      existingScriptTag.onload = event => {
        onload?.call(existingScriptTag, event);
        apiLoadingFinished();
      };
    } else {
      // Load Google Maps API
      setIsLoadingAPI(true);

      // Add google maps callback
      window.mapsCallback = () => {
        apiLoadingFinished();
      };

      params.set('callback', 'mapsCallback');

      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.src = `${GOOGLE_MAPS_API_URL}?${params.toString()}`;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }

    // Do not remove google map scripts and also google maps when unmounting
    // // Clean up Google Maps API
    // return () => {
    //   // Remove all loaded Google Maps API scripts
    //   document
    //     .querySelectorAll('script[src^="https://maps.googleapis.com"]')
    //     .forEach(script => {
    //       script.remove();
    //     });

    //   // Remove google.maps global
    //   if (typeof google === 'object' && typeof google.maps === 'object') {
    //     // @ts-ignore: The operand of a 'delete' operator must be optional.
    //     delete google.maps;
    //   }
    // };
  }, [
    googleMapsAPIKey,
    JSON.stringify(libraries),
    language,
    region,
    version,
    authReferrerPolicy
  ]);

  // Handle Google Maps map instance
  useEffect(() => {
    // Check for google.maps is needed because of Hot Module Replacement
    if (
      isLoadingAPI ||
      !mapContainer ||
      !(typeof google === 'object' && typeof google.maps === 'object')
    ) {
      return () => {};
    }

    const newMap = new google.maps.Map(mapContainer, mapOptions);

    google.maps.event.addListenerOnce(newMap, 'idle', () => {
      if (onLoadMap && newMap) {
        onLoadMap(newMap);
      }
    });

    setMap(newMap);

    // Remove all map related event listeners
    return () => {
      if (
        newMap &&
        typeof google === 'object' &&
        typeof google.maps === 'object'
      ) {
        google.maps.event.clearInstanceListeners(newMap);
      }
    };
  }, [isLoadingAPI, mapContainer]);

  return (
    <GoogleMapsContext.Provider
      value={{map, googleMapsAPIIsLoaded: !isLoadingAPI}}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
import * as React from "react";
import { Box, Tooltip, Typography } from '@mui/material';
import Icon from "common/components/icon/Icon";

type RecommendationIconProps = {
  icon: "Diamond" | "LightBulb";
  message: string
  recommendedOrder?: number
}

function RecommendationIcon({ icon, message, recommendedOrder }: RecommendationIconProps): JSX.Element {
  return (
    <Tooltip
      title={message}
      placement="top"
      arrow
    >
      <Box
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Icon name={icon} color="primary500" className="w-4 h-4" />

        {recommendedOrder && (
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              bottom: -4,
              left: 2 - (8 * recommendedOrder.toString().length),
              fontSize: "0.75rem"
            }}
          >
            {recommendedOrder}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
}

export default RecommendationIcon;

import * as React from "react";

interface IPriorityLowProps {
  className?: string;
}

function PriorityLow({ className }: IPriorityLowProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5212 5.14982C13.0212 4.84982 13.6212 5.04982 13.9212 5.54982C14.2212 6.04982 14.0212 6.54982 13.5212 6.84982L8.52124 9.84982C8.22124 10.0498 7.82124 10.0498 7.52124 9.84982L2.52124 6.84982C2.02124 6.54982 1.92124 5.94982 2.22124 5.44982C2.42124 5.04982 3.02124 4.84982 3.52124 5.14982L8.02124 7.84982L12.5212 5.14982Z"
        fill="#3DA49F"
      />
    </svg>
  );
}

export default PriorityLow;

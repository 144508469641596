import { useEditReturnItemMutation } from "gql/generated";
import { toasti18n } from "utils/toast";
import { useQueryClient } from "react-query";

export function useEditAdjustedReturnItem({ onSuccess }: { onSuccess: () => void; }) {
  const queryClient = useQueryClient();
  return useEditReturnItemMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async (_, vars) => {
      await queryClient.invalidateQueries([
        "GetCompletedPlanById",
        { planId: vars.planId },
      ]);
      onSuccess();
      toasti18n.success();
    },
  });
}

import * as React from "react";
import { KittingModalState } from "./useKittingModalState";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import Checkbox from "components/Checkbox";
import { Tooltip } from "@mui/material";
import { InventoryBarWithSelection } from "containers/new-operations/refill-order/InventoryBar";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";
import {
  STATUS_COLOR_MAP,
  STATUS_TRANSLATION_KEY_MAP,
} from "../../../Components";
import {
  trackKittingAmountAdjusted
} from "./tracking";
import analytics from "utils/analytics";
import * as styles from "./KittingModal.module.scss";

export function InventoryTable({
  inventory,
  inventoryValidity,
  isForecastingDisabled,
  state,
}: {
  inventory: KittingModalState["machines"][string]["inventory"];
  inventoryValidity: KittingModalState["form"]["inventoryValidity"][string];
  isForecastingDisabled: boolean;
  state: KittingModalState;
}) {
  const sortedInventory = Object.values(inventory).sort(
    (inventoryA, inventoryB) => alphanumeric(inventoryA.slot, inventoryB.slot)
  );

  return (
    <div>
      <div className={styles.KittingTableHeader}>
        <div className={styles.ColumnSlot}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_slot
          </Typography>
        </div>
        <div className={styles.ColumnSKU}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_sku
          </Typography>
        </div>
        <div className={styles.ColumnImage}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_image
          </Typography>
        </div>
        <div className={styles.ColumnIngredient}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_ingredient
          </Typography>
        </div>
        <div className={styles.ColumnLevel}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_level
          </Typography>
        </div>
        <div className={styles.ColumnInventory}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_inventory
          </Typography>
        </div>
        <div className={styles.ColumnStatus}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_status
          </Typography>
        </div>
        <div className={styles.ColumnAvailable}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_available_stocks
          </Typography>
        </div>
        <div className={styles.ColumnPackages}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_packages
          </Typography>
        </div>
      </div>

      {sortedInventory.map((inventory) => (
        <div
          key={inventory.inventoryId}
          className={
            inventoryValidity[inventory.inventoryId] === "INVALID"
              ? styles.KittingTableRowError
              : inventoryValidity[inventory.inventoryId] === "WARN"
                ? styles.KittingTableRowWarning
                : inventory.selected
                  ? styles.KittingTableRowSelected
                  : styles.KittingTableRow
          }
        >
          <div className={styles.ColumnSlot}>
            <div className={styles.SlotCell}>
              <Checkbox
                onClick={inventory.toggleInventorySelection}
                onChange={(e) => {
                  analytics.track({
                    name: "slot adjusted",
                    properties: {
                      machine_id: inventory.machineId,
                      current_level: inventory.inventoryLevel.current,
                      value: e.target.checked,
                      ingredient_name: inventory.productToUse.name,
                      ingredient_sku: inventory.productToUse.sku,
                      location_id: state.machines[0]?.location?.id,
                      machine_priority: state.machines[0]?.refillPriority,
                      plan_name: state.plan.planName,
                      remaining_amount:
                        inventory.productToUse.availablePackages,
                      slot_name: inventory.slot,
                      slot_status: inventory.inventoryStatus,
                      recommended_action: inventory.recommendedAction,
                      suggested_refill_amount:
                        state.aggregates.numberOfRecommendedRefillSlots,
                    },
                  });
                }}
                checked={inventory.selected}
                disabled={
                  inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE"
                }
              />
              <div className={styles.SlotCellText}>
                <Typography type="body-2">{inventory.slot}</Typography>
              </div>
              <div className={styles.RecommendedIconContainer}>
                {inventory.recommendedAction === "REFILL" ? (
                  <Icon name="Diamond" color="primary500" />
                ) : inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE" ? (
                  <Icon name="RefreshAlert" color="primary500" />
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.ColumnSKU}>
            <div className={styles.SKUTextContainer}>
              <Typography type="caption" className={styles.SKUTextStyles}>
                {inventory.productToUse.sku}
              </Typography>
              {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
              inventory.recommendedAction === "SWAP_REPLACE" ? (
                <div className={styles.SKUTextContainer}>
                  <Typography
                    type="caption"
                    className={styles.SKUTextStyles}
                    color="onSurfaceDisabled"
                  >
                    {inventory.currentProduct.sku}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>

          <div className={styles.ColumnImage}>
            <div className={styles.ImageContainer}>
              {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
              inventory.recommendedAction === "SWAP_REPLACE" ? (
                <div className={styles.IconContainer}>
                  <Icon name="RefreshAlert" color="primary500" />
                </div>
              ) : null}
              {inventory.productToUse.img ? (
                <img
                  src={inventory.productToUse.img}
                  className={styles.Image}
                  alt={inventory?.productToUse?.name}
                  style={{
                    opacity:
                      inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                      inventory.recommendedAction === "SWAP_REPLACE"
                        ? "50%"
                        : undefined,
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.ColumnIngredient}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceHigh">
                    {inventory.productToUse.name}
                  </Typography>
                </div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE" ? (
                    <Typography type="caption" color="onSurfaceDisabled">
                      {inventory.currentProduct.name}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ColumnLevel}>
            {inventory.recommendedAction !== "SWAP_REPLACE" ? (
              <Typography type="body-2" color="onSurfaceHigh">
                {`${inventory.inventoryLevel.current} ${inventory.productToUse.uom}`}
              </Typography>
            ) : (
              <Tooltip
                followCursor={true}
                title={
                  <Typography type="caption" translate color="background">
                    label_current_level_to_be_removed
                  </Typography>
                }
              >
                <div>
                  <Typography type="body-2" color="onSurfaceDisabled">
                    {`(${inventory.inventoryLevel.current} ${inventory.productToUse.uom})`}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </div>
          <div className={styles.ColumnInventory}>
            <InventoryBarWithSelection
              selected={inventory.selected}
              inventory={{
                autoRefill: inventory.isAutoRefillOn,
                capacity: inventory.inventoryLevel.capacity,
                currentLevel: inventory.inventoryLevel.current,
                highLevel: inventory.inventoryLevel.high,
                lowLevel: inventory.inventoryLevel.low,
                pendingSwap: inventory.pendingSwapMaterial,
                uom: inventory.productToUse.uom,
                isForecastingDisabled: isForecastingDisabled,
                swapType:
                  inventory.recommendedAction === "SWAP_REFILL_ON_TOP"
                    ? "REFILL_ON_TOP"
                    : inventory.recommendedAction === "SWAP_REPLACE"
                      ? "REPLACE"
                      : null,
              }}
              amountTaken={
                inventory.productToUse.amountUsedInPackages *
                inventory.productToUse.packageSize
              }
              recommendation={{
                baseline: inventory.inventoryLevel.baseline,
                recommendedAmount:
                  inventory.productToUse.amountRequired.recommendAmount,
                recommendedAmountRoundedToPackageSize: inventory.productToUse.amountRequired.roundedToPackageSize * inventory.productToUse.packageSize,
              }}
            />
          </div>
          <div className={styles.ColumnStatus}>
            <Typography
              type="body-2"
              translate
              color={STATUS_COLOR_MAP[inventory.inventoryStatus]}
            >
              {STATUS_TRANSLATION_KEY_MAP[inventory.inventoryStatus]}
            </Typography>
          </div>
          <div className={styles.ColumnAvailable}>
            <Typography
              type="body-2"
              color={STATUS_COLOR_MAP[inventory.inventoryStatus]}
            >
              {inventory.productToUse.availablePackages ?? "N/A"}
            </Typography>
          </div>
          <div className={styles.ColumnPackages}>
            <div className={styles.PackageCell}>
              <div
                className={styles.PackageIconContainer}
                onClick={
                  inventory.productToUse.amountUsedInPackages === 0
                    ? () => {}
                    : () =>{
                      inventory.productToUse.changePackageNumber(" decrement");
                      
                      trackKittingAmountAdjusted({
                        inventory,
                        adjustment: "decrease",
                        state,
                      });
                    }}
              >
                <Icon
                  name="CircleMinus"
                  color={
                    inventory.productToUse.amountUsedInPackages === 0
                      ? "onSurfaceDisabled"
                      : "primary500"
                  }
                />
              </div>
              <div className={styles.PackageNumberContainer}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {inventory.productToUse.amountUsedInPackages}
                </Typography>
              </div>
              <div
                className={styles.PackageIconContainer}
                onClick={() => {
                  inventory.productToUse.changePackageNumber("increment");
                  trackKittingAmountAdjusted({
                    inventory,
                    adjustment: "increase",
                    state
                  });
                }}
              >
                <Icon name="CirclePlus" color={"primary500"} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

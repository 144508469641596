import * as React from "react";
import { createColumnHelper } from "components/tableV2";
import { IMachineInventoryWithPercentageData } from "./mapDataToTable";
import { TFunction } from "i18next";
import ProgressBar from "common/components/progressbar/ProgressBar";
import { ExpirationDateCell } from "./cell/ExpirationDateCell";
import { MachineInventoryStatusCell } from "./cell/MachineInventoryStatusCell";
import ChangeIngredientOption from "../ChangeIngredientOption";
import { SelectButtonCell } from "common/components/table/cells/Cells";
import { TableActionHandlerProps, TablePermissionProps } from ".";
import { ColumnDef } from "@tanstack/react-table";
import Icon from "common/components/icon/Icon";

const columnHelper = createColumnHelper<IMachineInventoryWithPercentageData>();

type generateNonAdminColumnsType = {
  t: TFunction<"machine", undefined>;
  action: TableActionHandlerProps;
  permission: TablePermissionProps;
};

export function generateInventoryColumns({ t, action, permission }: generateNonAdminColumnsType) {
  const columns: ColumnDef<IMachineInventoryWithPercentageData>[] = [
    columnHelper.accessor("slot", {
      header: () => <div className="whitespace-nowrap">{t("label_slot")}</div>,
      cell: (info) => <div className="flex items-center gap-2">
        <p>{info.getValue()}</p>
        {info.row.original.swapStatus !== "idle" && <Icon name="RefreshAlert" className="w-6 h-6" color="primary" />}
      </div>,
      enableSorting: true,
      minSize: 50,
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          order: 2,
          label: t("label_slot"),
        }
      }
    }),
    columnHelper.accessor("inventoryCode", {
      header: () => <>{t("label_code")}</>,
      cell: (info) => info.getValue(),
      minSize: 50,
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          order: 3,
          label: t("label_code"),
        }
      }
    }),
    columnHelper.accessor("image", {
      header: t("label_image"),
      cell: (info) => <>{info.getValue() ? <img className="w-[50px] h-11 object-contain" src={info.getValue()} alt="Avatar" />: <div className="w-[50px] h-11 bg-gray-100" />}</>,
      minSize: 70,
    }),
    columnHelper.accessor("inventory", {
      header: () => <>{t("label_ingredient")}</>,
      cell: (info) => info.getValue(),
      minSize: 200,
      enableSorting: true,
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          order: 1,
          label: t("label_ingredient"),
        }
      }
    }),
    columnHelper.accessor("value", {
      header: () => <>{t("label_quantity")}</>,
      cell: (info) => <p className="text-body2 whitespace-nowrap">{info.getValue().toFixed(2) + info?.row?.original?.uom}</p>,
      minSize: 70,
    }),
    columnHelper.accessor("refillPercentage", {
      header: () => <>{t("label_inventory")}</>,
      cell: (info) => {
        const percentage = info.getValue() * 100;
        const roundedPercentage = Math.round(percentage);
        let color = 'success';
        const warningValue = info?.row?.original?.warningPercentage ? info?.row?.original?.warningPercentage * 100 : 30;
        if (percentage < warningValue) {
          color = 'warning';
        }

        const label: string[] = [];
        if (info.row.original?.autoRefill) {
          label.push("label_auto_refill_on");
        }
  
        if (info.row.original?.noPartialRefill) {
          if (label.length !== 0) {
            label.push(", ");
          }
          label.push("label_no_partial_refill");
        }
        
        return <div>
          <ProgressBar
            color={color}
            label={label}
            labelClassName={info.row.original?.autoRefill ? "": ""}
            percentage={roundedPercentage}
          />
        </div>;
      },
      minSize: 200,
    }),
    columnHelper.accessor("lastRefilled", {
      header: () => <>{t("label_expiration_date")}</>,
      cell: (info) => <ExpirationDateCell lastRefilled={info.getValue()} expiryDate={info.row.original?.expiryDate} />,
      minSize: 120,
    }),
    columnHelper.accessor("status", {
      header: () => <>{t("label_status")}</>,
      cell: (info) => <MachineInventoryStatusCell status={info.getValue()}  />,
      minSize: 70,
    }),
  ];

  // Add action column if the user has admin permissions
  if(permission.isAdmin) {
    const actionColumn = columnHelper.display({
      id: "action",
      header: () => <>{t("")}</>,
      cell: (info) => SelectButtonCell({
        label: "",
        className: "SmallSelectButtonCell",
        idKey: "id",
        buttonProps: {
          type: "secondary",
          preIcon: {
            name: "ChevronThick",
            color: "primary",
          },
        },
        options: [
          permission.isForthCKUser
            ? undefined
            : {
                label: "action_edit_quantity",
                onClick: action.handleQuantityEdit,
              },
          { label: "action_update_min_max", onClick: action.handleMinMaxEdit },
          {
            label: "",
            onClick: () => {},
            optionComponent: (data) => (
              <ChangeIngredientOption
                data={data}
                isEnableWorkOrderCreation={permission.isMachineEnableWorkOrderCreation}
                handleIngredientEdit={action.handleIngredientEdit}
                handleSwapInventoryCancel={action.handleSwapInventoryCancel}
              />
            ),
          },
          { label: "action_active_slot", onClick: action.handleActive },
          { label: "action_inactive_slot", onClick: action.handleDisable },
        ].filter(Boolean),
      })(info.row.original)
    });
    columns.push(actionColumn);
  }
  return columns;
}

import { IFilterOption } from "common/components/filterbar/PureFilterBar";
import { ITableRowKeys } from "./mapDataToTable";

export const filterOptions: Array<IFilterOption & { field: ITableRowKeys }> = [
  {
    field: "table.ticketIdFrontend",
    queryType: "contains",
    translationKey: "label_ticket_number",
  },
  {
    field: "misc.machineId",
    queryType: "contains",
    translationKey: "label_machine_id",
  },
  {
    field: "table.machineName",
    queryType: "contains",
    translationKey: "label_machine_name",
  },
  {
    field: "table.organisation",
    queryType: "contains",
    translationKey: "label_organisation",
  },
  {
    field: "table.serviceZone",
    queryType: "contains",
    translationKey: "label_service_zone",
  },
];

// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { patchMachineInventories } from 'machines/service';

// Types
import { IMachineInventoryData } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';
import { toasti18n } from 'utils/toast';

interface IUsePatchMachineInventory {
  machineId: string,
  machineInventories: Partial<IMachineInventoryData>[]
}

function usePatchMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<IMachineInventoryData[], AxiosError, IUsePatchMachineInventory>(
    ({
      machineId,
      machineInventories
    }) => patchMachineInventories(machineId, machineInventories),
    {
      onSuccess: (_, { machineId }) => {
        toasti18n.success("toast_machine_patch_success");
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { usePatchMachineInventory };
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from "@mui/material/Tooltip";
import Icon from 'common/components/icon/Icon';

import * as styles from './Inventory.module.scss';

type UncancellableSwapOrderTooltipProps = {
  show: boolean
}

function UncancellableSwapOrderTooltip({ show }: UncancellableSwapOrderTooltipProps): JSX.Element {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  return (
    <Tooltip
      title={t("label_unable_to_cancel_material_change_reason")}
      placement="top"
      className={styles.Tooltip}
      arrow
    >
      <div>
        <Icon
          name="QuestionCircle"
          color="primary500"
          className={styles.TooltipIcon}
        />
      </div>
    </Tooltip>
  );
}

export default UncancellableSwapOrderTooltip;

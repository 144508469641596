// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RemoveMachineUserModal--S5A2deZ5Gv{display:flex;flex-direction:column;min-width:400px;min-height:200px}.RemoveMachineUserModal--S5A2deZ5Gv .warning--1caAMOi2Dw{margin-top:20px;padding:10px 20px;border-radius:4px;background-color:#f8cecb;max-width:400px}.RemoveMachineUserModal--S5A2deZ5Gv .warning--1caAMOi2Dw .name--1pYFlqJHJI{font-weight:bolder !important;margin-right:10px}.RemoveMachineUserModal--S5A2deZ5Gv .confirmText--MPoEaqqW1O{margin:20px 0}.RemoveMachineUserModal--S5A2deZ5Gv .action--154C5vUhVx{display:flex;justify-content:space-between;margin-top:auto}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "warning--1caAMOi2Dw",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"RemoveMachineUserModal": "RemoveMachineUserModal--S5A2deZ5Gv",
	"name": "name--1pYFlqJHJI",
	"confirmText": "confirmText--MPoEaqqW1O",
	"action": "action--154C5vUhVx"
};
module.exports = exports;

import { Exact, ReturningQuantity, Scalars } from "gql/generated";
import analytics from "utils/analytics";
import { AdjustedPlan } from "./modals/types";
import { TableRow } from "./Complete";

type variables = Exact<{
  planId: Scalars["ID"];
  inventories: Array<ReturningQuantity> | ReturningQuantity;
}>;

type PlanReturnSubmittedProps = {
  plan: TableRow;
  adjustedPlan: AdjustedPlan;
  variables: variables;
};
export function PlanReturnSubmitted({ plan, adjustedPlan ,variables }: PlanReturnSubmittedProps) {
  const { totalPackageReturn, totalUnitReturn } = CalculateReturnPackages(variables);
  let InventoriesData = [];
  if (variables.inventories instanceof Array) {
    InventoriesData = variables.inventories.map((inventory) => {
      const packageInfo = adjustedPlan?.packages?.find(
        (p) => p.packageInfo.product.id === inventory?.productId
      );
      return {
        productId: inventory.productId,
        name: packageInfo?.packageInfo?.product?.id,
        package_take: packageInfo?.refillInfo?.productPackedInPackages,
        unit_take: packageInfo?.refillInfo?.productPackedInProductUOMInPackage,
        package_return: inventory?.actualBag,
        unit_return: inventory?.actualRemaining,
        unit: packageInfo.packageInfo.productUOMInPackage,
      };
    });
  }

  analytics.track({
    name: "plan return submitted",
    properties: {
      total_package_return: totalPackageReturn,
      total_unit_return: totalUnitReturn,
      Inventories_data: InventoriesData,
      plan_name: plan?.planName,
      plan_id: plan?.planId,
      warehouse: plan?.warehouseName,
      no_of_completed_machine: plan?.taoBinCompleted + plan?.spiralCompleted,
      no_of_machine_in_plan: plan?.taoBinInPlan + plan?.spiralInPlan,
      // spiral machine
      no_of_completed_spiral_machine: plan?.spiralCompleted,
      no_of_spiral_machine_in_plan: plan?.spiralInPlan,
      // taobin machine
      no_of_completed_taobin_machine: plan?.taoBinCompleted,
      no_of_taobin_machine_in_plan: plan?.taoBinInPlan,
    },
  });
}


function CalculateReturnPackages (variables: variables) {
  if(!Array.isArray(variables.inventories)) {
    return {
      totalPackageReturn: variables.inventories.actualBag,
      totalUnitReturn: variables.inventories.actualRemaining,
    };
  }

  return variables.inventories.reduce((acc, current) => {
    return {
       totalPackageReturn: acc.totalPackageReturn + current.actualBag,
       totalUnitReturn: acc.totalUnitReturn + current.actualRemaining,
     };
  }, { totalPackageReturn: 0, totalUnitReturn: 0 });
};
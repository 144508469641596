export const CATEGORY = {
  COFFEE: "0",
  SPIRAL: "1",
  BEVERAGE: "2",
} as const;

export type Category = typeof CATEGORY[keyof typeof CATEGORY];

const CATEGORY_TRANSLATIONS = {
  COFFEE: "label_coffee",
  [CATEGORY.COFFEE]: "label_coffee",
  SPIRAL: "label_spiral",
  [CATEGORY.SPIRAL]: "label_spiral",
  BEVERAGE: "label_beverage",
  [CATEGORY.BEVERAGE]: "label_beverage",
} as const;

export function getCategoryTranslation(
  key: Category | keyof typeof CATEGORY | (string & {})
): undefined | string {
  return CATEGORY_TRANSLATIONS[key];
}

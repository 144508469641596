import * as React from "react";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import * as styles from "./TextField.module.scss";

export default function TextField({
  label,
  required,
  className,
  InputProps,
  InputLabelProps,
  ...rest
}: TextFieldProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <MuiTextField
      fullWidth
      className={className}
      label={
        Array.isArray(label)
          ? label.map((s: string) => (s.includes("_") ? t(s) : s)).join("")
          : t(label as string)
      }
      InputProps={{ ...InputProps, className: styles.TextField }}
      InputLabelProps={{
        ...InputLabelProps,
        className: required ? styles.LabelRequired : styles.Label,
      }}
      {...rest}
    />
  );
}

import Button from "components/Button";
import { useUpdateLocationMutation } from "gql/generated";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { toasti18n } from "utils/toast";
import { Factory } from "../../types";
import { PageTitle } from "../../components/PageTitle";
import {
  mapLocationInfoToGeneralLocationInfoFormValue,
  GeneralLocationInfoForm,
  GeneralLocationInfoFormValue,
} from "../../components/GeneralLocationInfo";
import { MachineIds } from "../../components/MachineIds";
import { InfoPanel } from "containers/location/components/ui/InfoPanel";

export function FactoryForm({
  factory,
  canEdit,
}: {
  factory: Factory;
  canEdit: boolean;
}) {
  if (!canEdit) {
    throw new Error("User do not have permission to edit factory");
  }

  const [generalLocationFormValue, setGeneralLocationFormValue] =
    React.useState(
      mapLocationInfoToGeneralLocationInfoFormValue(factory.locationInfo)
    );

  const history = useHistory();
  const useUpdateFactoryResult = useUpdateFactory({
    onSuccess: () => {
      history.push("/location/" + factory.locationInfo.id);
    },
  });
  function submitForm() {
    useUpdateFactoryResult.submit({
      locationId: factory.locationInfo.id,
      generalLocationInfo: generalLocationFormValue,
    });
  }

  const CTA = () => <div className="flex gap-4">
    <Link
      to={`/location/${factory.locationInfo.id}`}
      style={{
        pointerEvents: useUpdateFactoryResult.isLoading
          ? "none"
          : "auto",
      }}
    >
      <Button
        type="secondary"
        disabled={useUpdateFactoryResult.isLoading}
      >
        action_cancel
      </Button>
    </Link>
    <Button
      type="primary"
      onClick={submitForm}
      loading={useUpdateFactoryResult.isLoading}
    >
      action_save
    </Button>
  </div>;

  return (
    <>
      <PageViewHeader location={factory} cta={<CTA />} />
      <div className="space-y-4">
        <InfoPanel
          title="label_general"
          body={
            <>
              <MachineIds machineIds={factory.machineIds} />
              <GeneralLocationInfoForm
                initialOrgOptions={[
                  {
                    label: factory.locationInfo.organization.name,
                    value: factory.locationInfo.organization.id,
                  },
                ]}
                value={generalLocationFormValue}
                onChange={setGeneralLocationFormValue}
              />
            </>
          }
        />

        <CTA />
      </div>
    </>
  );
}
type UpdateFactoryFormValue = {
  locationId: string;
  generalLocationInfo: GeneralLocationInfoFormValue;
};
function useUpdateFactory({ onSuccess }: { onSuccess: () => void }) {
  const { mutate, ...rest } = useUpdateLocationMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      toasti18n.success();
      onSuccess();
    },
  });

  function submit(form: UpdateFactoryFormValue) {
    mutate({
      input: {
        id: form.locationId,
        address: {
          province: form.generalLocationInfo.address.province,
          street: form.generalLocationInfo.address.street,
          city: "",
          country: "",
          postalCode: "",
        },
        coordinates: {
          latitude: form.generalLocationInfo.coordinates.latitude,
          longitude: form.generalLocationInfo.coordinates.longitude,
        },
        googleMapLink: form.generalLocationInfo.googleMapLink,
        name: form.generalLocationInfo.name,
        type: form.generalLocationInfo.type,
        organizationId: form.generalLocationInfo.organizationId,
      },
    });
  }
  return { submit, ...rest };
}

export function PageViewHeader({ location, cta }: { location: Factory, cta?: React.ReactNode; }) {
  return (
    <div className="flex items-center py-4 gap-4 justify-between">
      <PageTitle location={location} />
      {cta}
    </div>
  );
}

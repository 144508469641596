import * as React from "react";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import { Modal } from "containers/warehousing/components/ReusableModal";
import { Title } from "components/ResponsiveModal";
import { toasti18n } from "utils/toast";
import { useRemoveLocationFromContractMutation } from "gql/generated";
import { contractByIdCache } from "containers/contract/hooks/useGetContractById";
import { locationFeeCache } from "containers/contract/hooks/useGetLocationFee";


export function 
RemoveLocationFromContractButton({
  contractId, contractFriendlyId, locationId,
}: {
  contractId: string;
  contractFriendlyId: string;
  locationId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button
        style={{
          display: "grid",
          placeContent: "center",
          fontFamily: "monospace",
          color: "inherit",
          border: "none",
          cursor: "pointer",
          background: "transparent",
        }}
        onClick={() => setIsOpen(true)}
      >
        <CloseButton />
      </button>
      {isOpen ? (
        <RemoveLocationFromContractModal
          contractId={contractId}
          contractFriendlyId={contractFriendlyId}
          locationId={locationId}
          onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}
function RemoveLocationFromContractModal(props: {
  locationId: string;
  contractId: string;
  contractFriendlyId: string;
  onClose: () => void;
}) {
  const { mutate, isLoading } = useRemoveLocationFromContractMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async () => {
      toasti18n.success();
      props.onClose();
      setTimeout(() => {
        contractByIdCache.removeLocationFromContract({
          contractId: props.contractId,
          locationId: props.locationId,
        });
        locationFeeCache.removeLocationFee({
          contractId: props.contractId,
          locationId: props.locationId,
        });
      }, 0);
    },
  });

  function submit() {
    mutate({
      input: {
        contractId: props.contractId,
        locationId: props.locationId,
      },
    });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "400px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_remove_location_from_contract</Title>

      <div
        style={{
          marginTop: "25px",
        }}
      >
        <Typography type="subtitle-1" translate>
          label_you_are_removing_location_desc_1
          {` ${props.contractFriendlyId} `}
          label_you_are_removing_location_desc_2
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "29px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button type="status" onClick={submit} loading={isLoading}>
          action_confirm
        </Button>
      </div>
    </Modal>
  );
}
function CloseButton() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.0001" cy="8.0001" r="6.4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 1.33325C4.31325 1.33325 1.33325 4.31325 1.33325 7.99992C1.33325 11.6866 4.31325 14.6666 7.99992 14.6666C11.6866 14.6666 14.6666 11.6866 14.6666 7.99992C14.6666 4.31325 11.6866 1.33325 7.99992 1.33325ZM11.3333 10.3933L10.3933 11.3333L7.99992 8.93992L5.60658 11.3333L4.66658 10.3933L7.05992 7.99992L4.66658 5.60658L5.60658 4.66658L7.99992 7.05992L10.3933 4.66658L11.3333 5.60658L8.93992 7.99992L11.3333 10.3933Z"
        fill="#4B4D9B" />
    </svg>
  );
}

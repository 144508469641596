import { DashboardCardLineGraphProps } from '../organisms/DashboardCard';
import { APILineChart } from 'dashboard/interface';

export function mapDataToLineChartCard (
  dataPoint: APILineChart): Omit<DashboardCardLineGraphProps, 'className'> {
  return {
    type: 'line',
    cardProps: {
      title: dataPoint.title,
      icon: dataPoint.icon,
      color: dataPoint.title.toLowerCase().includes('error') ? "#E95D50" : "#2C2E78",
      aggregates: dataPoint.fields.map(({ type, header, value }) => ({
        type,
        header,
        value
      })),
      labels: dataPoint.chart.data.labels.map((lb) => {
        if (Array.isArray(lb)) {
          return lb.map((l) => l.toString());
        }
        return lb.toString();
      }),
      data: dataPoint.chart.data.points,
      scales: {
        xAxis: {
          min: dataPoint.chart.data.min_x,
          max: dataPoint.chart.data.max_x
        },
        yAxis: {
          min: dataPoint.chart.data.min_y,
          max: dataPoint.chart.data.max_y
        }
      }
    }
  };
}
// Hook
import { useQuery } from "react-query";

// Interface
import { AxiosError } from "axios";
import { IProduct } from "product/interfaces";

// Keys
import { productKeys } from "./productKeys";

// Service
import { getProducts } from "product/service";

function useGetWarehouseProducts(
  warehouseId: string,
  { enabled = true, keepPreviousData = false } = {}
) {
  return useQuery<IProduct[], AxiosError>(
    productKeys.warehouseProduct(warehouseId),
    () => getProducts({ warehouseId }),
    {
      enabled: enabled,
      keepPreviousData: keepPreviousData,
      staleTime: 10 * 1000,
    }
  );
}

export { useGetWarehouseProducts };

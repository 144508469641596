import * as React from "react";
import { toasti18n } from "utils/toast";
import * as moment from "moment";
import { downloadCSVFromJSON } from "utils/csv";
import i18n from "common/i18n";
import { getMonitorMachines } from 'machines/service';

async function downloadLastSellCSV() {
  function generateRelativeTimeString(timeStamp: number): string {
    const differenceInSeconds = Math.floor(
      (moment().valueOf() - timeStamp) / 1000
    );
    const differenceInMins = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMins / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInDays >= 1) {
      return `${differenceInDays} ${i18n.t("label_day")}`;
    }
    if (differenceInHours >= 1) {
      return `${differenceInHours} ${i18n.t("label_hour_short")}`;
    }

    if (differenceInMins >= 1) {
      return `${differenceInMins} ${i18n.t("label_minute_short")}`;
    }

    return `${differenceInSeconds} ${i18n.t("label_second_short")}`;
  }

  const machines = await getMonitorMachines();

  const records = (machines || [])
    .sort((a, b) => {
      if (a.lastSell === 0 || a.lastSell === undefined) return 1;
      else if (b.lastSell === 0 || b.lastSell === undefined) return -1;
      else return a.lastSell - b.lastSell;
    })
    .map((machine) => ({
      "Machine ID": machine.machineId,
      "Machine Name": machine.machineName,
      "Organisation Name": machine.organisationName,
      "Last sell (Relative)": machine.lastSell
        ? generateRelativeTimeString(machine.lastSell)
        : undefined,
      "Last Sell (Date/Time)": machine.lastSell
        ? moment(Number(machine.lastSell)).format("DD/MM/YYYY, HH:mm:ss z")
        : undefined,
    }));
  downloadCSVFromJSON(
    records,
    `Last sell report ${moment().format("DD-MM-YYYY--HH-mm-ss z")}`
  );
}

export function useDownloadLastSellReport() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function downloadLastSellReport() {
    try {
      setIsLoading(true);
      await toasti18n.promise(downloadLastSellCSV());
    } catch (error) {
      toasti18n.error({ message: "error" });
      throw new Error("Error downloading machine last sell report");
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadLastSellReport, isLoading };
}

import analytics, { TrackProps } from "utils/analytics";

type tableV2TrackingProps = TrackProps & {
  tableName: string;
}

export function tableV2Tracking({name, properties, tableName}: tableV2TrackingProps) {
  analytics.track({
    name: `tableV2: ${name}`,
    properties: {
      table_name: tableName,
      ...properties
    }
  });
}
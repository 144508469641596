import * as React from "react";
import { DailySalesGroupByErrorFormattedData, generateDateRange } from "./mapDataToTable";
import { createColumnHelper } from "components/tableV2";
import { DateRange } from "./table";
import { useTranslation } from "react-i18next";

const columnHelper = createColumnHelper<DailySalesGroupByErrorFormattedData>();

export function useGenerateColumnConfig({from, to } :DateRange) {
  const { t, i18n } = useTranslation("transaction", {
    keyPrefix: "transaction_group_by_non_page.columns"
  });
  

  const dateRangeColumns = React.useMemo(() => {
    const dates = generateDateRange({ from, to });
    return dates.map((date) => columnHelper.accessor("dates", {
      id: date,
      header: () => <>{date}</>,
      cell: (info) => {
        const DateCount = info.row.original.dates.find(item => item.date === date);
        if(!DateCount) return "-";
        return <>{DateCount.count > 0 ? <span className="min-w-8 inline-block box-border text-center rounded-[4px] text-error font-bold px-2 py-1 bg-red-100">{DateCount.count}</span> : <span className="text-on-surface-medium">0</span>}</>;
      },
      sortingFn: (a,b) => {
        const aDateCount = a.original.dates.find(item => item.date === date)?.count || 0;
        const bDateCount = b.original.dates.find(item => item.date === date)?.count || 0;
        return aDateCount - bDateCount;
      },
      enableColumnFilter: false,
      enableSorting: true,
      minSize: 70,
    }));
  }, [from, to]);

  // i18n.language === "th" ? 
  const errorDescriptionTH = columnHelper.accessor("errorMessageTh", {
    id: "error_description_th",
    enableHiding: true,
    header: () => <>{t("label_description")}</>,
    cell: (info) => <>{info.getValue() || "-"}</>,
    enableColumnFilter: true,
    minSize: 200,
    meta: {
      columnFilter: {
        label: t("label_description"),
        location: "primary",
        type: "text"
      },
      hide: i18n.language !== "th"
    }
  });
  const errorDescriptionEN = columnHelper.accessor("errorMessageEn", {
    id: "error_description_en",
    header: () => <>{t("label_description") || "-"}</>,
    cell: (info) => <>{info.getValue() || "-"}</>,
    enableColumnFilter: true,
    minSize: 200,
    meta: {
      columnFilter: {
        label: t("label_description"),
        location: "primary",
        type: "text"
      },
      hide: i18n.language !== "en"
    }
  });
  
  return [
    columnHelper.accessor("errorCode", {
      header: () => <>{t("label_error_code")}</>,
      cell: (info) => <>{info.getValue()}</>,
      enableColumnFilter: true,
      minSize: 120,
      meta: {
        columnFilter: {
          label: t("label_error_code"),
          location: "primary",
          type: "text"
        }
      }
    }),
    errorDescriptionTH,
    errorDescriptionEN,
    columnHelper.accessor("count", {
      header: () => <div className="flex-1 text-right min-w-[100px]">{t("label_total_count")}</div>,
      cell: (info) => <div className="text-right font-bold pr-6">{info.getValue()}</div>,
      enableSorting: true,
      enableColumnFilter: false,
      minSize: 70,
    }),
    ...dateRangeColumns,
  ];
}

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { IPatchUserRole } from 'userandauth/interfacesUser';
import { userKeys } from './userKeys';
import { patchUserRole } from 'userandauth/servicesUser';
import { toasti18n } from 'utils/toast';
import i18n from 'common/i18n';

function usePatchUserRole() {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, IUsePatchUser>(
    ({ newUserData, userId }) => patchUserRole(newUserData, userId),
    {
      onSuccess: (_, { userId }) => {
        toasti18n.success(i18n.t('toast_user_patch_success'));
        queryClient.invalidateQueries(userKeys.user(userId));
        queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
}

export { usePatchUserRole };

interface IUsePatchUser {
  userId: string;
  newUserData: IPatchUserRole;
}

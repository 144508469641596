import * as React from "react";
import Icon from "common/components/icon/Icon";
import { clsx } from 'clsx';
import analytics from "utils/analytics";

type SortStatus = "ASC" | "DESC" | "NONE";

export function TableHeader({
  columnId,
  sort,
  children,
  onClick,
}: {
  columnId?: string;
  sort?: SortStatus | undefined;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  if (sort === undefined) {
    return (
      <div className="font-kanit text-body2 text-brainstorming-black-tint-300">
        {children}
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => {
        onClick?.();
        columnId && analytics.track({
          name: "sorting applied",
          properties: {
            column_name: columnId,
            can_sort: true,
            ascend_or_descend: formatSortTrackingMessage(sort)
          }
        });
      }}
    >
      <div className="flex-1">
        <div
          className={clsx(
            "font-kanit text-body2 text-brainstorming-black-tint-300",
            sort === "NONE"
              ? "text-brainstorming-black-tint-300"
              : "text-on-surface-high"
          )}
        >
          {children}
        </div>
      </div>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {sort === "NONE" ? (
          <Icon name="SortChevrons" color="onSurfaceDisabled" />
        ) : (
          <div
            style={{
              transform:
                sort === "ASC"
                  ? "rotate(-90deg)"
                  : sort === "DESC"
                  ? "rotate(90deg)"
                  : undefined,
              marginRight: "8px",
              display: "grid",
              placeContent: "center",
            }}
          >
            <Icon name="Chevron" color="onSurfaceHigh" className="w-1.5" />
          </div>
        )}
      </div>
    </div>
  );
}


// Formats a tracking message for sort actions based on the current sort status.
function formatSortTrackingMessage(sort?: SortStatus): string {
  switch (sort) {
    // If the current sort status is ASC (ascending),
    // the next action will be DESC (descending).
    case "ASC":
      return "DESC";
    // If the current sort status is DESC (descending),
    // the next action will be NONE (no sorting).
    case "DESC":
      return "NONE";
    // If the current sort status is undefined or any other value,
    // the next action will be ASC (ascending) by default.
    default:
      return "ASC";
  }
}
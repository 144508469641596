import * as React from "react";

import Typography from "common/components/typography/Typography";
import { Redirect, Switch, Route, Link } from "react-router-dom";

import {
  NewTicket,
  InProgress,
  Blocked,
  WaitingForApproval,
  FollowUp,
  Close,
} from "./machine-ticket-tabs";

import { useGetTicketStatusCountsQuery } from "gql/generated";

import * as styles from "./MachineTicket.module.scss";
import TabButton from "components/TabButton";
import { useIsAdmin, useIsAdminContract, useIsClerk, useIsOperator, useIsTechnician } from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

export function MachineTicket(): JSX.Element {
  const canViewContent = useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsClerk() || useIsTechnician();

  const { data, isLoading } = useGetTicketStatusCountsQuery();
  
  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeadingContainer}>
          <Typography type="headline-5" translationKey="workspace_service" />
          <div className={styles.HeadingTabs}>
            <Link
              to="/service/machine-ticket"
              className={[styles.Tab, styles.ActiveTab].join(" ")}
            >
              <Typography type="body-2" translationKey="label_machine_ticket" />
            </Link>
          </div>
        </div>
        <div className={styles.TabButtonsContainer}>
          <TabButton
            to="/service/machine-ticket/newticket"
            number={isLoading ? null : data.ticketStatus.ready}
            type="status"
          >
            label_new_ticket
          </TabButton>
          <TabButton
            to="/service/machine-ticket/inprogress"
            number={isLoading ? null : data.ticketStatus.in_progress}
            type="status"
          >
            label_in_progress
          </TabButton>
          <TabButton
            to="/service/machine-ticket/blocked"
            number={isLoading ? null : data.ticketStatus.blocked}
            type="status"
          >
            label_blocked
          </TabButton>
          <TabButton
            to="/service/machine-ticket/waitingforapproval"
            number={isLoading ? null : data.ticketStatus.awaiting_approval}
            type="status"
          >
            label_waiting_for_approval
          </TabButton>
          <TabButton
            to="/service/machine-ticket/followup"
            number={isLoading ? null : data.ticketStatus.follow_up}
            type="status"
          >
            label_follow_up
          </TabButton>
          <TabButton
            to="/service/machine-ticket/close"
            number={isLoading ? null : data.ticketStatus.closed}
            type="status"
          >
            label_close
          </TabButton>
        </div>
      </div>
      <Switch>
        <Route path="/service/machine-ticket/newticket">
          <NewTicket />
        </Route>

        <Route path="/service/machine-ticket/inprogress">
          <InProgress />
        </Route>

        <Route path="/service/machine-ticket/blocked">
          <Blocked />
        </Route>

        <Route path="/service/machine-ticket/waitingforapproval">
          <WaitingForApproval />
        </Route>

        <Route path="/service/machine-ticket/followup">
          <FollowUp />
        </Route>

        <Route path="/service/machine-ticket/close">
          <Close />
        </Route>

        <Redirect to="/service/machine-ticket/newticket" />
      </Switch>
    </>
  );
}

import * as React from "react";

interface IPriorityHighProps {
  className?: string;
}

function PriorityHigh({ className }: IPriorityHighProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.47876 10.8502C2.97876 11.1502 2.37876 10.9502 2.07876 10.4502C1.77876 9.95018 1.97876 9.45018 2.47876 9.15018L7.47876 6.15018C7.77876 5.95018 8.17876 5.95018 8.47876 6.15018L13.4788 9.15018C13.9788 9.45018 14.0788 10.0502 13.7788 10.5502C13.5788 10.9502 12.9788 11.1502 12.4788 10.8502L7.97876 8.15018L3.47876 10.8502Z"
        fill="#E95D50"
      />
    </svg>
  );
}

export default PriorityHigh;

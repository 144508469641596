import Button from "components/Button/Button";
import * as React from "react";
import {
  WITHDRAW_LOT_REASONS,
  WithdrawLotReason,
  getWithdrawLotReasonTranslation,
  useGetProductLotsByWarehouseId,
  useWithdrawLotFromWarehouse,
} from "./use-get-product-lots";
import { useImmer } from "use-immer";
import { toasti18n } from "utils/toast";
import { Modal, Title } from "containers/warehousing/components/ReusableModal";
import Autocomplete from "components/Autocomplete/Autocomplete";
import TextField from "components/TextField/TextField";
import Select from "components/Select/Select";
import TextButton from "common/components/textbutton/TextButton";
import { useIsAdmin, useIsAdminContract, useIsClerk } from "utils/user-role";

function useCanWithdrawLot() {
  const canWithdrawLot = useIsAdmin() || useIsClerk() || useIsAdminContract();
  return canWithdrawLot;
}

export function WithdrawLotModalButton(props: {
  warehouseId: string;
  productId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canWithdrawLot = useCanWithdrawLot();
  if (!canWithdrawLot) {
    return null;
  }

  return (
    <>
      <TextButton
        icon="Reverse"
        translationKey="label_withdraw_stock"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <WithdrawLotModal onClose={() => setIsOpen(false)} {...props} />
      ) : null}
    </>
  );
}

function WithdrawLotModal(props: {
  warehouseId: string;
  onClose: () => void;
  productId: string;
}) {
  const { data } = useGetProductLotsByWarehouseId({
    productId: props.productId,
    warehouseId: props.warehouseId,
  });

  const [form, setForm] = useImmer<{
    quantity: string;
    justification: WithdrawLotReason | "";
    lotId: string;
  }>({
    lotId: "",
    quantity: "",
    justification: "",
  });

  const isFormDisabled =
    !form.lotId ||
    !form.justification ||
    !form.quantity ||
    isNaN(Number(form.quantity));

  const { mutate, isLoading } = useWithdrawLotFromWarehouse({
    onSuccess: () => {
      toasti18n.success();
      props.onClose();
    },
  });

  function handleCreate() {
    if (!form.justification) {
      throw new Error("Justification is required is required");
    }

    mutate({
      warehouseId: props.warehouseId,
      justification: form.justification,
      lotId: form.lotId,
      quantity: Number(form.quantity),
    });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "720px",
        alignment: "start",
        heightStyles: {
          flex: "1 1 auto",
          maxHeight: "679px",
        },
      }}
    >
      <Title>label_withdraw_stock</Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          marginTop: "25px",
        }}
      >
        <Autocomplete
          value={form.lotId}
          onChange={(newValue) =>
            setForm((draft) => {
              draft.lotId = newValue;
            })
          }
          id="lot_no"
          label="label_lot_no"
          options={data
            .map((lot) => ({
              label: `${lot.lotNo} - (${lot.lotId.slice(-6)})`,
              value: lot.lotId,
            }))
            .sort((lotA, lotB) => -lotA.label.localeCompare(lotB.label))}
          disableClearable={true}
          required={true}
        />

        <TextField
          required
          label="label_quantity"
          value={form.quantity}
          onChange={(e) =>
            setForm((draft) => {
              draft.quantity = e.target.value;
            })
          }
        />

        <Select
          required
          label="label_reason"
          id="type"
          value={form.justification}
          onChange={(e) =>
            setForm((draft) => {
              draft.justification = e.target.value;
            })
          }
          options={Object.values(WITHDRAW_LOT_REASONS).map((reason) => ({
            value: reason,
            label: getWithdrawLotReasonTranslation(reason),
          }))}
        />
      </div>
      <div style={{ flex: "1 1 0" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={handleCreate}
          loading={isLoading}
          disabled={isFormDisabled}
        >
          action_withdraw
        </Button>
      </div>
    </Modal>
  );
}

import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Select from 'common/components/select/Select';
import Typography from 'common/components/typography/Typography';
import { useGetUsers } from 'userandauth/hooks/useGetUsers';

import { IMachineUser } from 'machines/interfaces';
import { useInviteMachineUser } from 'machines/hooks/useInviteMachineUser';

import * as styles from './AddMachineUserModal.module.scss';

function AddMachineUserModal({ onClose }: IInviteMemberProps): JSX.Element {
  const { machineId }: { machineId: string } = useParams();
  const [userId, setUserId] = useState<string | null>(null);
  const { data: availableUsers, isLoading: isUsersLoading } = useGetUsers();
  const { mutate: inviteMachineuser } = useInviteMachineUser(machineId);
  const userOptions = availableUsers
    ?.map((eachUser) => {
      if (eachUser.firstName === '-' || !eachUser.firstName) {
        return { label: eachUser.email, value: eachUser.id };
      } else {
        return {
          label: `${eachUser.email} - ${eachUser.firstName}`,
          value: eachUser.id,
        };
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  function handleInvite(): void {
    const userDetails: IMachineUser = {
      userId: userId,
      machineId: machineId,
    };
    inviteMachineuser(userDetails);
    onClose();
  }

  return (
    <Modal isOpen={true} onClose={onClose} contentClassName={styles.modal}>
      <div className={styles.InviteMemberModal}>
        <header className={styles.header}>
          <Typography type="headline-6" translationKey="label_add_user" />
        </header>
        <div className={styles.body}>
          <Select
            label="label_please_select_user"
            value={userId}
            searchable={true}
            onSelect={(value: any) => setUserId(value)}
            options={isUsersLoading ? [] : userOptions}
          />
        </div>

        <footer className={styles.action}>
          <Button
            translationKey="action_cancel"
            onClick={onClose}
            type="secondary"
          />
          <Button
            translationKey="action_add_user"
            onClick={handleInvite}
            type="primary"
            disabled={userId === null}
          />
        </footer>
      </div>
    </Modal>
  );
}

export default AddMachineUserModal;

interface IInviteMemberProps {
  onClose: () => void;
}

// Hooks
import { useQuery } from 'react-query';

// Service function
import { getMachineInventory } from 'machines/service';

// Types
import { IMachineInventoryData } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';

function useGetMachineInventory (machineId: string, {enabled = true} = {}) {
  return useQuery<IMachineInventoryData[], AxiosError>(
    machineKeys.machineInventory(machineId),
    () => getMachineInventory(machineId),
    {
      enabled: enabled,
    }
  );
}

export { useGetMachineInventory };
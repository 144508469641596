import { Skeleton } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography/Typography";
import { warehouseBorderStyle } from "containers/warehousing/components/borderStyle";
import * as React from "react";
import * as colors from "common/styles/colors.scss";

interface TableProps<T, S extends string> {
  data: Array<T> | undefined;
  RowWrapper?: React.ComponentType<{}>;
  isLoading: boolean;
  isPaginating?: boolean;
  isSelected?: (dataPoint: T) => boolean;
  getKey: (dataPoint: T) => string;
  scrollableTableRef?: React.RefObject<HTMLDivElement>;
  columns: Array<{
    id: S;
    className?: string
    style?: React.CSSProperties;
    headerCell: (columnId: S) => JSX.Element;
    cell: (dataPoint: T) => JSX.Element;
  }>;
}

const DefaultRowWrapper = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

export function Table<T, S extends string>({
  isLoading,
  isPaginating,
  data,
  isSelected = () => false,
  scrollableTableRef,
  getKey,
  columns,
  RowWrapper = DefaultRowWrapper,
}: TableProps<T, S>): JSX.Element {
  const noData = !isLoading && Array.isArray(data) && data?.length === 0;

  const loadingRows = new Array(20).fill(null).map((_, index) => (
    <div
      style={{
        backgroundColor: "white",
        borderBottom: warehouseBorderStyle,
      }}
      key={index}
    >
      <RowWrapper key={index}>
        <div style={{ display: "flex", minHeight: "45px" }}>
          {columns.map((column) => (
            <div key={column.id} style={column.style} className={column.className}>
              <div
                style={{
                  width: "100%",
                  padding: "3px",
                }}
              >
                <Skeleton width={"100%"} />
              </div>
            </div>
          ))}
        </div>
      </RowWrapper>
    </div>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          maxHeight: "100%",
          width: "100%",
          overflow: "auto",
          overscrollBehavior: "none",
          height: noData ? "100%" : undefined,
        }}
        ref={scrollableTableRef}
      >
        <div
          style={{
            minWidth: "fit-content",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: "0",
              zIndex: 2,
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderBottom: warehouseBorderStyle,
              }}
            >
              <RowWrapper>
                <div style={{ display: "flex", minHeight: "45px" }}>
                  {columns.map((column) => (
                    <div style={column.style} key={column.id} className={column.className}>
                      {column.headerCell(column.id)}
                    </div>
                  ))}
                </div>
              </RowWrapper>
            </div>
          </div>

          {isLoading ? (
            loadingRows
          ) : noData ? (
            <div
              style={{
                flex: "1 1 0",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Icon name="Critical" color="disabled" />
                <Typography type="body-2" translate color="disabled">
                  label_no_data
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div>
                {data.map((element) => (
                  <div
                    key={getKey(element)}
                    style={{
                      borderBottom: warehouseBorderStyle,
                      backgroundColor: isSelected(element)
                        ? colors.primary050
                        : "white",
                    }}
                  >
                    <RowWrapper>
                      <div style={{ display: "flex", minHeight: "45px" }}>
                        {columns.map((column) => (
                          <div style={column.style} key={column.id} className={column.className}>
                            {column.cell(element)}
                          </div>
                        ))}
                      </div>
                    </RowWrapper>
                  </div>
                ))}
              </div>

              {isPaginating ? loadingRows : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import {
  GetCompletedPlanByIdQuery,
  useGetCompletedPlanByIdQuery,
} from "gql/generated";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import { CompletedPlan, PackageWithAdjustedInfo } from "../types";

export function CompletedPlanDataFetcher({
  planId,
  children,
}: {
  planId: string;
  children: ({
    isLoading,
    data,
  }: {
    isLoading: boolean;
    data: CompletedPlan;
  }) => React.ReactNode;
}) {
  const { data, isLoading } = useGetCompletedPlanByIdQuery(
    { planId: planId },
    { cacheTime: 0 }
  );
  return (
    <>
      {children({
        isLoading,
        data: data ? mapDataToCompletedPlan(data) : undefined,
      })}
    </>
  );
}

function mapDataToCompletedPlan(
  data: GetCompletedPlanByIdQuery
): CompletedPlan {
  return {
    packages: data.plan.totalRefillQuantities
      .map((returningInventory) => {
        if (!("product" in returningInventory)) {
          throw new Error("Product not found in returning inventory");
        }

        const packageInfo: PackageWithAdjustedInfo["packageInfo"] = {
          img: returningInventory.product.image,
          packageSKU: returningInventory.product.SKU,
          packageUOM: returningInventory.product.packageType,
          product: {
            id: returningInventory.productId,
            name: returningInventory.product.name,
          },
          productQuantityPerPackage: returningInventory.product.package,
          productUOMInPackage: returningInventory.product.uom,
        };

        const refillInfo: PackageWithAdjustedInfo["refillInfo"] = {
          productPackedInProductUOMInPackage:
            returningInventory.totalRequestedQuantity,
          productFilledInProductUOMInPackage:
            returningInventory.totalRefilledQuantity,
          expectedReturnInProductUOMInPackage:
            returningInventory.totalReturningQuantity,
          totalOldQuantityInProductUOMInPackage:
            returningInventory.totalReturningOldQuantity,
          productPackedInPackages: Math.floor(
            returningInventory.totalRequestedQuantity /
              packageInfo.productQuantityPerPackage
          ),
        };

        const type: PackageWithAdjustedInfo["type"] = returningInventory?.product?.machineCategory === "SPIRAL" ? "SPIRAL": "COFFEE";

        const adjustedInfo: PackageWithAdjustedInfo["adjustedInfo"] = {
          actualReturnInPackages: returningInventory.actualBag,
          actualRemainingInProductUOMInPackage:
            returningInventory.actualRemaining,
        };

        return {
          packageInfo,
          refillInfo,
          adjustedInfo,
          type,
        };
      })
      .sort((inventoryA, inventoryB) =>
        alphanumeric(
          inventoryA.packageInfo.packageSKU,
          inventoryB.packageInfo.packageSKU
        )
      ),
    planId: data.plan.id,
    canEditReturnItems: data.plan.canEditReturnedItem,
  };
}

import * as React from "react";

interface IMaintenanceProps {
  color?: string;
  className?: string;
}

function Maintenance({
  color = "white",
  className
}: IMaintenanceProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.0211 13.3228L22.1226 12.1053C22.139 11.9093 22.0725 11.7155 21.9393 11.5708C21.8062 11.4261 21.6185 11.3438 21.4219 11.3438H12.0469C11.8503 11.3438 11.6626 11.4261 11.5295 11.5708C11.3963 11.7155 11.3298 11.9093 11.3462 12.1052L11.4477 13.3228C10.3069 13.6472 9.46875 14.6977 9.46875 15.9411C9.46875 17.8582 10.5026 19.5382 12.0419 20.4536L12.212 22.4957C12.2824 23.3392 13.0005 24 13.847 24H19.6217C20.4682 24 21.1863 23.3392 21.2567 22.4956L21.4268 20.4536C22.9662 19.5382 24 17.8582 24 15.9411C24 14.6977 23.1618 13.6472 22.0211 13.3228ZM10.875 15.9411C10.875 15.4402 11.1563 15.0039 11.5692 14.7816L11.8816 18.5299C11.2568 17.8467 10.875 16.9377 10.875 15.9411ZM19.8553 22.3788C19.8453 22.4993 19.7427 22.5938 19.6218 22.5938H13.847C13.7261 22.5938 13.6234 22.4993 13.6134 22.3789L13.5023 21.0458C13.8918 21.1383 14.2978 21.1875 14.7151 21.1875H18.7536C19.1709 21.1875 19.577 21.1382 19.9664 21.0458L19.8553 22.3788ZM20.0918 19.5403C19.6749 19.6958 19.2241 19.7812 18.7536 19.7812H14.7151C14.2447 19.7812 13.7938 19.6958 13.3769 19.5403L12.811 12.75H20.6577L20.0918 19.5403ZM21.5872 18.53L21.8996 14.7816C22.3125 15.0039 22.5938 15.4402 22.5938 15.9411C22.5938 16.9377 22.212 17.8467 21.5872 18.53Z"
        fill={color}
      />
      <path
        d="M8.90625 19.0781V17.6719C8.90625 16.6699 8.20378 15.8295 7.26562 15.6157V14.8594C7.26562 13.9427 6.67776 13.1612 5.85937 12.8709V0.703125C5.85937 0.314812 5.54456 0 5.15625 0C4.76793 0 4.45312 0.314812 4.45312 0.703125V12.8709C3.63473 13.1612 3.04687 13.9427 3.04687 14.8594V15.6157C2.10871 15.8295 1.40625 16.6699 1.40625 17.6719V19.0781C1.40625 20.4384 0.956761 21.7868 0.14062 22.875C-0.0191766 23.088 -0.0448641 23.3731 0.0742453 23.6113C0.193308 23.8495 0.436777 24 0.70312 24H9.60937C9.87571 24 10.1192 23.8495 10.2382 23.6113C10.3574 23.3731 10.3317 23.088 10.1719 22.875C9.35573 21.7868 8.90625 20.4384 8.90625 19.0781ZM4.45312 14.8594C4.45312 14.4717 4.76854 14.1562 5.15625 14.1562C5.54395 14.1562 5.85937 14.4717 5.85937 14.8594V15.5625H4.45312V14.8594ZM4.67704 22.5938L5.08331 21.1427C5.18803 20.7688 4.96973 20.3807 4.59581 20.2761C4.22184 20.1713 3.83386 20.3896 3.72914 20.7636L3.2167 22.5938H1.96556C2.51685 21.5096 2.8125 20.2978 2.8125 19.0781V17.6719C2.8125 17.2842 3.12792 16.9688 3.51562 16.9688H6.79687C7.18457 16.9688 7.5 17.2842 7.5 17.6719V19.0781C7.5 20.2978 7.79564 21.5096 8.34693 22.5938H4.67704Z"
        fill={color}
      />
      <path
        d="M14.1562 7.82812C14.1562 9.2497 15.3128 10.4062 16.7344 10.4062C17.343 10.4062 17.9156 10.1954 18.3722 9.81398C18.5974 9.89555 18.8351 9.9375 19.0781 9.9375C20.2412 9.9375 21.1875 8.99123 21.1875 7.82812C21.1875 6.66502 20.2412 5.71875 19.0781 5.71875C18.8351 5.71875 18.5974 5.76075 18.3722 5.84227C17.9155 5.46084 17.343 5.25 16.7344 5.25C15.3128 5.25 14.1562 6.40655 14.1562 7.82812ZM16.7344 6.65625C17.1026 6.65625 17.4433 6.82964 17.6692 7.13194C17.7813 7.28203 17.9488 7.38112 18.1343 7.40733C18.32 7.43367 18.5082 7.38455 18.6574 7.2713C18.7453 7.20459 18.8897 7.12505 19.0781 7.12505C19.4658 7.12505 19.7812 7.44047 19.7812 7.82817C19.7812 8.21588 19.4658 8.5313 19.0781 8.5313C18.8897 8.5313 18.7453 8.45175 18.6574 8.38505C18.5081 8.27184 18.32 8.22286 18.1343 8.24902C17.9487 8.27517 17.7813 8.37431 17.6692 8.52441C17.4433 8.82661 17.1026 9 16.7344 9C16.0882 9 15.5625 8.4743 15.5625 7.82812C15.5625 7.18195 16.0882 6.65625 16.7344 6.65625Z"
        fill={color}
      />
      <path
        d="M13.9219 4.21875C15.085 4.21875 16.0312 3.27248 16.0312 2.10938C16.0312 0.946266 15.085 0 13.9219 0C12.7588 0 11.8125 0.946266 11.8125 2.10938C11.8125 3.27248 12.7588 4.21875 13.9219 4.21875ZM13.9219 1.40625C14.3096 1.40625 14.625 1.72167 14.625 2.10938C14.625 2.49708 14.3096 2.8125 13.9219 2.8125C13.5342 2.8125 13.2188 2.49708 13.2188 2.10938C13.2188 1.72167 13.5342 1.40625 13.9219 1.40625Z"
        fill={color}
      />
    </svg>
  );
}

export default Maintenance;

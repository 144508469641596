import * as React from "react";
import Icon from "../icon/Icon";

export function CopyInput({ input, id }: { input: string, id: string }) {

  return (
    <div className="relative flex gap-2 items-baseline">
      <input
        id={`copy-map-input-${id}`}
        className="!font-kanit w-full border-b-primary border-solid border-b text-primary font-medium"
        value={input}
        readOnly
      />
      <div id={`copy-button-${id}`} role="button" className="shrink-0 w-8 h-8 flex items-center justify-center border !border-primary !bg-white !rounded-md hover:!bg-primary-50 cursor-pointer">
        <Icon name="Copy" color="primary" className="w-5 h-5" />
      </div>
    </div>
  );
}

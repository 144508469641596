import { Redirect, Switch, Route } from "react-router-dom";
import { RefillOrder } from "./refill-order/RefillOrder";
import { VendiiGoogleMapsProvider } from "./refill-order/plan/GoogleMaps";
import { Archive } from "./archive/Archive";

import * as React from "react";

export function NewOperations(): JSX.Element {

  return (
    <>
      <VendiiGoogleMapsProvider>
        <Switch>
          <Route path="/new-operations/refillorder">
            <RefillOrder />
          </Route>
          <Route path="/new-operations/archive">
            <Archive />
          </Route>
          <Redirect to="/new-operations/refillorder" />
        </Switch>
      </VendiiGoogleMapsProvider>
    </>
  );
}

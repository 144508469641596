import { AxiosError } from "axios";
import i18n from "common/i18n";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ISwapOrder, IPostSwapOrder, postSwapOrder } from "services/swaporders";
import { swapOrderKeys } from ".";
import { machineKeys } from "machines/hooks/machineKeys";
import { delay } from "utils/async";

function usePostSwapOrder() {
  const queryClient = useQueryClient();
  return useMutation<ISwapOrder, AxiosError, IPostSwapOrder>(
    (swapOrder) => postSwapOrder(swapOrder),
    {
      onSuccess: async (_, vars) => {
        await delay(500);
        queryClient.invalidateQueries(swapOrderKeys.all());
        queryClient.invalidateQueries(machineKeys.machineInventory(vars.machineId));
        toast.success(i18n.t("toast_request_successful"));
      },
    }
  );
}

export { usePostSwapOrder };

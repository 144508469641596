import {
  GetMaintenanceBoardIceErrorDocument,
  GetMaintenanceBoardIceErrorQuery,
  GetMaintenanceBoardIceErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getIceError({
  serviceZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardIceErrorQuery,
    GetMaintenanceBoardIceErrorQueryVariables
  >(GetMaintenanceBoardIceErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}

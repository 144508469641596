// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textAndIndicatorCell--2HPxjAGxd4{display:flex;align-items:center}.textAndIndicatorCell--2HPxjAGxd4 .indicator--LYMDfBRYD4{flex-shrink:0;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"textAndIndicatorCell": "textAndIndicatorCell--2HPxjAGxd4",
	"indicator": "indicator--LYMDfBRYD4"
};
module.exports = exports;

import * as React from "react";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import { Column, ColumnFilter, Table } from "@tanstack/react-table";
import { isArray } from "lodash";

type FilterStateProps<T> = {
  table: Table<T>;
};

function renderValue<T>({
  column,
  filter,
}: {
  filter: ColumnFilter;
  column: Column<T, unknown>;
}) {
  const columnFilter = column.columnDef.meta?.columnFilter;

  if (
    columnFilter.type === "select" ||
    columnFilter.type === "autocomplete" ||
    columnFilter.type === "selectBadge"
  ) {
    if (isArray(filter.value)) {
      return (
        <div className="flex gap-1">
          {columnFilter.options
            ?.filter((option) =>
              (filter.value as unknown[]).includes(option.value)
            )
            .map((option) => option.label)
            .reduce((prev, curr) => [prev, ", ", curr])}
        </div>
      );
    } else {
      return columnFilter.options?.find(
        (option) => option.value === filter.value
      )?.label;
    }
  }
  if (columnFilter.type === "minmax") {
    if (isArray(filter.value)) {
      return `${filter.value[0] !== undefined ? filter.value[0] : ""} - ${filter.value[1] !== undefined ? filter.value[1] : ""}`;
    }
    return "";
  }

  if (["checkbox", "selectBadge"].includes(columnFilter.type)) {
    if (!isArray(filter.value)) return "";
    if (filter.value.length === 0) return "";
    return filter.value.join(", ");
  }

  return filter.value;
}

export function FilterState<T>({ table }: FilterStateProps<T>) {
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2",
  });

  const rowSelectedLength = Object.keys(table.getState().rowSelection).length;

  const getAllLeafColumns = table.getAllLeafColumns();
  const filters = table.getState().columnFilters;
  if (filters?.length === 0 && rowSelectedLength === 0) return null;
  return (
    <div className="p-2 border-0 !border-t border-outline border-solid min-h-12 flex items-center gap-6 box-border">
      <div className="flex-1 flex-wrap flex justify-start gap-2 items-center">
        {rowSelectedLength > 0 && <div className="h-6 leading-6 text-body3 text-on-surface-medium font-medium pr-4 border-r border-l-0 border-y-0 border-outline border-solid">{t("row_selection_state.label_selected", { count: rowSelectedLength })}</div>}
        {filters?.map((filter) => {
          const column = getAllLeafColumns?.find(
            (column) => column.id === filter.id
          );

          return (
            <div
              className="bg-overlay-surface-08 hover:bg-overlay-surface-12 flex px-2 py-1 rounded-full items-center gap-1"
              key={filter.id}
            >
              <div>
                {column?.columnDef.meta?.columnFilter?.label || column.id} :
              </div>
              <div className="">
                {renderValue({
                  filter,
                  column,
                })}
              </div>
              <div
                onClick={() =>
                  table.setColumnFilters(
                    filters.filter((f) => filter.id !== f.id)
                  )
                }
                className="cursor-pointer flex items-center justify-center w-4 h-4 rounded-full bg-on-surface-medium"
              >
                <Icon className="w-3 h-3" name="Close" />
              </div>
            </div>
          );
        })}
      </div>
      <div className="px-3 py-1">
        <p
          className="text-error text-body2 font-medium cursor-pointer"
          onClick={() => table.resetColumnFilters()}
        >
          {t("filter.label_clear_all_button")}
        </p>
      </div>
    </div>
  );
}

import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardAggregate } from '../molecules/CardAggregate';
import { CardTitle } from '../molecules/CardTitle';
import { LineGraph } from '../atoms/charts/LineGraph';

import * as styles from './LineGraphCard.module.scss';

export interface LineGraphCardProps {
  title: string;
  icon: string;
  aggregates: LineGraphCardAggregates[];
  color?: string;
  data: [number, number][];
  labels?: Array<string | Array<string>>;
  scales?: {
    xAxis?: {
      min?: number;
      max?: number;
    };
    yAxis?: {
      min?: number;
      max?: number;
    };
  };
  className?: string;
}

interface LineGraphCardAggregates {
  header: string;
  value: string;
  type: 'currency' | 'numeric';
}

function LineGraphCard({
  title,
  icon,
  aggregates,
  data,
  labels,
  scales,
  className = '',
  color = '#2C2E78',
}: LineGraphCardProps): JSX.Element {
  return (
    <Card className={[styles.LineGraphCard, className].join(' ')}>
      <CardTitle icon={icon} title={title} />
      <div className={styles.CardAggregatesContainer}>
        {aggregates.map((aggregate) => (
          <CardAggregate
            key={aggregate.header}
            header={aggregate.header}
            type={aggregate.type}
            value={aggregate.value}
            className={styles.CardAggregate}
          />
        ))}
      </div>

      <LineGraph
        data={data}
        labels={labels}
        scales={scales}
        className={styles.LineGraph}
        color={color}
      />
    </Card>
  );
}

export { LineGraphCard };

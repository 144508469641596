import * as React from "react";

import { ErrorBoundary } from "@sentry/react";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import Icon from "common/components/icon/Icon";
import { Fallback } from "components/Fallback";
import Typography from "components/Typography/Typography";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import { WarehousingNavigation } from "containers/warehousing/components/WarehousingNavigation";
import { WarehousingWorkspacePaddingContainer } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import { warehouseBorderStyle } from "containers/warehousing/components/borderStyle";
import { warehousingRoutes } from "containers/warehousing/routes";

import { Link } from "react-router-dom";

import { Skeleton } from "@mui/material";
import { useGetWarehouse } from "./use-get-warehouse";
import { AddNewWarehouseModalButton } from "./AddNewWarehouseModal";
import { translateProvince } from "containers/location/utils/getProvince";

import * as styles from "./WarehouseOverview.module.scss";
import { DownloadHistoryModalButton } from "./DownloadHistoryModal";
import { DownloadERPReportButton } from "./DownloadERPReport";

export function WarehousesOverview() {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <WarehousingNavigation />
      <WarehousingHeader
        title="label_warehouses"
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
          },
        ]}
        buttons={
          <div style={{ display: "flex", gap: "23px" }}>
            <DownloadERPReportButton/>
            <AddNewWarehouseModalButton />
            <DownloadHistoryModalButton />
          </div>
        }
      />
      <div style={{ flex: "1 1 0px", minHeight: "0px" }}>
        <ErrorBoundary fallback={Fallback}>
          <WarehouseCards />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function WarehouseCards() {
  const getWarehousesQuery = useGetWarehouse();
  const [filter, setFilter] = React.useState<IAppliedFilter>(null);
  const filteredWarehouses = React.useMemo(
    () =>
      filter === null
        ? getWarehousesQuery.data
        : (getWarehousesQuery.data ?? []).filter((warehouse) =>
            warehouse.name.toLowerCase().includes(filter.value.toLowerCase())
          ),
    [filter, getWarehousesQuery.data]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <div style={{ borderBottom: warehouseBorderStyle }}>
        <WarehousingWorkspacePaddingContainer>
          <PureFilterBar
            value={filter}
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              borderTop: "none",
              borderBottom: "none",
            }}
            onChange={setFilter}
            filterOptions={[
              {
                field: "warehouseName",
                queryType: "contains",
                translationKey: "label_warehouse_name",
              },
            ]}
            placeholder="label_search"
          />
        </WarehousingWorkspacePaddingContainer>
      </div>
      <div style={{ flex: "1 1 0", minHeight: "0px", overflow: "auto" }}>
        <div
          style={{
            paddingTop: "32px",
            paddingBottom: "32px",
          }}
        >
          <WarehousingWorkspacePaddingContainer>
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(min(100%, 290px), 1fr))",
                gap: "32px",
              }}
            >
              {getWarehousesQuery.isLoading
                ? new Array(20)
                    .fill(0)
                    .map((_, index) => (
                      <WarehouseCard key={index} isLoading={true} />
                    ))
                : filteredWarehouses.map((warehouse) => (
                    <Link
                      to={warehousingRoutes.warehousesWithWarehouseId(
                        warehouse.warehouseId
                      )}
                      key={warehouse.warehouseId}
                    >
                      <WarehouseCard warehouse={warehouse} />
                    </Link>
                  ))}
            </div>
          </WarehousingWorkspacePaddingContainer>
        </div>
      </div>
    </div>
  );
}

function WarehouseCard({
  warehouse,
  isLoading,
}: {
  warehouse?: {
    machineCount?: number;
    province?: string;
    name: string;
    warehouseId: string;
    productCount?: string;
    userCount?: number;
  };
  isLoading?: boolean;
}) {
  const { machineCount, province, name, productCount, userCount } =
    warehouse ?? {};

  return (
    <div
      style={{
        border: warehouseBorderStyle,
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.13)",
        padding: "16px",
        borderRadius: "8px",
      }}
      className={isLoading ? undefined : styles.HoverHightlight}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ flexBasis: "32px", minWidth: "32px" }}>
          {isLoading ? (
            <Skeleton height="32px" style={{ transform: "scale(1,1)" }} />
          ) : (
            <Icon name="Warehouse051" color="primary500" />
          )}
        </div>
        <div
          style={{
            flexGrow: 1,
            flexBasis: "0px",
            minWidth: "0px",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              marginTop: "-2px",
            }}
          >
            {isLoading ? (
              <Skeleton height="30px" style={{ transform: "scale(1,1)" }} />
            ) : (
              <Typography type="headline-6">{name}</Typography>
            )}
          </div>
          <div style={{ marginTop: "2px" }}>
            {isLoading ? (
              <Skeleton />
            ) : (
              <Typography type="body-2">
                {province ? translateProvince(province) : "-"}
              </Typography>
            )}
          </div>
          <div
            style={{
              marginTop: "12px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginRight: "30px",
              justifyContent: "space-between",
            }}
          >
            {isLoading ? (
              <>
                <Skeleton width="35px" />
                <Skeleton width="35px" />
                <Skeleton width="35px" />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Icon name="Machine" color="onSurfaceDisabled" />
                  <Typography type="subtitle-2">
                    {machineCount ?? "-"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Icon name="Cart" color="onSurfaceDisabled" />
                  <Typography type="subtitle-2">
                    {productCount ?? "-"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Icon name="Enroute" color="onSurfaceDisabled" />
                  <Typography type="subtitle-2">{userCount ?? "-"}</Typography>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

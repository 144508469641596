import * as React from 'react';
import { useState } from 'react';

import * as styles from './Tooltip.module.scss';

export type IAlign = 'left' | 'center' | 'right';
export type IPosition = 'top' | 'bottom';

interface ITooltipProps {
  children: JSX.Element,
  className?: string,
  contentClassName?: string,
  content: JSX.Element,
  align?: IAlign,
  disabled?: boolean
}

function Tooltip ({
  children,
  className,
  contentClassName,
  content,
  align = 'center',
  disabled = false,
  ...restProps
}: ITooltipProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  return (
    <div
      className={[
        styles.Tooltip,
        className
      ].join(' ')}
      onMouseEnter={() => setIsOpen(!disabled)}
      onMouseLeave={() => setIsOpen(false)}
      {...restProps}
    >
      {children}
      {isOpen && (
        <>
          <div
            className={[
              styles.content,
              styles[align],
              contentClassName
            ].join(' ')}
          >
            {content}
          </div>
          <div
            className={[
              styles.triangle,
              styles[align]
            ].join(' ')}
          />
        </>
      )}
    </div>
  );
}

export default Tooltip;

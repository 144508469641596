import * as React from 'react';

interface ICoffeeProps {
  color?: string,
  className?: string
}

function Coffee ({
  color = 'white',
  className
}: ICoffeeProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path id='icon' fillRule='evenodd' clipRule='evenodd' d='M4.35963 3.04466L4.82918 2.10557C5.16796 1.428 5.86049 1 6.61803 1H17.382C18.1395 1 18.832 1.428 19.1708 2.10557L19.6404 3.04466C20.3736 3.20235 20.9718 3.76416 21.1595 4.51493L21.6595 6.51493C21.9274 7.58652 21.2759 8.67239 20.2043 8.94029C20.0939 8.96789 19.9814 8.98599 19.8681 8.99445L17.8673 23H6.13271L4.13193 8.99454C3.09687 8.91839 2.28078 8.0545 2.28078 7C2.28078 6.83648 2.30083 6.67357 2.34049 6.51493L2.84049 4.51493C3.02818 3.76416 3.62642 3.20235 4.35963 3.04466ZM6.15301 9H17.847L16.1327 21H7.8673L6.15301 9ZM4.78078 5H5.61803L6.61803 3H17.382L18.382 5H19.2192L19.7192 7H4.28078L4.78078 5Z' fill={color} />
    </svg>
  );
}

export default Coffee;

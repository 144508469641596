// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Note--15nfDSmiJU{display:flex}.Note--15nfDSmiJU .Left--1w3IeRz51j{flex-basis:40px;min-width:40px}.Note--15nfDSmiJU .Left--1w3IeRz51j .ImageWrapper--1--RCiUCdE{width:40px;height:40px;border-radius:50%;overflow:hidden}.Note--15nfDSmiJU .Left--1w3IeRz51j .ImageWrapper--1--RCiUCdE img{width:100%;height:100%;object-fit:cover}.Note--15nfDSmiJU .Right--3TllFTHYdr{min-width:0;flex-basis:0;flex-grow:1;margin-left:8px}.Note--15nfDSmiJU .Right--3TllFTHYdr .NameAndDate--2cwfJuCud0{margin-bottom:1px}.Note--15nfDSmiJU .Right--3TllFTHYdr .NameAndDate--2cwfJuCud0 .Name--2eQcLSxCnK{text-transform:capitalize}.Note--15nfDSmiJU .Right--3TllFTHYdr .NameAndDate--2cwfJuCud0 .Date--MVLalkf3Pi{margin-left:8px}.Note--15nfDSmiJU .Right--3TllFTHYdr .Type--ZD0fUpIQc1{margin-bottom:-2px}.Note--15nfDSmiJU .Right--3TllFTHYdr .Description--3dMStxZt2f{line-height:.8}", ""]);
// Exports
exports.locals = {
	"Note": "Note--15nfDSmiJU",
	"Left": "Left--1w3IeRz51j",
	"ImageWrapper": "ImageWrapper--1--RCiUCdE",
	"Right": "Right--3TllFTHYdr",
	"NameAndDate": "NameAndDate--2cwfJuCud0",
	"Name": "Name--2eQcLSxCnK",
	"Date": "Date--MVLalkf3Pi",
	"Type": "Type--ZD0fUpIQc1",
	"Description": "Description--3dMStxZt2f"
};
module.exports = exports;

import * as React from "react";

import TextButton from "common/components/textbutton/TextButton";
import { Modal } from "@mui/material";
import * as moment from "moment";
import Button from "components/Button";
import analytics from "utils/analytics";
import { ReportType, useGenerateReportMutation } from "gql/generated";
import { useTranslation } from "react-i18next";
import PureDateRangePicker from "common/components/daterangepicker/PureDateRangePicker";

export function DownloadAccountingReportModal() {
  const { t } = useTranslation("transaction", {
    keyPrefix: "downloadAccounting",
  });
  const { mutate, isLoading } = useGenerateReportMutation({
    onSuccess: (data) => {
      window.open(`/download-report/${data.generateReport}`);
      setOpen(false);
    },
  });
  function onDatePickerBlur({ startDate, endDate }) {
    if (startDate >= endDate) {
      return;
    }
    setDateFrom(startDate);
    setDateTo(endDate);
  }
  const [open, setOpen] = React.useState(false);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  return (
    <>
      <TextButton
        className="ml-[30px]"
        text={t("modal.title")}
        icon="Download"
        onClick={() => {
          setOpen(true);
          analytics.track({
            name: "download accounting report button clicked",
            properties: {},
          });
        }}
      />

      <Modal
        className="flex items-center justify-center fixed !outline-none !outline-0"
        open={open}
        onClose={() => {
          setOpen(false);
          analytics.track({
            name: "modal close by backdrop clicked on accounting report modal",
            properties: {},
          });
        }}
      >
        <div className="relative w-full max-w-[420px] bg-white p-5 space-y-3 rounded-md !outline-none !outline-0">
          <h3 className="text-h6 text-on-surface-high font-kanit text-center">
            {t("modal.title")}
          </h3>
          <PureDateRangePicker
            onBlur={onDatePickerBlur}
            selectRange={31}
          />
          <div className="flex justify-between gap-3">
            <Button
              type="secondary"
              onClick={() => setOpen(false)}
              data-tracking-event="cancel button clicked on request accounting report"
            >
              {t("modal.cancel_button")}
            </Button>
            <Button
              disabled={!(dateFrom && dateTo)}
              type="primary"
              loading={isLoading}
              onClick={() =>
                mutate({
                  params: {
                    startDate: moment(dateFrom).format("yyyy-MM-DD"),
                    endDate: moment(dateTo).format("yyyy-MM-DD"),
                    reportType: ReportType.AccountingGroupByNone,
                  },
                })
              }
              data-tracking-event="download button clicked on request accounting report modal"
              data-tracking-property-date={dateFrom}
            >
              {t("modal.download_button")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

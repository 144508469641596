import * as React from 'react';
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";


export function ErrorFallBack() {
  return (
    <div className="flex-grow flex-col items-center justify-center flex ">
      <Icon name="AlertOutline" color="error2" className="w-[56px] h-[56px]" />
      <Typography type="body-3" color="error">
        Failed to load data
      </Typography>
    </div>
  );
}
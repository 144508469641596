import * as React from "react";
import * as moment from "moment";
import {
  GetPaginatedContractsDocument,
  GetPaginatedContractsQuery,
  GetPaginatedContractsQueryVariables,
  SortingOrder,
} from "gql/generated";
import { fetcher } from "gql/fetcher";
import { useImmer } from "use-immer";

async function fetchPaginatedContract({
  friendlyId,
  cursor,
}: {
  friendlyId: string;
  cursor: string | undefined;
}) {
  const paginationArgs = Object.assign(
    {
      pagination: {
        limit: 20,
        cursor: cursor,
      },
    },
    friendlyId
      ? {
          filter: {
            friendlyIds: [friendlyId],
          },
        }
      : {}
  );

  return await fetcher<
    GetPaginatedContractsQuery,
    GetPaginatedContractsQueryVariables
  >(GetPaginatedContractsDocument, {
    ...paginationArgs,
    order: SortingOrder.Desc,
  })();
}

type ContractPaginationState = {
  data: ContractList;
  state: "idle" | "loading" | "error" | "paginating";
  error: Error;
  cursorInfo: {
    hasNextPage: boolean;
    cursor: string;
  };
};

function initaliazeContractPaginationState(): ContractPaginationState {
  return {
    data: undefined,
    state: "loading",
    error: undefined,
    cursorInfo: undefined,
  };
}

export function useGetContracts({ filter }: { filter: string | undefined }) {
  const [contractPaginationState, setContractPaginationState] =
    useImmer<ContractPaginationState>(initaliazeContractPaginationState);

  React.useEffect(
    function fetchDataOnFilterChange() {
      setContractPaginationState((draft) => {
        draft.data = undefined;
        draft.cursorInfo = undefined;
        draft.state = "loading";
      });
      let isValid = true;

      fetchPaginatedContract({ friendlyId: filter, cursor: undefined })
        .then((response) => {
          if (isValid) {
            setContractPaginationState((draft) => {
              draft.data = mapResponseToContractList(response);
              draft.state = "idle";
              draft.cursorInfo = {
                hasNextPage: response.contracts2.pageInfo.hasNextPage,
                cursor: response.contracts2.pageInfo.endCursor,
              };
            });
          }
        })
        .catch((e) => {
          if (isValid) {
            setContractPaginationState((draft) => {
              draft.state = "error";
              draft.error = e;
            });
          }
        });

      return () => {
        isValid = false;
      };
    },
    [filter]
  );

  function paginate() {
    if (contractPaginationState.state !== "idle") return;
    if (!contractPaginationState.cursorInfo?.hasNextPage) return;
    setContractPaginationState((draft) => {
      draft.state = "paginating";
    });
    fetchPaginatedContract({
      friendlyId: filter,
      cursor: contractPaginationState.cursorInfo.cursor,
    })
      .then((response) => {
        setContractPaginationState((draft) => {
          if (draft.state !== "paginating") return;

          draft.data = [...draft.data, ...mapResponseToContractList(response)];
          draft.state = "idle";
          draft.cursorInfo = {
            hasNextPage: response.contracts2.pageInfo.hasNextPage,
            cursor: response.contracts2.pageInfo.endCursor,
          };
        });
      })
      .catch((e) => {
        setContractPaginationState((draft) => {
          if (draft.state !== "paginating") return;
          draft.state = "error";
          draft.error = e;
        });
      });
  }

  return {
    ...contractPaginationState,
    paginate,
    isLoading: contractPaginationState.state === "loading",
    isPaginating: contractPaginationState.state === "paginating",
  };
}

function mapResponseToContractList(response: GetPaginatedContractsQuery) {
  return response.contracts2.edges.map((edge) => ({
    id: edge.node.contractId,
    friendlyId: edge.node.friendlyId,
    locations: edge.node.locationIds?.map((location) => ({
      locationId: location.id,
      friendlyId: location.friendlyId,
    })),
    // sales: edge.node.sales,
    organisationName: edge.node.organization.name,
    // projectName: edge.node.project?.projectName,
    contractEndDate: moment(edge.node.endDate),
  }));
}

type ContractList = Array<Contract>;
type Contract = {
  id: string;
  friendlyId: string;
  locations: Array<{
    locationId: string;
    friendlyId: string;
  }>;
  // sales: string;
  organisationName: string;
  // projectName: string;
  contractEndDate: moment.Moment;
};

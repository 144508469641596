import * as React from 'react';
import { useMemo } from 'react';

import Typography from 'common/components/typography/Typography';
import IndicatorLabel from 'common/components/indicatorlabel/IndicatorLabel';
import Tooltip from 'common/components/tooltip/Tooltip';
import getHorizontalAlign from 'common/components/tooltip/util/getHoriztonalAlign';

import TooltipContent from './tooltipcontent/TooltipContent';
import getBucketData from './util/getBucketData';

import * as colors from 'common/styles/colors.scss';
import * as styles from './BucketStatus.module.scss';

export interface IBucketState {
  timestamp: number,
  state: number | string,
  priority?: number
}
export interface IBucket {
  key: number,
  min: number,
  max: number,
  data: IBucketState[],
  height: string,
  color: string
}

export interface IStateMap {
  state: number | string,
  label: string,
  color: string,
  isSuccessState: boolean,
  priority: number
}

interface IBucketStatusProps {
  title: string,
  data: IBucketState[],
  stateMap: IStateMap[],
  from: number,
  to: number,
  buckets: number,
  minimumEventHeight: number
}

function BucketStatus ({
  title,
  data = [],
  stateMap,
  from,
  to,
  buckets,
  minimumEventHeight
}: IBucketStatusProps): JSX.Element {
  const renderData = useMemo(() => {
    return getBucketData({
      data,
      from,
      to,
      buckets,
      stateMap,
      minimumEventHeight
    });
  }, [ data, buckets, stateMap, minimumEventHeight, from, to ]);

  return (
    <div className={styles.BucketStatus}>
      <div className={styles.header}>
        <Typography
          type='subtitle-1'
          translationKey={title}
        />
        <div className={styles.legend}>
          {stateMap.map((i, index) => {
            if (i.isSuccessState) {
              return null;
            }
            return (
              <IndicatorLabel
                key={index}
                color={i.color}
                label={i.label}
                className={styles.indicator}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.chart}>
        {renderData.map((i: IBucket, index: number) => {
          const align = getHorizontalAlign(index, renderData.length);
          return (
            <Tooltip
              key={index}
              align={align}
              content={<TooltipContent stateMap={stateMap} bucket={i} />}
              className={styles.bar}
            >
              <div
                className={styles.innerBar}
                style={{
                  backgroundColor: colors[i.color],
                  height: i.height
                }}
              />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

export default BucketStatus;

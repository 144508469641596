import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";

export function TransactionsSubNav() {
  return (
    <ScrollContainer>
      <TransactionsTabs />
    </ScrollContainer>
  );
}

export function TransactionsTabs() {
  const [serviceZone] = useServiceZoneQueryParam();

  const { t } = useTranslation("errorBoard");

  const { state } = useErrorBoard();
  const transactionsSummary = state.data.summary?.transactions;

  return (
    <>
      <TabButton
        path={routes.transactions.aaNoTransaction30Mins(serviceZone)}
        activePath={routes.transactions.aaNoTransaction30Mins()}
        amount={transactionsSummary?.aaNoTransaction30Mins ?? 0}
      >
        {t("transactionTabs.aaNoTransaction30Mins")}
      </TabButton>
      <TabButton
        path={routes.transactions.noTransaction2Hours(serviceZone)}
        activePath={routes.transactions.noTransaction2Hours()}
        amount={transactionsSummary?.noTransaction2Hours ?? 0}
      >
        {t("transactionTabs.noTransaction2Hours")}
      </TabButton>
      <TabButton
        path={routes.transactions.noTransactionToday(serviceZone)}
        activePath={routes.transactions.noTransactionToday()}
        amount={transactionsSummary?.noTransactionToday ?? 0}
      >
        {t("transactionTabs.noTransactionToday")}
      </TabButton>
      <TabButton
        path={routes.transactions.billAndCoin(serviceZone)}
        activePath={routes.transactions.billAndCoin()}
        amount={transactionsSummary?.billAndCoin ?? 0}
      >
        {t("transactionTabs.billAndCoin")}
      </TabButton>
    </>
  );
}

export function insertBetweenElements<T, K>({
  array, elementToInsert,
}: {
  array: Array<T>;
  elementToInsert: K;
}): Array<T | K> {
  return array.flatMap((element, index) => {
    if (index === array.length - 1) {
      return [element];
    }
    return [element, elementToInsert];
  });
}

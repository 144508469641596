import * as React from "react";
import { TransactionsSubNav } from "./TransactionsSubNav";
import { TransactionsTable } from "./TransactionsTable";

export function TransactionsView() {
  return (
    <>
      <TransactionsSubNav />
      <TransactionsTable />
    </>
  );
}

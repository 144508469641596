import * as React from "react";

// Custom components
import Typography from "../../../typography/Typography";
import Icon from "../../../icon/Icon";

// Styles
import * as styles from "./Option.module.scss";

interface IOptionProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  translationKey: string;
  icon: string;
}

function Option({
  onClick,
  className,
  disabled = false,
  translationKey,
  icon,
}: IOptionProps): JSX.Element {
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      className={[
        className,
        styles.Option,
        disabled ? styles.OptionDisabled : "",
      ].join(" ")}
    >
      <div className={styles.iconWrapper}>
        <Icon name={icon} color="primary500" />
      </div>
      <Typography
        translationKey={translationKey}
        type="subtitle-1"
        className={styles.text}
      />
    </button>
  );
}

export { Option };

import * as React from "react";
import { InventorySubNav } from "./InventorySubNav";
import { InventoryTables } from "./InventoryTables";

export function InventoryView() {
  return (
    <>
      <InventorySubNav />
      <InventoryTables />
    </>
  );
}

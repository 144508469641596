import * as React from "react";
import { Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { MenuWithIcon } from "../MenuWithIcon";
import analytics from "utils/analytics";
import Icon from "common/components/icon/Icon";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";

export function OnboardingDropdownMenu() {
  const { setModalOpen } = useMachinesStore();
  const { t } = useTranslation("machine", {
    keyPrefix: "overview_page.header",
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseDownloadDropdown = () => {
    setAnchorEl(null);
  };

  const handleClickDownloadDropdown = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    analytics.track({
      name: "click onboard machine dropdown",
      properties: { location: "header" },
    });
  };

  return (
    <>
      <Button
        iconName="MachineRegistration"
        size="small"
        type="primary"
        onClick={handleClickDownloadDropdown}
      >
        <div className="flex items-center pl-1 gap-2">
          {t("onboard_machine_dropdown.label")}
          <Icon name="ChevronThick" color="white" />
        </div>
      </Button>
      <Menu
        id="machine-monitor-page-download-dropdown"
        aria-labelledby="machine monitor page onboarding dropdown"
        open={open}
        onClose={handleCloseDownloadDropdown}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuWithIcon
          iconName="Box"
          className="min-w-[220px]"
          onClick={() => {
            setModalOpen("OnboardTaobinMachine");
            analytics.track({
              name: "click onboard taobin machine button",
              properties: { location: "header" },
            });
          }}
        >
          {t("onboard_machine_dropdown.label_taobin_machine")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Box"
          className="min-w-[220px]"
          onClick={() => {
            setModalOpen("OnboardSpiralMachine");
            analytics.track({
              name: "click onboard spiral machine button",
              properties: { location: "header" },
            });
          }}
        >
          {t("onboard_machine_dropdown.label_spiral_machine")}
        </MenuWithIcon>
      </Menu>
    </>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChangeZone--GSCaAsYrzY{display:flex;flex-direction:column}.ChangeZone--GSCaAsYrzY .EditZoneContent--Jy_OO1QI3w{margin-top:10px}.ChangeZone--GSCaAsYrzY .ButtonContainer--3QrS1y3qLO{display:flex;margin-top:10px}.ChangeZone--GSCaAsYrzY .ButtonContainer--3QrS1y3qLO .save--10T2vne1__{margin-left:auto}", ""]);
// Exports
exports.locals = {
	"ChangeZone": "ChangeZone--GSCaAsYrzY",
	"EditZoneContent": "EditZoneContent--Jy_OO1QI3w",
	"ButtonContainer": "ButtonContainer--3QrS1y3qLO",
	"save": "save--10T2vne1__"
};
module.exports = exports;

import * as React from 'react';

import Icon from 'common/components/icon/Icon';

import useEventListener from 'common/hooks/useEventListener';

import * as styles from './Modal.module.scss';

interface IModalProps {
  isOpen?: boolean,
  children: JSX.Element | JSX.Element[],
  onClose?: () => void,
  contentClassName?: string,
  modalClassName?: string,
  closeButton?: boolean,
  closeButtonColor?: string,
  escapable?: boolean
}

function Modal ({
  children,
  isOpen = true,
  onClose,
  modalClassName,
  contentClassName,
  closeButton,
  closeButtonColor,
  escapable = false
}: IModalProps): JSX.Element {
  useEventListener('escape', escapable ? onClose : () => null);

  return (
    <div
      className={[
        styles.Modal,
        styles.background,
        isOpen ? styles.open : '',
        modalClassName
      ].join(' ')}
    >
      <div
        className={[
          styles.content,
          closeButton ? styles.withClose : '',
          contentClassName
        ].join(' ')}
      >
        {closeButton && (
          <div
            className={styles.closeButton}
            onClick={onClose}
          >
            <Icon
              name='Close'
              color={closeButtonColor || 'surface'}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default Modal;

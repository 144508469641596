import * as React from 'react';

import * as styles from './Doughnut.module.scss';

interface DoughnutProps {
  className?: string;
  color: string;
}

function Doughnut ({ className = '', color }: DoughnutProps): JSX.Element {
  return (
    <div
      style={{ borderColor: color }}
      className={[ styles.Doughnut, className ].join(' ')}
    ></div>
  );
}

export { Doughnut };

import Typography from 'common/components/typography/Typography';
import { formatCurrency } from 'utils/currency';
import * as React from 'react';
import { PercentageIndicator } from '../atoms/PercentageIndicator';
import { SparkLine } from '../atoms/SparkLine';
import * as styles from './SparkLineCell.module.scss';

interface SparkLineCellProps {
  sparkLineData: Array<[number, number]>;
  value: number;
  percentage: number;
  color: 'success' | 'error' | 'warning';
}

function SparkLineCell ({
  sparkLineData,
  value,
  percentage,
  color
}: SparkLineCellProps): JSX.Element {
  return (
    <div className={styles.SparkLineCell}>
      <div className={styles.SparkLineContainer}>
        <SparkLine color={color} data={sparkLineData} className={styles.SparkLine} />
      </div>
      <div className={styles.ValueContainer}>
        <div className={styles.Prefix}>
          {value < 0 && <Typography translationKey='-' type="body-2"/>}
        </div>
        <Typography translationKey={[formatCurrency({input: Math.abs(value), minimumFractionDigits: 0 })]}
        type="body-2"/>
      </div>
      <PercentageIndicator percentage={percentage} color={color} className={styles.PercentageIndicator}/>
    </div>
  );
}

const MemoizedSparkLineCell = React.memo(SparkLineCell);

function SparkLineCellRenderProp (props: SparkLineCellProps) {
  return <MemoizedSparkLineCell {...props} />;
}

export { SparkLineCellRenderProp as SparkLineCell };

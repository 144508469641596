import * as Papa from "papaparse";

type JSONValue =
  | string
  | number
  | boolean
  | Array<JSONValue>
  | { [k: string]: JSONValue };

function downloadCSVFromJSON(json: JSONValue, fileName: string) {
  const csv = Papa.unparse(json);
  const csvData = new Blob(["\ufeff" + csv], {
    type: "text/csv;charset=utf-8;",
  });
  downloadBlob(csvData, `${fileName}.csv`);
}

async function downloadCSVFromNetworkRequest(
  fn: () => Promise<string>,
  fileName: string
) {
  const rawCSV = await fn();
  const CSVFile = new File(["\ufeff" + rawCSV], `${fileName}.csv`, {
    type: "text/csv",
  });
  downloadBlob(CSVFile, `${fileName}.csv`);
}

function downloadBlob(blob: Blob | File, fileName: string) {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}

export { downloadCSVFromJSON, downloadCSVFromNetworkRequest };

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { inviteMachineUser } from 'machines/service';
import { machineKeys } from './machineKeys';
import { IMachineUser } from 'machines/interfaces';
import { toasti18n } from 'utils/toast';



function useInviteMachineUser (machineId:string) {
    const queryClient = useQueryClient();
    return useMutation<string, AxiosError, IMachineUser>(
        (newUserData:IMachineUser) => inviteMachineUser(newUserData),{
            onSuccess:() => {
                toasti18n.success("toast_user_add_success");
                queryClient.invalidateQueries(machineKeys.machineUsers(machineId));
            }
        }
    );
}

export { useInviteMachineUser };
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RadialChartKey--1q9PoNL69n{display:flex;align-items:center}.Doughnut--1Cm7nhv3Wk{align-self:center}.Label--OnvYXInpOk{margin-left:8px;white-space:nowrap}.Value--1gVSFPIm1-{font-weight:700;margin-left:auto}", ""]);
// Exports
exports.locals = {
	"RadialChartKey": "RadialChartKey--1q9PoNL69n",
	"Doughnut": "Doughnut--1Cm7nhv3Wk",
	"Label": "Label--OnvYXInpOk",
	"Value": "Value--1gVSFPIm1-"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"font-main-light\";src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"font-main-regular\";src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"font-main-medium\";src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"font-alt-light\";src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"font-alt-regular\";src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"font-alt-medium\";src:url(\"/fonts/Roboto-Medium.ttf\")}@font-face{font-family:\"Kanit\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"Kanit\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"Kanit\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"Roboto\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"Roboto\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"Roboto\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Medium.ttf\")}.FormControlLabel--2ivFnShEOx{margin:0px !important;display:flex !important;align-items:start !important}.FormControlLabel--2ivFnShEOx .MuiCheckbox-root{flex-shrink:0}.FormControlLabel--2ivFnShEOx .MuiFormControlLabel-label{font-family:unset;font-weight:unset;font-size:unset;line-height:unset;letter-spacing:unset}.Label--KxTKXvh6oE{margin-left:6px}.LabelString--3nB8gOJt3b{margin-left:6px;font-family:\"Kanit\",\"Roboto\",Arial,Helvetica,sans-serif;user-select:none;line-height:1.05}", ""]);
// Exports
exports.locals = {
	"FormControlLabel": "FormControlLabel--2ivFnShEOx",
	"Label": "Label--KxTKXvh6oE",
	"LabelString": "LabelString--3nB8gOJt3b"
};
module.exports = exports;

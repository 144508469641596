import {
  useGetWarehouseId,
  warehousingRoutes,
} from "containers/warehousing/routes";
import * as React from "react";
import { useGetWarehouseById } from "../WarehouseOverview/use-get-warehouse";
import { WarehousingHeader } from "containers/warehousing/components/WarehousingHeader";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";
import TextButton from "common/components/textbutton/TextButton";
import { WarehousingWorkspacePaddingContainer } from "containers/warehousing/components/WarehousingWorkspacePaddingContainer";
import { warehouseBorderStyle } from "containers/warehousing/components/borderStyle";
import { FilteBar } from "containers/warehousing/components/FilteBar";
import { TableHeader } from "components/TableHeader";
import Typography from "components/Typography/Typography";
import { Table } from "../../../../components/Table";
import { IAppliedFilter } from "common/components/filterbar/PureFilterBar";
import {
  useAddUserToWarehouse,
  useGetWarehouseTeambyId,
} from "./use-get-warehouse-team";
import { Avatar } from "@mui/material";
import { useSortState } from "hooks/useSortState";
import { getRoleTranslation, isAdminContractRole, isAdminRole, useIsAdmin, useIsAdminContract } from "utils/user-role";
import { RemoveUserModalButton } from "./RemoveUserModalButton";
import { useGetUsers } from "userandauth/hooks/useGetUsers";
import { Modal, Title } from "../../../../components/ReusableModal";
import { toasti18n } from "utils/toast";
import Button from "components/Button/Button";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";

export function WarehouseIdTeam() {
  const warehouseId = useGetWarehouseId();
  const { data: warehouse, isLoading } = useGetWarehouseById(warehouseId);
  const { isLoading: areWarehouseUsersLoading } =
    useGetWarehouseTeambyId(warehouseId);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WarehousingHeader
        title={"label_warehouse_team"}
        isLoading={isLoading}
        breadCrumbs={[
          {
            label: "label_warehousing",
            link: warehousingRoutes.index(),
          },
          {
            label: "label_warehouses",
            link: warehousingRoutes.warehouses(),
          },
          {
            label: warehouse?.name,
            link: warehousingRoutes.warehousesWithWarehouseId(warehouseId),
          },
          {
            label: "label_warehouse_team",
          },
        ]}
        buttons={
          <div>
            {areWarehouseUsersLoading ? null : (
              <AddUserToWarehouseModalButton warehouseId={warehouseId} />
            )}
          </div>
        }
      />
      <div style={{ flex: "1 1 0px", minHeight: "0px", background: "white" }}>
        <ErrorBoundary fallback={Fallback}>
          <WarehouseTeamTable />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function WarehouseTeamTable() {
  const { t } = useTranslation();
  const [filterState, setFilterState] = React.useState<IAppliedFilter>(null);
  const warehouseId = useGetWarehouseId();
  const { data, isLoading } = useGetWarehouseTeambyId(warehouseId);

  const filteredData = React.useMemo(() => {
    if (!data || !filterState) return data;

    return data.filter((user) => {
      if (filterState.field === COLUMNS.USER) {
        return user.firstName
          .toLowerCase()
          .includes(filterState.value.toLowerCase());
      }
      return false;
    });
  }, [data, filterState]);

  const { getSortDirectionByColumnId, sortState, toggleSortColumnByColumnId } =
    useSortState();

  const sortedData = React.useMemo(() => {
    if (!filteredData || !sortState) return filteredData;

    return [...filteredData].sort((userA, userB) => {
      let comparisonResult: number = 0;
      if (sortState.columnId === COLUMNS.USER)
        comparisonResult = userA.firstName.localeCompare(userB.firstName);
      else if (sortState.columnId === COLUMNS.ROLE)
        comparisonResult = userA.role.localeCompare(userB.role);

      return sortState.direction === "ASC"
        ? comparisonResult
        : -comparisonResult;
    });
  }, [filteredData, sortState, sortState]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ borderBottom: warehouseBorderStyle }}>
        <WarehousingWorkspacePaddingContainer>
          <FilteBar
            value={filterState}
            onChange={setFilterState}
            filterOptions={[
              {
                field: COLUMNS.USER,
                queryType: "contains",
                translationKey: "label_user",
              },
            ]}
            placeholder="label_search"
          />
        </WarehousingWorkspacePaddingContainer>
      </div>
      <div style={{ flex: "1 1 0", minHeight: "0px" }}>
        <Table
          data={sortedData}
          isLoading={isLoading}
          getKey={(user) => user.id}
          RowWrapper={WarehousingWorkspacePaddingContainer}
          columns={[
            {
              id: COLUMNS.USER,
              headerCell: (columnId) => (
                <TableHeader
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_user")}
                </TableHeader>
              ),

              cell: (user) => (
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    src={
                      user.userAvatar === "-" || !user.userAvatar
                        ? null
                        : user.userAvatar
                    }
                  />
                  <Typography type="body-2">{user.firstName}</Typography>
                </div>
              ),
              style: {
                flex: "1 0 0px",
                minWidth: "0px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: COLUMNS.ROLE,
              headerCell: (columnId) => (
                <TableHeader
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_role")}
                </TableHeader>
              ),
              style: {
                flex: "1 0 0px",
                minWidth: "0px",
                display: "flex",
                alignItems: "center",
              },
              cell: (user) => (
                <Typography type="body-2" translate>
                  {getRoleTranslation(user.role)}
                </Typography>
              ),
            },

            {
              id: COLUMNS.REMOVE_USER,
              headerCell: () => <></>,
              style: {
                flex: "0 0 24px",
                minWidth: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              cell: (user) =>
                isAdminRole(user.role) || isAdminContractRole(user.role) ? null : (
                  <RemoveUserModalButton
                    userId={user.id}
                    warehouseId={warehouseId}
                  />
                ),
            },
          ]}
        />
      </div>
    </div>
  );
}

const COLUMNS = {
  USER: "user",
  ROLE: "role",
  REMOVE_USER: "removeUser",
} as const;

function useCanAddUserToWarehouse() {
  const canAdd = useIsAdmin() || useIsAdminContract();
  return canAdd;
}

function AddUserToWarehouseModalButton({
  warehouseId,
}: {
  warehouseId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canAddUser = useCanAddUserToWarehouse();
  if (!canAddUser) {
    return null;
  }

  return (
    <>
      <TextButton
        icon="Plus"
        translationKey="label_add_member"
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <AddUserToWarehouseModal
          warehouseId={warehouseId}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
}

function AddUserToWarehouseModal(props: {
  warehouseId: string;
  onClose: () => void;
}) {
  const { data: users, isLoading: areUsersLoading } = useGetUsers();
  const { data: warehouseUsers } = useGetWarehouseTeambyId(props.warehouseId);
  const usersInWarehouse = warehouseUsers.map((wu) => wu.id);
  const usersNotInWarehouse = users?.filter(
    (user) => !usersInWarehouse.includes(user.id)
  );

  const [selectedUserId, setSelectedUserId] = React.useState<string>("");

  const { mutate, isLoading: isAddingUser } = useAddUserToWarehouse({
    onSuccess: () => {
      props.onClose();
      toasti18n.success();
    },
  });
  function handleConfirm() {
    mutate({ userId: selectedUserId, warehouseId: props.warehouseId });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "500px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_add_user</Title>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Autocomplete
          label="label_please_select_user"
          id="user"
          disableClearable
          value={selectedUserId}
          required
          onChange={(newUserId) => {
            setSelectedUserId(newUserId);
          }}
          options={
            areUsersLoading
              ? []
              : usersNotInWarehouse.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} (${user.email})`,
                }))
          }
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "29px",
        }}
      >
        <Button
          type="secondary"
          onClick={props.onClose}
          disabled={isAddingUser}
        >
          action_cancel
        </Button>
        <Button type="primary" onClick={handleConfirm} loading={isAddingUser}>
          action_add_user
        </Button>
      </div>
    </Modal>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MachineDetail--3rCQVhTnOF{display:flex;flex-direction:row;position:relative;flex-grow:1;margin:-20px -35px}@media screen and (max-width: 768px){.MachineDetail--3rCQVhTnOF{margin:-20px}}.MachineDetail--3rCQVhTnOF .content--11KNVUkMbB{padding:20px 35px;flex:1;margin-left:240px;display:flex;flex-direction:column;min-width:0px;position:relative}@media screen and (max-width: 768px){.MachineDetail--3rCQVhTnOF .content--11KNVUkMbB{padding:20px;margin-left:0}}", ""]);
// Exports
exports.locals = {
	"MachineDetail": "MachineDetail--3rCQVhTnOF",
	"content": "content--11KNVUkMbB"
};
module.exports = exports;

import * as React from 'react';

// Custom components
import { EllipsisIcon, Option, OptionGroup } from './components';

// Styles
import * as styles from './EllipsisDropdown.module.scss';

interface IEllipsisDropDownProps {
  children: JSX.Element[];
  className?: string;
}

/**
 ### Usage
 ```
<EllipsisDropDown className={'...'}>
  <EllipsisDropDown.EllipsisIcon onClick={() => {...}}/>
  <EllipsisDropDown.OptionsGroup isOpen={...} className={...}>
    <EllipsisDropDown.Option onClick={() => {...}} translationKey='...' icon='...' className={...}>
    <EllipsisDropDown.Option onClick={() => {...}} translationKey='...' icon='...' className={...}>
  </EllipsisDropDown.OptionGroup>
</EllipsisDropDown>
```

*/

function EllipsisDropdown ({
  className,
  children
}: IEllipsisDropDownProps): JSX.Element {
  return (
    <div
      className={[ className, styles.EllipsisDropdown ].join(' ')}
    >
      {children}
    </div>
  );
}

EllipsisDropdown.EllipsisIcon = EllipsisIcon;
EllipsisDropdown.Option = Option;
EllipsisDropdown.OptionGroup = OptionGroup;

export { EllipsisDropdown };

import * as React from "react";
import { contractRoutes } from "containers/contract/routes";
import { Link, useHistory } from "react-router-dom";
import { ContractWorkSpace } from "../../components/ContractWorkSpace";

import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { toasti18n } from "utils/toast";
import {
  GeneralContractInfoForm,
  GeneralContractInfoFormValue,
  initializeGeneralContractInfoFormValue,
} from "../../components/contract/GeneralContractInfo";
import {
  ContractContactInfoForm,
  ContractContactInfoFormValue,
  initializeContractContactInfoFormValue,
} from "../../components/contract/ContractContactInfo";
import Typography from "components/Typography";

import {
  CreateContractMutation,
  useCreateContractMutation,
} from "gql/generated";
import {
  ContractContactInfo,
  ContractDetails,
  contractByIdCache,
} from "containers/contract/hooks/useGetContractById";
import { organisationIdCache } from "containers/contract/components/contract/OrganisationIdAutoComplete";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";

function NewContract() {
  const { t } = useTranslation();
  const permissionResult = useCanAccessContractFeature();

  return (
    <ContractWorkSpace.ScreenWrapper>
      <ContractWorkSpace.PageTitle>
        {t("label_contract")}
      </ContractWorkSpace.PageTitle>
      {permissionResult.isLoading ? (
        <ContractWorkSpace.LoadingIndicator />
      ) : (
        <AddNewContractForm />
      )}
    </ContractWorkSpace.ScreenWrapper>
  );
}

function AddNewContractForm() {
  const [generalContactInfo, setGeneralContactInfo] = React.useState(
    initializeGeneralContractInfoFormValue
  );
  const [contractContactInfo, setContractContactInfo] = React.useState(
    initializeContractContactInfoFormValue
  );

  const history = useHistory();
  const createContractResult = useCreateContract({
    onSuccess: (response) => {
      const createdContractId = response.createContract2;
      contractByIdCache.addContract(
        computeOptismiticContract({
          contractContactInfo,
          generalContactInfo,
          createdContractId,
        })
      );

      history.push(contractRoutes.contractId(createdContractId));
    },
  });

  function submitForm() {
    createContractResult.submit({
      contact: contractContactInfo,
      general: generalContactInfo,
    });
  }

  return (
    <ContractWorkSpace.MaxWidthContainer>
      <ContractWorkSpace.Spacer />
      <GeneralContractInfoForm
        value={generalContactInfo}
        onChange={setGeneralContactInfo}
      />
      <ContractWorkSpace.Spacer />

      <Typography type="headline-6" translate>
        label_location
      </Typography>
      <div style={{ height: "10px" }}></div>
      <Typography type="caption" color="onSurfaceMedium" translate>
        label_finish_creating_contract_to_begin_adding_locations
      </Typography>
      <ContractWorkSpace.Spacer />
      <ContractContactInfoForm
        value={contractContactInfo}
        onChange={setContractContactInfo}
      />
      <ContractWorkSpace.Spacer />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to={contractRoutes.index()}
          style={{
            pointerEvents: createContractResult.isLoading ? "none" : "auto",
          }}
        >
          <Button type="secondary" disabled={createContractResult.isLoading}>
            action_cancel
          </Button>
        </Link>
        <Button
          type="primary"
          onClick={submitForm}
          loading={createContractResult.isLoading}
        >
          action_save
        </Button>
      </div>
    </ContractWorkSpace.MaxWidthContainer>
  );
}

export default NewContract;

type CreateContractFormValue = {
  general: GeneralContractInfoFormValue;
  contact: ContractContactInfoFormValue;
};

function computeOptismiticContract({
  contractContactInfo,
  generalContactInfo,
  createdContractId,
}: {
  contractContactInfo: ContractContactInfo;
  generalContactInfo: GeneralContractInfoFormValue;
  createdContractId: string;
}): ContractDetails {
  return {
    contact: {
      accountPayableId: contractContactInfo.accountPayableId,
      contactPerson: contractContactInfo.contactPerson,
      contractOwnerName: contractContactInfo.contractOwnerName,
      phoneNumber: contractContactInfo.phoneNumber,
    },
    general: {
      contractId: createdContractId,
      contractFriendlyId: generalContactInfo.contractFriendlyId,
      contractStartDate: generalContactInfo.contractStartDate,
      contractEndDate: generalContactInfo.contractEndDate,
      numberOfLocationsPerContract: Number(
        generalContactInfo.numberOfLocationsPerContract
      ),
      organisation: {
        id: generalContactInfo.organisationId,
        name: organisationIdCache.getOrganisationNameById(
          generalContactInfo.organisationId
        ),
      },
    },
    documents: [],
    locations: [],
  };
}

function useCreateContract({
  onSuccess = () => {},
}: {
  onSuccess?: (response: CreateContractMutation) => void;
}) {
  const { mutate, ...rest } = useCreateContractMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: async (response) => {
      toasti18n.success();
      onSuccess(response);
    },
  });

  function submit(form: CreateContractFormValue) {
    const mutationArg = {
      input: {
        accountPayableId: form.contact.accountPayableId,
        contactPerson: {
          name: form.contact.contactPerson,
        },
        contractOwnerName: form.contact.contractOwnerName,
        contractPeriod: {
          start: form.general.contractStartDate.format("YYYY-MM-DD"),
          end: form.general.contractEndDate.format("YYYY-MM-DD"),
        },
        phoneNumber: form.contact.phoneNumber,
        friendlyId: form.general.contractFriendlyId,
        numberOfLocations: Number(form.general.numberOfLocationsPerContract),
        organizationId: form.general.organisationId,
      },
    };
    mutate(mutationArg);
  }
  return {
    submit,
    ...rest,
  };
}

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { IUser } from 'userandauth/interfacesUser';
import { getUser } from 'userandauth/servicesUser';
import { userKeys } from './userKeys';

export type useGetUserOptions = Omit<
  UseQueryOptions<IUser, AxiosError>,
  'queryKey' | 'queryFn' | 'staleTime' | 'retry'
>;

function useGetUser(userId: string, options: useGetUserOptions) {
  return useQuery<IUser, AxiosError>(
    userKeys.user(userId),
    () => getUser(userId),
    {
      ...options,
      staleTime: Infinity,
      retry: 0,
    }
  );
}

export { useGetUser };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header--1y-tYuHUpx{display:flex;flex-direction:row;align-items:center;margin-bottom:20px;gap:20px}.Header--1y-tYuHUpx .ButtonsAndNav--gTi9LrPFI4{display:flex;flex:1 1 0;justify-content:space-between;align-items:center}.Header--1y-tYuHUpx .Buttons--lWWN0OHDA5{display:flex;gap:20px}", ""]);
// Exports
exports.locals = {
	"Header": "Header--1y-tYuHUpx",
	"ButtonsAndNav": "ButtonsAndNav--gTi9LrPFI4",
	"Buttons": "Buttons--lWWN0OHDA5"
};
module.exports = exports;

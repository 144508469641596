import * as React from "react";
import { Redirect, Switch } from "react-router-dom";

import Route from "common/components/route/Route";
import SideNav from "common/components/sidenav/SideNav";
import TopNav from "common/components/topnav/TopNav";

import Dashboard from "containers/dashboard/Dashboard";
import MachineDetail from "containers/machinedetail/MachineDetail";
import { Service } from "containers/service/Service";
import Settings from "containers/settings/Settings";
import Location from "containers/location/Location";
import { NewOperations } from "containers/new-operations/NewOperations";
import Warehousing, { warehousingRoutes } from "containers/warehousing";

import { ErrorBoundary } from "react-error-boundary";
import { FallbackUI } from "error-handling-ui/FallbackUI";

import { Contract, contractRoutes } from "containers/contract";
import { TransactionRoute } from "containers/transactions";
import { useIsHideNavigation } from "./isHideNavigation";
import { DownloadReportPage } from "containers/transactions/downloadReport";
import { routes as maintenanceRoutes } from "containers/maintenance/routes";
import { Maintenance } from "containers/maintenance/Maintenance";

import * as styles from "./Portal.module.scss";
import { MachineV2 } from "containers/machinesV2";

function Portal(): JSX.Element {
  const isHide = useIsHideNavigation();
  return (
    <div className={styles.Portal}>
      {!isHide && (
        <>
          <TopNav />
          <SideNav />
        </>
      )}
      <div
        className={[styles.content, !isHide ? styles.contentWithNav : ""].join(
          " "
        )}
      >
        <ErrorBoundary
          FallbackComponent={FallbackUI}
          resetKeys={[window.location.href]}
        >
          <Switch>
            <Route path="/machines-v2" component={() => <Redirect to="/machines" />} /> 
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              path="/machines/detail/:machineId"
              component={MachineDetail}
            />
            <Route path="/machines" component={MachineV2} />

            <Route path="/transactions" component={TransactionRoute} />
            <Route path={warehousingRoutes.index()} component={Warehousing} />
            <Route path={contractRoutes.index()} component={Contract} />
            <Route path={maintenanceRoutes.index()} component={Maintenance} />
            <Route path="/service" component={Service} />
            <Route path="/new-operations" component={NewOperations} />
            <Route path="/settings" component={Settings} />
            <Route path="/location" component={Location} />
            <Route
              path="/download-report/:jobId"
              component={DownloadReportPage}
            />
            <Redirect to="/dashboard" />
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Portal;

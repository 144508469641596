/**
 * Changes the page parameter in the query string and returns the full route
 * @param page - The page to change the query string parameter to
 * @param queryObject - The query string in the form of an object - The object returned from `useQuerySync`
 * @param pathname - Path name of the current location
 * @returns The route with the 'page' parameter in the query string modified
 *
 * Examples:
 * generateLinkToPage(5, {page: '1', group: 'Vendii'}, pathname: '/users');
 *  => `/users?page=5&group=Vendii`
 * generateLinkToPage(20, {groupBy: 'machineId', page: '6'}, pathname: '/sales');
 *  => `/sales?groupBy=machineId&page=20`
 */
export function generateLinkToPage (
  page: number,
  queryObject: { page: string },
  pathname: string
) {
  const queryObjectCopy = { ...queryObject };
  queryObjectCopy.page = String(page);
  const searchString = new URLSearchParams(queryObjectCopy).toString();
  return `${pathname}?${searchString}`;
}

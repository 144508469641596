import * as React from "react";
import Typography from "components/Typography/Typography";
import {
  useGetWarehouseTeambyId,
  useRemoveUserFromWarehouse,
} from "./use-get-warehouse-team";
import Icon from "common/components/icon/Icon";
import { toasti18n } from "utils/toast";
import { Modal, Title } from "../../../../components/ReusableModal";
import Button from "components/Button/Button";
import { useIsAdmin, useIsAdminContract } from "utils/user-role";

function useCanRemoveUserFromWarehouse() {
  const canRemove = useIsAdmin() || useIsAdminContract();
  return canRemove;
}

export function RemoveUserModalButton(props: {
  userId: string;
  warehouseId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canRemove = useCanRemoveUserFromWarehouse();
  if (!canRemove) {
    return null;
  }

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(true)}
      >
        <Icon name="TrashInventoryV2" color="primary500" />
      </div>
      {isOpen ? (
        <RemoveUserFromWarehouseModal
          onClose={() => setIsOpen(false)}
          userId={props.userId}
          warehouseId={props.warehouseId}
        />
      ) : null}
    </>
  );
}

function RemoveUserFromWarehouseModal(props: {
  userId: string;
  warehouseId: string;
  onClose: () => void;
}) {
  const { data } = useGetWarehouseTeambyId(props.warehouseId);
  const userToBeRemoved = data.find((user) => user.id === props.userId);
  const { mutate, isLoading: isRemovingUser } = useRemoveUserFromWarehouse({
    onSuccess: () => {
      props.onClose();
      toasti18n.success();
    },
  });
  function handleConfirm() {
    mutate({ userId: props.userId, warehouseId: props.warehouseId });
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "500px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_remove_user</Title>
      <div
        style={{
          backgroundColor: "#ffdedb",
          borderRadius: "3px",
          marginTop: "20px",
        }}
      >
        <span
          style={{
            fontFamily: "Kanit, sans-serif",
            fontWeight: "700",
            fontSize: "16px",
            marginRight: "8px",
          }}
        >
          {userToBeRemoved.email}
        </span>
        <Typography type="subtitle-1" translate>
          warning_remove_user
        </Typography>
      </div>
      <div
        style={{
          marginTop: "8px",
          marginBottom: "14px",
        }}
      >
        <Typography type="subtitle-1" translate>
          warning_remove_user_confirmation_label
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="secondary"
          onClick={props.onClose}
          disabled={isRemovingUser}
        >
          action_cancel
        </Button>
        <Button type="status" onClick={handleConfirm} loading={isRemovingUser}>
          action_confirm
        </Button>
      </div>
    </Modal>
  );
}

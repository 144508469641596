// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"font-main-light\";src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"font-main-regular\";src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"font-main-medium\";src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"font-alt-light\";src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"font-alt-regular\";src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"font-alt-medium\";src:url(\"/fonts/Roboto-Medium.ttf\")}@font-face{font-family:\"Kanit\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"Kanit\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"Kanit\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"Roboto\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"Roboto\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"Roboto\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Medium.ttf\")}.MachineStatusBadge--3Qw59Mmh2i{display:inline-flex;justify-content:center;align-items:center;width:96px;height:24px;border-radius:9999px;font-family:\"Kanit\",\"Roboto\",Arial,Helvetica,sans-serif;font-size:12px;line-height:16px}", ""]);
// Exports
exports.locals = {
	"MachineStatusBadge": "MachineStatusBadge--3Qw59Mmh2i"
};
module.exports = exports;

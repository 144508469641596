// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Details--2ylPVYy59R{flex-grow:1;margin:-20px -35px;background-color:#fff;display:flex;flex-direction:column;border-left:1px solid rgba(0,0,0,.12)}.Details--2ylPVYy59R>*:not(:first-child){border-top:1px solid rgba(0,0,0,.12)}.HeaderPadding--6gOam1tm41{padding:31px 0px;background-color:#f3f3fd}.HeaderFlex--3N7SxQuJRU{display:flex;gap:30px;align-items:center}.ContainerPadding--1Wp2Q2-SVu{padding:0 40px}.TwoColumnLayoutContainer--mX3SoMuLQc{display:flex}.TwoColumnLayoutContainer--mX3SoMuLQc .LeftColumn--3hmv0dLnJi{min-width:0;flex-basis:236px}.TwoColumnLayoutContainer--mX3SoMuLQc .RightColumn--BDuCsL-nqk{min-width:0;flex-basis:647px;display:flex;flex-direction:column;gap:14px}.SectionPadding--qGbEOhhLEk{padding:28px 0px}.LocationHistory--SNUw1kidVa{padding:0px 14px;display:flex;flex-direction:column;gap:14px}.ButtonsContainer--sOYjOCfMh5{display:flex;justify-content:space-between}.StatusContainer--2EuLrRrP0w{display:flex;gap:35px}.StatusContainer--2EuLrRrP0w .CheckBoxAndLabel--_nvYgCt6wY{display:flex;gap:8px;align-items:center}.Setting--3DQx5zuDI7{display:flex;flex-direction:column;gap:23px}.Setting--3DQx5zuDI7>*{display:flex;align-items:center;gap:12px}.Setting--3DQx5zuDI7>* .WithCaption--23a38zuJn5{display:flex;flex-direction:column}.Setting--3DQx5zuDI7>* .WithCaption--23a38zuJn5 .WorkOrderToolTip--3mhz4w1CP7{display:flex;flex-direction:column;padding:16px;min-width:max-content}.Setting--3DQx5zuDI7>* .WithInfoButton--3tlhyVzlss{display:flex;align-items:center}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Details": "Details--2ylPVYy59R",
	"HeaderPadding": "HeaderPadding--6gOam1tm41",
	"HeaderFlex": "HeaderFlex--3N7SxQuJRU",
	"ContainerPadding": "ContainerPadding--1Wp2Q2-SVu",
	"TwoColumnLayoutContainer": "TwoColumnLayoutContainer--mX3SoMuLQc",
	"LeftColumn": "LeftColumn--3hmv0dLnJi",
	"RightColumn": "RightColumn--BDuCsL-nqk",
	"SectionPadding": "SectionPadding--qGbEOhhLEk",
	"LocationHistory": "LocationHistory--SNUw1kidVa",
	"ButtonsContainer": "ButtonsContainer--sOYjOCfMh5",
	"StatusContainer": "StatusContainer--2EuLrRrP0w",
	"CheckBoxAndLabel": "CheckBoxAndLabel--_nvYgCt6wY",
	"Setting": "Setting--3DQx5zuDI7",
	"WithCaption": "WithCaption--23a38zuJn5",
	"WorkOrderToolTip": "WorkOrderToolTip--3mhz4w1CP7",
	"WithInfoButton": "WithInfoButton--3tlhyVzlss"
};
module.exports = exports;

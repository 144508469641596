import { IMachineInventoryData } from "machines/interfaces";
import * as React from "react";
import { useState } from "react";
import { Updater, useImmer } from "use-immer";

import * as stylesSwapOrderForm from "./SwapOrderForm.module.scss";
import * as stylesSwapOrderConfirmationModal from "./SwapOrderConfirmationModal.module.scss";

import Modal from "common/components/modal/Modal";
import Input from "common/components/input/Input";
import Checkbox from "common/components/checkbox/Checkbox";
import Typography from "common/components/typography/Typography";
import Select from "common/components/select/Select";
import { useGetProducts } from "product/hooks/useGetProducts";
import Button from "common/components/button/Button";
import { RadioButton } from "common/components/radiobutton/RadioButton";
import { IPostSwapOrder } from "services/swaporders";
import { usePostSwapOrder } from "reactquery/swaporders";
import Autocomplete from "components/Autocomplete";

export type MachineType = "Spiral" | "Beverage";
interface ISwapOrderModalProps {
  machineType: MachineType;
  machineInventory: IMachineInventoryData;
  onClose: () => void;
}

interface IFormValues {
  swapInfo: {
    machineId: string;
    slot: string;
    machineInventoryId: string;
    currentNoPartialRefill: boolean;
    productOld: {
      name: string;
      id: string;
    };
    productNew: {
      name: string;
      id: string;
    };
    replacementMethod:
      | "removeOldOnCritical"
      | "removeOldNow"
      | "continueToRefillOnTop";
  };
  newSlotConfig: {
    parLevel: number;
    refillTarget: number;
    priority: string;
    capacity: number;
    noPartialRefill: boolean;
  };
}

function computeFormDefaultValue(
  machineInventory: IMachineInventoryData
): IFormValues {
  return {
    swapInfo: {
      machineId: machineInventory.machineId,
      slot: machineInventory.slot,
      machineInventoryId: machineInventory.id,
      currentNoPartialRefill: machineInventory.noPartialRefill,
      productOld: {
        name: machineInventory.inventory,
        id: machineInventory.productId,
      },
      productNew: {
        name: machineInventory.inventory,
        id: machineInventory.productId,
      },
      replacementMethod: "removeOldNow",
    },
    newSlotConfig: {
      parLevel: machineInventory.parLevel,
      refillTarget: machineInventory.refillLevel,
      priority: String(
        machineInventory.additional.inventoryInfo.importanceLevel
      ), // Vasant API number
      capacity: machineInventory.capacity,
      noPartialRefill: machineInventory.noPartialRefill,
    },
  };
}

function SwapOrderModal({
  machineType,
  machineInventory,
  onClose,
}: ISwapOrderModalProps): JSX.Element {
  const [step, setStep] = useState<"orderForm" | "confirmationModal">(
    "orderForm"
  );
  const [swapOrderFormValues, setSwapOrderFormValues] = useImmer(() =>
    computeFormDefaultValue(machineInventory)
  );

  return (
    <>
      {step === "orderForm" && (
        <SwapOrderForm
          swapOrderFormValues={swapOrderFormValues}
          setSwapOrderFormValues={setSwapOrderFormValues}
          onReview={() => setStep("confirmationModal")}
          onCancel={() => onClose()}
          machineType={machineType}
        />
      )}
      {step === "confirmationModal" && (
        <SwapOrderConfirmationModal
          swapOrderFormValues={swapOrderFormValues}
          onAccept={() => onClose()}
          onCancel={() => setStep("orderForm")}
        />
      )}
    </>
  );
}

export { SwapOrderModal };

interface ISwapOrderForm {
  machineType: MachineType;
  swapOrderFormValues: IFormValues;
  setSwapOrderFormValues: Updater<IFormValues>;
  onReview: () => void;
  onCancel: () => void;
}

function SwapOrderForm({
  machineType,
  swapOrderFormValues,
  setSwapOrderFormValues,
  onReview,
  onCancel,
}: ISwapOrderForm): JSX.Element {
  const { data: productOptions, isLoading: isProductOptionsLoading } =
    useGetProducts();

  const productOptionsFilter = React.useMemo(() => {
    if (!productOptions) return [];
    
    return productOptions.filter(({ machineCategory }) =>
      machineType === "Spiral" ? machineCategory === "SPIRAL" : machineCategory !== "SPIRAL"
    );
  }, [productOptions, machineType]);

  return (
    <Modal contentClassName={stylesSwapOrderForm.SwapOrderForm}>
      <div className={stylesSwapOrderForm.Header}>
        <Typography
          type="headline-6"
          translationKey={[
            "label_header_swap_inventory",
            ` (${swapOrderFormValues.swapInfo.slot})`,
          ]}
        />
      </div>

      <Autocomplete
        label="label_new_material"
        id="new-material"
        disableClearable
        value={swapOrderFormValues.swapInfo.productNew.id}
        required
        options={
          isProductOptionsLoading
            ? [
                {
                  label: swapOrderFormValues.swapInfo.productNew.name,
                  value: swapOrderFormValues.swapInfo.productNew.id,
                },
              ]
            : productOptionsFilter.map((product) => ({
                label: product.name,
                value: product.productId,
              }))
        }
        onChange={(selectedProductId) => {
          const selectedProduct = productOptionsFilter.find(
            (product) => product.productId === selectedProductId
          );
          setSwapOrderFormValues((draft) => {
            draft.swapInfo.productNew = {
              id: selectedProduct.productId,
              name: selectedProduct.name,
            };
          });
        }}
      />

      <div className={stylesSwapOrderForm.ReplacementMethodContainer}>
        <div className={stylesSwapOrderForm.ReplacementMethodHeaderContainer}>
          <Typography
            type="caption"
            translationKey="label_replacement_method"
          />
        </div>
        <div className={stylesSwapOrderForm.RemoveOldRadioAndTextContainer}>
          <RadioButton
            checked={
              swapOrderFormValues.swapInfo.replacementMethod !==
              "continueToRefillOnTop"
            }
            onClick={() => {
              setSwapOrderFormValues((draft) => {
                draft.swapInfo.replacementMethod = "removeOldNow";
              });
            }}
          />
          <Typography
            className={stylesSwapOrderForm.RemoveOldLabel}
            type="body-1"
            translationKey="label_replace_method_remove_old"
          />
        </div>
        <div className={stylesSwapOrderForm.RefillOnTopRadioAndTextContainer}>
          <RadioButton
            checked={
              swapOrderFormValues.swapInfo.replacementMethod ===
              "continueToRefillOnTop"
            }
            onClick={() => {
              setSwapOrderFormValues((draft) => {
                draft.swapInfo.replacementMethod = "continueToRefillOnTop";
              });
            }}
            disabled={swapOrderFormValues.swapInfo.currentNoPartialRefill}
          />
          <Typography
            type="body-1"
            translationKey="label_replace_method_refill_on_top"
            className={[
              stylesSwapOrderForm.RefillOnTopLabel,
              swapOrderFormValues.swapInfo.currentNoPartialRefill
                ? stylesSwapOrderForm.RefillOnTopLabel___disabled
                : "",
            ].join(" ")}
          />
        </div>
      </div>

      {swapOrderFormValues.swapInfo.replacementMethod !==
        "continueToRefillOnTop" && (
        <div className={stylesSwapOrderForm.RemoveOldWhenContainer}>
          <div className={stylesSwapOrderForm.RemoveOldWhenHeaderContainer}>
            <Typography
              type="caption"
              translationKey="label_create_swap_sheet_when"
            />
          </div>
          <div
            className={
              stylesSwapOrderForm.RemoveOldWhenLowRadioAndTextContainer
            }
          >
            <RadioButton
              disabled={true}
              checked={
                swapOrderFormValues.swapInfo.replacementMethod ===
                "removeOldOnCritical"
              }
              onClick={() => {
                setSwapOrderFormValues((draft) => {
                  draft.swapInfo.replacementMethod = "removeOldOnCritical";
                });
              }}
            />
            <Typography
              className={stylesSwapOrderForm.RemoveOldWhenLowLabel}
              type="body-1"
              translationKey="label_create_swap_when_low"
            />
          </div>
          <div
            className={stylesSwapOrderForm.RemoveOldNowRadioAndTextContainer}
          >
            <RadioButton
              checked={
                swapOrderFormValues.swapInfo.replacementMethod ===
                "removeOldNow"
              }
              onClick={() => {
                setSwapOrderFormValues((draft) => {
                  draft.swapInfo.replacementMethod = "removeOldNow";
                });
              }}
            />
            <Typography
              type="body-1"
              translationKey="label_create_swap_now"
              className={stylesSwapOrderForm.RemoveOldNowLabel}
            />
          </div>
        </div>
      )}

      <Input
        label="label_low_level"
        type="number"
        onChange={(value) =>
          setSwapOrderFormValues((draft) => {
            draft.newSlotConfig.parLevel = value;
          })
        }
        value={swapOrderFormValues.newSlotConfig.parLevel}
        className={stylesSwapOrderForm.ParLevelInputComponent}
      />

      <Input
        label="label_refill_target_level"
        type="number"
        onChange={(value) =>
          setSwapOrderFormValues((draft) => {
            draft.newSlotConfig.refillTarget = value;
          })
        }
        value={swapOrderFormValues.newSlotConfig.refillTarget}
        className={stylesSwapOrderForm.RefillLevelInputComponent}
      />

      <Select
        label="label_priority"
        value={swapOrderFormValues.newSlotConfig.priority}
        className={stylesSwapOrderForm.PrioritySelectComponent}
        options={[
          { label: "0", value: "0" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
        ]}
        onSelect={(selectedPriority) => {
          setSwapOrderFormValues((draft) => {
            draft.newSlotConfig.priority = selectedPriority as string;
          });
        }}
      />

      <Input
        label="label_slot_capacity"
        value={swapOrderFormValues.newSlotConfig.capacity}
        type="number"
        onChange={(value) =>
          setSwapOrderFormValues((draft) => {
            draft.newSlotConfig.capacity = value;
          })
        }
        className={stylesSwapOrderForm.CapacityInputComponent}
      />

      <div className={stylesSwapOrderForm.NoPartialRefillContainer}>
        <Checkbox
          checked={swapOrderFormValues.newSlotConfig.noPartialRefill}
          onClick={(checkedState) => {
            setSwapOrderFormValues((draft) => {
              draft.newSlotConfig.noPartialRefill = checkedState;
            });
          }}
        />
        <Typography
          translationKey="label_swap_modal_no_partial_refill"
          type="body-1"
          className={stylesSwapOrderForm.NoPartialRefillLabel}
        />
      </div>

      <div className={stylesSwapOrderForm.ButtonsContainer}>
        <Button
          translationKey="action_cancel"
          onClick={onCancel}
          type="secondary"
        />
        <Button
          onClick={onReview}
          translationKey="action_review"
          type="primary"
        />
      </div>
    </Modal>
  );
}

interface ISwapOrderConfirmationModal {
  swapOrderFormValues: IFormValues;
  onCancel: () => void;
  onAccept: () => void;
}

function SwapOrderConfirmationModal({
  swapOrderFormValues,
  onCancel,
  onAccept,
}: ISwapOrderConfirmationModal): JSX.Element {
  const { mutate: createSwapOrder, isLoading } = usePostSwapOrder();

  // Ugly utility
  const getValue = mapReplaceMethodToValue(
    swapOrderFormValues.swapInfo.replacementMethod
  );

  return (
    <Modal
      contentClassName={
        stylesSwapOrderConfirmationModal.SwapInventoryConfirmationModal
      }
    >
      {/* Header section - Replace method and slot name */}
      <header className={stylesSwapOrderConfirmationModal.header}>
        <Typography
          type="headline-6"
          translationKey={[
            getValue(
              "label_header_swap_inventory",
              "label_header_swap_inventory",
              "label_header_refill_without_removing"
            ),
            ` (${swapOrderFormValues.swapInfo.slot})`,
          ]}
        />
      </header>

      {/* Machine information */}
      <section className={stylesSwapOrderConfirmationModal.machineInfo}>
        <Typography
          type="body-2"
          text={swapOrderFormValues.swapInfo.machineId}
        />
      </section>

      {/* Swap inventory summary */}
      <section
        className={[
          stylesSwapOrderConfirmationModal.swapInventorySummary,
          getValue(
            stylesSwapOrderConfirmationModal.swapInventorySummary__blueTheme,
            stylesSwapOrderConfirmationModal.swapInventorySummary__redTheme,
            stylesSwapOrderConfirmationModal.swapInventorySummary__blueTheme
          ),
        ].join(" ")}
      >
        <div className={stylesSwapOrderConfirmationModal.oldMaterialContainer}>
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_replacement_action_remove",
              "label_replacement_action_remove",
              "label_replacement_action_current"
            )}
            className={stylesSwapOrderConfirmationModal.oldMaterialAction}
          />
          <Typography
            type="body-1"
            text={swapOrderFormValues.swapInfo.productOld.name}
            className={stylesSwapOrderConfirmationModal.oldMaterialName}
          />
        </div>
        <div className={stylesSwapOrderConfirmationModal.newMaterialContainer}>
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_replacement_action_swap_to",
              "label_replacement_action_swap_to",
              "label_replacement_action_continue_refill"
            )}
            className={stylesSwapOrderConfirmationModal.newMaterialAction}
          />
          <Typography
            type="body-1"
            text={swapOrderFormValues.swapInfo.productNew.name}
            className={stylesSwapOrderConfirmationModal.newMaterialName}
          />
        </div>
      </section>

      {/* Confirmation & Cancellation */}
      <section className={stylesSwapOrderConfirmationModal.confirmationSection}>
        <div
          className={
            stylesSwapOrderConfirmationModal.confirmationStringContainer
          }
        >
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_confirm_create_swap_sheet_later",
              "label_confirm_create_swap_sheet_now",
              "label_confirm_refill_without_removing"
            )}
          />
        </div>
        <div className={stylesSwapOrderConfirmationModal.confirmationButtons}>
          <Button
            translationKey="action_cancel"
            onClick={onCancel}
            disabled={isLoading}
            type="secondary"
          />
          <Button
            translationKey={getValue(
              "action_create_swap_sheet_later",
              "action_create_swap_sheet_now",
              "action_refill_without_removing"
            )}
            onClick={() => {
              createSwapOrder(buildRequestObject(swapOrderFormValues), {
                onSuccess: () => onAccept(),
              });
            }}
            type={getValue("primary", "status", "primary")}
            loading={isLoading}
          />
        </div>
      </section>
    </Modal>
  );
}

function mapReplaceMethodToValue(
  replacementMethod: IFormValues["swapInfo"]["replacementMethod"]
) {
  function getValue(
    removeOldOnCriticalValue,
    removeOldNowValue,
    continueToRefillOnTopValue
  ) {
    if (replacementMethod === "removeOldOnCritical")
      return removeOldOnCriticalValue;
    if (replacementMethod === "removeOldNow") return removeOldNowValue;
    if (replacementMethod === "continueToRefillOnTop")
      return continueToRefillOnTopValue;
  }
  return getValue;
}

function buildRequestObject(formValue: IFormValues): IPostSwapOrder {
  return {
    machineId: formValue.swapInfo.machineId,
    swapMethod: (() => {
      switch (formValue.swapInfo.replacementMethod) {
        case "continueToRefillOnTop":
          return "append";
        case "removeOldNow":
          return "immediate";
        case "removeOldOnCritical":
          return "await_low_level";
      }
    })(),
    assignee: "",
    itemsRequested: [
      {
        productId: formValue.swapInfo.productNew.id,
        machineInventoryId: formValue.swapInfo.machineInventoryId,
        slotConfig: {
          slot: formValue.swapInfo.slot,
          parLevel: formValue.newSlotConfig.parLevel,
          refillLevel: formValue.newSlotConfig.refillTarget,
          capacity: formValue.newSlotConfig.capacity,
          noPartialRefill: formValue.newSlotConfig.noPartialRefill,
          additional: {
            inventoryInfo: {
              importanceLevel: Number(formValue.newSlotConfig.priority), // Need to talk to Vasant about this
            },
          },
        },
      },
    ],
  };
}

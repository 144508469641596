import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface IUseQuerySyncReturnValue<T> {
  queryObject: T;
  setQueryObject: React.Dispatch<React.SetStateAction<T>>;
  queryString: string;
}

/** This hook exposes a `queryObject` that models the query string in the URL. The `queryObject` will always be kept in sync with the query string portion of the URL. The `setQueryObject` will change the query string in the URL, which would cause a subsequent change in the queryObject. Upon initialization, if there's no query string in the URL, the queryObject will be the `defaultParams` object argument
 * @param defaultParams - Object containing the default values for the query string if no query string specified in the URL
 * @returns `IUseQuerySyncReturnValue`
 * @returns `IUseQuerySyncReturnValue.queryObject` - The query object that is kept in sync with the query string in the URL
 * @returns `IUseQuerySyncReturnValue.setQueryObject` - React's setState function to set the query string in the URL, which would consequently change the queryObject
 * @returns `IUseQuerySyncReturnValue.queryString` - The query string built from the current query object
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 04-Aug-21
 *
 * __Code last updated__: 20-Aug-21
 */
export default function useQuerySync<T extends {[index: string]: string}> (
  defaultParams: T
): IUseQuerySyncReturnValue<T> {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const isMounting = useRef(true);

  // Initialization of the queryObject (current query string else default params)
  const [ queryObject, setQueryObject ] = useState<T>(() => {
    const params = new URLSearchParams(search);
    if (params.toString() === '') {
      return defaultParams;
    } else {
      return getParamsObject<T>(params);
    }
  });

  // Whenever search changes, update the query object
  useEffect(() => {
    if (search === '') {
      return;
    }
    const params = new URLSearchParams(search);
    setQueryObject(getParamsObject<T>(params));
  }, [search]);

  // If user navigate to same path via link click, prevent current query string from disappearing using the queryObject
  useEffect(() => {
    if (search === '' && isMounting.current === false) {
      const params = new URLSearchParams(queryObject as any);
      history.replace(`${pathname}?${params.toString()}`);
    }
  }, [search]);

  const [ queryStringWithObject, setQueryStringWithObject ] = useState(() => ({
    ...queryObject
  }));

  // Whenever user setQueryObject, change the URL
  useEffect(() => {
    if (isMounting.current === false) {
      const params = new URLSearchParams(queryStringWithObject as any);
      history.push(`${pathname}?${params.toString()}`);
    }
  }, [JSON.stringify(queryStringWithObject)]);

  // Replace current URL on mount using the queryObject
  useEffect(() => {
    const params = new URLSearchParams(queryObject as any);
    history.replace(`${pathname}?${params.toString()}`);
    isMounting.current = false;
  }, []);

  // Current queryString is built from the current queryObject
  return {
    queryObject,
    setQueryObject: setQueryStringWithObject,
    queryString: new URLSearchParams(queryObject as any).toString()
  };
}

/**
 * Function to build the queryObject object from the URLSearchParams instance. Loops through each key value pair in the query string and builds an object from it
 */
function getParamsObject<T> (params: URLSearchParams) {
  const queryObject = {};
  params.forEach((value, key) => {
    queryObject[key] = value;
  });

  return queryObject as T;
}

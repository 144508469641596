import * as React from "react";
import { useGetServiceZonesByOrgIdQuery } from "gql/generated";
import Autocomplete, { AutocompleteOption, IAutocompleteProps } from "components/Autocomplete/Autocomplete";

type ServiceZoneIdFormProps = {
  organizationId: string;
  onChange: (value: string) => void;
  value: string;
  fallBackOptions?: Array<AutocompleteOption>;
}  & Omit<IAutocompleteProps, "options" | "label" | "id">;

export function ServiceZoneIdAutoComplete({
  value, onChange, organizationId, fallBackOptions = [], ...props
}: ServiceZoneIdFormProps) {
  const getServiceZonesByOrgIdQueryResult = useGetServiceZonesByOrgIdQuery(
    {
      organisationId: organizationId,
    },
    {
      enabled: organizationId !== "",
    }
  );

  const serviceZones = getServiceZonesByOrgIdQueryResult.data?.serviceZones;
  const optionsFromQuery = serviceZones?.map((serviceZone) => ({
    label: serviceZone.friendlyId,
    value: serviceZone.id,
  })) ?? [];
  const options = serviceZones ? optionsFromQuery : fallBackOptions;

  return (
    <Autocomplete
      label="label_service_zone"
      id="service_zone"
      disableClearable
      value={value}
      onChange={onChange}
      options={options}
      {...props}
      />
  );
}

import { Dispatch } from 'redux';
import { keyBy } from 'lodash';

import { serializeError } from 'common/services/errorService';

import {
  ICreateRefillOrder,
  IRefillOrder,
  IPatchCreateRefillOrder,
  IPatchRefillRefillOrder,
  IPatchAdjustRefillOrder,
  IPatchUndoRefillOrder
} from 'refillorders/interfaces';
import * as refillOrderService from 'refillorders/service';
import { toasti18n } from 'utils/toast';

export function getRefillOrders () {
  return async function (dispatch: Dispatch): Promise<IRefillOrder[]> {
    try {
      dispatch({ type: 'REFILL_ORDER/GET_ALL/REQUEST', payload: true });
      const refillOrders = await refillOrderService.getRefillOrders();
      dispatch({
        type: 'REFILL_ORDER/GET_ALL/SUCCESS',
        payload: keyBy(refillOrders, 'orderNumber')
      });
      return refillOrders;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/GET_ALL/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_fetch_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/GET_ALL/REQUEST', payload: false });
    }
  };
}

export function createRefillOrder (refillOrderData: ICreateRefillOrder) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/CREATE/REQUEST', payload: true });
      const refillOrder = await refillOrderService.createRefillOrder(refillOrderData);
      dispatch({
        type: 'REFILL_ORDER/CREATE/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_create_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/CREATE/ERROR', payload: serializeError(error) });
    } finally {
      dispatch({ type: 'REFILL_ORDER/CREATE/REQUEST', payload: false });
    }
  };
}

export function dismissRefillOrder (orderNumber: string) {
  return async function (dispatch: Dispatch): Promise<string> {
    try {
      dispatch({ type: 'REFILL_ORDER/DISMISS/REQUEST', payload: true });
      const refillOrderId = await refillOrderService.patchDismissRefillOrder(orderNumber);
      dispatch({
        type: 'REFILL_ORDER/DISMISS/SUCCESS',
        payload: { id: refillOrderId }
      });
      toasti18n.success("toast_refill_order_dismiss_success");
      return refillOrderId;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/CREATE/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_dismiss_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/DISMISS/REQUEST', payload: false });
    }
  };
}

export function patchCreateRefillOrder (data: IPatchCreateRefillOrder) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_CREATE/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchCreateRefillOrder(data);
      dispatch({
        type: 'REFILL_ORDER/PATCH_CREATE/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_patch_create_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_CREATE/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_patch_create_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_CREATE/REQUEST', payload: false });
    }
  };
}

export function patchPrekitRefillOrder (orderNumber: string) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_PREKIT/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchPrekitRefillOrder(orderNumber);
      dispatch({
        type: 'REFILL_ORDER/PATCH_PREKIT/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_patch_prekit_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_PREKIT/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_patch_prekit_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_PREKIT/REQUEST', payload: false });
    }
  };
}

export function patchPickupRefillOrder (orderNumber: string) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_PICKUP/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchPickupRefillOrder(orderNumber);
      dispatch({
        type: 'REFILL_ORDER/PATCH_PICKUP/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_patch_pickup_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_PICKUP/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_patch_pickup_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_PICKUP/REQUEST', payload: false });
    }
  };
}

export function patchRefillRefillOrder (data: IPatchRefillRefillOrder) {
  const isAdjustment = data.needAdjustment;
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_REFILL/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchRefillRefillOrder(data);
      dispatch({
        type: 'REFILL_ORDER/PATCH_REFILL/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      if(isAdjustment) {
        toasti18n.success("toast_refill_order_patch_flag_adjust_success");
      }
      if(!isAdjustment) {
        toasti18n.success("toast_refill_order_patch_refill_success");
      }
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_REFILL/ERROR', payload: serializeError(error) });
      if(isAdjustment) {
        toasti18n.error({ message: "toast_refill_order_patch_flag_adjust_error" });
      }
      if(!isAdjustment) {
        toasti18n.error({ message: "toast_refill_order_patch_refill_error" });
      }
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_REFILL/REQUEST', payload: false });
    }
  };
}

export function patchAdjustRefillOrder (data: IPatchAdjustRefillOrder) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_ADJUST/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchAdjustRefillOrder(data);
      dispatch({
        type: 'REFILL_ORDER/PATCH_ADJUST/SUCCESS',
        payload: { [refillOrder.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_patch_adjust_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_ADJUST/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_patch_adjust_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_ADJUST/REQUEST', payload: false });
    }
  };
}

export function cancelRefillOrder (orderNumber: string) {
  return async function (dispatch: Dispatch): Promise<string> {
    try {
      dispatch({ type: 'REFILL_ORDER/CANCEL/REQUEST', payload: true });
      const refillOrderId = await refillOrderService.patchCancelRefillOrder(orderNumber);
      dispatch({
        type: 'REFILL_ORDER/CANCEL/SUCCESS',
        payload: { id: refillOrderId }
      });
      toasti18n.success("toast_refill_order_cancel_success");
      return refillOrderId;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/CANCEL/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_cancel_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/CANCEL/REQUEST', payload: false });
    }
  };
}

export function patchUndoRefillOrder (data: IPatchUndoRefillOrder) {
  return async function (dispatch: Dispatch): Promise<IRefillOrder> {
    try {
      dispatch({ type: 'REFILL_ORDER/PATCH_UNDO/REQUEST', payload: true });
      const refillOrder = await refillOrderService.patchUndoRefillOrder(data.orderNumber);
      dispatch({
        type: 'REFILL_ORDER/PATCH_UNDO/SUCCESS',
        payload: { [data.orderNumber]: refillOrder }
      });
      toasti18n.success("toast_refill_order_undo_success");
      return refillOrder;
    } catch (error) {
      dispatch({ type: 'REFILL_ORDER/PATCH_UNDO/ERROR', payload: serializeError(error) });
      toasti18n.error({ message: "toast_refill_order_undo_error" });
    } finally {
      dispatch({ type: 'REFILL_ORDER/PATCH_UNDO/REQUEST', payload: false });
    }
  };
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IconAndTextCell--TBulvIbNHu{display:flex;align-items:center}.IconContainer--vXFK2bOrYO{margin-right:4px;width:16px;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"IconAndTextCell": "IconAndTextCell--TBulvIbNHu",
	"IconContainer": "IconContainer--vXFK2bOrYO"
};
module.exports = exports;

import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { contractRoutes } from "./routes";

import ContractIndex from "./screens/ContractIndex";
import NewContract from "./screens/NewContract";
import ContractId from "./screens/ContractId";
import EditContract from "./screens/EditContract";
import AddLocationToContract from "./screens/AddLocationToContract";
import LocationFee from "./screens/LocationFee";
import EditLocationFee from "./screens/EditLocationFee";
import { ErrorBoundary } from "@sentry/react";
import { Fallback } from "components/Fallback";

export function Contract() {
  return (
    <div
      style={{
        margin: "-20px -35px",
        flex: "1 1 0",
        minHeight: 0,
      }}
    >
      <Switch>
        <Route path={contractRoutes.index()} exact>
          <ErrorBoundary fallback={Fallback} key="view-contracts">
            <ContractIndex />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.newContract()} exact>
          <ErrorBoundary fallback={Fallback} key="create-contract">
            <NewContract />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.contractId()} exact>
          <ErrorBoundary fallback={Fallback} key="view-contract">
            <ContractId />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.editContract()} exact>
          <ErrorBoundary fallback={Fallback} key="edit-contract">
            <EditContract />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.addLocationToContract()} exact>
          <ErrorBoundary fallback={Fallback} key="add-new-location">
            <AddLocationToContract />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.locationId()} exact>
          <ErrorBoundary fallback={Fallback} key="view-location-fee">
            <LocationFee />
          </ErrorBoundary>
        </Route>

        <Route path={contractRoutes.editLocation()} exact>
          <ErrorBoundary fallback={Fallback} key="edit-location-fee">
            <EditLocationFee />
          </ErrorBoundary>
        </Route>

        <Redirect to={contractRoutes.index()} />
      </Switch>
    </div>
  );
}

import * as React from "react";

export function LocationIdContainer({ children }: { children: React.ReactNode; }) {
  return (
    <div
      className="bg-white rounded-lg shadow-sm"
    >
      {children}
    </div>
  );
}

import * as React from 'react';
import { useState } from 'react';

import Button from 'common/components/button/Button';
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';

import { IMachineInventoryData } from 'machines/interfaces';

import * as styles from './EditQuantityModal.module.scss';
import { usePatchMachineInventory } from 'machines/hooks/usePatchMachineInventory';

interface IEditQuantityModalProps {
  data: IMachineInventoryData,
  onClose: () => void
}

function EditQuantityModal ({
  data,
  onClose
}: IEditQuantityModalProps): JSX.Element {
  const { isLoading: updateLoading, mutate: patchMachineInventory } = usePatchMachineInventory();

  const [ quantity, setQuantity ] = useState<number>(null);

  function handleClose (): void {
    onClose();
    setTimeout(() => {
      setQuantity(null);
    }, 100);
  }

  async function handleSubmit (): Promise<void> {
    patchMachineInventory(
      {
        machineId: data.machineId,
        machineInventories: [
          {
            id: data.id,
            value: quantity
          }
        ]
      },
      { onSuccess: handleClose }
    );
  }

  return (
    <Modal
      isOpen={!!data}
      onClose={handleClose}
    >
      <div className={styles.EditQuantityModal}>
        <div className={styles.title}>
          <Typography
            type='headline-6'
            translationKey='action_edit_quantity'
          />
          &nbsp;
          <Typography
            type='headline-6'
            text={data?.inventory ? `(${data.inventory})` : ''}
          />
        </div>

        <Input
          value={`${data?.value?.toLocaleString()} ${data?.uom ? data.uom : ''}`}
          label='label_current_quantity'
          renderStyle='none'
          className={styles.currentQuantity}
        />

        <Input
          value={quantity}
          onChange={setQuantity}
          label='action_edit_quantity_placeholder'
          type='number'
        />

        <div className={styles.actions}>
          <Button
            translationKey='action_cancel'
            onClick={handleClose}
            type='secondary'
          />
          <Button
            translationKey='action_refill'
            onClick={handleSubmit}
            type='primary'
            disabled={quantity === null}
            loading={updateLoading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default EditQuantityModal;

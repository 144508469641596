import * as React from "react";

interface IWarehouse051Props {
  color?: string;
  className?: string;
}

function Warehouse051({
  color = "white",
  className,
}: IWarehouse051Props): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_7360_25911)">
        <path
          d="M27.0001 31.9997H5.00008C3.71341 31.9997 2.66675 30.953 2.66675 29.6663V8.33301C2.66675 7.78101 3.11475 7.33301 3.66675 7.33301H28.3334C28.8854 7.33301 29.3334 7.78101 29.3334 8.33301V29.6663C29.3334 30.953 28.2867 31.9997 27.0001 31.9997ZM4.66675 9.33301V29.6663C4.66675 29.8503 4.81608 29.9997 5.00008 29.9997H27.0001C27.1841 29.9997 27.3334 29.8503 27.3334 29.6663V9.33301H4.66675Z"
          fill={color}
        />
        <path
          d="M31 9.33333H1.00001C0.673341 9.33333 0.368008 9.17467 0.181341 8.90667C-0.00665897 8.64 -0.050659 8.29733 0.0600077 7.99067L2.72667 0.657333C2.87067 0.262667 3.24667 0 3.66667 0H28.3333C28.7533 0 29.1293 0.262667 29.2733 0.658667L31.94 7.992C32.0507 8.29867 32.0067 8.64 31.8187 8.908C31.632 9.17467 31.3267 9.33333 31 9.33333V9.33333ZM2.42667 7.33333H29.5733L27.6333 2H4.36667L2.42667 7.33333Z"
          fill={color}
        />
        <path
          d="M25 32.0003H16C15.448 32.0003 15 31.5523 15 31.0003V23.667C15 23.115 15.448 22.667 16 22.667H25C25.552 22.667 26 23.115 26 23.667V31.0003C26 31.5523 25.552 32.0003 25 32.0003ZM17 30.0003H24V24.667H17V30.0003Z"
          fill={color}
        />
        <path
          d="M20.3334 24.667H11.6667C11.1147 24.667 10.6667 24.219 10.6667 23.667V15.667C10.6667 15.115 11.1147 14.667 11.6667 14.667H20.3334C20.8854 14.667 21.3334 15.115 21.3334 15.667V23.667C21.3334 24.219 20.8854 24.667 20.3334 24.667ZM12.6667 22.667H19.3334V16.667H12.6667V22.667Z"
          fill={color}
        />
        <path
          d="M16 32.0003H7C6.448 32.0003 6 31.5523 6 31.0003V23.667C6 23.115 6.448 22.667 7 22.667H16C16.552 22.667 17 23.115 17 23.667V31.0003C17 31.5523 16.552 32.0003 16 32.0003ZM8 30.0003H15V24.667H8V30.0003Z"
          fill={color}
        />
        <path
          d="M16 18.667C15.448 18.667 15 18.219 15 17.667V15.667C15 15.115 15.448 14.667 16 14.667C16.552 14.667 17 15.115 17 15.667V17.667C17 18.219 16.552 18.667 16 18.667Z"
          fill={color}
        />
        <path
          d="M11.6667 26.667C11.1147 26.667 10.6667 26.219 10.6667 25.667V23.667C10.6667 23.115 11.1147 22.667 11.6667 22.667C12.2187 22.667 12.6667 23.115 12.6667 23.667V25.667C12.6667 26.219 12.2187 26.667 11.6667 26.667Z"
          fill={color}
        />
        <path
          d="M20.3333 26.667C19.7813 26.667 19.3333 26.219 19.3333 25.667V23.667C19.3333 23.115 19.7813 22.667 20.3333 22.667C20.8853 22.667 21.3333 23.115 21.3333 23.667V25.667C21.3333 26.219 20.8853 26.667 20.3333 26.667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7360_25911">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Warehouse051;

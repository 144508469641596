import * as React from 'react';
import { locale } from 'moment';
import { useTranslation } from 'react-i18next';
import { localStorageLanguageKey } from 'common/i18n';

import withSuspense from 'common/hoc/withSuspense';

import Select from 'common/components/select/Select';

interface ILanguagePickerProps {
  className?: string
}

function LanguagePicker ({
  className
}: ILanguagePickerProps): JSX.Element {
  const { i18n } = useTranslation();

  const supportedLanguages = i18n.options.supportedLngs;
  const languageCodes = supportedLanguages && supportedLanguages.slice(0, supportedLanguages.length - 1);

  function handleLanguageChange (languageCode: string): void {
    i18n.changeLanguage(languageCode);
    locale(languageCode);
    localStorage.setItem(localStorageLanguageKey, languageCode);
  }

  return (
    <Select
      className={className}
      label='label_language'
      value={i18n.language}
      options={languageCodes.map(languageCode => {
        return {
          label: i18n.getFixedT(languageCode)('language'),
          value: languageCode
        };
      })}
      onSelect={handleLanguageChange}
    />
  );
}

export default withSuspense(LanguagePicker);

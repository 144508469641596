import * as React from 'react';
import { useState } from 'react';

import { useParams } from 'react-router-dom';

import Table from 'common/components/table/Table';
import Typography from 'common/components/typography/Typography';
import { IconCell, StringCell } from 'common/components/table/cells/Cells';
import { textAndIndicatorCell } from 'common/components/table/cells/textAndIndicatorCell';
import { MaintenanceRecordModal } from './MaintenanceRecordModal';
import { MaintainacePeriodModal } from './MaintainacePeriodModal';

import { ITableRow, mapDataToTable } from './mapDataToTable';

import { useGetMachineMaintenance } from 'machines/hooks/useGetMachineMaintenance';

import * as styles from './Maintenance.module.scss';
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
  useIsTechnician,
  useIsViewer,
} from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

function Maintenance(): JSX.Element {
  const cannotViewContent = useIsViewer() || useIsTechnician();
  const canEdit = useIsAdmin() || useIsAdminContract() || useIsClerk() || useIsOperator();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }

  const { machineId } = useParams<{ machineId: string }>();
  const { data, isLoading } = useGetMachineMaintenance(machineId);
  const [isSetDateModalOpen, setIsSetDateModalOpen] = useState<boolean>(false);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);
  const [selectedPartInfo, setSelectedPartInfo] = useState<{
    machineId: string;
    name: string;
    type: 'slot' | 'component';
  } | null>(null);

  const EditableColumns = [
    {
      dataKey: 'part',
      cellRenderer: StringCell,
      sortable: true,
      showMobile: true,
      columnFlex: 1,
      minWidth: 50,
      headerLabel: 'label_part',
    },
    {
      dataKey: 'maintenancePeriod',
      cellRenderer: StringCell,
      showMobile: true,
      columnFlex: 1,
      minWidth: 90,
      headerLabel: 'label_maintenance_period',
    },
    {
      cellRenderer: IconCell({
        icon: 'Pencil',
        onClick: (rowData: ITableRow) => {
          setIsSetDateModalOpen(true);
          setSelectedPartInfo({
            machineId: machineId,
            name: rowData.part,
            type: rowData.type,
          });
        },
      }),
      columnWidth: 24,
      showMobile: true,
    },
    {
      dataKey: 'lastMaintainDate',
      cellRenderer: StringCell,
      showMobile: true,
      columnFlex: 1,
      minWidth: 160,
      headerLabel: 'label_last_maintain_date',
    },
    {
      dataKey: 'nextMaintainDate',
      cellRenderer: textAndIndicatorCell({ indicatorType: 'circle' }),
      showMobile: true,
      columnFlex: 1,
      minWidth: 170,
      headerLabel: 'label_next_maintain_date',
    },
    {
      cellRenderer: IconCell({
        icon: 'Maintenance',
        onClick: (rowData: ITableRow) => {
          setIsRecordModalOpen(true);
          setSelectedPartInfo({
            machineId: machineId,
            name: rowData.part,
            type: rowData.type,
          });
        },
      }),
      columnWidth: 24,
      showMobile: true,
    },
  ];

  const nonEditableColumns = [
    {
      dataKey: 'part',
      cellRenderer: StringCell,
      sortable: true,
      showMobile: true,
      columnFlex: 1,
      minWidth: 50,
      headerLabel: 'label_part',
    },
    {
      dataKey: 'maintenancePeriod',
      cellRenderer: StringCell,
      showMobile: true,
      columnFlex: 1,
      minWidth: 90,
      headerLabel: 'label_maintenance_period',
    },
    {
      dataKey: 'lastMaintainDate',
      cellRenderer: StringCell,
      showMobile: true,
      columnFlex: 1,
      minWidth: 160,
      headerLabel: 'label_last_maintain_date',
    },
    {
      dataKey: 'nextMaintainDate',
      cellRenderer: textAndIndicatorCell({ indicatorType: 'circle' }),
      showMobile: true,
      columnFlex: 1,
      minWidth: 170,
      headerLabel: 'label_next_maintain_date',
    },
  ];

  return (
    <div>
      {isSetDateModalOpen && (
        <MaintainacePeriodModal
          partInfo={selectedPartInfo}
          onClose={() => {
            setIsSetDateModalOpen(false);
            setSelectedPartInfo(null);
          }}
        />
      )}
      {isRecordModalOpen && (
        <MaintenanceRecordModal
          partInfo={selectedPartInfo}
          onClose={() => {
            setIsRecordModalOpen(false);
            setSelectedPartInfo(null);
          }}
        />
      )}
      <header className={styles.Header}>
        <Typography type="headline-5" translationKey="menu_maintenance" />
      </header>
      <div>
        <Table
          data={mapDataToTable(data)}
          loading={isLoading}
          loadingRows={10}
          columns={canEdit ? EditableColumns : nonEditableColumns}
        />
      </div>
    </div>
  );
}

export { Maintenance };

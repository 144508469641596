import icons from 'common/components/icon/icons';
import { ArrayElement, UnionToObject } from 'types/utils';

type Icons = keyof (typeof icons)

export type APIDashboardResponse = Array<APILineChart | APIPieChart | APIHistogram | APIText | APIPolarChart | null>
export type APIDashboardType = ArrayElement<APIDashboardResponse>['type']

export const ChartTypes: UnionToObject<ArrayElement<APIDashboardResponse>['type']> = {
    line: "line",
    pie: "pie",
    histogram: 'histogram',
    text: 'text',
    polar: 'polar'
};

export interface APILineChart {
    type: 'line',
    icon: Icons
    title: string,
    fields: Array<APILineChartAggregates>
    chart: {
        type: 'line',
        data: {
            points: [number, number][],
            labels?: Array<string | (string | number)[]>, // labels.length === points.length
            max_x?: number,
            max_y?: number,
            min_x?: number,
            min_y?: number
        }
    }
}
interface APILineChartAggregates {
    type: 'currency' | 'numeric',
    header: string,
    value: string
}

export interface APIPieChart {
    type: 'pie',
    icon: Icons,
    title: string,
    data: {
        pieces: Array<APIPieChartSegment>
    }
}

export interface APIPieChartSegment {
    label: string,
    value: number,
}

export interface APIHistogram {
    type: 'histogram',
    icon: Icons,
    title: string,
    data: {
        bins: Array<[string, number]>;
        xAxis: {
            label: string
        }
    }
}
export interface APIText {
    type: 'text',
    icon: Icons,
    title: string,
    labelType: string;
    valueType: string;
    data: Array<{
        label: string;
        value: string;
    }>;
}

export interface APIPolarChart {
    type: "polar";
    icon: Icons;
    title: string;
    data: {
      pieces: Array<APIPolarChartSegment>;
    };
  }
  
export interface APIPolarChartSegment {
    label: string;
    value: { revenue: number; percent: number };
  }
import {
  GetMaintenanceBoardSugarErrorDocument,
  GetMaintenanceBoardSugarErrorQuery,
  GetMaintenanceBoardSugarErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getSugarError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardSugarErrorQuery,
    GetMaintenanceBoardSugarErrorQueryVariables
  >(GetMaintenanceBoardSugarErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

import * as React from "react";
import { FormProps } from "./FormView";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { AbnormalReturnQuantityNotifier } from "../components/ReturnLessThanExpected";
import { cs, stringifyToAmountInPackageWithRemainder } from "../utils";
import { InventoryCell } from "../components/InventoryCell";

import * as styles from "./AdjustmentModal.module.scss";
import * as colors from "common/styles/colors.scss";
import { useTranslation } from "react-i18next";

export function SpiralInventoryListHeader() {
  const { t } = useTranslation();
  return (
    <div className={styles.TableHeader}>
      <div className={styles.InventoryCodeColumn}>
        <p className="text-caption text-on-surface-medium">{t("label_inventory_code")}</p>
      </div>
      <div className={styles.InventoryColumn}>
        <p className="text-caption text-on-surface-medium">{t("label_inventory")}</p>
      </div>
      <div className={styles.PackedBagColumn}>
        <p className="text-caption text-on-surface-medium">
          {t("label_packed")}
          ({t("label_unit_pieces")})
        </p>
      </div>
      <div className={styles.FilledColumn}>
        <p className="text-caption text-on-surface-medium">
          {t("label_filled")}
          ({t("label_unit_pieces")})
        </p>
      </div>
      <div className={styles.ExpectedReturnColumn}>
        <p className="text-caption text-on-surface-medium">{t("label_expected_return")}</p>
      </div>
      <div className={styles.ActualReturnColumn}>
        <p className="text-caption text-on-surface-medium">
          {t("label_actual_return")}
          ({t("label_unit_pieces")})
        </p>
      </div>
    </div>
  );
}

export function SpiralInventoryListView({
  getPackageActualReturnBag,
  onPackageActualReturnBagChange,
  isPackageActualReturnAbnormal,
  packages,
}: Omit<
  FormProps,
  "getPackageActualReturnUnit" | "onPackageActualReturnUnitChange"
>) {
  return (
    <div>
      {packages.map((p) => {
        const abnormalityStatus = isPackageActualReturnAbnormal(p);
        return (
          <div
            key={p.packageInfo.packageSKU}
            className={styles.TableRow}
            style={{
              backgroundColor: abnormalityStatus.exceededThreshold
                ? colors.warningOverlay
                : undefined,
            }}
          >
            <div
              className={cs(
                styles.InventoryCodeColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" color="onSurfaceHigh">
                {p.packageInfo.packageSKU}
              </Typography>
            </div>
            <div
              className={cs(
                styles.InventoryColumn,
                styles.CellContentAlignment
              )}
            >
              <InventoryCell productPackage={p} />
            </div>
            <div
              className={cs(
                styles.PackedBagColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productPackedInPackages}
              </Typography>
            </div>

            <div
              className={cs(styles.FilledColumn, styles.CellContentAlignment)}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {p.refillInfo.productFilledInProductUOMInPackage}{" "}
                {p.packageInfo.productUOMInPackage}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ExpectedReturnColumn,
                styles.CellContentAlignment
              )}
            >
              <Typography type="body-1" translate color="onSurfaceMedium">
                {stringifyToAmountInPackageWithRemainder({
                  p,
                  value: p.refillInfo.expectedReturnInProductUOMInPackage,
                  oldQuantity:
                    p.refillInfo.totalOldQuantityInProductUOMInPackage,
                })}
              </Typography>
            </div>
            <div
              className={cs(
                styles.ActualReturnColumn,
                styles.CellContentAlignment
              )}
              style={{ position: "relative" }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1px",
                      alignItems: " center",
                    }}
                  >
                    <div
                      style={{
                        width: "150px",
                        borderRadius: "4px",
                      }}
                      className={cs(
                        styles.InputWhiteBgOnFocus,
                        getPackageActualReturnBag(p) === ""
                          ? styles.InputEmpty
                          : styles.InputFilled
                      )}
                    >
                      <TextField
                        value={getPackageActualReturnBag(p)}
                        onChange={(e) =>
                          onPackageActualReturnBagChange(p, e.target.value)
                        }
                        required
                        label={"label_quantity_short"}
                      />
                    </div>

                    <div
                      style={{
                        width: "30px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        type="body-1"
                        translate
                        color="onSurfaceMedium"
                      >
                        label_bag
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ position: "absolute", bottom: -1 }}>
                {abnormalityStatus.exceededThreshold ? (
                  <AbnormalReturnQuantityNotifier
                    abnormalStatus={abnormalityStatus.abnormalStatus}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

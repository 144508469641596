import * as React from "react";
import Icon from "common/components/icon/Icon";
import * as styles from "./SortIcon.module.scss";

interface SortIconProps {
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
}

export function SortIcon({
  canSort,
  isSorted,
  isSortedDesc,
}: SortIconProps): JSX.Element {
  return (
    <>
      {canSort ? (
        isSorted ? (
          isSortedDesc ? (
            <Icon
              name="Chevron"
              color="onSurfaceHigh"
              className={`w-6 flex-shrink-0" ${styles.Chevron} ${styles.Up}`}
            />
          ) : (
            <Icon
              name="Chevron"
              color="onSurfaceHigh"
              className={`w-6 flex-shrink-0" ${styles.Chevron} ${styles.Down}`}
            />
          )
        ) : (
          <Icon className="w-6 flex-shrink-0" name="SortChevrons" color="onSurfaceDisabled" />
        )
      ) : (
        ""
      )}
    </>
  );
}
import { Country, getUserCountry } from "./user-role";

type Locale = "th-TH" | "ms-MY" | "en-AU" | "en-US" | "en-SG" | "en-AE";

type CurrencyConfig = {
  MULTIPLIER: number;
  MINIMUM_FRACTION_DIGITS: number | undefined;
  LOCALE: Locale;
  CURRENCY: string;
};

const CURRENCY_CONSTANTS: Record<Country | "DEFAULT", CurrencyConfig> = {
  DEFAULT: {
    MULTIPLIER: 1,
    MINIMUM_FRACTION_DIGITS: undefined,
    LOCALE: "th-TH",
    CURRENCY: "THB",
  },
  SINGAPORE: {
    MULTIPLIER: 1 / 100,
    MINIMUM_FRACTION_DIGITS: 2,
    LOCALE: "en-SG",
    CURRENCY: "SGD",
  },
  THAILAND: {
    MULTIPLIER: 1,
    MINIMUM_FRACTION_DIGITS: undefined,
    LOCALE: "th-TH",
    CURRENCY: "THB",
  },
  MALAYSIA: {
    MULTIPLIER: 1 / 100,
    MINIMUM_FRACTION_DIGITS: 2,
    LOCALE: "ms-MY",
    CURRENCY: "MYR",
  },
  AUSTRALIA: {
    MULTIPLIER: 1 / 100,
    MINIMUM_FRACTION_DIGITS: 2,
    LOCALE: "en-AU",
    CURRENCY: "AUD",
  },
  UNITED_ARAB_EMIRATES: {
    MULTIPLIER: 1 / 100,
    MINIMUM_FRACTION_DIGITS: 2,
    LOCALE: "en-AE",
    CURRENCY: "AED",
  },
} as const;

function getUsersCountryMultiplier() {
  return CURRENCY_CONSTANTS[getUserCountry() ?? "DEFAULT"].MULTIPLIER;
}
function getUsersCountryCurrency() {
  return CURRENCY_CONSTANTS[getUserCountry() ?? "DEFAULT"].CURRENCY;
}
function getUsersCountryLocale() {
  return CURRENCY_CONSTANTS[getUserCountry() ?? "DEFAULT"].LOCALE;
}
function getUsersCountryMinimumFractionDigits() {
  return CURRENCY_CONSTANTS[getUserCountry() ?? "DEFAULT"]
    .MINIMUM_FRACTION_DIGITS;
}

export function formatCurrencyWithoutSymbol(arg: FormatCurrencyArg) {
  return formatCurrency(arg)?.replace(getCurrencySymbol(), "").trim();
}

export function adjustCurrencyValue(value: number) {
  return value * getUsersCountryMultiplier();
}

type FormatCurrencyArg = Pick<
  FormatCurrencyOptions,
  "minimumFractionDigits"
> & { input: SanitizeInputArg };

export function formatCurrency({
  input,
  minimumFractionDigits,
}: FormatCurrencyArg): string | undefined | null {
  try {
    const value = sanitizeInput(input);
    const adjustedValue = adjustCurrencyValue(value);
    return _formatCurrency({
      value: adjustedValue,
      minimumFractionDigits:
        getUsersCountryMinimumFractionDigits() ?? minimumFractionDigits,
      locale: getUsersCountryLocale(),
    });
  } catch (e) {
    return input as string | undefined | null;
  }
}

export function hasCurrencySymbol(s: string) {
  return (
    s.includes("$") ||
    s.includes("฿") ||
    s.includes("RM") ||
    s.includes("A$") ||
    s.includes("AU$") ||
    s.includes("AUD") ||
    s.includes("AED") ||
    s.includes("د.إ")
  );
}

type SanitizeInputArg = number | string | undefined | null;
function sanitizeInput(input: SanitizeInputArg): number {
  if (input === undefined || input === null) {
    throw new Error("Input is undefined or null");
  }
  let value: number;
  if (typeof input === "number") {
    value = input;
  } else {
    const sanitizedString = input
      .replace(/,/g, "")
      .replace(/฿/g, "")
      .replace(/RM/g, "")
      .replace(/A\$|AU\$/g, "")
      .replace(/AUD/g, "")
      .replace(/\$/g, "")
      .replace(/AED/g, "")
      .replace(/د\.إ/g, "")
      .replace(/\u200E/g, "");

    value = parseFloat(sanitizedString);
    if (isNaN(value)) {
      throw new Error("Error parsing string");
    }
  }

  return value;
}
interface FormatCurrencyOptions {
  locale: Locale;
  value: number;
  minimumFractionDigits?: number;
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat().format(number);
}

function _formatCurrency({
  value,
  minimumFractionDigits = 2,
  locale,
}: FormatCurrencyOptions): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: getUsersCountryCurrency(),
    minimumFractionDigits: minimumFractionDigits,
  });
  const formattedValue = formatter.format(value);

  return formattedValue;
}

export function getCurrencySymbol() {
  return _getCurrencySymbol(getUsersCountryLocale());
}
function _getCurrencySymbol(
  locale: Locale | undefined | null
): string | undefined {
  if (locale === undefined || locale === null) {
    return undefined;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: getUsersCountryCurrency(),
  });
  const parts = formatter.formatToParts(0);
  const currencySymbolPart = parts.find((part) => part.type === "currency");

  if (currencySymbolPart) {
    return currencySymbolPart.value;
  }

  return undefined;
}

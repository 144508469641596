/**
 * Generates an array containing all the pages selectable by the user.
 * Returns an a page range array with <= 5 elements. The page range will be in the interval 1 <= page <= totalPages. The function will attempt to place the currentPage in the center of the array if possible.
 * @param currentPage - The current page the user is on
 * @param totalPages - The total number of pages
 *
 * Examples:
 * generatePageRange(3,10);
 *  => [1,2,3,4,5]
 * generatePageRange(9,10);
 * => [6,7,8,9,10]
 * generatePageRange(3,3);
 * => [1,2,3]
 * generatePageRange(1,6);
 * => [1,2,3,4,5]
 */
export function generatePageRange (currentPage: number, totalPages: number | undefined) {
  if(totalPages === undefined){
    return [currentPage];
  }
  let pageCenter = currentPage;
  if (currentPage <= 2) {
    pageCenter = 3;
  } else if (totalPages - currentPage < 2) {
    pageCenter = totalPages - 2;
  }
  return [
    pageCenter - 2,
    pageCenter - 1,
    pageCenter,
    pageCenter + 1,
    pageCenter + 2
  ].filter(page => page >= 1 && page <= totalPages);
}

import * as React from "react";

import { Modal } from "@mui/material";
import Button from "components/Button";
import Typography from "components/Typography";
import { useBatchDeleteRefillOrderCandidateMutation } from 'gql/generated';
import { toasti18n } from 'utils/toast';
import { useQueryClient } from 'react-query';

export function RemoveCandidateConfirmationModal({
  candidateIdToRemove: candidateIdToDelete,
  onRemoved,
  onClose,
}: {
  candidateIdToRemove: string
  onRemoved: () => void;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();

  const { mutate: deleteCandidate, isLoading } = 
  useBatchDeleteRefillOrderCandidateMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      toasti18n.success("toast_remove_candidate_machine_successful");
      onRemoved();
      queryClient.invalidateQueries(["GetOperationStatusCounts"]);
      queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
    }
  });

  function handleConfirmButtonClick(): void {
    deleteCandidate({
      batchDeleteRefillOrderCandidateId: candidateIdToDelete
    });
  }

  return (
    <Modal open={true} onClose={onClose}>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            pointerEvents: "auto",
            padding: "25px",
            minWidth: "340px",
            borderRadius: "3px",
          }}
        >
          <div style={{ marginTop: "-10px" }}>
            <Typography type="headline-6" translate>
              label_remove_candidate_machine_confirmation_modal_title
            </Typography>
          </div>

          <div>
            <Typography type="body-2" translate>
              label_remove_candidate_machine_confirmation_modal_body
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "18px",
            }}
          >
            <Button type="secondary" onClick={onClose} disabled={isLoading}>
              action_cancel
            </Button>
            <Button type="status" onClick={handleConfirmButtonClick} loading={isLoading}>
              action_delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

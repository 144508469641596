import * as React from "react";
import { ErrorBoardContext } from "../providers/ErrorBoardProvider";


export function useErrorBoard() {
  const context = React.useContext(ErrorBoardContext);
  if (context === undefined) {
    throw new Error("useErrorBoard must be used within a ErrorBoardProvider");
  }
  return context;
}

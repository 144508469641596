import * as React from "react";
import { useState, useRef } from "react";
import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import { useClickAway } from "react-use";
import * as styles from './EllipsisDropDownCell.module.scss';

interface IEllipsisDropDownCellProps {
  options:
    | Array<DropDownOptions>
    | ((rowData: object) => Array<DropDownOptions>);
}

interface DropDownOptions {
  translationKey: string;
  onClick: (rowData: object) => void;
}

function EllipsisDropDownCell({ options }: IEllipsisDropDownCellProps) {
  function rowDataAcceptor(rowData: object) {
    function Component(rowData: object) {
      const dynamicOptions =
        typeof options === "function" ? options(rowData) : options;

      const [isOpen, setIsOpen] = useState<boolean>(false);

      const ref = useRef(null);
      useClickAway(ref, () => setIsOpen(false));

      return (
        <div ref={ref} className={styles.EllipsisDropDownCell}>
          {dynamicOptions.length !== 0 && (
            <div onClick={() => setIsOpen(!isOpen)} className={styles.SVGContainer}>
              <Icon color="primary500" name="Ellipsis" />
            </div>
          )}
          {isOpen && (
            <div className={styles.DropDownContainer}>
              {dynamicOptions.map(option => (
                <div
                  key={option.translationKey}
                  onClick={() => {
                    option.onClick(rowData);
                    setIsOpen(false);
                  }}
                >
                  <Typography
                    type="body-2"
                    translationKey={option.translationKey}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    return <Component {...rowData} />;
  }

  return rowDataAcceptor;
}

export { EllipsisDropDownCell };

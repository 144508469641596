import * as React from 'react';
import { Scatter } from 'react-chartjs-2';
import * as styles from './SparkLine.module.scss';
import * as SCSScolors from 'common/styles/colors.scss';

interface SparkLineProps {
  className?: string;
  data: [number, number][];
  color: 'success' | 'error' | 'warning'
}

function SparkLine ({ data, color, className }: SparkLineProps): JSX.Element {
  return (
    <div className={[ styles.SparkLine, className ].join(' ')}>
      <Scatter
        data={{
          datasets: [
            {
              showLine: true,
              data: data.map(([ x, y ]) => ({ x, y: y / 1000 })), // Normalization
              tension: 0.4,
              borderColor: SCSScolors[color],
              borderWidth: 1,
              pointRadius: 0
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            }
          },
          scales: {
            xAxis: {
              display: false,
              min: Math.min(...data.map(([x]) => x)),
              max: Math.max(...data.map(([x]) => x))
            },
            yAxis: {
              display: false,
              min: Math.min(...data.map(([ _, y ]) => y / 1000)) - 1,
              max: Math.max(...data.map(([ _, y ]) => y / 1000)) + 1
            }
          }
        }
        }
      />
    </div>
  );
}

export { SparkLine };

import i18n from "common/i18n";
import {
  GetLocationByIdQuery,
  LocationKind,
  LocationType
} from "gql/generated";

export type Location = Store | Factory | SingleMachineWithContract;

export type Store = {
  kind: LocationKind.Store;
  locationInfo: LocationInfo;
  machineIds: Array<string> | null;
  operation: LocationOperation;
};

export type Factory = {
  kind: LocationKind.Factory;
  locationInfo: LocationInfo;
  machineIds: Array<string> | null;
};

export type SingleMachineWithContract = {
  kind: LocationKind.Location;
  locationInfo: LocationInfo;
  machineId: string;
  operation: LocationOperation;
  contracts: Contracts;
  additionalContractInformation: AdditionalContractInformation;
  rentFee: GetLocationByIdQuery["locationAggregate"]["rentFee"]
  electricFee: GetLocationByIdQuery["locationAggregate"]["electricFee"]
};

export type AdditionalContractInformation = {
  sales: string;
  projectType: {
    id: string;
    name: string;
  }
};

export function getLocationTypeTranslation(type: LocationType) {
  return i18n.t(`label_${type.split(" ").join("_").toLowerCase()}`);
}

export type LocationInfo = {
  type: LocationType;
  id: string;
  friendlyId: string;
  name: string;
  organization: {
    id: string;
    name: string;
  };
  googleMapLink: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  address: {
    street: string;
    province: string;
  };
};

export type Contracts = {
  active: ContractArray;
  inactive: ContractArray;
  pastContract: ContractArray;
};

export type ContractArray = Array<Contract>;

export type Contract = {
  id: string;
  friendlyId: string;
};

export type LocationOperation = {
  refillZone: {
    id: string;
    friendlyId: string;
  };
  serviceZone: {
    id: string;
    friendlyId: string;
  };
  warehouse: {
    id: string;
    name: string;
  };
  refillOperationNote: string;
};

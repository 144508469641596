import {
  DashboardCardProps
} from '../organisms/DashboardCard';

import { APIDashboardResponse, ChartTypes } from 'dashboard/interface';

import { mapDataToLineChartCard } from './mapDataToLineChartCard';
import { mapDataToPieChartCard } from './mapDataToPieChartCard';
import { mapDataToHistogramCard } from './mapDataToHistogramCard';
import { mapDataToTextCard } from './mapDataToTextCard';
import { mapDataToPolarChartCard } from './mapDataToPolarChartCard';

// Omit is not distributive by default - https://stackoverflow.com/a/67794430/16050486
type DistributiveOmit<T, K extends PropertyKey> = T extends any
  ? Omit<T, K>
  : never;

export function mapDashboardDataToCards (
  data: APIDashboardResponse
): Array<DistributiveOmit<DashboardCardProps, 'className'>> {
  return data
    .filter(dataPoint => dataPoint !== null && Object.values(ChartTypes).includes(dataPoint.type))
    .map(dataPoint => {
      switch (dataPoint.type) {
        case 'line':
          return mapDataToLineChartCard(dataPoint);
        case 'pie':
          return mapDataToPieChartCard(dataPoint);
        case 'histogram':
          return mapDataToHistogramCard(dataPoint);
        case 'text':
          return mapDataToTextCard(dataPoint);
        case 'polar':
          return mapDataToPolarChartCard(dataPoint);
        default:
          throw Error('Chart with unknown type');
      }
    }
  );
}

import { flatten, orderBy } from 'lodash';

import { IRootState } from 'common/store/reducers/rootReducer';
import {
  IMachineData,
  IMachineInventoryData,
  IMachineInventoryTemplate,
  IMachineStatusData,
  IMachineTransactionData
} from 'machines/interfaces';

export function selectMachine (machineId: string) {
  return function (state: IRootState): IMachineData {
    return state.machines[machineId];
  };
}

export function selectMachines (state: IRootState): IMachineData[] {
  return Object.values(state.machines);
}

export function selectMachineInventory (machineId: string) {
  return function (state: IRootState): IMachineInventoryData[] {
    const inventory = Object.values(state.machineInventory[machineId] || {});
    return orderBy(inventory || [], ['inventory'], ['asc']);
  };
}

export function selectMachineStatus (machineId: string) {
  return function (state: IRootState): IMachineStatusData[] {
    const statuses = Object.values(state.machineStatus[machineId] || {});
    return orderBy(statuses || [], ['time'], ['desc']);
  };
}

export function selectMachineTransactions (machineId: string) {
  return function (state: IRootState): IMachineTransactionData[] {
    const transactions = Object.values(state.machineTransaction[machineId] || {});
    return orderBy(transactions || [], ['time'], ['desc']);
  };
}

export function selectAllTransactions (state: IRootState): IMachineTransactionData[] {
  const toFlatten = Object.values(state.machineTransaction).map(machineTransactions => {
    return Object.values(machineTransactions);
  });
  const transactions = flatten(toFlatten);
  return orderBy(transactions, ['time'], ['desc']);
};

export function selectMachineInventoryTemplates (state: IRootState): Array<Partial<IMachineInventoryTemplate>> {
  return Object.values(state.machineInventoryTemplates);
}

export function selectMachineInventoryTemplate (templateId: string) {
  return function (state: IRootState): Partial<IMachineInventoryTemplate> {
    return state.machineInventoryTemplates[templateId];
  };
}

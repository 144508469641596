// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectButton--3L2zr5itZU{position:relative;display:flex;flex-direction:column}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT{position:absolute;box-shadow:0px 2px 5px 1px rgba(0,0,0,.16);top:40px;right:0px;display:none;flex-direction:column;background-color:#fff;min-width:100%;z-index:1;border:1px solid rgba(0,0,0,.12);border-radius:4px}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT.open--6xck4iUWhY{display:flex}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT .option--17jXByxfUp{display:flex;align-items:center;padding:14px 21px}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT .option--17jXByxfUp.selected--1iyG3ZgDOk{background-color:#e0e0e0}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT .option--17jXByxfUp.disabled--jdxcw0FrI-{color:#b0b0b0}.SelectButton--3L2zr5itZU .dropdown--2YVMKwNjQT .option--17jXByxfUp:hover{background-color:#ebebeb;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "disabled--jdxcw0FrI-",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"SelectButton": "SelectButton--3L2zr5itZU",
	"dropdown": "dropdown--2YVMKwNjQT",
	"open": "open--6xck4iUWhY",
	"option": "option--17jXByxfUp",
	"selected": "selected--1iyG3ZgDOk"
};
module.exports = exports;

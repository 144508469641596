// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpdateLimitModal--X3KmVjF9fn{flex-direction:column}.UpdateLimitModal--X3KmVjF9fn .header--2EkkwBmaWD{margin-bottom:25px}.UpdateLimitModal--X3KmVjF9fn .filePicker--1BSJh3EIPE{margin-bottom:25px}.UpdateLimitModal--X3KmVjF9fn .buttonGroup--1hJV7k7Sg-{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"UpdateLimitModal": "UpdateLimitModal--X3KmVjF9fn",
	"header": "header--2EkkwBmaWD",
	"filePicker": "filePicker--1BSJh3EIPE",
	"buttonGroup": "buttonGroup--1hJV7k7Sg-"
};
module.exports = exports;

import * as React from "react";
import { Title } from "../../components/Title";
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";

interface InfoPanelProps {
  title: string;
  body: React.ReactNode;
  className?: string;
  open?: boolean;
}

export function InfoPanel ({ title, body, className, open = true }: InfoPanelProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(open);
  return (
    <div className={clsx("p-4 flex gap-4 bg-white rounded-lg shadow-sm items-start", className)}>
      <div onClick={() => setIsOpen(state => !state)} className="min-w-[150px] cursor-pointer flex gap-2 items-center">
        <Title>{t(title)}</Title>
        <Icon name="Chevron" color="onSurfaceMedium" className={clsx("w-4 h-4 transition-all duration-100", isOpen ? "rotate-90": "-rotate-90")} />
      </div>
      <div className={clsx("flex-1 space-y-4 overflow-hidden", isOpen ? "max-h-auto": "max-h-0")}>
        {body}
      </div>
    </div>
  );
};

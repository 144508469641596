import * as React from "react";
import { View } from "@react-pdf/renderer";

export function Checkbox(): JSX.Element {
  return (
    <View
      style={{
        width: 14,
        height: 14,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "#212121",
        borderRadius: 2
      }}
    ></View>
  );
}

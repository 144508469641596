import * as React from "react";
import MuiModal from "components/Modal/MuiModal";
import Button from "components/Button/Button";
import { CoordinatesType } from "./mapDataToTable";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type LocationCellModalProps = {
  machineId: string;
  locationId: string;
  locationName: string;
  coordinates: CoordinatesType,
}

export function LocationCellModal({ coordinates, locationId, locationName, machineId }: LocationCellModalProps) {
  const { t } = useTranslation("machine", {
    keyPrefix: "overview_page"
  });
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button type="secondary" size="small" iconName="Share" className="!min-w-0 !w-10" onClick={() => setIsOpen(true)} />
      <MuiModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        minWidth="400px"
        padding="16px 24px"
      >
        <div className="space-y-4 font-kanit">
          <h3 className="text-on-primary text-subtitle1">Share Location</h3>
          <div className="space-y-1">
            <p className="text-caption text-on-surface-medium">Machine Id</p>
            <p className="text-caption text-on-surface-high">
              <Link className="hover:text-primary hover:underline" target="_blank" to={`/location/${locationId}`}>{machineId}</Link>
            </p>
          </div>
          <div className="space-y-1">
            <p className="text-caption text-on-surface-medium">Location Name</p>
            <p className="text-caption text-on-surface-high">
              <Link className="hover:text-primary hover:underline" target="_blank" to={`/location/${locationId}`}>{locationName}</Link>
            </p>
          </div>
          <div className="space-y-1">
            <p className="text-caption text-on-surface-medium">GPS Location</p>
            <CopyInput input={generateGoogleMapLink(coordinates)} toastMessage={t("copy_google_map_link_success_toast_label")} />
            <CopyInput input={`${coordinates.latitude}, ${coordinates.longitude}`} toastMessage={t("copy_lng_lat_success_toast_label")} />
          </div>
        </div>
      </MuiModal>
    </>
  );
}


function  generateGoogleMapLink (coordinates: CoordinatesType) {
  return `https://www.google.com/maps/search/?api=1&query=${coordinates.latitude},${coordinates.longitude}`;
}


function CopyInput({ input, toastMessage }: { input: string, toastMessage?: string }) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleCopy = async () => {
    try {
      if (inputRef.current) {
        inputRef.current.select();
        await navigator.clipboard.writeText(input);
        toastMessage && toast.success(toastMessage);
      }
    } catch (err) {
      throw new Error('Failed to copy text:' + err);
    }
  };

  return (
    <div className="relative flex gap-2">
      <input
        ref={inputRef}
        className="!font-kanit w-full border-b-primary border-solid border-b text-primary font-medium"
        value={input}
        readOnly
      />
      <Button type="secondary" size="small" iconName="Copy" className="!min-w-0 !w-10" onClick={handleCopy} />
    </div>
  );
}

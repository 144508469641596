import {
  GetMaintenanceBoardNoTransactionTodayDocument,
  GetMaintenanceBoardNoTransactionTodayQuery,
  GetMaintenanceBoardNoTransactionTodayQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getNoTransactionToday({
  serviceZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransactionTodayQuery,
    GetMaintenanceBoardNoTransactionTodayQueryVariables
  >(GetMaintenanceBoardNoTransactionTodayDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}

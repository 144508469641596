import TextButton from "common/components/textbutton/TextButton";
import * as React from "react";
import {
  Modal,
  Title,
  modalBorderStyle,
  modalSpacing,
} from "containers/warehousing/components/ReusableModal";
import { useImmer } from "use-immer";
import Select from "components/Select/Select";
import {
  MOVE_INVENTORY_REASONS,
  MoveInventoryReason,
  getMoveInventoryReasonTranslation,
  useMoveInventoryFromWarehouse,
  useMoveInventoryToWarehouse,
} from "./use-get-lot-transfer";
import TextField from "components/TextField/TextField";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { useGetWarehouse } from "../WarehouseOverview/use-get-warehouse";
import Typography from "components/Typography/Typography";
import Button from "components/Button/Button";
import Icon from "common/components/icon/Icon";
import { useGetWarehouseProductsById } from "./use-get-products";
import { useGetProductLotsByWarehouseId } from "../WarehouseIdWithProductId/use-get-product-lots";
import { toasti18n } from "utils/toast";
import { useIsAdmin, useIsAdminContract, useIsClerk } from "utils/user-role";

function useCanMoveInventory() {
  const canMoveInventory = useIsAdmin() || useIsClerk() || useIsAdminContract();
  return canMoveInventory;
}

export function MoveInventoryModalButton({
  warehouseId,
}: {
  warehouseId: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const canMoveInventory = useCanMoveInventory();
  if (!canMoveInventory) {
    return null;
  }

  return (
    <>
      <TextButton
        icon="MoveInventory"
        translationKey="label_move_inventory"
        onClick={() => setIsOpen(true)}
      />

      {isOpen ? (
        <MoveInventoryModal
          onClose={() => setIsOpen(false)}
          warehouseId={warehouseId}
        />
      ) : null}
    </>
  );
}

function createNewFormRow(): Form["selectedProducts"][0] {
  return {
    uniqueId: Date.now(),
    lotId: "",
    lot: null,
    productId: "",
    product: null,
    quantity: "",
    error: null,
  };
}

type Form = {
  selectedProducts: Array<{
    lotId: string | null;
    lot: {
      no: string;
    } | null;
    quantity: string | null;
    productId: string;
    product: {
      name: string;
      img: string;
      sku: string;
      totalQuantity: number;
    } | null;
    uniqueId: number;
    error: null | Error;
  }>;
  reason: "" | "move_to_warehouse" | MoveInventoryReason;
  note: string;
  toWarehouseId: string;
};

export function MoveInventoryModal(props: {
  onClose: () => void;
  warehouseId: string;
}) {
  const [step, setStep] = React.useState<"FORM" | "REVIEW">("FORM");

  const [form, setForm] = useImmer<Form>({
    selectedProducts: [createNewFormRow()],
    reason: "",
    note: "",
    toWarehouseId: "",
  });

  function handleInsertInventoryRow() {
    setForm((draft) => {
      draft.selectedProducts.push(createNewFormRow());
    });
  }
  function handleRemoveInventoryRow(index: number) {
    setForm((draft) => {
      draft.selectedProducts.splice(index, 1);
    });
  }

  const useGetWarehouseResult = useGetWarehouse();
  const useGetWarehouseProductsResult = useGetWarehouseProductsById(
    props.warehouseId
  );

  const selectedLotIds = form.selectedProducts.map((product) => product.lotId);

  const isFormDisabled = React.useMemo(() => {
    return (
      form.reason === "" ||
      (form.reason === "move_to_warehouse" && form.toWarehouseId === "") ||
      form.note === "" ||
      form.selectedProducts.some(
        (product) =>
          product.lotId === "" ||
          product.productId === "" ||
          product.quantity === "" ||
          isNaN(Number(product.quantity)) ||
          product.error !== null
      )
    );
  }, [form]);

  function onSuccess() {
    toasti18n.success();
    props.onClose();
  }
  const useMoveInventoryFromWarehouseResult = useMoveInventoryFromWarehouse({
    onSuccess,
  });
  const useMoveInventoryToWarehouseResult = useMoveInventoryToWarehouse({
    onSuccess,
  });
  const isLoading =
    useMoveInventoryFromWarehouseResult.isLoading ||
    useMoveInventoryToWarehouseResult.isLoading;

  function handleSubmission() {
    if (form.reason === "move_to_warehouse") {
      useMoveInventoryToWarehouseResult.mutate({
        fromWarehouseId: props.warehouseId,
        notes: form.note,
        toWarehouseId: form.toWarehouseId,
        productsToTransfer: form.selectedProducts.map((product) => ({
          lotId: product.lotId,
          quantity: Number(product.quantity),
        })),
      });
    } else {
      useMoveInventoryFromWarehouseResult.mutate({
        justification: form.reason as MoveInventoryReason,
        warehouseId: props.warehouseId,
        payload: form.selectedProducts.map((product) => ({
          lotId: product.lotId,
          quantity: Number(product.quantity),
        })),
      });
    }
  }

  return (
    <Modal
      onClose={props.onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "712px",
        heightStyles: {
          flex: "1 1 0",
          maxHeight: "760px",
          minHeight: "600px",
        },
        alignment: "start",
      }}
    >
      <Title>label_move_inventory</Title>
      {step === "REVIEW" ? <SummaryView data={form} /> : null}
      {step === "FORM" ? (
        <>
          <div style={{ marginTop: "21px" }}>
            <Select
              required
              label="label_reason"
              id="type"
              value={form.reason}
              onChange={(e) =>
                setForm((draft) => {
                  draft.reason = e.target.value;
                })
              }
              options={[
                {
                  label: "label_move_to_warehouse",
                  value: "move_to_warehouse",
                },
                ...Object.values(MOVE_INVENTORY_REASONS).map((reason) => ({
                  value: reason,
                  label: getMoveInventoryReasonTranslation(reason),
                })),
              ]}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <TextField
              required
              label="label_inventory_note"
              value={form.note}
              onChange={(e) =>
                setForm((draft) => {
                  draft.note = e.target.value;
                })
              }
            />
          </div>

          {form.reason === "move_to_warehouse" ? (
            <div style={{ marginTop: "20px" }}>
              <Autocomplete
                label="label_warehouse"
                id="warehouse"
                required
                value={form.toWarehouseId}
                onChange={(newValue) => {
                  setForm((draft) => {
                    draft.toWarehouseId = newValue;
                  });
                }}
                options={
                  useGetWarehouseResult.isLoading
                    ? []
                    : [
                        ...useGetWarehouseResult.data
                          .filter(
                            (warehouse) =>
                              warehouse.warehouseId !== props.warehouseId
                          )
                          .map((warehouse) => {
                            return {
                              label: warehouse.name,
                              value: warehouse.warehouseId,
                            };
                          }),
                      ]
                }
              />
            </div>
          ) : null}
          <div style={{ height: "16px" }}></div>

          <Typography type="subtitle-1" translate>
            label_inventory_list
          </Typography>
          <div
            style={{
              borderBottom: modalBorderStyle,
              marginLeft: `-${modalSpacing.horizontalSpace}`,
              marginRight: `-${modalSpacing.horizontalSpace}`,
            }}
          ></div>

          <div
            style={{
              flex: "1 1 0",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "21px",
              paddingTop: "15px",
              paddingBottom: "5px",
              marginLeft: `-${modalSpacing.horizontalSpace}`,
              marginRight: `-${modalSpacing.horizontalSpace}`,
              paddingLeft: modalSpacing.horizontalSpace,
              paddingRight: modalSpacing.horizontalSpace,
            }}
          >
            {form.selectedProducts.map((product, i) => (
              <div
                key={product.uniqueId}
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <div>
                  <Typography type="caption" color="onSurfaceDisabled">
                    {i + 1}
                  </Typography>
                </div>
                <div style={{ flex: "1 1 332px" }}>
                  <Autocomplete
                    value={product.productId}
                    onChange={(newValue) =>
                      setForm((draft) => {
                        const product = useGetWarehouseProductsResult.data.find(
                          (product) => product.id === newValue
                        );

                        draft.selectedProducts[i].productId = newValue;
                        draft.selectedProducts[i].product = {
                          img: product.image,
                          name: product.name,
                          sku: product.SKU,
                          totalQuantity: product.totalQuantity,
                        };
                      })
                    }
                    id="product_id"
                    label="label_inventory"
                    options={
                      useGetWarehouseProductsResult.isLoading
                        ? []
                        : [
                            ...useGetWarehouseProductsResult.data.map(
                              (product) => {
                                return {
                                  label: product.name,
                                  value: product.id,
                                };
                              }
                            ),
                          ]
                    }
                    disableClearable={true}
                    required={true}
                  />
                </div>
                <div style={{ flex: "1 1 148px" }}>
                  <LotDropDown
                    lotId={product.lotId}
                    onChange={(newValue) => {
                      setForm((draft) => {
                        if (!newValue) {
                          draft.selectedProducts[i].lotId = "";
                          draft.selectedProducts[i].lot = null;
                        } else {
                          draft.selectedProducts[i].lotId = newValue.lotId;
                          draft.selectedProducts[i].lot = {
                            no: newValue.lotNo,
                          };
                        }
                      });
                    }}
                    productId={product.productId}
                    warehouseId={props.warehouseId}
                    listOfLotsToHide={selectedLotIds}
                  />
                </div>
                <div style={{ flex: "1 1 111px" }}>
                  <QuantityTextField
                    lotId={product.lotId}
                    onChange={(newValue) => {
                      setForm((draft) => {
                        draft.selectedProducts[i].quantity = newValue;
                      });
                    }}
                    onError={(error) => {
                      setForm((draft) => {
                        draft.selectedProducts[i].error = error;
                      });
                    }}
                    productId={product.productId}
                    quantity={product.quantity}
                    warehouseId={props.warehouseId}
                  />
                </div>
                <div
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                  onClick={() => handleRemoveInventoryRow(i)}
                >
                  <Icon name="TrashInventoryV2" color="primary500" />
                </div>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <TextButton
                icon="Plus"
                translationKey="label_add_more"
                onClick={handleInsertInventoryRow}
              />
            </div>
          </div>
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: modalBorderStyle,
          marginLeft: `-${modalSpacing.horizontalSpace}`,
          marginRight: `-${modalSpacing.horizontalSpace}`,
          paddingLeft: modalSpacing.horizontalSpace,
          paddingRight: modalSpacing.horizontalSpace,
          paddingTop: modalSpacing.verticalSpace,
        }}
      >
        {step === "FORM" ? (
          <>
            <Button type="secondary" onClick={props.onClose}>
              action_cancel
            </Button>
            <Button
              type="primary"
              disabled={isFormDisabled}
              onClick={() => setStep("REVIEW")}
            >
              label_review
            </Button>
          </>
        ) : null}
        {step === "REVIEW" ? (
          <>
            <Button
              type="secondary"
              onClick={() => setStep("FORM")}
              disabled={isLoading}
            >
              label_back
            </Button>
            <Button
              type="primary"
              onClick={handleSubmission}
              loading={isLoading}
            >
              action_confirm
            </Button>
          </>
        ) : null}
      </div>
    </Modal>
  );
}

function SummaryView(props: { data: Form }) {
  const rows = props.data.selectedProducts.reduce(
    (acc, curr) => {
      const existingRow = acc.find((row) => row.productId === curr.productId);

      if (existingRow) {
        existingRow.quantity += Number(curr.quantity);
        existingRow.lots.push(curr.lot.no);
      } else {
        acc.push({
          productId: curr.productId,
          lots: [curr.lot.no],
          quantity: Number(curr.quantity),
          img: curr.product.img,
          name: curr.product.name,
          sku: curr.product.sku,
          totalQuantity: curr.product.totalQuantity,
        });
      }

      return acc;
    },
    [] as Array<{
      productId: string;
      sku: string;
      lots: Array<string>;
      img: string;
      name: string;
      quantity: number;
      totalQuantity: number;
    }>
  );

  return (
    <div style={{ overflow: "auto", flex: "1 1 0" }}>
      <div
        style={{
          display: "flex",
          borderBottom: modalBorderStyle,
          minHeight: "45px",
          alignItems: "center",
          position: "sticky",
          top: "0",
          left: "0",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            flex: "1 1 317px",
            paddingLeft: "14px",
            boxSizing: "border-box",
          }}
        >
          <Typography type="caption" color="onSurfaceMedium" translate>
            label_ingredient
          </Typography>
        </div>
        <div style={{ flex: "1 1 82px" }}>
          <Typography type="caption" color="onSurfaceMedium" translate>
            label_lots
          </Typography>
        </div>
        <div style={{ flex: "1 1 82px" }}>
          <Typography type="caption" color="onSurfaceMedium" translate>
            label_available_stocks
          </Typography>
        </div>
        <div style={{ flex: "1 1 82px" }}>
          <Typography type="caption" color="onSurfaceMedium" translate>
            label_quantity
          </Typography>
        </div>
      </div>

      {rows.map((item) => (
        <div
          key={item.productId}
          style={{
            display: "flex",
            borderBottom: modalBorderStyle,
            minHeight: "45px",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 317px", minWidth: "0px" }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: "0 0 40px",
                  minWidth: "40px",
                }}
              >
                <img
                  src={item.img}
                  style={{
                    height: "40px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1px",
                  paddingRight: "30px",
                }}
              >
                <Typography type="body-2">{item.name}</Typography>
                <Typography type="caption" color="onSurfaceDisabled">
                  {item.sku}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ flex: "1 1 82px" }}>
            <Typography type="body-2">{item.lots.join(", ")}</Typography>
          </div>
          <div style={{ flex: "1 1 82px" }}>
            <Typography type="body-2">{item.totalQuantity}</Typography>
          </div>
          <div style={{ flex: "1 1 82px" }}>
            <Typography type="body-2">{item.quantity}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

function LotDropDown(props: {
  lotId: string;
  productId: string;
  warehouseId: string;
  onChange: (value: { lotId: string; lotNo: string }) => void;
  listOfLotsToHide: string[];
}) {
  const useGetProductLotsResult = useGetProductLotsByWarehouseId(
    { productId: props.productId, warehouseId: props.warehouseId },
    { enabled: props.productId !== "" && props.warehouseId !== "" }
  );
  React.useEffect(() => {
    // If there's a lot id in the result, do nothing
    if (
      !useGetProductLotsResult.isIdle &&
      !useGetProductLotsResult.isLoading &&
      useGetProductLotsResult.data?.find((lot) => lot.lotId === props.lotId)
    ) {
      return;
    }

    props.onChange(null);
  }, [props.warehouseId, props.productId, useGetProductLotsResult.data]);

  return (
    <Autocomplete
      value={props.lotId}
      onChange={(newValue) => {
        // Get the lot from the lot id
        const lot = useGetProductLotsResult.data.find(
          (lot) => lot.lotId === newValue
        );
        props.onChange({
          lotId: newValue,
          lotNo: lot.lotNo,
        });
      }}
      id="lot_no"
      label="label_lot_no"
      options={
        useGetProductLotsResult.isLoading || useGetProductLotsResult.isIdle
          ? []
          : useGetProductLotsResult.data
              .filter(
                (lot) =>
                  lot.lotId === props.lotId ||
                  !props.listOfLotsToHide.includes(lot.lotId)
              )
              .map((lot) => ({
                label: `${lot.lotNo} - (${lot.lotId.slice(-6)})`,
                value: lot.lotId,
              }))
              .sort((lotA, lotB) => -lotA.label.localeCompare(lotB.label))
      }
      disableClearable={true}
      required={true}
    />
  );
}

function QuantityTextField(props: {
  lotId: string;
  productId: string;
  warehouseId: string;
  quantity: string;
  onChange: (newValue: string) => void;
  onError: (error: Error) => void;
}) {
  const useGetProductLotsResult = useGetProductLotsByWarehouseId(
    { productId: props.productId, warehouseId: props.warehouseId },
    { enabled: props.productId !== "" && props.warehouseId !== "" }
  );
  const selectedLot = useGetProductLotsResult.data?.find(
    (lot) => lot.id === props.lotId
  );
  const isError = selectedLot?.quantity < Number(props.quantity);

  React.useEffect(() => {
    if (isError) {
      props.onError(new Error("Quantity is greater than available quantity"));
    } else {
      props.onError(null);
    }
  }, [props.quantity]);

  return (
    <div style={{ position: "relative" }}>
      <TextField
        required
        label="label_quantity"
        value={props.quantity}
        onChange={(e) => props.onChange(e.target.value)}
        error={isError}
      />
      {isError ? (
        <div
          style={{
            position: "absolute",
            bottom: "-18px",
            left: "15px",
          }}
        >
          <Typography type="caption" color="error" translate>
            {selectedLot.quantity}
            Available
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdminIconCell--3p-Y9tKJC1{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"AdminIconCell": "AdminIconCell--3p-Y9tKJC1"
};
module.exports = exports;

import * as React from "react";

import { ResponsiveModal as Modal, Title } from "components/ResponsiveModal";
import Button from "components/Button/Button";
import { toasti18n } from "utils/toast";

import { httpClient } from "common/services/transportService";
import DatePicker from "components/DatePicker/DatePicker";
import * as moment from "moment";
import TextButton from "common/components/textbutton/TextButton";
import Select from "components/Select/Select";
import { downloadCSVFromNetworkRequest } from "utils/csv";

export function DownloadInventorySummaryReportButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <TextButton
        onClick={() => setIsOpen(true)}
        translationKey="label_download_inventory_summary"
        icon="DownloadDocument"
      />
      {isOpen ? (
        <DownloadInventorySummaryReportModal onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

export function DownloadInventorySummaryReportModal(props: {
  onClose: () => void;
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().startOf("day")
  );
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment().endOf("day")
  );
  const [status, setStatus] = React.useState<
    "en_route" | "refill" | "completed"
  >("en_route");

  async function downloadReport() {
    setIsLoading(true);
    try {
      await downloadCSVFromNetworkRequest(
        () => downloadInventorySummaryReportCSV(startDate, endDate, status),
        `inventory-summary-${status}-${startDate.format("DD-MM-YYYY")}-to-${endDate.format(
          "DD-MM-YYYY"
        )}.csv`
      );
      props.onClose();
      toasti18n.success();
    } catch {
      setIsLoading(false);
    }
  }

  const isFormInvalid =
    startDate === null ||
    !startDate.isValid() ||
    endDate === null ||
    !endDate.isValid() ||
    startDate.isAfter(endDate);

  return (
    <Modal
      onClose={isLoading ? () => {} : props.onClose}
      open={true}
      modalStyles={{
        maxWidth: "500px",
        heightStyles: {
          flex: "0 0 auto",
        },
      }}
    >
      <Title>label_download_inventory_summary</Title>

      <div
        style={{
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Select
          id="status"
          label="label_status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={[
            { label: "label_enroute", value: "en_route" },
            { label: "label_refill", value: "refill" },
            { label: "label_completed", value: "completed" },
          ]}
          required
        />
        <DatePicker
          disableFuture={true}
          label="label_start_date"
          value={startDate}
          onChange={(value) => {
            if (moment.isMoment(value) && value.isValid()) {
              setStartDate(value.startOf("day"));
            } else {
              setStartDate(value);
            }
          }}
          required
        />
        <DatePicker
          disableFuture={true}
          shouldDisableDate={(day) => day.isBefore(startDate)}
          label="label_end_date"
          value={endDate}
          onChange={(value) => {
            if (moment.isMoment(value) && value.isValid()) {
              setEndDate(value.endOf("day"));
            } else {
              setEndDate(value);
            }
          }}
          required
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "29px",
        }}
      >
        <Button type="secondary" onClick={props.onClose} disabled={isLoading}>
          action_cancel
        </Button>
        <Button
          type="primary"
          onClick={downloadReport}
          loading={isLoading}
          disabled={isFormInvalid}
        >
          action_download
        </Button>
      </div>
    </Modal>
  );
}

export async function downloadInventorySummaryReportCSV(
  startDate: moment.Moment,
  endDate: moment.Moment,
  status: "en_route" | "refill" | "completed"
): Promise<string> {
  const response = await httpClient.get<string>(`/plan/inventory-report`, {
    params: {
      status,
      // Backend expects the days to be the start of the day for both start and end
      startTimestamp: startDate.startOf("day").toISOString(),
      endTimestamp: endDate.startOf("day").toISOString(),
    },
  });
  return response.data;
}

import * as React from "react";

import { Dialog, IconButton } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";
import Button from "components/Button";
import Select from "components/Select";
import { Link } from "react-router-dom";
import TextField from "components/TextField";
import StatusChangeOverlay from "./components/StatusChangeOverlay";
import LoadingScreen from "components/LoadingScreen";

import { useQueryClient } from "react-query";
import {
  useDismissMachineTicketMutation,
  useGetTicketByIdQuery,
  useInProgressMachineTicketMutation,
} from "gql/generated";

import * as styles from "./AcceptDismissTicketModal.module.scss";

import { toast } from "react-toastify";
import i18n from "common/i18n";

import * as moment from "moment";

type Priority =
  | "urgent"
  | "highest"
  | "high"
  | "medium"
  | "low"
  | "lowest"
  | "";
interface IAcceptDismissTicketModalProps {
  ticketId: string | null;
  onClose: () => void;
}

export default function AcceptDismissTicketModal({
  ticketId,
  onClose,
}: IAcceptDismissTicketModalProps): JSX.Element {
  const { data, isLoading } = useGetTicketByIdQuery(
    { ticketId: ticketId },
    {
      enabled: ticketId !== null,
      cacheTime: 0,
      onSuccess: (data) => {
        if (priority === "") {
          setPriority((data.ticket.priority as Priority) ?? "");
        }
      },
    }
  );
  const ticket = data?.ticket;

  const queryClient = useQueryClient();
  const { mutate: acceptMachineTicketMutation, isLoading: acceptLoading } =
    useInProgressMachineTicketMutation({
      onSuccess: () => {
        queryClient.invalidateQueries([
          "GetTicketsByStatus",
          { status: "ready" },
        ]);
        queryClient.invalidateQueries([
          "GetTicketsByStatus",
          { status: "in_progress" },
        ]);
        queryClient.invalidateQueries(["GetTicketStatusCounts"]);
        onClose();
        toast.success(i18n.t("toast_request_successful"));
      },
    });
  const { mutate: dismissMachineTicketMutation, isLoading: dismissLoading } =
    useDismissMachineTicketMutation({
      onSuccess: () => {
        queryClient.invalidateQueries([
          "GetTicketsByStatus",
          { status: "ready" },
        ]);
        queryClient.invalidateQueries(["GetTicketStatusCounts"]);
        onClose();
        toast.success(i18n.t("toast_request_successful"));
      },
    });

  const [priority, setPriority] = React.useState<Priority>("");
  const [technician, setTechnician] = React.useState<string>("");
  const [note, setNote] = React.useState<string>("");

  if (!isLoading && ticket.status !== "ready") {
    queryClient.invalidateQueries(["GetTicketsByStatus", { status: "ready" }]);
    queryClient.invalidateQueries([
      "GetTicketsByStatus",
      { status: ticket.status },
    ]);
    queryClient.invalidateQueries(["GetTicketStatusCounts"]);
  }

  return (
    <Dialog
      open={ticketId !== null}
      onClose={onClose}
      PaperProps={{
        className: styles.Paper,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={styles.IconButton}
      >
        <Icon
          name="Close"
          color="onSurfaceHigh"
        />
      </IconButton>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div>
            <Typography
              translate
              type="headline-6"
            >
              label_new_ticket
              {" " + ticket.ticketId}
            </Typography>
          </div>

          <div className={styles.InfoOuterContainer}>
            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography
                  type="subtitle-1"
                  color="onSurfaceMedium"
                  translate
                >
                  label_machine_id
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Link
                  to={`/machines/detail/${ticket.machine.id}/inventory`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.Link}
                >
                  <Typography
                    type="subtitle-1"
                    color="primary500"
                  >
                    {ticket.machine.id}
                  </Typography>
                </Link>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography
                  type="subtitle-1"
                  color="onSurfaceMedium"
                  translate
                >
                  label_machine_name
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Typography type="subtitle-1">{ticket.machine.name}</Typography>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography
                  type="subtitle-1"
                  color="onSurfaceMedium"
                  translate
                >
                  label_service_zone
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                <Typography type="subtitle-1">
                  {ticket.machine.serviceZone}
                </Typography>
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography
                  type="subtitle-1"
                  color="onSurfaceMedium"
                  translate
                >
                  status_error
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                {ticket.issues.map((issue) => (
                  <div
                    key={issue.id}
                    className={styles.Error}
                  >
                    <Typography type="subtitle-1">
                      {issue.description.split("(").join(" (")}
                    </Typography>
                    <Typography
                      type="body-3"
                      color="onSurfaceDisabled"
                      className={styles.Date}
                    >
                      {" "}
                      {moment(Number(issue.createdAt)).format("MM/DD/YY HH:mm")}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.InfoContainer}>
              <div className={styles.FieldName}>
                <Typography
                  type="subtitle-1"
                  color="onSurfaceMedium"
                  translate
                >
                  label_note
                </Typography>
              </div>
              <div className={styles.FieldValue}>
                {ticket.notes.map((note) => (
                  <div
                    key={note.id}
                    className={styles.Note}
                  >
                    <Typography
                      type="body-3"
                      color="onSurfaceDisabled"
                      translate
                    >
                      {moment(Number(note.createdAt)).format("MM/DD/YY HH:mm")}
                      {" Created by "}
                      {note.createdBy.name}
                      {" - "}
                      {note.description.split("(").join(" (")}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.FormField}>
            <Select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              id="priority"
              label="label_priority"
              options={[
                {
                  label: "label_urgent",
                  value: "urgent",
                  icon: { name: "PriorityUrgent" },
                },
                {
                  label: "label_highest",
                  value: "highest",
                  icon: { name: "PriorityVeryHigh" },
                },
                {
                  label: "label_high",
                  value: "high",
                  icon: { name: "PriorityHigh" },
                },
                {
                  label: "label_medium",
                  value: "medium",
                  icon: { name: "PriorityMedium" },
                },
                {
                  label: "label_low",
                  value: "low",
                  icon: { name: "PriorityLow" },
                },
                {
                  label: "label_lowest",
                  value: "lowest",
                  icon: { name: "PriorityLowest" },
                },
              ]}
              required
            />
          </div>

          <div className={styles.FormField}>
            <TextField
              value={technician}
              onChange={(e) => setTechnician(e.target.value)}
              label="label_technician"
              required
            />
          </div>

          <div className={styles.FormField}>
            <TextField
              value={note}
              onChange={(e) => setNote(e.target.value)}
              label="label_note"
            />
          </div>

          <div className={styles.ButtonContainer}>
            <Button
              type="secondary"
              onClick={onClose}
              disabled={dismissLoading || acceptLoading}
            >
              {"action_cancel"}
            </Button>
            <div className={styles.InnerButtonContainer}>
              <Button
                type="status"
                disabled={acceptLoading}
                loading={dismissLoading}
                onClick={() => {
                  dismissMachineTicketMutation({
                    updateMaintenanceTicketId: ticket.id,
                    status: "cancelled",
                  });
                }}
              >
                {"action_dismiss"}
              </Button>
              <Button
                type="primary"
                disabled={
                  technician === "" || priority === "" || dismissLoading
                }
                onClick={() => {
                  acceptMachineTicketMutation({
                    updateMaintenanceTicketId: ticket.id,
                    status: "in_progress",
                    assignedTo: technician,
                    priority: priority,
                    note: note,
                  });
                }}
                loading={acceptLoading}
              >
                {"action_accept"}
              </Button>
            </div>
          </div>
          {ticket.status !== "ready" && <StatusChangeOverlay />}
        </>
      )}
    </Dialog>
  );
}

import * as React from "react";
import ReactPDF, {
  Document,
  Page,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import { Checkbox, FormField, InfoBox } from "pdf/components/atoms";
import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

import * as moment from "moment";

import { ArrayElement } from "types/utils";
import { RefillOrder } from "./type";

export function OperationSheet({
  refillOrder,
  planName,
}: {
  refillOrder: RefillOrder;
  planName: string;
}) {
  return (
    <Document>
      <Page size="A4" style={{ padding: 32 }}>
        <OperationHeader refillOrder={refillOrder} planName={planName} />
        <View style={{ marginTop: 8 }}>
          <OperationInfo refillOrder={refillOrder} />
        </View>
        <OperationTable refillOrder={refillOrder} />
        <View
          wrap={false}
          style={{
            marginTop: 13,
          }}
        >
          <OperationForm refillOrder={refillOrder} />
        </View>
      </Page>
    </Document>
  );
}

export function formatDate(timeStamp: string) {
  return moment(Number(timeStamp)).format("DD/MM/YYYY");
}
function formatTime(timeStamp: string) {
  return moment(Number(timeStamp)).format("HH:mm");
}

function OperationHeader({
  refillOrder,
  planName,
}: {
  refillOrder: RefillOrder;
  planName: string;
}) {
  planName = planName.replace(/\t/g, " ");
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <View>
        <View>
          <Text
            style={{
              fontFamily: "Kanit-medium",
              fontSize: 20,
              letterSpacing: 0.15,
              color: COLORS.onSurfaceHigh,
            }}
            translate
          >
            label_order_number
            {` `}
            {refillOrder.refillOrderId}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginTop: -8,
            justifyContent: "space-between",
          }}
        >
          <Text
            breakWordAnywhere
            style={{
              fontFamily: "Kanit-regular",
              fontSize: 13,
              lineHeight: 1,
              letterSpacing: 0.15,
              color: COLORS.onSurfaceMedium,
              marginRight: 10,
              maxWidth: 350,
              marginTop: 4,
            }}
          >
            {planName}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Text
              translate
              style={{
                fontFamily: "Kanit-regular",
                fontSize: 16,
                letterSpacing: 0.15,
                color: COLORS.onSurfaceMedium,
              }}
            >
              {`(${refillOrder.requestedItems.length} `}
              {refillOrder.requestedItems.length > 1
                ? "label_items"
                : "label_item"}
              {`)`}
            </Text>
            <Text
              translate
              style={{
                fontFamily: "Kanit-light",
                fontSize: 12,
                letterSpacing: 0.25,
                color: COLORS.onSurfaceHigh,
                marginBottom: 1,
                marginLeft: 10,
              }}
            >
              {formatDate(refillOrder.createdDateTimestamp)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

function OperationInfo({ refillOrder }: { refillOrder: RefillOrder }) {
  const columnSizeStyles: ReactPDF.NodeProps["style"] = {
    flexBasis: 90,
    minWidth: 90,
  };
  const growToFillRemainingSpaceStyles: ReactPDF.NodeProps["style"] = {
    flexBasis: "0",
    flexGrow: 1,
    minWidth: 0,
  };

  return (
    <>
      <View style={{ display: "flex", flexDirection: "row", marginBottom: 2 }}>
        <InfoBox
          label="label_route_position"
          value={refillOrder.positionInPlan}
          style={columnSizeStyles}
        />
        <InfoBox
          label="label_time_to"
          value={formatTime(refillOrder.estimatedArrivalTimestamp)}
          style={columnSizeStyles}
        />
        <InfoBox
          label="label_route"
          value={refillOrder.routeName}
          style={growToFillRemainingSpaceStyles}
        />
      </View>

      <View style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <InfoBox
          label="label_machine_id"
          value={refillOrder.machineId}
          style={columnSizeStyles}
        />
        <InfoBox
          label="label_location_name"
          value={refillOrder.locationName}
          style={growToFillRemainingSpaceStyles}
        />
      </View>
    </>
  );
}

function OperationTable({ refillOrder }: { refillOrder: RefillOrder }) {
  return (
    <>
      <TableHeader />
      {refillOrder.requestedItems.map((requestedItem, index) => (
        <TableRow requestedItem={requestedItem} key={index} />
      ))}
    </>
  );
}
function TableRow({
  requestedItem,
}: {
  requestedItem: ArrayElement<RefillOrder["requestedItems"]>;
}) {
  const tableRowText: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceHigh,
    fontFamily: "Kanit-light",
    fontSize: 11,
  };
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
      }}
      wrap={false}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -2,
          marginBottom: -2,
        }}
      >
        <View style={styles.slotCell}>
          <Text
            style={{
              fontFamily: "Kanit-medium",
              fontSize: 11,
              fontWeight: 700,
            }}
          >
            {requestedItem.slot}
          </Text>
        </View>

        <View style={styles.ingredientCell}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "10",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: COLORS.onSurfaceMedium,
                fontSize: 7,
                fontFamily: "Kanit-regular",
                minWidth: 60,
                flexBasis: 60,
              }}
            >
              {requestedItem.package.packageSKU}
            </Text>
            <Text
              style={{
                ...tableRowText,
                flexBasis: 0,
                flexGrow: 1,
                minWidth: 0,
              }}
              breakWordAnywhere
            >
              {requestedItem.package.product.productName}{" "}
            </Text>
          </View>
        </View>

        <View style={styles.unitCell}>
          <Text
            style={{
              ...tableRowText,
              paddingRight: 5,
            }}
            breakWordAnywhere
          >
            {requestedItem.package.productQuantityPerPackage +
              requestedItem.package.productUOMInPackage +
              "/" +
              requestedItem.package.packageUOM}
          </Text>
        </View>
        <View style={{ ...styles.totalCell, paddingRight: 5 }}>
          <Text
            style={{
              ...tableRowText,
            }}
          >
            {requestedItem.numberOfPackages *
              requestedItem.package.productQuantityPerPackage +
              requestedItem.package.productUOMInPackage}
          </Text>
        </View>
        <View style={styles.quantityCell}>
          <Text
            style={{
              fontFamily: "Kanit-medium",
              fontSize: 14,
              fontWeight: 800,
            }}
          >
            {requestedItem.numberOfPackages}
          </Text>
        </View>
        <View style={styles.packedCell}>
          <Checkbox />
        </View>
        <View style={styles.filledCell}>
          <Checkbox />
        </View>
      </View>

      {requestedItem.type !== "REFILL" ? (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={styles.slotCell}></View>

          <View style={styles.ingredientCell}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "10",
              }}
            >
              <Text
                style={{
                  minWidth: 60,
                  flexBasis: 60,
                }}
              ></Text>
              <Text
                style={{
                  fontFamily: "Kanit-medium",
                  fontSize: 11,
                  color: COLORS.onSurfaceHigh,
                  flexBasis: 0,
                  flexGrow: 1,
                  minWidth: 0,
                }}
                breakWordAnywhere
                translate
              >
                {requestedItem.type === "SWAP_REFILL_ON_TOP"
                  ? "label_replace_method_refill_on_top"
                  : null}
                {requestedItem.type === "SWAP_REPLACE"
                  ? "label_replace_method_remove_old"
                  : null}
              </Text>
            </View>
          </View>

          <View style={styles.unitCell}></View>
          <View style={styles.totalCell}></View>
          <View style={styles.quantityCell}></View>
          <View style={styles.packedCell}>
            <Checkbox />
          </View>
          <View style={styles.filledCell}>
            <Checkbox />
          </View>
        </View>
      ) : null}
    </View>
  );
}
function TableHeader() {
  const tableHeaderStyles: ReactPDF.NodeProps["style"] = {
    color: COLORS.onSurfaceMedium,
    fontFamily: "Kanit-regular",
    fontSize: 10,
    lineHeight: 16 / 10,
    letterSpacing: 0.4,
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "#666666",
        borderBottomWidth: 1,
      }}
    >
      <View style={styles.slotCell}>
        <Text translate style={tableHeaderStyles}>
          label_slot
        </Text>
      </View>
      <View style={styles.ingredientCell}>
        <Text translate style={tableHeaderStyles}>
          label_ingredient
        </Text>
      </View>
      <View style={styles.unitCell}>
        <Text translate style={tableHeaderStyles}>
          label_unit
        </Text>
      </View>
      <View style={styles.totalCell}>
        <Text translate style={tableHeaderStyles}>
          label_total
        </Text>
      </View>
      <View style={styles.quantityCell}>
        <Text translate style={tableHeaderStyles}>
          {"label_quantity_short"}{" "}
        </Text>
      </View>
      <View style={styles.packedCell}>
        <Text translate style={tableHeaderStyles}>
          label_packed
        </Text>
      </View>
      <View style={styles.filledCell}>
        <Text translate style={tableHeaderStyles}>
          label_filled
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tableRowText: {
    color: COLORS.onSurfaceHigh,
    fontFamily: "Kanit-light",
    fontSize: 11,
  },
  slotCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 42,
    flexBasis: 42,
  },
  ingredientCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    flexBasis: 0,
    flexGrow: 1,
  },
  unitCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 65,
    flexBasis: 65,
  },
  totalCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 58,
    flexBasis: 58,
  },
  quantityCell: {
    display: "flex",
    justifyContent: "center",
    minWidth: 30,
    flexBasis: 30,
  },
  packedCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 54,
    flexBasis: 54,
  },
  filledCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 38,
    flexBasis: 38,
  },
});

function OperationForm({ refillOrder }: { refillOrder: RefillOrder }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flexBasis: 265,
          minWidth: 265,
        }}
      >
        <View>
          {!refillOrder.locationNote && !refillOrder.criticalNote ? (
            <FormField
              label="label_notes"
              numberOfLines={3}
              numberOfDots={85}
              type="text"
            />
          ) : (
            <>
              <Text
                translate
                style={{
                  fontFamily: "Kanit-regular",
                  fontSize: 12,
                  lineHeight: 16 / 12,
                  letterSpacing: 0.4,
                  color: COLORS.onSurfaceMedium,
                  marginBottom: 8,
                }}
              >
                label_notes
              </Text>

              <Text
                style={{
                  fontFamily: "Kanit-regular",
                  fontSize: 13,
                  lineHeight: 24 / 13,
                  paddingRight: 30,
                }}
                breakWordAnywhere
              >
                {refillOrder.criticalNote ? (
                  <Text
                    style={{
                      color: "#FF0000",
                    }}
                    breakWordAnywhere
                  >
                    {refillOrder.criticalNote}
                    {refillOrder.criticalNote && refillOrder.locationNote
                      ? ","
                      : null}
                  </Text>
                ) : null}
                {refillOrder.locationNote ? refillOrder.locationNote : null}
              </Text>
            </>
          )}
        </View>
      </View>

      <View
        style={{
          flexBasis: 150,
          minWidth: 150,
        }}
      >
        <FormField
          label="label_pack_by"
          numberOfDots={11 * 3 + 4}
          type="text"
        />
        <FormField label="label_pack_date" numberOfDots={11} type="date" />
      </View>
      <View
        style={{
          flexGrow: 1,
          flexBasis: 0,
          minWidth: 0,
        }}
      >
        <FormField
          label="label_fill_by"
          numberOfDots={11 * 3 + 4}
          type="text"
        />
        <FormField label="label_fill_date" numberOfDots={11} type="date" />
      </View>
    </View>
  );
}

import * as React from "react";

import { Modal } from "@mui/material";
import LoadingScreen from "components/LoadingScreen";
import useKittingModalState from "./useKittingModalState";

import * as styles from "./KittingModal.module.scss";

import TextButton from "common/components/textbutton/TextButton";
import Button from "components/Button";
import Typography from "components/Typography";
import {
  ModalFallback,
} from "../../../Components";
import { toasti18n } from "utils/toast";
import { ErrorBoundary } from "react-error-boundary";
import {
  GetCreatedPlanByIdQuery,
  useGetCreatedPlanByIdQuery,
  useReplanMutation,
} from "gql/generated";

import { useDownloadOperationPDFByPlanId } from "containers/new-operations/refill-order/operation-pdf/useDownloadOperationPDFByPlanId";
import { ConfirmationModal } from "./ConfirmationModal";
import { useGetWarehouseProducts } from "./useGetWarehouseProducts";
import {
  trackMachineKittingPlanGenerated,
  trackPlanMoveToPrekits,
} from "./tracking";
import { useTranslation } from "react-i18next";
import { ReviewTable } from "./ReviewTable";
import { KittingTable } from "./KittingTable";

interface Props {
  planId: string;
  onClose: () => void;
  onReplanSuccess: () => void;
  onForecastedSuccess: () => void;
  onRemoveMachineFromPlanSuccess: () => void;
}

function ModalContainer(props: Props): JSX.Element {
  return (
    <Modal open={true} onClose={props.onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <ModalFallback
                error={error}
                metaData={`operations/refillorder/kitting | ID: ${props.planId}`}
                onClose={props.onClose}
              />
            )}
          >
            <KittingModalDataFetcher {...props} />
          </ErrorBoundary>
        </div>
      </div>
    </Modal>
  );
}

export function checkIfRoutingSkipped(
  plan: GetCreatedPlanByIdQuery["plan"] | undefined
) {
  if (!plan) return undefined;

  const startTime = plan.startTime;
  const refillOrders = plan.refillOrders;
  const refillCandidateTimes = refillOrders.map(
    (order) => order.estimatedArrival
  );
  return refillCandidateTimes.every((time) => time === startTime);
}

function KittingModalDataFetcher(props: Props) {
  const { data: planQuery, isLoading: isPlanLoading } =
    useGetCreatedPlanByIdQuery(
      {
        planId: props.planId,
      },
      {
        cacheTime: 0,
      }
    );
  const { isLoading: isWarehouseProductsLoading } = useGetWarehouseProducts(
    planQuery?.plan.warehouseId
  );

  const isLoading =
    isWarehouseProductsLoading || isPlanLoading;

  return (
    <Modal open={true} onClose={props.onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          {isLoading ? <LoadingScreen /> : <KittingModal {...props} />}
        </div>
      </div>
    </Modal>
  );
}

function KittingModal({
  planId,
  onClose,
  onForecastedSuccess,
  onReplanSuccess,
}: Props) {
  const kittingModalState = useKittingModalState({
    planId: planId,
  });
  const {
    isSubmitting,
    submitForm: forecastPlan,
    hasChanged,
  } = kittingModalState.form;
  const [step, setStep] = React.useState<"REVIEW" | "SELECT_ITEMS">(
    "SELECT_ITEMS"
  );
  const [showWarningUponExitModal, setShowWarningUponExitModal] =
    React.useState(false);
  const [isPlanSuccessfullyCreated, setIsPlanSuccessfullyCreated] =
    React.useState(false);

  const {
    downloadOperationPDFByPlanId,
    isLoading: isDownloadingOperationSheet,
  } = useDownloadOperationPDFByPlanId();

  const [_selectedMachineId, setSelectedMachineId] = React.useState(
    () => Object.keys(kittingModalState.machines)[0]
  );

  const selectedMachineId = kittingModalState["machines"][_selectedMachineId]
    ? _selectedMachineId
    : Object.values(kittingModalState["machines"]).sort(
        (a, b) => a.positionInPlan - b.positionInPlan
      )[0].machineId;

  const { mutate: replan, isLoading: isReplanLoading } = useReplanMutation();

  return (
    <>
      {showWarningUponExitModal ? (
        <ConfirmationModal
          onAccept={onClose}
          onClose={() => setShowWarningUponExitModal(false)}
        />
      ) : null}
      <div className={styles.KittingModal}>
        {kittingModalState.plan.isRoutingSkipped ? (
          <ForecastingTurnedOffNotifier />
        ) : null}
        <div className={styles.Header}>
          <Typography type="headline-6" color="onSurfaceHigh" translate>
            label_plan
            {` ${kittingModalState.plan.planName} `}
          </Typography>
          <Typography type="subtitle-1" color="onSurfaceMedium" translate>
            {"("}
            {kittingModalState.aggregates.numberOfRecommendedRefillSlots}
            {` `}
            label_recommended
            {` `}
            label_refill
            {")"}
          </Typography>
        </div>

        <div className={styles.GrowArea}>
          {step === "SELECT_ITEMS" ? (
            <KittingTable
              kittingModalState={kittingModalState}
              selectedMachineId={selectedMachineId}
              onMachineRowSelect={setSelectedMachineId}
            />
          ) : null}
          {step === "REVIEW" ? (
            <ReviewTable kittingModalState={kittingModalState} />
          ) : null}
        </div>

        <div className={styles.ButtonsContainer}>
          {step === "SELECT_ITEMS" ? (
            <>
              <Button
                type="secondary"
                onClick={
                  hasChanged ? () => setShowWarningUponExitModal(true) : onClose
                }
                disabled={isReplanLoading}
              >
                label_close
              </Button>
              <div className={styles.ReplanAndReviewContainer}>
                <Button
                  type="secondary"
                  loading={isReplanLoading}
                  onClick={() => {
                    replan(
                      { rePlanId: planId },
                      {
                        onError: (err) => {
                          toasti18n.error(err as Error);
                        },
                        onSuccess: () => {
                          onClose();
                          toasti18n.success();
                          onReplanSuccess();
                        },
                      }
                    );
                  }}
                >
                  label_replan
                </Button>
                <Button
                  type="primary"
                  onClick={() => setStep("REVIEW")}
                  disabled={
                    !kittingModalState.form.isFormValid || isReplanLoading
                  }
                >
                  action_review
                </Button>
              </div>
            </>
          ) : null}
          {step === "REVIEW" ? (
            <>
              <Button
                type="secondary"
                onClick={() => setStep("SELECT_ITEMS")}
                disabled={isSubmitting}
                style={
                  isPlanSuccessfullyCreated ? { visibility: "hidden" } : {}
                }
              >
                label_back
              </Button>
              <div className={styles.ReplanAndReviewContainer}>
                {isPlanSuccessfullyCreated ? (
                  <TextButton
                    translationKey="action_download_refill_paper"
                    onClick={() => downloadOperationPDFByPlanId(planId)}
                    icon="DownloadTray"
                    disabled={isDownloadingOperationSheet}
                  />
                ) : null}

                {isPlanSuccessfullyCreated ? (
                  <Button
                    type="primary"
                    loading={isDownloadingOperationSheet}
                    onClick={onClose}
                  >
                    label_close
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    onClick={() => {
                      forecastPlan({
                        onError: (err: Error) => {
                          toasti18n.error(err);
                        },
                        onSuccess: (_, variables) => {
                          toasti18n.success();
                          setIsPlanSuccessfullyCreated(true);
                          onForecastedSuccess();

                          trackMachineKittingPlanGenerated({
                            data: kittingModalState,
                            planId: variables.planId,
                            variables,
                          });
                          trackPlanMoveToPrekits(kittingModalState);
                        },
                      });
                    }}
                  >
                    action_generate
                  </Button>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
export { ModalContainer as KittingModal };



function ForecastingTurnedOffNotifier() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: "18px 33px",
        backgroundColor: "#FFB025",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <p
        style={{
          fontFamily: "Kanit",
          fontSize: "14px",
          fontWeight: 700,
          letterSpacing: "0.15px",
          marginLeft: "14px",
          marginRight: "14px",
        }}
      >
        {t("label_ingredient_usage_forecast_turned_off")}
      </p>
      <p
        style={{
          fontFamily: "Kanit",
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "0.15px",
          textAlign: "center",
        }}
      >
        {t("label_this_happens_when_we_couldnt_determine_eta")}
      </p>
    </div>
  );
}

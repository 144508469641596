// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FadeIn--3CDZQ2lL5v{animation:FadeIn--3CDZQ2lL5v 200ms ease-in-out}@keyframes FadeIn--3CDZQ2lL5v{0%{opacity:0}100%{opacity:1}}.FadeInMoveUp--40trXn-3Do{animation:FadeInMoveUp--40trXn-3Do 300ms ease-out}@keyframes FadeInMoveUp--40trXn-3Do{0%{opacity:0;transform:translateY(8px)}100%{opacity:1;transform:translateY(0)}}.Fallback--3AKJf3Zz3m{background-color:#ffd8d4;width:100%;height:100%;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"FadeIn": "FadeIn--3CDZQ2lL5v",
	"FadeInMoveUp": "FadeInMoveUp--40trXn-3Do",
	"Fallback": "Fallback--3AKJf3Zz3m"
};
module.exports = exports;

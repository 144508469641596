// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HoverStyle--1UkLPdfmLG{transition:all .15s ease-out}.HoverStyle--1UkLPdfmLG:hover{transform:scale(1.01) translate(0, -2.5px);transition:all .1s ease-in}.HoverStyle--1UkLPdfmLG:active{transform:scale(0.99) translate(0px, 2.5px);transition:all .02s linear}", ""]);
// Exports
exports.locals = {
	"HoverStyle": "HoverStyle--1UkLPdfmLG"
};
module.exports = exports;

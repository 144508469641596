import {
  GetMaintenanceBoardSpiralErrorDocument,
  GetMaintenanceBoardSpiralErrorQuery,
  GetMaintenanceBoardSpiralErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getSpiralUnsellableError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardSpiralErrorQuery,
    GetMaintenanceBoardSpiralErrorQueryVariables
  >(GetMaintenanceBoardSpiralErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

import * as React from 'react';

import Icon from 'common/components/icon/Icon';
import Button from 'components/Button';
import Typography from 'components/Typography';

import {
  GetAdjustedDataByPlanIdQuery,
  useGetAdjustedDataByPlanIdQuery,
  useReturnInventoryOnPlanMutation,
  ReturnInventoryOnPlanMutationVariables,
} from 'gql/generated';
import { toasti18n } from 'utils/toast';
import { useImmer } from 'use-immer';
import { Modal } from '@mui/material';
import { LoadingScreen, ModalFallback } from '../../../../Components';
import { ErrorBoundary } from 'react-error-boundary';

import * as styles from './AdjustmentModal.module.scss';
import alphanumeric from 'components/Table/util/compareFn/alphanumeric';
import { AdjustedPlan, MachineCategoryType, Package } from '../types';
import {
  isReturnQuantityAbnormal,
} from '../components/ReturnLessThanExpected';
import { FormView } from './FormView';
import { ConfirmView } from './ConfirmView';

interface SelectedPackageForAdjustment {
  [packageSKU: string]: {
    actualReturnBag: string;
    actualReturnUnit: string;
    type: MachineCategoryType;
  };
}

type OnAdjustmentSuccessType = {
  adjustedPlan: AdjustedPlan;
  variables: ReturnInventoryOnPlanMutationVariables;
}

export function AdjustmentModal({
  onClose,
  planId,
  onAdjustmentSuccess,
}: {
  onClose: () => void;
  planId: string;
  onAdjustmentSuccess: (props: OnAdjustmentSuccessType) => void;
}) {
  return (
    <Modal open={true}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <ModalFallback
                error={error}
                metaData={`operations/refillorder/adjustment | ID: ${planId}`}
                onClose={onClose}
              />
            )}
          >
            <AdjustmentModalDataFetcher planId={planId}>
              {({ isLoading, data }) =>
                isLoading ? (
                  <LoadingScreen />
                ) : (
                  <AdjustmentModalView
                    onClose={onClose}
                    adjustedPlan={mapDataToAdjustmentModalViewProps(data)}
                    onAdjustmentSuccess={onAdjustmentSuccess}
                  />
                )
              }
            </AdjustmentModalDataFetcher>
          </ErrorBoundary>
        </div>
      </div>
    </Modal>
  );
}

function AdjustmentModalDataFetcher({
  planId,
  children,
}: {
  planId: string;
  children: ({
    isLoading,
    data,
  }: {
    isLoading: boolean;
    data: GetAdjustedDataByPlanIdQuery;
  }) => React.ReactNode;
}) {
  const { data, isLoading } = useGetAdjustedDataByPlanIdQuery(
    { planId: planId },
    { cacheTime: 0 }
  );
  return <>{children({ isLoading, data })}</>;
}

function mapDataToAdjustmentModalViewProps(
  data: GetAdjustedDataByPlanIdQuery
): AdjustedPlan {
  return {
    packages: data.plan.totalRefillQuantities
      .map((returningInventory) => {
        if (!('product' in returningInventory)) {
          throw new Error('Product not found in returning inventory');
        }

        const packageInfo: Package['packageInfo'] = {
          img: returningInventory.product.image,
          packageSKU: returningInventory.product.SKU,
          packageUOM: returningInventory.product.packageType,
          product: {
            id: returningInventory.productId,
            name: returningInventory.product.name,
          },
          productQuantityPerPackage: returningInventory.product.package,
          productUOMInPackage: returningInventory.product.uom,
        };

        const refillInfo: Package['refillInfo'] = {
          productPackedInProductUOMInPackage:
            returningInventory.totalRequestedQuantity,
          productFilledInProductUOMInPackage:
            returningInventory.totalRefilledQuantity,
          expectedReturnInProductUOMInPackage:
            returningInventory.totalReturningQuantity,
          totalOldQuantityInProductUOMInPackage:
            returningInventory.totalReturningOldQuantity,
          productPackedInPackages: Math.floor(
            returningInventory.totalRequestedQuantity /
              packageInfo.productQuantityPerPackage
          ),
        };

        const type: Package["type"] = returningInventory?.product?.machineCategory === "SPIRAL" ? "SPIRAL": "COFFEE";

        return {
          packageInfo,
          refillInfo,
          type,
        };
      })
      .sort((inventoryA, inventoryB) =>
        alphanumeric(
          inventoryA.packageInfo.packageSKU,
          inventoryB.packageInfo.packageSKU
        )
      ),
    planId: data.plan.id,
  };
}

interface AdjustmentModalViewProps {
  onClose: () => void;
  onAdjustmentSuccess: (props: OnAdjustmentSuccessType) => void;
  adjustedPlan: AdjustedPlan;
}

function initializeFormData(
  adjustedPlan: AdjustedPlan
): SelectedPackageForAdjustment {
  return Object.fromEntries(
    adjustedPlan.packages.map((p) => [
      p.packageInfo.packageSKU,
      {
        actualReturnBag: '',
        actualReturnUnit: '',
        type: p.type,
      },
    ])
  );
}

function AdjustmentModalView({
  onClose,
  onAdjustmentSuccess,
  adjustedPlan,
}: AdjustmentModalViewProps) {
  const { packages } = adjustedPlan;
  const [step, setStep] = React.useState<'FORM' | 'CONFIRM'>('FORM');

  const [selectedPackageForAdjustment, setSelectedPackageForAdjustment] =
    useImmer<SelectedPackageForAdjustment>(() =>
      initializeFormData(adjustedPlan)
    );

  const isFormDisabled = Object.values(selectedPackageForAdjustment).some(
    ({ actualReturnBag, actualReturnUnit, type }) => {
      if(type === "COFFEE") return actualReturnBag === '' || actualReturnUnit === '';
      return actualReturnBag === '';
    }
  );

  function getPackageSKU(p: Package): string {
    return p.packageInfo.packageSKU;
  }
  function getPackageByPackageSKU(packageSKU: string): Package {
    return packages.find((p) => p.packageInfo.packageSKU === packageSKU);
  }

  function getActualReturnBagByPackage(p: Package): string {
    return (
      selectedPackageForAdjustment[getPackageSKU(p)]?.actualReturnBag ?? ''
    );
  }
  function getActualReturnUnitByPackage(p: Package): string {
    return (
      selectedPackageForAdjustment[getPackageSKU(p)]?.actualReturnUnit ?? ''
    );
  }

  function isPackageActualReturnAbnormal(p: Package) {
    const actualReturnBag = getActualReturnBagByPackage(p);
    const actualReturnUnit = getActualReturnUnitByPackage(p);

    return isReturnQuantityAbnormal({
      expectedReturn: p.refillInfo.expectedReturnInProductUOMInPackage,
      oldQuantity: p.refillInfo.totalOldQuantityInProductUOMInPackage,
      packageSize: p.packageInfo.productQuantityPerPackage,
      actualReturnBag,
      actualReturnUnit,
      type: p.type,
    });
  }

  function handleActualReturnBagChange(p: Package, value: string) {
    if (!isNaN(Number(value)) && value.slice(-1) !== '.') {
      setSelectedPackageForAdjustment((draft) => {
        draft[getPackageSKU(p)].actualReturnBag = value;
      });
    }
  }

  function handleActualReturnUnitChange(p: Package, value: string) {
    if (!isNaN(Number(value))) {
      setSelectedPackageForAdjustment((draft) => {
        draft[getPackageSKU(p)].actualReturnUnit = value;
      });
    }
  }

  const { isLoading: isSendingAdjustment, mutate } =
    useReturnInventoryOnPlanMutation({
      onSuccess: (_, variables) => {
        toasti18n.success();
        onClose();
        onAdjustmentSuccess({
          adjustedPlan,
          variables,
        });
      },
      onError: (err: Error) => {
        toasti18n.error(err);
      },
    });

  function sendAdjustment() {
    const selectedPackages = Object.entries(selectedPackageForAdjustment);

    mutate({
      planId: adjustedPlan.planId,
      inventories: selectedPackages.map(
        ([packageSKU, { actualReturnBag, actualReturnUnit }]) => {
          const p = getPackageByPackageSKU(packageSKU);
          return {
            actualBag: Number(actualReturnBag),
            actualRemaining: Number(actualReturnUnit),
            productId: p.packageInfo.product.id,
          };
        }
      ),
    });
  }

  return (
    <div className={styles.AdjustmentModalContainer}>
      <div className={styles.Header}>
        <Typography type="headline-6" color="onSurfaceHigh" translate>
          label_order_number
        </Typography>
        <div
          onClick={isSendingAdjustment ? () => {} : onClose}
          className={styles.CloseButton}
        >
          <Icon name="Close" color="onSurfaceHigh" />
        </div>
      </div>

      <div className={styles.GrowContainer}>
        <div className={styles.ScrollContainer}>
          <div className={styles.MinWidthMinContentContainer}>
            {step === 'FORM' ? (
              <FormView
                getPackageActualReturnBag={(p) =>
                  getActualReturnBagByPackage(p)
                }
                isPackageActualReturnAbnormal={(p) =>
                  isPackageActualReturnAbnormal(p)
                }
                getPackageActualReturnUnit={(p) =>
                  getActualReturnUnitByPackage(p)
                }
                onPackageActualReturnUnitChange={(p, value) =>
                  handleActualReturnUnitChange(p, value)
                }
                onPackageActualReturnBagChange={(p, value) =>
                  handleActualReturnBagChange(p, value)
                }
                packages={packages}
              />
            ) : null}
            {step === 'CONFIRM' ? (
              <ConfirmView
                packages={packages}
                isPackageActualReturnAbnormal={(p) =>
                  isPackageActualReturnAbnormal(p)
                }
                getPackageActualReturnBag={(p) =>
                  getActualReturnBagByPackage(p)
                }
                getPackageActualReturnUnit={(p) =>
                  getActualReturnUnitByPackage(p)
                }
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.ButtonsContainer}>
        {step === 'FORM' ? (
          <>
            <Button onClick={onClose} type="secondary">
              action_cancel
            </Button>
            <Button
              onClick={() => setStep('CONFIRM')}
              disabled={isFormDisabled}
              type="primary"
            >
              label_accept_return_inventory
            </Button>
          </>
        ) : null}
        {step === 'CONFIRM' ? (
          <>
            <Button
              onClick={() => setStep('FORM')}
              disabled={isSendingAdjustment}
              type="secondary"
            >
              action_cancel
            </Button>
            <Button
              onClick={() => sendAdjustment()}
              loading={isSendingAdjustment}
              type="primary"
            >
              action_confirm
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
}

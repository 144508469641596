import * as React from "react";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { get, orderBy, debounce } from "lodash";

import Typography from "common/components/typography/Typography";
import Icon from "common/components/icon/Icon";

import * as styles from "./Table.module.scss";

interface ISortConfig {
  dataKey: string;
  sortColumn: string;
  sortDirection: "asc" | "desc";
}

export interface IColumn {
  className?: string;
  dataKey?: string;
  headerClassName?: string;
  headerLabel?: string;
  columnFlex?: number;
  columnWidth?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  cellRenderer: (dataValue: unknown, rowData?: unknown) => JSX.Element;
  headerCellRenderer?: () => JSX.Element;
  align?: "flex-end" | "flex-start" | "center";
  color?: string;
  sortable?: boolean;
  sortingFn?: (a: object, b: object) => number; // To use this, you need to pass a dataKey prop
  showMobile?: boolean;
  showTablet?: boolean;
}

interface ITableProps {
  columns: Array<IColumn>;
  data: Array<object>;
  noDataIcon?: string;
  className?: string;
  loading?: boolean;
  loadingRows?: number;
  onRowClick?: (rowData: object) => void;
  rowBackgroundColorCb?: (rowData: object) => string;
  infiniteScroll?: boolean;
  defaultSortColumn?: string;
  defaultSortDirection?: "asc" | "desc";
  disableScrollToTop?: boolean;
}

const ROWS_PER_PAGE = 40;

function Table({
  columns,
  data,
  className,
  loading,
  onRowClick,
  noDataIcon = "Critical",
  loadingRows = 10,
  infiniteScroll = false,
  defaultSortColumn = null,
  defaultSortDirection = null,
  disableScrollToTop = false,
  rowBackgroundColorCb,
}: ITableProps): JSX.Element {
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
    defaultSortDirection
  );

  const [sortedData, setSortedData] = useState<object[]>([]);
  const [page, setPage] = useState<number>(0);
  const [paginatedData, setPaginatedData] = useState<object[]>([]);

  const sortedDataRef = useRef(sortedData);
  const pageRef = useRef(page);

  useEffect(() => {
    if (!disableScrollToTop) {
      window.scrollTo(0, 0);
    }
    if (infiniteScroll) {
      const listener = debounce(handleScroll, 750);
      window.addEventListener("scroll", listener);
      return () => window.removeEventListener("scroll", listener);
    }
  }, []);

  useLayoutEffect(() => {
    if (data) {
      if (sortColumn) {
        const sortingFn = columns.find(
          (column) => column.dataKey === sortColumn
        ).sortingFn;
        const sortedData =
          sortingFn === undefined
            ? orderBy(data, [sortColumn], [sortDirection])
            : [...data].sort((a, b) => {
                return sortDirection === "desc"
                  ? -sortingFn(a, b)
                  : sortingFn(a, b);
              });
        handleSetSortedData(sortedData);
      } else {
        handleSetSortedData(data);
      }
    }
  }, [data]);

  useLayoutEffect(() => {
    if (sortedData.length) {
      handleSetPage(0);
      setPaginatedData([]);

      if (infiniteScroll && sortedData.length > ROWS_PER_PAGE) {
        return paginate(true);
      }
      return setPaginatedData(sortedData);
    }
  }, [sortedData]);

  function handleSetSortedData(data: object[]): void {
    setSortedData(data);
    sortedDataRef.current = data;
  }

  function handleSetPage(page: number): void {
    setPage(page);
    pageRef.current = page;
  }

  function paginate(clear: boolean): void {
    const maxPages = Math.ceil(sortedDataRef.current.length / ROWS_PER_PAGE);
    const startingIndex = pageRef.current * ROWS_PER_PAGE;
    const endingIndex = startingIndex + ROWS_PER_PAGE;
    const nextPaginatedSet = sortedDataRef.current.slice(
      startingIndex,
      endingIndex
    );

    const proposedNextPage = pageRef.current + 1;

    if (maxPages === 1) {
      return setPaginatedData(sortedDataRef.current);
    }

    if (proposedNextPage <= maxPages) {
      handleSetPage(proposedNextPage);
      if (clear) {
        return setPaginatedData(nextPaginatedSet);
      }
      return setPaginatedData((_paginated) => [
        ..._paginated,
        ...nextPaginatedSet,
      ]);
    }
  }

  function handleScroll(): void {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const windowBottom = windowHeight + window.pageYOffset;

    const docHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    if (windowBottom >= docHeight) {
      paginate(false);
    }
  }

  function handleHeaderSort(
    dataKey: string,
    sortingFn: undefined | ((a: object, b: object) => number)
  ) {
    function configureSort(): ISortConfig {
      if (sortColumn !== dataKey) {
        setSortColumn(dataKey);
        setSortDirection("asc");
        return { dataKey, sortColumn: dataKey, sortDirection: "asc" };
      }
      if (sortColumn === dataKey && sortDirection === "asc") {
        setSortDirection("desc");
        return { dataKey, sortColumn: dataKey, sortDirection: "desc" };
      }
      if (sortColumn === dataKey && sortDirection === "desc") {
        setSortColumn(null);
        setSortDirection("asc");
        return { dataKey, sortColumn: null, sortDirection: "asc" };
      }
    }
    const sortConfig = configureSort();

    if (sortConfig.sortColumn) {
      const _sortedData =
        sortingFn === undefined
          ? orderBy(
              sortedData,
              [sortConfig.dataKey],
              [sortConfig.sortDirection]
            )
          : [...sortedData].sort((a, b) => {
              return sortConfig.sortDirection === "desc"
                ? -sortingFn(a, b)
                : sortingFn(a, b);
            });
      return handleSetSortedData(_sortedData);
    }
    if (!sortConfig.sortColumn) {
      return handleSetSortedData(data);
    }
  }

  const _columns = isMobile
    ? columns.filter((i) => i.showMobile || (isTablet && i.showTablet))
    : columns;

  return (
    <div
      className={[
        styles.Table,
        infiniteScroll ? styles.hasInfiniteScroll : "",
        className,
      ].join(" ")}
    >
      <div className={[styles.row, styles.headerRow].join(" ")}>
        {_columns.map((columnConfig: IColumn, columnIndex: number) => {
          return (
            <div
              key={`header-cell-${columnIndex}`}
              className={[
                styles.headerCell,
                columnConfig.sortable ? styles.sortable : "",
                columnConfig.className ? columnConfig.className : "",
                columnConfig.headerClassName || "",
              ].join(" ")}
              style={{
                flex: columnConfig.columnFlex || "initial",
                width: columnConfig.columnFlex
                  ? "inherit"
                  : columnConfig.columnWidth,
                maxWidth: columnConfig.maxWidth
                  ? columnConfig.maxWidth
                  : "unset",
                minWidth: columnConfig.minWidth
                  ? columnConfig.minWidth
                  : "unset",
                justifyContent: columnConfig.align,
                backgroundColor: columnConfig.color,
              }}
              onClick={() => {
                if (columnConfig.sortable) {
                  handleHeaderSort(
                    columnConfig.dataKey,
                    columnConfig.sortingFn
                  );
                }
              }}
            >
              {columnConfig.headerCellRenderer?.()}
              <Typography
                translationKey={columnConfig.headerLabel}
                type="body-2"
                className={[
                  styles.headerLabel,
                  sortColumn === columnConfig.dataKey ? styles.inSort : "",
                ].join(" ")}
              />
              {columnConfig.sortable && sortColumn !== columnConfig.dataKey && (
                <Icon
                  className={styles.sortChevrons}
                  name="SortChevrons"
                  color="onSurfaceDisabled"
                />
              )}
              {columnConfig.sortable &&
                sortColumn === columnConfig.dataKey &&
                sortDirection === "asc" && (
                  <Icon
                    className={[styles.sortChevrons, styles.upChevron].join(
                      " "
                    )}
                    name="Chevron"
                    color="onSurfaceHigh"
                  />
                )}
              {columnConfig.sortable &&
                sortColumn === columnConfig.dataKey &&
                sortDirection === "desc" && (
                  <Icon
                    className={[styles.sortChevrons, styles.downChevron].join(
                      " "
                    )}
                    name="Chevron"
                    color="onSurfaceHigh"
                  />
                )}
            </div>
          );
        })}
      </div>

      {!sortedData.length && !loading && (
        <div className={styles.message}>
          <Icon name={noDataIcon} color="disabled" />
          <Typography
            translationKey="label_no_data"
            type="body-2"
            className={styles.text}
          />
        </div>
      )}

      {!sortedData.length &&
        !!loading &&
        Array(loadingRows)
          .fill("")
          .map((_i, index) => {
            return (
              <div
                key={index}
                className={[styles.row, styles.loadingRow].join(" ")}
              >
                {_columns.map((columnConfig, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.loadingCell}
                      style={{
                        flex: columnConfig.columnFlex
                          ? columnConfig.columnFlex
                          : "initial",
                        width: columnConfig.columnFlex
                          ? "initial"
                          : columnConfig.columnWidth,
                        maxWidth: columnConfig.maxWidth
                          ? columnConfig.maxWidth
                          : "unset",
                        minWidth: columnConfig.minWidth
                          ? columnConfig.minWidth
                          : "unset",
                        justifyContent: columnConfig.align,
                      }}
                    />
                  );
                })}
              </div>
            );
          })}

      {!!sortedData.length &&
        !!paginatedData.length &&
        paginatedData.map((rowData: object, rowIndex: number) => {
          return (
            <div
              key={rowIndex}
              className={[
                styles.row,
                styles.dataRow,
                onRowClick ? styles.clickable : "",
              ].join(" ")}
              style={{
                backgroundColor: rowBackgroundColorCb?.(rowData),
              }}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(rowData);
                }
              }}
            >
              {_columns.map((columnConfig: IColumn, columnIndex: number) => {
                return (
                  <div
                    key={`row-${rowIndex}-column-${columnIndex}`}
                    className={[
                      styles.dataCell,
                      columnConfig.className ? columnConfig.className : "",
                    ].join(" ")}
                    style={{
                      flex: columnConfig.columnFlex
                        ? columnConfig.columnFlex
                        : "initial",
                      width: columnConfig.columnFlex
                        ? "initial"
                        : columnConfig.columnWidth,
                      maxWidth: columnConfig.maxWidth
                        ? columnConfig.maxWidth
                        : "unset",
                      minWidth: columnConfig.minWidth
                        ? columnConfig.minWidth
                        : "unset",
                      justifyContent: columnConfig.align,
                      backgroundColor: columnConfig.color,
                    }}
                  >
                    {columnConfig.dataKey
                      ? columnConfig.cellRenderer(
                          get(rowData, columnConfig.dataKey),
                          rowData
                        )
                      : columnConfig.cellRenderer(rowData)}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

export default Table;

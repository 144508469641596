import * as React from "react";
import { LocationKind } from "gql/generated";

import { useGetLocationById } from "../../hooks/useGetLocationById";
import {
  useGetLocationPermissions,
  useCanViewLocationEditPage,
  useCanUpdateFactory,
  useCanViewAndEditAdditionalContractInfo,
} from "../../hooks/useGetLocationPermissions";
import { useGetLocationIdUrlParams } from "../../hooks/useGetLocationIdUrlParams";

import { LocationIdLoading } from "../../components/LocationIdLoading";
import { LocationIdContainer } from "../../components/LocationIdContainer";
import { LocationContracts } from "../../components/LocationContracts";
import { GeneralLocationInfo } from "../../components/GeneralLocationInfo";
import { MachineIds } from "../../components/MachineIds";
import { OperationInfo } from "../../components/OperationInfo";
import {
  Factory,
  SingleMachineWithContract,
  Store,
  Location,
} from "../../types";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { AdditionalContractInfo } from "containers/location/components/ContractInfo";
import Button from "components/Button/Button";
import { InfoPanel } from "containers/location/components/ui/InfoPanel";
import { RentFeeView } from "containers/contract/components/location/RentFee";
import { ElectricFeeView } from "containers/contract/components/location/ElectricFee";
import { mapDataToRentFeeType } from "../EditLocationId/utils/mapDataToRentFeeType";
import { mapDataToElectricFeeType } from "../EditLocationId/utils/mapDataToElectricFeeType";
import { ContractLocationFee } from "./ContractLocationFee";

export default function LocationId() {
  const locationId = useGetLocationIdUrlParams();
  const getLocationByIdResult = useGetLocationById(locationId);
  const locationPermissions = useGetLocationPermissions();
  const isLoading =
    locationPermissions.isLoading || getLocationByIdResult.isLoading;

  let childrenLocation: React.ReactNode;
  if (isLoading) {
    childrenLocation = <LocationIdLoading />;
  } else {
    const location = getLocationByIdResult.data;
    switch (location.kind) {
      case LocationKind.Store:
        childrenLocation = <StoreView store={location} />;
        break;
      case LocationKind.Factory:
        childrenLocation = <FactoryView factory={location} />;
        break;
      case LocationKind.Location:
        childrenLocation = <LocationView location={location} locationId={locationId} />;
        break;
    }
  }

  return <>{childrenLocation}</>;
}

function LocationView({ location, locationId }: { location: SingleMachineWithContract, locationId: string }) {
  const { allowed: allowedToViewAdditionalContractInfo } =
    useCanViewAndEditAdditionalContractInfo();
  return (
    <>
      <PageViewHeader location={location} />
      <div className="space-y-4">
        <InfoPanel
          title="label_general"
          body={
            <>
              <MachineIds machineIds={[location.machineId]} />
              <LocationContracts contracts={location.contracts} />
              <GeneralLocationInfo locationInfo={location.locationInfo} />
            </>
          }
        />
        <InfoPanel
          title="label_operation"
          body={<OperationInfo operationInfo={location.operation} />}
        />

        {allowedToViewAdditionalContractInfo ? (
          <InfoPanel
            title="label_contract"
            body={
              <div>
                <AdditionalContractInfo
                  additionalContractInfo={
                    location.additionalContractInformation
                  }
                />
                <div className="h-5" />

                {location.contracts.active.length === 0 && (
                  <>
                    <RentFeeView
                      rentFee={mapDataToRentFeeType(location.rentFee)}
                    />
                    <ElectricFeeView
                      electricFee={mapDataToElectricFeeType(
                        location.electricFee
                      )}
                    />
                  </>
                )}
                {location.contracts.active.length > 0 && <ContractLocationFee contractId={location.contracts.active[0].id} locationId={locationId} />}
              </div>
            }
          />
        ) : null}
      </div>
    </>
  );
}

function StoreView({ store }: { store: Store }) {
  return (
    <>
      <PageViewHeader location={store} />
      <div className="space-y-4">
        <InfoPanel
          title="label_general"
          body={
            <>
              <MachineIds machineIds={store.machineIds} />
              <GeneralLocationInfo locationInfo={store.locationInfo} />
            </>
          }
        />
        <InfoPanel
          title="label_operation"
          body={<OperationInfo operationInfo={store.operation} />}
        />
      </div>
    </>
  );
}

export function FactoryView({ factory }: { factory: Factory }) {
  return (
    <>
      <PageViewHeader location={factory} />
      <div className="space-y-4">
        <LocationIdContainer>
          <InfoPanel
            title="label_general"
            body={
              <>
                <MachineIds machineIds={factory.machineIds} />
                <GeneralLocationInfo locationInfo={factory.locationInfo} />
              </>
            }
          />
        </LocationIdContainer>
      </div>
    </>
  );
}

export function PageViewHeader({ location }: { location: Location }) {
  const { allowed: canViewLocationEdit } = useCanViewLocationEditPage();
  const { allowed: canUpdateFactory } = useCanUpdateFactory();
  const locationId = useGetLocationIdUrlParams();

  let showEditLink: boolean = false;
  if (location.kind === LocationKind.Location && canViewLocationEdit) {
    showEditLink = true;
  } else if (location.kind === LocationKind.Factory && canUpdateFactory) {
    showEditLink = true;
  } else if (location.kind === LocationKind.Store) {
    showEditLink = false;
  }

  return (
    <div className="flex items-center py-4 gap-4">
      <PageTitle location={location} />
      <div className="">
        {showEditLink ? (
          <Link to={`/location/${locationId}/edit`}>
            <Button iconName="Edit" type="primary" size="small">
              action_edit
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

import Typography from "common/components/typography/Typography";
import * as React from "react";
import { Doughnut } from "../atoms/Doughnut";

import * as styles from "./RadialChartKey.module.scss";

interface RadialChartKeyProps {
  className?: string;
  color: string;
  label: string;
  value: number | string;
}

function RadialChartKey({
  className = "",
  color,
  label,
  value,
}: RadialChartKeyProps): JSX.Element {
  return (
    <div className={[styles.RadialChartKey, className].join(" ")}>
      <Doughnut color={color} className={styles.Doughnut} />
      <div style={{overflow: "hidden", textOverflow: "ellipsis", width: "85px"}}>
      <Typography
        translationKey={label}
        type="body-2"
        className={styles.Label}
      />
      </div>
      <div style={{marginLeft: "auto", flexShrink: 0}}>
        <Typography text={`${value}%`} type="body-2" className={styles.Value} />
      </div>  
    </div>
  );
}

export { RadialChartKey };
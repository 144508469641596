import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MasterInventoryList from "./screens/MasterInventoryList";
import { warehousingRoutes } from "./routes";
import Warehouses from "./screens/Warehouses";

export function Warehousing() {
  return (
    <div
      style={{
        margin: "-20px -35px",
        flex: "1 1 0",
        minHeight: 0,
      }}
    >
      <Switch>
        <Route path={warehousingRoutes.warehouses()}>
          <Warehouses />
        </Route>

        <Route path={warehousingRoutes.masterInventoryList()}>
          <MasterInventoryList />
        </Route>
        <Redirect to={warehousingRoutes.warehouses()} />
      </Switch>
    </div>
  );
}

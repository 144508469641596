import * as React from "react";

interface ISwapProps {
  color?: string;
  className?: string;
}

function Swap({ color = "white", className }: ISwapProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2929 2.70706L16.5868 5.00095H8C5.23858 5.00095 3 7.23953 3 10.001V13.001H5V10.001C5 8.3441 6.34315 7.00095 8 7.00095H16.5848L14.2929 9.29285L15.7071 10.7071L20.4142 5.99995L15.7071 1.29285L14.2929 2.70706ZM9.70711 21.2928L7.41421 19H16C18.7614 19 21 16.7614 21 14V11H19V14C19 15.6568 17.6569 17 16 17H7.41421L9.70711 14.7071L8.29289 13.2928L3.58579 18L8.29289 22.7071L9.70711 21.2928Z"
        fill={color}
      />
    </svg>
  );
}

export default Swap;

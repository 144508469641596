import { AxiosError } from "axios";
import { httpClient } from "common/services/transportService";
import {
  QueryFunctionContext,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { warehouseProductKeys } from "./use-get-products";
import { queryClient } from "index";

const warehouseLotTransfersKeys = {
  warehouseLotTransfers: (warehouseId: string) =>
    [
      {
        scope: "lotTransfers",
        warehouseId,
        url: `/lottransfer?warehouseId=:warehouseId`,
        resolvedUrl: `/lottransfer?warehouseId=${warehouseId}`,
      },
    ] as const,
};

function getCachedLotTransfers() {
  const query = queryClient.getQueriesData<LotTransfers>([
    {
      scope: "lotTransfers",
    },
  ]);
  return query;
}
function getCachedLotTransferByTransferId(transferId: string) {
  let lotTransfer: LotTransfers[0] | undefined;

  const lotTransferQueries = getCachedLotTransfers();
  lotTransferQueries.find(([_, cachedLotTransfers]) => {
    const cachedResult = cachedLotTransfers.find(
      (cachedTransfer) => cachedTransfer.id === transferId
    );
    if (cachedResult) {
      lotTransfer = cachedResult;
      // Stop searching
      return true;
    }
    // Continue searching
    return false;
  });

  return lotTransfer;
}

type WarehouseLotTransfersQueryKey = ReturnType<
  typeof warehouseLotTransfersKeys["warehouseLotTransfers"]
>;

type LotTransfers = Array<{
  id: string;
  fromWarehouseId: string;
  toWarehouseId: string;
  payload: Payload;
  updatedAt: string;
  createdAt: string;
  type: null;
  notes: null;
}>;

export interface Payload {
  type: string;
  notes: string;
  createdBy: string;
  additional: [];
  incomingInventory: IncomingInventory[];
}

export interface IncomingInventory {
  id: string;
  UPC: string;
  image?: string;
  lotId: string;
  lotNo: string;
  quantity: number;
  productId: string;
  totalUsed: number;
  expiryDate: string;
  productName: string;
  warehouseId: string;
  receivedDate: string;
  organisationId: string;
}

async function getWarehouseLotTransfers(
  context: QueryFunctionContext<WarehouseLotTransfersQueryKey>
) {
  const [{ warehouseId }] = context.queryKey;
  const response = await httpClient.get<LotTransfers>("/lottransfer", {
    params: {
      warehouseId,
    },
  });
  return response.data;
}

type GetWarehouseLotTransfersByIdQueryOptions = Omit<
  UseQueryOptions<
    LotTransfers,
    AxiosError,
    LotTransfers,
    WarehouseLotTransfersQueryKey
  >,
  "queryKey" | "queryFn" | "staleTime" | "select"
>;
function useGetWarehouseLotTransfersBase({
  warehouseId,
  options = {},
}: {
  warehouseId: string;
  options?: GetWarehouseLotTransfersByIdQueryOptions;
}) {
  return useQuery({
    queryKey: warehouseLotTransfersKeys.warehouseLotTransfers(warehouseId),
    queryFn: getWarehouseLotTransfers,
    staleTime: 60 * 1000,
    ...options,
  });
}

export function useGetWarehouseLotTransfers(
  warehouseId: string,
  options?: GetWarehouseLotTransfersByIdQueryOptions
) {
  return useGetWarehouseLotTransfersBase({
    warehouseId: warehouseId,
    options,
  });
}

export const MOVE_INVENTORY_REASONS = {
  REFILL_MACHINE: "refill_machine",
  GENERAL_WITHDRAW: "general_withdraw",
  WITHDRAW_FOR_REPACKAGE: "withdraw_for_repackage",
  ADJUST_STOCK: "adjust_stock",
  SELL_TO_OPERATOR: "sell_to_operator",
  DAMAGE_GOODS: "damage_goods",
  EXPIRED: "expired",
} as const;

const MOVE_INVENTORY_TRANSLATIONS = {
  [MOVE_INVENTORY_REASONS.REFILL_MACHINE]: "label_refill_machine",
  [MOVE_INVENTORY_REASONS.GENERAL_WITHDRAW]: "label_general_withdraw",
  [MOVE_INVENTORY_REASONS.WITHDRAW_FOR_REPACKAGE]: "label_withdraw_package",
  [MOVE_INVENTORY_REASONS.ADJUST_STOCK]: "label_adjust_stock",
  [MOVE_INVENTORY_REASONS.SELL_TO_OPERATOR]: "label_sell_to_operator",
  [MOVE_INVENTORY_REASONS.DAMAGE_GOODS]: "label_damage_goods",
  [MOVE_INVENTORY_REASONS.EXPIRED]: "label_expired",
} as const;

export type MoveInventoryReason =
  typeof MOVE_INVENTORY_REASONS[keyof typeof MOVE_INVENTORY_REASONS];

export function getMoveInventoryReasonTranslation(
  key: MoveInventoryReason | (string & {})
): undefined | string {
  return MOVE_INVENTORY_TRANSLATIONS[key];
}

export type MoveInventoryRequest = {
  warehouseId: string;
  justification: MoveInventoryReason;
  payload: Array<{ lotId: string; quantity: number }>;
};

async function moveInventoryFromWarehouse(data: MoveInventoryRequest) {
  return await httpClient.put("/lots/withdraw", data);
}

export function useMoveInventoryFromWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, MoveInventoryRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: moveInventoryFromWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, { warehouseId }] = args;

      queryClient.invalidateQueries(
        warehouseProductKeys.warehouseProducts(warehouseId)
      );
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

export type MoveInventoryToWarehouseRequest = {
  fromWarehouseId: string;
  toWarehouseId: string;
  productsToTransfer: Array<{ lotId: string; quantity: number }>;
  // type: "move_to_warehouse";
  notes: string;
};

async function moveInventoryToWarehouse(data: MoveInventoryToWarehouseRequest) {
  return await httpClient.post("/lottransfer", {
    ...data,
    type: "move_to_warehouse",
  });
}

export function useMoveInventoryToWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<
    unknown,
    unknown,
    MoveInventoryToWarehouseRequest,
    unknown
  >,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: moveInventoryToWarehouse,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, { fromWarehouseId, toWarehouseId }] = args;
      queryClient.invalidateQueries(
        warehouseProductKeys.warehouseProducts(fromWarehouseId)
      );
      queryClient.invalidateQueries(
        warehouseLotTransfersKeys.warehouseLotTransfers(toWarehouseId)
      );
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

type RejectInventoryTransferRequest = {
  id: string;
};
export async function rejectInventoryTransfer({
  id,
}: RejectInventoryTransferRequest): Promise<void> {
  return await httpClient.post("/lottransfer/reject", {
    lotTransactionId: id,
  });
}

export function useRejectInventoryTransfer({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, RejectInventoryTransferRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectInventoryTransfer,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, { id }] = args;
      const lotTransfer = getCachedLotTransferByTransferId(id);
      if (lotTransfer) {
        const { fromWarehouseId, toWarehouseId } = lotTransfer;
        queryClient.invalidateQueries(
          warehouseLotTransfersKeys.warehouseLotTransfers(toWarehouseId)
        );
        queryClient.invalidateQueries(
          warehouseProductKeys.warehouseProducts(fromWarehouseId)
        );
      }

      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

type AcceptInventoryTransferRequest = {
  id: string;
};
export async function acceptInventoryTransfer({
  id,
}: AcceptInventoryTransferRequest): Promise<void> {
  return await httpClient.post("/lottransfer/accept", {
    lotTransactionId: id,
  });
}
export function useAcceptInventoryTransfer({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, AcceptInventoryTransferRequest, unknown>,
  "mutationFn"
> = {}) {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: acceptInventoryTransfer,
    onSuccess: (...args) => {
      // eslint-disable-next-line no-unused-vars
      const [_, { id }] = args;
      const lotTransfer = getCachedLotTransferByTransferId(id);
      if (lotTransfer) {
        const { fromWarehouseId, toWarehouseId } = lotTransfer;
        queryClient.invalidateQueries(
          warehouseLotTransfersKeys.warehouseLotTransfers(toWarehouseId)
        );
        queryClient.invalidateQueries(
          warehouseProductKeys.warehouseProducts(fromWarehouseId)
        );
        queryClient.invalidateQueries(
          warehouseProductKeys.warehouseProducts(toWarehouseId)
        );
      }

      onSuccess?.(...args);

      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { OverviewLayout } from "./views/monitor";
import Cash from "./views/cash/Cash";
import Map from "./views/map/Map";
import StandbyMachine from "./views/standbyMachine/StandbyMachine";
import ViewNav, { IViewNavOption } from "common/components/viewnav/ViewNav";
import { useTranslation } from "react-i18next";

type NavigationOptionsType = IViewNavOption & {
  label: string;
  show?: boolean;
};

const NavigationOptions: NavigationOptionsType[] = [
  {
    icon: "Map",
    to: "/machines/map",
    label: "Map",
    show: true,
  },
  {
    icon: "MachineCash",
    to: "/machines/cash",
    label: "cash_page.title",
    show: true,
  },
  {
    icon: "Speedometer",
    to: "/machines/monitor/overview",
    label: "overview_page.title",
    rootPath: "/machines/monitor",
    show: true,
  },
  {
    icon: "Speedometer",
    to: "/machines/monitor/snapshot",
    label: "snapshot_page.title",
    show: false,
  },
  {
    icon: "StandbyMachine",
    to: "/machines/standbymachine",
    label: "Standby Machine",
    show: true,
  },
];

export function MachineV2() {
  const { t } = useTranslation("machine");
  const location = useLocation();
  const { path } = useRouteMatch();

  return (
    <div className="flex flex-col flex-1">
      <div className="flex gap-3 mb-4">
        <h1 className="flex-1 font-kanit">
          {t(
            NavigationOptions.find((i) => location.pathname.includes(i.to))
              ?.label || ""
          )}
        </h1>
        <ViewNav options={NavigationOptions.filter(option => option.show)} />
      </div>
      <div className="flex flex-col flex-1">
        <Switch>
          <Route path={`${path}/monitor`}>
            <OverviewLayout />
          </Route>
          <Route exact path="/machines/map" component={Map} />
          <Route exact path="/machines/cash" component={Cash} />
          <Route exact path="/machines/standbymachine">
            <StandbyMachine />
          </Route>
          <Redirect to={`${path}/monitor`} />
        </Switch>
      </div>
    </div>
  );
}

import { httpClient } from 'common/services/transportService';

type CSVRequest = {
  data: string;
  type: 'csv';
};

type BatchRefillZoneJSONRequest = {
  type: 'json';
  data: Array<{ Location: string; refillZone: string }>;
};
type BatchServiceZoneJSONRequest = {
  type: 'json';
  data: Array<{ Location: string; ServiceZone: string }>;
};

export async function batchUpdateRefillZone(
  body: CSVRequest | BatchRefillZoneJSONRequest
) {
  return httpClient.post('/location/updateRefillZone', body);
}

export async function batchUpdateServiceZone(
  body: CSVRequest | BatchServiceZoneJSONRequest
) {
  return httpClient.post('/location/updateServiceZone', body);
}

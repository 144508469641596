import * as React from 'react';

import Select from 'common/components/select/Select';
import Typography from 'common/components/typography/Typography';

import * as styles from './DropdownCell.module.scss';
import { getRoleTranslation, isAdminContractRole, isAdminRole } from "utils/user-role";

export function DropdownCell({
  onChangeRole,
  options,
  currentUserId,
}: IDropdownCellProps) {
  return function Component(rowData: IUserData): JSX.Element {
    // work around for disable admin to change their own role
    if (rowData.id === currentUserId && (isAdminRole(rowData.role) || isAdminContractRole(rowData.role))) {
      return (
        <>
          <div className={styles.nonDropdown}>
            <div className={styles.input}>
              <Typography
                translationKey={getRoleTranslation(rowData.role)}
                type='caption'
                className={styles.label}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.DropdownCell}>
          <Select
            renderStyle='nobox'
            value={rowData.role}
            options={options}
            onSelect={(value) => onChangeRole(value, rowData)}
          />
        </div>
      </>
    );
  };
}

interface IDropdownCellProps {
  onChangeRole: (string, object) => void;
  options: ISelecteOption[];
  currentUserId: string;
}

interface ISelecteOption {
  value: string;
  label: string;
}

interface IUserData {
  id: string;
  role: string;
  name: string;
}

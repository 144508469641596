// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spin--7piKSDYVll{animation:Spin--7piKSDYVll 1.25s infinite linear}@keyframes Spin--7piKSDYVll{0%{transform:rotate(360deg)}100%{transform:rotate(0deg)}}", ""]);
// Exports
exports.locals = {
	"Spin": "Spin--7piKSDYVll"
};
module.exports = exports;

import * as React from "react";

import { useTranslation } from "react-i18next";

import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import { Moment } from "moment";
import Icon from "common/components/icon/Icon";
import TextField from "components/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { createTheme, TextFieldProps, ThemeProvider } from "@mui/material";
import * as colors from "common/styles/colors.scss";

interface MyDateTimePickerProps<TDate = unknown>
  extends Omit<DateTimePickerProps<TDate>, "renderInput"> {
  required?: boolean;
  label: string;
}

const theme = createTheme({
  palette: {
    primary: {
      "500": colors.primary500,
    },
  },
});

export default function DateTimePicker({
  label,
  required = false,
  ...props
}: MyDateTimePickerProps<Moment>) {
  const { t } = useTranslation();

  const MemoizedTextField = React.useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (props: TextFieldProps) => (
      <TextField required={required} {...props} />
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MUIDateTimePicker
          label={t(label)}
          slots={{
            openPickerIcon: function CalendarIcon() {
              return <Icon name="Calendar" color="primary" />;
            },
            textField: MemoizedTextField,
          }}
          {...props}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

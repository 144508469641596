import { useQuery } from 'react-query';
import { useState } from 'react';

import { APIDashboardResponse } from 'dashboard/interface';
import { dashboardKeys } from './dashboardKeys';
import { getDashboard } from 'dashboard/service';

import i18n from 'common/i18n';

import { AxiosError } from 'axios';

function useGetDashboard ({ enabled = true } = {}) {
  const [ isPolling, setIsPolling ] = useState(true);
  return useQuery<APIDashboardResponse, AxiosError>(
    dashboardKeys.all({ lang: i18n.language }),
    () =>
      getDashboard({
        lang: i18n.language as 'en' | 'th'
      }),
    {
      refetchInterval: isPolling ? 60 * 1000 : undefined,
      retry: 0,
      enabled: enabled,
      onError: () => setIsPolling(false)
    }
  );
}

export { useGetDashboard };

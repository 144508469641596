// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Paper--1hl2N-66pd{--modal-padding: 18px}.FormContainer--HjUyRrlMnT{--form-gap: 18px;display:flex;flex-direction:column;margin:0px calc(-1*var(--modal-padding));padding:var(--form-gap) var(--modal-padding);overflow:auto;gap:var(--form-gap)}.TwoFormFieldsContainer--14RXLpTp1Y{display:flex;gap:var(--form-gap)}.TwoFormFieldsContainer--14RXLpTp1Y>*{flex:1 1 0;min-width:0}.Divider--3sA7h9DMzl{margin:0px calc(-1*var(--modal-padding));border-top:1px solid rgba(0,0,0,.12)}.ButtonsContainer--1lnfBcfg3K{padding-top:var(--modal-padding);display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Paper": "Paper--1hl2N-66pd",
	"FormContainer": "FormContainer--HjUyRrlMnT",
	"TwoFormFieldsContainer": "TwoFormFieldsContainer--14RXLpTp1Y",
	"Divider": "Divider--3sA7h9DMzl",
	"ButtonsContainer": "ButtonsContainer--1lnfBcfg3K"
};
module.exports = exports;

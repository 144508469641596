import * as React from 'react';

interface IDownloadTrayProps {
  color?: string,
  className?: string
}

function DownloadTray ({
  color = 'white',
  className
}: IDownloadTrayProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16.2929 9.29289L13 12.5858V2H11V12.5858L7.70711 9.29289L6.29289 10.7071L12 16.4142L17.7071 10.7071L16.2929 9.29289ZM22 20V16H20V20H4V16H2V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z' fill={color} />
    </svg>
  );
}

export default DownloadTray;

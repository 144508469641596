// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HoverHightlight--mbv6z5900e{transition:background-color .1s ease-in 0s,transform .1s ease-in 0s;transform:scale(1)}.HoverHightlight--mbv6z5900e:hover{background-color:#f3f3fd;transition:background-color .2s ease-out 0s,transform .2s ease-out 0s;transform:scale(1.03)}", ""]);
// Exports
exports.locals = {
	"HoverHightlight": "HoverHightlight--mbv6z5900e"
};
module.exports = exports;

import * as React from "react";
import { useState, useEffect } from "react";
import * as queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as moment from "moment";
import {
  FocusedInputShape,
  DateRangePicker as RDDateRangePicker,
} from "react-dates";
import {
  START_DATE,
  END_DATE,
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
} from "react-dates/constants";

import * as styles from "./DateRangePicker.module.scss";

interface IDates {
  startDate: moment.Moment;
  endDate: moment.Moment;
}
interface IDateRangePickerProps {
  defaultStartDate?: number;
  maxPastDays?: number;
  maxFutureDays?: number;
  onDateChange?: ({ startDate, endDate }: IDates) => void;
  className?: string;
}

function DateRangePicker({
  defaultStartDate = 30,
  maxPastDays = 365,
  maxFutureDays = 0,
  onDateChange,
  className,
}: IDateRangePickerProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const [_startDate, setStartDate] = useState<moment.Moment>(null);
  const [_endDate, setEndDate] = useState<moment.Moment>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  function getQueryDates(): void {
    const _searchMap = queryString.parse(location.search);

    const startDate = _searchMap["time[gte]"]
      ? moment(Number(_searchMap["time[gte]"]))
      : moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .subtract(defaultStartDate, "days");

    const endDate = _searchMap["time[lte]"]
      ? moment(Number(_searchMap["time[lte]"]))
      : moment().set({ hour: 23, minute: 59, second: 59 });

    setStartDate(startDate);
    setEndDate(endDate);

    if (!_searchMap["time[gte]"] || !_searchMap["time[lte]"]) {
      handleDateChange({ startDate, endDate });
    }
  }

  useEffect(() => {
    getQueryDates();
  }, [location.search]);

  function pushQueryToUrl(startDate: number, endDate: number): void {
    const existingSearchMap = queryString.parse(location.search);
    const search = queryString.stringify(
      {
        ...existingSearchMap,
        "time[gte]": startDate,
        "time[lte]": endDate,
      },
      { skipNull: true }
    );
    history.push({ search });
  }

  function handleDateChange({ startDate, endDate }: IDates): void {
    if (startDate && endDate) {
      pushQueryToUrl(
        startDate.set({ hour: 0, minute: 0, second: 0 }).valueOf(),
        endDate.set({ hour: 23, minute: 59, second: 59 }).valueOf()
      );
      if (onDateChange) {
        onDateChange({ startDate, endDate });
      }
    }
  }

  return (
    <div className={[styles.DateRangePickerWrapper, className].join(" ")}>
      <RDDateRangePicker
        startDate={_startDate}
        startDateId={START_DATE}
        endDate={_endDate}
        endDateId={END_DATE}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={() => moment().subtract(1, "month")}
        isOutsideRange={(day) =>
          day.isBefore(
            moment()
              .set({ hour: 0, minute: 0, second: 0 })
              .subtract(maxPastDays, "days")
          ) ||
          day.isAfter(
            moment()
              .set({ hour: 23, minute: 59, second: 59 })
              .add(maxFutureDays, "days")
          )
        }
        customArrowIcon={<span>-</span>}
        horizontalMargin={20}
        orientation={isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
        minimumNights={0}
        noBorder
        readOnly
      />
    </div>
  );
}

export default DateRangePicker;

import * as React from "react";

export function WarehousingWorkspacePaddingContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div style={{ padding: "0 40px" }}>{children}</div>;
}

export const warehouseHorizontalPadding = "40px";

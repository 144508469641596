import {
  InventoryStatusString,
  isValidRefillPriority,
  RefillPriorityString,
} from "../../../Components";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import { Location, RouteModalData } from "./types";
import {
  CreateRefillOrderCandidatesMutationVariables,
  GetMachinesAndOrdersInRefillZoneQuery,
  MachineKind,
  MachineRefillOverviewInput,
} from "gql/generated";
import * as moment from "moment";
import { computeInventoryStatus } from "../../utils";
import { ArrayElement } from "types/utils";

export type CustomMachineType = Omit<Extract<GetMachinesAndOrdersInRefillZoneQuery["refillZone"]["locations"][0], { __typename: "Location" }>, "__typename"> & {
  __typename: "Store" | "Location";
}

export function mapIncomingDataToRouteModalData(
  getMachinesAndOrdersInRefillZoneQuery: GetMachinesAndOrdersInRefillZoneQuery
): RouteModalData {
  const refillZone = getMachinesAndOrdersInRefillZoneQuery.refillZone;
  const machineActiveRefillOrderMap = Object.fromEntries(
    refillZone.refillOrders.map((refillOrder) => [refillOrder.machineId, true])
  );
  const recommendedInventoryMap: {
    [machineId: string]: { [machineInventoryId: string]: true };
  } = Object.fromEntries(
    refillZone.refillOrderRecommendations.map((refillOrder) => [
      refillOrder.machineId,
      Object.fromEntries(
        refillOrder.recommendedItems.filter((recommendedItem) => recommendedItem.machineInventory).map((recommendedItem) => [
          recommendedItem.machineInventory.id,
          true,
        ])
      ),
    ])
  );

  const swapInventoryMap = refillZone.orphanSwapOrders.reduce(
    (swapInventoryMap, orphanSwapOrder) => {
      const currentEntry = swapInventoryMap[orphanSwapOrder.machineId] ?? {};
      const [swapItem] = orphanSwapOrder.swapItems;

      swapInventoryMap[orphanSwapOrder.machineId] = {
        ...currentEntry,
        [swapItem.machineInventoryId]: swapItem.product,
      };

      return swapInventoryMap;
    },
    {} as Record<
    string,
    | Record<
        string,
        | ArrayElement<
            ArrayElement<
              GetMachinesAndOrdersInRefillZoneQuery["refillZone"]["orphanSwapOrders"]
            >["swapItems"]
          >['product']
        | undefined
      >
    | undefined
  > 
  );

  const operableLocations = refillZone.locations
    .filter((location) => {
      if (location.__typename === "Location") {
        return Boolean(location.machine);
      } 
      
      if (location.__typename === "Store") {
        return Boolean(location.machines.length > 0);
      }

      return false;
    })
    .reduce<CustomMachineType[]>((prev, curr) => {
      if (curr.__typename === "Location") {
        prev.push(curr);
      } 
    
      if (curr.__typename === "Store") {
        curr.machines.forEach((machine) => prev.push({ ...curr, machine }));
      }

      return prev;
    }, []);

  const locations: Location[] = operableLocations
    .filter((location) => location.__typename === "Location" || location.__typename === "Store")
    .map((location) => ({
      locationName: location.locationName,
      locationNote: location.operationNote,
      revenue: location.revenueSinceLastRefilled,
      routePosition: location.positionInRoute,
      machine: {
        machineId: location.machine.id,
        kind: location.machine.kind,
        generation: location.machine.generation,
        parentId: location.machine.parentId,
        recommended: Boolean(recommendedInventoryMap[location.machine.id]),
        enableWorkOrderCreation: location.machine.enableWorkOrderCreation,
        hasRefillCandidates: location.hasRefillCandidate,
        hasRefillOrdersInNotCompletedStatus: location.hasActiveRefill,

        lowSlots: location.machine.id && recommendedInventoryMap[location.machine.id]
          ? Object.values(recommendedInventoryMap[location.machine.id]).length
          : 0,
        activeRefill: Boolean(location.machine.id && machineActiveRefillOrderMap[location.machine.id]),
        cash: {
          isBanksFull: location.machine.cashStatus?.isBankNoteFull ?? false,
          isCoinsFull: location.machine.cashStatus?.isCoinFull ?? false,
        },
        hasSwap: Boolean(swapInventoryMap[location.machine.id]),
        numberOfSwapItems: swapInventoryMap[location.machine.id]
          ? Object.values(swapInventoryMap[location.machine.id]).length
          : 0,
        lastRefilled: location.machine.lastRefilled,
        inventories: location.machine.machineInventories.map(
          (machineInventory) => ({
            autoRefill: machineInventory.autoRefill,
            inventoryId: machineInventory.id,
            levels: {
              capacity: machineInventory.capacity,
              current: machineInventory.value,
              high: machineInventory.refillLevel,
              low: machineInventory.parLevel,
            },
            pendingSwap: machineInventory.hasPendingSwap,
            product: {
              img: machineInventory.product.image,
              name: machineInventory.product.name,
              sku: machineInventory.product.SKU,
              uom: machineInventory.product.uom,
            },
            recommended: recommendedInventoryMap[location.machine.id]
              ? Boolean(
                  recommendedInventoryMap[location.machine.id][
                    machineInventory.id
                  ]
                )
              : false,
            slot: machineInventory.slot,
            status: computeInventoryStatus(machineInventory.status),
            swappedProduct: !swapInventoryMap[location.machine.id]
              ? null
              : swapInventoryMap[location.machine.id][machineInventory.id]
              ? {
                  img: swapInventoryMap[location.machine.id][
                    machineInventory.id
                  ].image,
                  name: swapInventoryMap[location.machine.id][
                    machineInventory.id
                  ].name,
                  sku: swapInventoryMap[location.machine.id][
                    machineInventory.id
                  ].SKU,
                  uom: swapInventoryMap[location.machine.id][
                    machineInventory.id
                  ].uom,
                }
              : null,
            })
          ),

          // TODO: CONFUSION
          recentErrorCode: location.machine?.latestAlarm?.code,
          refillPriority: isValidRefillPriority(location.refillPriority)
            ? location.refillPriority
            : null,
          // TODO: MISSING
          recentTickets: [],
          refillOverview: location.machine?.refillOverview
            ? (() => {
              const { __typename, ...refillOverview } = location.machine.refillOverview;
              return {
                ...refillOverview,
                generation: location.machine.generation ?? "Unknown",
                lastRefilled: refillOverview.lastRefilled ?? null
              };
            })()
            : null
      }
    }));

  return {
    refillZone: refillZone.friendlyId,
    locations,
  };
}
export function mapStateAndIncomingDataToOutgoingData(
  selectionState: SelectionState,
  getMachinesAndOrdersInRefillZoneQuery: GetMachinesAndOrdersInRefillZoneQuery
): CreateRefillOrderCandidatesMutationVariables {
  return {
    refillOrderCandidates: Object.entries(selectionState)
      .filter(([_, machine]) => machine.refillSelected)
      .map(([machineId, machine]) => {
        const refillOrderRecommendationId =
          getMachinesAndOrdersInRefillZoneQuery.refillZone.refillOrderRecommendations.find(
            (ror) => ror.machineId === machineId
          )?.id;

        const operableLocations = getMachinesAndOrdersInRefillZoneQuery.refillZone.locations.reduce((prev, curr) => {
          if (curr.__typename === "Location") {
            prev.push(curr);
          }

          if (curr.__typename === "Store") {
            curr.machines.forEach((machine) => prev.push({ ...curr, machine }));
          }

          return prev;
        }, []);

        const locationId = operableLocations.find((location) => location.machine?.id === machineId)?.id;

        return {
          isCritical: machine.critical,
          criticalNote: machine.criticalNote,
          note: machine.locationNote,
          machineId,
          locationId,
          includeSwapOrder: Boolean(machine.swapSelected),
          ...(refillOrderRecommendationId
            ? { refillOrderRecommendationId }
            : {}),
        };
      }),
      refillRecommendations: getMachinesAndOrdersInRefillZoneQuery.refillZone.locations.reduce<Array<MachineRefillOverviewInput>>((prev, curr) => {
        const isLocation = curr.__typename === "Location";
        const isStore = curr.__typename === "Store";

        if (isLocation && curr.machine?.refillOverview) {
          prev.push({
            ...curr.machine.refillOverview,
            machineId: curr.machine.id,
            machineGeneration: curr.machine.generation
          });
        }

        if (isStore) {
          const refillRecommendations = curr.machines
            .filter((machine) => machine?.refillOverview)
            .map((machine) => ({
              ...machine.refillOverview,
              machineId: machine.id,
              machineGeneration: machine.generation
            }));
      
          prev.push(...refillRecommendations);
        }

        return prev;
      }, [])
  };
}

type SelectionState = {
  [machineId: string]:
    | {
        refillSelected: boolean;
        /** null use to indicate machine with no swap orders */
        swapSelected: boolean | null;
        locationNote: string;
        critical: boolean;
        criticalNote: string;
        enableWorkOrderCreation: boolean;
        isSuggestedSelection: boolean
      }
    | undefined;
};

export function countNumberOfSelectedMachines(selectionState: SelectionState) {
  return Object.values(selectionState).filter(
    (machine) => machine.refillSelected
  ).length;
}

export function initializeSelectionState(
  locations: Location[]
): SelectionState {
  const stateArrayEntries = locations.map((location) => {
    const machine = location.machine;
    const machineId = machine.machineId;
    const isMachineRecommendedForRefill = Boolean(machine.refillOverview?.isSuggestedSelection);

    const neverSelect =
      !machine.enableWorkOrderCreation ||
      machine.hasRefillCandidates ||
      machine.hasRefillOrdersInNotCompletedStatus;

    const machineState: SelectionState[string] = {
      refillSelected: neverSelect ? false : isMachineRecommendedForRefill,
      swapSelected: neverSelect
        ? false
        : machine.hasSwap
        ? false
        : null,
      locationNote: location.locationNote === null ? "" : location.locationNote,
      critical: false,
      criticalNote: "",
      enableWorkOrderCreation: machine.enableWorkOrderCreation,
      isSuggestedSelection: !!machine.refillOverview?.isSuggestedSelection
    };

    return [machineId, machineState];
  });
  return Object.fromEntries(stateArrayEntries);
}

export function filterLocationsWithMachineAndSortByRoutePosition(
  locations: Location[]
) {
  return locations
    .filter((location) => location.machine)
    .sort(
      (locationA, locationB) =>
        locationA.routePosition - locationB.routePosition
    );
}

export function toggleCriticalForMachine(
  selectionState: SelectionState,
  machineId: string
): void {
  selectionState[machineId].critical = !selectionState[machineId].critical;
}

export function handleCriticalNoteChangeForMachine(
  selectionState: SelectionState,
  machineId: string,
  newCriticalNote: string
): void {
  selectionState[machineId].criticalNote = newCriticalNote;
}

export function handleLocationNoteChangeForMachine(
  selectionState: SelectionState,
  machineId: string,
  newLocationNote: string
): void {
  selectionState[machineId].locationNote = newLocationNote;
}

export function toggleRefillOrderSelectionForMachine(
  selectionState: SelectionState,
  machineId: string,
  value?: boolean // use to overwrite value
): void {
  const isSelected = value !== undefined ? value : !selectionState[machineId].refillSelected;
  
  selectionState[machineId].refillSelected = isSelected;

  if (selectionState[machineId].swapSelected !== null) {
    selectionState[machineId].swapSelected = isSelected;
  }
}

export function toggleSwapOrderSelectionForMachine(
  selectionState: SelectionState,
  machineId: string,
  value?: boolean // use to overwrite value
): void {
  selectionState[machineId].swapSelected = value !== undefined ? value: !selectionState[machineId].swapSelected;
}

export function removeAllSelection(selectionState: SelectionState): void {
  Object.values(selectionState).forEach((machine) => {
    machine.refillSelected = false;
    if (machine.swapSelected !== null) {
      machine.swapSelected = false;
    }
  });
}

export function selectAllMachines(selectionState: SelectionState): void {
  Object.values(selectionState)
    .filter((machine) => machine.enableWorkOrderCreation)
    .forEach((machine) => {
      machine.refillSelected = true;
      if (machine.swapSelected !== null) {
        machine.swapSelected = true;
      }
    });
}

export function selectMachines(selectionState: SelectionState, machineIds: string[]): void {
  Object.entries(selectionState)
  .filter(([ machineId, machine ]) => machine.enableWorkOrderCreation && machineIds.includes(machineId))
  .forEach(([ _, machine]) => {
    machine.refillSelected = true;
    if (machine.swapSelected !== null) {
      machine.swapSelected = true;
    }
  });
}

export function computeSelectionStatus(
  selectionState: SelectionState
): "NONE" | "PARTIAL" | "ALL" {
  function hasSwap(machineState: SelectionState[string]) {
    return machineState.swapSelected !== null;
  }

  return Object.values(selectionState)
    .filter(machine => machine.enableWorkOrderCreation)
    .every((machine) =>
      hasSwap(machine)
        ? !machine.refillSelected && !machine.swapSelected
        : !machine.refillSelected
    )
      ? "NONE"
      : Object.values(selectionState)
          .filter(machine => machine.enableWorkOrderCreation)
          .every((machine) =>
            hasSwap(machine)
              ? machine.refillSelected && machine.swapSelected
              : machine.refillSelected
          )
        ? "ALL"
        : "PARTIAL";
}

export type MachineToView = {
  machineId: string;
  type: "SWAP" | "REFILL";
} | null;

export function computeRouteModalAggregates(routeModalData: RouteModalData): {
  numberOfMachines: number;
  numberOfRecommendedMachines: number;
} {
  const locations = routeModalData.locations;
  return locations.reduce(
    (aggregate, location) => {
      const machine = location.machine;
      if (!machine) {
        return aggregate;
      }
      aggregate.numberOfMachines++;
      if (machine.recommended) {
        aggregate.numberOfRecommendedMachines++;
      }
      return aggregate;
    },
    {
      numberOfMachines: 0,
      numberOfRecommendedMachines: 0,
    }
  );
}

type OverviewRowType = "REFILL" | "SWAP";

export interface OverviewTableRow {
  type: OverviewRowType;
  typeDisplay: string;
  recommended: boolean;
  activeRefill: boolean;
  routePosition: number;
  machineId: string;
  kind: MachineKind;
  parentId: string;
  refillPriority: RefillPriorityString | null;
  locationName: string;
  lastRefill: string | null;
  revenue: number;
  recentErrorCode: number | null;
  lowSlots: number;
  handleRowClick: () => void;
  handleCheckboxClick: () => void;
  checkboxSelected: boolean;
  checkboxDisabled: boolean;
  hasRefillOrdersNotInCompleted: boolean;
  hasRefillCandidates: boolean;
  enableWorkOrderCreation: boolean;
  refillOverview: RefillOverview | null;
}

interface RefillOverview {
  isSuggestedSelection: boolean
  suggestedOrder: number
}

interface OverviewFnArg {
  machineId: string;
  rowType: OverviewRowType;
}

export function generateOverviewRows({
  locations,
  isCheckboxSelected,
  isCheckboxDisabled,
  handleRowClick,
  handleCheckboxClick,
}: {
  locations: Location[];
  handleRowClick: (arg: OverviewFnArg) => void;
  handleCheckboxClick: (arg: OverviewFnArg) => void;
  isCheckboxSelected: (arg: OverviewFnArg) => boolean;
  isCheckboxDisabled: (arg: OverviewFnArg) => boolean;
}): OverviewTableRow[] {
  return locations
    .map((location) => {
      const machine = location.machine;
      const machineId = machine.machineId;
      const commonMachineData: Pick<
        OverviewTableRow,
        | "activeRefill"
        | "routePosition"
        | "revenue"
        | "lastRefill"
        | "locationName"
        | "lowSlots"
        | "machineId"
        | "recentErrorCode"
        | "recommended"
        | "refillPriority"
        | "hasRefillCandidates"
        | "hasRefillOrdersNotInCompleted"
        | "enableWorkOrderCreation"
        | "refillOverview"
      > = {
        recommended: machine.recommended,
        activeRefill: machine.activeRefill,
        routePosition: location.routePosition,
        machineId: machineId,
        refillPriority: machine.refillPriority,
        locationName: location.locationName,
        lastRefill: machine.lastRefilled,
        revenue: location.revenue,
        recentErrorCode: machine.recentErrorCode,
        lowSlots: machine.lowSlots,
        hasRefillCandidates: machine.hasRefillCandidates,
        hasRefillOrdersNotInCompleted:
          machine.hasRefillOrdersInNotCompletedStatus,
        enableWorkOrderCreation: machine.enableWorkOrderCreation,
        refillOverview: machine.refillOverview
      };

      const refillRow: OverviewTableRow = {
        type: "REFILL",
        typeDisplay: "Refill",
        kind: location.machine.kind,
        parentId: location?.machine?.parentId,
        checkboxDisabled: !machine.enableWorkOrderCreation || isCheckboxDisabled({ machineId, rowType: "REFILL" }),
        checkboxSelected: isCheckboxSelected({ machineId, rowType: "REFILL" }),
        handleRowClick: () => handleRowClick({ machineId, rowType: "REFILL" }),
        handleCheckboxClick: () =>
          handleCheckboxClick({ machineId, rowType: "REFILL" }),
        ...commonMachineData,
      };
      const machineHasOrphanSwapOrder = machine.hasSwap;
      if (!machineHasOrphanSwapOrder) {
        return refillRow;
      }

      const swapRow: OverviewTableRow = {
        type: "SWAP",
        kind: location.machine.kind,
        parentId: location?.machine?.parentId,
        typeDisplay: `Swap (${machine.numberOfSwapItems})`,
        checkboxDisabled: !machine.enableWorkOrderCreation || isCheckboxDisabled({ machineId, rowType: "SWAP" }),
        checkboxSelected: isCheckboxSelected({ machineId, rowType: "SWAP" }),
        handleRowClick: () => handleRowClick({ machineId, rowType: "SWAP" }),
        handleCheckboxClick: () =>
          handleCheckboxClick({ machineId, rowType: "SWAP" }),
        ...commonMachineData,
      };
      return [refillRow, swapRow];
    })
    .flat(1);
}

interface MachineView {
  id: string;
  kind: MachineKind;
  parentId: string;
  locationName: string;
  errorDetails: Array<{ createdAt: string; description: string }>;
  cashStatus: {
    isBankFull: boolean;
    isCoinFull: boolean;
  };
  refillCandidateMetaData: {
    critical: boolean;
    criticalNote: string;
    locationNote: string;
    toggleCritical: () => void;
    handleCriticalNoteChange: (newValue: string) => void;
    handleLocationNoteChange: (newValue: string) => void;
  };
  inventories: MachineViewInventory[];
}
interface MachineViewInventory {
  slot: string;
  recommended: boolean;
  swappedProduct: MachineViewInventoryProduct | null;
  inventoryStatus: InventoryStatusString | null;
  pendingSwap: boolean;
  autoRefill: boolean;
  levels: {
    current: number;
    capacity: number;
    low: number;
    high: number;
  };
  product: MachineViewInventoryProduct;
}

interface MachineViewInventoryProduct {
  img: string;
  name: string;
  uom: string;
  sku: string;
}

export function generateMachineViewMap({
  machineId,
  locations,
  isCritical,
  toggleCritical,
  getLocationNote,
  getCriticalNote,
  handleCriticalNoteChange,
  handleLocationNoteChange,
}: {
  machineId?: string;
  locations: Location[];
  isCritical: (machineId: string) => boolean;
  toggleCritical: (machineId: string) => void;
  getLocationNote: (machineId: string) => string;
  getCriticalNote: (machineId: string) => string;
  handleCriticalNoteChange: (machineId: string, newValue: string) => void;
  handleLocationNoteChange: (machineId: string, newValue: string) => void;
}): MachineView | null {
  if(!machineId) return null;
  const location = locations.find(location => location.machine.machineId === machineId);
  
  if(location) {
    const machine = location.machine;
    const machineId = machine.machineId;
    const inventories: MachineViewInventory[] = machine.inventories.map(
      (inventory) => ({
        autoRefill: inventory.autoRefill,
        inventoryStatus: inventory.status,
        levels: {
          capacity: inventory.levels.capacity,
          current: inventory.levels.current,
          high: inventory.levels.high,
          low: inventory.levels.low,
        },
        pendingSwap: inventory.pendingSwap,
        product: {
          img: inventory.product.img,
          name: inventory.product.name,
          sku: inventory.product.sku,
          uom: inventory.product.uom,
        },
        recommended: inventory.recommended,
        slot: inventory.slot,
        swappedProduct: inventory.swappedProduct
          ? {
              img: inventory.swappedProduct.img,
              name: inventory.swappedProduct.name,
              sku: inventory.swappedProduct.sku,
              uom: inventory.swappedProduct.uom,
            }
          : null,
      })
    );
    const sortedInventoriesBySlotName = [...inventories].sort(
      (inventoryA, inventoryB) => alphanumeric(inventoryA.slot, inventoryB.slot)
    );

    const machineView: MachineView = {
      cashStatus: {
        isBankFull: machine.cash.isBanksFull,
        isCoinFull: machine.cash.isCoinsFull,
      },
      kind: machine.kind,
      parentId: machine.parentId,
      errorDetails: machine.recentTickets.map((ticket) => ({
        createdAt: moment(Number(ticket.createdAt)).format("DD/MM/YY H:mm"),
        description: ticket.detail,
      })),
      id: machineId,
      locationName: location.locationName,
      inventories: sortedInventoriesBySlotName,
      refillCandidateMetaData: {
        critical: isCritical(machineId),
        criticalNote: getCriticalNote(machineId),
        handleCriticalNoteChange: (newValue: string) =>
          handleCriticalNoteChange(machineId, newValue),
        handleLocationNoteChange: (newValue: string) =>
          handleLocationNoteChange(machineId, newValue),
        locationNote: getLocationNote(machineId),
        toggleCritical: () => toggleCritical(machineId),
      },
    };

    return machineView;
  }

  return null;
}

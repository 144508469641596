import * as React from "react";

import Icon from "common/components/icon/Icon";
import Button from "components/Button";
import Typography from "components/Typography";
import { Modal } from "@mui/material";
import { LoadingScreen, ModalFallback } from "../../../../Components";
import { ErrorBoundary } from "react-error-boundary";
import { useCompletedModalState } from "./useCompletedModalState";
import {
  getPackageProductId,
} from "../utils";
import * as styles from "./CompletedModal.module.scss";
import { CompletedPlan, PackageWithAdjustedInfo } from "../types";
import { useEditAdjustedReturnItem } from "./useEditAdjustedReturnItem";
import { CompletedPlanDataFetcher } from "./CompletedPlanDataFetcher";
import {
  isReturnQuantityAbnormal,
} from "../components/ReturnLessThanExpected";
import { TableRow } from "../../Complete";
import { AccordionWithStickyHeader } from "../components/AccordionWithStickyHeader";
import { useTranslation } from "react-i18next";
import { TaobinInventoryListHeader, TaobinInventoryListView } from "./TaobinInventoryList";
import { SpiralInventoryListHeader, SpiralInventoryListView } from "./SpiralInventoryList";

export function CompletedModal({
  onClose,
  planId,
  planData,
}: {
  onClose: () => void;
  planId: string;
  planData: TableRow;
}) {
  return (
    <Modal open={true}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <ModalFallback
                error={error}
                metaData={`operations/refillorder/completed | ID: ${planId}`}
                onClose={onClose}
              />
            )}
          >
            <CompletedPlanDataFetcher planId={planId}>
              {({ isLoading, data }) =>
                isLoading ? (
                  <LoadingScreen />
                ) : (
                  <CompletedModalForm
                    onClose={onClose}
                    completedPlan={data}
                    planData={planData}
                  />
                )
              }
            </CompletedPlanDataFetcher>
          </ErrorBoundary>
        </div>
      </div>
    </Modal>
  );
}

export interface CompletedModalFormProps {
  onClose: () => void;
  completedPlan: CompletedPlan;
  planData: TableRow;
}

function CompletedModalForm({
  planData,
  onClose,
  completedPlan,
}: CompletedModalFormProps) {
  const { t } = useTranslation();
  const completedModalState = useCompletedModalState();

  const editAdjustedReturnItems = useEditAdjustedReturnItem({
    onSuccess: () => {
      completedModalState.reset();
    },
  });

  function isPackageActualReturnAbnormal(p: PackageWithAdjustedInfo) {
    if (!completedModalState.checkIfEditingProduct(getPackageProductId(p))) {
      return isReturnQuantityAbnormal({
        actualReturnBag: p.adjustedInfo.actualReturnInPackages,
        actualReturnUnit: p.adjustedInfo.actualRemainingInProductUOMInPackage,
        expectedReturn: p.refillInfo.expectedReturnInProductUOMInPackage,
        oldQuantity: p.refillInfo.totalOldQuantityInProductUOMInPackage,
        packageSize: p.packageInfo.productQuantityPerPackage,
        type: p.type,
      });
    }

    return isReturnQuantityAbnormal({
      actualReturnBag: completedModalState.product.actualReturnBag,
      actualReturnUnit: completedModalState.product.actualReturnRemaining,
      expectedReturn: p.refillInfo.expectedReturnInProductUOMInPackage,
      oldQuantity: p.refillInfo.totalOldQuantityInProductUOMInPackage,
      packageSize: p.packageInfo.productQuantityPerPackage,
      type: p.type,
    });
  }

  function submitForm() {
    editAdjustedReturnItems.mutate({
      planId: completedPlan.planId,
      editItem: {
        productId: completedModalState.product.id,
        newActualBag: Number(completedModalState.product.actualReturnBag),
        newActualRemaining: Number(
          completedModalState.product.actualReturnRemaining
        ),
      },
    });
  }

  return (
    <div className={styles.CompletedModalContainer}>
      <div className={styles.Header}>
        <Typography type="headline-6" color="onSurfaceHigh" translate>
          label_order_number
        </Typography>
        <div
          onClick={editAdjustedReturnItems.isLoading ? () => {} : onClose}
          className={styles.CloseButton}
        >
          <Icon name="Close" color="onSurfaceHigh" />
        </div>
      </div>

      <div className={styles.GrowContainer}>
        <div className={styles.ScrollContainer}>
          <div className={styles.MinWidthMinContentContainer}>
            <AccordionWithStickyHeader
              title={t("label_complete_plan_taobin_package_header")}
              subTitle={<TaobinInventoryListHeader />}
              body={
                <TaobinInventoryListView
                  completedPlan={completedPlan}
                  isPackageActualReturnAbnormal={isPackageActualReturnAbnormal}
                  planData={planData}
                  completedModalState={completedModalState}
                />
              }
            />
            <AccordionWithStickyHeader
              title={t("label_complete_plan_spiral_package_header")}
              subTitle={<SpiralInventoryListHeader />}
              body={
                <SpiralInventoryListView
                  completedPlan={completedPlan}
                  isPackageActualReturnAbnormal={isPackageActualReturnAbnormal}
                  planData={planData}
                  completedModalState={completedModalState}
                />
              }
            />
          </div>
        </div>
      </div>

      {completedModalState.isEditing ? (
        <div className={styles.ButtonsContainer}>
          <Button onClick={completedModalState.reset} type="secondary">
            action_cancel
          </Button>
          <Button
            onClick={submitForm}
            disabled={
              !editAdjustedReturnItems.isLoading &&
              completedModalState.checkIfInvalid()
            }
            type="primary"
            loading={editAdjustedReturnItems.isLoading}
          >
            action_save
          </Button>
        </div>
      ) : null}
    </div>
  );
}

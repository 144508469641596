import * as React from "react";

import { Card } from "../atoms/Card";
import { PolarChart } from "../atoms/charts/PolarChart";
import { CardTitle } from "../molecules/CardTitle";
import { RadialChartKey } from "../molecules/RadialChartKey";

import * as styles from "./PolarChartCard.module.scss";

export interface PolarChartCardProps {
  title: string;
  icon: string;
  className?: string;
  data: PolarChartSegment[];
}

interface PolarChartSegment {
  label: string;
  color: string;
  amount: number;
  percent: number;
}

export function PolarChartCard({
  title,
  icon,
  data,
  className = "",
}: PolarChartCardProps): JSX.Element {
  return (
    <Card className={[styles.PolarChartCard, className].join(" ")}>
      <CardTitle icon={icon} title={title} />
      <div className={styles.ChartContainer}>
        <PolarChart data={data} />
        <div className={styles.Legend}>
          {data.map((segment) => (
            <RadialChartKey
              key={segment.label}
              value={(segment.percent*100).toFixed(0)}
              label={segment.label}
              color={segment.color}
            />
          )).slice(0,5)}
        </div>
      </div>
    </Card>
  );
}
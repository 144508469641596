import { patchMachineMaintenancePeriod } from "machines/service";

import { useMutation, useQueryClient } from "react-query";

import { machineKeys } from "./machineKeys";
import { toasti18n } from "utils/toast";

function usePatchMachineMaintainencePeriod() {
  const queryClient = useQueryClient();
  return useMutation(patchMachineMaintenancePeriod, {
    onSuccess: (_, { machineId }) => {
      toasti18n.success("toast_maintenance_post_succes");
      queryClient.invalidateQueries(machineKeys.machineMaintenance(machineId));
    }
  });
}

export { usePatchMachineMaintainencePeriod };
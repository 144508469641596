import * as React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom';


import Route from 'common/components/route/Route';

import Splash from 'containers/splash/Splash';
import Login from 'containers/login/Login';
import SignUp from 'containers/signup/Signup';
import Portal from 'containers/portal/Portal';

import { refreshToken } from 'userandauth/utilsAuth';
import { useAuthStore } from 'userandauth/useAuthStore';

import 'pdf/constants';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as styles from './App.module.scss';
import './reset.css';
import './globals.css';
import { ErrorBoundary } from '@sentry/react';
import { Fallback } from 'components/Fallback';
import analytics from 'utils/analytics';
import { ForgotPassword } from 'containers/forgotPassword';
import { ValidateResetPasswordToken } from 'containers/forgotPassword/validate-reset-password-token';
import { ClientEmotionCacheProvider, ClientStyleContext, EmotionCacheWrapper } from 'components/EmotionProvider';


function App(): JSX.Element {
  const clientStyleData = React.useContext(ClientStyleContext);

  const [appInitialized, setAppInitialized] = useState<boolean>(false);

  async function refreshTokenOnStartUp() {
    try {
      await refreshToken();
    } finally {
      setAppInitialized(true);
    };
  }

  useEffect(() => {
    refreshTokenOnStartUp();
  }, []);

  React.useEffect(() => {
    // reset emotion css on page change
    clientStyleData.reset();
    
  }, [appInitialized]);

  if (!appInitialized) {
    return <Splash />;
  }

  return (
    <ClientEmotionCacheProvider>
      <EmotionCacheWrapper>
        <ErrorBoundary fallback={FallBackWrapper}>
          <div className={styles.App}>
            <React.Suspense fallback={<Splash />}>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/validate-reset-token" component={ValidateResetPasswordToken} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <PrivateRoute>
                    <Route path="/" component={Portal} />
                  </PrivateRoute>
                </Switch>
              </BrowserRouter>
            </React.Suspense>
          </div>
          <ToastContainer
            className={styles.ToastContainer}
            position="top-center"
            theme="colored"
            pauseOnFocusLoss={false}
          />
        </ErrorBoundary>
      </EmotionCacheWrapper>
    </ClientEmotionCacheProvider>
  );
}

export default App;

interface IPrivateRoute {
  children: JSX.Element;
}

function PrivateRoute({ children }: IPrivateRoute): JSX.Element {
  const loggedIn: boolean = useAuthStore((state) => state.isLoggedIn);
  const location = useLocation();

  if (!loggedIn) {
    analytics.track({
      name: "force logout on private route",
      properties: {}
    });
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return <>{children}</>;
}

export function FallBackWrapper({ error }: { error: Error }) {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
      }}
    >
      <Fallback error={error} />
    </div>
  );
}

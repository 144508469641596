import Icon from "common/components/icon/Icon";
import * as React from "react";

import * as styles from "./ScrollContainer.module.scss";

export function ScrollContainer({ children }: { children: React.ReactNode }) {
  const endRef = React.useRef<HTMLDivElement>();
  const [isEnd, setIsEnd] = React.useState(true);
  const scrollContainer = React.useRef<HTMLDivElement>();

  function scrollToEnd() {
    endRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  React.useEffect(function observeEndRef() {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsEnd(true);
        } else {
          setIsEnd(false);
        }
      },
      { threshold: 1, root: scrollContainer.current, rootMargin: "30px" }
    );
    observer.observe(endRef.current);

    return () => {
      if(endRef.current){
        observer.unobserve(endRef.current);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.ScrollContainer}>
      <div
        className={styles.ErrorBoardTableTabsContainer}
        ref={scrollContainer}
      >
        {children}

        <div ref={endRef} />
      </div>
      <button
        onClick={scrollToEnd}
        disabled={isEnd}
        className={styles.ScrollButton}
        style={{
          cursor: isEnd ? "default" : "pointer",
        }}
      >
        <Icon
          style={{
            opacity: isEnd ? 0 : 1,
            transform: isEnd ? "translateX(-5px)" : "translateX(0)",
            transition: "all 0.1s",
            transitionTimingFunction: isEnd ? "ease-in" : "ease-out",
          }}
          name="ChevronsRight"
          color="primary500"
          className={styles.ScrollIcon}
        />
      </button>
    </div>
  );
}

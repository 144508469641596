import * as React from "react";
import Icon from "common/components/icon/Icon";
import { toasti18n } from "utils/toast";
import { clsx } from "clsx";
import { useDownloadOperationPDFByPlanId } from "./useDownloadOperationPDFByPlanId";

export function DownloadOperationPDFButton({ planId }: { planId: string; }) {
  const { downloadOperationPDFByPlanId, isLoading } = useDownloadOperationPDFByPlanId();

  return (
    <button
      className={clsx(
        "bg-transparent border-none p-0 m-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400",
        isLoading ? "cursor-not-allowed" : "cursor-pointer"
      )}
      disabled={isLoading}
      onClick={() => {
        downloadOperationPDFByPlanId(planId, {
          onStart() {
            return toasti18n.loading();
          },
          onError(_, toastId) {
            toasti18n.updateLoadingToastStatus(toastId, "error");
          },
          onSuccess(toastId) {
            toasti18n.updateLoadingToastStatus(toastId, "success");
          },
        });
      }}
    >
      <Icon
        name="DownloadTray"
        color={isLoading ? "onSurfaceDisabled" : "primary500"} />
    </button>
  );
}

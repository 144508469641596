// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Histogram--2YhpU1oYKP{width:296px;height:140px;position:relative}.HistogramXAxisLabel--3rjWFTX9I7{width:296px;font-weight:400;font-size:10px !important;text-align:center;line-height:.7}", ""]);
// Exports
exports.locals = {
	"Histogram": "Histogram--2YhpU1oYKP",
	"HistogramXAxisLabel": "HistogramXAxisLabel--3rjWFTX9I7"
};
module.exports = exports;

import * as React from "react";
import ReactPDF, { View } from "@react-pdf/renderer";

import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

interface InfoBoxProps extends ReactPDF.ViewProps {
  label: string;
  value: string;
}

export function InfoBox({ label, value, ...rest }: InfoBoxProps): JSX.Element {
  return (
    <View {...rest}>
      <Text
        translate
        style={{
          fontFamily: "Kanit-regular",
          fontSize: 10,
          lineHeight: 16 / 10,
          letterSpacing: 0.4,
          color: COLORS.onSurfaceMedium
        }}
      >
        {label}{" "}
      </Text>
      <Text
        style={{
          fontFamily: "Kanit-light",
          fontSize: 12,
          lineHeight: 16 / 12,
          letterSpacing: 0.25,
          color: COLORS.onSurfaceHigh,
          marginTop: -4
        }}
      >
        {value}
      </Text>
    </View>
  );
}

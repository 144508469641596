import * as React from "react";
import { clsx } from "clsx";
import { MachineStatusEnum } from "gql/generated";

export const MachineStatusBadge = ({
  status,
  label,
  className,
}: {
  status: MachineStatusEnum;
  className?: string;
  label: string;
}) => {
  const baseClassName = clsx(
    "h-4 leading-4 text-caption px-4 py-1 rounded-full whitespace-nowrap text-center font-kanit",
    {
      "bg-success-overlay": status === MachineStatusEnum.Online,
      "bg-on-surface-disabled text-white": status === MachineStatusEnum.Offline,
      "bg-error-200": status === MachineStatusEnum.OutOfService,
      "bg-warning-overlay": status === MachineStatusEnum.Maintenance,
    },
    className
  );

  return <div className={baseClassName}>{label}</div>;
};

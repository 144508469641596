import {
  GetMaintenanceBoardOtherErrorDocument,
  GetMaintenanceBoardOtherErrorQuery,
  GetMaintenanceBoardOtherErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getOtherError({
  serviceZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardOtherErrorQuery,
    GetMaintenanceBoardOtherErrorQueryVariables
  >(GetMaintenanceBoardOtherErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

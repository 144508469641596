// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PolarChart--3GblFTR4Vg{height:168px;width:168px}", ""]);
// Exports
exports.locals = {
	"PolarChart": "PolarChart--3GblFTR4Vg"
};
module.exports = exports;

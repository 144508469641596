import * as React from "react";

import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import { useHistory, useParams } from "react-router-dom";
import { TransactionGroupByErrorPage } from "containers/transactions/groupByError/page";
import { machineMenuSelectOptions } from "../selectOptions";

export function MachineTransactionGroupByError () {
  const { machineId }: { machineId: string } = useParams();
  const history = useHistory();

  function onGroupBySelect(selectedGroupBy: string) {


    if(selectedGroupBy === "allTransactionNone") {
      history.push(`/machines/detail/${machineId}/transaction/group-by-none`);
      return;
    }

    if (selectedGroupBy === "groupByError") {
      return;
    }

    const newURLSearchParams = new URLSearchParams(
      getDefaultQueryParamsForGroupBy(selectedGroupBy)
    ).toString();

    history.push(`transaction?${newURLSearchParams}`);
  }
  
  
  return <TransactionGroupByErrorPage name="All Transaction group by error" machineId={machineId} options={machineMenuSelectOptions} onSelect={onGroupBySelect} />;
}
import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";

export function InventorySubNav() {
  return (
    <ScrollContainer>
      <InventoryTabs />
    </ScrollContainer>
  );
}

export function InventoryTabs() {
  const [serviceZone] = useServiceZoneQueryParam();
  const { t } = useTranslation("errorBoard");

  const { state } = useErrorBoard();
  const inventorySummary = state.data.summary?.inventory;

  return (
    <>
      <TabButton
        path={routes.inventory.milk(serviceZone)}
        activePath={routes.inventory.milk()}
        amount={inventorySummary?.milk ?? 0}
      >
        {t("inventoryTabs.milk")}
      </TabButton>
      <TabButton
        path={routes.inventory.sugar(serviceZone)}
        activePath={routes.inventory.sugar()}
        amount={inventorySummary?.sugar ?? 0}
      >
        {t("inventoryTabs.sugar")}
      </TabButton>
      <TabButton
        path={routes.inventory.coffeeBeans(serviceZone)}
        activePath={routes.inventory.coffeeBeans()}
        amount={inventorySummary?.coffeeBeans ?? 0}
      >
        {t("inventoryTabs.coffeeBeans")}
      </TabButton>

      <TabButton
        path={routes.inventory.plasticCups(serviceZone)}
        activePath={routes.inventory.plasticCups()}
        amount={inventorySummary?.plasticCups ?? 0}
      >
        {t("inventoryTabs.plasticCups")}
      </TabButton>

      <TabButton
        path={routes.inventory.paperCups(serviceZone)}
        activePath={routes.inventory.paperCups()}
        amount={inventorySummary?.paperCups ?? 0}
      >
        {t("inventoryTabs.paperCups")}
      </TabButton>

      <TabButton
        path={routes.inventory.espressoCups(serviceZone)}
        activePath={routes.inventory.espressoCups()}
        amount={inventorySummary?.espressoCups ?? 0}
      >
        {t("inventoryTabs.espressoCups")}
      </TabButton>
      <TabButton
        path={routes.inventory.lid(serviceZone)}
        activePath={routes.inventory.lid()}
        amount={inventorySummary?.lid ?? 0}
      >
        {t("inventoryTabs.lids")}
      </TabButton>
      <TabButton
        path={routes.inventory.other(serviceZone)}
        activePath={routes.inventory.other()}
        amount={inventorySummary?.other ?? 0}
      >
        {t("inventoryTabs.other")}
      </TabButton>
    </>
  );
}

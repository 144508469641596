import * as React from "react";
import {
  ACCOUNT_TYPE,
  AccountType,
  PaymentInfo,
  getAccountTypeLabel
} from "../../hooks/useGetLocationFee";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";
import Select from "components/Select/Select";

export function PaymentInformationView({
  paymentInformation,
  title,
}: {
  paymentInformation: PaymentInfo;
  title: string;
}) {
  return (
    <>
      <div>
        <Typography type="headline-6" translate>
          {title}
        </Typography>
      </div>

      <div
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          display: "grid",
          gap: "0px",
          marginTop: "20px",
        }}
      >
        <TextFieldViewOnly
          label="label_account_number"
          value={paymentInformation.accountNumber}
        />
        <TextFieldViewOnly
          label="label_name_on_account"
          value={paymentInformation.nameOnAccount}
        />
        <TextFieldViewOnly
          label="label_type_of_account"
          value={getAccountTypeLabel(paymentInformation.typeOfAccount)}
          translateValue
        />
        <TextFieldViewOnly
          label="label_bank_name"
          value={paymentInformation.bankName}
        />
        <TextFieldViewOnly
          label="label_branch_name"
          value={paymentInformation.branchName}
        />
        <TextFieldViewOnly
          label="label_email"
          value={paymentInformation.email}
        />
      </div>
    </>
  );
}

type PaymentInformationFormProps = {
  value: PaymentInformationFormValue;
  onChange: (value: PaymentInformationFormValue) => void;
  title: string;
};

export type PaymentInformationFormValue = Omit<PaymentInfo, "typeOfAccount"> & {
  typeOfAccount: PaymentInfo["typeOfAccount"] | "";
};

export function mapPaymentInfoToPaymentInfoFormValue(
  paymentInfo: PaymentInfo
): PaymentInformationFormValue {
  return paymentInfo;
}
export function initializePaymentInfoFormValue(): PaymentInformationFormValue {
  return {
    accountNumber: "",
    nameOnAccount: "",
    typeOfAccount: "",
    bankName: "",
    branchName: "",
    email: "",
  };
}

export function PaymentInformationForm({
  value,
  onChange,
  title,
}: PaymentInformationFormProps) {
  return (
    <>
      <div>
        <Typography type="headline-6" translate>
          {title}
        </Typography>
      </div>

      <div
        style={{
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          display: "grid",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <TextField
          value={value.accountNumber}
          label="label_account_number"
          onChange={(e) =>
            onChange({
              ...value,
              accountNumber: e.target.value,
            })
          }
        />

        <TextField
          value={value.nameOnAccount}
          label="label_name_on_account"
          onChange={(e) =>
            onChange({
              ...value,
              nameOnAccount: e.target.value,
            })
          }
        />

        <Select
          label="label_type_of_account"
          id="type"
          value={value.typeOfAccount}
          onChange={(e) =>
            onChange({
              ...value,
              typeOfAccount: e.target.value as AccountType,
            })
          }
          options={Object.values(ACCOUNT_TYPE).map((type) => ({
            value: type,
            label: getAccountTypeLabel(type),
          }))}
        />

        <TextField
          value={value.bankName}
          label="label_bank_name"
          onChange={(e) =>
            onChange({
              ...value,
              bankName: e.target.value,
            })
          }
        />

        <TextField
          value={value.branchName}
          label="label_branch_name"
          onChange={(e) =>
            onChange({
              ...value,
              branchName: e.target.value,
            })
          }
        />

        <TextField
          value={value.email}
          label="label_email"
          onChange={(e) =>
            onChange({
              ...value,
              email: e.target.value,
            })
          }
        />
      </div>
    </>
  );
}
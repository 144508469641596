import * as React from "react";

import { useTranslation } from "react-i18next";

import Icon, { IIconProps } from "common/components/icon/Icon";

interface MuiLabelProps {
  children: React.ReactNode;
  required?: boolean;
  label: string | string[];
  iconProps?: IIconProps;
}

function MuiLabel({
  children,
  required,
  label,
  iconProps,
}: MuiLabelProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="mb-4 relative">
      {iconProps ? (
        <Icon
          {...iconProps}
          className={
            "absolute -top-[1px] -left-[26px] w-[19px] h-[19px] " +
            (iconProps.className ?? "")
          }
        />
      ) : null}
      <div className="text-on-surface-medium font-kanit text-body3 tracking-[0.00938em]">
        {Array.isArray(label)
          ? label.map((s) => (s.includes("_") ? t(s) : s)).join("")
          : t(label)}
        {required && <span className="text-error">*</span>}
      </div>
      <div className="leading-[1.4375em]">{children}</div>
    </div>
  );
}

export default MuiLabel;

import { DailySalesGroupByErrorQuery } from "gql/generated";
import * as moment from "moment";
import { DateRange } from "./table";

type DateCount = {
  date: string;
  count: number;
};

export type DailySalesGroupByErrorFormattedData = Omit<DailySalesGroupByErrorQuery["dailySalesGroupByError"][number], "machineId" | "time"> & {
  machineIds: string[];
  dates: DateCount[];
}

const dateFormat = "DD/MM"; // Format DD/MM

export function generateDateRange( {from, to } :{ from: number, to: number }): string[] {
  const dates: string[] = [];
  const currentDate = new Date(from);

  while (currentDate.getTime() <= to) {
    dates.push(moment(currentDate).format(dateFormat));
    currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
  }

  return dates;
}

type mapDataToTableProps = DateRange & {
  data: DailySalesGroupByErrorQuery["dailySalesGroupByError"];
}

export function mapDataToTable({ data, from, to }: mapDataToTableProps): DailySalesGroupByErrorFormattedData[] {

  const allDatesInRange = generateDateRange({ from, to });
  const groupedData: { [key: string]: DailySalesGroupByErrorFormattedData } = {};

  data.forEach((item) => {
    const {
      machineId,
      errorCode,
      errorCodeRaw,
      errorMessageTh,
      errorMessageEn,
      count,
      organizationId,
      time,
    } = item;

    const timeStamp = new Date(time).getTime();

    // If the timestamp is not within the range, skip the item
    if (timeStamp < from || timeStamp > to) return;

    const date = moment(time).format(dateFormat);

    if (groupedData[errorCode]) {
      // If errorCode exists, update the existing entry
      groupedData[errorCode].machineIds.push(machineId);
      groupedData[errorCode].count += count;
      const dateEntry = groupedData[errorCode].dates.find((d) => d.date === date);
      if (dateEntry) {
        dateEntry.count += count;
      }
    } else {
      // Create a new entry for the errorCode
      groupedData[errorCode] = {
        machineIds: [machineId],
        errorCode,
        errorCodeRaw,
        errorMessageTh,
        errorMessageEn,
        count,
        organizationId,
        dates: allDatesInRange.map((d) => ({ date: d, count: 0 })), // Initialize all dates with 0 count
      };

      // Add count for the first occurrence of the date
      const dateEntry = groupedData[errorCode].dates.find((d) => d.date === date);
      if (dateEntry) {
        dateEntry.count += count;
      }
    }
  });

  // Convert grouped data object into an array
  return Object.values(groupedData);
}

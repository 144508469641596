import { GetPlanForOperationPdfQuery, MachineKind } from "gql/generated";
import { Package, PlanPackageSummary } from "./type";
import * as moment from "moment";

export function mapDataToPlanPackageSummary(
  data: GetPlanForOperationPdfQuery,
  kind: MachineKind
): PlanPackageSummary {
  // Create an object to store the number of packages and package details for each SKU
  const packages: { [sku: string]: Package } = {};
  const filteredRefillOrders = data.plan.refillOrders.filter((refillOrder) => refillOrder?.machine?.kind === kind);
  // Loop through each requested item and accumulate the number of packages
  filteredRefillOrders.forEach((refillOrder) => {
    refillOrder.requestedItems.forEach((requestedItem) => {
      const packageSKU = requestedItem.product.SKU;
      // If the package SKU already exists, add the number of packages
      if (packages[packageSKU]) {
        packages[packageSKU].numberOfPackages +=
          requestedItem.usage / requestedItem.product.package;
      } else {
        // If the package SKU does not exist, create a new package object
        packages[packageSKU] = {
          numberOfPackages: requestedItem.usage / requestedItem.product.package,
          productQuantityPerPackage: requestedItem.product.package,
          packageUOM: requestedItem.product.packageType,
          productUOMInPackage: requestedItem.product.uom,
          packageSKU: requestedItem.product.SKU,
          productName: requestedItem.product.name,
        };
      }
    });
  });

  // Convert package object to array and sort by SKU
  const sortedPackages = Object.values(packages).sort((a, b) =>
    a.packageSKU.localeCompare(b.packageSKU)
  );

  return {
    planName: data.plan.name,
    planFriendlyId: data.plan.friendlyId,
    numberOfMachines: data.plan.refillOrders.length,
    routeNames: Array.from(
      new Set(
        data.plan.refillOrders.map(
          (refillOrder) => refillOrder.location.refillZone.friendlyId
        )
      )
    ).sort((a, b) => a.localeCompare(b)),
    packages: sortedPackages,
    warehouseName: data.plan.warehouse.name,
    createdAt: moment(Number(data.plan.createdAt)),
  };
}
import * as React from "react";
import { Column } from "@tanstack/react-table";
import Autocomplete from "components/Autocomplete/Autocomplete";
import { CustomTextField } from "./customTextfield";

export function AutocompleteFilter<T>({
  column,
  className,
  onChange,
}: {
  column: Column<T, unknown>;
  className?: string;
  onChange?: (e: string) => void;
}) {
  const filterValue = column.getFilterValue() as string;

  if(column.columnDef.meta.columnFilter.type !== "autocomplete") return null;
  return (
    <Autocomplete
      className={`!min-w-[220px] !font-kanit ${className}`}
      label={column.columnDef.meta?.columnFilter?.label || column.id}
      onChange={(newValue) => {
        column.setFilterValue(newValue);
        onChange?.(newValue);
      }}
      value={filterValue || ""}
      options={column.columnDef.meta?.columnFilter?.options}
      required
      size="small"
      id={column.id}
      customRenderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder={
            column.columnDef.meta?.columnFilter?.placeholder ||
            column.columnDef.meta?.columnFilter?.label ||
            column.id
          }
        />
      )}
    />
  );
}

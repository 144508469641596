import { IAlign } from '../Tooltip';

function getHorizontalAlign (index: number, total: number): IAlign {
  const divisions = 10;
  const lowerThreshold = total / divisions;
  const upperThreshold = lowerThreshold * (divisions - 1);

  if (index < lowerThreshold) {
    return 'left';
  }
  if (index >= upperThreshold) {
    return 'right';
  }
  return 'center';
}

export default getHorizontalAlign;

import * as React from "react";

import { Modal } from "@mui/material";
import { LoadingScreen, ModalFallback } from "../../../Components";
import { ErrorBoundary } from "react-error-boundary";
import { RouteModalView } from "./RouteModalView";

import { LocationKind, MachineKind, useGetMachinesAndOrdersInRefillZoneQuery } from "gql/generated";

import * as styles from "./RouteModal.module.scss";

interface Props {
  routeId: string;
  onClose: () => void;
  onRouteSuccess: () => void;
}

function RouteModal(props: Props) {
  return (
    <Modal open={true} onClose={props.onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <ModalFallback
                error={error}
                metaData={`operations/refillorder/route | ID: ${props.routeId}`}
                onClose={props.onClose}
              />
            )}
          >
            <RouteModalDataFetcher {...props} />
          </ErrorBoundary>
        </div>
      </div>
    </Modal>
  );
}
function RouteModalDataFetcher(props: Props) {
  const { isLoading } = useGetMachinesAndOrdersInRefillZoneQuery(
    {
      refillZoneId: props.routeId,
      locationKind: [LocationKind.Location, LocationKind.Store],
      machineKind: [MachineKind.BeverageVending, MachineKind.BeverageUnit]
    },
    { cacheTime: 0 }
  );
  return <>{isLoading ? <LoadingScreen /> : <RouteModalView {...props} />}</>;
}
export { RouteModal };

import { httpClient } from "common/services/transportService";
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

const queryKeyFactory = {
  warehouses: () => [{ scope: "warehouses", url: "/warehouse" }],
};

export type Warehouses = Array<{
  warehouseId: string;
  name: string;
  address?: string;
  lat: number;
  long: number;
  additional: any;
  createdAt: string;
  updatedAt: string;
  organisationId: string;
  province?: string;
  machineCount?: number;
  userCount?: number;
  productCount: string;
}>;

async function getWarehouses() {
  const response = await httpClient.get<Warehouses>("/warehouse");
  return response.data;
}

export function useGetWarehouseBase<T>({
  select,
}: {
  select: (response: Warehouses) => T;
}) {
  return useQuery({
    queryKey: queryKeyFactory.warehouses(),
    queryFn: getWarehouses,
    staleTime: 1000 * 60,
    select,
  });
}

export function useGetWarehouse() {
  return useGetWarehouseBase({ select: (response) => response });
}

export function useGetWarehouseById(warehouseId: string) {
  const warehouseQuery = useGetWarehouseBase({
    select: (response) =>
      response.find((warehouse) => warehouse.warehouseId === warehouseId),
  });
  if (!warehouseQuery.data && !warehouseQuery.isLoading) {
    throw new Error(`Warehouse not found | ID: ${warehouseId}`);
  }
  return warehouseQuery;
}

export type AddNewWarehouseRequest = {
  name: string;
  province: string;
  address: string;
  lat: number;
  long: number;
};

export async function addNewWarehouse(warehouse: AddNewWarehouseRequest) {
  return await httpClient.post("/warehouse", warehouse);
}

export function useAddNewWarehouse({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, AddNewWarehouseRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewWarehouse,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(queryKeyFactory.warehouses());
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

type EditWarehouseRequest = {
  warehouseId: string;
  warehouseData: AddNewWarehouseRequest;
};

export async function editWarehouseSettings(data: EditWarehouseRequest) {
  return await httpClient.patch(
    `/warehouse/${data.warehouseId}`,
    data.warehouseData
  );
}

export function useEditWarehouseSettings({
  onSuccess,
  ...restOptions
}: Omit<
  UseMutationOptions<unknown, unknown, EditWarehouseRequest, unknown>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editWarehouseSettings,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(queryKeyFactory.warehouses());
      onSuccess?.(...args);
    },
    ...restOptions,
  });
}

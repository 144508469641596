import Select from "components/Select";
import { LocationType } from "gql/generated";
import * as React from "react";
import { getLocationTypeTranslation } from "../../types";

export function LocationTypeSelect({
  value, onChange, required
}: {
  value: LocationType | ""
  onChange: (value: LocationType) => void;
  required: boolean
}) {
  return (
    <Select
      required={required}
      label="label_location_type"
      id="type"
      value={value}
      onChange={(e) => onChange(e.target.value as LocationType)}
      options={Object.values(LocationType).map((type) => ({
        value: type,
        label: getLocationTypeTranslation(type),
      }))} />
  );
}

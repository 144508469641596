import {
  ThailandState,
  MalaysiaState,
  SingaporeState,
  AustraliaState,
  UnitedArabEmiratesState,
} from "gql/generated";
import { Country, getUserCountry } from "utils/user-role";
import i18n from "common/i18n";

const COUNTRY_TO_STATE = {
  DEFAULT: ThailandState,
  SINGAPORE: SingaporeState,
  THAILAND: ThailandState,
  MALAYSIA: MalaysiaState,
  AUSTRALIA: AustraliaState,
  UNITED_ARAB_EMIRATES: UnitedArabEmiratesState,
} satisfies Record<Country | 'DEFAULT', any>;

export function getProvince() {
  const country = getUserCountry();
  return COUNTRY_TO_STATE[country] ?? COUNTRY_TO_STATE.DEFAULT;
}

const malaysiaState = Object.values(COUNTRY_TO_STATE.MALAYSIA);
const thailandState = Object.values(COUNTRY_TO_STATE.THAILAND);
const singaporeState = Object.values(COUNTRY_TO_STATE.SINGAPORE);
const australiaState = Object.values(COUNTRY_TO_STATE.AUSTRALIA);
const unitedArabEmirateState = Object.values(COUNTRY_TO_STATE.UNITED_ARAB_EMIRATES);
const allStates = [
  ...malaysiaState,
  ...thailandState,
  ...singaporeState,
  ...australiaState,
  ...unitedArabEmirateState,
];

export function translateProvince(state: string) {
  return allStates.includes(state as any)
    ? i18n.t(`label_${state.toLowerCase()}`)
    : state;
}

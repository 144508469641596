import {
  GetMaintenanceBoardCoffeeMaterialErrorDocument,
  GetMaintenanceBoardCoffeeMaterialErrorQuery,
  GetMaintenanceBoardCoffeeMaterialErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getCoffeeBeans({
  serviceZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardCoffeeMaterialErrorQuery,
    GetMaintenanceBoardCoffeeMaterialErrorQueryVariables
  >(GetMaintenanceBoardCoffeeMaterialErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort,
  })();
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FallbackUI--12LsFlyfwb{display:flex;height:100%;flex-direction:column;justify-content:center;align-items:center}.FallbackUI--12LsFlyfwb .icon--108jsdtbyo{width:170px;height:170px}.FallbackUI--12LsFlyfwb .information--o_Q_rBelk7{display:flex}.FallbackUI--12LsFlyfwb .title--1RfPenx0xG{margin-top:20px}.FallbackUI--12LsFlyfwb .button--mafMmvbMag{width:200px;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"FallbackUI": "FallbackUI--12LsFlyfwb",
	"icon": "icon--108jsdtbyo",
	"information": "information--o_Q_rBelk7",
	"title": "title--1RfPenx0xG",
	"button": "button--mafMmvbMag"
};
module.exports = exports;

import { orderBy, maxBy } from 'lodash';

import { IBucketState, IBucket, IStateMap } from '../BucketStatus';

interface IGetBucketData {
  data: IBucketState[],
  from: number,
  to: number,
  buckets: number,
  stateMap: IStateMap[],
  minimumEventHeight: number
}

function getBucketData ({
  data,
  from,
  to,
  buckets,
  stateMap,
  minimumEventHeight
}: IGetBucketData): IBucket[] {
  const firstTimestamp = from;
  const lastTimestamp = to;
  const range = lastTimestamp - firstTimestamp;
  const interval = Math.round(range / buckets);

  const bucketData = {};
  let count = 1;
  let currentMin = firstTimestamp;
  while (count <= buckets) {
    bucketData[count] = {
      key: count,
      min: currentMin,
      max: currentMin + interval,
      data: []
    };
    currentMin = currentMin + interval;
    count++;
  };

  const orderedData = orderBy(data, ['timestamp'], ['asc']);
  const successState = stateMap.find(i => i.isSuccessState);
  const filteredData = orderedData.filter(i => {
    const inStateMap = stateMap.find(x => x.state === i.state);
    if (!inStateMap) {
      return false;
    }
    if (i.state === successState.state) {
      return false;
    }
    return true;
  });

  const bucketArray: IBucket[] = Object.values(bucketData);
  for (const data of filteredData) {
    const bucket = bucketArray.find(i => {
      return data.timestamp >= i.min && data.timestamp < i.max;
    });
    if (bucket) {
      const stateDefinition = stateMap.find(i => i.state === data.state);
      const existingData = bucketData[bucket.key];
      bucketData[bucket.key] = {
        ...existingData,
        data: [
          ...existingData.data,
          {
            ...data,
            priority: stateDefinition.priority
          }
        ]
      };
    }
  }

  const bucketedArray: IBucket[] = Object.values(bucketData);
  const dataCounts = bucketedArray.map(i => i.data.length);
  const maxHeight = Math.max(minimumEventHeight, ...dataCounts);

  const renderData = bucketedArray.map(i => {
    const maxPriority = maxBy(i.data, 'priority') || { state: null };
    const stateDefinition = stateMap.find(i => i.state === maxPriority.state);

    const isFutureBar = Date.now() < i.min;
    return {
      ...i,
      height: `${((i.data.length / maxHeight) || 0.05) * 100}%`,
      color: isFutureBar
        ? 'onSurfaceDisabled'
        : stateDefinition
          ? stateDefinition.color
          : successState.color
    };
  });

  return renderData;
}

export default getBucketData;

import * as React from 'react';

// Custom components
import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

// Styles
import * as styles from './TransactionHeader.module.scss';

interface ITransactionHeaderProps {
  label: string;
  sort: 'ASC' | 'DESC' | 'NONE';
  onClick: () => void;
}

/**
 * Component to display the table headers in '/transactions' and '/machines/detail/:machineId/transaction'
 * @param ITransactionHeaderProps
 * @param ITransactionHeaderProps.label - Text to show in header
 * @param ITransactionHeaderProps.sort - Current sort of the column - used to change the rotation / color / type of the icon
 * @param ITransactionHeaderProps.onClick - Fires when the header is clicked
 * @remarks __Justification for existence:__ This component is required because the only way to customize the header cell in the `Table.tsx` component is to pass in an argument to the `headerCellRenderer` prop to each `columnConfig` object.
 * @remarks __Current usage__: Used as an argument to `headerCellRenderer` argument in the `columns` prop of the `Table.tsx` element in __'/transactions'__ and __'/machines/detail/:machineId/transaction'__
 * @privateRemarks
 * __Last documented by__: Tiger Schad
 *
 * __Documented date__: 20-Aug-21
 *
 * __Code last updated__: 20-Aug-21
 */
function TransactionHeader ({
  label,
  sort,
  onClick
}: ITransactionHeaderProps): JSX.Element {
  // Typography related properties
  let typographyClass: string;

  // Icon related properties
  let iconColor: string;
  let iconName: string;
  let iconClass: string;

  if (sort === 'NONE') {
    // Typography
    typographyClass = styles.headerLabel;
    // Icon
    iconName = 'SortChevrons';
    iconColor = 'onSurfaceDisabled';
    iconClass = styles.sortChevrons;
  } else if (sort === 'ASC') {
    // Typography
    typographyClass = [ styles.headerLabel, styles.headerLabelActive ].join(' ');
    // Icon
    iconName = 'Chevron';
    iconColor = 'onSurfaceHigh';
    iconClass = [ styles.sortChevrons, styles.upChevron ].join(' ');
  } else if (sort === 'DESC') {
    // Typography
    typographyClass = [ styles.headerLabel, styles.headerLabelActive ].join(' ');
    // Icon
    iconName = 'Chevron';
    iconColor = 'onSurfaceHigh';
    iconClass = [ styles.sortChevrons, styles.downChevron ].join(' ');
  }

  return (
    <div onClick={onClick} className={styles.TransactionHeader}>
      <Typography
        translationKey={label}
        type="body-2"
        className={typographyClass}
      />
      <Icon
        className={iconClass}
        name={iconName}
        color={iconColor}
      />
    </div>
  );
}

export default TransactionHeader;

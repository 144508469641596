import * as React from "react";
import { View } from "@react-pdf/renderer";
import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

export function TransferInventoryHeader({
  transferId
}: {
  transferId: string;
}): JSX.Element {
  return (
    <View
      style={{
        borderColor: COLORS.primary500,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4
      }}
    >
      <Text
        translate
        style={{
          fontFamily: "Kanit-light",
          fontSize: 14,
          letterSpacing: 0,
          lineHeight: 20 / 14,
          color: COLORS.onSurfaceHigh,
          textTransform: "uppercase",
          marginBottom: 5
        }}
      >
        {"label_transfer_inventory"}
      </Text>
      <Text
        translate
        style={{
          fontFamily: "Kanit-regular",
          fontSize: 8,
          color: COLORS.onSurfaceMedium
        }}
      >
        {"label_transfer_id"} {transferId.slice(transferId.length - 12)}
      </Text>
    </View>
  );
}

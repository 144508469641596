import Typography from "components/Typography/Typography";
import * as React from "react";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { warehousingRoutes } from "../../routes";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "components/Fallback";
import { Skeleton } from "@mui/material";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { useGetProductCatalog } from "./hooks/use-get-product-catalog";
import { WarehousingWorkspacePaddingContainer } from "../../components/WarehousingWorkspacePaddingContainer";
import {
  PreventTextOverflow,
  TextDotSeparator,
} from "../../components/TextDotSeparator";
import { useInventoryIdFromURL } from "./hooks/use-inventory-id-from-url";
import { InventoryImage } from "../../components/InventoryImage";
import { InventoryIdView } from "./InventoryIdView";
import { InventoryIdViewPlaceHolder } from "./InventoryIdViewPlaceHolder";
import { parseISODuration } from "containers/warehousing/utils/parseDuration";
import * as styles from "./MasterInventoryCard.module.scss";

export function InventoryListView() {
  const locationChangeToResetErrorBoundary = useLocation();

  const getMasterInventoryProduct = useGetProductCatalog();

  const [filter, setFilter] = React.useState<IAppliedFilter[]>([]);

  const filteredProducts = React.useMemo(() => {
    let filtered = getMasterInventoryProduct.data;

    filter.forEach((f) => {
      switch (f.queryVerb) {
        case "contains":
          filtered = filtered.filter((product) =>
            product[f.field]?.toLowerCase().includes(f.value.toLowerCase())
          );
          break;
      }
    });

    return filtered;
  }, [filter, getMasterInventoryProduct.data]);

  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <aside
          style={{
            flex: "0 1 558px",
            backgroundColor: "white",
            borderRight: "1px solid rgba(0,0,0,0.12)",
            minWidth: "0px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}>
              <WarehousingWorkspacePaddingContainer>
                <PureFilterBar
                  value={filter}
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  onChange={setFilter}
                  filterOptions={[
                    {
                      field: "productName",
                      queryType: "contains",
                      translationKey: "label_name",
                    },
                    {
                      field: "productSKU",
                      queryType: "contains",
                      translationKey: "label_sku",
                    },
                  ]}
                  placeholder="label_search"
                />
              </WarehousingWorkspacePaddingContainer>
            </div>
            <div style={{ flex: "1 1 0", overflow: "auto" }}>
              <WarehousingWorkspacePaddingContainer>
                <div
                  style={{
                    padding: "24px 0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {getMasterInventoryProduct.isLoading
                    ? new Array(5)
                        .fill(0)
                        .map((_, index) => (
                          <MasterInventoryCard key={index} isLoading={true} />
                        ))
                    : filteredProducts.map((product) => (
                        <MasterInventoryCard
                          key={product.productId}
                          inventory={{
                            currentStock: product.totalStock,
                            // enoughFor: product.enoughFor,
                            enoughFor: undefined,
                            id: product.productId,
                            imgUrl: product.productImage,
                            inventoryName: product.productName,
                            packageType: product.packageType,
                            productQuantityPerPackage: product.packageValue,
                            sku: product.productSKU,
                            uom: product.packageUnit,
                          }}
                        />
                      ))}
                </div>
              </WarehousingWorkspacePaddingContainer>
            </div>
          </div>
        </aside>

        <main style={{ flex: "1 1 824px", minWidth: "0px" }}>
          <Switch>
            <Route path={warehousingRoutes.masterInventoryList()} exact={true}>
              <InventoryIdViewPlaceHolder />
            </Route>
            <Route
              path={warehousingRoutes.masterInventoryListWithInventoryId()}
            >
              <ErrorBoundary
                FallbackComponent={Fallback}
                key={locationChangeToResetErrorBoundary.pathname}
              >
                <InventoryIdView />
              </ErrorBoundary>
            </Route>
            <Redirect to={warehousingRoutes.masterInventoryList()} />
          </Switch>
        </main>
      </div>
    </div>
  );
}
interface MasterInventoryCardProps {
  isLoading?: boolean;
  inventory?: {
    id: string;
    sku: string;
    currentStock: number;
    enoughFor: string;
    uom: string;
    productQuantityPerPackage: number;
    packageType: string;
    imgUrl: string;
    inventoryName: string;
  };
}

function MasterInventoryCard({
  inventory,
  isLoading,
}: MasterInventoryCardProps) {
  const {
    id,
    currentStock,
    enoughFor,
    packageType,
    productQuantityPerPackage,
    sku,
    uom,
    inventoryName,
    imgUrl,
  } = inventory ?? {};

  const inventoryId = useInventoryIdFromURL();
  const doesInventoryIdMatch = inventoryId === id;

  const card = (
    <div
      className={isLoading ? "" : styles.HoverStyle}
      style={{
        padding: "12px",
        borderRadius: "8px",
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.13)",
        border: "1px solid rgba(0,0,0,0.12)",
        backgroundColor: doesInventoryIdMatch
          ? "rgb(230, 230, 255)"
          : undefined,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "16px",
        }}
      >
        <InventoryImage isLoading={isLoading} imgUrl={imgUrl} />

        <div style={{ flexGrow: 1, minWidth: "0px" }}>
          {isLoading ? (
            <Skeleton height={"30px"} style={{ transform: "scale(1,1)" }} />
          ) : (
            <PreventTextOverflow>
              <Typography type="headline-6">{inventoryName}</Typography>
            </PreventTextOverflow>
          )}

          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                gap: "10px",
              }}
            >
              <PreventTextOverflow>
                <Typography type="caption" color="onSurfaceMedium">
                  {sku}
                </Typography>
              </PreventTextOverflow>

              <TextDotSeparator
                leftText="label_current_stock"
                rightText={String(currentStock) ?? "-"}
              />
            </div>
          )}

          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                gap: "10px",
              }}
            >
              <TextDotSeparator
                leftText={["label_pack", "/", "label_value", "/", "label_unit"]}
                rightText={`${packageType} ${productQuantityPerPackage} ${uom}`}
              />

              <TextDotSeparator
                leftText="label_enough_for"
                rightText={enoughFor ? parseISODuration(enoughFor) : "-"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return isLoading ? (
    card
  ) : (
    <Link to={warehousingRoutes.masterInventoryListWithInventoryId(id)}>
      {card}
    </Link>
  );
}

import * as React from "react";

import { CircularProgress } from "@mui/material";

import * as styles from "./LoadingScreen.module.scss";

function LoadingScreen(): JSX.Element {
  return (
    <div className={styles.LoadingIndicatorContainer}>
      <Spinner />
    </div>
  );
}

export function Spinner() {
  return <CircularProgress size={50} className={styles.LoadingIndicator} />;
}

export default LoadingScreen;

import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';
import { PieChart } from '../atoms/charts/PieChart';

import { PieChartKey } from '../molecules/PieChartKey';
import { ErrorFallBack } from '../atoms/ErrorFallback';
export interface PieChartCardProps {
  title: string;
  icon: string;
  className?: string;
  isError?: boolean;
  data: PieChartSegment[];
}

interface PieChartSegment {
  label: string;
  color: string;
  amount: number;
  borderColor: string;
}

function PieChartCard({
  title,
  icon,
  data,
  className = '',
  isError,
}: PieChartCardProps): JSX.Element {
  return (
    <Card className={className}>
      <CardTitle
        icon={icon}
        title={title}
        className={isError ? '!text-on-surface-disabled' : undefined}
      />
      {isError ? (
        <ErrorFallBack />
      ) : (
        <div className="mt-auto flex justify-between items-center">
          <PieChart data={data} />
          <div className="min-w-36 flex flex-col gap-[6px]">
            {data.map((segment) => (
              <PieChartKey
                key={segment.label}
                value={(
                  (segment.amount * 100) /
                  data.reduce((acc, segment) => acc + segment.amount, 0)
                ).toFixed(1)}
                label={segment.label}
                color={segment.borderColor}
              />
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}

export { PieChartCard };

import * as React from 'react';

import Typography from 'common/components/typography/Typography';

import * as colors from 'common/styles/colors.scss';
import * as styles from './IndicatorLabel.module.scss';

interface IIndicatorLabelProps {
  label?: string,
  color: string,
  className?: string
}

function IndicatorLabel ({
  label,
  color,
  className
}: IIndicatorLabelProps): JSX.Element {
  return (
    <div
      className={[
        styles.IndicatorLabel,
        className
      ].join(' ')}
    >
      {color && (
        <div
          className={styles.indicator}
          style={{
            backgroundColor: colors[color],
            marginRight: label ? '7px' : '0px'
          }}
        />
      )}
      {label && (
        <Typography
          type='body-2'
          translationKey={label}
        />
      )}
    </div>
  );
}

export default IndicatorLabel;

import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type IStatus = 
  | 'online' 
  | 'success' 
  | 'warning' 
  | 'offline' 
  | 'error' 
  | 'none' 
  | 'null' 
  | 'out_of_service' 
  | 'maintenance' 
  | 'standby' 
  | 'not_deployed';

export interface IBadgeProps {
  translationKey: string;
  status: IStatus;
}

const statusClasses: Record<IStatus, string> = {
  online: 'bg-success text-on-primary-high',
  success: 'bg-success text-on-primary-high',
  warning: 'bg-warning text-on-surface-high',
  offline: 'bg-error text-on-primary-high',
  error: 'bg-error text-on-primary-high',
  none: 'bg-on-surface-disabled text-on-primary-high',
  null: 'bg-on-surface-disabled text-on-primary-high',
  out_of_service: 'bg-error text-on-primary-high',
  maintenance: 'bg-warning text-on-surface-high',
  standby: 'bg-on-surface-disabled text-on-primary-high',
  not_deployed: 'bg-on-surface-disabled text-on-primary-high',
};

function Badge({ translationKey, status }: IBadgeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={`rounded-[4px] px-2 py-1 min-w-[80px] flex justify-center items-center ${statusClasses[status]}`}>
      <p className='text-[10px] uppercase font-main whitespace-pre-line'>{t(translationKey)}</p>
    </div>
  );
}

export default Badge;

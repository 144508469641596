import * as React from "react";

import Button from "components/Button";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Select from "components/Select";
import MuiModal from "components/Modal";

import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import {
  CreateRefillZoneInput,
  CreateServiceZoneInput,
  useCreateRefillZoneMutation,
  useCreateServiceZoneMutation,
  useGetOrganisationsQuery,
} from "gql/generated";
import { useQueryClient } from "react-query";

import { toasti18n } from "utils/toast";
import Autocomplete from "components/Autocomplete";
import { useGetOwnPermission } from 'hooks/permission';

function AddNewZoneModal({ onCancel }: { onCancel: () => void }): JSX.Element {
  const [zoneType, setZoneType] = React.useState<
    "" | "refillZone" | "serviceZone"
  >("");
  const [zoneFriendlyId, setZoneFriendlyId] = React.useState<string>("");
  const [zoneName, setZoneName] = React.useState<string>("");
  const [organisationId, setOrganisationId] = React.useState<string>("");

  const { data: user } = useGetCurrentUser();
  const {
    allowed: isAllowedToCreateOrganisationZone,
    isLoading: isCurrentUserPermissionLoading,
  } = useGetOwnPermission({
    action: "MANAGE",
    resourceType: "LOCATION",
  });

  const { data: getOrganisationQuery, isLoading: isOrganisationsLoading } =
    useGetOrganisationsQuery(undefined, { enabled: !isCurrentUserPermissionLoading && isAllowedToCreateOrganisationZone });
  const organisations = getOrganisationQuery?.organisations;

  const queryClient = useQueryClient();
  const { mutate: createRefillZone, isLoading: isCreatingRefillZone } =
    useCreateRefillZoneMutation({
      onError: (error) => {
        toasti18n.error(error as Error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          "GetRefillZonesByOrgId",
          { organisationId: isAllowedToCreateOrganisationZone ? organisationId : user.orgId },
        ]);
        onCancel();
        toasti18n.success();
      },
    });
  const { mutate: createServiceZone, isLoading: isCreatingServiceZone } =
    useCreateServiceZoneMutation({
      onError: (error) => {
        toasti18n.error(error as Error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          "GetServiceZonesByOrgId",
          {
            organisationId: isAllowedToCreateOrganisationZone ? organisationId : user.orgId,
          },
        ]);
        onCancel();
        toasti18n.success();
      },
    });

  function onSubmit() {
    const requestObject: CreateRefillZoneInput & CreateServiceZoneInput = {
      friendlyId: zoneFriendlyId,
      name: zoneName,
      organisationId: isAllowedToCreateOrganisationZone ? organisationId : user.orgId,
    };
    if (zoneType === "refillZone") {
      createRefillZone({ refillZone: requestObject });
    } else if (zoneType === "serviceZone") {
      createServiceZone({ serviceZone: requestObject });
    }
  }

  const isLoading = isCreatingRefillZone || isCreatingServiceZone;

  return (
    <MuiModal
      open={true}
      onClose={isLoading ? () => {} : onCancel}
      padding="18px 19px"
      minWidth="390px"
      PaperProps={{ sx: { gap: "16px" } }}
    >
      <>
        <Typography type="headline-6" translate>
          label_add_new_zone
        </Typography>
        <Select
          required
          label="label_type"
          id="type"
          value={zoneType}
          onChange={(e) => setZoneType(e.target.value)}
          options={[
            { value: "refillZone", label: "label_refill_zone" },
            { value: "serviceZone", label: "label_service_zone" },
          ]}
        />
        {isAllowedToCreateOrganisationZone && (
          <Autocomplete
            label="label_organisation"
            id="organization"
            disableClearable
            onChange={(newValue) => setOrganisationId(newValue)}
            value={organisationId}
            options={
              isOrganisationsLoading
                ? []
                : organisations.map((org) => {
                    return { label: org.name, value: org.id };
                  })
            }
            required
          />
        )}
        <TextField
          label="label_zone_id"
          required
          value={zoneFriendlyId}
          onChange={(e) => setZoneFriendlyId(e.target.value)}
        />
        <TextField
          label="label_zone_name"
          required
          value={zoneName}
          onChange={(e) => setZoneName(e.target.value)}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="secondary" onClick={onCancel} disabled={isLoading}>
            action_cancel
          </Button>
          <Button
            type="primary"
            disabled={
              zoneType === "" ||
              zoneName === "" ||
              zoneFriendlyId === "" ||
              (isAllowedToCreateOrganisationZone && organisationId === "")
            }
            loading={isLoading}
            onClick={onSubmit}
          >
            action_save
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export default AddNewZoneModal;

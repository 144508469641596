import { httpClient } from "common/services/transportService";

export type PlanStates = 'kitted' | 'en_route'

export async function getRefillOrdersForPlansByStateCSV(
  state: PlanStates
): Promise<string> {
  const response = await httpClient.get<string>(
    `/refillorder/v2?state=${state}`,
    {
      responseType: 'text',
    }
  );
  return response.data;
}
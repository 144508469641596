import {
  GetMaintenanceBoardSodaErrorDocument,
  GetMaintenanceBoardSodaErrorQuery,
  GetMaintenanceBoardSodaErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getSodaError({
  serviceZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardSodaErrorQuery,
    GetMaintenanceBoardSodaErrorQueryVariables
  >(GetMaintenanceBoardSodaErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}

import * as React from 'react';

interface ITeamProps {
  color?: string;
  className?: string;
}

function Team ({ color = 'white', className }: ITeamProps): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.9486 5.83838C14.4259 5.22455 14.1094 4.43002 14.1094 3.5625C14.1094 1.61892 15.6813 0 17.625 0C19.5686 0 21.1406 1.61883 21.1406 3.5625C21.1406 4.42875 20.8251 5.2223 20.3037 5.83575C22.4289 6.39389 24 8.31539 24 10.5938V12C24 12.3883 23.6852 12.7031 23.2969 12.7031H12C11.6117 12.7031 11.2969 12.3883 11.2969 12V10.5938C11.2969 8.31891 12.8483 6.39984 14.9486 5.83838ZM17.625 1.40625C16.4816 1.40625 15.5156 2.39367 15.5156 3.5625C15.5156 4.72561 16.4619 5.67188 17.625 5.67188C18.7881 5.67188 19.7344 4.72561 19.7344 3.5625C19.7344 2.39367 18.7684 1.40625 17.625 1.40625ZM12.7031 11.2969H22.5938V10.5938C22.5938 8.65523 20.9956 7.07812 19.0312 7.07812H16.2188C14.2802 7.07812 12.7031 8.65523 12.7031 10.5938V11.2969Z'
        fill={color}
      />
      <path
        d='M3.69633 17.0858C3.17494 16.4723 2.85938 15.6788 2.85938 14.8125C2.85938 12.874 4.43648 11.2969 6.375 11.2969C8.31352 11.2969 9.89062 12.874 9.89062 14.8125C9.89062 15.68 9.57413 16.4745 9.05138 17.0884C11.1517 17.6498 12.7031 19.5689 12.7031 21.8438V23.2969C12.7031 23.6852 12.3883 24 12 24H0.703125C0.314813 24 0 23.6852 0 23.2969V21.8438C0 19.5654 1.57106 17.6439 3.69633 17.0858ZM6.375 12.7031C5.21189 12.7031 4.26562 13.6494 4.26562 14.8125C4.26562 15.9756 5.21189 16.9219 6.375 16.9219C7.53811 16.9219 8.48438 15.9756 8.48438 14.8125C8.48438 13.6494 7.53811 12.7031 6.375 12.7031ZM1.40625 22.5938H11.2969V21.8438C11.2969 19.9052 9.71977 18.3281 7.78125 18.3281H4.96875C3.00441 18.3281 1.40625 19.9052 1.40625 21.8438V22.5938Z'
        fill={color}
      />
      <path
        d='M14.3154 19.9402L15.7215 18.534C15.9961 18.2595 16.4413 18.2595 16.7159 18.534C16.9905 18.8086 16.9905 19.2538 16.7159 19.5284L16.51 19.7344H19.0313C20.1944 19.7344 21.1406 18.7881 21.1406 17.625V14.8125C21.1406 14.4242 21.4554 14.1094 21.8438 14.1094C22.2321 14.1094 22.5469 14.4242 22.5469 14.8125V17.625C22.5469 19.5635 20.9698 21.1406 19.0313 21.1406H16.51L16.7159 21.3466C16.9905 21.6212 16.9905 22.0664 16.7159 22.341C16.4414 22.6155 15.9961 22.6155 15.7215 22.341L14.3154 20.9348C14.0429 20.6625 14.0401 20.2153 14.3154 19.9402Z'
        fill={color}
      />
      <path
        d='M7.78128 2.8594H10.3025L10.0966 2.65348C9.82198 2.37888 9.82198 1.93366 10.0966 1.65907C10.3712 1.38448 10.8164 1.38448 11.091 1.65907L12.4972 3.06532C12.773 3.34099 12.7692 3.78771 12.4972 4.05968L11.091 5.46593C10.8164 5.74048 10.3712 5.74052 10.0966 5.46593C9.82203 5.19134 9.82203 4.74616 10.0966 4.47157L10.3025 4.26565H7.78128C6.61817 4.26565 5.67191 5.21191 5.67191 6.37502V9.18752C5.67191 9.57584 5.35709 9.89065 4.96878 9.89065C4.58047 9.89065 4.26565 9.57584 4.26565 9.18752V6.37502C4.26565 4.43651 5.84276 2.8594 7.78128 2.8594Z'
        fill={color}
      />
    </svg>
  );
}

export default Team;

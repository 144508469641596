import * as React from "react";
import {
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Checkbox";

import * as styles from "./CheckboxWithLabel.module.scss";

export default function CheckboxWithLabel({
  checked,
  onChange,
  label,
  className,
  ...rest
}: Omit<FormControlLabelProps, "control" | "onChange" | "checked"> &
  Pick<CheckboxProps, "checked" | "onChange">): JSX.Element {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      label={
        typeof label === "string" ? (
          <div className={styles.LabelString}>{t(label)}</div>
        ) : (
          <div className={styles.Label}>{label}</div>
        )
      }
      className={[styles.FormControlLabel, className].join(" ")}
      {...rest}
      control={<Checkbox checked={checked} onChange={onChange} />}
    />
  );
}

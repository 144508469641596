import { errorCodeMapping } from  "./errorCodeMapping";

export function getErrorMessage({ code, language }: { code: string | number, language: string }) {

  try {
    if(!["th", "en"].includes(language)) return code;
    const error = errorCodeMapping.find(error => error.error_code === Number(code));
    if (error) {
      return language === "th" ? error.error_message_th : error.error_message_en;
    }

    return code;
  } catch (error) {
    return code;
  }
}
import * as React from 'react';

interface IDrinkProps {
  color?: string;
  className?: string;
}

function Drink ({ color = 'white', className }: IDrinkProps): JSX.Element {
  return (
    <svg
      className={className}
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3332 1.66667V3.51536e-10H10.1666C9.99416 -5.0059e-06 9.826 0.0534609 9.68526 0.153029C9.54452 0.252598 9.43812 0.393367 9.38073 0.555937L8.10636 4.16666H0.895736C0.777986 4.16798 0.661822 4.19399 0.554754 4.24301C0.447686 4.29203 0.352097 4.36298 0.274165 4.45126C0.196233 4.53954 0.137692 4.64319 0.102328 4.75551C0.0669637 4.86783 0.0555624 4.98633 0.0688612 5.10333L1.83969 19.27C1.86488 19.4715 1.96281 19.6569 2.11507 19.7914C2.26734 19.9258 2.46345 20 2.66657 20H9.22906C9.43218 20 9.62829 19.9258 9.78056 19.7914C9.93282 19.6569 10.0308 19.4715 10.0559 19.27L11.8268 5.10333C11.8401 4.98633 11.8287 4.86783 11.7933 4.75551C11.7579 4.64319 11.6994 4.53954 11.6215 4.45126C11.5435 4.36298 11.4479 4.29203 11.3409 4.24301C11.2338 4.19399 11.1176 4.16798 10.9999 4.16666H9.87386L10.7561 1.66667H14.3332ZM7.51813 5.83333L6.9299 7.49999H2.04813L1.8398 5.83333H7.51813ZM8.49344 18.3333H3.4023L2.25646 9.16666H6.34167L4.38073 14.7226L5.9524 15.2774L8.10907 9.16666H9.63927L8.49344 18.3333ZM10.0559 5.83333L9.84761 7.49999H8.69781L9.28594 5.83333H10.0559Z"
        fill={color}
      />
    </svg>
  );
}

export default Drink;

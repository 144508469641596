import { IRefillOrder, IItemRequested } from 'refillorders/interfaces';

export function prepareItems (order: IRefillOrder) {
  switch (order?.status) {
    case 'recommended':
      return prepareRecommendedItems(order.itemsRequested)
        .sort((l, r) => l.slot <= r.slot ? -1 : 1);
    case 'created':
    case 'ready_for_collection':
    case 'en_route':
    case 'refilled':
    case 'adjustment':
    case 'completed':
      return prepareItemsKitted(order.itemsRequested)
        .sort((l, r) => l.slot <= r.slot ? -1 : 1);

    default:
      return [];
  }
}

function prepareRecommendedItems (itemsRequested: IItemRequested[]) {
  return itemsRequested?.map((req) => {
    return {
      slot: req.slot,
      image: req.image,
      name: req.name,
      quantity: req.packageQuantity,
      packageQuantity: `${req.packageSize} ${req.uom} / ${req.packageType}`, // e.g. 50 cups / box
      total: `${req.packageQuantity * req.packageSize} ${req.uom}`
    };
  });
}

function prepareItemsKitted (itemsRequested: IItemRequested[]) {
  return itemsRequested?.map((req) => {
    return {
      slot: req.slot,
      image: req.image,
      name: req.name,
      lots: req.kit.lots?.map(l => l.lotNo).join(), // TODO: add how much quantity from which lots
      location: 'Unknown',
      quantity: req.packageQuantity,
      packageQuantity: `${req.packageSize} ${req.uom} / ${req.packageType}`, // e.g. 50 cups / box
      total: `${req.kit.totalQuantity * req.packageSize} ${req.uom}`
    };
  });
}

import * as React from "react";

import * as moment from "moment";

import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";

import { Table } from "containers/warehousing/components/Table";
import { TableHeader } from "components/TableHeader";

import { Link } from "react-router-dom";
import TextButton from "common/components/textbutton/TextButton";

import * as colors from "common/styles/colors.scss";

import { useGetContracts } from "../../hooks/useGetContracts";
import { contractRoutes } from "containers/contract/routes";
import { useCanAccessContractFeature } from "containers/contract/hooks/useCanAccessContractFeature";
import { ExportCSVButton } from "./useExportContractCSV";

import { useTranslation } from "react-i18next";
import Typography from "components/Typography";

function ContractIndex(): JSX.Element {
  const [filter, setFilter] = React.useState<IAppliedFilter>(null);
  const getContractsResult = useGetContracts({ filter: filter?.value });
  const scrollableElementRef = useOnScrollToBottom({
    callback: getContractsResult.paginate,
  });
  const permissionResult = useCanAccessContractFeature();
  const isLoading = getContractsResult.isLoading || permissionResult.isLoading;

  const { t } = useTranslation();

  return (
    <div
      style={{
        flex: "1 1 0",
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "20px 35px",
        boxSizing: "border-box",
      }}
    >
      <ContractHeader />
      <PureFilterBar
        value={filter}
        onChange={setFilter}
        filterOptions={[
          {
            field: COLUMNS.CONTRACT_ID,
            queryType: "contains",
            translationKey: t("label_contract_id"),
          },
        ]}
      />
      <div
        style={{
          minHeight: "0",
          flex: "1 1 0",
          fontFamily: "Kanit",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        <Table
          RowWrapper={({ children }: { children: React.ReactNode }) => {
            return (
              <div
                style={{
                  padding: "0 18px",
                }}
              >
                {children}
              </div>
            );
          }}
          data={getContractsResult.data}
          scrollableTableRef={scrollableElementRef}
          isPaginating={getContractsResult.isPaginating}
          isLoading={isLoading}
          getKey={(contract) => contract.id}
          columns={[
            {
              id: COLUMNS.CONTRACT_ID,
              style: {
                flex: "0 0 111px",
                minWidth: "111px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t('label_contract_id')}</TableHeader>,
              cell: (contract) => (
                <Link to={contractRoutes.contractId(contract.id)}>
                  <span
                    style={{
                      color: colors.primary500,
                    }}
                  >
                    {contract.friendlyId}
                  </span>
                </Link>
              ),
            },
            {
              id: COLUMNS.LOCATION,
              style: {
                flex: "0 0 111px",
                minWidth: "111px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => <TableHeader>{t('label_location')}</TableHeader>,
              cell: (contract) => {
                const locations = contract.locations;
                let cellDisplay: JSX.Element;

                if (locations.length === 1) {
                  cellDisplay = (
                    <Link
                      to={contractRoutes.locationId(
                        contract.id,
                        locations[0].locationId
                      )}
                    >
                      <span>{locations[0].friendlyId}</span>
                    </Link>
                  );
                }
                if (locations.length > 1) {
                  cellDisplay = (
                    <Link to={contractRoutes.contractId(contract.id)}>
                      <span>{`Mul(${locations.length})`}</span>
                    </Link>
                  );
                }

                return (
                  <div
                    style={{
                      fontFamily: "Kanit",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: colors.primary500,
                    }}
                  >
                    {cellDisplay}
                  </div>
                );
              },
            },

            {
              id: COLUMNS.ORGANISATION,
              style: {
                flex: "1 0 170px",
                minWidth: "170px",
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              },
              headerCell: () => <TableHeader>{t('label_organisation')}</TableHeader>,
              cell: (contract) => <span>{contract.organisationName}</span>,
            },

            {
              id: COLUMNS.CONTRACT_END_DATE,
              style: {
                flex: "0 0 150px",
                minWidth: "150px",
                display: "flex",
                alignItems: "center",
              },
              headerCell: () => (
                <TableHeader>{t("label_contract_end_date")}</TableHeader>
              ),
              cell: (contract) => {
                const isExpired = contract.contractEndDate.isBefore(moment());
                return (
                  <div
                    style={{
                      width: "120px",
                      height: "38px",
                      display: "grid",
                      borderRadius: "4px",
                      placeContent: "center",
                      backgroundColor: isExpired
                        ? colors.error
                        : colors.success,
                    }}
                  >
                    <Typography type="caption" color="surface">
                      {contract.contractEndDate.format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

const COLUMNS = {
  CONTRACT_ID: "contractId",
  LOCATION: "location",
  SALES: "sales",
  ORGANISATION: "organisation",
  PROJECT_TYPE: "projectType",
  CONTRACT_END_DATE: "contractEndDate",
};

export default ContractIndex;

function useOnScrollToBottom({ callback }: { callback: () => void }) {
  const scrollableElementRef = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (!scrollableElementRef.current) return;

    function scrollHandler() {
      const target = scrollableElementRef.current;
      const isAtBottom =
        target.scrollHeight - target.scrollTop === target.clientHeight;
      if (isAtBottom) {
        callback();
      }
    }

    scrollableElementRef.current.addEventListener("scroll", scrollHandler);
  });

  return scrollableElementRef;
}

function ContractHeader(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "20px",
        gap: "20px",
      }}
    >
      <div>
        <Typography type="headline-5" translate>
          label_contract
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flex: "1 1 0",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <Link to={contractRoutes.newContract()}>
            <TextButton text={t("label_new_contract")} icon="Plus" />
          </Link>

          <ExportCSVButton />
        </div>
      </div>
    </div>
  );
}

import { MachineStoreType } from "containers/machinesV2/useMachinesStore";
import { getSelectedMachineReportCSV } from "machines/service";
import * as React from "react";
import { toasti18n } from "utils/toast";

function getMachineParams(machines: string[]) {
  const URLparam = new URLSearchParams();
  machines.forEach((each) => URLparam.append("machines", each));
  return URLparam.toString();
}

async function downloadSelectedMachineReportCSV(selectedMachines: MachineStoreType["selectedMachine"]) {
  const machineParam = getMachineParams(selectedMachines);
  const file = await getSelectedMachineReportCSV(machineParam);

  const CSVFile = new File([file], "report.csv", { type: "text/csv" });

  const anchorElement = document.createElement("a");
  anchorElement.href = URL.createObjectURL(CSVFile);
  anchorElement.download = "report.csv";
  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);
}

export function useDownloadSelectedMachineReport() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function downloadSelectedMachineReport(selectedMachines: MachineStoreType["selectedMachine"]) {
    try {
      setIsLoading(true);
      await toasti18n.promise(downloadSelectedMachineReportCSV(selectedMachines));
    } catch (error) {
      toasti18n.error({ message: "error" });
      throw new Error("Error downloading machine report");
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadSelectedMachineReport, isLoading };
}

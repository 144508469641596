// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error--3SddSQO4F6{display:flex;flex-direction:column;justify-content:center;align-items:center}.Error--3SddSQO4F6 .icon--3tv9WaqtK5{width:170px;height:170px}.TableContainer--1HRtwaV_l5{flex:1 1 0;min-height:0;overflow-x:auto;overflow-y:hidden}", ""]);
// Exports
exports.locals = {
	"Error": "Error--3SddSQO4F6",
	"icon": "icon--3tv9WaqtK5",
	"TableContainer": "TableContainer--1HRtwaV_l5"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextButton--2G-UjpPSh2{display:flex;flex-direction:row;align-items:center;cursor:pointer;transition:all 100ms ease-in}.TextButton--2G-UjpPSh2 .text--2j-QuI7BMp{color:#4b4d9b;margin-left:5px}.TextButton--2G-UjpPSh2.loading--M-phA2p3nD{pointer-events:none;opacity:.5}.TextButton--2G-UjpPSh2.border--2w8eL4zkdp{padding:10px;border:1px solid rgba(0,0,0,.12);border-radius:4px}.badge--1eJixBn-5v{top:-8px;right:5px;padding:0px 6px;border-radius:50%;background-color:#e95d50;color:#fff;position:relative}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"error2": "#f89389",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "hsla(0,0%,100%,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"TextButton": "TextButton--2G-UjpPSh2",
	"text": "text--2j-QuI7BMp",
	"loading": "loading--M-phA2p3nD",
	"border": "border--2w8eL4zkdp",
	"badge": "badge--1eJixBn-5v"
};
module.exports = exports;

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Button from 'common/components/button/Button';
import Typography from 'common/components/typography/Typography';

import * as styles from './SelectButton.module.scss';

export interface IOption {
  label: string;
  onClick: (option: IOption) => void;
  optionComponent?: JSX.Element;
}

interface ISelectProps {
  options: Array<IOption>;
  label: string;
  className?: string;
  dropdownClassName?: string;
  buttonProps?: object;
}

const defaultButtonProps = {
  postIcon: {
    name: 'SortChevrons',
    color: 'surface',
  },
};

function SelectButton({
  options,
  label,
  className,
  dropdownClassName,
  buttonProps = defaultButtonProps,
}: ISelectProps): JSX.Element {
  const dropdownNode = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    function handleClick(e) {
      if (!dropdownNode.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  function handleOptionClick(option: IOption): void {
    option.onClick(option);
    setIsOpen(false);
  }

  function handleButtonClick(): void {
    setIsOpen((open) => !open);
  }

  return (
    <div
      ref={dropdownNode}
      className={[styles.SelectButton, className].join(' ')}
    >
      <Button
        type="primary"
        translationKey={isMobile ? undefined : label}
        onClick={handleButtonClick}
        className={styles.button}
        {...buttonProps}
      />

      <div
        className={[
          styles.dropdown,
          isOpen ? styles.open : '',
          dropdownClassName,
        ].join(' ')}
      >
        {options.map((option, index) => {
          return (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              className={styles.option}
            >
              {option.optionComponent ? (
                option.optionComponent
              ) : (
                <Typography translationKey={option.label} type="body-1" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectButton;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SnapshotTable--2bMBcoNvFV{max-width:70vw}@media screen and (max-width: 1024px){.SnapshotTable--2bMBcoNvFV{max-width:unset}}.SnapshotTable--2bMBcoNvFV .ColumnContainer--2D4_cCiC9n{display:flex}.SnapshotTable--2bMBcoNvFV .ColumnContainer--2D4_cCiC9n>*{flex:1 1 0;min-width:0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin:2px;padding:2px}.SnapshotTable--2bMBcoNvFV .ColumnContainer--2D4_cCiC9n>:first-child{flex:2 1 0}", ""]);
// Exports
exports.locals = {
	"SnapshotTable": "SnapshotTable--2bMBcoNvFV",
	"ColumnContainer": "ColumnContainer--2D4_cCiC9n"
};
module.exports = exports;

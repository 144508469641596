import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { clsx } from "clsx";

export const CustomTextField = styled((props: TextFieldProps) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps,
      className: clsx("!font-kanit", props.InputProps.className),
    }}
  />
))(() => ({
  "& .MuiInputLabel-root": {
    display: "none",
  },
}));

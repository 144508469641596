import * as React from 'react';

// Interfaces
import { IColumn } from 'common/components/table/Table';

// Custom components
import TransactionHeader from '../components/TransactionHeader';

// Custom Utils
import { toggleQueryObjectOrderBy } from '../../transactions/constants/toggleQueryObjectOrderBy';

/**
 * Remove `headerLabel` property from each `config` object in `currentColumnsConfig` array.
 * Replace it with `headerCellRenderer` property using the 'TransactionHeader.tsx' component
 * @remarks
 * 1) headerLabel must be removed otherwise `Table.tsx` component will try to display the text (We only need the text to display once using  `TransactionHeader.tsx`)
 * 2) `dataKey` is the field in the returned object that a column will attempt to look up. The `dataKey` could also be supplied as an API argument to `orderBy`.
 * 3) The status field needs special treatment because the `dataKey` is not defined for status cells (due to how the cell is implemented)
 *
 * Examples: (Showing what is happening to each column config)
 * Input: {headerLabel: label_machine_name, ...}
 * Output: {headerCellRenderer: () => <TransactionHeader label={label_machine_name} .../>, ...}
 */
function insertTransactionHeader<
  T extends { orderBy: string; sort: string }
> (
  currentColumnsConfig: IColumn[],
  queryObject: T,
  setQueryObject: React.Dispatch<React.SetStateAction<T>>
) {
  currentColumnsConfig = currentColumnsConfig.map(config => {
    // Remove headerLabel field
    const { headerLabel, ...configWithoutHeaderLabel } = config;

    // Determine dataKey associated with each column to be used in the onClick handler
    let dataKey;
    if (headerLabel === 'label_status') {
      dataKey = 'status';
    } else {
      dataKey = config.dataKey;
    }

    // Determine the current sort for each header to correctly display sorting icon
    let sortProp: 'ASC' | 'DESC' | 'NONE';
    if (queryObject.orderBy === dataKey) {
      sortProp = queryObject.sort as 'ASC' | 'DESC' | 'NONE';
    } else {
      sortProp = 'NONE';
    }

    const headerCellRenderer = () => (
      <TransactionHeader
        label={headerLabel}
        sort={sortProp}
        onClick={() =>
          setQueryObject(toggleQueryObjectOrderBy(queryObject, dataKey))
        }
      />
    );

    return { ...configWithoutHeaderLabel, headerCellRenderer };
  });
  return currentColumnsConfig;
}

export default insertTransactionHeader;

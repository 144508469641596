import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { MilkTable } from "./MilkTable";
import { CoffeeBeansTable } from "./CoffeeBeansTable";
import { Fallback } from "../../components/Fallback";
import { SugarTable } from "./SugarTable";
import { LidsTable } from "./LidsTable";
import { OtherTable } from "./OtherTable";
import { PlasticCupsTable } from "./PlasticCupsTable";
import { EspressoCupsTable } from "./EspressoCupsTable";
import { PaperCupsTable } from "./PaperCupsTable";

export function InventoryTables() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();
  return (
    <Switch>
      <Route path={routes.inventory.milk()}>
        <ErrorBoundary fallback={Fallback} key={"milk" + (serviceZone ?? +"")}>
          <MilkTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.sugar()}>
        <ErrorBoundary fallback={Fallback} key={"sugar" + (serviceZone ?? +"")}>
          <SugarTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.coffeeBeans()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"coffee-beans" + (serviceZone ?? +"")}
        >
          <CoffeeBeansTable />
        </ErrorBoundary>
      </Route>

      <Route path={routes.inventory.plasticCups()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"plastic-cups" + (serviceZone ?? +"")}
        >
          <PlasticCupsTable />
        </ErrorBoundary>
      </Route>

      <Route path={routes.inventory.espressoCups()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"expresso-cups" + (serviceZone ?? +"")}
        >
          <EspressoCupsTable />
        </ErrorBoundary>
      </Route>

      <Route path={routes.inventory.paperCups()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"paper-cups" + (serviceZone ?? +"")}
        >
          <PaperCupsTable />
        </ErrorBoundary>
      </Route>

      <Route path={routes.inventory.lid()}>
        <ErrorBoundary fallback={Fallback} key={"lid" + (serviceZone ?? +"")}>
          <LidsTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.other()}>
        <ErrorBoundary fallback={Fallback} key={"other" + (serviceZone ?? +"")}>
          <OtherTable />
        </ErrorBoundary>
      </Route>
      <Redirect to={routes.inventory.milk() + search} />
    </Switch>
  );
}

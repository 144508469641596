import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";
import * as React from "react";
import { Trans } from "react-i18next";
import { MachineCategoryType } from "../types";

export type AbnormalStatus = "MORE_THAN_EXPECTED" | "LESS_THAN_EXPECTED";

export function AbnormalReturnQuantityNotifier({
  abnormalStatus,
}: {
  abnormalStatus: AbnormalStatus;
}) {
  return (
    <div style={{ display: "flex", minWidth: "max-content", gap: 7.5 }}>
      <Icon name="AlertIcon" color="warning" />

      <Typography type="caption" color="onSurfaceMedium">
        <Trans
          i18nKey={
            abnormalStatus === "MORE_THAN_EXPECTED"
              ? "label_return_more_than_expected"
              : "label_return_less_than_expected"
          }
          components={{
            bold: <strong />,
          }}
        />
      </Typography>
    </div>
  );
}

export type IsReturnQuantityAbnormalReturnType =
  | { exceededThreshold: false }
  | {
      exceededThreshold: true;
      abnormalStatus: AbnormalStatus;
    };

export function isReturnQuantityAbnormal({
  expectedReturn,
  oldQuantity,
  packageSize,
  actualReturnBag,
  actualReturnUnit,
  type,
}: {
  expectedReturn: number;
  oldQuantity: number;
  packageSize: number;
  actualReturnBag: string | number;
  actualReturnUnit: string | number;
  type: MachineCategoryType;
}): IsReturnQuantityAbnormalReturnType {
  if (packageSize === 0) throw new Error("Package size cannot be 0");

  if (
    type === "COFFEE" &&
    (actualReturnBag === "" ||
      actualReturnUnit === "" ||
      isNaN(Number(actualReturnBag)) ||
      isNaN(Number(actualReturnUnit)))
  ) {
    return { exceededThreshold: false };
  }

  if (
    type === "SPIRAL" &&
    (actualReturnBag === "" || isNaN(Number(actualReturnBag)))
  ) {
    return { exceededThreshold: false };
  }

  const actualReturnNumber =
    Number(actualReturnBag) * packageSize + Number(actualReturnUnit);
  const totalExpectedReturn = oldQuantity + expectedReturn;

  const differenceInTermsOfPackage =
    (actualReturnNumber - totalExpectedReturn) / packageSize;

  if (Math.abs(differenceInTermsOfPackage) <= 1) {
    return { exceededThreshold: false };
  }

  if (differenceInTermsOfPackage > 1) {
    return {
      exceededThreshold: true,
      abnormalStatus: "MORE_THAN_EXPECTED",
    };
  }

  if (differenceInTermsOfPackage < -1) {
    return {
      exceededThreshold: true,
      abnormalStatus: "LESS_THAN_EXPECTED",
    };
  }
}

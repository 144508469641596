import * as React from "react";
import { TransactionGroupByErrorPage } from "./page";
import { groupByOptions } from "../common/groupByOptions";
import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import { useHistory } from "react-router-dom";

export function TransactionGroupByError () {
  const history = useHistory();

  function onGroupBySelect(selectedGroupBy) {
    if (selectedGroupBy === "allTransactionNone") {
      history.push('/transactions/group-by-none');
      return;
    }

    if (selectedGroupBy === "groupByError") {
      return;
    }

    const newURLSearchParams = new URLSearchParams(
      getDefaultQueryParamsForGroupBy(selectedGroupBy)
    ).toString();

    history.push(`transaction?${newURLSearchParams}`);
  }
  
  
  return <TransactionGroupByErrorPage name="All Transaction group by error" options={groupByOptions} onSelect={onGroupBySelect} />;
}

type InputValue = {
  id: string;
  value: string;
};
export type InputValuesState = InputValue[];

export type InputStateType = {
  getInputValue?: string | null;
  onInputChange?: (text: string) => void;
}

type GetColumnInputValueArgs = {
  id: string;
  inputState: InputValuesState
}

type SetColumnInputValueArgs = {
  id: string;
  inputState: InputValuesState;
  val: string;
}

export function getColumnInputValue ({ id, inputState }: GetColumnInputValueArgs) {
  return (): string | null => {
    return inputState?.find(input => input.id === id)?.value || null;
  };
};


export function setColumnInputValue({ id, inputState, val }: SetColumnInputValueArgs): InputValuesState {
  if (val === null || val.trim() === "") {
    // Remove the entry with the given id if val is null or empty
    return inputState.filter(input => input.id !== id);
  }

  const existingIndex = inputState.findIndex(input => input.id === id);

  if (existingIndex !== -1) {
    // Update existing value
    return inputState.map((input, index) => 
      index === existingIndex ? { ...input, value: val } : input
    );
  } else {
    // Add new value
    return [...inputState, { id, value: val }];
  }
}


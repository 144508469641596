import * as React from 'react';

interface IReplyProps {
  color?: string,
  className?: string
}

function Reply ({
  color = 'white',
  className
}: IReplyProps): JSX.Element {
  return (
    <svg className={className} width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.48145 12L12 21.2038V15.8719C15.3098 15.519 18.0461 16.4603 20.2929 18.7071L22 20.4142V18C22 11.7345 18.5487 8.33144 12 8.02305V2.79626L1.48145 12ZM4.51863 12L10 7.2038V10H11C16.2007 10 19.0505 11.8511 19.7988 15.7166C17.2556 14.0145 14.2502 13.4445 10.8356 14.0136L10 14.1529V16.7963L4.51863 12Z' fill={color} />
    </svg>
  );
}

export default Reply;

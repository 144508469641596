import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { MachineUnsellableTable } from "./MachineUnsellableTable";
import { OutOfServiceTable } from "./OutOfServiceTable";
import { OfflineTable } from "./OfflineTable";
import { IceErrorTable } from "./IceErrorTable";
import { CoffeeErrorTable } from "./CoffeeErrorTable";
import { IceRateLowTable } from "./IceRateLowTable";
import { SodaErrorTable } from "./SodaErrorTable";
import { Fallback } from "../../components/Fallback";
import { SpiralUnsellableTable } from "./SpiralUnsellableTable";
import { WhipperTable } from "./WhipperTable";

export function MajorTroubleTables() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();
  return (
    <Switch>
      <Route path={routes.majorTrouble.machineUnsellable()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"machine-unsellable" + (serviceZone ?? +"")}
        >
          <MachineUnsellableTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.outOfService()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"out-of-service" + (serviceZone ?? +"")}
        >
          <OutOfServiceTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.offline()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"offline" + (serviceZone ?? +"")}
        >
          <OfflineTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.iceError()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"ice-error" + (serviceZone ?? +"")}
        >
          <IceErrorTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.coffeeError()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"coffee-error" + (serviceZone ?? +"")}
        >
          <CoffeeErrorTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.iceRateLow()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"ice-rate-low" + (serviceZone ?? +"")}
        >
          <IceRateLowTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.sodaError()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"soda-error" + (serviceZone ?? +"")}
        >
          <SodaErrorTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.whipper()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"whipper" + (serviceZone ?? +"")}
        >
          <WhipperTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.majorTrouble.spiralUnsellable()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"spiral-unsellable" + (serviceZone ?? +"")}
        >
          <SpiralUnsellableTable />
        </ErrorBoundary>
      </Route>
      <Redirect to={routes.majorTrouble.machineUnsellable() + search} />
    </Switch>
  );
}

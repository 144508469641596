import * as React from "react";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";

type AccordionWithStickyHeaderProps = {
  title: string;
  subTitle: React.ReactNode;
  body: React.ReactNode;
  className?: string;
};
export function AccordionWithStickyHeader({
  title,
  subTitle,
  body,
  className,
}: AccordionWithStickyHeaderProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <>
      <div
        className={clsx("sticky bg-white top-0 z-10", className)}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex items-center gap-4 bg-on-surface-high px-2 py-1 cursor-pointer">
          <Icon
            name="Chevron"
            className={isOpen ? "-rotate-90" : "rotate-90"}
          />
          <p className="text-white text-body2 font-medium">{title}</p>
        </div>
        <div className={clsx("overflow-hidden", isOpen ? "h-auto" : "h-0")}>
          {subTitle}
        </div>
      </div>
      <div className={clsx("overflow-hidden", isOpen ? "h-auto" : "h-0")}>
        {body}
      </div>
    </>
  );
};
import * as React from 'react';
import * as styles from './PercentageIndicator.module.scss';

import * as SCSScolors from 'common/styles/colors.scss';

interface PercentageIndicatorProps {
  className?: string;
  percentage: number;
  color: 'success' | 'error' | 'warning';
}

function PercentageIndicator ({
  percentage,
  color,
  className = ''
}: PercentageIndicatorProps): JSX.Element {
  return (
    <div className={[ styles.PercentageIndicator, className ].join(' ')}>
      <div className={styles.Triangle}>
        {percentage < 0
          ? (
          <DownwardsTriangle color={color} />
            )
          : (
          <UpwardsTriangle color={color} />
            )}
      </div>
      <span className={styles.Percentage} style={{ color: SCSScolors[color] }}>
        {(Math.abs(percentage) * 100).toFixed(1)}%
      </span>
    </div>
  );
}

export { PercentageIndicator };

interface UpwardsTriangleProps {
  color: 'success' | 'error' | 'warning';
}

function UpwardsTriangle ({ color }: UpwardsTriangleProps): JSX.Element {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 0L6.53109 3.75H0.468911L3.5 0Z" fill={SCSScolors[color]} />
    </svg>
  );
}

interface DownwardsTriangleProps {
  color: 'success' | 'error' | 'warning';
}

function DownwardsTriangle ({ color }: DownwardsTriangleProps) {
  return (
    <svg
      width="7"
      height="3"
      viewBox="0 0 7 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 3L6.53109 0H0.468911L3.5 3Z" fill={SCSScolors[color]} />
    </svg>
  );
}

import * as React from 'react';

interface ISortChevronProps {
  color?: string,
  className?: string
}

function SortChevrons ({
  color = 'white',
  className
}: ISortChevronProps): JSX.Element {
  return (
    <svg className={className} width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.71423 15.0714L11.9999 19.3571L16.2857 15.0714M16.2857 9.92857L11.9999 5.64285L7.71423 9.92857' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
}

export default SortChevrons;

import Typography from "components/Typography/Typography";
import * as React from "react";

export function TextDotSeparator({
  leftText, rightText,
}: {
  leftText: Array<string> | string;
  rightText: Array<string> | string;
}) {
  return (
    <PreventTextOverflow>
      <Typography type="caption" color="onSurfaceMedium" translate>
        {leftText}
        {" • "}
        {rightText}
      </Typography>
    </PreventTextOverflow>
  );
}

export function PreventTextOverflow({ children }: { children: React.ReactNode; }) {
  return (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
      {children}
    </div>
  );
}

import * as React from "react";

export function useSortState<Columns extends string>(
  initialState?: null | { columnId: Columns; direction: "ASC" | "DESC" }
) {
  const [sortState, setSortState] = React.useState(initialState ?? null);
  function getSortDirectionByColumnId(columnId: Columns) {
    return sortState === null
      ? null
      : sortState.columnId !== columnId
      ? null
      : sortState.direction;
  }
  function toggleSortColumnByColumnId(columnId: Columns) {
    if (sortState === null) {
      setSortState({ columnId: columnId, direction: "ASC" });
    } else if (sortState.columnId !== columnId) {
      setSortState({ columnId: columnId, direction: "ASC" });
    } else if (sortState.direction === "ASC") {
      setSortState({ ...sortState, direction: "DESC" });
    } else if (sortState.direction === "DESC") {
      setSortState(null);
    }
  }

  return { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId };
}
